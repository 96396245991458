import type {SVGProps} from 'react';
import React from 'react';

const ProcessingIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" {...props}>
        <rect x="0.666504" y="0.5" width="48" height="48" rx="24" fill="#009FE3" fillOpacity="0.12" />
        <path
            d="M24.2165 17.2H24.6075C27.35 17.2 28.7212 17.2 29.2418 17.6926C29.6917 18.1183 29.8911 18.7456 29.7697 19.353C29.6291 20.0557 28.5096 20.8476 26.2706 22.4313L22.6125 25.0187C20.3734 26.6024 19.2539 27.3943 19.1134 28.097C18.9919 28.7044 19.1913 29.3317 19.6412 29.7574C20.1618 30.25 21.533 30.25 24.2755 30.25H25.1165M21.0665 17.2C21.0665 18.6912 19.8577 19.9 18.3665 19.9C16.8753 19.9 15.6665 18.6912 15.6665 17.2C15.6665 15.7088 16.8753 14.5 18.3665 14.5C19.8577 14.5 21.0665 15.7088 21.0665 17.2ZM33.6665 29.8C33.6665 31.2912 32.4577 32.5 30.9665 32.5C29.4753 32.5 28.2665 31.2912 28.2665 29.8C28.2665 28.3088 29.4753 27.1 30.9665 27.1C32.4577 27.1 33.6665 28.3088 33.6665 29.8Z"
            stroke="#009FE3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ProcessingIcon;
