import React from 'react';
import {IPriceProps} from '../../types';
import {isNullOrUndefined} from '../../utils/runtimeUtils';
import {getCurrencySymbol} from '../../utils/currencyHelpers';

type Props = IPriceProps;

const Price: React.FC<Props> = ({price, amountComponent, currencyComponent, separateWithNbsp, toFixed = 2}) => {
    if (isNullOrUndefined(price) || isNullOrUndefined(price.amount)) {
        return null;
    }

    const amount = (parseFloat(price.amount) / 100).toFixed(toFixed).toString(),
        currency = getCurrencySymbol(price.currency);

    return (
        <React.Fragment>
            {amountComponent ? amountComponent(amount) : <span className={'price-amount'}>{amount}</span>}
            {true === separateWithNbsp ? <>&nbsp;</> : ''}
            {currencyComponent ? currencyComponent(currency) : currency}
        </React.Fragment>
    );
};

export default Price;
