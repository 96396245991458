import type {SVGProps} from 'react';
import React from 'react';

const AddUserIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <path
            d="M15.25 16V11.5M13 13.75H17.5M10 11.5H7C5.60218 11.5 4.90326 11.5 4.35195 11.7284C3.61687 12.0328 3.03284 12.6169 2.72836 13.3519C2.5 13.9033 2.5 14.6022 2.5 16M12.625 2.71807C13.7244 3.16311 14.5 4.24098 14.5 5.5C14.5 6.75902 13.7244 7.83689 12.625 8.28193M11.125 5.5C11.125 7.15685 9.78185 8.5 8.125 8.5C6.46815 8.5 5.125 7.15685 5.125 5.5C5.125 3.84315 6.46815 2.5 8.125 2.5C9.78185 2.5 11.125 3.84315 11.125 5.5Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default AddUserIcon;
