import {Box, Stack, Typography} from '@mui/material';
import {IAccountBasicInfoOutput, PhoneComponent, DefaultAvatar} from 'palipali-panel-common-web';
import React from 'react';

interface ICourierAssignDetailsProps {
    account: IAccountBasicInfoOutput;
}
const CourierAssignDetails: React.FC<ICourierAssignDetailsProps> = (props: ICourierAssignDetailsProps) => {
    const courierName = `${props.account.firstName} ${props.account.lastName}`,
        courierEmail = props.account.username ? props.account.username : '-';

    return (
        <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
            <DefaultAvatar />
            <Stack>
                <Typography variant="subtitle2" component="p" color="text.primary">
                    {courierName}
                </Typography>
                <Typography variant="caption" component="p" color="text.secondary">
                    {courierEmail}
                </Typography>
                <Typography variant="caption" component="p" color="text.secondary">
                    <PhoneComponent phone={props.account.phone} />
                </Typography>
            </Stack>
        </Box>
    );
};
export default CourierAssignDetails;
