import React, {FC, Suspense, useEffect} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Box, ThemeProvider, Theme} from '@mui/material';
import {
    useSettings,
    withAuthGuard,
    VerticalLayout,
    UserRole,
    Toast,
    PrivateRoute,
    LogoutComponent,
    accountUserNameSelector,
    authTokenSelector,
    setLoginFailure,
    setAuthState,
    initApp,
    refreshTokenSelector,
    Loader,
    LoaderType,
    isLoadingSelector,
    silentRefreshDictionariesAndParameters,
    AppDataRefreshComponent,
} from 'palipali-panel-common-web';
import {useSections} from './config';
import {useSubMenuSections} from './sub-menu-config';
import ActiveCouriers from '../ActiveCouriers';
import Reports from '../Reports';
import Settlements from '../Settlements';
import Couriers from '../Couriers';
import Vehicles from '../Vehicles';
import Account from '../Account';

interface IPanelHostProps {
    theme: Theme;
    userRole: UserRole;
}

export const PanelHost: FC<IPanelHostProps> = withAuthGuard((props) => {
    const settings = useSettings();
    const sections = useSections();
    const subMenuSections = useSubMenuSections();
    const backgroundColor = props.theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100';
    const userName = useSelector(accountUserNameSelector);
    const dispatch = useDispatch();
    const authToken = useSelector(authTokenSelector);
    const refreshToken = useSelector(refreshTokenSelector);
    const isLoading = useSelector(isLoadingSelector);

    useEffect(() => {
        if (!authToken || !refreshToken) {
            dispatch(setLoginFailure('alerts.noAccessError'));
            dispatch(setAuthState(null, null, null, false, []));
        } else {
            dispatch(initApp(refreshToken, props.userRole));
        }
    }, []);

    const fetchData = () => {
        dispatch(silentRefreshDictionariesAndParameters());
    };

    return (
        <PrivateRoute userRole={props.userRole}>
            <ThemeProvider theme={props.theme}>
                <VerticalLayout
                    sections={sections}
                    subMenuSections={subMenuSections}
                    navColor={settings.navColor}
                    userName={userName}
                    {...props}>
                    <Suspense>
                        <AppDataRefreshComponent silentDictionaryRefreshFunction={fetchData} />
                        <Box sx={{backgroundColor: backgroundColor}}>
                            <Routes>
                                <Route path="active-couriers" element={<ActiveCouriers />} key="active-couriers" />
                                <Route path="reports" element={<Reports />} key="reports" />
                                <Route path="settlements" element={<Settlements />} key="settlements" />
                                <Route path="couriers" element={<Couriers />} key="couriers" />
                                <Route path="vehicles/*" element={<Vehicles />} key="vehicles" />
                                <Route path="account" element={<Account />} key="account" />
                                <Route path="logout" element={<LogoutComponent theme={props.theme} />} key="logout" />
                                <Route path="*" element={<Navigate to="active-couriers" />} />
                            </Routes>
                        </Box>
                    </Suspense>
                    <Toast />
                </VerticalLayout>
                <Loader showLoader={isLoading} type={LoaderType.Global} />
            </ThemeProvider>
        </PrivateRoute>
    );
});
