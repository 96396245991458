import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {IConfirmRegistrationOutput} from '../../model/user';

export function createConfirmEmailChangeAPI(authenticationToken: string): Observable<IApiSingleResponseBase<IConfirmRegistrationOutput>> {
    return palipaliAPI.post(`api/users/confirm_email_change`, {}, new RestQueryParams(), {
        'X-Authentication-Token': authenticationToken,
    });
}
