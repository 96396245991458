import {Button, CardActions, CardContent, Divider, Grid, useTheme} from '@mui/material';
import {
    FormikForm,
    IModelDictionaryDatum,
    IVehicleDefinitionOutput,
    Translation,
    colorsSelector,
    fetchVehicleTypes,
    groupedVehicleDefinitionsSelector,
    sortMultiselectLabels,
    ChevronRight,
    Loader,
    LoaderType,
    vehicleTypesLoadingSelector,
} from 'palipali-panel-common-web';
import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    IVehicleBasicInformation,
    VehicleOperationStep,
    setBasicInformation,
    setCurrentStep,
} from '../../../../store/reducers/vehicleOperationSlice';
import {vehicleBasicInformationSelector} from '../../../../store/selectors/vehicleOperationSelector';
import basicInformationFormConfig from './basicInformationFormConfig';

export interface IBaseInformationValues {
    registrationNumber: string | null;
    vehicleDefinitionId: string | null;
    make: string | null;
    model: string | null;
    vehicleType: string | null;
    vehicleTypeId: string | null;
    productionYear: string | null;
    colorId: string | null;
    s: boolean;
    m: boolean;
    l: boolean;
}

const BasicInformation: React.FunctionComponent = () => {
    const vehicleGroupedDefinitions = useSelector(groupedVehicleDefinitionsSelector),
        vehicleColors: IModelDictionaryDatum[] = useSelector(colorsSelector),
        isLoading = useSelector(vehicleTypesLoadingSelector),
        [submitAllowed, setSubmitAllowed] = React.useState<boolean>(false),
        vehicleColorsOptions = sortMultiselectLabels(
            vehicleColors.map((color: IModelDictionaryDatum) => ({
                value: color.id,
                label: color.name,
            }))
        ),
        vehicleBasicInformation = useSelector(vehicleBasicInformationSelector),
        dispatch = useDispatch(),
        initialValues: IBaseInformationValues = {
            registrationNumber: vehicleBasicInformation?.registrationNumber || '',
            vehicleDefinitionId: vehicleBasicInformation?.vehicleDefinitionId || '',
            make: vehicleBasicInformation?.make || '',
            model: vehicleBasicInformation?.model || '',
            vehicleType: vehicleBasicInformation?.vehicleType || '',
            vehicleTypeId: vehicleBasicInformation?.vehicleTypeId || '',
            productionYear: vehicleBasicInformation?.productionYear || '',
            colorId: vehicleBasicInformation?.colorId || '',
            s: vehicleBasicInformation?.packageSizes?.s || false,
            m: vehicleBasicInformation?.packageSizes?.m || false,
            l: vehicleBasicInformation?.packageSizes?.l || false,
        };

    const theme = useTheme();

    const getData = React.useCallback(
        (path?: string, getKeyValue?: boolean): string[] | IVehicleDefinitionOutput => {
            if (!vehicleGroupedDefinitions) {
                return [];
            }
            if (!path) {
                return Object.keys(vehicleGroupedDefinitions);
            }
            const keys = path.split('.');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let result: any = vehicleGroupedDefinitions;
            for (const key of keys) {
                result = result[key];
            }
            if (getKeyValue) {
                return result as IVehicleDefinitionOutput;
            }
            return Object.keys(result);
        },
        [vehicleGroupedDefinitions]
    );

    useEffect(() => {
        dispatch(fetchVehicleTypes());
    }, [dispatch]);

    const onSubmitAction = (values: IBaseInformationValues) => {
        const basicInfoPayload: IVehicleBasicInformation = {
            registrationNumber: values.registrationNumber ? values.registrationNumber : '',
            vehicleDefinitionId: values.vehicleDefinitionId,
            make: values.make,
            model: values.model,
            vehicleType: values.vehicleType,
            vehicleTypeId: values.vehicleTypeId,
            productionYear: values.productionYear,
            colorId: values.colorId ? values.colorId : '',
            packageSizes: {
                s: values.s,
                m: values.m,
                l: values.l,
            },
        };
        dispatch(setBasicInformation(basicInfoPayload));
        dispatch(setCurrentStep(VehicleOperationStep.INSPECTION));
    };

    return (
        <React.Fragment key={'basic-information-wrapper-react-fragment'}>
            <CardContent>
                <Grid item xs={12} md={7}>
                    <FormikForm
                        fields={basicInformationFormConfig(getData, vehicleColorsOptions)}
                        formId="basic-information-form"
                        initialValues={initialValues}
                        submitAllowed={setSubmitAllowed}
                        theme={theme}
                        shouldApplyCustomValidation={true}
                        onSubmit={onSubmitAction}
                    />
                </Grid>
            </CardContent>
            <Divider />
            <CardActions className="card-actions-button-wrapper">
                <Button
                    variant="contained"
                    color="primary"
                    form="basic-information-form"
                    className="vehicle-nav-next-button"
                    type="submit"
                    size="medium"
                    disabled={!submitAllowed}
                    endIcon={<ChevronRight />}>
                    <Translation text={`vehicles.vehicleOperation.buttons.next`} />
                </Button>
            </CardActions>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
        </React.Fragment>
    );
};

export default BasicInformation;
