import {InputAdornment, TextField} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {MagnifyingGlassIcon} from 'palipali-panel-common-web';
import {IChangeSearchFilter, changeCourierSearchFilter} from 'src/store/reducers/courierListingSlice';
import {courierListingSearchSelector} from 'src/store/selectors/courierListingSelector';

const CourierSearchInput: React.FC = () => {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null),
        initSearchValue = useSelector(courierListingSearchSelector),
        dispatch = useDispatch(),
        {t} = useTranslation();

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (timeoutId) clearTimeout(timeoutId);

        const newTimeoutId = setTimeout(() => {
            const searchValue = e.target.value;
            const filterInput: IChangeSearchFilter = {
                search: searchValue,
            };
            dispatch(changeCourierSearchFilter(filterInput));
        }, 500);
        setTimeoutId(newTimeoutId);
    };
    const searchLabel = t('couriers.filters.search');
    return (
        <TextField
            fullWidth
            variant="outlined"
            label={searchLabel}
            className="search courier-search-input-container"
            onChange={(e) => {
                handleSearchChange(e);
            }}
            defaultValue={initSearchValue}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <MagnifyingGlassIcon />
                    </InputAdornment>
                ),
                className: 'courier-search-wrapper',
            }}
            inputProps={{
                className: 'courier-search-input',
            }}
        />
    );
};

export default CourierSearchInput;
