import {SVGProps} from 'react';
import React from 'react';

const StarEmptyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
        <g id="star-01">
            <path
                id="Icon"
                d="M15.0429 4.6041C15.3502 3.98151 15.5039 3.67022 15.7125 3.57076C15.8939 3.48423 16.1048 3.48423 16.2863 3.57076C16.4949 3.67022 16.6485 3.98151 16.9559 4.6041L19.8714 10.5107C19.9622 10.6945 20.0075 10.7864 20.0738 10.8578C20.1325 10.921 20.2029 10.9721 20.2811 11.0085C20.3694 11.0496 20.4708 11.0644 20.6736 11.094L27.1953 12.0473C27.8821 12.1477 28.2255 12.1979 28.3844 12.3656C28.5227 12.5115 28.5877 12.7121 28.5614 12.9114C28.5311 13.1404 28.2825 13.3826 27.7853 13.8669L23.0679 18.4616C22.9209 18.6048 22.8474 18.6764 22.7999 18.7616C22.7579 18.8371 22.731 18.92 22.7206 19.0057C22.7088 19.1025 22.7262 19.2037 22.7609 19.406L23.874 25.8958C23.9914 26.5803 24.0501 26.9226 23.9397 27.1257C23.8438 27.3024 23.6732 27.4263 23.4754 27.463C23.2482 27.5051 22.9408 27.3435 22.3262 27.0202L16.4959 23.9541C16.3142 23.8586 16.2234 23.8108 16.1277 23.7921C16.0429 23.7754 15.9558 23.7754 15.8711 23.7921C15.7754 23.8108 15.6845 23.8586 15.5029 23.9541L9.67258 27.0202C9.0579 27.3435 8.75056 27.5051 8.52331 27.463C8.32559 27.4263 8.15498 27.3024 8.059 27.1257C7.94868 26.9226 8.00738 26.5803 8.12478 25.8958L9.23786 19.406C9.27256 19.2037 9.28992 19.1025 9.27818 19.0057C9.26778 18.92 9.24083 18.8371 9.19882 18.7616C9.15138 18.6764 9.07785 18.6048 8.9308 18.4616L4.21343 13.8668C3.71623 13.3826 3.46764 13.1404 3.43738 12.9114C3.41106 12.7121 3.47609 12.5115 3.61435 12.3656C3.77326 12.1979 4.11664 12.1477 4.80341 12.0473L11.3251 11.094C11.5279 11.0644 11.6293 11.0496 11.7176 11.0085C11.7958 10.9721 11.8662 10.921 11.9249 10.8578C11.9912 10.7864 12.0366 10.6945 12.1273 10.5107L15.0429 4.6041Z"
                stroke="#FEA100"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
export default StarEmptyIcon;
