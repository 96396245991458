import {Button, Stack, Typography} from '@mui/material';
import {
    MemberOperationType,
    Translation,
    accountIdSelector,
    closeModal,
    deleteAccount,
    openModal,
    HorizontalCard,
    isModalOpenSelector,
    currentlySelectedModalTypeSelector,
    checkCanDeleteAccount,
    canDeleteAccountSelector,
    isNotNullOrUndefined,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DeleteProfileModal from './DeleteProfileModal';

const ProfileDeleteCard: React.FC = () => {
    const dispatch = useDispatch(),
        accountId: string = useSelector(accountIdSelector),
        isModalOpen = useSelector(isModalOpenSelector),
        openedModal = useSelector(currentlySelectedModalTypeSelector),
        canBeDeleted: boolean = useSelector(canDeleteAccountSelector),
        [deleteAllowed, setDeleteAllowed] = React.useState<boolean>(false);

    const handleConfirm = () => {
        dispatch(deleteAccount(accountId));
        dispatch(closeModal());
    };

    useEffect(() => {
        dispatch(checkCanDeleteAccount(accountId));
    }, []);

    useEffect(() => {
        if (isNotNullOrUndefined(canBeDeleted)) {
            setDeleteAllowed(canBeDeleted);
        }
    }, [canBeDeleted]);

    return (
        <HorizontalCard
            heading={<Translation text="account.deleteProfile.title" />}
            content={
                <Stack spacing={3} sx={{alignItems: 'flex-start'}}>
                    <Typography variant="subtitle1" component="h4" gutterBottom>
                        <Translation text="account.deleteProfile.subtitle" />
                    </Typography>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => dispatch(openModal(MemberOperationType.ACCOUNT_DELETE_ACCOUNT))}
                        size="medium"
                        disabled={!deleteAllowed}
                        className="account-delete-account-toggle-modal-button">
                        <Translation text="account.deleteProfile.buttons.deleteAccount" />
                    </Button>
                    {isModalOpen && openedModal === MemberOperationType.ACCOUNT_DELETE_ACCOUNT && (
                        <DeleteProfileModal handleConfirm={handleConfirm} />
                    )}
                </Stack>
            }
        />
    );
};

export default ProfileDeleteCard;
