import {
    AlertType,
    IFleetPartnerCourierListingOutput,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
    ListSuccessActionsFunction,
    addAlert,
    createFetchListEpic,
    flattenObj,
    getFleetPartnerActiveCouriersListAPI,
    handleApiError,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {
    getFleetPartnerActiveCourierListing,
    setError,
    setFleetPartnerActiveCourierListing,
    setLoading,
    setMetadata,
} from '../reducers/fleetPartnerActiveCourierListingSlice';
import {RootState} from '../reducers';
import {activeCourierFiltersSelector} from '../selectors/fleetPartnerActiveCourierListingSelector';

const vehicleListingSuccessActions: ListSuccessActionsFunction<IFleetPartnerCourierListingOutput> = (
    courierArray: IFleetPartnerCourierListingOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setFleetPartnerActiveCourierListing({activeCourierList: courierArray}), setMetadata({metadata: metadata}), setLoading(false)];
};

const getParameters = (state: RootState): IRawRestQueryParams => {
    const filters = activeCourierFiltersSelector(state);
    const params = {
        ...filters,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const fleetPartnerActiveCourierListingEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getFleetPartnerActiveCouriersListAPI,
    vehicleListingSuccessActions,
    errorActions,
    getFleetPartnerActiveCourierListing().type,
    (state: RootState) => getParameters(state)
);

const fleetPartnerActiveCouriersEpic = combineEpics(fleetPartnerActiveCourierListingEpic);

export default fleetPartnerActiveCouriersEpic;
