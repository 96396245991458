import type {SVGProps} from 'react';
import React from 'react';

const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={18} height={18} fill="none" {...props}>
        <g id="document">
            <path
                id="DocumentIcon"
                d="M12.75 7.98214H10.875M10.875 10.3036H12.75M7.875 7.5H5.25V10.875H7.875V7.5ZM2.25 6.72857L2.25 12.0214C2.25 12.8015 2.25 13.1915 2.39714 13.4895C2.52657 13.7516 2.73309 13.9646 2.98711 14.0982C3.27589 14.25 3.65393 14.25 4.41 14.25L13.59 14.25C14.3461 14.25 14.7241 14.25 15.0129 14.0982C15.2669 13.9647 15.4734 13.7516 15.6029 13.4895C15.75 13.1915 15.75 12.8015 15.75 12.0214V6.72857C15.75 5.9485 15.75 5.55846 15.6029 5.26051C15.4734 4.99843 15.2669 4.78535 15.0129 4.65181C14.7241 4.5 14.3461 4.5 13.59 4.5L4.41 4.5C3.65393 4.5 3.27589 4.5 2.98711 4.65181C2.73309 4.78535 2.52657 4.99843 2.39714 5.26051C2.25 5.55846 2.25 5.9485 2.25 6.72857Z"
                stroke="#6C737F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
export default DocumentIcon;
