import React, {useEffect} from 'react';
import classnames from 'classnames';
import 'animate.css/animate.css';
import {connect} from 'react-redux';
import {CommonRootState} from '../../store/reducers';
import {changeMenuHidden} from '../../store/reducers/sagaSlice';
import {contentWidthSelector, menuHiddenSelector, routerTransitionSelector} from '../../store/selectors/sagaSelectors';
import {ILayoutWrapperProps} from '../../types';

type Props = ILayoutWrapperProps;

const LayoutWrapper: React.FC<Props> = ({
    menuHidden,
    routerTransition,
    contentWidth,
    changeMenuHidden,
    layout,
    children,
    contentClass,
    appLayout,
    wrapperClass,
}) => {
    useEffect(() => {
        if (!menuHidden && window.innerWidth <= 768) {
            changeMenuHidden(true);
        }
    }, [menuHidden]);

    const defaultLayout = layout ? layout : 'VerticalLayout';

    return (
        <div className={classnames('app-content content overflow-hidden', wrapperClass ? {[wrapperClass]: wrapperClass} : {})}>
            <div className="content-overlay" />
            <section
                className={classnames(
                    {
                        'content-wrapper flex-grow-1': !appLayout,
                        'content-area-wrapper': appLayout,
                        'container-xxl p-0': contentWidth === 'boxed',
                    },
                    routerTransition
                        ? {
                              [`animate__animated animate__${routerTransition}`]: routerTransition !== 'none' && routerTransition.length,
                          }
                        : {}
                )}>
                <div
                    className={classnames(
                        contentClass ? {[contentClass]: contentClass} : {},
                        defaultLayout === 'HorizontalLayout' && !appLayout ? {'content-body': !appLayout} : {}
                    )}>
                    {children}
                </div>
            </section>
        </div>
    );
};

export default connect(
    (state: CommonRootState) => ({
        menuHidden: menuHiddenSelector(state),
        contentWidth: contentWidthSelector(state),
        routerTransition: routerTransitionSelector(state),
    }),
    {
        changeMenuHidden,
    }
)(LayoutWrapper);
