import {createContext, MouseEvent} from 'react';
const noop = () => null;

interface ContextValue {
    anchorEl: HTMLElement | null;
    onMenuEnter: (event: MouseEvent<HTMLElement>) => void;
    onMenuLeave: (event: MouseEvent<HTMLElement>) => void;
    onTriggerEnter: (event: MouseEvent<HTMLElement>) => void;
    onTriggerLeave: (event: MouseEvent<HTMLElement>) => void;
    open: boolean;
}

const DropdownContext = createContext<ContextValue>({
    anchorEl: null,
    onMenuEnter: noop,
    onMenuLeave: noop,
    onTriggerEnter: noop,
    onTriggerLeave: noop,
    open: false,
});

export default DropdownContext;
