import {Observable} from 'rxjs';
import {ICreateVehicleInput, IVehicleDetailsOutput} from '../../model/vehicle';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const createVehicleAPI: ApiOperationResponseFunction<IVehicleDetailsOutput> = (
    authToken: string,
    vehicle: ICreateVehicleInput
): Observable<IApiSingleResponseBase<IVehicleDetailsOutput>> => {
    return palipaliAPI.post(`api/vehicles`, vehicle, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
