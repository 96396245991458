import {Observable} from 'rxjs';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const deleteJobAPI: ApiOperationResponseFunction<null> = (
    authToken: string,
    payload: {id: string}
): Observable<IApiSingleResponseBase<null>> => {
    const id: string = payload.id;
    return palipaliAPI.delete(`api/jobs/${id}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
