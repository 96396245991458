import React from 'react';

interface ISizeComponentProps {
    readonly size: string;
}

const SizeComponent: React.FC<ISizeComponentProps> = ({size}) => {
    switch (size) {
        case 's':
            return <span className="package-size-icon small">{size}</span>;
        case 'l':
            return <span className="package-size-icon large">{size}</span>;
        case 'm':
            return <span className="package-size-icon medium">{size}</span>;
        default:
            return null;
    }
};

export default SizeComponent;
