import React from 'react';
import {TileLayer} from 'react-leaflet';
// import {useTheme} from '@mui/material';

interface ICustomTileLayer {}

// export const MapTileURL = {
//     DARK: `https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}.png`,
//     LIGHT: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}.png`,
// };

const CustomTileLayer: React.FC<ICustomTileLayer> = () => {
    // const theme = useTheme();
    // const url: string = theme.palette.mode === 'dark' ? MapTileURL.DARK : MapTileURL.LIGHT;
    const url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    return (
        <TileLayer
            url={url}
            attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
    );
};

export default CustomTileLayer;
