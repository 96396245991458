import {
    AlertType,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
    IVehicleListingOutput,
    ListSuccessActionsFunction,
    addAlert,
    createFetchListEpic,
    flattenObj,
    getVehicleMineListAPI,
    handleApiError,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {RootState} from '../reducers';
import {
    VehicleListingFilter,
    changeVehicleListingPagination,
    changeVehicleListingSearchPhrase,
    changeVehicleListingVerifiedFilter,
    getVehicleListing,
    setError,
    setLoading,
    setMetadata,
    setVehicleListing,
} from '../reducers/vehicleListingSlice';
import {
    vehicleListingFilterSelector,
    vehicleListingPaginationSelector,
    vehicleListingSearchParamsSelector,
} from '../selectors/vehicleListingSelector';

const vehicleListingSuccessActions: ListSuccessActionsFunction<IVehicleListingOutput> = (
    vehiclesArray: IVehicleListingOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setVehicleListing({vehicleList: vehiclesArray}), setMetadata({metadata: metadata}), setLoading(false)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const vehicleListingEpic = createFetchListEpic<IVehicleListingOutput>(
    getVehicleMineListAPI,
    vehicleListingSuccessActions,
    errorActions,
    getVehicleListing().type,
    (state: RootState) => getParameters(state)
);

const getParameters = (state: RootState): IRawRestQueryParams => {
    const pagination = vehicleListingPaginationSelector(state),
        searchParams = vehicleListingSearchParamsSelector(state),
        filter = vehicleListingFilterSelector(state),
        filterParam = filter && filter !== VehicleListingFilter.ALL ? {verified: filter} : null,
        searchParam = searchParams ? {search: searchParams} : null;

    const params = {
        ...pagination,
        ...searchParam,
        ...filterParam,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const changePaginationEpic = createFetchListEpic<IVehicleListingOutput>(
    getVehicleMineListAPI,
    vehicleListingSuccessActions,
    errorActions,
    changeVehicleListingPagination().type,
    (state: RootState) => getParameters(state)
);

const changeFiltersEpic = createFetchListEpic<IVehicleListingOutput>(
    getVehicleMineListAPI,
    vehicleListingSuccessActions,
    errorActions,
    changeVehicleListingVerifiedFilter().type,
    (state: RootState) => getParameters(state)
);

const changeSearchEpic = createFetchListEpic<IVehicleListingOutput>(
    getVehicleMineListAPI,
    vehicleListingSuccessActions,
    errorActions,
    changeVehicleListingSearchPhrase().type,
    (state: RootState) => getParameters(state)
);

const vehiclesEpic = combineEpics(vehicleListingEpic, changePaginationEpic, changeFiltersEpic, changeSearchEpic);

export default vehiclesEpic;
