import {InputAdornment, TextField} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {MagnifyingGlassIcon} from 'palipali-panel-common-web';
import {changeVehicleListingSearchPhrase} from 'src/store/reducers/vehicleListingSlice';
import {vehicleListingSearchParamsSelector} from 'src/store/selectors/vehicleListingSelector';

const VehicleSearchInput: React.FC = () => {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null),
        initSearchValue = useSelector(vehicleListingSearchParamsSelector),
        dispatch = useDispatch(),
        {t} = useTranslation();
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (timeoutId) clearTimeout(timeoutId);

        const newTimeoutId = setTimeout(() => {
            dispatch(changeVehicleListingSearchPhrase({search: e.target.value}));
        }, 500);
        setTimeoutId(newTimeoutId);
    };
    const searchLabel = t('vehicles.vehicleList.table.searchInputLabel');
    return (
        <TextField
            fullWidth
            variant="outlined"
            label={searchLabel}
            defaultValue={initSearchValue}
            className="search vehicle-search-input"
            onChange={(e) => {
                handleSearchChange(e);
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <MagnifyingGlassIcon />
                    </InputAdornment>
                ),
                className: 'vehicle-search-input-wrapper',
            }}
            inputProps={{
                className: 'vehicle-list-filters-vehicle-search-input',
            }}
        />
    );
};

export default VehicleSearchInput;
