import {Box, Container} from '@mui/material';
import {ThemeProvider, styled} from '@mui/material/styles';
import React, {useEffect} from 'react';
import {renderToString} from 'react-dom/server';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation, Navigate} from 'react-router-dom';
import {fetchSystemParameters} from '../../store/reducers/systemParametersSlice';
import {isAuthPageLoadingSelector} from '../../store/selectors/authSelectors';
import {systemParametersInitializedSelector} from '../../store/selectors/systemParametersSelectors';
import {IAuthPanelProps, UserRole} from '../../types';
import Toast from '../Toast';
import AuthBackground from './AuthBackground/authBackground';
import AuthHeader from './AuthHeader';
import ConfirmRegistration from './ConfirmRegistration';
import ConfirmTeamInvitation from './ConfirmTeamInvitation';
import Login from './Login';
import NewPassword from './NewPassword';
import Registration from './Registration';
import ResetPassword from './ResetPassword';
import {courierRoutes} from './courierRouting';
import {loginWithToken} from '../../store/reducers/loginSlice';
import ConfirmEmailChange from './ConfimEmailChange';

const AuthPanel: React.FC<IAuthPanelProps> = ({userRole, theme, envData}) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(isAuthPageLoadingSelector),
        systemParametersInitialized = useSelector(systemParametersInitializedSelector),
        location = useLocation();

    useEffect(() => {
        if (systemParametersInitialized === false) {
            dispatch(fetchSystemParameters());
        }
    }, [systemParametersInitialized]);

    const setAuthToken = (token: string | null) => {
        if (!token) {
            return;
        }

        dispatch(loginWithToken(token, userRole));
    };
    const backgroundImage = `url("data:image/svg+xml;utf8,${encodeURIComponent(renderToString(<AuthBackground />))}")`;
    const LayoutRoot = styled('main')(() => ({
        backgroundColor: '#FFF',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: backgroundImage,
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        minHeight: '100vh',
    }));

    useEffect(() => {
        const isProduction = process.env.REACT_APP_ENV === 'prod';

        if (!isProduction) {
            if (location && location.search) {
                const params = new URLSearchParams(location.search);

                if (params.has('token')) {
                    setAuthToken(params.get('token') as string);
                }
            }
        }
    }, [location]);
    return (
        <ThemeProvider key="provider-wrapper" theme={theme}>
            <LayoutRoot>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flex: '1 1 auto',
                    }}>
                    <Container
                        maxWidth="sm"
                        sx={{
                            py: {
                                xs: '60px',
                                md: '120px',
                            },
                        }}>
                        <AuthHeader key="auth-header" userRole={userRole} />
                        {userRole === UserRole.COURIER ? (
                            courierRoutes(envData, isLoading, theme)
                        ) : (
                            <Routes key="basic-routes">
                                <Route key="login-route" path="/login" element={<Login userRole={userRole} theme={theme} key="login" />} />

                                <Route
                                    key="registration-route"
                                    path="/register"
                                    element={<Registration userRole={userRole} envData={envData} theme={theme} key={'registration'} />}
                                />

                                <Route
                                    key="registration-with-invitation-route"
                                    path="/register/:invitationToken"
                                    element={
                                        <Registration
                                            userRole={userRole}
                                            envData={envData}
                                            theme={theme}
                                            key={'registration-with-invitation'}
                                        />
                                    }
                                />

                                <Route
                                    key="reset-password-route"
                                    path="/reset-password"
                                    element={
                                        <ResetPassword
                                            userRole={userRole}
                                            envData={envData}
                                            isLoading={isLoading}
                                            theme={theme}
                                            key="reset-password"
                                        />
                                    }
                                />
                                <Route
                                    key="confirm-email-change"
                                    path="/confirm-email/:id"
                                    element={<ConfirmEmailChange isLoading={isLoading} userRole={userRole} theme={theme} />}
                                />

                                <Route
                                    key="confirm-registration-route"
                                    path="/confirm-registration/:id"
                                    element={<ConfirmRegistration isLoading={isLoading} userRole={userRole} key="confirm-registration" />}
                                />
                                <Route
                                    key="confirm-team-member-invitation-route"
                                    path="/confirm-team-member-invitation/:id"
                                    element={
                                        <ConfirmTeamInvitation
                                            isLoading={isLoading}
                                            userRole={userRole}
                                            theme={theme}
                                            key="confirm-team-member-invitation"
                                        />
                                    }
                                />

                                <Route
                                    key="new-password-route"
                                    path="/new-password/:id"
                                    element={<NewPassword isLoading={isLoading} theme={theme} userRole={userRole} key="new-password" />}
                                />

                                <Route key="not-found-route" path="*" element={<Navigate to="/auth/login" replace />} />
                            </Routes>
                        )}
                    </Container>
                </Box>
                <Toast />
            </LayoutRoot>
        </ThemeProvider>
    );
};

export default AuthPanel;
