import React from 'react';
import {Typography} from '@mui/material';
import {IMoneyOutput} from '../../model/common';
import Price from '../Price';
import Translation from '../Translation';

interface IJobPriceProps {
    readonly price: IMoneyOutput;
    readonly toFixed?: number;
    readonly isGrossPrice?: boolean;
}

const JobPrice: React.FC<IJobPriceProps> = ({price, toFixed, isGrossPrice}) => {
    return (
        <Typography
            component="span"
            variant="inherit"
            color="text.primary"
            className={` ${price.amount !== '0' ? 'package-price-color' : 'package-price'}`}>
            <Price price={price} toFixed={toFixed} />
            {isGrossPrice ? (
                <span className="gross-price">
                    <Translation text={'general.grossPrice'} />
                </span>
            ) : null}
        </Typography>
    );
};

export default JobPrice;
