import React from 'react';
import Moment from 'react-moment';
import {IDateComponentProps} from '../../types';
import {activeLanguageSelector, DateFormat} from '../..';
import {connect} from 'react-redux';

type Props = IDateComponentProps;

const DateComponent: React.FC<Props> = ({date, activeLanguage, interval, locale, format}) => {
    return (
        <Moment
            interval={interval ? interval : 0}
            locale={locale ? locale : activeLanguage}
            format={format ? format : DateFormat.DateDefaultDisplay}>
            {date}
        </Moment>
    );
};

export default connect(
    (state: any) => ({
        activeLanguage: activeLanguageSelector(state),
    }),
    {}
)(DateComponent);
