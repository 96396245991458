import {Button} from '@mui/material';
import {Translation} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {VehicleListingFilter, changeVehicleListingVerifiedFilter} from 'src/store/reducers/vehicleListingSlice';
import {vehicleListingFilterSelector} from 'src/store/selectors/vehicleListingSelector';

const VehicleFilterWrapper: React.FC = () => {
    const vehicleListFilter = useSelector(vehicleListingFilterSelector),
        dispatch = useDispatch();
    const enumValuesArray: string[] = Object.values(VehicleListingFilter);

    const filterButton = (filter: string) => {
        const isActive = vehicleListFilter === filter;
        return (
            <Button
                variant="outlined"
                key={`vehicle_filter_button_${filter}`}
                href="#"
                className={`tab-filter ${isActive ? 'active' : ''} vehicles-vehicle-list-filter-${filter}}`}
                onClick={() => dispatch(changeVehicleListingVerifiedFilter({verified: filter}))}>
                <Translation text={`vehicles.vehicleList.table.filterButtons.${filter}`} />
            </Button>
        );
    };

    return (
        <>
            {enumValuesArray.map((filter) => {
                return filterButton(filter);
            })}
        </>
    );
};

export default VehicleFilterWrapper;
