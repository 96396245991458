import {WithTranslation} from 'react-i18next';
import {FormControlType, InputType, MultiselectMenuPlacement, MultiSelectType} from './components/FormControl';
import {FormButtonDisabledStrategy, FormButtonType} from './components/FormControl/FormButton';
import {addAlert, AlertObject, removeAlert} from './store/reducers/alertSlice';
import {ReactElement, ReactNode} from 'react';
import {LanguageLocale} from './constants/locales';
import {MultiValue, SingleValue} from 'react-select';
import {IAccountBasicInfoOutput, IAccountPublicOutput} from './model/account';
import {changeMenuHidden, ContentWidth, RouterTransition} from './store/reducers/sagaSlice';
import * as React from 'react';
import {IGeoPointOutput, IMoneyOutput, IPhoneOutput} from './model/common';
import {ITranslationName} from './model/dictionaryDatum';
import {Theme} from '@mui/material';
import {IModelApiObject} from './model/base';
import {IVehicleDetailsOutput} from './model/vehicle';
import {IJobRecipientInput, IJobRecipientOutput, IJobTeamOutput, IJobCourierOutput} from './model/jobListing';
import {ITeamMemberOutput, ITeamVerificationOutput} from './model/team';
import {IPaymentMethodOutput} from './model/payment';
import {IGoogleAddressParts} from './model/location';

export enum AuthType {
    LOGIN = 'login',
    REGISTER = 'register',
    NEW_PASSWORD = 'new_password',
    CHANGE_PASSWORD = 'change_password',
    RESET_PASSWORD = 'reset_password',
    ACCEPT_INVITATION = 'accept_invitation',
    CONFIRM_REGISTRATION = 'confirm_registration',
}

export enum LoaderType {
    Local = 'local',
    Global = 'global',
}

export enum AlertType {
    INFO = 'info',
    WARNING = 'warning',
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum CustomCardType {
    MODAL_CARD = 'modal-card',
    BASIC = 'basic',
}

export enum FormControlChangeType {
    Init = 'init', // when setting initial value
    Internal = 'internal', // when internal program changes change the value
    User = 'user', // when user (UI) changes the value
}

export enum CurrencyType {
    EURO = 'EUR',
    DOLLAR = 'DOL',
    PLN = 'PLN',
}

export enum Locale {
    LOCALE_EN = 'en_US',
    LOCALE_PL = 'pl_PL',
    LOCALE_UA = 'uk_UA',
}

export enum ShortLocale {
    LOCALE_EN = 'en',
    LOCALE_PL = 'pl',
    LOCALE_UA = 'uk',
}

export enum UserRole {
    USER = 'ROLE_USER',
    COURIER_STANDALONE = 'ROLE_COURIER_STANDALONE',
    COURIER_FLEET_CARRIER = 'ROLE_COURIER_FLEET_CARRIER',
    COURIER_FLEET_PARTNER = 'ROLE_COURIER_FLEET_PARTNER',
    PURCHASER = 'ROLE_PURCHASER',
    ADMIN = 'ROLE_ADMIN',
    SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    PURCHASER_TEAM_EMPLOYEE = 'ROLE_PURCHASER_TEAM_EMPLOYEE',
    PURCHASER_TEAM_MANAGER = 'ROLE_PURCHASER_TEAM_MANAGER',
    PURCHASER_TEAM_OWNER = 'ROLE_PURCHASER_TEAM_OWNER',
    COURIER = 'ROLE_COURIER',
    ROLE_FLEET_PARTNER = 'ROLE_FLEET_PARTNER',
}

export enum JobStatus {
    CANCELLED_P = 'cancelled:p',
    CANCELLED_C = 'cancelled:c',
    PROBLEM_C = 'problem:c',
    PROBLEM_P = 'problem:p',
    COMPLETED = 'delivered',
    PLANNED = 'planned',
    SEARCHING = 'searching',
    PICKUP_DRIVE = 'pickup:drive',
    PICKUP = 'pickup',
    DELIVERY_DRIVE = 'delivery:drive',
    DELIVERY = 'delivery',
    FAILED = 'failed',
}

export type ILoginApiResponse = {
    token: string | null;
    refresh_token: string | null;
};

export type IAuthToken = {
    teams?: {
        [key: string]: UserRole.PURCHASER_TEAM_EMPLOYEE | UserRole.PURCHASER_TEAM_MANAGER | UserRole.PURCHASER_TEAM_OWNER;
    };
} & IDecodedToken<UserRole>;

export type IDecodedToken<T> = {
    account_id: string;
    exp: number;
    iat: number;
    is_refresh_token: false;
    roles: UserRole[];
    user_id: string;
    username: string;
};

export interface IApiError extends Partial<Error> {
    message: string;
    name?: string;
    response?: {
        xhr: any;
        '@context': string;
        '@type': string;
        'hydra:title': string;
        'hydra:description': string;
        'i18n:messageCode': string;
        'i18n:messageParams': {[key: string]: string};
        violations: [
            {
                propertyPath: string;
                message: string;
                code: number | null;
            }
        ];
        code?: string;
        message?: string;
    };
    type?: AlertType;
}

export type IAvatarInput = {
    mediaObjectId: string; // id MediaObject
};

export type IPaymentAccount = {
    id: string;
    vendorPaymentAccounts: IVendorPaymentAccount[];
};

export type IVendorPaymentAccount = {
    id: string;
    paymentMethods: IPaymentMethod[];
};

export type IPaymentMethod = {
    id: string;
    cardNumber: string | null;
    cardHolderName: string | null;
    expiryDate: string | null;
    cvc: string | null;
};

export type IPaymentAccountOutput = {
    id: string; // id PaymentAccount
    vendorPaymentAccounts: IVendorPaymentAccount[];
};

export enum PaymentServiceName {
    STRIPE = 'stripe',
}

export type IDetachStripePaymentMethodInput = {
    systemPaymentMethodId: string;
};

export enum TooltipPlacement {
    AUTO = 'auto',
    AUTO_START = 'auto-start',
    AUTO_END = 'auto-end',
    TOP = 'top',
    TOP_START = 'top-start',
    TOP_END = 'top-end',
    RIGHT = 'right',
    RIGHT_START = 'right-start',
    RIGHT_END = 'right-end',
    BOTTOM = 'bottom',
    BOTTOM_START = 'bottom-start',
    BOTTOM_END = 'bottom-end',
    LEFT = 'left',
    LEFT_START = 'left-start',
    LEFT_END = 'left-end',
}

export enum DateRangePickerPlacement {
    TOP = 'top',
    BOTTOM = 'bottom',
    RIGHT = 'right',
    LEFT = 'left',
    BOTTOM_START = 'bottomStart',
    BOTTOM_END = 'bottomEnd',
    TOP_START = 'topStart',
    TOP_END = 'topEnd',
    LEFT_START = 'leftStart',
    RIGHT_START = 'rightStart',
    LEFT_END = 'leftEnd',
    RIGHT_END = 'rightEnd',
    AUTO = 'auto',
    AUTO_VERTICAL = 'autoVertical',
    AUTO_VERTICAL_START = 'autoVerticalStart',
    AUTO_VERTICAL_END = 'autoVerticalEnd',
    AUTO_HORIZONTAL = 'autoHorizontal',
    AUTO_HORIZONTAL_START = 'autoHorizontalStart',
    AUTO_HORIZONTAL_END = 'autoHorizontalEnd',
}

export enum ReactstrapColors {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
}

export enum AvatarSize {
    SM = 'sm',
    LG = 'lg',
    XL = 'xl',
}

export enum AvatarStatus {
    ONLINE = 'online',
    OFFLINE = 'offline',
    AWAY = 'away',
    BUSY = 'busy',
}

export enum TimeSlotsType {
    CALENDAR = 'calendar',
    WEEK_CALENDAR = 'week-calendar',
}

export enum CourierStatus {
    INVITED = 'invited',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DELETED = 'deleted',
}

export enum AvatarColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    INFO = 'info',
    WARNING = 'warning',
    DARK = 'dark',
    LIGHT_PRIMARY = 'light-primary',
    LIGHT_SECONDARY = 'light-secondary',
    LIGHT_SUCCESS = 'light-success',
    LIGHT_DANGER = 'light-danger',
    LIGHT_INFO = 'light-info',
    LIGHT_WARNING = 'light-warning',
    LIGHT_DARK = 'light-dark',
}

export enum BadgeType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    ROLE_PURCHASER_TEAM_EMPLOYEE = 'employee',
    ROLE_PURCHASER_TEAM_MANAGER = 'manager',
    ROLE_PURCHASER_TEAM_OWNER = 'owner',
    ROLE_PURCHASER_TEAM_INVITED = 'invited',
    LOCATION_TYPE_WAREHOUSE = 'warehouse',
    LOCATION_TYPE_SHOP = 'shop',
    LOCATION_TYPE_WHOLESALER = 'wholesaler',
    DELIVERY_SEARCHING = 'searching',
    DELIVERY_PLANNED = 'planned',
    DELIVERY_ONGOING = 'ongoing',
    FILTER_OPTION = 'filterOption',
    TEAM = 'team',
    ORDER_OTHER_CITY = 'otherCity',
}

export type IAvatarProps = {
    img?: any;
    icon?: ReactElement<any>;
    src?: string;
    badgeUp?: boolean;
    content?: string;
    badgeText?: string;
    className?: string;
    imgClassName?: string;
    contentStyles?: {[key: string]: any};
    size?: AvatarSize;
    tag: any;
    status?: AvatarStatus;
    imgHeight?: string | number;
    imgWidth?: string | number;
    badgeColor?: AvatarColor;
    color: AvatarColor;
    initials?: any;
};

export type ISidebarProps = {
    menuCollapsed: boolean;
    currentActiveItem: any;
    skin: string;
    logoImage: string;
    menuData: (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[][];
    setMenuCollapsed?: (isMenuCollapsed: boolean) => void;
    toggleMobileMenu?: () => void;
    isMenuMobile?: boolean;
};

export type ISideMenuGroup = {
    id: string;
    title: string;
    icon: any;
    navLink?: string;
    children?: ISideMenuItem[];
    badge?: string;
    badgeText?: string;
    navLinkClassname?: string;
};

export type ISideMenuItem = {
    id: string;
    title: string;
    icon: any;
    navLink?: string;
    onClick?: () => void;
    badge?: string;
    badgeText?: string;
    navLinkClassname?: string;
};

export type ISideMenuHeader = {
    header: string;
};

export type IVerticalMenuHeaderProps = {
    setGroupOpen: any;
    logoImage: string;
    toggleMobileMenu?: () => void;
};

export type IVerticalNavMenuGroupProps = {
    item: any;
    groupOpen: any;
    activeItem: any;
    parentItem: any;
    groupActive: any;
    currentActiveGroup: any;
    setGroupOpen: any;
    setActiveItem: any;
    setGroupActive: any;
    setCurrentActiveGroup: any;
    currentActiveItem: any;
};

export type IVerticalNavMenuLinkProps = {
    item: any;
    activeItem: any;
    setActiveItem: any;
    currentActiveItem: any;
};

export type IVerticalNavMenuSectionHeaderProps = {
    item: ISideMenuHeader;
};

export type IVerticalNavMenuItemsProps = {
    items: any;
    activeItem: any;
    setActiveItem: any;
    currentActiveItem: any;
    groupOpen: {[key: string]: any}[] | [];
    parentItem: ISideMenuGroup | null;
    groupActive: {[key: string]: any}[] | [];
    currentActiveGroup: {[key: string]: any}[] | [];
    setGroupOpen: any;
    setGroupActive: any;
    setCurrentActiveGroup: any;
    toggleMobileMenu?: () => void;
};

export type IPrice = {
    readonly amount: string;
    readonly currency: ICurrency;
    config?: any;
};

export type ICurrency = {
    readonly name: string;
};

export type ILoaderProps = {
    showLoader: boolean;
    type?: string;
    customClass?: string;
};

export type IToastProps = {
    readonly alerts: AlertObject[];
    readonly alertsDisabled: boolean;
    readonly removeAlert: typeof removeAlert;
    readonly alertType?: AlertType;
} & WithTranslation;

export type IIconItem = {
    label: any;
    icon: any;
};

export type ITranslationProps = {
    readonly text: string;
    readonly config?: {[key: string]: any};
    translationKey?: string;
};

export type IValidationRuleResult = {
    valid: boolean;
    errorMessages: string[];
};

export type IValidationRule = {
    name: string;
    params: {[key: string]: any};
};

export type IValidationConfig = IValidationRule[];

export type IInputControlOptions = {
    value: any;
    displayValue: string;
};

export type InputDataMapper = (data: any, config?: any) => any;

export type OutputDataMapper = (data: any, previousStateSnapshot: any, controlName: string, changeType: FormControlChangeType) => any;

export type ChangeHandler = (e: any) => void;

export type ButtonClickHandler = (key: string, type: FormButtonType, event: any) => void;

export type MultiselectChangeHandler = (values: SingleValue<IMultiselectOption> | MultiValue<IMultiselectOption[]>, action: any) => void;

export type DataAccessor = (data: any, key: string | number) => any;

export type IFormControls = IFormGroupConfig[] | {[key: string]: IFormGroupConfig | IFormControlConfig};

export type IBaseFormConfig = {
    inputDataMapper?: InputDataMapper; // used for mapping input (props) value to form value
    outputDataMapper?: OutputDataMapper; // used for mapping form value to output value
    validationRules?: IValidationConfig;
};

export type IBaseGroupedFormConfig = {
    class: string;
    dataAccessor?: DataAccessor; // used for retrieving control value from group value
    controls: IFormControls;
} & IBaseFormConfig;

export type IFleetPartnerCourierOutput = {
    id: string;
    account: IAccountBasicInfoOutput;
    status: CourierStatus;
};

export type IInviteFleetPartnerCourierInput = {
    email: string;
    returnUrl: string;
};

export type IFormGroupConfig = {
    controlType: 'group' | 'fieldset';
    key: string; // used for identifying group in the model
    fieldsetTitle?: string;
    groupLabel?: string;
} & IBaseGroupedFormConfig;

export type IFormControlConfig = {
    controlType: 'control';
    defaultValue?: any;

    formControlType: FormControlType;
    placeholder?: string;
    label?: string;
    richTextLabel?: string;
    isLabelHidden?: boolean;
    hostClass: string;
    type?: InputType;
    isCurrencyInput?: boolean;
    options?: IInputControlOptions[];
    multiselectOptions?: IMultiselectOption[] | (() => IMultiselectOption[]);
    disabled?: boolean;
    additionalStyles?: any;
    renderPreIcon?: () => any;
    checkboxSlots?: boolean;
    isDateFiltered?: boolean;
    isDayDisabled?: boolean;
    readonly?: boolean;

    multiselectType?: MultiSelectType;
    isCurrencySelect?: boolean;
    isComponentCustom?: boolean;
    isCustomLogoOption?: boolean;
    isCustomMultiValueContainer?: boolean;
    isGroupedComponentCustom?: boolean;
    isGroupedLabelTranslated?: boolean;
    openMenuOnClick?: boolean;
    hideValueOnFocus?: boolean;
    isSearchable?: boolean;
    handleMultiselectInputChange?: any;
    menuPlacement?: MultiselectMenuPlacement;
    firstOptionValue?: MultiSelectType;
    handleChange?: MultiselectChangeHandler;
    cols?: number;
    rows?: number;
    maxDate?: string | Date;
    minDate?: string | Date;
    popperPlacement?: any;
    dateFormat?: string;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    openToDate?: string | Date;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    inline?: boolean;
    selected?: any;
    onChange?: any;
    selectedDate?: Date | null;
    availableDates?: Date[] | null;
    availableTimeSlots?: {[key: string]: any}[] | null;
    checkboxLabel?: string;
    labelStyles?: string;
    isCheckboxLabelRaw?: boolean;
    isCheckboxReversed?: boolean;
    checked?: boolean;
    stripePublicKey?: any;
    stripeAccount?: string;
    tooltipText?: string;
    currency?: CurrencyType;
    recommendationsDates?: Date[] | null;
    eventDates?: Date[] | null;
    buttonName?: string;
    defaultStyles?: string;
    inputStyles?: string;
    wrapperStyles?: string;
    defaultContainerStyles?: string;
    containerStyles?: string;
    btnText?: string;
    customClickHandler?: ButtonClickHandler;
    buttonType?: FormButtonType;
    buttonDisabled?:
        | boolean
        | FormButtonDisabledStrategy
        | ((mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void);
    disabledStyles?: string;
    enabledStyles?: string;
    btnInnerStyles?: string;
    btnPreIconStyles?: string;
    btnPostIconStyles?: string;
    stepValue?: number;
    customNumberIndicator?: string;
    isTranslationComponentCustom?: boolean;
    translationComponents?: React.ReactElement[] | {readonly [tagName: string]: React.ReactElement};

    onDateChange?: any;
    onMonthChange?: any;
    handleRadioChange?: (e: any) => void;

    daysNumberDisplay?: number;
    startDate?: Date;
    availableConsultationSlots?: {[key: string]: any}[] | null;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;

    rangeStep?: number;
    rangeMinValue?: number;
    rangeMaxValue?: number;
    rangeValueUnit?: string;
    autocomplete?: string;
    customIncrementButtons?: boolean;
    step?: number;

    maxValue?: number;
    minValue?: number;

    dateRangePlacement?: any;
    dateRangesConfig?: any[];
    defaultStartValue?: Date;
    defaultEndValue?: Date;

    rangeLabels?: {[key: string]: any};
    labelFormat?: (value: number) => void;
    useWholePathAsName?: boolean;

    onFileChange?: (imageId: string, imageFile: IFileType) => void;
    isFileRemovable?: boolean;
    acceptedFileExtension?: string;

    btnHasTooltip?: boolean;
    btnTooltipText?: string;
    measurementUnit?: string;
    isDisplayValueTranslated?: boolean;
    uploaderInformation?: string;
} & IBaseFormConfig;

export type IGroupedMultiSelectConfig = {
    label: DateRangePickerPlacement;
    options: IMultiselectOption[];
};

export type IMultiselectOption = {
    value: string;
    label: string;
};

export type IRadioOption = {
    value: string;
    label: string;
    additionalValue?: string;
};

export type IGroupedMultiselectOption = {
    label: string;
    options: IMultiselectOption[] | [];
};

export type IBaseFormControlProps = {
    config: IFormConfig | IFormGroupConfig | IFormControlConfig;

    controlName: string;
    value: any;
    key?: string;
    formControlName?: string;

    onValidationStateChange?: (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => void;
    onTouchedStateChange?: (controlName: string, touched: boolean) => void;
    onValueStateChange?: (controlName: string, value: any, changeType: FormControlChangeType) => void;
    onButtonClicked?: ButtonClickHandler;
};

export type IFormConfig = {
    controlType: 'form' | 'fieldset';
    fieldsetTitle?: string;
} & IBaseGroupedFormConfig;

export type IBaseGroupedFormControlProps = {
    config: IFormConfig | IFormGroupConfig;
} & IBaseFormControlProps;

export type IBaseGroupedFormControlState = {
    childValidationState: {[key: string]: boolean};
    mappedOutputValue: {[key: string]: any}; // output
} & IBaseFormControlState;

export type IBaseInternalFormControlProps = {
    handleChange: ChangeHandler;
    handleMultiselectChange?: MultiselectChangeHandler;
    submitTouched: boolean;
    onButtonClicked?: ButtonClickHandler;
    formId: string;
    controlPath: string;
};

export type IBaseFormControlState = {
    valid: boolean;
    touched: boolean;
    value: any; // input
    errorMessages: string[];
};

export type IFormProps = {
    config: IFormConfig;
    submitForm?: (event: any, mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void;
} & IBaseGroupedFormControlProps;

export type IFormState = {
    submitted: boolean;
    formId: string;
} & IBaseGroupedFormControlState;

export type IFormControlsGroupProps = {
    config: IFormGroupConfig;
} & IBaseGroupedFormControlProps &
    IBaseInternalFormControlProps;

export type IFormControlsGroupState = {} & IBaseGroupedFormControlState;

export type IFormControlProps = {
    config: IFormControlConfig;
    controlName: string;
} & IBaseFormControlProps &
    IBaseInternalFormControlProps &
    WithTranslation;

export type IFormControlState = {} & IBaseFormControlState;

export type ICalendarProps = {
    onChange: any;
    selectedDate: any;
    availableDates: Date[] | null;
    availableTimeSlots: {[key: string]: any}[] | null;
    placeholder: string;
    checkboxSlots: boolean;
    isDateFiltered?: boolean;
    isDayDisabled?: boolean;
    isLabelHidden?: boolean;
    maxDate?: string | Date;
};

export type IDatePickerInputProps = {
    value: string;
    name: string;
    id: string;
    placeholderText: string;
    disabledKeyboardNavigation: boolean;
    handleChange: (e: any) => void;
    inputStyles?: string;
    disabled?: boolean;
    maxDate?: string | Date;
    minDate?: string | Date;
    openToDate?: string | Date;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    onChange: (e: any) => void;
    onChangeRaw: (e: any) => void;
    onSelect: (e: any) => void;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    inline?: boolean;
    dateFormat?: string;
    popperPlacement?: any;
};

export type IDateRangePickerProps = {
    onChange: any;
    name: string;
    value?: any;
    placeholder?: string;
    placement?: DateRangePickerPlacement;
    ranges?: any[];
    defaultStartValue?: Date;
    defaultEndValue?: Date;
    defaultValue?: any;
    renderValue?: any;
    allowedMaxDays?: number;
} & WithTranslation;

export type IDateRangePickerState = {
    defaultDateValue: any;
};

export type ICalendarState = {
    availableDates: Date[] | null;
    selectedDay: Date | null;
};

export type ICalendarTimeSlotsProps = {
    onChange: any;
    selectedDate: any;
    availableTimeSlots: {[key: string]: any}[] | null;
    placeholder: string;
    checkboxSlots: boolean;
    isLabelHidden?: boolean;
    expandedSlotsShown?: boolean;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;
};

export type ICalendarTimeSlotsState = {};

export type ICheckboxProps = {
    name: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    labelStyles?: string;
    checked: boolean;
    value?: any;
    disabled?: boolean;
    label?: string;
    isLabelRaw?: boolean;
    isLabelTranslated?: boolean;
    additionalStyles?: any;
    isCheckboxReversed?: boolean;
    isTranslationComponentCustom?: boolean;
    translationComponents?: React.ReactElement[] | {readonly [tagName: string]: React.ReactElement};
};

export type ICheckboxState = {
    checked: boolean;
};

export type IEventCalendarProps = {
    onChange: any;
    selectedDate: Date | null;
    eventDates: any[] | null;
    placeholder: string;
    minDate?: string | Date;
    maxDate?: string | Date;
};

export type IEventCalendarState = {
    availableDates: Date[] | null;
    eventDates: any[] | null;
    selectedDate: Date | null;
    selectedDay: Date | null;
};

export type IFormButtonProps = {
    name: string;
    btnText: string;
    buttonType?: FormButtonType;
    defaultInputStyles?: string;
    inputStyles?: string;
    defaultContainerStyles?: string;
    containerStyles?: string;
    disabled?: boolean | FormButtonDisabledStrategy | ((mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void);
    disabledStyles?: string;
    enabledStyles?: string;
    onButtonClicked?: ButtonClickHandler;
    customClickHandler?: ButtonClickHandler;
    innerStyles?: string;
    preIconStyles?: string;
    postIconStyles?: string;
    btnHasTooltip?: boolean;
    btnTooltipText?: string;
    formId: string;
};

export type IFormButtonState = {
    mappedOutputValue: any;
    isFormValid: boolean;
    isFormTouched: boolean;
    disabled?: boolean;
};

export type IInputProps = {
    value: any;
    name: string;
    type: InputType;
    placeholder: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    disabled?: boolean;
    maxDate?: string | Date;
    minDate?: string | Date;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    step?: number;
    readonly?: boolean;
    currency?: CurrencyType;
    isCurrencyInput?: boolean;
    autocomplete?: string;
    customIncrementButtons?: boolean;
    renderPreIcon?: () => any;
    customNumberIndicator?: string;
};

export type IMultiSelectProps = {
    multiselectType: MultiSelectType;
    handleChange?: MultiselectChangeHandler;
    value?: IMultiselectOption[] | {[key: string]: any};
    options: IMultiselectOption[] | IGroupedMultiSelectConfig[] | (() => IMultiselectOption[] | IGroupedMultiSelectConfig[]);
    name: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    groupedOptions?: IGroupedMultiSelectConfig[];
    menuPlacement?: MultiselectMenuPlacement;
    isCurrencySelect?: boolean;
    control?: IFormControlConfig;
    isComponentCustom?: boolean;
    isCustomLogoOption?: boolean;
    isCustomMultiValueContainer?: boolean;
    isGroupedComponentCustom?: boolean;
    openMenuOnClick?: boolean;
    hideValueOnFocus?: boolean;
    handleInputChange?: any;
    isGroupedLabelTranslated?: boolean;
    isSearchable?: boolean;
} & WithTranslation;

export type IMultiSelectState = {
    value: string | null;
    valueHidden: boolean;
};

export type IRadioProps = {
    value: any;
    name: string;
    placeholder: string;
    handleChange: ChangeHandler;
    options: any[];
    inputStyles?: string;
    wrapperStyles?: string;
    disabled?: boolean;
    isDisplayValueTranslated?: boolean;
};

export type ISelectProps = {
    value: any;
    name: string;
    placeholder: string;
    handleChange: ChangeHandler;
    options: any[] | (() => any[]);
    inputStyles?: string;
    disabled?: boolean;
    firstOptionValue?: string;
};

export type ISwitchProps = {
    name: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    checked: boolean | undefined;
    disabled?: boolean;
};

export type ISwitchState = {
    isChecked: boolean | undefined;
};

export type ITextareaProps = {
    name: string;
    value: string;
    placeholder: string;
    handleChange: ChangeHandler;
    inputStyles: string;
    cols?: number;
    rows?: number;
    minLength?: number;
    maxLength?: number;
    disabled: boolean;
};

export interface IBlockerEvent {
    startsAt: Date;
    endsAt: Date;
    isFree: boolean;
}

export type IWeekCalendarProps = {
    daysNumberDisplay: number;
    startDate?: Date; // string | Date;
    onChange: any;
    availableConsultationSlots: {[key: string]: any} | null;
    selectedDate?: Date;
    placeholder?: string;
    checkboxSlots?: any;
    isLabelHidden?: boolean;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;
};

export type IWeekCalendarState = {
    value: any;
    isLoading: boolean;
    calendarStartDate: Date;
    selectedDatesList: Date[] | null;
    selectedDate: Date | null;
    availableDates: any | null;
    timeSlots: {[key: string]: any}[] | null;
    expandedSlotsShown: boolean;
};

export type IPaginationProps = {
    listMetadata: {[key: string]: any} | null;
    changePage: any;
    itemsPerPage?: number;
    currentPage?: number;
};

export type IRangeControlProps = {
    step: number;
    minValue: number;
    maxValue: number;
    valueUnit: string;
    onChange: any;
    label: string;
    value: any;
    defaultValue?: number[];
};

export type IRangeControlState = {
    values: number[];
};

export type IRangePickerProps = {
    labels: {[key: string]: any};
    onChange: any;
    defaultValue?: number[];
    labelFormat?: (value: number) => void;
    disabled?: boolean;
};

export type IRangePickerState = {
    value: string[] | null;
};

export type IQuantityInputProps = {
    name: string;
    handleChange: any;
    value?: number;
    maxValue?: number;
    minValue?: number;
    inputStyles?: string;
    disabled?: boolean;
    stepValue?: number;
    measurementUnit?: string;
};

export type ITooltipProps = {
    target: string;
    tooltipText: string;
    placement?: TooltipPlacement;
    innerClassName?: string;
};

export type IQuantityInputState = {};

export type LegalRestQueryParamValue = string | number | boolean | null;

export type IRawRestQueryParams = {path: string; val: LegalRestQueryParamValue}[];

export interface IControlConfigUpdateStrategy {
    process(control: IFormControlConfig, value: any, targetValue: any): void;
}

export type IPriceProps = {
    readonly price?: IPrice | null;
    readonly amountComponent?: (amount: string) => ReactNode;
    readonly currencyComponent?: (code: string) => ReactNode;
    readonly separateWithNbsp?: boolean;
    readonly toFixed?: number;
};

export type IItemDescriptionProps = {
    description: string | null;
};

export type IUserDisplayNameProps = {
    account: IAccountBasicInfoOutput;
};

export type IFileType = {
    name?: string;
    fileKey?: number | string;
    blobFile?: File;
    status?: 'inited' | 'uploading' | 'error' | 'finished';
    progress?: number;
    url?: string;
};

export type IFileUploaderProps = {
    readonly authToken: string;
    readonly addAlert: typeof addAlert;
    readonly onImageChange: (imageId: string, imageFile: IFileType) => void;
    readonly label?: string;
    readonly isFileRemovable?: boolean;
    readonly onFileChange?: (file: IFileType[]) => void;
    readonly onFileUpload?: (file: any) => void;
    readonly isDragDropHidden?: boolean;
    readonly onUpload?: () => void;
    readonly acceptedFileExtension?: string;
    readonly uploaderInformation?: string;
};

export type IFileUploaderState = {
    readonly value: any[];
    readonly isProcessing: boolean;
    readonly inputFileUploaded: boolean;
    readonly progressValue: number | null;
};

export type IDateComponentProps = {
    date: string | number | Date | moment.Moment;
    activeLanguage: LanguageLocale;
    interval?: number;
    locale?: string;
    format?: string;
};

export type IWithLocation = {
    location: {
        hash: string;
        key: string;
        pathname: string;
        search: string;
        state: {[key: string]: any} | null;
    };
};

export type IWithNavigate = {
    navigate: (to: string, options?: {replace?: boolean; state?: any}) => void;
};

export type IWithParams = {
    params: {[key: string]: any};
};

export type IMapProps = {
    isMarkerShown: boolean;
    position: {lat: number; lng: number};
    googleMapURL: string;
    loadingElement: ReactElement<any>;
    containerElement: ReactElement<any>;
    mapElement: ReactElement<any>;
};

export type INavBarItem = {
    id: string;
    target: string;
    isBookmarked: boolean;
    title: string;
    icon: string;
    link: string;
};

export type IUserMenuItem = {
    id: string;
    title?: string;
    icon?: string;
    action?: () => void;
    link?: string;
    isDivider?: boolean;
};

export type INavbarNavigationProps = {
    navigationMenu: INavBarItem[];
    isTooltipVisible: boolean;
};

export type INavbarUserProps = {
    account: IAccountBasicInfoOutput | null;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
    isAuthenticated: boolean;
};

export type IUserDropdownProps = {
    account: IAccountBasicInfoOutput | null;
    isAuthenticated: boolean;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
};

export type INavbarProps = {
    navigationMenu: INavBarItem[];
    account: IAccountBasicInfoOutput | null;
    isAuthenticated: boolean;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
};

export type IAccordionItem = {
    title: string;
    description: string;
    icon: string;
};

export type IAccordionComponentProps = {
    items: IAccordionItem[];
};

export type IApiResponseBase<T> = {
    '@id': string;
    '@type': string;
    'hydra:search': any;
    'hydra:totalItems': number;
    'hydra:view': any;
    'hydra:member': T;
    'extra:metadata'?: any;
    '@context'?: IContext;
};

export type IApiResponseBaseWithExtraMetadata<T, K> = {
    'extra:metadata': K;
} & IApiResponseBase<T>;

export type IApiSingleResponseBase<T> = {
    '@id': string;
    '@type': string;
    '@context'?: IContext;
} & T;

type IContext = {
    '@vocab': string;
    contentUrl: 'MediaObjectOutput/contentUrl';
    filePath: 'MediaObjectOutput/filePath';
    hydra: 'http://www.w3.org/ns/hydra/core#';
    id: 'MediaObjectOutput/id';
};

export type IMediaFile = {
    id: string;
    fileUrls: {[key in FileUrlKey]: string};
    contentUrl: string;
};

export enum FileUrlKey {
    LARGE_THUMB = 'largeThumb',
    MEDIUM_THUMB = 'mediumThumb',
    ORIGINAL = 'original',
    SMALL_THUMB = 'smallThumb',
    TINY_THUMB = 'tinyThumb',
}

export enum Currency {
    EURO = 'EUR',
    PLN = 'PLN',
}

export type IModelApiResponseViewObject = {[key: string]: any};

export type IWithRouterWrapperProps = {
    router: {
        params: {[key: string]: any};
        navigate: (to: string, options?: {replace?: boolean; state?: any}) => void;
        location: {
            hash: string;
            key: string;
            pathname: string;
            search: string;
            state: {[key: string]: any} | null;
        };
    };
};

export type IAuthPanelProps = {
    readonly userRole: UserRole;
    readonly envData: any;
    readonly theme: Theme;
};

export type IAuthHeaderProps = {
    readonly userRole: UserRole;
};

export type INameTranslation = {
    readonly en: string;
};

export type IFileUploadListElement = {
    name: string;
    fileKey: string;
    url: string;
};

export interface IBaseAuthPanelComponentProps {
    readonly isLoading: boolean;
    readonly userRole: UserRole;
}

export interface IBaseAuthPanelComponentWithThemeProps extends IBaseAuthPanelComponentProps {
    readonly theme: Theme;
}

export type IIsLoadingComponentProps = {
    readonly isLoading: boolean;
};

export interface ICourierSuccessPageProps extends IIsLoadingComponentProps {
    readonly invitationToFleetPartner?: boolean;
}

export interface ILoginProps {
    readonly userRole: UserRole;
    readonly theme: Theme;
}

// export interface IRegisterProps extends IIsLoadingComponentProps {
export interface IRegisterProps {
    readonly userRole: UserRole;
    readonly envData: any;
    readonly theme: Theme;
}

export interface IResetPasswordProps extends IBaseAuthPanelComponentWithThemeProps {
    readonly envData: any;
}

export type ITabsProps = {
    readonly chosenTab: number;
    readonly selectedTab: any;
    readonly sourceTypes?: any[];
};

export type ILayoutWrapperProps = {
    menuHidden: boolean;
    routerTransition: RouterTransition;
    contentWidth: ContentWidth;
    changeMenuHidden: typeof changeMenuHidden;
    children: any;
    layout?: string;
    appLayout?: boolean;
    wrapperClass?: string;
    contentClass?: string;
};

export type IImageUploaderProps = {
    readonly authToken: string;
    readonly triggerText: string;
    readonly className?: string;
    readonly onImageChange: (imageId: string, imageFile: IFileType) => void;
    readonly selectedImageFile?: any;
};

export type IAuthorizedImageProps = {
    url?: string | null;
    alt?: string;
    default?: string;
};

export type IPurchaserJobListingOutput = {
    id: string;
    toLocation: IJobLocationShortOutput;
    createdAt: string;
    deliveryStatus: string;
    cost: IMoneyOutput;
    time: number | null;
    packageSize: 's' | 'm' | 'l';
    invoiceId: string | null;
    processAt: string | null;
    canDownloadInvoice: boolean;
};

export type IJobLocationShortOutput = {
    id: string;
    locationId: string | null;
    name: string | null;
    companyName: string | null;
    addressLine: string | null;
    flatNumber: string | null;
    closestCityId: string | null;
    point: IGeoPointOutput;
    phone: IPhoneOutput | null;
} & IGoogleAddressParts;

export type ISettlementPeriodOutput = {
    id: string;
    name: string;
    code: string;
};

export interface IPurchaserCurrentJobDetailsOutput extends IPurchaserJobDetailsOutput {
    readonly estimatedTime: number;
    readonly estimatedPickupAt: string;
    readonly estimatedDeliveryAt: string;
    readonly courierJobStartedAt: string | null;
    readonly processAt: string | null;
    readonly deliveryPin: string;
    readonly deliveryTimeExpiresAt: string | null;
    readonly deliveryStartedAt: string | null;
    readonly isPickupAtRiskOfBeingClosed: boolean;
}

export interface IPlacedJobOutput {
    readonly id: string;
    readonly clientSecret?: string | null;
    readonly paymentLockId?: string | null;
}

export interface IJobProblemTypeOutput extends IModelApiObject {
    readonly id: string;
    readonly name: string;
    readonly code: string;
    readonly name_i18n: ITranslationName;
    readonly allowedFor: string[] | null;
}
export interface IJobCancellationReasonOutput extends IModelApiObject {
    readonly id: string;
    readonly name: string;
    readonly code: string;
}

export interface IJobOpinionInput {
    rating: number; // 10-50
}

export type IPurchaserJobDetailsOutput = {
    id: string;
    toLocation: IJobLocationShortOutput;
    createdAt: string;
    deliveryStatus: JobStatus;
    cost: IMoneyOutput;
    costNet: IMoneyOutput;
    costTax: IMoneyOutput;
    time: number;
    packageSize: 's' | 'm' | 'l';
    fromLocation: IJobLocationShortOutput;
    distance: number | null;
    number: string;
    courier: IJobCourierOutput;
    isCompleted: boolean;
    team: IJobTeamOutput;
    recipient: IJobRecipientOutput;
    orderNumber: string | null;
    description: string | null;
    insuranceValue: IMoneyOutput | null;
    creditCardNumber: string;
    courierLocations: IGeoPointOutput[];
    creator: IAccountPublicOutput;
    reportProblemExpiresAt: string | null;
    isPaymentOnHold: boolean;
    pickupAt: string | null;
    deliveryAt: string | null;
    invoiceId: string | null;
    canDownloadInvoice: boolean;
};

export type IVehicleShortOutput = {
    id: string;
    registrationNumber: string;
    make: string | null;
    model: string | null;
    color: IColorOutput;
};

export type IColorOutput = {
    id: string;
    name: string;
};

export type IUpdateJobInput = {
    recipient: IJobRecipientInput;
    orderNumber: string | null;
    description: string | null;
};

export type ICourierJobJobDetailsOutput = {
    readonly id: string;
    readonly fromLocation: IJobLocationShortOutput;
    readonly toLocation: IJobLocationShortOutput;
    readonly createdAt: string;
    readonly deliveryStatus: string;
    readonly packageSize: 's' | 'm' | 'l';
    readonly number: string;
    readonly creator: IAccountPublicOutput;
    readonly recipient: IJobRecipientOutput;
    readonly orderNumber: string | null;
    readonly description: string | null;
    readonly time: number | null;
    readonly distance: number | null;
    readonly courierLocations: IGeoPointOutput[];
    readonly vehicle: IVehicleDetailsOutput;
};

export type IFleetPartnerCourierListingOutput = {
    readonly id: string;
    readonly account: IAccountBasicInfoOutput;
    readonly status: 'invited' | 'active' | 'inactive' | 'deleted';
    readonly assignedVehiclesCount: number;
    readonly lastPosition: IGeoPointOutput | null;
    readonly activeVehicle: IVehicleDetailsOutput | null;
    readonly currentJob: ICourierJobJobDetailsOutput | null;
    readonly canUnassign: boolean | null;
};

export type INoDataInfoProps = {
    readonly text: string;
};

export type ICanOutput = {
    readonly can: boolean;
};

export type ICombinedOutput = {
    readonly id?: string;
    readonly name?: string | null;
    readonly companyName?: string | null;
    readonly clearingEmail?: string | null;
    readonly taxIdentifier?: string | null;
    readonly addressLine?: string | null;
    readonly houseNumber?: string | null;
    readonly flatNumber?: string | null;
    readonly street?: string | null;
    readonly city?: string | null;
    readonly zip?: string | null;
    readonly countryId?: string | null;
    readonly memberCount?: ITeamMemberOutput[];
    readonly verificationStatus?: ITeamVerificationOutput;
    readonly privateLocationsCount?: number;
    readonly defaultPaymentMethod?: IPaymentMethodOutput | null;
    readonly locationId?: string | null;
    readonly closestCityId?: string | null;
    readonly point?: IGeoPointOutput;
};
