import {Box, TextField} from '@mui/material';
import React from 'react';
import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings} from '../../../components/FormikForm';

type InputPropsType = {
    className: string;
    inputMode?: 'numeric' | 'decimal' | 'none' | 'text' | 'tel' | 'url' | 'email' | 'search'; // Add other modes as needed
    pattern?: string;
    maxLength?: number;
};

const TextFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    type,
    formik,
    endAdornment,
    autofocus,
    customHandleChange,
    customDisabled,
    className,
    customEventChange,
    rows,
    maxNumberLength,
    characterLimit,
    lettersOnly,
    isRequired,
}) => {
    const isNumberInput = type === FormikFieldTypes.NUMBER;
    const handleChange = (event) => {
        if (lettersOnly) {
            const {value} = event.target;
            if (!FormikRegexSettings.LETTERS_AND_DASH.test(value) && value !== '') {
                event.preventDefault();
                return;
            }
        }

        if (isNumberInput) {
            event.target.value = event.target.value.replace(/[^0-9]/g, '');
        }
        formik.handleChange(event);

        if (customHandleChange) {
            customHandleChange(event.target.value, formik.setFieldValue);
        }

        if (customEventChange) {
            customEventChange(name, event.target.value);
        }
    };
    let inputProps: InputPropsType = {
        className: `${name}-input ${className || ''}`,
    };
    if (isNumberInput) {
        inputProps = {
            ...inputProps,
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: maxNumberLength,
        };
    }

    return (
        <Box className={characterLimit ? 'textarea-container' : ''} sx={{display: 'flex', flex: '1'}}>
            <TextField
                key={name}
                autoFocus={autofocus || false}
                error={!!(formik.touched[name] && formik.errors[name])}
                fullWidth
                disabled={customDisabled ? customDisabled(formik.values) : false}
                helperText={formik.touched[name] && typeof formik.errors[name] ? (formik.errors[name] as string) : undefined}
                label={label}
                name={name}
                onBlur={formik.handleBlur}
                onChange={handleChange}
                type={'text'}
                value={formik.values[name]}
                className={className || ''}
                inputProps={inputProps}
                InputProps={{
                    endAdornment: endAdornment,
                }}
                rows={rows}
                multiline={rows !== undefined}
                required={isRequired}
            />
            {characterLimit && formik.values[name] ? (
                <Box className="character-limit-container">
                    {formik.values[name].length} / {characterLimit}
                </Box>
            ) : null}
        </Box>
    );
};

export default TextFormControl;
