import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiResponseBase, IRawRestQueryParams} from '../../types';
import {ISystemParameterOutput} from '../../model/systemPatameters';
import {ApiResponseFunction} from '../base/apiConnectionInfrastructure';

export const getSystemParametersAPI: ApiResponseFunction<ISystemParameterOutput> = (
    authToken?: string,
    params?: IRawRestQueryParams | []
): Observable<IApiResponseBase<ISystemParameterOutput[]>> => {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return palipaliAPI.get(`api/system_parameters/public`, new RestQueryParams(params), headers);
};
