import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export const enum ConfirmInvitationStatus {
    CONFIRMED = 'confirmed',
    REJECTED = 'rejected',
    LOADING = 'loading',
    ERROR = 'error',
}

export interface IConfirmInvitationState {
    fleetPartnerInvitationConfirmActionStatus: ConfirmInvitationStatus | null;
    purchaserInvitationConfirmActionStatus: ConfirmInvitationStatus | null;
}

export interface IChangeInvitationStatus {
    readonly confirmActionStatus: ConfirmInvitationStatus | null;
}

export interface IConfirmInvitation {
    readonly authenticationToken: string;
    readonly confirm: boolean;
}

export interface IChangePurchaserConfirmInvitationStatus {
    readonly purchaserInvitationConfirmActionStatus: ConfirmInvitationStatus | null;
}

const initialState: IConfirmInvitationState = {
    fleetPartnerInvitationConfirmActionStatus: null,
    purchaserInvitationConfirmActionStatus: null,
};

const confirmInvitationSlice = createSlice({
    name: 'confirmInvitation',
    initialState: initialState,
    reducers: {
        confirmFleetPartnerInvitation: {
            reducer: (state: IConfirmInvitationState) => {
                return {
                    ...state,
                    fleetPartnerInvitationConfirmActionStatus: ConfirmInvitationStatus.LOADING,
                };
            },
            prepare(authenticationToken: string, isAccepted: boolean) {
                return {
                    payload: {
                        authenticationToken: authenticationToken,
                        confirm: isAccepted,
                    },
                };
            },
        },
        confirmTeamMemberInvitation: {
            reducer: (state: IConfirmInvitationState) => {
                return {
                    ...state,
                    purchaserInvitationConfirmActionStatus: ConfirmInvitationStatus.LOADING,
                };
            },
            prepare(authenticationToken: string, isAccepted: boolean) {
                return {
                    payload: {
                        authenticationToken: authenticationToken,
                        confirm: isAccepted,
                    },
                };
            },
        },

        setConfirmFleetPartnerInvitationActionStatus: {
            reducer: (state: IConfirmInvitationState, action: PayloadAction<IChangeInvitationStatus>) => {
                return {
                    ...state,
                    fleetPartnerInvitationConfirmActionStatus: action.payload.confirmActionStatus,
                };
            },
            prepare(confirmActionStatus: ConfirmInvitationStatus | null) {
                return {
                    payload: {
                        confirmActionStatus: confirmActionStatus,
                    },
                };
            },
        },

        setConfirmPurchaserInvitationActionStatus: {
            reducer: (state: IConfirmInvitationState, action: PayloadAction<IChangeInvitationStatus>) => {
                return {
                    ...state,
                    purchaserInvitationConfirmActionStatus: action.payload.confirmActionStatus,
                };
            },
            prepare(confirmActionStatus: ConfirmInvitationStatus | null) {
                return {
                    payload: {
                        confirmActionStatus: confirmActionStatus,
                    },
                };
            },
        },
    },
});

export const {
    confirmFleetPartnerInvitation,
    confirmTeamMemberInvitation,
    setConfirmFleetPartnerInvitationActionStatus,
    setConfirmPurchaserInvitationActionStatus,
} = confirmInvitationSlice.actions;

export default confirmInvitationSlice.reducer;
