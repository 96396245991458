import {Observable} from 'rxjs';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IReportJobProblemInput} from '../../model/jobProblemType';
export const updateReportJobProblemAPI: ApiOperationResponseFunction<{problem: IReportJobProblemInput; id: string}> = (
    authToken: string,
    payload: {problem: IReportJobProblemInput; id: string}
): Observable<IApiSingleResponseBase<{problem: IReportJobProblemInput; id: string}>> => {
    const updatePayload: IReportJobProblemInput = payload.problem,
        id: string = payload.id;
    return palipaliAPI.put(`api/jobs/${id}/report_problem`, updatePayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
