import type {SVGProps} from 'react';
import React from 'react';

const CreditCard02 = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <g id="credit-card-02">
            <path
                id="Icon"
                d="M14 6.33398H2M7.4 8.73398H4.4M2 5.25398L2 9.81398C2 10.486 2 10.8221 2.13079 11.0788C2.24584 11.3046 2.42942 11.4881 2.65521 11.6032C2.91191 11.734 3.24794 11.734 3.92 11.734L12.08 11.734C12.7521 11.734 13.0881 11.734 13.3448 11.6032C13.5706 11.4881 13.7542 11.3046 13.8692 11.0788C14 10.8221 14 10.486 14 9.81398V5.25399C14 4.58192 14 4.24589 13.8692 3.9892C13.7542 3.7634 13.5706 3.57983 13.3448 3.46478C13.0881 3.33399 12.7521 3.33399 12.08 3.33399L3.92 3.33398C3.24794 3.33398 2.91191 3.33398 2.65521 3.46478C2.42942 3.57982 2.24584 3.7634 2.13079 3.9892C2 4.24589 2 4.58192 2 5.25398Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
        </g>
    </svg>
);

export default CreditCard02;
