import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {IConfirmRegistrationOutput} from '../../model/user';

export function createConfirmFleetPartnerInvitationAPI(
    authenticationToken: string,
    confirm: boolean
): Observable<IApiSingleResponseBase<IConfirmRegistrationOutput>> {
    return palipaliAPI.post(`api/fleet_partner_couriers/confirm_invitation`, {confirm: confirm}, new RestQueryParams(), {
        'X-Authentication-Token': authenticationToken,
    });
}
