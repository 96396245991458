import React from 'react';
import {INoDataInfoProps} from '../../types';
import {Translation} from '../../index';
import SadFaceIcon from '../../theme/icons/untitled-ui/duocolor/sad-face-icon';

type Props = INoDataInfoProps;

const NoDataInfo: React.FC<Props> = ({text}) => {
    return (
        <div className="no-data-info">
            <SadFaceIcon />
            <Translation text={text} />
        </div>
    );
};

export default NoDataInfo;
