import {Button, Grid, Stack, Typography} from '@mui/material';
import {
    ChevronLeftIcon,
    IVehicleDetailsOutput,
    Translation,
    isAuthTokenExpiredSelector,
    isNotNullOrUndefined,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {fetchVehicleDetails, resetVehicleOperationState} from 'src/store/reducers/vehicleOperationSlice';
import {currentlyEditedVehicleSelector} from 'src/store/selectors/vehicleOperationSelector';
import VehicleBasicInformationCard from './VehicleBasicInformationCard';
import VehicleDeleteCard from './VehicleDeleteCard';
import VehicleInsuranceCard from './VehicleInsuranceCard';
import VehicleRegistrationCard from './VehicleRegistrationCard';
import VehicleStatusBadge from '../shared/VehicleStatusBadge';

interface IVehicleViewProps {}

const VehicleView: React.FC<IVehicleViewProps> = () => {
    const vehicle: IVehicleDetailsOutput | null = useSelector(currentlyEditedVehicleSelector),
        isAuthTokenExpired = useSelector(isAuthTokenExpiredSelector),
        params = useParams(),
        dispatch = useDispatch(),
        navigate = useNavigate(),
        vehicleId = params.id;

    useEffect(() => {
        if (vehicleId && !isAuthTokenExpired) {
            dispatch(fetchVehicleDetails(vehicleId));
        }
        return () => {
            dispatch(resetVehicleOperationState());
        };
    }, [dispatch, vehicleId, isAuthTokenExpired]);

    const navigateToVehicleList = () => {
        dispatch(resetVehicleOperationState());
        navigate('/panel/vehicles/');
    };

    const isInsuranceExpiring =
        vehicle !== null &&
        vehicle.latestInspection &&
        isNotNullOrUndefined(vehicle.latestInspection.upcomingExpiration) &&
        vehicle.latestInspection.upcomingExpiration === true;

    const isInspectionExpiring =
        vehicle !== null &&
        isNotNullOrUndefined(vehicle.latestInsurance) &&
        isNotNullOrUndefined(vehicle.latestInsurance.upcomingExpiration) &&
        vehicle.latestInsurance.upcomingExpiration === true;

    return (
        <>
            <div className="btn-back-wrapper">
                <Button onClick={() => navigateToVehicleList()} className="btn-action" startIcon={<ChevronLeftIcon />}>
                    <Translation text="vehicles.vehicleDetails.buttons.back" />
                </Button>
            </div>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="vehicles.vehicleDetails.title" />
                    </Typography>

                    {vehicle && (
                        <div className="badge-wrapper">
                            {' '}
                            <VehicleStatusBadge status={vehicle.status} expiring={isInsuranceExpiring || isInspectionExpiring} />{' '}
                        </div>
                    )}
                </div>
            </div>
            <Grid sx={{display: 'flex', flexDirection: 'column'}}>
                <Stack spacing={5}>
                    <VehicleBasicInformationCard />
                    <VehicleRegistrationCard />
                    <VehicleInsuranceCard />
                    <VehicleDeleteCard />
                </Stack>
            </Grid>
        </>
    );
};

export default VehicleView;
