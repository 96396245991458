import {TypographyOptions} from '@mui/material/styles/createTypography';

export const createTypography = (): TypographyOptions => {
    return {
        htmlFontSize: 16,
        fontFamily:
            '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        body1: {
            fontSize: '1.6rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '1.4rem',
            fontWeight: 400,
            // lineHeight: 1.57,
        },
        button: {
            fontWeight: 600,
        },
        caption: {
            fontSize: '1.2rem',
            fontWeight: 500,
            lineHeight: 1.66,
        },
        subtitle1: {
            fontSize: '1.6rem',
            fontWeight: 500,
            lineHeight: 1.57,
        },
        subtitle2: {
            fontSize: '1.4rem',
            fontWeight: 500,
            lineHeight: 1.57,
        },
        overline: {
            fontSize: '1.2rem',
            fontWeight: 600,
            letterSpacing: '0.5px',
            lineHeight: 2.5,
            textTransform: 'uppercase',
        },
        h1: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: '5.6rem',
            lineHeight: 1.2,
            fontFeatureSettings: `"clig" off, "liga" off`,
        },
        h2: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: '4.8rem',
            lineHeight: 1.2,
            fontFeatureSettings: `"clig" off, "liga" off`,
        },
        h3: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: '3.6rem',
            lineHeight: 1.2,
            fontFeatureSettings: `"clig" off, "liga" off`,
        },
        h4: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: '3.2rem',
            lineHeight: 1.2,
            fontFeatureSettings: `"clig" off, "liga" off`,
        },
        h5: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: '2.4rem',
            lineHeight: 1.2,
            fontFeatureSettings: `"clig" off, "liga" off`,
        },
        h6: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: '1.8rem',
            lineHeight: 1.2,
            fontFeatureSettings: `"clig" off, "liga" off`,
        },
    };
};
