import {Observable} from 'rxjs';
import {ITeamViewOutput} from '../../model/team';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const deleteTeamAPI: ApiOperationResponseFunction<ITeamViewOutput> = (
    authToken: string,
    payload: {id: string}
): Observable<IApiSingleResponseBase<ITeamViewOutput>> => {
    const id: string = payload.id;
    return palipaliAPI.delete(`api/teams/${id}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
