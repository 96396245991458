import React, {useEffect, useMemo} from 'react';
import {Grid, Card, Typography, FormGroup, FormControlLabel, Switch, Stack} from '@mui/material';
import {
    Translation,
    IReportsFilters,
    citiesSelector,
    IVehicleListingOutput,
    IFleetPartnerCourierListingOutput,
    Loader,
    LoaderType,
    isAuthTokenExpiredSelector,
} from 'palipali-panel-common-web';
import FiltersGroup from './FiltersGroup';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {getVehicleListing} from '../../../../store/reducers/vehicleListingSlice';
import {vehicleListingSelector} from '../../../../store/selectors/vehicleListingSelector';
import {getFleetPartnerCouriersListing} from '../../../../store/reducers/vehicleAssignedCourierListingSlice';
import {vehicleAssignedCourierListingSelector} from '../../../../store/selectors/vehicleAssignedCourierListingSelector';
import {reportsFiltersLoadingSelector, reportsFiltersSelector} from '../../../../store/selectors/reportsSelectors';

interface IFiltersProps {
    setFieldValue: (field: keyof IReportsFilters, value: unknown) => void;
}
const Filters: React.FC<IFiltersProps> = ({setFieldValue}) => {
    const {t} = useTranslation();
    const cityList = useSelector(citiesSelector);
    const dispatch = useDispatch();
    const vehicleList: IVehicleListingOutput[] | null = useSelector(vehicleListingSelector);
    const courierListing: IFleetPartnerCourierListingOutput[] | null = useSelector(vehicleAssignedCourierListingSelector);
    const filters = useSelector(reportsFiltersSelector);
    const [showVehiclesFiltersTab, setShowVehiclesFiltersTab] = React.useState(false);
    const areFiltersLoading = useSelector(reportsFiltersLoadingSelector);
    const isAuthTokenExpired: boolean = useSelector(isAuthTokenExpiredSelector);

    const vehicleOptions = useMemo(() => {
        if (!vehicleList) {
            return [];
        }

        return vehicleList?.map((vehicle) => {
            return {
                label: `${vehicle.model} ${vehicle.make} (${vehicle.registrationNumber})`,
                value: vehicle.id,
            };
        });
    }, [vehicleList]);

    const cityOptions = useMemo(() => {
        return cityList.map((city) => {
            return {
                label: city.name,
                value: city.id,
            };
        });
    }, [cityList]);

    const courierOptions = useMemo(() => {
        if (!courierListing) {
            return [];
        }
        return courierListing
            ?.map((courier) => {
                const name =
                    courier.account.firstName && courier.account.lastName
                        ? courier.account.firstName + ' ' + courier.account.lastName
                        : courier.account.username;
                return {
                    label: name,
                    value: courier?.account?.courier?.id || '',
                };
            })
            .filter((courier) => courier.value !== '');
    }, [courierListing]);

    useEffect(() => {
        if (!isAuthTokenExpired) {
            dispatch(getFleetPartnerCouriersListing());
            dispatch(getVehicleListing());
        }
    }, [isAuthTokenExpired]);

    const handleFleetVehiclesFilters = () => {
        setShowVehiclesFiltersTab((isVisible) => {
            if (isVisible) {
                setFieldValue('fleetPartnerVehicle', []);
            }
            return !isVisible;
        });
    };

    return (
        <Grid item xs={12} md={3}>
            <Card>
                <Stack position={'relative'} spacing={3} px={3} py={4}>
                    <Typography variant={'h6'} color={'textPrimary'}>
                        <Translation text={'reports.reportsList.filters.title'} />
                    </Typography>
                    <FiltersGroup
                        fieldName={'courierId'}
                        setFieldValue={setFieldValue}
                        groupTitle={'reports.reportsList.filters.courier'}
                        filterOptions={courierOptions}
                    />
                    <FiltersGroup
                        fieldName={'cityId'}
                        setFieldValue={setFieldValue}
                        groupTitle={'reports.reportsList.filters.city'}
                        filterOptions={cityOptions}
                    />
                    <FormGroup>
                        <FormControlLabel
                            labelPlacement={'end'}
                            control={
                                <Switch
                                    onChange={() => setFieldValue('courierVehicle', !filters['courierVehicle'])}
                                    checked={filters['courierVehicle']}
                                    className="reports-list-filters-couriers-vehicles-switch"
                                />
                            }
                            label={t('reports.reportsList.filters.courierVehicles')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            labelPlacement={'end'}
                            control={
                                <Switch
                                    onChange={handleFleetVehiclesFilters}
                                    checked={showVehiclesFiltersTab}
                                    className="reports-list-filters-fleet-vehicles-switch"
                                />
                            }
                            label={t('reports.reportsList.filters.fleetVehicles')}
                        />
                    </FormGroup>
                    {showVehiclesFiltersTab && (
                        <FiltersGroup fieldName={'fleetPartnerVehicle'} setFieldValue={setFieldValue} filterOptions={vehicleOptions} />
                    )}
                    <Loader showLoader={areFiltersLoading} type={LoaderType.Local} />
                </Stack>
            </Card>
        </Grid>
    );
};

export default Filters;
