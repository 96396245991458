import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../reducers';
import {ISettlementPeriodSliceState} from '../reducers/settlementPeriodSlice';

export const selectSettlementPeriod = (state: CommonRootState): ISettlementPeriodSliceState => {
    return state.settlementPeriod;
};

export const settlementPeriodSelector = createSelector(
    [selectSettlementPeriod],
    (state: ISettlementPeriodSliceState) => state.settlementPeriods
);
export const isSettlementPeriodLoadingSelector = createSelector(
    [selectSettlementPeriod],
    (state: ISettlementPeriodSliceState) => state.isLoading
);
export const isSettlementPeriodInitializedSelector = createSelector(
    [selectSettlementPeriod],
    (state: ISettlementPeriodSliceState) => state.isInitialized
);
export const settlementPeriodErrorSelector = createSelector([selectSettlementPeriod], (state: ISettlementPeriodSliceState) => state.error);
