import {Observable} from 'rxjs';
import {IServerReportsFilters} from '../../model/reports';
import {from} from 'rxjs';
import {API_URL} from '../../config/entrypoint';

export function fetchDownloadCourierReportAPI(authToken: string, filters: IServerReportsFilters, page: number = 1): Observable<any> {
    const url = '/api/courier_jobs/report_listing_download';
    const fleetPartnerVehicleParams =
        filters['fleetPartnerVehicle[]']?.length > 0
            ? filters['fleetPartnerVehicle[]'].map((vehicleId) => `fleetPartnerVehicle[]=${vehicleId}`).join('&')
            : undefined;
    const courierIdParams =
        filters['courier.id[]']?.length > 0 ? filters['courier.id[]'].map((courierId) => `courier.id[]=${courierId}`).join('&') : undefined;
    const cityIdParams =
        filters['city.id[]']?.length > 0
            ? filters['city.id[]'].map((cityId) => `job.fromJobLocation.closestCity.id[]=${cityId}`).join('&')
            : undefined;
    const finishedAtParams = filters['finishedAt[before]'] ? `finishedAt[before]=${filters['finishedAt[before]']}` : undefined;
    const startedAtParams = filters['startedAt[after]'] ? `startedAt[after]=${filters['startedAt[after]']}` : undefined;
    const courierVehicleParams = filters.courierVehicle ? `courierVehicle=${filters.courierVehicle}` : undefined;

    const finalUrl =
        url +
        '?' +
        startedAtParams +
        '&' +
        finishedAtParams +
        `&page=${page}` +
        (cityIdParams ? '&' + cityIdParams : '') +
        (courierIdParams ? '&' + courierIdParams : '') +
        (fleetPartnerVehicleParams ? '&' + fleetPartnerVehicleParams : '') +
        (courierVehicleParams ? '&' + courierVehicleParams : '');

    return from(
        fetch(API_URL + finalUrl, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + authToken,
            },
        })
    );
}
