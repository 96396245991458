import type {SVGProps} from 'react';
import React from 'react';

const LogOut02 = (props: SVGProps<SVGSVGElement>) => (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="log-out-02">
            <path
                id="Icon"
                d="M48 51L63 36M63 36L48 21M63 36H27M36 51C36 53.7899 36 55.1849 35.6933 56.3294C34.8611 59.4352 32.4352 61.8611 29.3294 62.6933C28.1849 63 26.7899 63 24 63H22.5C18.3065 63 16.2098 63 14.5558 62.3149C12.3506 61.4015 10.5985 59.6494 9.68508 57.4442C9 55.7902 9 53.6935 9 49.5V22.5C9 18.3065 9 16.2098 9.68509 14.5558C10.5985 12.3506 12.3506 10.5985 14.5559 9.68508C16.2098 9 18.3065 9 22.5 9H24C26.7899 9 28.1849 9 29.3294 9.30667C32.4352 10.1389 34.8611 12.5648 35.6933 15.6706C36 16.8151 36 18.2101 36 21"
                stroke="#F04438"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default LogOut02;
