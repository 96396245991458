import React from 'react';
import {connect} from 'react-redux';
import {Uploader} from 'rsuite';
import {CommonRootState} from '../../../store/reducers';
import Translation from '../../Translation';
import {addAlert, AlertType, authTokenSelector, IconSize, TrashIcon} from '../../..';
import {convertApiErrorToErrorMessage} from '../../../utils/alertUtils';
import {IFileType, IFileUploaderProps, IFileUploaderState} from '../../../types';

type Props = IFileUploaderProps;
type State = IFileUploaderState;

class InputFileUploader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: [],
            isProcessing: false,
            inputFileUploaded: false,
            progressValue: null,
        };
    }

    render() {
        return (
            <div className="uploader-control">
                <div className="uploader-wrapper">
                    <Uploader
                        removable={this.props.isFileRemovable}
                        className={`form-input ${this.state.value.length > 0 ? 'uploaded' : 'empty'}`}
                        action={``}
                        multiple={false}
                        autoUpload={false}
                        onChange={this.handleChange}
                        headers={{Authorization: 'Bearer ' + this.props.authToken}}
                        onSuccess={this.handleUploadSuccess}
                        fileList={this.state.value}
                        onProgress={this.updateProgressData}
                        onRemove={(file: IFileType) => this.removeFile(file)}
                        onError={this.handleUploadError}
                        onUpload={this.props.onFileUpload}
                        accept={this.props.acceptedFileExtension ? this.props.acceptedFileExtension : null}
                        listType="text"
                        renderFileInfo={(file, fileElement) => {
                            return (
                                <div className="file-list-item">
                                    <span>{file.name}</span>
                                    <button className="remove-file-button" onClick={() => this.removeFile(file)}>
                                        <TrashIcon size={IconSize.Large} />
                                        <Translation text={'buttons.delete'} />
                                    </button>
                                </div>
                            );
                        }}>
                        {this.props.label && (
                            <div className="uploader-wrapper">
                                <label className="uploader-label">
                                    <Translation text={this.props.label} />
                                </label>
                                <button className="file-upload-button" onClick={(e: any) => e.preventDefault()}>
                                    <div className="file-upload-button-icon" />
                                    <span className="sr-only">
                                        {this.state.value.length ? (
                                            <Translation text={`buttons.change`} />
                                        ) : (
                                            <Translation text={'buttons.add'} />
                                        )}
                                    </span>
                                </button>
                            </div>
                        )}
                    </Uploader>
                    {this.state.value.length === 0 && (
                        <div className="uploader-information">
                            <Translation text={this.props.uploaderInformation} />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private handleChange = (file: any) => {
        if (!file.length) {
            return;
        }
        this.props.onFileChange && this.props.onFileChange(file);
        return this.setState({
            value: file,
        });
    };

    private handleUploadSuccess = (response: any) => {
        if (response) {
            this.setState({inputFileUploaded: true, progressValue: null});
        }
    };

    private handleUploadError = (error: any) => {
        const message = convertApiErrorToErrorMessage(error);
        this.props.addAlert({message: message, type: AlertType.WARNING});
        this.setState({progressValue: null, inputFileUploaded: false});
    };

    private removeFile = (file: IFileType) => {
        const uploadedFiles = this.state.value.filter((item: IFileType) => item.fileKey !== file.fileKey);

        this.setState({value: uploadedFiles, inputFileUploaded: false});
    };

    private updateProgressData = (progressValue: number) => {
        this.setState({progressValue: progressValue});
    };
}

export default connect(
    (state: CommonRootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        addAlert,
    }
)(InputFileUploader);
