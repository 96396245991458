import React from 'react';
import styles from './styles.module.scss';
import DatePicker from 'react-datepicker';
import {IDatePickerInputProps} from '../../../types';
import {getDateFromString} from '../../../utils/dateUtils';

type Props = IDatePickerInputProps;

class DatePickerInput extends React.Component<Props> {
    render() {
        return (
            <div
                className={`${
                    this.props.showMonthDropdown || this.props.showYearDropdown ? 'select-month-year' : ''
                } ${'react-datepicker-host'}`}>
                <DatePicker
                    name={`${this.props.name}`}
                    id={`${this.props.name}`}
                    dateFormat={this.props.dateFormat}
                    placeholderText={this.props.placeholderText}
                    className={`${this.props.inputStyles} ${styles.dateInput}`}
                    disabled={this.props.disabled}
                    value={this.props.value}
                    weekLabel={''}
                    autoComplete={'off'}
                    onChangeRaw={this.handleDateChangeRaw}
                    selected={this.props.value ? new Date(this.props.value) : null}
                    minDate={this.props.minDate ? getDateFromString(this.props.minDate) : null}
                    maxDate={this.props.maxDate ? getDateFromString(this.props.maxDate) : null}
                    openToDate={this.props.openToDate ? getDateFromString(this.props.openToDate) : undefined}
                    onChange={(e) => this.changeInputValue(e)}
                    showMonthDropdown={this.props.showMonthDropdown}
                    showYearDropdown={this.props.showYearDropdown}
                    dropdownMode="select"
                    inline={this.props.inline}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                    popperPlacement={this.props.popperPlacement ? this.props.popperPlacement : 'bottom-end'}
                />
            </div>
        );
    }

    handleDateChangeRaw = (e: any) => {
        e.preventDefault();
    };

    private changeInputValue = (e: Date | [Date, Date] | null) => {
        if (e) {
            const eventData = {target: {name: this.props.name, value: e}};
            this.props.handleChange(eventData);
        }
    };
}

export default DatePickerInput;
