import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {IBaseAuthPanelComponentWithThemeProps} from '../../../types';
import Login from '../Login';
import {purchaserInvitationConfirmActionStatusSelector} from '../../../store/selectors/confirmInvitationSelectors';
import {confirmTeamMemberInvitation} from '../../../store/reducers/confirmInvitationSlice';

type Props = IBaseAuthPanelComponentWithThemeProps;

const ConfirmTeamInvitation: React.FC<Props> = ({userRole, theme}) => {
    const {id} = useParams(),
        dispatch = useDispatch(),
        actionValue = new URLSearchParams(location.search).get('action') === 'accept' ? true : false,
        confirmActionStatus = useSelector(purchaserInvitationConfirmActionStatusSelector);

    useEffect(() => {
        if (id && confirmActionStatus === null) {
            dispatch(confirmTeamMemberInvitation(id, actionValue));
        }
    }, [confirmActionStatus, id, location.search]);

    return <Login userRole={userRole} theme={theme} key="login" />;
};

export default ConfirmTeamInvitation;
