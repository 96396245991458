import React from 'react';
import Translation from '../../Translation';
import LayoutWrapper from '../../LayoutWrapper';

const NotFound = () => {
    return (
        <LayoutWrapper>
            <div className="maintenance-container">
                <h2 className="header">404</h2>
                <p className="description">
                    <Translation text="maintenance.pageNotFound" />
                </p>
            </div>
        </LayoutWrapper>
    );
};

export default NotFound;
