import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export function createChangePasswordAPI(oldPassword: string, newPassword: string, accessToken: string): Observable<any> {
    return palipaliAPI.post(
        'api/users/change_password',
        {
            oldPassword: oldPassword,
            newPassword: newPassword,
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        }
    );
}
