import {
    addDays,
    addMonths,
    endOfMonth,
    endOfWeek,
    isAfter,
    isBefore,
    isSameDay,
    isSameMonth,
    isValid,
    startOfMonth,
    startOfWeek,
} from 'date-fns';
import {DateRange} from './types';

export const identity = <T>(x: T) => x;

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] =>
    Array.from({length: Math.ceil(array.length / size)}, (_v, i) => array.slice(i * size, i * size + size));

// Date
export const getDaysInMonth = (date: Date) => {
    const startWeek = startOfWeek(startOfMonth(date));
    const endWeek = endOfWeek(endOfMonth(date));
    const days = [];
    for (let curr = startWeek; isBefore(curr, endWeek); ) {
        days.push(curr);
        curr = addDays(curr, 1);
    }
    return days;
};

export const isStartOfRange = ({startDate}: DateRange, day: Date) => (startDate && isSameDay(day, startDate)) as boolean;

export const isEndOfRange = ({endDate}: DateRange, day: Date) => (endDate && isSameDay(day, endDate)) as boolean;

export const inDateRange = ({startDate, endDate}: DateRange, day: Date): boolean => {
    if (startDate && endDate) {
        return (isAfter(day, startDate) && isBefore(day, endDate)) || isSameDay(day, startDate) || isSameDay(day, endDate);
    }
    return false;
};
export const isRangeSameDay = ({startDate, endDate}: DateRange) => {
    if (startDate && endDate) {
        return isSameDay(startDate, endDate);
    }
    return false;
};

type Falsy = false | null | undefined | 0 | '';

export const parseOptionalDate = (date: Date | string | Falsy, defaultValue: Date) => {
    if (date) {
        const parsed = date instanceof Date ? date : new Date(date);
        if (isValid(parsed)) return parsed;
    }
    return defaultValue;
};

export const getValidatedMonths = (range: DateRange, minDate: Date, maxDate: Date): [Date | null, Date | null] => {
    const {startDate, endDate} = range;

    if (startDate && endDate) {
        const newStart = new Date(Math.max(startDate.getTime(), minDate.getTime()));
        const newEnd = new Date(Math.min(endDate.getTime(), maxDate.getTime()));

        return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
    }

    return [startDate, endDate];
};
