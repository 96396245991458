import {FormikFieldConfig, FormikFieldTypes, IMultiselectOption, ValidationErrorType} from 'palipali-panel-common-web';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

const settingsFormConfig = (localeList: IMultiselectOption[]): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'isDarkTheme',
            label: t('account.settings.highContrastMode'),
            sublabel: `${t('account.settings.highContrastModeSubtitle')}`,
            type: FormikFieldTypes.SWITCH,
            className: 'switch-form-control-wrapper',
            validation: Yup.boolean(),
        },
        {
            name: 'locale',
            label: t('account.settings.formControls.language'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            options: localeList,
        },
    ];
};
export default settingsFormConfig;
