import {Button, Card, CardContent, CardHeader, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {addAlert} from '../../../store/reducers/alertSlice';
import {confirmRegistration} from '../../../store/reducers/authSlice';
import {AlertType, IBaseAuthPanelComponentProps, LoaderType} from '../../../types';
import Loader from '../../Loader';
import Translation from '../../Translation';

type Props = IBaseAuthPanelComponentProps;

const ConfirmRegistration: React.FC<Props> = ({isLoading, userRole}) => {
    const {id} = useParams(),
        dispatch = useDispatch(),
        {t} = useTranslation();

    const sendConfirmRegistration = (): void => {
        if (!id) {
            dispatch(addAlert({message: 'auth.alert.invalidConfirmRegistrationURL', type: AlertType.WARNING}));
            return;
        }

        dispatch(confirmRegistration(id, userRole));
    };

    return (
        <Card elevation={16}>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <CardHeader
                subheader={
                    <Typography color="text.secondary" variant="body2" key="subheader">
                        <Translation text="auth.confirmRegistration.subtitle" />
                    </Typography>
                }
                sx={{pb: 0}}
                title={t('auth.confirmRegistration.title')}
            />
            <CardContent>
                <Button
                    fullWidth
                    size="large"
                    sx={{mt: 2, fontSize: '1.5rem'}}
                    type="submit"
                    variant="contained"
                    onClick={() => sendConfirmRegistration()}
                    className="confirmRegistration-button">
                    <Translation text="auth.confirmRegistration.confirmButton" />
                </Button>
            </CardContent>
        </Card>
    );
};

export default ConfirmRegistration;
