import type {SVGProps} from 'react';
import React from 'react';

const CurrentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" {...props}>
        <rect y="0.5" width="48" height="48" rx="24" fill="#009FE3" fillOpacity="0.12" />
        <path
            d="M24 18.1V23.5L27.6 25.3M33 23.5C33 28.4706 28.9706 32.5 24 32.5C19.0294 32.5 15 28.4706 15 23.5C15 18.5294 19.0294 14.5 24 14.5C28.9706 14.5 33 18.5294 33 23.5Z"
            stroke="#009FE3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CurrentIcon;
