import {CustomIconProps} from '../../../types/iconTypes';
import React from 'react';

const RefreshIcon = (props: CustomIconProps) => (
    <svg width={props.size ? props.size : 20} height={props.size ? props.size : 20} viewBox="0 0 20 20" fill="none">
        <g id="refresh-cw-05">
            <path
                id="Icon"
                d="M17.0443 10.7439C16.8128 12.9188 15.5805 14.9569 13.5413 16.1342C10.1534 18.0902 5.82132 16.9295 3.86531 13.5415L3.65698 13.1807M2.95527 9.25553C3.18677 7.08062 4.4191 5.04252 6.45825 3.86521C9.84616 1.9092 14.1783 3.06998 16.1343 6.45789L16.3426 6.81873M2.91113 15.0547L3.52118 12.778L5.79788 13.3881M14.2022 6.61137L16.4789 7.22141L17.0889 4.9447"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default RefreshIcon;
