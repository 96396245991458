import {IApiSingleResponseBase} from '../../types';
import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IAccountBasicInfoOutput} from '../../model/account';

export function updateLocaleAPI(
    authToken: string | null,
    accountId: string,
    locale: string
): Observable<IApiSingleResponseBase<IAccountBasicInfoOutput>> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return palipaliAPI.put(`api/accounts/${accountId}/change_locale`, {locale: locale}, new RestQueryParams(), headers);
}
