import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings, ValidationErrorType} from 'palipali-panel-common-web';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

const newPasswordFormConfig = (): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'oldPassword',
            label: t('account.changePassword.formControls.oldPassword'),
            autofocus: true,
            validation: Yup.string()
                .max(255)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.PASSWORD,
            isRequired: true,
        },
        {
            name: 'newPassword',
            label: t('account.changePassword.formControls.newPassword'),
            autofocus: true,
            validation: Yup.string()
                .max(255)
                .matches(FormikRegexSettings.PASSWORD, `${t(ValidationErrorType.IS_PASSWORDS_STRING)}`)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.PASSWORD,
            isRequired: true,
        },
        {
            name: 'confirmNewPassword',
            label: t('account.changePassword.formControls.confirmNewPassword'),
            validation: Yup.string()
                .max(255)
                .matches(FormikRegexSettings.PASSWORD, `${t(ValidationErrorType.IS_PASSWORDS_STRING)}`)
                .oneOf([Yup.ref('newPassword')], `${t(ValidationErrorType.IS_PASSWORDS_SAME)}`)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.PASSWORD,
            isRequired: true,
        },
    ];
};
export default newPasswordFormConfig;
