import {FormikFieldTypes, FormikFormControlType, FormikGroupFieldConfig, FormikRegexSettings} from '../../FormikForm';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {ValidationErrorType} from '../../../utils/formValidators';
import {selectPhoneCodeList} from '../../../utils/formUtils';
import {TextMaskType} from '../../../theme/components/TextMaskFormControl';
import {isPhoneNumberValid} from '../../../utils/phoneUtils';

const registrationFormConfig = (cities, translationComponents): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'username',
                    label: t('auth.formControls.email'),
                    validation: Yup.string()
                        .max(255)
                        .matches(FormikRegexSettings.EMAIL, `${t(ValidationErrorType.IS_EMAIL)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    autofocus: true,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'password',
                    label: t('auth.formControls.password'),
                    validation: Yup.string()
                        .matches(FormikRegexSettings.PASSWORD, `${t(ValidationErrorType.IS_PASSWORDS_STRING)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.PASSWORD,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'repeatPassword',
                    label: t('auth.formControls.repeatPassword'),
                    validation: Yup.string()
                        .max(255)
                        .matches(FormikRegexSettings.PASSWORD, `${t(ValidationErrorType.IS_PASSWORDS_STRING)}`)
                        .oneOf([Yup.ref('password')], `${t(ValidationErrorType.IS_PASSWORDS_SAME)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.PASSWORD,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'firstName',
                    label: t('auth.formControls.firstName'),
                    validation: Yup.string()
                        .max(255)
                        .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                            const dashRegExp = /-.*-/;
                            const singleQuoteRegExp = /'.*'/;
                            const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                            if (result) {
                                return false;
                            }
                            return true;
                        })
                        .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
                        .matches(
                            FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE,
                            `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`
                        )
                        .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                    lettersOnly: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'lastName',
                    label: t('auth.formControls.lastName'),
                    validation: Yup.string()
                        .max(255)
                        .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                            const dashRegExp = /-.*-/;
                            const singleQuoteRegExp = /'.*'/;
                            const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                            if (result) {
                                return false;
                            }
                            return true;
                        })
                        .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
                        .matches(
                            FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE,
                            `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`
                        )
                        .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                    lettersOnly: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'country',
                    label: `${t('auth.formControls.dialingCode')}`,
                    validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.SELECT,
                    className: `account-profile-phone-code`,
                    formControlClassName: 'phone-code-select',
                    flexSettings: '0',
                    isRequired: true,
                    options: selectPhoneCodeList,
                },
                {
                    name: 'phone',
                    label: t('auth.formControls.phone'),
                    validation: Yup.string()
                        .test('is-valid-phone', `${t(ValidationErrorType.IS_PHONE)}`, function (value) {
                            const {country} = this.parent;

                            if (!value) return true;

                            try {
                                return isPhoneNumberValid(country, value);
                            } catch (error) {
                                return false;
                            }
                        })
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.MASKED,
                    textMaskType: TextMaskType.PHONE,
                    flexSettings: '1',
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'cityId',
                    label: t('auth.formControls.city'),
                    validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.SELECT,
                    options: cities,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'policy',
                    label: t('auth.formControls.privacyPolicy'),
                    validation: Yup.boolean().oneOf([true], ''),
                    type: FormikFieldTypes.CHECKBOX,
                    isRequired: true,
                    isCustomLabelTranslation: true,
                    translationComponents: translationComponents,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'register-button',
                    label: t('auth.register.registerButton'),
                    type: FormikFieldTypes.SUBMIT_BUTTON,
                    sx: {mt: '2.2rem', fontSize: '1.5rem'},
                    variant: 'contained',
                    validation: {},
                },
            ],
        },
    ];
};
export default registrationFormConfig;
