import React from 'react';
import {getDisplayName} from '../../model/account';
import {IUserDisplayNameProps} from '../../types';

type Props = IUserDisplayNameProps;

const UserDisplayName: React.FC<Props> = ({account}) => {
    return <>{getDisplayName(account)}</>;
};

export default UserDisplayName;
