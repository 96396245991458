import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, ICanOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';

export function getCanDoStripeOnboardingAPI(authToken: string, payload: {id: string}): Observable<IApiSingleResponseBase<ICanOutput>> {
    const id = payload.id;
    return palipaliAPI.get(`api/fleet_partners/${id}/can_do_stripe_onboarding`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
