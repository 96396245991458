import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings} from '../../FormikForm';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {ValidationErrorType} from '../../../utils/formValidators';

const loginFormConfig = (): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'email',
            label: t('auth.formControls.email'),
            autofocus: true,
            validation: Yup.string()
                .max(255)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`)
                .matches(FormikRegexSettings.EMAIL, `${t(ValidationErrorType.IS_EMAIL)}`),
            type: FormikFieldTypes.TEXT,
        },
        {
            name: 'password',
            label: t('auth.formControls.password'),
            validation: Yup.string()
                .max(255)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.PASSWORD,
        },
        {
            name: 'login-button',
            label: t('auth.login.loginButton'),
            type: FormikFieldTypes.SUBMIT_BUTTON,
            sx: {},
            validation: {},
        },
    ];
};
export default loginFormConfig;
