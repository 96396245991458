import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings, ValidationErrorType} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export const courierInviteFormConfig = (): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'email',
            label: t('couriers.inviteCourier.email'),
            validation: Yup.string()
                .max(255)
                .matches(FormikRegexSettings.EMAIL, `${t(ValidationErrorType.IS_EMAIL)}`)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.TEXT,
            isRequired: true,
        },
    ];
};
