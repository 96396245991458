import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ICourierListingState} from '../reducers/courierListingSlice';

export const selectCouriersListingSlice = (state: RootState) => {
    return state.courierList;
};

export const courierListingSelector = createSelector([selectCouriersListingSlice], (state: ICourierListingState) => state.courierList);
export const courierLoadingSelector = createSelector([selectCouriersListingSlice], (state: ICourierListingState) => state.isLoading);
export const redirectToCouriersListSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.redirectToCouriersList
);
export const courierListingErrorSelector = createSelector([selectCouriersListingSlice], (state: ICourierListingState) => state.error);
export const courierListingInitializedSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.isInitialized
);
export const canBeUnassignedSelector = createSelector([selectCouriersListingSlice], (state: ICourierListingState) => state.canBeUnassigned);
export const courierListingMetadataSelector = createSelector([selectCouriersListingSlice], (state: ICourierListingState) => state.metadata);
export const courierListingFilterVerifiedSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.courierFilters?.verified
);
export const courierListingSearchSelector = createSelector([selectCouriersListingSlice], (state: ICourierListingState) =>
    state.courierFilters?.search ? state.courierFilters.search : ''
);
export const courierListingCitySelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.courierFilters?.city
);
export const courierListingPaginationSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.pagination
);

export const unassignCourierCheckingSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.canBeUnassignedCheckInProgress
);

export const courierVehicleListSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.courierVehiclesList
);

export const courierVehicleListPaginationSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.courierVehiclesListPagination
);

export const courierVehicleListMetadataSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.courierVehiclesListMetadata
);

export const courierAssignedVehicleIdsSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.courierAssignedVehicleIds
);

export const courierVehicleLoadingSelector = createSelector(
    [selectCouriersListingSlice],
    (state: ICourierListingState) => state.isCourierVehicleListLoading
);
