import {Observable} from 'rxjs';
import {IAssignCouriersToVehiclesInput, IVehicleAssignCouriersProcessOutput} from '../../model/vehicle';
import {IApiSingleResponseBase, IRawRestQueryParams} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const updateCouriersAssignmentToVehicleAPI: ApiOperationResponseFunction<IVehicleAssignCouriersProcessOutput> = (
    authToken: string,
    payload?: {courierArrays: IAssignCouriersToVehiclesInput; id: string},
    params?: IRawRestQueryParams | []
): Observable<IApiSingleResponseBase<IVehicleAssignCouriersProcessOutput>> => {
    const updatePayload: IAssignCouriersToVehiclesInput = payload.courierArrays,
        id: string = payload.id;
    return palipaliAPI.put(`api/vehicles/${id}/assign_fleet_partner_couriers`, updatePayload, new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
    });
};
