import {Box, Button, CircularProgress, InputAdornment, TextField} from '@mui/material';
import React, {useRef, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Subscription, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {createMediaObjectAPI} from '../../../api/mediaObject/createMediaObjectAPI';
import {authTokenSelector} from '../../../store/selectors/authSelectors';
import {IFileUploadListElement} from '../../../types';
import DocumentIcon from '../../icons/untitled-ui/duocolor/document';
import {FormikFieldConfig} from '../../../components/FormikForm';

const FileFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    acceptedFileTypes,
    multipleFiles,
    placeholder,
    formik,
    className,
    getFileIdValue,
    customEventChange,
}) => {
    const [mediaItem, setMediaItem] = useState<IFileUploadListElement | null>(null);
    const ref = useRef<HTMLInputElement>(null),
        {t} = useTranslation(),
        authToken: string = useSelector(authTokenSelector),
        [isProcessing, setIsProcessing] = useState<boolean>(false),
        getFileName = () => (formik.values[name]?.name ? formik.values[name].name : mediaItem?.name ? mediaItem.name : ''),
        getFileId = () => (mediaItem?.fileKey ? mediaItem.fileKey : formik.values[name]?.fileKey ? formik.values[name]?.fileKey : ''),
        subscriptions: Subscription[] = [],
        placeholderText = placeholder ? t(placeholder) : '';
    useEffect(() => {
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    const fileHandleChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        if (!e.target.files) return;
        const files: File[] = Array.from(e.target.files);

        setIsProcessing(true);
        if (files.length > 0) {
            const documentImage = files[0];
            const documentName = documentImage.name;
            const formData = new FormData();
            formData.append('file', documentImage);
            formData.append('public', 'true');
            subscriptions.push(
                createMediaObjectAPI(authToken, formData)
                    .pipe(
                        tap((resp: any) => {
                            if (resp.response) {
                                const mediaItem = {
                                    name: documentName ? documentName : resp.response?.fileName,
                                    fileKey: resp.response.id,
                                    url: resp.response.contentUrl ? resp.response.contentUrl : resp.response.fileUrls.original,
                                };
                                setMediaItem(mediaItem);
                                if (customEventChange) {
                                    customEventChange(name, getFileIdValue ? resp.response.id : mediaItem);
                                }
                                formik.setFieldValue(name, getFileIdValue ? resp.response.id : mediaItem);
                            }
                            setIsProcessing(false);
                        }),
                        catchError((error) => {
                            setIsProcessing(false);
                            return of(...error);
                        })
                    )
                    .subscribe()
            );
        }
    };

    const error =
        formik.touched[name] && typeof formik.errors[name] === 'object'
            ? Object.values(formik.errors[name]).join(' ')
            : formik.touched[name] && typeof formik.errors[name] === 'string'
            ? formik.errors[name]
            : undefined;

    return (
        <Box position="relative" height={57} sx={{marginBottom: '2.4rem'}} width="100%" key={`file-input-wrapper-box-${name}`}>
            <Box position="absolute" width="100%" key={`file-input-wrapper-box-textfield-${name}`}>
                <TextField
                    fullWidth
                    key={`file-input-wrapper-textfield-${name}`}
                    name={name}
                    label={t(label)}
                    value={getFileName()}
                    sx={{pointerEvents: 'none'}}
                    error={!!(formik.touched[name] && formik.errors[name])}
                    helperText={error}
                    placeholder={placeholderText}
                    disabled={isProcessing}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{padding: '0.8rem'}}>
                                {isProcessing ? <CircularProgress size={18} /> : <DocumentIcon />}
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Button
                disabled={isProcessing}
                component="label"
                onKeyDown={(e) => e.key === '32' && ref.current?.click()}
                fullWidth
                key={`file-input-wrapper-button-${name}`}
                sx={{height: '100%'}}>
                <input
                    ref={ref}
                    className={`${className || ''}`}
                    key={`file-input-wrapper-input-${name}`}
                    type="file"
                    onChange={(event) => fileHandleChange(event, name)}
                    accept={acceptedFileTypes ? acceptedFileTypes : '*'}
                    hidden
                    value={''}
                    multiple={multipleFiles ? multipleFiles : false}
                />
            </Button>
        </Box>
    );
};
export default FileFormControl;
