import React from 'react';
import {useSelector} from 'react-redux';
import {activeLanguageSelector} from '../../store/selectors/sagaSelectors';
import Translation from '../Translation';

interface ITimeComponentProps {
    readonly application: string;
    readonly date?: Date | string | null | undefined;
    readonly time?: number | null;
    readonly isEstimatedTime?: boolean;
    readonly isSimpleTime?: boolean;
    readonly className?: string;
    readonly isHistoricalView?: boolean;
}
interface IMonthFormat {
    month: 'long';
    timeZone?: string | undefined;
}

const options: IMonthFormat = {month: 'long'},
    monthName = (monthNumber: number | string, language: string) => {
        const intl = new Intl.DateTimeFormat(language, options);
        const date = new Date(`2000-${monthNumber}-01`);

        return intl.format(date);
    },
    formatTime = (time: number | null | undefined) => {
        if (time) {
            const totalMinutes = Math.ceil(time / 60);
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            if (hours === 0) {
                return `${minutes} min`;
            } else {
                return `${hours} h ${minutes} min`;
            }
        }
    },
    language = (locale: string) => {
        switch (locale) {
            case 'pl':
                return 'pl-PL';
            case 'uk':
                return 'uk-UK';
            case 'en':
                return 'en-EN';
            default:
                return 'pl-PL';
        }
    };

const TimeComponent: React.FC<ITimeComponentProps> = ({
    application,
    date,
    time,
    isSimpleTime,
    isEstimatedTime,
    className,
    isHistoricalView,
}) => {
    const locale: string = useSelector(activeLanguageSelector),
        formattedDate = new Date(date ? date : ''),
        createdAt = formattedDate.getHours() + ':' + formattedDate.getMinutes(),
        year = formattedDate.getFullYear(),
        month = (formattedDate.getMonth() + 1).toString().padStart(2, '0'),
        simpleMonth = String(formattedDate.getMonth() + 1).padStart(2, '0'),
        formattedHoursMinutesFromDate =
            String(formattedDate.getHours()).padStart(2, '0') + ':' + String(formattedDate.getMinutes()).padStart(2, '0'),
        day = String(formattedDate.getDate()).padStart(2, '0'),
        completedFleetDate = `${day}.${month}.${year}`,
        completedDate = `${year}-${month}-${day}`;

    if (isSimpleTime) {
        return (
            <span>
                {`${day}.${simpleMonth}.${year} ${formattedHoursMinutesFromDate}`}{' '}
                {isEstimatedTime && <Translation text="app.timeComponent.estimatedTime" />}
            </span>
        );
    }
    const showData = () => {
        if (application === 'purchaser') {
            if (isHistoricalView) {
                return (
                    <span>{`${day} ${monthName(month, language(locale))} ${formattedHoursMinutesFromDate} ${
                        time ? `| ${formatTime(time)}` : ''
                    }`}</span>
                );
            }
            return time ? (
                <span>{`${time !== null ? `${completedDate} | ${formatTime(time)}` : `${formattedDate}`}`}</span>
            ) : (
                <span>{`${day} ${monthName(month, language(locale))} ${formattedHoursMinutesFromDate}`}</span>
            );
        }
        if (application === 'fleetPartner') {
            if (time !== null && time !== undefined) {
                if (time === 0) {
                    return <span>{`0 min`}</span>;
                } else {
                    return <span>{formatTime(time)}</span>;
                }
            } else if (date) {
                return <span>{`${completedFleetDate}`}</span>;
            } else {
                return null;
            }
        } else return null;
    };
    return <span className={className}>{showData()}</span>;
};

export default TimeComponent;
