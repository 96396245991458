import {Card, CardContent, CardHeader, Link, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link as ReactRouterLink, useNavigate, useParams} from 'react-router-dom';
import {LanguageLocale, LanguageLocaleType} from '../../../constants/locales';
import {DEFAULT_LOCALE} from '../../../model/account';
import {IModelDictionaryDatum} from '../../../model/dictionaryDatum';
import {SystemParameterCode} from '../../../model/systemPatameters';
import {IRegisterUserInput} from '../../../model/user';
import {IRegistrationInput, registerUser, setRegistrationData} from '../../../store/reducers/authSlice';
import {fetchCities} from '../../../store/reducers/citySlice';
import {changeActiveLanguage} from '../../../store/reducers/sagaSlice';
import {isAuthPageLoadingSelector, isAuthenticatedSelector, registrationDataSelector} from '../../../store/selectors/authSelectors';
import {citiesInitializedSelector, citiesSelector} from '../../../store/selectors/citySelectors';
import {selectSystemParameter} from '../../../store/selectors/systemParametersSelectors';
import {IMultiselectOption, IRegisterProps, LoaderType, UserRole} from '../../../types';
import {convertToMultiselectLabels} from '../../../utils/formUtils';
import {transformLocaleToLocaleType} from '../../../utils/localeUtils';
import {isNotEmpty, isNullOrUndefined} from '../../../utils/runtimeUtils';
import FormikForm from '../../FormikForm';
import Loader from '../../Loader';
import Translation from '../../Translation';
import registrationFormConfig from './registrationFormConfig';
import {deepCloneObject} from '../../../utils/objectUtils';

export enum UserRegistrationRoles {
    COURIER = 'courier',
    PURCHASER = 'purchaser',
    FLEET_PARTNER = 'fleet_partner',
}

const Registration: React.FC<IRegisterProps> = ({userRole, envData, theme}) => {
    const isAuthenticated = useSelector((state) => isAuthenticatedSelector(state)),
        registrationData = useSelector(registrationDataSelector),
        cities: IModelDictionaryDatum[] = useSelector(citiesSelector),
        citiesInitialized: boolean = useSelector(citiesInitializedSelector),
        privacyRulesUrl = useSelector((state) =>
            selectSystemParameter(
                state,
                userRole === UserRole.PURCHASER ? SystemParameterCode.PURCHASER_TOC_LINK : SystemParameterCode.COURIER_TOC_LINK
            )
        ),
        params = new URLSearchParams(location.search),
        firstName = params.get('firstName'),
        lastName = params.get('lastName'),
        username = params.get('email'),
        privacyPolicyUrl = useSelector((state) => selectSystemParameter(state, SystemParameterCode.PRIVACY_LINK)),
        [cityList, setCityList] = useState<IMultiselectOption[]>([]),
        [locale, setLocale] = useState<string>(LanguageLocaleType.PL.toString()),
        dispatch = useDispatch(),
        navigate = useNavigate(),
        isLoading = useSelector(isAuthPageLoadingSelector),
        {t} = useTranslation(),
        returnUrl = `${envData.REACT_APP_URL}/${userRole === UserRole.COURIER ? 'couriers' : 'auth'}/confirm-registration`,
        role =
            userRole === UserRole.COURIER
                ? UserRegistrationRoles.COURIER
                : userRole === UserRole.ROLE_FLEET_PARTNER
                ? UserRegistrationRoles.FLEET_PARTNER
                : null,
        {invitationToken} = useParams(),
        loginUrl = '/auth/login',
        [initialValues, setInitialValues] = useState<IRegistrationInput>({
            username: username ? username : '',
            password: '',
            repeatPassword: '',
            firstName: firstName ? firstName : '',
            lastName: lastName ? lastName : '',
            phone: '',
            country: '+48',
            locale: '',
            cityId: '',
            policy: false,
            returnUrl: '',
        });

    useEffect(() => {
        if (registrationData) {
            setInitialValues(registrationData);
        }
    }, [registrationData]);

    useEffect(() => {
        if (cities && cities.length > 0) {
            const cityListArray = convertToMultiselectLabels(cities);
            setCityList(cityListArray);
        }
    }, [cities]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.has('locale')) {
            setLocale(params.get('locale') as string);
        }
    }, [location]);

    useEffect(() => {
        if (locale && locale !== DEFAULT_LOCALE) {
            const localeType: LanguageLocale = transformLocaleToLocaleType(locale);
            dispatch(changeActiveLanguage(localeType));
        }
    }, [locale]);

    useEffect(() => {
        if (citiesInitialized === false) {
            dispatch(fetchCities());
        }
    }, [citiesInitialized]);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (userRole === UserRole.ROLE_FLEET_PARTNER && (isNullOrUndefined(invitationToken) || '' === invitationToken)) {
            navigate('/auth/login');
        }
    }, [userRole, invitationToken]);

    return (
        <Card elevation={16}>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <CardHeader
                key={'card_header'}
                subheader={
                    <Typography color="text.secondary" variant="body2" key="subheader">
                        <Translation text="auth.register.accountCreated" />
                        <Link to={loginUrl} component={ReactRouterLink} underline="hover" color="primary" variant="subtitle2">
                            <Translation text="auth.register.login" />
                        </Link>
                    </Typography>
                }
                sx={{pb: 0}}
                title={t('auth.register.title')}
            />
            <CardContent>
                <FormikForm
                    fields={registrationFormConfig(cityList, [
                        <Link component="a" href={privacyRulesUrl?.value} target="_blank" rel="noreferrer" key="regulations" />,
                        <Link component="a" href={privacyPolicyUrl?.value} target="_blank" rel="noreferrer" key="privacy_policy" />,
                    ])}
                    formId="register-form"
                    theme={theme}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, formikHelpers) => {
                        const nonMutableValues = deepCloneObject(values);
                        const nonMutableLocale = deepCloneObject(locale);
                        const registerPayload: IRegisterUserInput = {
                            username: nonMutableValues.username,
                            firstName: nonMutableValues.firstName,
                            lastName: nonMutableValues.lastName,
                            password: nonMutableValues.password,
                            locale: nonMutableLocale,
                            phone: {
                                country: nonMutableValues.country,
                                phone: nonMutableValues.phone,
                            },
                            type: role,
                            cityId: nonMutableValues.cityId,
                            returnUrl: returnUrl,
                        };
                        dispatch(setRegistrationData(nonMutableValues));
                        formikHelpers.setSubmitting(false);

                        if (isNotEmpty(invitationToken)) {
                            dispatch(registerUser(registerPayload, invitationToken));
                        } else {
                            dispatch(registerUser(registerPayload));
                        }
                    }}
                />
            </CardContent>
        </Card>
    );
};

export default Registration;
