import {Observable} from 'rxjs';
import {IApiSingleResponseBase, IPurchaserCurrentJobDetailsOutput, IUpdateJobInput} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const updateJobAPI: ApiOperationResponseFunction<IPurchaserCurrentJobDetailsOutput> = (
    authToken: string,
    payload: {job: IUpdateJobInput; id: string}
): Observable<IApiSingleResponseBase<IPurchaserCurrentJobDetailsOutput>> => {
    const updatePayload: IUpdateJobInput = payload.job,
        id: string = payload.id;
    return palipaliAPI.put(`api/jobs/${id}`, updatePayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
