import {createSelector} from '@reduxjs/toolkit';
import {IVehicleDefinitionsState} from '../reducers/vehicleDefinitionsSlice';

export const selectVehicleDefinitions = (state: any): IVehicleDefinitionsState => {
    return state.vehicleDefinitions;
};

export const groupedVehicleDefinitionsSelector = createSelector(
    [selectVehicleDefinitions],
    (state: IVehicleDefinitionsState) => state.groupedData
);

export const vehicleDefinitionsSelector = createSelector(
    [selectVehicleDefinitions],
    (state: IVehicleDefinitionsState) => state.vehicleDefinitions
);

export const vehicleDefinitionsLoadingSelector = createSelector(
    [selectVehicleDefinitions],
    (state: IVehicleDefinitionsState) => state.isLoading
);

export const vehicleDefinitionsErrorSelector = createSelector([selectVehicleDefinitions], (state: IVehicleDefinitionsState) => state.error);

export const vehicleDefinitionsByIdSelector = createSelector([vehicleDefinitionsSelector, (state, id) => id], (vehicleDefinitions, id) => {
    return vehicleDefinitions?.find((vehicleDefinition) => vehicleDefinition.id === id);
});
