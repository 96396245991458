import React from 'react';
import {CustomIconProps} from '../../../types/iconTypes';

const CheckedPackageIcon: React.FC<CustomIconProps> = ({...props}) => (
    <svg
        width={props.size ? props.size : 14}
        height={props.size ? props.size : 14}
        viewBox={`0 0 ${props.size ? props.size : 14} ${props.size ? props.size : 14}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="check">
            <path
                id="Icon"
                d="M11.6663 3.5L5.24967 9.91667L2.33301 7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default CheckedPackageIcon;
