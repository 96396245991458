import {IconButton, InputAdornment, TextField} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import CalendarIconSmall from '../../icons/untitled-ui/duocolor/calendar-small';
import {customLocaleTranslation} from '../../../utils/localeUtils';

interface DateTimePickerControlProps {
    formik: any;
    name: string;
    label: string;
    disablePast?: boolean;
    minDate?: Date | string | number;
    minDateTime?: Date | string | number;
    maxDate?: Date | string | number;
    startFromTomorrow?: boolean;
    className?: string;
    isRequired?: boolean;
    customEventChange?: (controlName: string, value: any) => void;
}

const DateTimePickerControl: React.FC<DateTimePickerControlProps> = ({
    formik,
    name,
    label,
    disablePast,
    className,
    customEventChange,
    minDate,
    minDateTime,
    maxDate,
    startFromTomorrow,
    isRequired,
}) => {
    const [open, setOpen] = useState(false),
        {t} = useTranslation(),
        today = new Date(),
        tomorrow = new Date(today);

    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customLocaleTranslation()}>
            <DateTimePicker
                label={t(label)}
                key={`datetimepicker-${name}`}
                className={`form-control-input-wrapper-${className || ''}`}
                value={formik.values[name]}
                minDateTime={minDateTime}
                ampm={false}
                dayOfWeekFormatter={(day) => t(`datepickerWeekday.${day}`)}
                onChange={(date) => {
                    if (customEventChange) {
                        customEventChange(name, date);
                    }
                    formik.setFieldValue(name, date);
                }}
                onAccept={(date) => {
                    if (customEventChange) {
                        customEventChange(name, date);
                    }
                    formik.setFieldValue(name, date);
                }}
                open={open}
                minDate={minDate ? minDate : startFromTomorrow ? tomorrow : undefined}
                maxDate={maxDate}
                onOpen={() => setOpen(true)}
                disablePast={disablePast}
                inputFormat={'dd.MM.yyyy HH:mm'}
                onClose={() => setOpen(false)}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        key={`datetimepicker-textfield-${name}`}
                        error={formik.touched[name] && formik.errors[name] ? true : false}
                        helperText={formik.touched[name] && typeof formik.errors[name] ? (formik.errors[name] as string) : undefined}
                        onBlur={() => formik.setFieldTouched(name, true)}
                        onKeyDown={(event) => event.preventDefault()}
                        onClick={() => setOpen(true)}
                        className={`date-time-picker-input ${className || ''}`}
                        required={isRequired}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CalendarIconSmall />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default DateTimePickerControl;
