import React, {useEffect, useState} from 'react';
import {useRoutes} from 'react-router-dom';
import {Theme} from '@mui/material';
import {useSelector} from 'react-redux';
import {accountThemeSelector} from '../../../store/selectors/accountSelectors';
import {deepCloneObject} from '../../../utils/objectUtils';
import {APP_THEME_DARK, APP_THEME_NORMAL} from '../../../store/reducers/accountSlice';
import {createTheme} from '../../themeBase';
import {ColorPreset} from '../../..';

interface ThemeProps {
    theme: Theme;
    settings: any;
    routes: any;
    colorPreset?: ColorPreset;
}
const ThemeRouterHost: React.FC<ThemeProps> = ({settings, routes, colorPreset}) => {
    const themeValue: string = useSelector(accountThemeSelector);
    const [currentTheme, setCurrentTheme] = useState(themeValue || APP_THEME_NORMAL);

    useEffect(() => {
        setCurrentTheme(themeValue);
    }, [themeValue]);

    const updatedSettings = deepCloneObject(settings);
    updatedSettings.paletteMode = currentTheme === APP_THEME_DARK ? 'dark' : 'light';

    const updatedTheme = createTheme({
        colorPreset: colorPreset ? colorPreset : updatedSettings.paletteMode,
        contrast: updatedSettings.contrast,
        direction: updatedSettings.direction,
        paletteMode: updatedSettings.paletteMode,
        responsiveFontSizes: false,
    });

    document.body.style.setProperty('--bs-link-hover-color', updatedTheme.palette.primary.main);

    const element = useRoutes(routes(updatedTheme, updatedSettings));

    return <>{element}</>;
};

export default ThemeRouterHost;
