import type {Components} from '@mui/material/styles/components';
import {createTheme, inputLabelClasses, tableCellClasses} from '@mui/material';
import React from 'react';

// Used only to create transitions
const muiTheme = createTheme();

export const createComponents = (): Components => {
    return {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.search': {
                        paddingBottom: 0,
                        minWidth: '15rem',
                        '.MuiInputBase-input': {
                            height: '2.3rem',
                            paddingTop: '2.5rem',
                            paddingRight: '1.2rem',
                            paddingBottom: '0.8rem',
                            paddingLeft: '1.2rem',
                        },
                    },
                    '&.multiselect': {
                        paddingBottom: 0,
                        marginRight: '2rem',
                        minWidth: '15rem',
                        icon: {
                            right: '1.5rem',
                        },
                    },
                    '&.custom-mui-select': {
                        minWidth: '15rem',
                        '&.phone-code-select': {
                            minWidth: '11rem',
                        },
                        icon: {
                            right: '1.5rem',
                        },
                    },
                    '&.date-range-picker': {
                        paddingBottom: 0,
                        width: '25rem',
                        '.MuiIconButton-root': {
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            padding: 16,
                            justifyContent: 'flex-end',
                            borderRadius: 0,
                        },
                    },
                    '&.datepicker': {
                        paddingBottom: 0,
                        width: '20rem',
                    },
                    flex: 1,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 600,
                    letterSpacing: 0,
                    background: '#fff',
                    width: '4.8rem',
                    height: '4.8rem',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    background: 'rgba(0,0,0,0.25)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '1.2rem',
                    textTransform: 'none',
                    '&.btn-action': {
                        border: 'none',
                        color: '#111927',
                    },
                    '&.Mui-disabled': {
                        opacity: 0.25,
                    },
                    '.MuiTouchRipple-rippleVisible': {
                        color: 'rgba(0,0,0,0.2)',
                    },
                    '.MuiButton-startIcon': {
                        marginLeft: 0,
                    },
                },
                sizeSmall: {
                    padding: '0.6rem 1.6rem',
                },
                sizeMedium: {
                    padding: '0.6rem 1.6rem',
                    fontSize: '1.4rem',
                    fontWeight: 600,
                },
                sizeLarge: {
                    padding: '1.1rem 2.4rem',
                    height: '4.2rem',
                    fontSize: '1.5rem',
                },
                textSizeSmall: {
                    padding: '0.6rem 1.2rem',
                },
                textSizeMedium: {
                    padding: '0.7rem 1.6rem',
                },
                textSizeLarge: {
                    padding: '1.2rem 1.6rem',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    borderRadius: 20,
                    overflow: 'unset',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '1.6rem 2.4rem 3.2rem 2.4rem',
                    '&:last-child': {
                        paddingBottom: '3.2rem',
                    },
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '1.6rem 2.4rem',
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: 'h6',
                },
                subheaderTypographyProps: {
                    variant: 'body2',
                },
            },
            styleOverrides: {
                root: {
                    padding: '3.2rem 2.4rem 1.6rem',
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                checkedIcon: (
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            clipRule="evenodd"
                            d="M9 3.5C5.68629 3.5 3 6.18629 3 9.5V15.5C3 18.8137 5.68629 21.5 9 21.5H15C18.3137 21.5 21 18.8137 21 15.5V9.5C21 6.18629 18.3137 3.5 15 3.5H9ZM16.7179 10.1961C17.1024 9.79966 17.0926 9.16657 16.6961 8.7821C16.2997 8.39763 15.6666 8.40737 15.2821 8.80385L10.6667 13.5635L8.7179 11.5539C8.33343 11.1574 7.70034 11.1476 7.30385 11.5321C6.90737 11.9166 6.89763 12.5497 7.2821 12.9461L9.94877 15.6961C10.1371 15.8904 10.3961 16 10.6667 16C10.9372 16 11.1962 15.8904 11.3846 15.6961L16.7179 10.1961Z"
                            fill="currentColor"
                            fillRule="evenodd"
                        />
                    </svg>
                ),
                color: 'primary',
                icon: (
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <rect height="16" rx="5" stroke="currentColor" strokeWidth="2" width="16" x="4" y="4.5" />
                    </svg>
                ),
                indeterminateIcon: (
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            clipRule="evenodd"
                            d="M9 5.5H15C17.2091 5.5 19 7.29086 19 9.5V15.5C19 17.7091 17.2091 19.5 15 19.5H9C6.79086 19.5 5 17.7091 5 15.5V9.5C5 7.29086 6.79086 5.5 9 5.5ZM3 9.5C3 6.18629 5.68629 3.5 9 3.5H15C18.3137 3.5 21 6.18629 21 9.5V15.5C21 18.8137 18.3137 21.5 15 21.5H9C5.68629 21.5 3 18.8137 3 15.5V9.5ZM8 11.5C7.44772 11.5 7 11.9477 7 12.5C7 13.0523 7.44772 13.5 8 13.5H16C16.5523 13.5 17 13.0523 17 12.5C17 11.9477 16.5523 11.5 16 11.5H8Z"
                            fill="currentColor"
                            fillRule="evenodd"
                        />
                    </svg>
                ),
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                },
                html: {
                    MozOsxFontSmoothing: 'grayscale',
                    WebkitFontSmoothing: 'antialiased',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    width: '100%',
                },
                body: {
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    minHeight: '100%',
                    width: '100%',
                },
                '#root, #__next': {
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                },
                '#nprogress': {
                    pointerEvents: 'none',
                },
                '#nprogress .bar': {
                    height: 3,
                    left: 0,
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 2000,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    padding: 4,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    right: '1.6rem',
                },
                select: {
                    '.MuiTypography-root': {
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: 56,
                },
                input: {
                    '&::placeholder': {
                        opacity: 1,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '2.4rem',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    borderRadius: 8,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    overflow: 'hidden',
                    transition: muiTheme.transitions.create(['border-color', 'box-shadow']),
                    '&:before': {
                        display: 'none',
                    },
                    '&:after': {
                        display: 'none',
                    },
                    '&.Mui-disabled': {
                        opacity: 0.6,
                        cursor: 'not-allowed',
                    },
                },
                input: {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '2.4rem',
                    '&.Mui-disabled': {
                        opacity: 0.6,
                        cursor: 'not-allowed',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '2.4rem',
                },
                notchedOutline: {
                    top: 0,
                    transition: muiTheme.transitions.create(['border-color', 'box-shadow']),
                    borderWidth: 1,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&.switch-form-control-wrapper': {
                        marginLeft: 0,
                        marginRight: 0,
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        paddingBottom: '2.4rem',
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 500,
                    [`&.${inputLabelClasses.filled}`]: {
                        transform: 'translate(16px, 18px) scale(1)',
                    },
                    [`&.${inputLabelClasses.shrink}`]: {
                        [`&.${inputLabelClasses.standard}`]: {
                            transform: 'translate(0, -1.5px) scale(0.85)',
                        },
                        [`&.${inputLabelClasses.filled}`]: {
                            transform: 'translate(12px, 6px) scale(0.85)',
                        },
                        [`&.${inputLabelClasses.outlined}`]: {
                            transform: 'translate(12px, 6px) scale(0.85)',
                        },
                    },
                },
                asterisk: {color: '#F04438'},
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                    overflow: 'hidden',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        color: 'primary.main',
                    },
                    '&:active': {
                        color: 'primary.main',
                    },
                },
            },
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    marginRight: '16px',
                    minWidth: 'unset',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    '&.MuiDrawer-paper': {
                        borderRight: 'none',
                    },
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    '&.MuiModal-root': {
                        transform: 'unset',
                    },
                },
            },
            defaultProps: {
                elevation: 16,
            },
        },
        MuiRadio: {
            defaultProps: {
                color: 'primary',
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    transform: 'translateY(0.3rem)',
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: 'primary',
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.71,
                    minWidth: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0,
                    textTransform: 'none',
                    '& + &': {
                        marginLeft: 24,
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child .MuiTableCell-root': {
                        borderBottom: 'none',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '1rem 1.6rem',
                    '&.MuiTableCell-head': {
                        padding: '1.6rem',
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        fontSize: 12,
                        fontWeight: 600,
                        lineHeight: 1,
                        letterSpacing: 0.5,
                        textTransform: 'uppercase',
                    },
                    [`& .${tableCellClasses.paddingCheckbox}`]: {
                        paddingTop: 4,
                        paddingBottom: 4,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    paddingBottom: 24,
                    '.MuiFormHelperText-root.Mui-error': {
                        bottom: '0.5rem',
                        fontSize: '1rem',
                        position: 'absolute',
                        padding: 0,
                    },
                },
            },
            defaultProps: {
                variant: 'filled',
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: '0.8rem 0',
                },
            },
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    padding: '0',
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    fontWeight: 500,
                    color: '#6C737F',
                    '&.MuiStepLabel-active': {
                        color: '#111927',
                    },
                },
            },
        },

        MuiStepConnector: {
            styleOverrides: {
                root: {
                    padding: '0',
                    margin: '0 2.4rem',
                },
                line: {
                    borderLeftColor: 'divider',
                    borderLeftWidth: 2,
                    flexDirection: 'column',
                },
            },
        },
    };
};
