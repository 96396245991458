import React from 'react';
import {IPhoneOutput} from '../../model/common';
import {formatPhoneNumber} from '../../utils/phoneUtils';
interface IPhoneProps {
    readonly phone: IPhoneOutput | null | undefined;
}
const PhoneComponent: React.FC<IPhoneProps> = (props: IPhoneProps) => {
    if (!props.phone) return <span>-</span>;

    return <span>{formatPhoneNumber(props.phone)}</span>;
};

export default PhoneComponent;
