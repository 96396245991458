import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import {debounce} from '@mui/material/utils';
import {IconButton, Stack} from '@mui/material';
import Geocode from 'react-geocode';
import {useEffect} from 'react';
import {IGeoPointInput} from '../../../model/common';
import BookClosedIcon from '../../icons/untitled-ui/duocolor/book-closed';
import ChevronDownIcon from '../../icons/untitled-ui/duocolor/chevron-down';
import {IGoogleAddressParts} from '../../../model/location';
import getAddressLocation from '../../../utils/autocompleteUtils';
import {useTranslation} from 'react-i18next';
import {ValidationErrorType} from '../../../utils/formValidators';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
    if (!position) {
        return;
    }

    if (GOOGLE_MAPS_API_KEY) {
        Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
};

const autocompleteService = {current: null};

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
}

export interface IAddressAutocompleteProps {
    label: string;
    defaultValue?: string;
    noOptionText?: string | null;
    onChange?: (addressLine: string, point: IGeoPointInput | null, addressParts: IGoogleAddressParts) => void;
    onAddressClear?: () => void;
    isAddressBookShown?: boolean;
    onAddressBookAction?: () => void;
    isRequired?: boolean;
    isError?: boolean;
}

const AddressAutocomplete: React.FC<IAddressAutocompleteProps> = ({
    label,
    defaultValue,
    noOptionText,
    onChange,
    isAddressBookShown,
    onAddressBookAction,
    onAddressClear,
    isRequired,
    isError,
}) => {
    const [value, setValue] = React.useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
    const loaded = React.useRef(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (defaultValue) {
            setValue({
                description: defaultValue,
                structured_formatting: {
                    main_text: '',
                    secondary_text: '',
                },
            });
        }
    }, [defaultValue]);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps'
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            debounce((request: {input: string}, callback: (results?: readonly PlaceType[]) => void) => {
                (autocompleteService.current as any).getPlacePredictions(request, callback);
            }, 400),
        []
    );

    const getAddressLocationValue = (address: string) => {
        if (address && Geocode) {
            Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
            Geocode.fromAddress(address).then(
                (response) => {
                    const addressParts = getAddressLocation(response.results[0]);
                    const {lat, lng} = response.results[0].geometry.location;
                    const location = {
                        latitude: lat,
                        longitude: lng,
                    };
                    if (onChange) {
                        onChange(address, location, addressParts);
                    }
                },
                (error) => {
                    console.error(error);
                }
            );
        }
        return null;
    };

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({input: inputValue}, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const handleInputChange = (event, value, reason) => {
        const removedValueFromFilledInput = value === '' && inputValue !== '';
        if ((reason === 'clear' && onAddressClear) || removedValueFromFilledInput) {
            onAddressClear();
        }
        setInputValue(value);
    };

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={{xs: 1, sm: 2, md: 4}} className="address-autocomplete-control">
            <Stack className="address-autocomplete-wrapper">
                <Autocomplete
                    id="google-autocomplete"
                    sx={{width: '100%'}}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    defaultValue={
                        defaultValue
                            ? {
                                  description: defaultValue,
                                  structured_formatting: {
                                      main_text: '',
                                      secondary_text: '',
                                  },
                              }
                            : null
                    }
                    filterOptions={(x) => x}
                    options={options}
                    clearText={`${t('general.clearTextButton')}`}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    className={`${label}-form-control-select-wrapper custom-mui-select`}
                    noOptionsText={noOptionText || ''}
                    onChange={(event, newValue: PlaceType | null) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue);
                        if (newValue?.description) {
                            getAddressLocationValue(newValue.description);
                        }
                    }}
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                        <TextField
                            {...(params as any)}
                            required={isRequired}
                            error={isError}
                            helperText={isError ? t(ValidationErrorType.IS_AUTOCOMPLETE_ADDRESS_VALID) : ''}
                            label={label}
                            fullWidth
                            className={`${label}-form-control-select-wrapper custom-mui-select ${label || ''}`}
                        />
                    )}
                    popupIcon={<ChevronDownIcon />}
                    renderOption={(props, option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings || [];

                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [match.offset, match.offset + match.length])
                        );

                        return (
                            <li {...props}>
                                <Grid container alignItems="center">
                                    <Grid item sx={{display: 'flex', width: 44}}>
                                        <LocationOnIcon sx={{color: 'text.secondary'}} />
                                    </Grid>
                                    <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                        {parts.map((part, index) => (
                                            <Box key={index} component="span" sx={{fontWeight: part.highlight ? 'bold' : 'regular'}}>
                                                {part.text}
                                            </Box>
                                        ))}
                                        <Typography variant="body2" color="text.secondary">
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />
            </Stack>
            {isAddressBookShown ? (
                <IconButton
                    className="address-book-control"
                    onClick={() => {
                        if (onAddressBookAction) {
                            onAddressBookAction();
                        }
                    }}>
                    <BookClosedIcon />
                </IconButton>
            ) : null}
        </Stack>
    );
};

export default AddressAutocomplete;
