const en = {
    app: {
        title: 'Fleet Partner Panel',
        courierTitle: 'Courier',
        companyTitle: 'PaliPali',
        errorPages: {
            401: {
                title: '401 - Unauthorised',
                description: 'You are not authorised to view this page.',
                buttons: {
                    backToHome: 'Back to home page',
                },
            },
            404: {
                title: '404 - Page not found',
                description: 'The page you are looking for does not exist.',
                buttons: {
                    backToHome: 'Back to home page',
                },
            },
            500: {
                title: '500 - Server error',
                description: 'Something has gone wrong. Try again later.',
                buttons: {
                    backToHome: 'Back to home page',
                },
            },
        },
        validation: {
            phone_taken: 'Phone number you entered is already registered in the system.',
            user_does_not_exists: 'User with the specified name does not exist.',
            user_already_confirmed: 'User with the given name is already confirmed.',
            object_with_given_id_does_not_exists: 'Object with specified id does not exist.',
            user_is_not_owner_of_object: 'User does not own the facility.',
            object_must_implement_ownable_interface: 'Object must implement OwnableInterface in order to pass validation.',
            value_not_part_of_enum: 'Specified value is not a valid value',
            location_not_supported:
                "The execution of the order is not possible because the locations are not within the application's operating area.",
            invalid_domain: 'Url contains an invalid domain.',
            invalid_vehicle: 'No authorisation to use this vehicle.',
            vehicle_occupied: 'Vehicle is occupied by another user.',
            incompatible_package_sizes: 'Selected parcel sizes are not acceptable for this vehicle.',
            not_full_team_member: 'User is not a full member of the team.',
            not_team_member: 'User is not a member of the team.',
            insufficient_team_role: 'User does not have the appropriate rights in the team or is not a member of it.',
            missing_phone_in_from_location: 'Telephone number of the sending point is required.',
            not_fleet_courier: 'Fleet courier does not belong to a fleet partner.',
            location_type_not_accessible: 'This type of location is not allowed.',
            invalid_city_limits: 'City boundaries given are in the wrong format.',
            enum_value_not_not_supported: 'Specified value of the %value% enum is not supported.',
            greater_than_now: 'Date should be in the future.',
            invalid_nip_length: 'NIP must have exactly 10 digits.',
            invalid_nip_number: 'NIP entered is incorrect.',
            locale_not_supported: 'Specified location is not supported.',
            no_package_size_selected: 'You must select at least one category.',
            locations_have_the_same_localisation: 'selected pick-up and delivery points have the same location.',
        },
        exception: {
            object_with_given_id_does_not_exists: 'Object with specified id does not exist.',
            user_is_not_owner_of_object: 'User does not own the facility.',
            object_must_implement_ownable_interface: 'Object must implement OwnableInterface in order to pass validation.',
            value_not_part_of_enum: 'Specified value is not a valid value',
            location_not_supported:
                "The execution of the order is not possible because the locations are not within the application's operating area.",
            invalid_domain: 'Url contains an invalid domain.',
            invalid_vehicle: 'No authorisation to use this vehicle.',
            vehicle_occupied: 'Vehicle is occupied by another user.',
            incompatible_package_sizes: 'Selected parcel sizes are not acceptable for this vehicle.',
            not_full_team_member: 'User is not a full member of the team.',
            not_team_member: 'User is not a member of the team.',
            insufficient_team_role: 'User does not have the appropriate rights in the team or is not a member of it.',
            missing_phone_in_from_location: 'Telephone number of the sending point is required.',
            not_fleet_courier: 'Fleet courier does not belong to a fleet partner.',
            location_type_not_accessible: 'This type of location is not allowed.',
            invalid_city_limits: 'City boundaries given are in the wrong format.',
            user_already_exists: 'User with this e-mail address already exists.',
            file_not_provided: 'File not attached.',
            authentication_token_could_not_be_resolved: 'Authorisation token cannot be handled.',
            concurrent_conflict: 'Operation cannot be implemented due to a conflict.',
            cant_change_role_to_owner: 'You cannot change the role of the user who owns.',
            invalid_role_invited: 'You can only invite members with a role lower than yours.',
            invalid_team_member_invitation: 'This invitation is invalid.',
            cant_invite_deleted_user: 'Invited user has been deleted and may not be invited again.',
            invalid_date_format: 'Incorrect date format.',
            invalid_invitation: 'Incorrect invitation.',
            invalid_invitation_action: 'Unauthorised action on an invitation.',
            invalid_recipient_data: 'Recipients details must include the team members id or name and telephone number.',
            invalid_job_location_data: 'Task location data must contain the location id or location data including address and location.',
            location_not_allowed: 'Location indicated is not public or does not belong to the selected team.',
            courier_job_proposal_rejected: 'An order proposal that has been rejected cannot be accepted.',
            courier_job_proposal_accepted: 'A job proposal that has been accepted cannot be rejected.',
            courier_job_proposal_expired: 'This order is no longer available.',
            invalid_job_problem_type: 'Incorrect type of assignment problem.',
            invalid_uuid_provided: 'Specified id is incorrect.',
            invalid_vehicle_provided: 'Vehicles indicated do not exist or there is no authority to use them in the order.',
            invoicing_service_exception: 'Failed invoice download.',
            invalid_phone_number: 'Incorrect telephone number specified.',
            cant_download_invoice: 'Failed invoice download.',
            account_not_confirmed: 'Account has not been confirmed.',
            account_not_active: 'Account is not active.',
            account_deleted: 'Account has been deleted.',
            account_is_invitation: 'Account is an invitation.',
            user_not_found: 'User entered does not exist.',
            payment_method_cannot_be_captured:
                'Selected payment method cannot currently be used. Please check if you have sufficient funds.',
            not_allowed_to_login: 'You cannot log in to this application with this account.',
            invalid_identifier: 'Specified identifier is invalid.',
        },
        timeComponent: {
            estimatedTime: ' (expected time)',
        },
    },
    logoutModal: {
        title: 'Log out',
        content: 'Are you sure you want to log off?',
        logout: 'Log out',
    },
    reports: {
        title: 'Reports',
        downloadReport: 'Download CSV',
        totals: {
            timeOfRealisation: 'Lead time',
            distanceDone: 'Distance covered',
            gain: 'Revenue',
        },
        graphs: {
            income: 'Revenue',
            jobsDone: 'Jobs Done',
            numberOfActiveCouriers: 'Number of active couriers',
            totalDistance: 'Distance covered',
        },
        reportsList: {
            noDataInfo: 'No data available',
            filters: {
                title: 'Filters',
                courier: 'Courier',
                city: 'City',
                fleetVehicles: 'Fleet vehicles',
                courierVehicles: 'Courier vehicles',
            },
            headers: {
                courier: 'Courier',
                city: 'City',
                jobsDone: 'Orders completed',
                jobsReported: 'orders notified',
                jobsCanceled: 'Orders cancelled',
                time: 'Lead time',
                distance: 'Distance covered',
                income: 'Revenue',
                date: 'Period',
                filters: 'Filters',
            },
        },
    },
    account: {
        title: 'Account',
        subtitle: {
            profile: 'Profile',
            settlement: 'Settlements',
            settings: 'Settings',
        },
        profile: {
            title: 'Profile',
            formControls: {
                username: 'Email',
                phone: 'Phone number',
                dialingCode: 'Country code',
                firstName: 'First name',
                lastName: 'Last name',
                cityId: 'City',
            },
            buttons: {
                update: 'Update',
            },
        },
        status: {
            inactive: 'Inactive',
            active: 'Active',
            toFill: 'To be completed',
        },
        settings: {
            title: 'Settings',
            formControls: {
                language: 'Language',
                highContrastMode: 'High contrast mode',
            },
            highContrastMode: 'High contrast mode',
            highContrastModeSubtitle: 'Indicated for the visually impaired',
            status: {
                inactive: 'Inactive',
                active: 'Active',
            },
        },
        changePassword: {
            title: 'Password change',
            formControls: {
                oldPassword: 'Actual password',
                newPassword: 'New password',
                confirmNewPassword: 'Repeat new password',
            },
        },
        deleteProfile: {
            title: 'Delete account',
            subtitle: 'Are you sure you want to delete your account and all the data associated with it?',
            warning: 'This operation is irreversible.',
            cannotBeDeleted: 'You cannot delete your account now.',
            buttons: {
                deleteAccount: 'Delete account',
            },
            alerts: {
                deleted: 'Account has been deleted.',
            },
        },
        info: {
            name: 'First name',
            surname: 'Last name',
            email: 'Email',
            tel: 'Phone number',
            city: 'City',
            language: 'Language',
            theme: 'Theme',
        },
        settlements: {
            title: 'Settlements',
            status: {
                active: 'Active',
                inActive: 'To fill',
            },
            billingInfoCard: {
                title: 'Settlement information',
                formControls: {
                    companyName: 'Company name',
                    taxIdentifier: 'NIP number',
                    street: 'Street',
                    houseNumber: 'Building number',
                    flatNumber: 'Local number',
                    city: 'City',
                    zip: 'Zip code',
                },
                alert: {
                    success: 'Successfully updated settlement information.',
                },
            },
            stripeAccountCard: {
                title: 'Settlement account',
                inactive: {
                    info: 'Stripe settlement account is necessary for processing payments for completed orders.',
                    warning: 'To set up a settlement account, first enter your settlement information.',
                },
                active: {
                    info: 'If you want to change your company data go through the Stripe settlement account configuration again.',
                },
                button: 'Configure your Stripe settlement account',
            },
        },
        locale: {
            en_US: 'english',
            pl_PL: 'polish',
            uk_UA: 'українська',
        },
        themes: {
            dark: 'High contrast',
            normal: 'Usual',
        },
        modal: {
            title: 'Select avatar',
        },
        alert: {
            imageAddSuccess: 'Photo added.',
            miscChangeSuccess: 'Theme has been changed',
            changeLocaleSuccess: 'Language has been changed',
            changePasswordSuccess: 'Password has been changed',
            deleteAccountSuccess: 'Account has been deleted',
            updateProfileDataSuccess: 'Data has been updated',
            changeEmailSuccess: 'Email address is changed',
        },
    },
    profileEdit: {
        title: 'Profile',
        formControls: {
            username: 'Email',
            phone: 'Phone number',
            dialingCode: 'Country code',
            firstName: 'First name',
            lastName: 'Last name',
            cityId: 'City',
        },
        links: {
            changePassword: 'Password change',
            deleteAccount: 'Delete account',
        },
        settings: {
            title: 'Settings',
            formControls: {
                language: 'Language',
                highContrastMode: 'High contrast mode',
            },
        },
        resetPassword: {
            title: 'Password change',
            formControls: {
                oldPassword: 'Actual password',
                newPassword: 'New password',
                confirmNewPassword: 'Confirm new password',
            },
            buttons: {
                saveChanges: 'Save changes',
            },
        },
    },
    settlements: {
        title: 'Settlements',
        noSettlements: 'No settlement',
        date: 'Period',
        summary: {
            current: 'Current',
            inSettlement: 'In the settlement',
            revenueSettlement: 'Revenue settled',
            invoiceCount: 'from  {{invoiceCount}} invoices',
        },
        settled: 'Settled ({{settlementsCount}})',
        income: 'Revenue',
        downloadInvoice: 'Download the invoice',
    },
    vehicles: {
        title: 'Vehicles',
        content: 'Content',
        vehicleOperation: {
            title: {
                create: 'New vehicle',
                backButton: 'Back',
            },
            basicInformation: {
                title: 'General information',
                form: {
                    registrationNumber: {
                        label: 'Licence plate number',
                        placeholder: 'Enter licence plate number',
                    },
                    make: {
                        label: 'Brand',
                        placeholder: 'Select a brand',
                    },
                    model: {
                        label: 'Model',
                        placeholder: 'Select a model',
                    },
                    doorNumber: {
                        label: 'Side number',
                        placeholder: 'Enter side number',
                    },
                    vehicleTypeId: {
                        label: 'Body type',
                        placeholder: 'Select body type',
                    },
                    productionYear: {
                        label: 'Year of production',
                        placeholder: 'Select the year of production',
                    },
                    colorId: {
                        label: 'Vehicle colour',
                        placeholder: 'Select a colour',
                    },
                },
            },
            inspection: {
                title: 'Registration certificate',
                form: {
                    until: {
                        label: 'Expiry date',
                        placeholder: 'Select a date',
                    },
                    documentPhoto: {
                        label: 'Photo of document',
                        labelFront: 'Photo of document - front',
                        labelBack: 'Photo of document - back',
                        placeholderFront: 'Photo of the front',
                        placeholderBack: 'Photo of the back',
                        description: `Here you can add a photo of your insurance, two photos titled - 'front' and 'back' are required.`,
                        button: {
                            add: 'Add',
                            delete: 'Delete',
                        },
                    },
                },
            },
            insurance: {
                title: 'Insurance',
                form: {
                    number: {
                        label: 'Public liability policy number',
                        placeholder: 'Enter the number',
                    },
                    until: {
                        label: 'Expiry date',
                        placeholder: 'Select date',
                    },
                    documentPhoto: {
                        label: 'Photo of document',
                        description: `Here you can add a photo of your insurance, two photos titled - 'front' and 'back' are required.`,
                        button: {
                            add: 'Add',
                            delete: 'Delete',
                        },
                    },
                },
            },
            buttons: {
                next: 'Next',
                back: 'Back',
                create: 'Create a vehicle',
                update: 'Update',
            },
            alerts: {
                success: {
                    createVehicle: 'Vehicle has been created',
                    updateVehicleInsurance: 'Insurance has been updated.',
                    updateVehicleInspection: 'Registration certificate has been updated.',
                },
            },
        },
        vehicleList: {
            title: 'List of vehicles',
            table: {
                columnTitles: {
                    number: 'Serial number',
                    vehicle: 'Vehicle',
                    registrationNo: 'Registration number',
                    jobCategories: 'Available order categories',
                    status: 'Status',
                    actions: 'Actions',
                },
                buttons: {
                    editVehicle: 'Edit vehicle',
                    viewVehicle: 'Details of vehicle',
                    addVehicle: 'Add vehicle',
                    deleteVehicle: 'Delete vehicle',
                    couriers: 'Couriers ({{count}})',
                    details: 'Details',
                },
                filterButtons: {
                    all: 'All',
                    verified: 'Active',
                    unverified: 'Inactive',
                    pending: 'Verification in progress',
                },
                searchInputLabel: 'Search',
                noResults: 'No vehicles',
            },
            deleteVehicle: {
                modal: {
                    title: 'Delete vehicle',
                    content: 'Are you sure you want to delete the vehicle?',
                    subtitle: 'This operation is irreversible.',
                    buttons: {
                        deleteVehicle: 'Delete vehicle',
                    },
                },
                alerts: {
                    cannotDeleteVehicle: 'You do not have the authority to remove the vehicle.',
                    deleteSuccess: 'Vehicle was removed.',
                },
            },
            assignedCouriers: {
                modal: {
                    title: 'Couriers assigned to a vehicle',
                    table: {
                        columnTitles: {
                            assigned: 'Assigned courier',
                            available: 'Available couriers',
                        },
                        buttons: {
                            update: 'Update',
                        },
                        noResults: 'No couriers',
                    },
                    alerts: {
                        success: 'Successful reassignment of couriers to vehicles.',
                        notAssigned: 'Some couriers were not assigned to a vehicle.',
                    },
                },
            },
        },
        vehicleDetails: {
            title: 'Vehicle',
            basicInformation: {
                title: 'General information',
            },
            insuranceSettings: {
                title: 'Insurance',
            },
            inspectionSettings: {
                title: 'Registration certificate',
            },
            buttons: {
                back: 'Back',
                update: 'Update',
                delete: 'Delete vehicle',
            },
            deleteVehicle: {
                title: 'Delete of the vehicle',
                subtitle: 'Delete the vehicle. This operation is irreversible.',
            },
            documentStatus: {
                active: 'Verified',
                rejected: 'Rejected',
                pending: 'Verification in progress',
                inactive: 'Outdated',
                verified: 'Verified',
                obsolete: 'Outdated',
                to_be_filled: 'To be completed',
                expiring: 'Expires',
            },
        },
        status: {
            verified: 'Active',
            unverified: 'Inactive',
            pending: 'Verification in progress',
            verification_pending: 'Verification in progress',
            active: 'Active',
            inactive: 'Inactive',
            expiring: 'Expiring',
            to_be_filled: 'To be filled in',
        },
        deliveryStatus: {
            planned: 'Scheduled',
            searching: 'Searching',
            drive: 'On the road',
            pickup: 'Taking delivery',
            delivery: 'Delivery of the consignment',
            delivered: 'Provided by',
            cancelledPurchaser: 'Cancelled',
            cancelledCourier: 'Cancelled by Courier',
            problemPurchaser: 'Reported',
            problemCourier: 'Reported by Courier',
            deliveryDrive: 'In delivering',
            failed: 'Failure',
            online: 'Online',
        },
    },
    activeCouriers: {
        noOnboardingInfo: {
            subtitle: 'Inactive account',
            title: 'Settlement account configuration required',
            description:
                'In order to start cooperation as a Fleet Partner, you need to configure your Stripe billing account, which is necessary to make settlements for completed orders.',
            button: 'Configure billing account',
        },
        list: {
            title: 'Active couriers',
            noActiveCouriers: 'No active couriers',
            vehicleOwnership: {
                assigned: 'vehicle Partner',
                owned: 'vehicle own',
            },
        },
        noActiveCouriers: 'No active couriers',
    },
    couriers: {
        noCouriers: 'No couriers',
        title: 'Couriers',
        filters: {
            all: 'All',
            active: 'Active',
            inactive: 'Inactive',
            invited: 'Invited',
            city: 'City',
            search: 'Search',
            allCities: 'All cities',
            filterButtons: {
                active: 'Active',
                inactive: 'Inactive',
                invited: 'Invited',
                all: 'All',
            },
        },
        table: {
            courier: 'Courier',
            city: 'City',
            status: 'Status',
        },
        buttons: {
            vehicles: 'Vehicles ({{ count }})',
            delete: 'Delete',
            addCourier: 'Invite Courier',
            refresh: 'Refresh',
        },
        courierStatus: {
            active: 'Active',
            inactive: 'Inactive',
            invited: 'Invited',
            online: 'Online',
        },
        inviteCourier: {
            title: 'Invite Courier',
            description: 'Enter the Couriers email address, to which we will send an invitation to your Fleet Partner account.',
            note: 'If the courier does not have an account in the system, he will have to register.',
            helper: 'Courier will find your invitation under:',
            helperRoad: `Settings -> Billing -> Fleet Partner`,
            button: 'Send invitation',
            email: 'Email',
            alerts: {
                successInvitation: 'Courier was invited.',
                unsuccessfulInvitation: 'This courier has deleted his account and can no longer be invited.',
                successUnassignment: 'Courier successfully removed',
            },
        },
        unassignCourier: {
            title: 'Delete Courier account',
            checking: 'Checking the possibility of deleting the Courier account...',
            description: 'Are you sure you want to delete Courier from your account?',
            cannotBeUnassigned: 'You can not untie that courier now.',
            warning: 'This operation is irreversible.',
            button: 'I confirm the deletion of my account',
        },
        assignVehicle: {
            title: 'Vehicles assigned to Courier',
            description: 'If you do not see your vehicle, check its status under',
            descriptionVehicles: ' Vehicles.',
            availableVehicles: 'Available vehicles',
            assignButton: 'Update',
            vehiclesAssigned: 'The list of vehicles assigned to couriers has been updated.',
            vehiclesAssignedError: 'Some assigned vehicles could not be updated.',
            noVehicle: 'No vehicles to be assigned.',
        },
    },
    auth: {
        header: {
            courier: 'Courier',
        },
        authFooter: {
            srTitle: '',
            addressColumn: {
                title: 'PaliPali',
            },
            contactColumn: {
                title: 'Contact',
                correspondenceAddressTitle: 'Address for correspondence:',
            },
            linksColumn: {
                title: 'PaliPali',
                aboutUs: 'About us',
                career: 'Career',
                cooperation: 'Cooperation',
                contact: 'Contact',
                policies: 'Regulations and privacy policy',
                safePayments: 'Safe payments',
            },
            newsletterColumn: {
                title: 'Stay updated',
                subscribeInfo: 'Subscribe to our newsletter',
                newsletterLabelSR: 'Enter your email address to receive the newsletter',
                newsletterSubmitButtonSR: 'Newsletter subscription form approval button',
            },
            copyright: 'PaliPali {{date}} © All right reserved.',
            socialIcons: {
                facebook: 'Link to Facebook profile',
                instagram: 'Link to Instagram profile',
                linkedin: 'Link to Linkedin profile',
                youtube: 'Link to Youtube channel',
            },
        },
        formControls: {
            email: 'Email',
            password: 'Password',
            newPassword: 'New password',
            confirmPassword: 'Repeat password',
            repeatPassword: 'Repeat password',
            placeholderConfirmPassword: 'Repeat password',
            phone: 'Phone number',
            city: 'City',
            firstName: 'First name',
            lastName: 'Last name',
            dialingCode: 'Prefix',
            privacyPolicy: 'I have read the <0>Regulations</0> and <1>Privacy Policy</1> of the PaliPali application.',
            placeholders: {
                email: 'Email',
                password: 'Password',
                repeatPassword: 'Repeat password',
                confirmPassword: 'Repeat password',
                city: 'Enter city',
                firstName: 'First name',
                lastName: 'Last name',
                phone: 'Phone number',
                phoneCode: 'Code',
            },
        },
        login: {
            title: 'Logging',
            haveAccount: 'New in palipali? ',
            register: 'Register',
            resetPassword: 'Forgotten your password?',
            loginButton: 'Log in',
            availableCities: 'Cities available',
            availableCitiesDescription: 'Find out in which cities you can make rides using the PaliPali app.',
            availableCitiesLink: 'Check out <0>palipali.co.uk/cities</0> and stay up to date.',
        },
        resetPassword: {
            title: 'Password reset',
            subtitle: 'Enter your e-mail address. We will send you a link to change your password.',
            sendEmailButton: 'Send link to change your password',
            backToLoginButton: 'Logging',
        },
        register: {
            title: 'Registration',
            accountCreated: 'You already have an account? ',
            createAccount: 'Create a Fleet Partner account',
            login: 'Log in.',
            registerButton: 'Register',
        },
        confirmRegistration: {
            title: 'Confirmation of registration',
            subtitle: 'To complete your registration on the platform, click the button below:',
            confirmButton: 'Confirm ',
        },
        newPassword: {
            title: 'Password reset',
            subtitle: 'Enter new password.',
            setPasswordButton: 'Set a new password',
        },
        confirmEmailChange: {
            title: 'Confirm change of email address',
            subtitle: 'To change your email address, click on the button below',
            confirmButton: 'Confirm ',
        },
        courierSuccessPage: {
            title: 'Thank you.',
            loading: 'Confirmation of invitation in progress...',
            subtitle:
                'The operation is now complete. If further steps are required, you will find the information in the email. Otherwise, continue with the operation in the mobile app. You can now close this page.',
            success: 'Operation successfully completed - you can now close this window and log in to the mobile application.',
            failure: 'Operation failed - try again later or contact your administrator.',
        },
        alert: {
            invalidConfirmRegistrationURL: 'Incorrect registration confirmation link.',
            loginSuccess: 'Login successful.',
            loginFailed: 'Incorrect login or password. Please try again.',
            logout: 'You have been logged out.',
            registrationSuccess: 'Successfully registered. Check your email to confirm your registration.',
            confirmRegistrationSuccess: 'Your email has been confirmed.',
            confirmCourierRegistrationSuccess: 'Your email has been confirmed. Go to the mobile application to log in.',
            sendResetPasswordMailSuccess: 'Check your email for a link to reset your password.',
            confirmResetPasswordSuccess: 'Your password has been changed. You can log in to the panel.',
            confirmCourierResetPasswordSuccess: 'Your password has been changed. Go to the mobile application to log in.',
            authenticationError: 'You must be logged in to access the data.',
            courierEmailChangeSuccess: 'Your email has been changed. Go to the mobile app to log in.',
            confirmFleetPartnerInvitationSuccess: 'Your invitation has been confirmed. Go to the mobile app to log in.',
            changeEmailSuccess: 'E-mail address has been changed.',
        },
    },
    packageSizes: {
        label: 'Available order categories',
        maxWeight: 'do {{weight}} kg',
        max: 'max.',
        s: {
            label: 'S',
        },
        m: {
            label: 'M',
        },
        l: {
            label: 'L',
        },
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        upload: 'Send',
        more: 'More',
        edit: 'Edit',
        saveChanges: 'Save changes',
        discard: 'Reject',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Less...',
        readMore: 'More...',
        close: 'Close',
        join: 'Join',
        showAll: 'Show all',
        next: 'Next',
        previous: 'Previous',
        submit: 'Approve',
        change: 'Change',
        remove: 'Delete',
        update: 'Update',
        resetPassword: 'Reset password',
        cancel: 'Cancel',
        create: 'Create',
        confirm: 'Approve',
        sendInvitation: 'Send invitation',
        view: 'View',
        detach: 'Disconnect',
        delete: 'Delete',
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Previous month',
            lastWeek: 'Last week',
            today: 'Today',
            currentMonth: 'Current month',
        },
    },
    formValidation: {
        errors: {
            minLength: 'Value is too short',
            maxLength: 'Value is too long',
            isEmailValid: 'Email is incorrect',
            isPostCodeValid: 'Postcode is incorrect',
            isRequired: 'Field is required',
            isNipValid: 'NIP is incorrect',
            isPhoneValid: 'Phone number is incorrect',
            isMobileValid: 'Mobile phone number is incorrect',
            isCheckedTrue: 'Area should be checked',
            isTrue: 'Area should be checked',
            isPasswordSame: 'Passwords do not match',
            isStartDateValid: 'Please select a date from the future',
            isStartDateGreaterOrEvenValid: 'Please select a date from the future',
            isEndDateValid: 'Please select your return date after your departure date',
            isNumber: 'Value should be a number',
            isPasswordString: 'Password is incorrect.',
            atLeastOneChecked: 'Please tick at least one option',
            termsAndConditions: 'Conditions must be accepted.',
            isInteger: 'Value should be an integer',
            isPositiveNumber: 'Value should be a positive number',
            isLengthValid: 'Length is incorrect',
            noRepeatedSpecialCharacters: 'There can only be one linking character',
            containingLetterNotStartingWithWhitespace: 'Area cannot begin or end with a space',
            noEndWithDash: 'Hyphens allowed only in the middle',
            onlyLetters: 'Value should contain only letters',
            isLaterThanNow: 'Date must be in the future',
            isProcessAtLaterThanNow: 'Implementation date must be in the future',
        },
    },
    footer: {
        copyright: `PaliPali {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: '404 - page not found',
    },
    general: {
        clearTextButton: 'Clear',
    },
    menuItems: {
        activeCouriers: 'Active Couriers',
        reports: 'Reports',
        settlements: 'Settlements',
        couriers: 'Couriers',
        vehicles: 'Vehicles',
        account: 'Account',
        logout: 'Logout',
    },
    alerts: {
        warning: 'Warning!',
        error: 'Error!',
        success: 'Success!',
        info: 'Information',
        authError: 'User name or password is incorrect.',
        noAccessError: 'You are not authorised to view this page.',
        baseError: 'An error has occurred, please contact your administrator.',
        loginError: 'Login error occurred, please try again.',
        tryAgain: 'Try again',
        unknown: 'Unknown error has occurred.',
        accessDenied: 'Access denied',
        accountNotConfirmed: 'Account has not been confirmed.',
        accountNotActive: 'Account is not active.',
        accountDeleted: 'Account has been deleted.',
        accountIsInvitation: 'Account is an invitation.',
        wrongAccountError: 'You cannot log in to this application with this account.',
        invalidOrExpiredToken: 'Your token has expired or is invalid.',
        expiredTokenLogout: 'Your session has expired. You have been logged out.',
    },
    month: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
    },
    weekday: {
        sunday: 'Su',
        monday: 'Mo',
        tuesday: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa',
    },
    datepickerWeekday: {
        Su: 'Su',
        Mo: 'Mo',
        Tu: 'Tu',
        We: 'We',
        Th: 'Th',
        Fr: 'Fr',
        Sa: 'Sa',
    },
    pagination: {
        pageCount: '<0>{{ page }}</0> z <1>{{ totalPages }}</1>',
    },
    jobStatusDetails: {
        planned: 'Scheduled',
        searching: 'Searching',
        drive: 'On the road',
        pickup: 'Taking delivery',
        delivery: 'Shipment release',
        delivered: 'Realised',
        cancelledPurchaser: 'Cancelled',
        cancelledCourier: 'Cancelled by Courier',
        problemPurchaser: 'Reported',
        problemCourier: 'Reported by Courier',
        deliveryDrive: 'In delivering',
        failed: 'Failure',
    },
    countryPhoneCodes: {
        AD: 'Andorra (376)',
        AE: 'United Arab Emirates (971)',
        AF: 'Afghanistan (93)',
        AG: 'Antigua and Barbuda (1-268)',
        AI: 'Anguilla (1-264)',
        AL: 'Albania (355)',
        AM: 'Armenia (374)',
        AN: 'Netherlands Antilles (599)',
        AO: 'Angola (244)',
        AQ: 'Antarctica (672)',
        AR: 'Argentina (54)',
        AS: 'American Samoa (1-684)',
        AT: 'Austria (43)',
        AU: 'Australia (61)',
        AW: 'Aruba (297)',
        AZ: 'Azerbaijan (994)',
        BA: 'Bosnia and Herzegovina (387)',
        BB: 'Barbados (1-246)',
        BD: 'Bangladesh (880)',
        BE: 'Belgium (32)',
        BF: 'Burkina Faso (226)',
        BG: 'Bulgaria (359)',
        BH: 'Bahrain (973)',
        BI: 'Burundi (257)',
        BJ: 'Benin (229)',
        BL: 'Saint-Barthélemy (590)',
        BM: 'Bermuda (1-441)',
        BN: 'Brunei (673)',
        BO: 'Bolivia (591)',
        BR: 'Brazil (55)',
        BS: 'Bahamas (1-242)',
        BT: 'Bhutan (975)',
        BW: 'Botswana (267)',
        BY: 'Belarus (375)',
        BZ: 'Belize (501)',
        CA: 'Canada (1)',
        CC: 'Cocos Islands (61)',
        CD: 'Democratic Republic of the Congo (243)',
        CF: 'Central African Republic (236)',
        CG: 'Congo (242)',
        CH: 'Switzerland (41)',
        CI: "Côte d'Ivoire (225)",
        CK: 'Cook Islands (682)',
        CL: 'Chile (56)',
        CM: 'Cameroon (237)',
        CN: 'China (86)',
        CO: 'Colombia (57)',
        CR: 'Costa Rica (506)',
        CU: 'Cuba (53)',
        CV: 'Cape Verde (238)',
        CW: 'Curaçao (599)',
        CX: 'Christmas Island (61)',
        CY: 'Cyprus (357)',
        CZ: 'Czech Republic (420)',
        DE: 'Germany (49)',
        DJ: 'Djibouti (253)',
        DK: 'Denmark (45)',
        DM: 'Dominica (1-767)',
        DO: 'Dominican Republic (1-809)',
        DZ: 'Algeria (213)',
        EC: 'Ecuador (593)',
        EE: 'Estonia (372)',
        EG: 'Egypt (20)',
        EH: 'Western Sahara (212)',
        ER: 'Eritrea (291)',
        ES: 'Spain (34)',
        ET: 'Ethiopia (251)',
        FI: 'Finland (358)',
        FJ: 'Fiji (679)',
        FK: 'Falkland Islands (500)',
        FM: 'Micronesia (691)',
        FO: 'Faroe Islands (298)',
        FR: 'France (33)',
        GA: 'Gabon (241)',
        GB: 'Great Britain (44)',
        GD: 'Grenada (1-473)',
        GE: 'Georgia (995)',
        GG: 'Guernsey (44-1481)',
        GH: 'Ghana (233)',
        GI: 'Gibraltar (350)',
        GL: 'Greenland (299)',
        GM: 'Gambia (220)',
        GN: 'Guinea (224)',
        GQ: 'Equatorial Guinea (240)',
        GR: 'Greece (30)',
        GT: 'Guatemala (502)',
        GU: 'Guam (1-671)',
        GW: 'Guinea-Bissau (245)',
        GY: 'Guyana (592)',
        HN: 'Hong Kong (852)',
        HR: 'Croatia (385)',
        HT: 'Haiti (509)',
        HU: 'Hungary (36)',
        ID: 'Indonesia (62)',
        IE: 'Ireland (353)',
        IL: 'Israel (972)',
        IM: 'Isle of Man (44-1624)',
        IN: 'India (91)',
        IO: 'British Indian Ocean Territory (246)',
        IQ: 'Iraq (964)',
        IR: 'Iran (98)',
        IS: 'Iceland (354)',
        IT: 'Italy (39)',
        JE: 'Jersey (44-1534)',
        JM: 'Jamaica (1-876)',
        JO: 'Jordan (962)',
        JP: 'Japan (81)',
        KE: 'Kenya (254)',
        KG: 'Kyrgyzstan (996)',
        KH: 'Cambodia (588)',
        KI: 'Kiribati (686)',
        KM: 'Chambers (269)',
        KN: 'Saint Kitts i Nevis (1-869)',
        KP: 'North Korea (850)',
        KR: 'South Korea (82)',
        KW: 'Kuwait (965)',
        KY: 'Cayman Islands (1-345)',
        KZ: 'Kazakhstan (7)',
        LA: 'Laos (856)',
        LB: 'Liban (961)',
        LC: 'Saint Lucia (1-758)',
        LI: 'Liechtenstein (423)',
        LK: 'Sri Lanka (94)',
        LR: 'Liberia (231)',
        LS: 'Lesotho (266)',
        LT: 'Lithuania (370)',
        LU: 'Luxembourg (352)',
        LV: 'Latvia (371)',
        LY: 'Libya (218)',
        MA: 'Morocco (212)',
        MC: 'Monaco (373)',
        MD: 'Moldavia (373)',
        ME: 'Montenegro (382)',
        MF: 'Saint Martin (590)',
        MG: 'Madagascar (261)',
        MH: 'Wyspy Marshalla (692)',
        MK: 'North Macedonia (389)',
        ML: 'Mali (223)',
        MM: 'Myanmar (95)',
        MN: 'Mongolia (976)',
        MO: 'Macau (853)',
        MP: 'Northern Mariana Islands (1-670)',
        MR: 'Mauritania (222)',
        MS: 'Montserrat (1-664)',
        MT: 'Malta (356)',
        MU: 'Mauritius (230)',
        MV: 'Maldives (960)',
        MW: 'Malawi (265)',
        MX: 'Mexico (52)',
        MY: 'Malaysia (60)',
        MZ: 'Mozambique (258)',
        NA: 'Namibia (264)',
        NC: 'New Caledonia (687)',
        NE: 'Niger (227)',
        NG: 'Nigeria (234)',
        NI: 'Nicaragua (505)',
        NL: 'Netherlands (31)',
        NO: 'Norway (47)',
        NP: 'Nepal (977)',
        NR: 'Nauru (674)',
        NU: 'Niue (683)',
        NZ: 'New Zealand (64)',
        OM: 'Oman (968)',
        PA: 'Panama (507)',
        PE: 'Peru (51)',
        PF: 'French Polynesia (689)',
        PG: 'Papua New Guinea (675)',
        PH: 'Philippines (63)',
        PK: 'Pakistan (92)',
        PL: 'Poland (48)',
        PM: 'Saint-Pierre and Miquelon (508)',
        PN: 'Pitcairn (64)',
        PR: 'Puerto Rico (1-787)',
        PS: 'Palestine (970)',
        PT: 'Portugal (351)',
        PW: 'Palau (680)',
        PY: 'Paraguay (595)',
        QA: 'Qatar (974)',
        RO: 'Romania (40)',
        RS: 'Serbia (381)',
        RU: 'Russia (7)',
        RW: 'Rwanda (250)',
        SA: 'Saudi Arabia (966)',
        SB: 'Solomon Islands (677)',
        SC: 'Seychelles (248)',
        SD: 'Sudan (249)',
        SE: 'Sweden (46)',
        SG: 'Singapore (65)',
        SH: 'Saint Helena (290)',
        SI: 'Slovenia (386)',
        SJ: 'Svalbard and Jan Mayen (47)',
        SK: 'Slovakia (421)',
        SL: 'Sierra Leone (232)',
        SM: 'San Marino (378)',
        SN: 'Senegal (221)',
        SO: 'Somalia (252)',
        SR: 'Surinam (597)',
        SS: 'South Sudan (211)',
        ST: 'São Tomé and Príncipe (239)',
        SV: 'Salvador (503)',
        SX: 'Sint Maarten (1-721)',
        SY: 'Syria (963)',
        SZ: 'Eswatini (268)',
        TD: 'Chad (235)',
        TG: 'Togo (228)',
        TH: 'Thailand (66)',
    },
    // special auth errors from the backend
    'An authentication exception occurred.': 'An authentication error occurred.',
    'Authentication credentials could not be found.': 'Authentication data not found.',
    'Authentication request could not be processed due to a system problem.':
        'Authentication request could not be completed successfully due to a problem with the system.',
    'Invalid credentials.': 'Incorrect data.',
    'Cookie has already been used by someone else.': 'This cookie is used by someone else.',
    'Not privileged to request the resource.': 'No authority for requesting the indicated resource.',
    'Invalid CSRF token.': 'Incorrect token CSRF.',
    'No authentication provider found to support the authentication token.':
        'No authentication mechanism capable of handling the submitted token was found.',
    'No session available, it either timed out or cookies are not enabled.': 'No session data, session expired or cookies not enabled.',
    'No token could be found.': 'Token not found.',
    'Username could not be found.': 'The user with the specified name does not exist.',
    'Account has expired.': 'Account has expired.',
    'Credentials have expired.': 'Authentication data expired.',
    'Account is disabled.': 'Account is disabled.',
    'Account is locked.': 'Account is blocked.',
    'Too many failed login attempts, please try again later.': 'Too many unsuccessful login attempts, please try again later.',
    'Invalid or expired login link.': 'Incorrect or expired login link.',
    'Too many failed login attempts, please try again in %minutes% minute.':
        'Too many unsuccessful login attempts, try again after %minutes% minutes.',
    'Too many failed login attempts, please try again in %minutes% minutes.':
        'Too many unsuccessful login attempts, try again after %minutes% minutes.',
    // end special auth errors from the backend
};

export default en;
