import {Box, Card, CardContent, CardHeader, Link, Stack, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link as ReactRouterLink, useNavigate} from 'react-router-dom';
import {SystemParameterCode} from '../../../model/systemPatameters';
import {getLoginCredentials} from '../../../store/reducers/loginSlice';
import {isAuthenticatedSelector} from '../../../store/selectors/authSelectors';
import {selectSystemParameter} from '../../../store/selectors/systemParametersSelectors';
import {ILoginProps, LoaderType, UserRole} from '../../../types';
import FormikForm from '../../FormikForm';
import Loader from '../../Loader';
import Translation from '../../Translation';
import loginFormConfig from './loginFormConfig';
import {loginLoadingSelector} from '../../../store/selectors/loginSelectors';

const Login: React.FC<ILoginProps> = ({userRole, theme}) => {
    const {t} = useTranslation(),
        isAuthenticated = useSelector((state) => isAuthenticatedSelector(state)),
        citiesUrl = useSelector((state) => selectSystemParameter(state, SystemParameterCode.CITIES_LINK)),
        isLoading = useSelector(loginLoadingSelector),
        dispatch = useDispatch(),
        navigate = useNavigate();

    interface Values {
        email: string;
        password: string;
        submit: null;
    }

    const initialValues: Values = {
        email: '',
        password: '',
        submit: null,
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated]);

    const login = (values: any): any => {
        return dispatch(getLoginCredentials(values.email, values.password, userRole));
    };
    const isCourier = userRole.includes(UserRole.COURIER);

    const registerUrl = isCourier ? '/couriers/register' : '/auth/register',
        resetPasswordUrl = isCourier ? '/couriers/reset-password' : '/auth/reset-password';
    const userIsFleetPartnerOrCourier = userRole.includes(UserRole.ROLE_FLEET_PARTNER) || userRole.includes(UserRole.COURIER);

    return (
        <Card elevation={16}>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <CardHeader
                key={'card_header'}
                subheader={
                    !userIsFleetPartnerOrCourier ? (
                        <Typography color="text.secondary" variant="body2" key="subheader">
                            <Translation text="auth.login.haveAccount" />

                            <Link to={registerUrl} component={ReactRouterLink} underline="hover" variant="body2">
                                <Translation text="auth.login.register" />
                            </Link>
                        </Typography>
                    ) : null
                }
                title={t('auth.login.title')}
            />
            <CardContent>
                <FormikForm
                    fields={loginFormConfig()}
                    formId="login_form"
                    initialValues={initialValues}
                    theme={theme}
                    onSubmit={(values: Values) => {
                        login(values);
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: '1.6rem',
                        mb: '1.4rem',
                    }}>
                    <Link to={resetPasswordUrl} component={ReactRouterLink} underline="hover" variant="subtitle2" sx={{fontSize: '1.5rem'}}>
                        <Translation text="auth.login.resetPassword" />
                    </Link>
                </Box>
                <Box
                    sx={{display: 'flex', justifyContent: 'center', backgroundColor: 'primary.light'}}
                    className="login-cities-information-host">
                    <Stack className="login-cities-information-wrapper">
                        <Typography color="text.primary" variant="h6" component="h2" key="subheader" className="cities-information-title">
                            <Translation text="auth.login.availableCities" />
                        </Typography>
                        <Stack>
                            <Typography color="text.secondary" variant="caption" key="subheader">
                                <Translation text="auth.login.availableCitiesDescription" />
                            </Typography>
                            <Typography color="text.secondary" variant="caption" key="secondary-subheader">
                                <Trans
                                    i18nKey={'auth.login.availableCitiesLink'}
                                    components={[<Link href={citiesUrl?.value} target="_blank" underline="none" variant="caption" />]}
                                />
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Login;
