import React from 'react';
import {IVerticalMenuHeaderProps} from '../../../types';
import XIcon from '../../../theme/icons/feather/x';

type Props = IVerticalMenuHeaderProps;

const VerticalMenuHeader = (props: Props) => {
    return (
        <>
            <div className="navbar-header">
                <span className="brand-logo">
                    <img src={props.logoImage} alt="logo" />
                </span>
            </div>
            <div className="nav-item nav-toggle">
                <div
                    className="nav-link modern-nav-toggle cursor-pointer"
                    onClick={() => (props.toggleMobileMenu ? props.toggleMobileMenu() : null)}
                    data-tour="toggle-icon">
                    <XIcon size={24} />
                </div>
            </div>
        </>
    );
};

export default VerticalMenuHeader;
