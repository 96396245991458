import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase} from '../../types';
import {IAccountMeOutput} from '../../model/account';

export function getAccountDataAPI(authToken: string): Observable<IApiSingleResponseBase<IAccountMeOutput>> {
    return palipaliAPI.get(`api/accounts/me`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
