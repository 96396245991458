import {IconButton, TablePagination} from '@mui/material';
import React from 'react';
import {Trans} from 'react-i18next';
import {IModelApiResponseViewObject} from '../../../types';
import ChevronLeftIcon from '../../icons/untitled-ui/duocolor/chevron-left';
import ChevronRightIcon from '../../icons/untitled-ui/duocolor/chevron-right';
import DoubleArrowLeftIcon from '../../icons/untitled-ui/duocolor/double-arrow-left';
import DoubleArrowRightIcon from '../../icons/untitled-ui/duocolor/double-arrow-right';

const TablePaginationActions = (props) => {
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 1);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
    };

    return (
        <div className="custom-table-pagination-wrapper">
            <div className="custom-table-pagination-arrows prev">
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 1}>
                    <DoubleArrowLeftIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 1}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>

            <span className="custom-table-pagination-info">
                <Trans
                    i18nKey="pagination.pageCount"
                    values={{
                        page: page,
                        totalPages: Math.max(0, Math.ceil(count / rowsPerPage)),
                    }}
                    components={[<span className={'current-page'} />, <span className={'total-pages'} />]}
                />
            </span>

            <div className="custom-table-pagination-arrows next">
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage)}>
                    <ChevronRightIcon />
                </IconButton>{' '}
                <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage)}>
                    <DoubleArrowRightIcon />
                </IconButton>
            </div>
        </div>
    );
};

interface TablePaginationActionsProps {
    metadata: IModelApiResponseViewObject | null;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}
const CustomTablePagination: React.FC<TablePaginationActionsProps> = ({metadata, page, rowsPerPage, onChangePage}) => {
    if (!metadata || !metadata.totalItems) return null;
    const count = metadata.totalItems,
        pageCount = Math.max(0, Math.ceil(count / rowsPerPage));

    return (
        <TablePagination
            component="div"
            count={pageCount}
            className="custom-table-pagination"
            page={rowsPerPage >= pageCount ? 0 : page}
            onPageChange={onChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            ActionsComponent={() => (
                <TablePaginationActions
                    {...{
                        count,
                        page,
                        rowsPerPage,
                        onChangePage,
                    }}
                />
            )}
            labelDisplayedRows={() => null}
        />
    );
};

export default CustomTablePagination;
