import {createSelector} from '@reduxjs/toolkit';
import {IAccountState, APP_THEME_NORMAL, APP_THEMES, APP_THEME} from '../reducers/accountSlice';
import {CommonRootState} from '../reducers';
import {isNotNullOrUndefined, isNullOrUndefined} from '../../utils/runtimeUtils';
import {IAccountBasicInfoOutput, ICompletedAccounts} from '../../model/account';
import {Locale} from '../../types';

export const selectAccountState = (state: CommonRootState): IAccountState => {
    return state.account;
};

export const accountSelector = createSelector([selectAccountState], (state) => state.account);

export const accountUserNameSelector = createSelector([selectAccountState], (state) => {
    return isNotNullOrUndefined(state.account?.account?.username) ? state.account?.account?.username : null;
});
export const accountIsInitialisedSelector = createSelector([selectAccountState], (state) => state.isInitialized);
export const accountIsLoadingSelector = createSelector([selectAccountState], (state) => state.isLoading);
export const accountSuccessfulSelector = createSelector([selectAccountState], (state) => state.isActionSuccessful);
export const accountInfoSelector = createSelector([selectAccountState], (state) => state.account.account);
export const accountCompletedAccountsSelector = createSelector([selectAccountState], (state) => state.account.completedAccounts);
export const canDeleteAccountSelector = createSelector([selectAccountState], (state: IAccountState) => state.canBeDeleted);
export const accountThemeSelector = createSelector([selectAccountState], (state): APP_THEME => {
    const theme = state?.account?.account?.misc?.theme;
    return isNullOrUndefined(theme) || -1 === APP_THEMES.indexOf(theme)
        ? APP_THEME_NORMAL
        : (state.account.account.misc.theme as APP_THEME);
});

export const accountIdSelector = createSelector([accountInfoSelector], (accountInfo: IAccountBasicInfoOutput) => {
    return accountInfo?.id;
});

export const accountCredentialsSelector = createSelector([accountInfoSelector], (accountInfo: IAccountBasicInfoOutput): string => {
    return isNotNullOrUndefined(accountInfo?.firstName) && isNotNullOrUndefined(accountInfo?.lastName)
        ? `${accountInfo.firstName} ${accountInfo.lastName}`
        : '';
});

export const isFleetPartnerStripeOnboardingCompletedSelector = createSelector(
    [accountCompletedAccountsSelector],
    (completedAccounts: ICompletedAccounts): boolean => {
        return completedAccounts.fleetPartner?.stripeOnboardingCompleted ?? false;
    }
);

export const isAccountActiveSelector = createSelector(
    [accountCompletedAccountsSelector],
    (completedAccounts: ICompletedAccounts): boolean => {
        return (completedAccounts?.fleetPartner?.active || completedAccounts?.courier?.active) ?? false;
    }
);

export const accountLocaleSelector = createSelector([selectAccountState], (state): Locale | undefined => state.account?.account?.locale);

export const fleetPartnerIdSelector = createSelector(
    [accountCompletedAccountsSelector],
    (completedAccounts: ICompletedAccounts): string | null => {
        return completedAccounts.fleetPartner?.id || null;
    }
);
