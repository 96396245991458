import {IconButton, InputAdornment, Stack, TextField} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {FormikFieldConfig} from '../../../components/FormikForm';
import CalendarIconSmall from '../../icons/untitled-ui/duocolor/calendar-small';
import {DateRange, DateRangePicker} from '../../../components/DateRangePickerHost';
import moment from 'moment';

const DateRangePickerControl: React.FC<FormikFieldConfig> = ({
    formik,
    name,
    customHandleChange,
    customEventChange,
    className,
    label,
    initialDates,
    dateFormat,
}) => {
    const [dateRange, setDateRange] = React.useState<DateRange>({
        startDate: initialDates?.startDate || null,
        endDate: initialDates?.endDate || null,
    });
    const [openPicker, setOpenPicker] = React.useState<boolean>(false);
    const {t} = useTranslation();

    const toggle = () => setOpenPicker(!open);

    const handleChange = (daterange: DateRange) => {
        setDateRange(daterange);
        if (customHandleChange) {
            customHandleChange(daterange, formik.setFieldValue, formik.values);
        }
        if (customEventChange) {
            customEventChange(name, daterange);
        }
        setOpenPicker(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack className="date-range-picker-wrapper">
                <TextField
                    key={`daterangepicker-textfield-${name}`}
                    label={t(label)}
                    error={formik.touched[name] && Boolean(formik.errors[name])}
                    helperText={formik.touched[name] && formik.errors[name]}
                    onKeyDown={(event) => event.preventDefault()}
                    onClick={() => setOpenPicker(true)}
                    className={`date-range-picker ${className || ''}`}
                    value={
                        dateRange.startDate
                            ? `${moment(dateRange.startDate).format(dateFormat)} - ${moment(dateRange.endDate).format(dateFormat)}`
                            : ''
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <CalendarIconSmall />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <DateRangePicker
                    open={openPicker}
                    closeOnClickOutside
                    initialDateRange={dateRange}
                    toggle={toggle}
                    onChange={handleChange}
                    wrapperClassName="date-range-picker-calendar"
                />
            </Stack>
        </LocalizationProvider>
    );
};

export default DateRangePickerControl;
