import type {SVGProps} from 'react';
import React from 'react';

const TeamsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M17.4438 15.6275C18.7626 16.29 19.8932 17.3531 20.7185 18.6825C20.8819 18.9458 20.9636 19.0774 20.9919 19.2597C21.0493 19.6301 20.796 20.0855 20.451 20.2321C20.2812 20.3042 20.0902 20.3042 19.7083 20.3042M15.6322 11.7284C16.9744 11.0614 17.8967 9.67645 17.8967 8.07605C17.8967 6.47565 16.9744 5.09067 15.6322 4.42369M13.8207 8.07605C13.8207 10.3272 11.9958 12.1521 9.74462 12.1521C7.49348 12.1521 5.66857 10.3272 5.66857 8.07605C5.66857 5.82491 7.49348 4 9.74462 4C11.9958 4 13.8207 5.82491 13.8207 8.07605ZM3.45775 18.4368C4.90185 16.2685 7.18067 14.8695 9.74462 14.8695C12.3086 14.8695 14.5874 16.2685 16.0315 18.4368C16.3479 18.9118 16.506 19.1493 16.4878 19.4527C16.4736 19.6889 16.3188 19.9781 16.13 20.1209C15.8876 20.3042 15.5542 20.3042 14.8874 20.3042H4.60187C3.93504 20.3042 3.60163 20.3042 3.3592 20.1209C3.17045 19.9781 3.01559 19.6889 3.00141 19.4527C2.9832 19.1493 3.14138 18.9118 3.45775 18.4368Z"
            stroke="CurrentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default TeamsIcon;
