import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const createOpinionAPI: ApiOperationResponseFunction<string> = (
    authToken: string,
    payload: {id: string; rating: number}
): Observable<IApiSingleResponseBase<string>> => {
    return palipaliAPI.put(`api/jobs/${payload.id}/create_opinion`, {rating: payload.rating}, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
