import {SVGProps} from 'react';
import React from 'react';

const RouteIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.217 5.2H12.608C15.3505 5.2 16.7217 5.2 17.2423 5.69256C17.6922 6.11833 17.8916 6.74555 17.7701 7.35299C17.6296 8.05571 16.5101 8.84757 14.271 10.4313L10.6129 13.0187C8.37391 14.6024 7.25438 15.3943 7.11384 16.097C6.99236 16.7044 7.19177 17.3317 7.64172 17.7574C8.16225 18.25 9.53351 18.25 12.276 18.25H13.117M9.06699 5.2C9.06699 6.69117 7.85816 7.9 6.36699 7.9C4.87582 7.9 3.66699 6.69117 3.66699 5.2C3.66699 3.70883 4.87582 2.5 6.36699 2.5C7.85816 2.5 9.06699 3.70883 9.06699 5.2ZM21.667 17.8C21.667 19.2912 20.4582 20.5 18.967 20.5C17.4758 20.5 16.267 19.2912 16.267 17.8C16.267 16.3088 17.4758 15.1 18.967 15.1C20.4582 15.1 21.667 16.3088 21.667 17.8Z"
            stroke="CurrentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default RouteIcon;
