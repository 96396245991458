import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';

export function checkMobileNumberAPI(authToken: string, country: string, phone: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return palipaliAPI.post(`api/common/is_mobile_phone_number_valid`, {country: country, phone: phone}, new RestQueryParams(), headers);
}
