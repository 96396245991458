import type {SVGProps} from 'react';
import React from 'react';

const DoubleArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" {...props}>
        <g id="DoubleArrowLeft">
            <path id="Vector" d="M12.7049 7.41L11.2949 6L5.29492 12L11.2949 18L12.7049 16.59L8.12492 12L12.7049 7.41Z" fill="#6C737F" />
            <path id="Vector_2" d="M18.7049 7.41L17.2949 6L11.2949 12L17.2949 18L18.7049 16.59L14.1249 12L18.7049 7.41Z" fill="#6C737F" />
        </g>
    </svg>
);

export default DoubleArrowLeftIcon;
