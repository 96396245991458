import {Button, Typography} from '@mui/material';
import React from 'react';
import {Translation, FileDownloadIcon} from 'palipali-panel-common-web';
import {useDispatch, useSelector} from 'react-redux';
import {downloadReport} from '../../../store/reducers/reportsSlice';
import {reportsListingSelector, reportsPageSelector, serverReportsFiltersSelector} from '../../../store/selectors/reportsSelectors';

interface IReportsTitleProps {}
const ReportsTitle: React.FC<IReportsTitleProps> = () => {
    const dispatch = useDispatch();
    const filters = useSelector(serverReportsFiltersSelector);
    const page = useSelector(reportsPageSelector);
    const reportsList = useSelector(reportsListingSelector);
    const downloadReportCSV = () => dispatch(downloadReport({filters, page}));

    return (
        <div className="header">
            <div className="title">
                <Typography variant={'h4'} color={'textPrimary'}>
                    <Translation text={'reports.title'} />
                </Typography>
            </div>
            <Button
                disabled={reportsList.length === 0}
                startIcon={<FileDownloadIcon width={20} height={20} />}
                onClick={downloadReportCSV}
                variant={'contained'}
                className="reports-download-csv-button"
                color={'primary'}>
                <Translation text={'reports.downloadReport'} />
            </Button>
        </div>
    );
};
export default ReportsTitle;
