import React from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {IconSize} from '../../../constants/miscellaneous';
import {getDisplayName} from '../../../model/account';
import Users from '../../../theme/icons/untitled-ui/duocolor/users';
import {AvatarColor, AvatarSize, IUserDropdownProps, IUserMenuItem} from '../../../types';
import Avatar from '../../Avatar';
import Translation from '../../Translation';

type Props = IUserDropdownProps;

const UserDropdown: React.FC<Props> = ({isAuthenticated, account, userMenu, unauthorizedUserMenu}) => {
    const renderDropdownItem = (item: IUserMenuItem) => {
        if (item.isDivider) {
            return <DropdownItem divider key="divider" />;
        }

        const redirectUrl = isAuthenticated ? `${process.env.REACT_APP_CANDIDATE_URL}/${item.link}` : item.link,
            Tag =
                undefined !== item.action ? (
                    <span className="" onClick={() => (item.action ? item.action() : null)}>
                        <span className="align-middle">
                            <Translation text={item.title} />
                        </span>
                    </span>
                ) : (
                    <a className="" href={redirectUrl} target="_blank">
                        <span className="align-middle">
                            <Translation text={item.title} />
                        </span>
                    </a>
                );

        return <DropdownItem key={item.id}>{Tag}</DropdownItem>;
    };

    const handleLogout = () => {
        console.log('logout');
    };

    const renderAuthenticatedUserMenu = () => {
        const role = 'Admin',
            avatar = account?.avatar ? account.avatar : undefined;

        return (
            <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={(e) => e.preventDefault()}>
                    <div className="user-nav d-sm-flex d-none">
                        <span className="user-name">{getDisplayName(account, 'Username')}</span>
                        <span className="user-status">{role}</span>
                    </div>
                    <Avatar
                        color={AvatarColor.LIGHT_PRIMARY}
                        size={AvatarSize.LG}
                        img={avatar}
                        icon={avatar === undefined ? <Users size={IconSize.UserNavAvatar} /> : undefined}
                        tag={'div'}
                    />
                </DropdownToggle>
                <DropdownMenu end>{userMenu(handleLogout).map((item: IUserMenuItem) => renderDropdownItem(item))}</DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    const renderUnauthenticatedUserMenu = () => {
        return (
            <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={(e) => e.preventDefault()}>
                    <div className="user-nav d-sm-flex d-none">
                        <span className="login-placeholder">
                            <Translation text="buttons.login" />
                        </span>
                    </div>
                    <Avatar
                        color={AvatarColor.LIGHT_PRIMARY}
                        size={AvatarSize.LG}
                        icon={<Users size={IconSize.UserNavAvatar} />}
                        tag={'div'}
                    />
                </DropdownToggle>
                <DropdownMenu end>{unauthorizedUserMenu.map((item: IUserMenuItem) => renderDropdownItem(item))}</DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return isAuthenticated ? renderAuthenticatedUserMenu() : renderUnauthenticatedUserMenu();
};

export default UserDropdown;
