import {IModelColor, IPackageSizesInput, IPackageSizesOutput} from './common';
import {IModelVehicleType} from './vehicleType';
import {IInspectionInput, IInspectionOutput} from './inspection';
import {IInsuranceInput, IInsuranceOutput} from './insurance';
import {IVehicleDefinitionOutput} from './vehicleDefinition';

export enum VehicleStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    VERIFICATION_PENDING = 'verification_pending',
    VERIFIED = 'verified',
    PENDING = 'pending',
    REJECTED = 'rejected',
}

export type IVehicleListingOutput = {
    readonly id: string;
    readonly make: string;
    readonly model: string;
    readonly registrationNumber: string;
    readonly status: string;
    readonly assignedCourierCount: number | null;
    readonly packageSizes: IPackageSizesOutput;
    readonly canBeDeleted: boolean;
    readonly productionYear: number | null;
    readonly color: IModelColor;
    readonly canUnassign: boolean | null;
    readonly latestInsurance: IInsuranceOutput;
    readonly latestInspection: IInspectionOutput;
};

export type IVehicleDetailsOutput = {
    readonly id: string;
    readonly registrationNumber: string;
    readonly status: string;
    readonly vehicleDefinition: IVehicleDefinitionOutput | null;
    readonly make: string | null;
    readonly model: string | null;
    readonly doorNumber: number | null;
    readonly vehicleType: IModelVehicleType | null;
    readonly productionYear: number | null;
    readonly color: IModelColor;
    readonly type: 'owned' | 'assigned';
    readonly packageSizes: IPackageSizesOutput;
    readonly latestInsurance: IInsuranceOutput;
    readonly latestInspection: IInspectionOutput;
    readonly assignedCourierCount: number | null;
};

export type ICreateVehicleInput = {
    registrationNumber: string;
    vehicleDefinitionId: string | null;
    make: string | null;
    model: string | null;
    vehicleTypeId: string | null;
    productionYear: string | null;
    colorId: string;
    packageSizes: IPackageSizesInput;
    insurance: IInsuranceInput;
    inspection: IInspectionInput;
};

export type IUpdateVehicleInput = {
    colorId: string;
};

export type IVehicleDocumentStatus = 'active' | 'rejected' | 'pending' | 'inactive';

export type IAssignCouriersToVehiclesInput = {
    addCourierIds: string[];
    removeCourierIds: string[];
};

export type IVehicleAssignedCouriersOutput = {
    courierIds: string[];
};

export type IVehicleAssignCouriersProcessOutput = {
    assigned: IVehicleAssignedCouriersOutput;
    failedCourierIds: string[];
};

export type IAssignVehiclesToCourierInput = {
    addVehicleIds: string[];
    removeVehicleIds: string[];
};

export type ICourierAssignedVehiclesOutput = {
    vehicleIds: string[];
};

export type ICourierAssignVehiclesProcessOutput = {
    assigned: ICourierAssignedVehiclesOutput;
    failedVehicleIds: string[];
};
