import {CustomIconProps} from '../../../types/iconTypes';
import React from 'react';

const EditIcon = (props: CustomIconProps) => (
    <svg width={props.size || 20} height={props.size || 20} viewBox="0 0 20 20" fill="none" {...props}>
        <path
            d="M10.5432 5.54289L4.68493 11.4012C4.67186 11.4143 4.65838 11.4277 4.64456 11.4414C4.49239 11.5926 4.29977 11.7841 4.16289 12.0259C4.026 12.2676 3.96094 12.5313 3.90953 12.7396C3.90487 12.7585 3.90031 12.777 3.89583 12.7949L3.39896 14.7823C3.39616 14.7936 3.39324 14.8052 3.39022 14.8172C3.3534 14.9637 3.3024 15.1666 3.28483 15.3462C3.26461 15.5529 3.25792 16.0048 3.6267 16.3736L4.33087 15.6695L3.6267 16.3736C3.99548 16.7424 4.44744 16.7357 4.65413 16.7155C4.83375 16.6979 5.03666 16.6469 5.18315 16.6101C5.19515 16.6071 5.20678 16.6042 5.21798 16.6014L7.20543 16.1045C7.22337 16.1 7.24182 16.0955 7.26073 16.0908C7.46904 16.0394 7.73271 15.9743 7.97448 15.8374C8.21624 15.7006 8.40769 15.5079 8.55894 15.3558C8.57267 15.342 8.58607 15.3285 8.59915 15.3154L14.4574 9.45711C14.4671 9.4474 14.4768 9.4377 14.4865 9.42801C14.7156 9.19904 14.9385 8.97621 15.106 8.76978C15.2916 8.54104 15.4819 8.24775 15.5529 7.86277C15.5972 7.62295 15.5972 7.37705 15.5529 7.13723C15.4819 6.75225 15.2916 6.45896 15.106 6.23021C14.9385 6.02379 14.7156 5.80096 14.4865 5.57199L14.4574 5.54289C14.4477 5.53319 14.438 5.52349 14.4283 5.5138C14.1994 5.28476 13.9765 5.06185 13.7701 4.89435C13.5414 4.70873 13.2481 4.5184 12.8631 4.44739C12.6233 4.40315 12.3774 4.40315 12.1376 4.44739C11.7526 4.5184 11.4593 4.70873 11.2305 4.89435C11.0241 5.06185 10.8013 5.28475 10.5723 5.51379C10.5626 5.52348 10.5529 5.53318 10.5432 5.54289Z"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path d="M10.417 6.24967L12.917 4.58301L15.417 7.08301L13.7503 9.58301L10.417 6.24967Z" fill="currentColor" />
    </svg>
);

export default EditIcon;
