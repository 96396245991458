import {
    createBaseReducerSlice,
    IBaseReducerState,
    initApiCall,
    ICourierSettlementListingOutput,
    isSameValue,
    IModelApiResponseViewObject,
    PAGINATION_ITEMS_PER_PAGE,
    IMoneyOutput,
} from 'palipali-panel-common-web';
import {PayloadAction} from '@reduxjs/toolkit';
import {IPaginationParams} from './courierListingSlice';
import moment from 'moment';

export interface IModelApiResponseExtrasObject extends IModelApiResponseViewObject {
    totalIncome: IMoneyOutput;
}

export interface ISettlementListingSliceState extends IBaseReducerState {
    settlementList: ICourierSettlementListingOutput[] | null;
    currentSettlement: ICourierSettlementListingOutput | null;
    processingSettlement: ICourierSettlementListingOutput | null;
    pagination: IPaginationParams;
    metadata: IModelApiResponseExtrasObject | null;
    settlementFilters: ISettlementFilters;
}

export interface ISettlementFilters {
    'date[after]': string | Date | null;
    'date[before]': string | Date | null;
}

export interface ISetMetadata {
    metadata: IModelApiResponseExtrasObject | null;
}

export interface ISetSettlementListing {
    readonly settlementList: ICourierSettlementListingOutput[] | null;
}

export interface ISetCurrentSettlement {
    readonly currentSettlement: ICourierSettlementListingOutput | null;
}

export interface ISetProcessingSettlement {
    readonly processingSettlement: ICourierSettlementListingOutput | null;
}

export interface IChangeSettlementsFilters {
    readonly settlementFilters: ISettlementFilters;
}

const initialState: ISettlementListingSliceState = {
    settlementList: null,
    currentSettlement: null,
    processingSettlement: null,
    settlementFilters: {
        'date[after]': `${moment().startOf('month')}`,
        'date[before]': `${moment()}`,
    },
    pagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    metadata: null,
    isInitialized: false,
    isLoading: false,
    error: null,
};

const settlementListingSlice = createBaseReducerSlice({
    name: 'settlementListing',
    initialState: initialState,
    reducers: {
        setSettlementListing: (state: ISettlementListingSliceState, action: PayloadAction<ISetSettlementListing>) => {
            return {
                ...state,
                settlementList: action.payload.settlementList,
                isLoading: false,
            };
        },
        getSettlementListing: (state: ISettlementListingSliceState) => initApiCall(state),
        fetchCurrentSettlement: (state: ISettlementListingSliceState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        fetchProcessingSettlement: (state: ISettlementListingSliceState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        applySettlementFilters: (state: ISettlementListingSliceState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        changeSettlementFilters: {
            reducer: (state: ISettlementListingSliceState, action: PayloadAction<IChangeSettlementsFilters>) => {
                if (isSameValue(action.payload.settlementFilters, state.settlementFilters)) {
                    return {
                        ...state,
                        settlementFilters: action.payload.settlementFilters,
                    };
                }
                return {
                    ...state,
                    isLoading: true,
                    settlementFilters: action.payload.settlementFilters,
                };
            },
            prepare: (settlementFilters: ISettlementFilters) => {
                return {
                    payload: {
                        settlementFilters: settlementFilters,
                    },
                };
            },
        },
        setCurrentSettlement: {
            reducer: (state: ISettlementListingSliceState, action: PayloadAction<ISetCurrentSettlement>) => {
                return {
                    ...state,
                    currentSettlement: action.payload.currentSettlement,
                };
            },
            prepare: (currentSettlement: ICourierSettlementListingOutput | null) => {
                return {
                    payload: {
                        currentSettlement: currentSettlement,
                    },
                };
            },
        },
        setProcessingSettlement: {
            reducer: (state: ISettlementListingSliceState, action: PayloadAction<ISetProcessingSettlement>) => {
                return {
                    ...state,
                    processingSettlement: action.payload.processingSettlement,
                };
            },
            prepare: (processingSettlement: ICourierSettlementListingOutput | null) => {
                return {
                    payload: {
                        processingSettlement: processingSettlement,
                    },
                };
            },
        },
        changeSettlementsListingPagination: (state: ISettlementListingSliceState, action: PayloadAction<IPaginationParams>) => {
            state.pagination = action.payload;
            state.isLoading = true;
        },
        setSettlementsListingMetadata: (state: ISettlementListingSliceState, action: PayloadAction<ISetMetadata>) => {
            state.metadata = action.payload.metadata;
        },
    },
});

export const {
    setLoading,
    setError,
    setInitialized,
    changeSettlementFilters,
    applySettlementFilters,
    fetchProcessingSettlement,
    fetchCurrentSettlement,
    setCurrentSettlement,
    setProcessingSettlement,
    getSettlementListing,
    setSettlementListing,
    changeSettlementsListingPagination,
    setSettlementsListingMetadata,
} = settlementListingSlice.actions;
export default settlementListingSlice.reducer;
