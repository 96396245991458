import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase, IFleetPartnerCourierOutput} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const createUnassignCourierAPI: ApiOperationResponseFunction<IFleetPartnerCourierOutput> = (
    authToken: string,
    payload: {
        id: string;
        courierId: string;
    }
): Observable<IApiSingleResponseBase<IFleetPartnerCourierOutput>> => {
    const id = {id: payload.id};
    return palipaliAPI.post(`api/fleet_partner_couriers/${payload.courierId}/unassign`, id, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
