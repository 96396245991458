import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {noTokenCheckPaliPaliAPI} from '../provider/noTokenCheckPaliPaliAPI';
import {ILoginApiResponse} from '../../types';

export function createLoginAPI(username: string, password: string): Observable<ILoginApiResponse> {
    return noTokenCheckPaliPaliAPI.post(
        'api/auth/token',
        {
            username: username,
            password: password,
        },
        new RestQueryParams()
    );
}
