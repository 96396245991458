import {
    FormikFieldTypes,
    FormikFormControlType,
    FormikGroupFieldConfig,
    FormikRegexSettings,
    IMultiselectOption,
    TextMaskType,
    ValidationErrorType,
    selectPhoneCodeList,
    isPhoneNumberValid,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const profileFormConfig = (cities: IMultiselectOption[]): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'email',
                    label: t('auth.formControls.email'),
                    validation: Yup.string()
                        .max(255)
                        .matches(FormikRegexSettings.EMAIL, `${t(ValidationErrorType.IS_EMAIL)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'firstName',
                    label: t('auth.formControls.firstName'),
                    validation: Yup.string()
                        .max(255)
                        .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                            const dashRegExp = /-.*-/;
                            const singleQuoteRegExp = /'.*'/;
                            const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                            if (result) {
                                return false;
                            }
                            return true;
                        })
                        .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
                        .matches(
                            FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE,
                            `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`
                        )
                        .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                    lettersOnly: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'lastName',
                    label: t('auth.formControls.lastName'),
                    validation: Yup.string()
                        .max(255)
                        .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                            const dashRegExp = /-.*-/;
                            const singleQuoteRegExp = /'.*'/;
                            const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                            if (result) {
                                return false;
                            }
                            return true;
                        })
                        .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
                        .matches(
                            FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE,
                            `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`
                        )
                        .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                    lettersOnly: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,

            fields: [
                {
                    name: 'country',
                    label: `${t('auth.formControls.dialingCode')}`,
                    validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.SELECT,
                    className: `account-profile-phone-code`,
                    formControlClassName: 'phone-code-select',
                    flexSettings: '0',
                    options: selectPhoneCodeList,
                },
                {
                    name: 'phone',
                    label: t('auth.formControls.phone'),
                    validation: Yup.string()
                        .test('is-valid-phone', `${t(ValidationErrorType.IS_PHONE)}`, function (value) {
                            const {country} = this.parent;

                            if (!value) return true;

                            try {
                                return isPhoneNumberValid(country, value);
                            } catch (error) {
                                return false;
                            }
                        })
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.MASKED,
                    textMaskType: TextMaskType.PHONE,
                    flexSettings: '1',
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'cityId',
                    label: t('auth.formControls.city'),
                    validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.SELECT,
                    options: cities,
                    isRequired: true,
                },
            ],
        },
    ];
};
export default profileFormConfig;
