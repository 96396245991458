import {PayloadAction} from '@reduxjs/toolkit';
import {IBillingInfoOutput, createBaseReducerSlice, IBillingInfoInput, IStripeOnboardingInput} from 'palipali-panel-common-web';

export interface IAccountSettlementState {
    billingInfo: IBillingInfoOutput | null;
    canDoStripeOnboarding: boolean;
    stripeOnboarding: IStripeOnboardingInput | null;
    stripeOnboardingUrl: string | null;
    isInitialized: boolean;
    isLoading: boolean;
    isStripeCardLoading: boolean;
    error: string | null;
}

export interface ISetBillingInfo {
    readonly billingInfo: IBillingInfoOutput | null;
}

export interface ISetCanDoStripeOnboarding {
    readonly canDoStripeOnboarding: boolean;
}

export interface ISetStripeOnboardingUrl {
    readonly stripeOnboardingUrl: string | null;
}

const initialState: IAccountSettlementState = {
    billingInfo: null,
    canDoStripeOnboarding: false,
    stripeOnboarding: null,
    stripeOnboardingUrl: null,
    isInitialized: false,
    isLoading: false,
    isStripeCardLoading: false,
    error: null,
};

const accountSettlementSlice = createBaseReducerSlice({
    name: 'accountSettlement',
    initialState,
    reducers: {
        fetchBillingInfo: {
            reducer: (state: IAccountSettlementState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(fleetPartnerId: string) {
                return {
                    payload: {id: fleetPartnerId},
                };
            },
        },
        setBillingInfo: {
            reducer: (state: IAccountSettlementState, action: PayloadAction<ISetBillingInfo>) => {
                return {
                    ...state,
                    isLoading: false,
                    billingInfo: action.payload.billingInfo,
                };
            },
            prepare(billingInfo: IBillingInfoOutput) {
                return {
                    payload: billingInfo,
                };
            },
        },
        updateBillingInfo: {
            reducer: (state: IAccountSettlementState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(billingInfo: IBillingInfoInput, fleetPartnerId: string) {
                return {
                    payload: {billingInfo: billingInfo, id: fleetPartnerId},
                };
            },
        },
        fetchCanDoStripeOnboarding: {
            reducer: (state: IAccountSettlementState) => {
                return {
                    ...state,
                    isStripeCardLoading: true,
                };
            },
            prepare(fleetPartnerId: string) {
                return {
                    payload: {id: fleetPartnerId},
                };
            },
        },
        setCanDoStripeOnboarding: {
            reducer: (state: IAccountSettlementState, action: PayloadAction<ISetCanDoStripeOnboarding>) => {
                return {
                    ...state,
                    isStripeCardLoading: false,
                    canDoStripeOnboarding: action.payload.canDoStripeOnboarding,
                };
            },
            prepare(canDoStripeOnboarding: boolean) {
                return {
                    payload: {
                        canDoStripeOnboarding: canDoStripeOnboarding,
                    },
                };
            },
        },
        updateStripeOnboarding: {
            reducer: (state: IAccountSettlementState) => {
                return {
                    ...state,
                    isStripeCardLoading: true,
                };
            },
            prepare(stripeOnboarding: IStripeOnboardingInput, fleetPartnerId: string) {
                return {
                    payload: {stripeOnboarding: stripeOnboarding, id: fleetPartnerId},
                };
            },
        },
        setStripeOnboardingUrl: {
            reducer: (state: IAccountSettlementState, action: PayloadAction<ISetStripeOnboardingUrl>) => {
                return {
                    ...state,
                    isStripeCardLoading: false,
                    stripeOnboardingUrl: action.payload.stripeOnboardingUrl,
                };
            },
            prepare(stripeOnboardingUrl: string | null) {
                return {
                    payload: {
                        stripeOnboardingUrl: stripeOnboardingUrl,
                    },
                };
            },
        },
        setStripeCardLoading: {
            reducer: (state: IAccountSettlementState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isStripeCardLoading: action.payload,
                };
            },
            prepare(isStripeCardLoading: boolean) {
                return {
                    payload: isStripeCardLoading,
                };
            },
        },
    },
});

export const {
    setLoading,
    setStripeCardLoading,
    setError,
    setBillingInfo,
    setCanDoStripeOnboarding,
    fetchBillingInfo,
    fetchCanDoStripeOnboarding,
    updateBillingInfo,
    updateStripeOnboarding,
    setStripeOnboardingUrl,
} = accountSettlementSlice.actions;
export default accountSettlementSlice.reducer;
