import {CustomIconProps} from '../../../types/iconTypes';
import React from 'react';

const TrashIcon = (props: CustomIconProps) => (
    <svg width={props.size || 18} height={props.size || 18} viewBox="0 0 18 18" fill="none" {...props}>
        <path
            d="M11.025 4.95V4.41C11.025 3.65393 11.025 3.27589 10.8779 2.98711C10.7484 2.73309 10.5419 2.52657 10.2879 2.39714C9.99911 2.25 9.62107 2.25 8.865 2.25H7.785C7.02893 2.25 6.65089 2.25 6.36211 2.39714C6.10809 2.52657 5.90157 2.73309 5.77214 2.98711C5.625 3.27589 5.625 3.65393 5.625 4.41V4.95M6.975 8.6625V12.0375M9.675 8.6625V12.0375M2.25 4.95H14.4M13.05 4.95V12.51C13.05 13.6441 13.05 14.2112 12.8293 14.6443C12.6351 15.0254 12.3254 15.3351 11.9443 15.5293C11.5112 15.75 10.9441 15.75 9.81 15.75H6.84C5.70589 15.75 5.13884 15.75 4.70567 15.5293C4.32464 15.3351 4.01486 15.0254 3.82071 14.6443C3.6 14.2112 3.6 13.6441 3.6 12.51V4.95"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default TrashIcon;
