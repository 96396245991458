import React from 'react';
import DatePicker from 'react-datepicker';
import {IEventCalendarState, IEventCalendarProps} from '../../../types';
import styles from './styles.module.scss';
import {isSameValue} from '../../..';
import {getDateFromString} from '../../../utils/dateUtils';

type Props = IEventCalendarProps;
type State = IEventCalendarState;

class EventCalendarFormControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            availableDates: null,
            selectedDate: null,
            selectedDay: null,
            eventDates: null,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (!isSameValue(this.props.eventDates, prevProps.eventDates)) {
            this.setState({eventDates: this.props.eventDates});
        }
    }

    render() {
        return (
            <React.Fragment>
                <DatePicker
                    selected={this.state.selectedDay}
                    onChange={(e) => this.onDateChange(e)}
                    onMonthChange={(e) => this.onMonthChange(e)}
                    minDate={this.props.minDate ? getDateFromString(this.props.minDate) : null}
                    maxDate={this.props.maxDate ? getDateFromString(this.props.maxDate) : null}
                    renderDayContents={this.renderDayContents}
                    inline
                    formatWeekDay={(day: string) => day.substr(0, 3)}
                    disabledKeyboardNavigation
                />
            </React.Fragment>
        );
    }

    private renderDayContents = (day: number, date: Date) => {
        if (!this.state.eventDates) {
            return <span>{day}</span>;
        }

        let recommendationNumber;

        return (
            <React.Fragment>
                <span>{day}</span>
                {this.state.eventDates
                    .map((el: any) => {
                        if (el[0] === `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`) {
                            recommendationNumber = el[1];
                        }
                        return el[0];
                    })
                    .includes(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`) ? (
                    <div className={`calendar-label ${styles.calendarLabel}`}>
                        <span className="reccomendation-number">{recommendationNumber !== 0 ? recommendationNumber : ''}</span>
                    </div>
                ) : null}
            </React.Fragment>
        );
    };

    private onMonthChange = (e: any) => {
        if (e) {
            const eventData = {
                target: {
                    value: {
                        name: 'month',
                        value: e,
                    },
                },
            };
            this.props.onChange(eventData);
        }
    };

    private onDateChange = (e: any) => {
        if (e) {
            const eventData = {
                target: {
                    value: {
                        name: 'date',
                        value: e,
                    },
                },
            };
            this.setState({selectedDay: e});
            this.props.onChange(eventData);
        }
    };
}

export default EventCalendarFormControl;
