import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase, IRawRestQueryParams} from '../../types';
import {IVehicleDetailsOutput} from '../../model/vehicle';

export function getVehicleDetailsAPI(
    authToken: string,
    payload?: {vehicleId: string},
    params?: IRawRestQueryParams | []
): Observable<IApiSingleResponseBase<IVehicleDetailsOutput>> {
    return palipaliAPI.get(`api/vehicles/${payload.vehicleId}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
