import {Observable} from 'rxjs';
import {ApiResponseFunction} from '../base/apiConnectionInfrastructure';
import {IApiResponseBase, IRawRestQueryParams, IFleetPartnerCourierListingOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const getFleetPartnerCouriersListAPI: ApiResponseFunction<IFleetPartnerCourierListingOutput> = (
    authToken: string,
    vehicleId?: string,
    params?: IRawRestQueryParams | []
): Observable<IApiResponseBase<IFleetPartnerCourierListingOutput[]>> => {
    return palipaliAPI.get('api/fleet_partner_couriers/list_mine', new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
        'X-Unassign-Target-Vehicle-Id': vehicleId || null,
    });
};
