import {Epic, StateObservable} from 'redux-observable';
import {map, mergeMap} from 'rxjs/operators';
import {of, timer} from 'rxjs';
import {renewAuthToken} from '../reducers/authSlice';
import jwtDecode from 'jwt-decode';
import {authTokenSelector, refreshTokenSelector} from '../selectors/authSelectors';
import {initLogout} from '../reducers/loginSlice';

const reauthenticateEpic: Epic = (action$, state$: StateObservable<any>) =>
    timer(0, 1000 * 60 * 5).pipe(
        map(() => {
            const authToken = authTokenSelector(state$.value);
            if (null === authToken || undefined === authToken) {
                return null;
            }
            const decoded: any = jwtDecode(authToken),
                tokenExpiration = decoded.exp;
            return tokenExpiration - Math.floor(new Date().getTime() / 1000);
        }),
        mergeMap((remainingCount: number | null) => {
            const actions = [];
            if (null === remainingCount) {
            } else if (remainingCount < 600) {
                actions.push(renewAuthToken(refreshTokenSelector(state$.value), authTokenSelector(state$.value)));
            }

            return of(...actions);
        })
    );

export default reauthenticateEpic;
