import type {SVGProps} from 'react';
import React from 'react';

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={16} height={16} fill="none" {...props}>
        <path d="M4 6L8 10L12 6" stroke="#6C737F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default ChevronDownIcon;
