import {Observable} from 'rxjs';
import {ITeamMemberOutput} from '../../model/team';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IUpdateTeamMemberInput} from '../../model/teamMember';

export const updateTeamMemberAPI: ApiOperationResponseFunction<ITeamMemberOutput> = (
    authToken: string,
    payload: {member: IUpdateTeamMemberInput}
): Observable<IApiSingleResponseBase<ITeamMemberOutput>> => {
    const updatePayload: {role: string} = {role: payload.member.role},
        id: string = payload.member.id;
    return palipaliAPI.put(`api/team_members/${id}`, updatePayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
