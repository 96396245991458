import {Button, Stack, Typography, useTheme} from '@mui/material';
import {
    CustomModalComponent,
    IAccountMeOutput,
    Loader,
    LoaderType,
    Translation,
    accountSelector,
    AlertTriangle,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {canUnassignCourier, unassignCourier} from 'src/store/reducers/courierListingSlice';
import {canBeUnassignedSelector, unassignCourierCheckingSelector} from 'src/store/selectors/courierListingSelector';

interface ICourierUnassignProps {
    id: string | null | undefined;
}

const CourierUnassignModal: React.FC<ICourierUnassignProps> = ({id}) => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        account: IAccountMeOutput = useSelector(accountSelector),
        canUnassign = useSelector(canBeUnassignedSelector),
        canUnassignCheckInProgress = useSelector(unassignCourierCheckingSelector);

    useEffect(() => {
        if (id !== '') {
            dispatch(canUnassignCourier(id));
        }
    }, [id]);

    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`couriers.unassignCourier.title`} />
                </Typography>
            }
            content={
                <Stack className="description unassign-courier" gap={1.6}>
                    {canUnassignCheckInProgress ? (
                        <Loader showLoader={canUnassignCheckInProgress} type={LoaderType.Local} />
                    ) : canUnassign ? (
                        <>
                            <AlertTriangle className="triangle" />
                            <Typography className="unassign-description">
                                <Translation text="couriers.unassignCourier.description" />
                            </Typography>
                            <Typography className="unassign-warning" color="error.dark">
                                <Translation text="couriers.unassignCourier.warning" />
                            </Typography>
                        </>
                    ) : (
                        <>
                            <AlertTriangle className="triangle" />
                            <Typography className="unassign-description">
                                <Translation text="couriers.unassignCourier.cannotBeUnassigned" />
                            </Typography>
                        </>
                    )}
                </Stack>
            }
            footer={
                canUnassign ? (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="couriers-unassign-courier-modal-submit-button"
                        onClick={() => dispatch(unassignCourier(id, account?.completedAccounts?.fleetPartner?.id))}>
                        <Translation text={`couriers.unassignCourier.button`} />
                    </Button>
                ) : undefined
            }
        />
    );
};

export default CourierUnassignModal;
