import {Box} from '@mui/material';
import {IPackageSizesOutput, Translation} from 'palipali-panel-common-web';
import React from 'react';

interface VehicleJobCategoriesComponentProps {
    packageSizes: IPackageSizesOutput;
}
const VehicleJobCategoriesComponent: React.FC<VehicleJobCategoriesComponentProps> = ({packageSizes}) => {
    if (!packageSizes) return <Translation text="vehicle.vehicleList.noJobCategoriesPresent" />;
    const availableJobCategories = Object.entries(packageSizes)
        .filter(([, value]) => value === true)
        .map(([key]) => key);
    const orderSizeIcon = (size: string) => {
        switch (size) {
            case 's':
                return (
                    <span key={`item_${size}`} className="package-size-icon small">
                        {size}
                    </span>
                );
            case 'l':
                return (
                    <span key={`item_${size}`} className="package-size-icon large">
                        {size}
                    </span>
                );
            case 'm':
                return (
                    <span key={`item_${size}`} className="package-size-icon medium">
                        {size}
                    </span>
                );
            default:
                return null;
        }
    };
    return <Box className="job-categories-wrapper">{availableJobCategories.map((size) => orderSizeIcon(size))}</Box>;
};
export default VehicleJobCategoriesComponent;
