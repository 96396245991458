import {Drawer} from '@mui/material';
import PropTypes from 'prop-types';
import type {FC} from 'react';
import React from 'react';
import {NavColor, Section} from '../../types/themeTypes';
import {UserRole} from '../../../types';
import {Nav} from '../Nav';
import useCssVars from '../useCssVars';

const MOBILE_NAV_WIDTH = 280;

interface IMobileNavHostProps {
    color?: NavColor;
    onClose?: () => void;
    open?: boolean;
    sections?: Section[];
    subMenuSections?: Section[];
    userRole?: UserRole;
}

export const MobileNavHost: FC<IMobileNavHostProps> = (props) => {
    const {color = 'evident', open, onClose} = props;
    const cssVars = useCssVars(color);

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    ...cssVars,
                    backgroundColor: 'var(--nav-bg)',
                    color: 'var(--nav-color)',
                    width: MOBILE_NAV_WIDTH,
                },
            }}
            variant="temporary">
            <Nav sections={props.sections} subMenuSections={props.subMenuSections} userRole={props.userRole} />
        </Drawer>
    );
};

MobileNavHost.propTypes = {
    color: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident']),
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
