import React from 'react';
import Translation from '../Translation';

export type IFormErrorMessagesProps = {
    errorMessages: string[];
};

class FormErrorMessages extends React.Component<IFormErrorMessagesProps, {}> {
    render() {
        if (!this.props.errorMessages) {
            return null;
        }

        return (this.props.errorMessages || []).map((errorKey: any, index: number) => (
            <span key={index} className="error-element">
                <Translation text={errorKey} />
            </span>
        ));
    }
}

export default FormErrorMessages;
