import {Observable} from 'rxjs';
import {IApiSingleResponseBase, ICanOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export function getCanDeleteVehicleAPI(
    authToken: string,
    payload: {currentlyDeletedVehicleId: string}
): Observable<IApiSingleResponseBase<ICanOutput>> {
    return palipaliAPI.get(`api/vehicles/${payload.currentlyDeletedVehicleId}/can_delete`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
