import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings} from '../../FormikForm';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {ValidationErrorType} from '../../../utils/formValidators';

const newPasswordFormConfig = (): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'password',
            label: t('auth.formControls.password'),
            autofocus: true,
            validation: Yup.string()
                .max(255)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.PASSWORD,
        },
        {
            name: 'confirmPassword',
            label: t('auth.formControls.confirmPassword'),
            validation: Yup.string()
                .max(255)
                .matches(FormikRegexSettings.PASSWORD, `${t(ValidationErrorType.IS_PASSWORDS_STRING)}`)
                .oneOf([Yup.ref('password')], `${t(ValidationErrorType.IS_PASSWORDS_SAME)}`)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.PASSWORD,
        },
        {
            name: 'new-password-button',
            label: t('auth.newPassword.setPasswordButton'),
            type: FormikFieldTypes.SUBMIT_BUTTON,
            sx: {mt: '2.2rem', fontSize: '1.5rem'},
            variant: 'contained',
            validation: {},
        },
    ];
};
export default newPasswordFormConfig;
