import {Box, Grid, Typography} from '@mui/material';
import {
    formatNumberToMoneyWithThousands,
    formattedMetersToKilometers,
    getCurrencySymbol,
    timeWithHoursAndMinutes,
    BankNote01,
    Clock,
    RouteIcon,
} from 'palipali-panel-common-web';
import React from 'react';
import {useSelector} from 'react-redux';
import {reportsMetadataSelector} from '../../../store/selectors/reportsSelectors';
import ReportTotalsCard from './ReportTotalsCard';

interface IReportsTotalsProps {
    haveResults?: boolean;
}
const ReportsTotals: React.FC<IReportsTotalsProps> = ({haveResults}) => {
    const totals = useSelector(reportsMetadataSelector);

    const amount = totals?.totalIncome?.amount;
    const renderAmount = () => {
        if (!amount || amount === '0' || !haveResults) {
            return (
                <Typography
                    variant={'h5'}
                    component="span"
                    fontWeight={600}
                    color={!amount || amount === '0' ? 'primary' : 'textSecondary'}>
                    {'-'}
                </Typography>
            );
        }
        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography component={'div'} variant="h5" color={amount && +amount > 0 ? 'primary' : 'textPrimary'}>
                    {formatNumberToMoneyWithThousands(totals?.totalIncome, true)}
                </Typography>
                <Typography variant="h5" color="textPrimary" ml={'0.5rem'}>
                    {getCurrencySymbol(totals?.totalIncome?.currency)}
                </Typography>
            </Box>
        );
    };

    return (
        <Grid mb={4} container spacing={2}>
            <ReportTotalsCard title={'reports.totals.timeOfRealisation'} icon={<Clock />}>
                {haveResults ? (
                    <Typography noWrap variant={'h5'} color={'textPrimary'}>
                        {timeWithHoursAndMinutes(totals.totalTime)}
                    </Typography>
                ) : (
                    <Typography variant={'h5'} fontWeight={600} color={'textSecondary'}>
                        {'-'}
                    </Typography>
                )}
            </ReportTotalsCard>
            <ReportTotalsCard title={'reports.totals.distanceDone'} icon={<RouteIcon />}>
                {haveResults ? (
                    <Typography noWrap variant={'h5'} color={'textPrimary'}>
                        {formattedMetersToKilometers(totals.totalDistance)}
                    </Typography>
                ) : (
                    <Typography variant={'h5'} fontWeight={600} color={'textSecondary'}>
                        {'-'}
                    </Typography>
                )}
            </ReportTotalsCard>
            <ReportTotalsCard title={'reports.totals.gain'} icon={<BankNote01 />}>
                {renderAmount()}
            </ReportTotalsCard>
        </Grid>
    );
};

export default ReportsTotals;
