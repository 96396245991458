import {
    FormikFormControlType,
    FormikFieldTypes,
    FormikGroupFieldConfig,
    TextMaskType,
    FormikRegexSettings,
    ValidationErrorType,
    validateNip,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export const billingInfoFormConfig = (): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'companyName',
                    label: t('account.settlements.billingInfoCard.formControls.companyName'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    className: `account-settlements-billing-info-form-company-name`,
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'taxIdentifier',
                    label: t('account.settlements.billingInfoCard.formControls.taxIdentifier'),
                    validation: Yup.string()
                        .matches(FormikRegexSettings.NIP, `${t(ValidationErrorType.IS_NIP)}`)
                        .test('isNipValid', `${t(ValidationErrorType.IS_NIP)}`, validateNip)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    className: `account-settlements-billing-info-form-tax-identifier`,
                    type: FormikFieldTypes.MASKED,
                    textMaskType: TextMaskType.NIP,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'street',
                    label: t('account.settlements.billingInfoCard.formControls.street'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    className: `account-settlements-billing-info-form-street`,
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'houseNumber',
                    label: t('account.settlements.billingInfoCard.formControls.houseNumber'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    className: `account-settlements-billing-info-form-house-number`,
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 3,
                    isRequired: true,
                },
                {
                    name: 'flatNumber',
                    label: t('account.settlements.billingInfoCard.formControls.flatNumber'),
                    validation: Yup.string().max(255).notRequired(),
                    className: `account-settlements-billing-info-form-flat-number`,
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 3,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'city',
                    label: t('account.settlements.billingInfoCard.formControls.city'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    className: `account-settlements-billing-info-form-city`,
                    controlFlexWidth: 3,
                    isRequired: true,
                },
                {
                    name: 'zip',
                    label: t('account.settlements.billingInfoCard.formControls.zip'),
                    validation: Yup.string()
                        .matches(FormikRegexSettings.POSTAL_CODE, `${t(ValidationErrorType.IS_POST_CODE)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.MASKED,
                    textMaskType: TextMaskType.POSTAL_CODE,
                    className: `account-settlements-billing-info-form-zip`,
                    controlFlexWidth: 3,
                    isRequired: true,
                },
            ],
        },
    ];
};
