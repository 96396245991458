import {Stack} from '@mui/material';
import React from 'react';
import BillingInfoCard from './BillingInfoCard';
import StripeAccountCard from './StripeAccountCard';

const SettlementTab: React.FC = () => {
    return (
        <Stack spacing={5}>
            <BillingInfoCard />
            <StripeAccountCard />
        </Stack>
    );
};

export default SettlementTab;
