import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const deleteLocationAPI: ApiOperationResponseFunction<null> = (
    authToken: string,
    payload: {
        locationId: string;
    }
): Observable<IApiSingleResponseBase<null>> => {
    return palipaliAPI.delete(`api/locations/${payload.locationId}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
