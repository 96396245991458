import {FormikFieldConfig, FormikFieldTypes, ValidationErrorType} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export const createVehicleInsuranceFormConfig = (): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'number',
            label: t('vehicles.vehicleOperation.insurance.form.number.label'),
            validation: Yup.string()
                .max(255)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            className: 'vehicles-create-vehicle-insurance-form-number',
            type: FormikFieldTypes.TEXT,
        },
        {
            name: 'until',
            label: t('vehicles.vehicleOperation.insurance.form.until.label'),
            validation: Yup.date().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            className: 'vehicles-create-vehicle-insurance-form-until w-100',
            type: FormikFieldTypes.DATE,
            dateFormat: 'dd.MM.yyyy',
            disablePast: true,
            minDate: new Date().setDate(new Date().getDate() + 1),
        },
        {
            name: 'files',
            label: t('vehicles.vehicleOperation.insurance.form.documentPhoto.label'),
            validation: Yup.object()
                .shape({
                    name: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    fileKey: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    url: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                })
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            acceptedFileTypes: 'image/jpeg, image/png, application/pdf',
            className: 'vehicles-create-vehicle-insurance-form-insurance-document-photo',
            multipleFiles: false,
            placeholder: `${t('vehicles.vehicleOperation.inspection.form.documentPhoto.placeholderBack')}`,
            type: FormikFieldTypes.FILE,
        },
    ];
};
