import type {SVGProps} from 'react';
import React from 'react';

const RedTrashIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <g id="trash-01">
            <path
                id="Icon"
                d="M12.25 5.5V4.9C12.25 4.05992 12.25 3.63988 12.0865 3.31901C11.9427 3.03677 11.7132 2.8073 11.431 2.66349C11.1101 2.5 10.6901 2.5 9.85 2.5H8.65C7.80992 2.5 7.38988 2.5 7.06901 2.66349C6.78677 2.8073 6.5573 3.03677 6.41349 3.31901C6.25 3.63988 6.25 4.05992 6.25 4.9V5.5M7.75 9.625V13.375M10.75 9.625V13.375M2.5 5.5H16M14.5 5.5V13.9C14.5 15.1601 14.5 15.7902 14.2548 16.2715C14.039 16.6948 13.6948 17.039 13.2715 17.2548C12.7902 17.5 12.1601 17.5 10.9 17.5H7.6C6.33988 17.5 5.70982 17.5 5.22852 17.2548C4.80516 17.039 4.46095 16.6948 4.24524 16.2715C4 15.7902 4 15.1601 4 13.9V5.5"
                stroke="#B42318"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default RedTrashIcon;
