import type {SVGProps} from 'react';
import React from 'react';

const Users03 = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="currentColor"
            d="M9.4998 12c2.4853 0 4.5-2.0147 4.5-4.5S11.9851 3 9.4998 3s-4.5 2.0147-4.5 4.5 2.0148 4.5 4.5 4.5Zm0 3c-2.8306 0-5.3464 1.5446-6.9407 3.9383-.3493.5245-.524.7867-.5038 1.1216.0156.2608.1866.5801.395.7377C2.7179 21 3.086 21 3.8222 21h11.3553c.7362 0 1.1043 0 1.3719-.2024.2084-.1576.3793-.4769.395-.7377.0201-.3349-.1545-.5971-.5038-1.1216C14.8463 16.5446 12.3305 15 9.4998 15Z"
            opacity={0.12}
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17.9998 15.8369c1.4559.7314 2.7042 1.9051 3.6153 3.3727.1804.2907.2706.436.3018.6372.0634.409-.2163.9117-.5971 1.0736-.1875.0796-.3983.0796-.82.0796m-4.5-9.4678c1.4818-.7363 2.5-2.2653 2.5-4.0322 0-1.7669-1.0182-3.2959-2.5-4.0322m-2 4.0322c0 2.4853-2.0147 4.5-4.5 4.5s-4.5-2.0147-4.5-4.5S7.0146 3 9.4998 3c2.4853 0 4.5 2.0147 4.5 4.5ZM2.5591 18.9383C4.1534 16.5446 6.6692 15 9.4998 15c2.8307 0 5.3465 1.5446 6.9408 3.9383.3493.5245.5239.7867.5038 1.1216-.0157.2608-.1866.5801-.395.7377C16.2818 21 15.9137 21 15.1775 21H3.8222c-.7362 0-1.1043 0-1.372-.2024-.2083-.1576-.3793-.4769-.395-.7377-.02-.3349.1546-.5971.5039-1.1216Z"
        />
    </svg>
);

export default Users03;
