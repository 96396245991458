import React, {PropsWithChildren, useEffect, useState} from 'react';
import {isNullOrUndefined} from '../../utils/runtimeUtils';
import {ITabsProps} from '../../types';

type Props = ITabsProps;

const Tabs: React.FC<PropsWithChildren<Props>> = ({chosenTab, selectedTab, sourceTypes, ...props}) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        if (!chosenTab !== null) {
            select(chosenTab);
        }
    }, [chosenTab]);

    const renderContent = () => {
        return React.Children.map(props.children, (item: any) => {
            if (item.props.className !== 'tabs-content') {
                return null;
            }

            let activeTabBody = null;
            item.props.children.forEach((tabBodyDiv: any, i) => {
                if (!tabBodyDiv) {
                    return;
                }
                if (i === activeTab) {
                    activeTabBody = tabBodyDiv;
                }
            });

            return activeTabBody;
        });
    };

    const renderTabs = () => {
        return React.Children.map(props.children, (item: any) => {
            if (item.props.className !== 'tab-headers') {
                return null;
            }
            const header: any = [];
            item.props.children.forEach((tabHeaderDiv: any, i) => {
                if (!tabHeaderDiv) {
                    return;
                }
                const disabled = tabHeaderDiv.props.disabled ? 'disabled' : '';
                const active = activeTab === i ? 'active' : '';

                header.push(
                    <li id={'header' + i} className="nav-item" onClick={() => select(i)} data-toggle="tab" role="tab">
                        <button
                            className={`nav-link ${active} ${disabled}`}
                            id={'header-tab-' + i}
                            data-toggle="tab"
                            role="tab"
                            aria-controls={'header-tab-' + i}
                            aria-selected="false">
                            {tabHeaderDiv}
                        </button>
                    </li>
                );
            });
            return header;
        });
    };

    const select = (activeTab: number | null) => {
        if (isNullOrUndefined(activeTab)) {
            return;
        }
        if (activeTab === activeTab) {
            return;
        }
        selectedTab(activeTab);
        setActiveTab(activeTab);
    };

    return (
        <div className="tabs">
            <ul className="nav nav-tabs" id="nav-tab" role="tablist">
                {renderTabs()}
            </ul>
            <div className="tab-content-container">{renderContent()}</div>
        </div>
    );
};

export default Tabs;
