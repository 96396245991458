import {Observable} from 'rxjs';
import {IApiSingleResponseBase, IPurchaserCurrentJobDetailsOutput} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IJobCancelInput} from '../../model/jobListing';

export const updateCancelJobAPI: ApiOperationResponseFunction<IPurchaserCurrentJobDetailsOutput> = (
    authToken: string,
    payload: {cancelReason: IJobCancelInput; id: string}
): Observable<IApiSingleResponseBase<IPurchaserCurrentJobDetailsOutput>> => {
    const updatePayload: IJobCancelInput = payload.cancelReason,
        id: string = payload.id;
    return palipaliAPI.put(`api/jobs/${id}/cancel`, updatePayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
