import type {SVGProps} from 'react';
import React from 'react';

const PhoneHandle = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.64001 11.7167C7.72723 11.1947 8.74621 10.4832 9.64739 9.58197C10.5486 8.68079 11.2602 7.66181 11.7822 6.57459C11.8271 6.48107 11.8495 6.43432 11.8668 6.37515C11.9281 6.16488 11.884 5.90669 11.7565 5.72862C11.7206 5.67851 11.6778 5.63564 11.592 5.54991C11.3298 5.2877 11.1987 5.1566 11.113 5.02477C10.7898 4.5276 10.7898 3.88666 11.113 3.38949C11.1987 3.25766 11.3298 3.12656 11.592 2.86435L11.7382 2.7182C12.1368 2.31962 12.3361 2.12033 12.5501 2.01207C12.9758 1.79677 13.4785 1.79677 13.9041 2.01207C14.1182 2.12033 14.3175 2.31962 14.7161 2.7182L14.8343 2.83643C15.2315 3.23364 15.4301 3.43225 15.5818 3.70228C15.7501 4.00191 15.8711 4.46728 15.8701 4.81094C15.8692 5.12065 15.8091 5.33232 15.6889 5.75565C15.0432 8.0307 13.8249 10.1775 12.0339 11.9685C10.2429 13.7594 8.09612 14.9778 5.82108 15.6235C5.39774 15.7437 5.18607 15.8038 4.87636 15.8047C4.5327 15.8057 4.06733 15.6847 3.7677 15.5164C3.49768 15.3647 3.29907 15.1661 2.90185 14.7689L2.78362 14.6506C2.38504 14.252 2.18575 14.0528 2.07749 13.8387C1.86219 13.413 1.86219 12.9104 2.07749 12.4847C2.18575 12.2706 2.38504 12.0714 2.78362 11.6728L2.92978 11.5266C3.19198 11.2644 3.32308 11.1333 3.45492 11.0476C3.95208 10.7244 4.59302 10.7244 5.09019 11.0476C5.22202 11.1333 5.35312 11.2644 5.61533 11.5266C5.70106 11.6124 5.74393 11.6552 5.79404 11.6911C5.97211 11.8186 6.2303 11.8626 6.44057 11.8013C6.49974 11.7841 6.5465 11.7616 6.64001 11.7167Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default PhoneHandle;
