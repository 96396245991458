import React from 'react';
import UserDropdown from '../UserDropdown';
import {INavbarUserProps} from '../../../types';

type Props = INavbarUserProps;

const NavbarUser: React.FC<Props> = ({account, userMenu, unauthorizedUserMenu, isAuthenticated}) => {
    return (
        <ul className="nav navbar-nav align-items-center justify-content-center">
            <UserDropdown
                account={account}
                userMenu={userMenu}
                unauthorizedUserMenu={unauthorizedUserMenu}
                isAuthenticated={isAuthenticated}
            />
        </ul>
    );
};

export default NavbarUser;
