import type {SVGProps} from 'react';
import React from 'react';

const DoubleArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" {...props}>
        <g id="DoubleArrowRight">
            <path id="Vector" d="M5.29508 16.59L6.70508 18L12.7051 12L6.70508 6L5.29508 7.41L9.87508 12L5.29508 16.59Z" fill="#6C737F" />
            <path id="Vector_2" d="M11.2951 16.59L12.7051 18L18.7051 12L12.7051 6L11.2951 7.41L15.8751 12L11.2951 16.59Z" fill="#6C737F" />
        </g>
    </svg>
);

export default DoubleArrowRightIcon;
