import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, IPurchaserJobDetailsOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';

export function getHistoricalJobAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<IPurchaserJobDetailsOutput>> {
    return palipaliAPI.get(`api/jobs/${id}/historical`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
