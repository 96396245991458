import {useCallback, useEffect, useState} from 'react';
import {usePathname} from '../../hooks/themeHooks';

export const useMobileNav = () => {
    const pathname = usePathname();
    const [open, setOpen] = useState<boolean>(false);

    const handlePathnameChange = useCallback((): void => {
        if (open) {
            setOpen(false);
        }
    }, [open]);

    useEffect(() => {
        handlePathnameChange();
    }, [pathname]);

    const handleOpen = useCallback((): void => {
        setOpen(true);
    }, []);

    const handleClose = useCallback((): void => {
        setOpen(false);
    }, []);

    return {
        handleOpen,
        handleClose,
        open,
    };
};
