import {PayloadAction} from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {getAccountDataAPI} from '../../api/account/getAccountDataAPI';
import {createLoginAPI} from '../../api/user/createLoginAPI';
import {LanguageLocale} from '../../constants/locales';
import {IAccountMeOutput} from '../../model/account';
import {AlertType, UserRole} from '../../types';
import {handleApiError} from '../../utils/errorHandlingUtils';
import {setAccountState, setAccountStateFailure} from '../reducers/accountSlice';
import {addAlert} from '../reducers/alertSlice';
import {setAuthState} from '../reducers/authSlice';
import {fetchAllDictionaryData} from '../reducers/countrySlice';
import {
    getLoginCredentials,
    IGetLoginCredentials,
    ISetLoginWithToken,
    loginWithToken,
    setLoadingState,
    setLoginFailure,
} from '../reducers/loginSlice';
import {changeActiveLanguage} from '../reducers/sagaSlice';
import {getTeamListing} from '../reducers/teamListingSlice';
import {groupTeamsByRole} from '../../utils/tokenDecodingUtils';
import {changeTeamsByRoles} from '../reducers/teamsByRolesSlice';

const loginWithTokenFromURL: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(loginWithToken.type),
        switchMap((action: PayloadAction<ISetLoginWithToken>) => {
            const authToken = action.payload.authToken,
                decoded = jwt_decode(authToken),
                tokenExpiration = (decoded as any).exp,
                refresh_token = action.payload.refreshToken ? action.payload.refreshToken : (decoded as any).refresh_token,
                userRoles = (decoded as any).roles,
                username = (decoded as any).username,
                hasExpired = Math.floor(new Date().getTime() / 1000) > tokenExpiration;
            if (hasExpired) {
                return of(addAlert({message: 'alerts.invalidOrExpiredToken', type: AlertType.WARNING}));
            } else {
                if (isRoleMatched(userRoles, action.payload.userRole) !== undefined) {
                    return getAccountDataAPI(authToken).pipe(
                        switchMap((account: IAccountMeOutput) => {
                            const actions: any[] = [
                                setLoadingState(false),
                                setAuthState(username, authToken, refresh_token, true, userRoles),
                            ];
                            const activeLanguage = (state$ as any).value.saga.activeLanguage,
                                accountLocale = account.account.locale
                                    ? account.account.locale.split('_')[0]
                                    : activeLanguage
                                    ? activeLanguage
                                    : LanguageLocale.PL;

                            const teamsByRole = groupTeamsByRole(decoded);
                            actions.push(setAccountState(account));
                            actions.push(changeTeamsByRoles(teamsByRole));
                            actions.push(changeActiveLanguage(accountLocale));
                            actions.push(fetchAllDictionaryData());
                            if (action.payload.userRole === UserRole.PURCHASER) {
                                actions.push(getTeamListing());
                            }

                            return of(...actions);
                        }),
                        catchError((error: any) => {
                            return of(
                                setAccountStateFailure('alerts.noAccessError'),
                                setLoginFailure('alerts.noAccessError'),
                                setAuthState(null, null, null, false, null),
                                addAlert(handleApiError(error))
                            );
                        })
                    );
                } else {
                    return of(setLoginFailure('alerts.noAccessError'), addAlert(handleApiError('alerts.noAccessError')));
                }
            }
        }),
        catchError((error: any) => of(addAlert(handleApiError(error))))
    );

const loginStart: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getLoginCredentials.type),
        switchMap((action: PayloadAction<IGetLoginCredentials>): any => {
            return createLoginAPI(action.payload.username, action.payload.password).pipe(
                switchMap((resp: any) => {
                    const token = resp.token,
                        refresh_token = resp.refresh_token,
                        decoded = jwt_decode(resp.token),
                        userRoles = (decoded as any).roles;
                    const isFleetPartnerLoggingToPurchaserPanel =
                        action.payload.role === UserRole.PURCHASER && isRoleMatched(userRoles, UserRole.ROLE_FLEET_PARTNER) !== undefined;
                    if (isRoleMatched(userRoles, action.payload.role) !== undefined && !isFleetPartnerLoggingToPurchaserPanel) {
                        return getAccountDataAPI(token).pipe(
                            mergeMap((account: IAccountMeOutput) => {
                                const actions: any[] = [
                                    setAuthState((state$ as any).value.login.username, token, refresh_token, true, userRoles),
                                ];
                                const activeLanguage = (state$ as any).value.saga.activeLanguage,
                                    accountLocale = account.account.locale
                                        ? account.account.locale.split('_')[0]
                                        : activeLanguage
                                        ? activeLanguage
                                        : LanguageLocale.PL;

                                const teamsByRole = groupTeamsByRole(decoded);

                                actions.push(setAccountState(account));
                                actions.push(changeTeamsByRoles(teamsByRole));
                                actions.push(changeActiveLanguage(accountLocale));
                                actions.push(fetchAllDictionaryData());
                                if (action.payload.role === UserRole.PURCHASER) {
                                    actions.push(getTeamListing());
                                }

                                return of(...actions);
                            }),
                            catchError((error: any) => {
                                return of(
                                    setAccountStateFailure('alerts.noAccessError'),
                                    setLoginFailure('alerts.noAccessError'),
                                    setAuthState(null, null, null, false, null),
                                    addAlert(handleApiError(error))
                                );
                            })
                        );
                    } else {
                        return of(setLoginFailure('alerts.wrongAccountError'), addAlert(handleApiError('alerts.wrongAccountError')));
                    }
                }),
                catchError((error: any) => {
                    return of(setLoginFailure(handleApiError(error)?.message), addAlert(handleApiError(error)));
                })
            );
        })
    );

export function isRoleMatched(roles: string[], substring: string): string | undefined {
    return roles.find((element: string) => {
        if (element.includes(substring)) {
            return true;
        }
    });
}

const loginEpic = combineEpics(loginStart, loginWithTokenFromURL);

export default loginEpic;
