import {ILocationTypeOutput} from '../model/location';
import {IModelCity} from '../model/common';
import {IModelDictionaryDatum, IJobCancellationReasonOutput} from '../model/dictionaryDatum';
import {ISettlementPeriodResult} from '../model/settlementPeriods';
import {IJobProblemTypeOutput, Locale} from '../types';
import {DEFAULT_LOCALE} from '../model/account';

export const dictionaryTranslationName = (
    datum:
        | ILocationTypeOutput
        | IModelCity
        | IModelDictionaryDatum
        | ISettlementPeriodResult
        | IJobCancellationReasonOutput
        | IJobProblemTypeOutput,
    locale?: Locale | null
): string => {
    const validLocales: Locale[] = [Locale.LOCALE_EN, Locale.LOCALE_PL, Locale.LOCALE_UA],
        defaultLocale = DEFAULT_LOCALE,
        accountLocale = locale ? locale : defaultLocale,
        language = validLocales.includes(accountLocale)
            ? accountLocale.substring(0, accountLocale.indexOf('_'))
            : defaultLocale.substring(0, accountLocale.indexOf('_')),
        translatedName = (datum.name_i18n as any)[language];
    return translatedName;
};
