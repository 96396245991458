import {Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {confirmEmailChange} from '../../../store/reducers/accountSlice';
import {addAlert} from '../../../store/reducers/alertSlice';
import {AlertType, IIsLoadingComponentProps, LoaderType, UserRole} from '../../../types';
import Translation from '../../Translation';
import Loader from '../../Loader';

type Props = IIsLoadingComponentProps;

const ConfirmCourierEmailChange: React.FC<Props> = ({isLoading}) => {
    const {id} = useParams(),
        dispatch = useDispatch(),
        {t} = useTranslation();

    const sendConfirmCourierEmailChange = (): void => {
        if (!id) {
            dispatch(addAlert({message: 'auth.alert.invalidConfirmRegistrationURL', type: AlertType.WARNING}));
            return;
        }

        dispatch(confirmEmailChange(id, UserRole.COURIER));
    };

    return (
        <Card elevation={16}>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <CardHeader
                subheader={
                    <Typography color="text.secondary" variant="body2" key="subheader">
                        <Translation text="auth.confirmEmailChange.subtitle" />
                    </Typography>
                }
                sx={{pb: 0}}
                title={t('auth.confirmEmailChange.title')}
            />
            <CardContent>
                <Button
                    fullWidth
                    size="large"
                    sx={{mt: '2.2rem', fontSize: '1.5rem'}}
                    type="submit"
                    variant="contained"
                    onClick={() => sendConfirmCourierEmailChange()}
                    className="confirmEmailChange-button">
                    <Translation text="auth.confirmEmailChange.confirmButton" />
                </Button>
            </CardContent>
        </Card>
    );
};

export default ConfirmCourierEmailChange;
