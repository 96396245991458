import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {IMoneyOutput} from '../../model/common';

export function getCheckCancellationFeeAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<IMoneyOutput>> {
    return palipaliAPI.get(`api/jobs/${id}/cancellation_fee`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
