import {IconButton, InputAdornment, TextField} from '@mui/material';
import React, {useState} from 'react';
import VisibilityIcon from '../../../theme/icons/untitled-ui/duocolor/visibility';
import VisibilityOffIcon from '../../../theme/icons/untitled-ui/duocolor/visibility-off';
import {FormikFieldConfig} from '../../../components/FormikForm';
import {useTranslation} from 'react-i18next';

const PasswordFormControl: React.FC<FormikFieldConfig> = ({name, label, formik, className, isRequired}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {t} = useTranslation();

    return (
        <TextField
            key={`${name}-form-control`}
            error={!!(formik.touched[name] && formik.errors[name])}
            fullWidth
            helperText={formik.touched[name] && formik.errors[name]}
            label={t(label)}
            name={name}
            required={isRequired}
            type={showPassword ? 'text' : 'password'}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values[name]}
            inputProps={{
                className: `${name}-input ${className || ''}`,
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" key={`${name}-input-adornment`}>
                        <IconButton
                            key={`${name}-input-adornment-icon`}
                            aria-label="toggle password visibility"
                            tabIndex={-1}
                            onMouseDown={() => setShowPassword(!showPassword)}
                            onMouseUp={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordFormControl;
