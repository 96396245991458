import {
    Box,
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import {
    CustomModalComponent,
    CustomTablePagination,
    IVehicleListingOutput,
    Loader,
    LoaderType,
    NoDataInfo,
    PAGINATION_ITEMS_PER_PAGE,
    Translation,
    accountLocaleSelector,
    dictionaryTranslationName,
    arraysAreSame,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    assignVehiclesToCourier,
    changeCourierVehiclesListPagination,
    fetchCourierAssignedVehicleIds,
    fetchCourierVehiclesList,
    setCourierAssignedVehicles,
} from '../../../store/reducers/courierListingSlice';
import {
    courierAssignedVehicleIdsSelector,
    courierVehicleListMetadataSelector,
    courierVehicleListPaginationSelector,
    courierVehicleListSelector,
    courierVehicleLoadingSelector,
} from '../../../store/selectors/courierListingSelector';
import VehicleJobCategoriesComponent from '../../Vehicles/VehicleList/VehicleJobCategoriesComponent';

interface IVehicleAssignModalProps {
    selectedCourierAccountId: string | null;
    selectedCourierId: string | null;
}

const VehicleAssignModal: React.FC<IVehicleAssignModalProps> = ({selectedCourierAccountId, selectedCourierId}) => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        courierVehiclesList = useSelector(courierVehicleListSelector),
        activeLanguage = useSelector(accountLocaleSelector),
        metadata = useSelector(courierVehicleListMetadataSelector),
        pagination = useSelector(courierVehicleListPaginationSelector),
        selectedInitialCourierVehicleIds = useSelector(courierAssignedVehicleIdsSelector),
        isLoading = useSelector(courierVehicleLoadingSelector),
        [selectedCourierVehicleIds, setSelectedCourierVehicleIds] = useState<string[]>(selectedInitialCourierVehicleIds || []),
        [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        setIsButtonDisabled(
            arraysAreSame(selectedCourierVehicleIds, selectedInitialCourierVehicleIds ? selectedInitialCourierVehicleIds : [])
        );
    }, [selectedCourierVehicleIds, selectedInitialCourierVehicleIds]);

    useEffect(() => {
        dispatch(fetchCourierVehiclesList({courierId: selectedCourierAccountId}));

        return () => {
            setSelectedCourierVehicleIds([]);
            dispatch(setCourierAssignedVehicles(null));
        };
    }, []);

    useEffect(() => {
        dispatch(fetchCourierAssignedVehicleIds(selectedCourierAccountId));
    }, [selectedCourierAccountId, courierVehiclesList]);

    useEffect(() => {
        if (selectedInitialCourierVehicleIds) {
            setSelectedCourierVehicleIds(selectedInitialCourierVehicleIds);
        }
    }, [selectedInitialCourierVehicleIds]);

    const setSelectedVehicles = (value: boolean, vehicle: IVehicleListingOutput) => {
        setSelectedCourierVehicleIds((prevValues) => {
            if (prevValues) {
                if (value) {
                    return [...prevValues, vehicle.id];
                } else {
                    return prevValues.filter((value) => value !== vehicle.id);
                }
            }
            return [];
        });
    };

    const onAssignVehiclesToCourier = () => {
        const removeVehicleIds =
            (selectedInitialCourierVehicleIds || []).filter((item) => {
                return selectedCourierVehicleIds?.indexOf(item.toString()) === -1 ? item : null;
            }) || [];

        dispatch(assignVehiclesToCourier(selectedCourierId, selectedCourierVehicleIds, removeVehicleIds));
    };

    const renderTableRows = () => {
        if (!courierVehiclesList) return;

        if (!courierVehiclesList.length) {
            return (
                <TableRow>
                    <TableCell>
                        <NoDataInfo text={'couriers.assignVehicle.noVehicle'} />
                    </TableCell>
                </TableRow>
            );
        }

        return courierVehiclesList.map((vehicle: IVehicleListingOutput) => {
            const isSelected = selectedCourierVehicleIds ? selectedCourierVehicleIds.includes(vehicle.id) : false;
            return (
                <TableRow hover key={vehicle.id}>
                    <TableCell>
                        <span className="checkbox-list-item">
                            <Checkbox
                                checked={isSelected}
                                disabled={vehicle.canUnassign === false && isSelected}
                                onChange={(event, value) => setSelectedVehicles(value, vehicle)}
                            />
                            <span className="checkbox-list-item-details">
                                <Typography
                                    color="text.primary"
                                    variant="subtitle2">{`${vehicle.make} ${vehicle.model} ${vehicle.productionYear}`}</Typography>
                                <Typography className="checkbox-list-item-registration">{`${
                                    vehicle.registrationNumber
                                } | ${dictionaryTranslationName(vehicle.color, activeLanguage)}`}</Typography>
                            </span>
                            <VehicleJobCategoriesComponent packageSizes={vehicle.packageSizes} />
                        </span>
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <CustomModalComponent
            theme={theme}
            customClasses={'vehicle-assign-modal'}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`couriers.assignVehicle.title`} />
                </Typography>
            }
            content={
                <Stack spacing={3} className="assign-modal-list">
                    <Box sx={{marginBottom: '1.6rem'}}>
                        <Typography variant="body1" color="text.secondary">
                            <Translation text="couriers.assignVehicle.description" />
                            <Typography variant="body1" color="text.primary" component="span">
                                <Translation text="couriers.assignVehicle.descriptionVehicles" />
                            </Typography>
                        </Typography>
                    </Box>

                    <TableContainer className={'checkbox-list'}>
                        <Table className="custom-list">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography className="checkbox-list-header" color="text.primary">
                                            <Translation text="couriers.assignVehicle.availableVehicles" />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderTableRows()}</TableBody>
                        </Table>
                    </TableContainer>

                    <CustomTablePagination
                        metadata={metadata}
                        onChangePage={(event, pageNumber) =>
                            dispatch(
                                changeCourierVehiclesListPagination({
                                    page: pageNumber,
                                    itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
                                })
                            )
                        }
                        page={pagination.page}
                        rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
                    />
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Stack>
            }
            footer={
                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    className="couriers-assign-vehicle-modal-submit-button"
                    form="courier-invite"
                    onClick={() => onAssignVehiclesToCourier()}>
                    <Translation text="couriers.assignVehicle.assignButton" />
                </Button>
            }
        />
    );
};
export default VehicleAssignModal;
