import {Typography} from '@mui/material';
import {SeverityPill, Translation, VehicleStatus} from 'palipali-panel-common-web';
import {SeverityPillColor} from 'palipali-panel-common-web/dist/theme/components/SeverityPill';
import React from 'react';

interface IVehicleStatusBadgeProps {
    status: string;
    expiring: boolean;
}

export const vehicleStatusColorSettings = {
    info: {color: 'info', customColor: '#107569', backgroundColor: '#e1f5f9'},
    successDarkest: {color: 'success', customColor: '#134E48', backgroundColor: '#e2e9e9'},
    successDark: {color: 'success', customColor: '#107569', backgroundColor: '#e2f6f3'},
    warningDark: {color: 'warning', customColor: '#B54708', backgroundColor: '#fef1e1'},
    error: {color: 'error', customColor: '#B42318', backgroundColor: '#fde8e7'},
};

const VehicleStatusBadge: React.FC<IVehicleStatusBadgeProps> = ({status, expiring}) => {
    const statusOverride = expiring ? 'expiring' : status;

    const getVehicleStatusBadgeColor = () => {
        switch (statusOverride) {
            case VehicleStatus.VERIFICATION_PENDING:
            case VehicleStatus.PENDING:
            case 'expiring':
                return vehicleStatusColorSettings.warningDark;
            case VehicleStatus.ACTIVE:
                return vehicleStatusColorSettings.successDark;
            case VehicleStatus.INACTIVE:
            case VehicleStatus.REJECTED:
                return vehicleStatusColorSettings.error;
            case VehicleStatus.VERIFIED:
                return vehicleStatusColorSettings.successDark;
            default:
                return vehicleStatusColorSettings.error;
        }
    };

    return (
        <SeverityPill
            color={getVehicleStatusBadgeColor().color as SeverityPillColor}
            customColor={getVehicleStatusBadgeColor().customColor ? getVehicleStatusBadgeColor().customColor : undefined}
            backgroundColor={getVehicleStatusBadgeColor().backgroundColor}
            sx={{textTransform: 'none'}}>
            <Typography variant="caption" component="span">
                <Translation text={`vehicles.status.${statusOverride}`} />
            </Typography>
        </SeverityPill>
    );
};

export default VehicleStatusBadge;
