import {Box, Card, Divider, Grid, Stack, Typography} from '@mui/material';
import Chart from '../Chart';
import React from 'react';
import {Loader, LoaderType, Translation} from 'palipali-panel-common-web';
import {useChartOptions} from '../Chart/useChartOptions';
import {useSelector} from 'react-redux';
import {reportsLoadingSelector} from '../../../../store/selectors/reportsSelectors';
type ApexMarkerShape = 'circle' | 'square' | 'rect' | string[];

interface IGrapCardProps {
    title: string;
    chartSeries: ApexAxisChartSeries;
    customYFormatter?: (value: number) => string | string[];
    customTooltipFormatter?: (value: number) => string;
}
const GraphCard: React.FC<IGrapCardProps> = ({title, chartSeries, customYFormatter, customTooltipFormatter}) => {
    const chartOptions = useChartOptions(customYFormatter);
    const isLoading = useSelector(reportsLoadingSelector);
    const tickNumber = chartSeries?.[0]?.data?.length;

    if (chartOptions) {
        const markerOptions = {
            size: 3,
            colors: undefined,
            strokeColors: '#fff',
            strokeWidth: 1,
            strokeOpacity: 0.9,
            fillOpacity: 1,
            discrete: [],
            shape: 'circle' as ApexMarkerShape,
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: false,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        };
        const tooltipOptions = {
            shared: false,
            y: {
                tickAmount: undefined,
                formatter: customTooltipFormatter ? customTooltipFormatter : (val: number): string => val.toString(),
                title: {
                    formatter: () => '',
                },
            },
        };
        if (chartOptions.yaxis) {
            const seriesArray = chartSeries[0].data as {x: string; y: number}[];
            const areAnyValuesPositive = seriesArray ? seriesArray.some((data: {x: string; y: number}) => data?.y > 0) : false;
            const yAxisOptions = chartOptions.yaxis as ApexYAxis;
            const maxYValue = Math.max(...seriesArray.map((data: {x: string; y: number}) => data?.y));
            if (!areAnyValuesPositive) {
                yAxisOptions.min = 0;
                yAxisOptions.max = 1;
                yAxisOptions.tickAmount = 1;
                if (yAxisOptions.labels) {
                    yAxisOptions.labels.formatter = (val, opts) =>
                        val === 0 && opts === 0 && customYFormatter ? customYFormatter(val) : '';
                    yAxisOptions.labels.minWidth = 20;
                }
            } else {
                yAxisOptions.max = maxYValue;
                yAxisOptions.tickAmount = maxYValue > 5 ? 3 : maxYValue;
            }
            chartOptions.yaxis = yAxisOptions;
            chartOptions.yaxis.showForNullSeries = false;
        }
        if (chartOptions.xaxis && chartOptions.xaxis.labels && chartOptions.xaxis.axisTicks) {
            chartOptions.xaxis.tickPlacement = 'between';
            chartOptions.xaxis.axisTicks.show = false;
            chartOptions.xaxis.labels.showDuplicates = false;
            chartOptions.xaxis.labels.offsetX = 0;
            chartOptions.xaxis.labels.offsetY = 0;
            if (tickNumber && tickNumber <= 6) {
                chartOptions.xaxis.tickAmount = tickNumber;
                chartOptions.xaxis.type = 'category';
            }
        }
        chartOptions.tooltip = tooltipOptions;
        chartOptions.markers = markerOptions;
    }
    return (
        <Grid item xs={12} md={6} lg={6} xl={3}>
            <Card className="custom-card" sx={{paddingLeft: '0.5rem', paddingRight: '0.8rem'}}>
                <Loader showLoader={isLoading} type={LoaderType.Local} />
                <Stack divider={<Divider />} className="reports-chart-wrapper">
                    <Box my={2} mx={4}>
                        <Typography noWrap className={'h6 chart-title'} color={'textPrimary'}>
                            <Translation text={title} />
                        </Typography>
                    </Box>
                    {!chartSeries?.[0]?.data || chartSeries?.[0]?.data?.length === 0 ? (
                        <Box display={'flex'} alignItems={'center'} flexDirection={'column'} justifyContent={'center'} height={140}>
                            <Typography variant={'caption'} fontWeight={400} letterSpacing={0.4} color={'textPrimary'}>
                                <Translation text={'reports.reportsList.noDataInfo'} />
                            </Typography>
                        </Box>
                    ) : (
                        <Chart height={140} options={chartOptions} series={chartSeries} type="area" class="apexcharts-custom-reports" />
                    )}
                </Stack>
            </Card>
        </Grid>
    );
};

export default GraphCard;
