import {Button, CardActions, CardContent, Divider, Grid, useTheme} from '@mui/material';
import {
    FormikForm,
    ICreateVehicleInput,
    IFileUploadListElement,
    IInspectionInput,
    IInsuranceInput,
    Translation,
    ChevronRight,
    ChevronLeftIcon,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    vehicleBasicInformationSelector,
    vehicleInspectionSelector,
    vehicleInsuranceSelector,
} from 'src/store/selectors/vehicleOperationSelector';
import {VehicleOperationStep, createVehicle, setCurrentStep} from '../../../../store/reducers/vehicleOperationSlice';
import {createVehicleInsuranceFormConfig} from './createVehicleInsuranceFormConfig';

interface IInsuranceValues {
    number: string;
    until: string;
    files: IFileUploadListElement | undefined;
}

const Insurance: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const vehicleBasicInformation = useSelector(vehicleBasicInformationSelector),
        vehicleInspection = useSelector(vehicleInspectionSelector),
        vehicleInsurance = useSelector(vehicleInsuranceSelector),
        [submitAllowed, setSubmitAllowed] = React.useState<boolean>(false);

    const initialValues: IInsuranceValues = {
        number: vehicleInsurance?.number || '',
        until: vehicleInsurance?.until || '',
        files: vehicleInsurance?.files[0] || undefined,
    };

    const submitStep = (values: IInsuranceValues) => {
        if (!values.files || !values.number || !values.until) return;
        const fileString = values.files.fileKey.toString();
        const filesString: string[] = [];
        filesString.push(fileString);
        const insurancePayload: IInsuranceInput = {
            number: values.number,
            until: new Date(values.until).toISOString(),
            files: filesString,
        };
        const inspectionPayload: IInspectionInput = {
            until: vehicleInspection.until,
            files: vehicleInspection.files.map((file: IFileUploadListElement) => file.fileKey),
        };

        const vehicleInformation = {...vehicleBasicInformation};
        delete vehicleInformation.vehicleType;

        const vehicle: ICreateVehicleInput = {
            ...vehicleInformation,
            inspection: inspectionPayload,
            insurance: insurancePayload,
        };
        dispatch(createVehicle(vehicle));
    };

    return (
        <React.Fragment key={'insurance-wrapper-react-fragment'}>
            <CardContent>
                <Grid item xs={12} md={7}>
                    <FormikForm
                        fields={createVehicleInsuranceFormConfig()}
                        formId="insurance-form"
                        initialValues={initialValues}
                        theme={theme}
                        submitAllowed={setSubmitAllowed}
                        onSubmit={submitStep}
                    />
                </Grid>
            </CardContent>
            <Divider />
            <CardActions className="card-actions-button-wrapper">
                <Button
                    size="medium"
                    className="btn btn-action vehicle-nav-prev-button"
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => dispatch(setCurrentStep(VehicleOperationStep.INSPECTION))}>
                    <Translation text="vehicles.vehicleOperation.buttons.back" />
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    size="medium"
                    type="submit"
                    disabled={!submitAllowed}
                    form="insurance-form"
                    className="vehicle-nav-next-button"
                    endIcon={<ChevronRight />}>
                    <Translation text="vehicles.vehicleOperation.buttons.create" />
                </Button>
            </CardActions>
        </React.Fragment>
    );
};

export default Insurance;
