import {PayloadAction} from '@reduxjs/toolkit';
import {
    createBaseReducerSlice,
    IBaseReducerState,
    IModelApiResponseViewObject,
    initApiCall,
    PAGINATION_ITEMS_PER_PAGE,
    IVehicleListingOutput,
} from 'palipali-panel-common-web';

export enum VehicleListingFilter {
    ALL = 'all',
    VERIFIED = 'verified',
    UNVERIFIED = 'unverified',
    PENDING = 'pending',
}

export interface IVehicleListingState extends IBaseReducerState {
    vehicleList: IVehicleListingOutput[] | null;
    pagination: IPaginationParams;
    verified: VehicleListingFilter;
    search: string | null;
    metadata: IModelApiResponseViewObject | null;
}

export interface ISetVehicleListing {
    vehicleList: IVehicleListingOutput[] | null;
}

const initialState: IVehicleListingState = {
    vehicleList: null,
    pagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    verified: VehicleListingFilter.ALL,
    search: null,
    metadata: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

export interface IPaginationParams {
    itemsPerPage: number;
    page: number;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

export interface IChangeVehicleListFilters {
    verified: VehicleListingFilter;
}

export interface IChangeVehicleListSearchPhrase {
    search: string | null;
}

const vehicleListingSlice = createBaseReducerSlice({
    name: 'vehicleListing',
    initialState: initialState,
    reducers: {
        setVehicleListing: (state: IVehicleListingState, action: PayloadAction<ISetVehicleListing>) => {
            state.vehicleList = action.payload.vehicleList;
        },
        setMetadata: (state: IVehicleListingState, action: PayloadAction<ISetMetadata>) => {
            state.metadata = action.payload.metadata;
        },
        changeVehicleListingPagination: (state: IVehicleListingState, action: PayloadAction<IPaginationParams>) => {
            state.pagination = action.payload;
            state.isLoading = true;
        },
        changeVehicleListingVerifiedFilter: (state: IVehicleListingState, action: PayloadAction<IChangeVehicleListFilters>) => {
            state.verified = action.payload.verified;
            state.isLoading = true;
        },
        changeVehicleListingSearchPhrase: (state: IVehicleListingState, action: PayloadAction<IChangeVehicleListSearchPhrase>) => {
            state.search = action.payload.search;
            state.isLoading = true;
        },
        getVehicleListing: (state: IVehicleListingState) => initApiCall(state),
    },
});

export const {
    setVehicleListing,
    setLoading,
    setMetadata,
    changeVehicleListingPagination,
    setError,
    setInitialized,
    getVehicleListing,
    changeVehicleListingVerifiedFilter,
    changeVehicleListingSearchPhrase,
} = vehicleListingSlice.actions;
export default vehicleListingSlice.reducer;
