import React from 'react';
import {ISelectProps} from '../../../types';

type Props = ISelectProps;

class Select extends React.Component<Props> {
    private get options() {
        const options = this.props.options;

        return typeof options === 'function' ? options() : options;
    }

    render() {
        return (
            <select
                className={`${this.props.inputStyles}`}
                value={this.props.value}
                onChange={this.props.handleChange}
                name={this.props.name}
                disabled={this.props.disabled}>
                {this.props.firstOptionValue && (
                    <option hidden disabled value="">
                        {this.props.firstOptionValue}
                    </option>
                )}

                {this.options.map((option: any, index: number) => {
                    return (
                        <option value={option.value} key={index} disabled={true === option.isDisabled}>
                            {option.displayValue}
                        </option>
                    );
                })}
            </select>
        );
    }
}

export default Select;
