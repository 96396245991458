import {Stack, Switch, Typography, FormControlLabel} from '@mui/material';
import React from 'react';
import {FormikFieldConfig} from '../../../components/FormikForm';
import Translation from '../../../components/Translation';

const SwitchFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    formik,
    sublabel,
    className,
    switchLabelPlacement,
    customEventChange,
}) => {
    const handleChange = (event) => {
        if (customEventChange) {
            customEventChange(name, event.target.checked);
        }
    };

    return (
        <FormControlLabel
            value="start"
            control={<Switch color="primary" checked={formik.values[name]} onChange={handleChange} />}
            className={`switch-form-control-wrapper ${className ? className : ''}`}
            label={
                <Stack>
                    <Typography variant="subtitle1">
                        <Translation text={label} />
                    </Typography>
                    {sublabel && (
                        <Typography variant="body1" color="textSecondary">
                            <Translation text={sublabel} />
                        </Typography>
                    )}
                </Stack>
            }
            labelPlacement={switchLabelPlacement ? switchLabelPlacement : 'start'}
        />
    );
};

export default SwitchFormControl;
