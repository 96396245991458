import {isEmpty, isNotEmpty, isNotNullOrUndefined} from '../utils/runtimeUtils';
import {IFileOutput, ILocaleString, IPhoneInput, IPhoneOutput} from './common';
import {IVehicleDetailsOutput} from './vehicle';
import {VerifiableStatusEnum} from './enums';
import {Locale} from '../types';

export const DEFAULT_LOCALE: Locale = Locale.LOCALE_PL;

export type IAccountMeOutput = {
    readonly account: IAccountBasicInfoOutput;
    readonly completedAccounts: ICompletedAccounts;
};

export type ICompletedFleetPartnerAccount = {
    readonly active: boolean;
    readonly onboarding: boolean;
    readonly settlement: boolean;
    readonly id: string;
    readonly stripeOnboardingCompleted: boolean;
};

export type ICompletedCourierAccount = {
    readonly active: boolean;
    readonly profile: VerifiableStatusEnum;
    readonly cooperation: boolean;
    readonly vehicle: VerifiableStatusEnum;
};

export type ICompletedAccounts = {
    readonly fleetPartner: null | ICompletedFleetPartnerAccount;
    readonly courier: null | ICompletedCourierAccount;
};

export type IAccountBasicInfoOutput = {
    readonly id: string;
    readonly userId: string;
    readonly username: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly phone: IPhoneOutput | null;
    readonly avatar: IFileOutput | null;
    readonly locale: Locale;
    readonly misc: IMiscOutput;
    readonly courier: ICourierBasicOutput | null;
    readonly cityId: string | null;
};

export type IMiscOutput = {
    readonly theme: string | null;
};

export type ICourierBasicOutput = {
    readonly id: string;
    readonly fleet: boolean;
    readonly online: boolean;
    readonly averageRating: number | null;
    readonly activeVehicle: IVehicleDetailsOutput | null;
};

export type IAccountPublicOutput = {
    readonly id: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly avatar: File | null;
    readonly phone: IPhoneOutput | null;
};

export const getDisplayName = (
    account?: IAccountBasicInfoOutput,
    defaultValue: string | null = null,
    firstNameOnly: boolean = false
): string | null => {
    if (isNotNullOrUndefined(account?.firstName)) {
        return `${account.firstName}${!firstNameOnly && isNotEmpty(account.lastName) ? ` ${account.lastName}` : ''}`;
    }

    return defaultValue;
};

export const getUserInitials = (account?: IAccountBasicInfoOutput, defaultValue: string | null = 'XX') => {
    if (isEmpty(account?.firstName) || isEmpty(account?.lastName)) {
        return defaultValue;
    }

    return `${account.firstName.charAt(0)} ${account.lastName.charAt(0)}`.toUpperCase();
};

export type IAccountLocaleInput = {
    locale: ILocaleString;
};

export type IAccountAvatarInput = {
    mediaObjectId: string;
};

export type IAccountMiscInput = {
    misc: any;
};
export interface IAccountProfileInput {
    username: string;
    firstName: string;
    lastName: string;
    phone: IPhoneInput;
    cityId: string;
    returnUrl: string;
}

export interface IAvatarPayload {
    mediaObjectId: string;
}
