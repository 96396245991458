import {
    FormikFieldConfig,
    FormikFieldTypes,
    IMultiselectOption,
    IVehicleDefinitionOutput,
    ValidationErrorType,
    convertStringArrayToMultiselectLabels,
    sortMultiselectLabels,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {IBaseInformationValues} from '.';

const basicInformationFormConfig = (
    getData: (path?: string, getKeyValue?: boolean) => string[] | IVehicleDefinitionOutput,
    colors: IMultiselectOption[]
): FormikFieldConfig[] => {
    const {t} = useTranslation();
    return [
        {
            name: 'registrationNumber',
            label: t('vehicles.vehicleOperation.basicInformation.form.registrationNumber.label'),
            validation: Yup.string()
                .max(255)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            type: FormikFieldTypes.TEXT,
        },
        {
            name: 'make',
            label: t('vehicles.vehicleOperation.basicInformation.form.make.label'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            type: FormikFieldTypes.SELECT,
            customOptions: () => {
                const makeArray = getData();
                return sortMultiselectLabels(convertStringArrayToMultiselectLabels(makeArray as string[]));
            },
            customHandleChange: (make, setFieldValue) => {
                setFieldValue('make', make);
                setFieldValue('model', '');
                setFieldValue('vehicleType', '');
                setFieldValue('productionYear', '');
                setFieldValue('vehicleDefinitionId', '');
            },
        },
        {
            name: 'model',
            label: `${t('vehicles.vehicleOperation.basicInformation.form.model.label')}`,
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            type: FormikFieldTypes.SELECT,
            customDisabled: (values: IBaseInformationValues) => {
                return !values.make;
            },
            customOptions: (values: IBaseInformationValues) => {
                if (values.make) {
                    const modelArray = getData(values.make);
                    return sortMultiselectLabels(convertStringArrayToMultiselectLabels(modelArray as string[]));
                } else {
                    return [];
                }
            },
            customHandleChange: (model, setFieldValue) => {
                setFieldValue('model', model);
                setFieldValue('vehicleType', '');
                setFieldValue('productionYear', '');
                setFieldValue('vehicleDefinitionId', '');
            },
        },
        {
            name: 'vehicleType',
            label: t('vehicles.vehicleOperation.basicInformation.form.vehicleTypeId.label'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            type: FormikFieldTypes.SELECT,
            customDisabled: (values: IBaseInformationValues) => {
                return !values.make || !values.model;
            },
            customOptions: (values: IBaseInformationValues) => {
                if (values.make && values.model) {
                    const vehicleTypeArray = getData(`${values.make}.${values.model}`);
                    return sortMultiselectLabels(convertStringArrayToMultiselectLabels(vehicleTypeArray as string[]));
                } else {
                    return [];
                }
            },
            customHandleChange: (type, setFieldValue) => {
                setFieldValue('vehicleType', type);
                setFieldValue('productionYear', '');
                setFieldValue('vehicleDefinitionId', '');
            },
        },
        {
            name: 'productionYear',
            label: t('vehicles.vehicleOperation.basicInformation.form.productionYear.label'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            type: FormikFieldTypes.SELECT,
            customDisabled: (values: IBaseInformationValues) => {
                return !values.make || !values.model || !values.vehicleType;
            },
            customOptions: (values: IBaseInformationValues) => {
                if (values.make && values.model && values.vehicleType) {
                    const productionYearArray = getData(`${values.make}.${values.model}.${values.vehicleType}`);
                    return convertStringArrayToMultiselectLabels(productionYearArray as string[]);
                } else {
                    return [];
                }
            },
            customHandleChange: (year, setFieldValue, formValues: IBaseInformationValues) => {
                setFieldValue('productionYear', year);
                const finalValue = getData(
                    `${formValues.make}.${formValues.model}.${formValues.vehicleType}.${year}`,
                    true
                ) as IVehicleDefinitionOutput;
                setFieldValue('vehicleDefinitionId', finalValue.id);
                setFieldValue('vehicleTypeId', finalValue.vehicleType.id);
                setFieldValue('s', finalValue.packageSizes.s);
                setFieldValue('m', finalValue.packageSizes.m);
                setFieldValue('l', finalValue.packageSizes.l);
            },
        },
        {
            name: 'colorId',
            label: t('vehicles.vehicleOperation.basicInformation.form.colorId.label'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            type: FormikFieldTypes.SELECT,
            options: colors,
        },
        {
            name: 'packageSizes',
            label: t('vehicles.vehicleOperation.basicInformation.form.packageSizes.label'),
            validation: null,
            type: FormikFieldTypes.PACKAGE_SIZE,
            customDisabled() {
                return true;
            },
        },
    ];
};
export default basicInformationFormConfig;
