import {Stack, Typography} from '@mui/material';
import {IVehicleListingOutput} from 'palipali-panel-common-web';
import React from 'react';

interface IVehicleDetailsProps {
    vehicle: IVehicleListingOutput;
}
const VehicleDetails: React.FC<IVehicleDetailsProps> = (props: IVehicleDetailsProps) => {
    const vehicleBrand = props.vehicle.make ? props.vehicle.make : '-',
        vehicleModel = props.vehicle.model ? props.vehicle.model : '',
        vehicleYear = props.vehicle.productionYear ? props.vehicle.productionYear : '-',
        vehicleColor = props.vehicle.color?.name ? props.vehicle.color.name : '-';
    return (
        <Stack>
            <Typography variant="subtitle2" component="p" color="text.primary">
                {`${vehicleBrand} ${vehicleModel}`}
            </Typography>
            <Typography variant="caption" component="p" color="text.secondary">
                {`${vehicleYear} | ${vehicleColor}`}
            </Typography>
        </Stack>
    );
};
export default VehicleDetails;
