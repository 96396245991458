import type {Theme} from '@mui/material';
import {useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import type {FC, ReactNode} from 'react';
import React from 'react';
import {MobileNavHost} from '../MobileNavHost';
import {SideNavHost} from '../SideNavHost';
import {useMobileNav} from './use-mobile-nav';
import {NavColor, Section} from '../../types/themeTypes';
import LogoutModal from '../../../components/AuthPanel/LogoutModal';
import {UserRole} from '../../../types';
import {TopNav} from '../TopNav';
import {MemberOperationType} from '../../../store/reducers/modalSlice';
import {useSelector} from 'react-redux';
import {currentlySelectedModalTypeSelector, isModalOpenSelector} from '../../../store/selectors/modalSelectors';

const SIDE_NAV_WIDTH = 280;

const VerticalLayoutRoot = styled('div')(({theme}) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
        paddingLeft: SIDE_NAV_WIDTH,
    },
}));

const VerticalLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
});

interface IVerticalLayoutProps {
    children?: ReactNode;
    navColor?: NavColor;
    sections?: Section[];
    subMenuSections?: Section[];
    theme: Theme;
    userRole: UserRole;
    userName: string | null;
}

export const VerticalLayout: FC<IVerticalLayoutProps> = (props: IVerticalLayoutProps) => {
    const isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector);
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const mobileNav = useMobileNav();

    const renderNav = () => {
        if (mdUp) {
            return (
                <SideNavHost
                    color={props.navColor}
                    sections={props.sections}
                    subMenuSections={props.subMenuSections}
                    userRole={props.userRole}
                    userName={props.userName}
                />
            );
        }

        return (
            <>
                <TopNav onMobileNavOpen={mobileNav.handleOpen} userRole={props.userRole} />
                <MobileNavHost
                    color={props.navColor}
                    onClose={mobileNav.handleClose}
                    open={mobileNav.open}
                    sections={props.sections}
                    subMenuSections={props.subMenuSections}
                    userRole={props.userRole}
                />
            </>
        );
    };

    return (
        <>
            {renderNav()}
            <VerticalLayoutRoot>
                <VerticalLayoutContainer>{props.children}</VerticalLayoutContainer>
            </VerticalLayoutRoot>
            {isModalOpen && modalType === MemberOperationType.LOGOUT && <LogoutModal theme={props.theme} />}
        </>
    );
};

VerticalLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident']),
    sections: PropTypes.array,
};
