import {Navigate} from 'react-router-dom';
import {isAuthenticatedSelector, UserRole, withRouterWrapper, IWithRouterWrapperProps} from '../..';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {initApp} from '../../store/reducers/authSlice';

type Props = {
    isAuthenticated: boolean;
    children: any;
    initAuthTokenChange: typeof initApp;
    userRole: UserRole;
    authUrl?: string;
    [key: string]: any;
} & IWithRouterWrapperProps;

const PrivateRoute = ({isAuthenticated, authUrl = '/auth/login', children, initAuthTokenChange, userRole, router}: Props) => {
    useEffect(() => {
        if (!isProductionMode()) {
            if (router && router.location && router.location.search) {
                const params = new URLSearchParams(location.search);
                if (params.has('token')) {
                    setAuthToken(params.get('token') as string);
                }
            }
        }
    }, []);

    const setAuthToken = (token: string | null) => {
        if (!token) {
            return;
        }

        initAuthTokenChange(token, userRole);
    };

    const isProductionMode = (): boolean => {
        return process.env.REACT_APP_ENV === 'prod';
    };

    if (!isAuthenticated) {
        return <Navigate replace to={authUrl} />;
    }

    return children;
};

export default connect(
    (state: any) => ({
        isAuthenticated: isAuthenticatedSelector(state),
    }),
    {initApp}
)(withRouterWrapper(PrivateRoute));
