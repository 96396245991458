import type {PaletteColor} from '@mui/material/styles/createPalette';
import type {ColorPreset} from './index';
import {blue, courier, fleetPartner, green, indigo, purchaser, purple} from './colors';

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
    switch (preset) {
        case 'blue':
            return blue;
        case 'green':
            return green;
        case 'indigo':
            return indigo;
        case 'purple':
            return purple;
        case 'fleetPartner':
            return fleetPartner;
        case 'purchaser':
            return purchaser;
        case 'courier':
            return courier;
        default:
            console.error(
                'Invalid color preset, accepted values: "blue", "green", "indigo", "fleetPartner", "purchaser", "courier" or "purple"".'
            );
            return blue;
    }
};
