import React, {ReactElement} from 'react';
import Translation from '../../Translation';
import JobPrice from '../../JobPrice';
import PhoneComponent from '../../PhoneComponent';

interface IJobViewDetailsListItemProps {
    itemClass?: string;
    label: string;
    labelClass?: string;
    value: ReactElement<typeof JobPrice> | ReactElement<typeof PhoneComponent> | string | null | undefined;
    valueClass?: string;
}

const JobViewDetailsListItem: React.FC<IJobViewDetailsListItemProps> = (props: IJobViewDetailsListItemProps) => {
    const itemClasses: string = props.itemClass ? ` ${props.itemClass}` : ``,
        labelClasses: string = props.labelClass ? ` ${props.labelClass}` : ``,
        valueClasses: string = props.valueClass ? ` ${props.valueClass}` : ``,
        value: ReactElement | string = props.value ? props.value : '-';

    return (
        <div className={`job-details-list-item${itemClasses}`}>
            <dt className={`item-label${labelClasses}`}>
                <Translation text={props.label} />
            </dt>
            <dd className={`item-value${valueClasses}`}>{value}</dd>
        </div>
    );
};

export default JobViewDetailsListItem;
