import {Observable} from 'rxjs';
import {ITeamViewOutput, IUpdateTeamInput} from '../../model/team';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const updateTeamAPI: ApiOperationResponseFunction<ITeamViewOutput> = (
    authToken: string,
    payload: {team: IUpdateTeamInput; id: string}
): Observable<IApiSingleResponseBase<ITeamViewOutput>> => {
    const updatePayload: IUpdateTeamInput = payload.team,
        id: string = payload.id;
    return palipaliAPI.put(`api/teams/${id}`, updatePayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
