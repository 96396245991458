import {Box, FormHelperText, Checkbox, Typography, FormControlLabel} from '@mui/material';
import React from 'react';
import {FormikFieldConfig} from '../../../components/FormikForm';
import Translation from '../../../components/Translation';
import {Trans} from 'react-i18next';

const CheckboxFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    formik,
    className,
    customEventChange,
    isRequired,
    isCustomLabelTranslation,
    translationConfigValues,
    translationComponents,
}) => {
    const handleChange = (event) => {
        formik.setFieldValue(name, event.target.checked);

        if (customEventChange) {
            customEventChange(name, event.target.checked);
        }
    };

    return (
        <Box className="checkbox-control">
            <FormControlLabel
                control={
                    <>
                        <Checkbox required={isRequired} onChange={handleChange} checked={formik.values[name]} color="primary" />
                    </>
                }
                className={`checkbox-form-control-wrapper ${className ? className : ''}`}
                label={
                    <Typography>
                        {isCustomLabelTranslation ? (
                            <Trans i18nKey={label} values={translationConfigValues} components={translationComponents} />
                        ) : (
                            <Translation text={label} />
                        )}

                        {isRequired && (
                            <Typography component="span" color={'error'}>
                                *
                            </Typography>
                        )}
                    </Typography>
                }
            />
            {formik.errors[name] ? <FormHelperText className={'error'}>{formik.errors[name]}</FormHelperText> : null}
        </Box>
    );
};

export default CheckboxFormControl;
