import React from 'react';
import {CustomIconProps} from '../../../types/iconTypes';

const MagnifyingGlassIcon = (props: CustomIconProps) => (
    <svg width={props.size ? props.size : 16} height={props.size ? props.size : 16} viewBox="0 0 16 16" fill="none" {...props}>
        <g id="search-md">
            <path
                id="Icon"
                d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
                stroke="#6C737F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default MagnifyingGlassIcon;
