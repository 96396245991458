import {Container} from 'inversify';
import getDecorators, {fixInjectedProperties} from './decorators';
import FormStateEmitter from '../service/formStateEmitter';

const container = new Container();

container.bind<FormStateEmitter>('FormStateEmitter').to(FormStateEmitter).inSingletonScope();

const {lazyInject} = getDecorators(container);

export {container, lazyInject, fixInjectedProperties};
