import {createSelector} from '@reduxjs/toolkit';
import {IConfirmInvitationState} from '../reducers/confirmInvitationSlice';

export const selectAuth = (state: any): IConfirmInvitationState | null => {
    return state.confirmInvitation;
};

export const fleetPartnerInvitationConfirmActionStatusSelector = createSelector([selectAuth], (state?: IConfirmInvitationState) =>
    state ? state.fleetPartnerInvitationConfirmActionStatus : null
);

export const purchaserInvitationConfirmActionStatusSelector = createSelector([selectAuth], (state?: IConfirmInvitationState) =>
    state ? state.purchaserInvitationConfirmActionStatus : null
);
