export const timerTimeWithMinutes = (timeInSeconds?: number | null, timeNegative?: boolean) => {
    if (!timeInSeconds) {
        return '0:00';
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds ? timeInSeconds % 60 : 0;

    return `${timeNegative ? '-' : ''}${minutes}:${seconds < 10 && seconds > -10 ? `0${Math.abs(seconds)}` : Math.abs(seconds)}`;
};

export const timeInMinutes = (timeInSeconds?: number | null, timeNegative?: boolean) => {
    if (!timeInSeconds) {
        return 0;
    }
    return timeNegative ? -Math.floor(timeInSeconds / 60) : Math.ceil(timeInSeconds / 60);
};
