import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiResponseBase, IPurchaserJobListingOutput, IRawRestQueryParams} from '../../types';

export function getJobsListAPI(
    authToken: string,
    payload?: any,
    params?: IRawRestQueryParams | []
): Observable<IApiResponseBase<IPurchaserJobListingOutput[]>> {
    return palipaliAPI.get(`api/jobs`, new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
    });
}
