import type {SVGProps} from 'react';
import React from 'react';

const LocationsIcon02 = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path
            d="M4.275 9.79329C3.02533 10.3446 2.25 11.1128 2.25 11.9625C2.25 13.6401 5.27208 15 9 15C12.7279 15 15.75 13.6401 15.75 11.9625C15.75 11.1128 14.9747 10.3446 13.725 9.79329M13.05 5.55C13.05 8.293 10.0125 9.6 9 11.625C7.9875 9.6 4.95 8.293 4.95 5.55C4.95 3.31325 6.76325 1.5 9 1.5C11.2368 1.5 13.05 3.31325 13.05 5.55ZM9.675 5.55C9.675 5.92279 9.37279 6.225 9 6.225C8.62721 6.225 8.325 5.92279 8.325 5.55C8.325 5.17721 8.62721 4.875 9 4.875C9.37279 4.875 9.675 5.17721 9.675 5.55Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default LocationsIcon02;
