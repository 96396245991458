import {
    addAlert,
    IApiResponseBase,
    updateAssignVehiclesToCourierAPI,
    authTokenSelector,
    ICanOutput,
    CourierStatus,
    getCanUnassignCourierAPI,
    closeModal,
    ICourierAssignVehiclesProcessOutput,
    createFetchDetailsEpic,
    createFetchListEpic,
    createOperationEpic,
    DetailsSuccessActionsFunction,
    flattenObj,
    getAssignedCouriersListAPI,
    getFleetPartnerCouriersListAPI,
    getVehicleMineListAPI,
    handleApiError,
    IFleetPartnerCourierListingOutput,
    IFleetPartnerCourierOutput,
    IModelApiResponseViewObject,
    updateInviteCourierAPI,
    IRawRestQueryParams,
    ListSuccessActionsFunction,
    createUnassignCourierAPI,
    IVehicleListingOutput,
    AlertType,
    Action,
} from 'palipali-panel-common-web';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {RootState} from '../reducers';
import {
    assignVehiclesToCourier,
    canUnassignCourier,
    changeCourierListingCityFilter,
    changeCourierListingPagination,
    changeCourierSearchFilter,
    changeCourierVerifiedFilter,
    changeCourierVehiclesListPagination,
    CourierFilterStatus,
    fetchCourierAssignedVehicleIds,
    fetchCouriersList,
    fetchCourierVehiclesList,
    inviteCourier,
    setCanBeUnassigned,
    setCourierAssignedVehicles,
    setCouriersList,
    setError,
    setLoading,
    setMetadata,
    setCourierVehiclesList,
    setCourierVehiclesListMetadata,
    unassignCourier,
    setCourierVehicleListLoading,
} from '../reducers/courierListingSlice';
import {
    courierListingCitySelector,
    courierListingFilterVerifiedSelector,
    courierListingPaginationSelector,
    courierListingSearchSelector,
    courierVehicleListPaginationSelector,
    courierVehicleListSelector,
} from '../selectors/courierListingSelector';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {EMPTY, forkJoin, of} from 'rxjs';

const basicOperationSuccessActions = (successMessage: string, alertType?: AlertType) => [
    setLoading(false),
    addAlert({message: successMessage, type: alertType || AlertType.SUCCESS}),
    fetchCouriersList(),
];
const courierVehicleListErrorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [closeModal(), setCourierVehicleListLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [closeModal(), setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const getParameters = (state: RootState): IRawRestQueryParams => {
    const pagination = courierListingPaginationSelector(state),
        search = courierListingSearchSelector(state),
        filter = courierListingFilterVerifiedSelector(state),
        city = courierListingCitySelector(state),
        cityParam = city ? {[`courier.account.city`]: city} : null,
        filterParam = filter && filter !== CourierFilterStatus.ALL ? {verified: filter} : null,
        searchParam = search ? {['courier.account.joinedName']: search} : null;
    const params = {
        ...pagination,
        ...searchParam,
        ...cityParam,
        ...filterParam,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const getVehicleListParameters = (state: RootState): IRawRestQueryParams => {
    const pagination = courierVehicleListPaginationSelector(state);

    const params = {
        ...pagination,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const courierListingSuccessActions: ListSuccessActionsFunction<IFleetPartnerCourierListingOutput> = (
    courierArray: IFleetPartnerCourierListingOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setCouriersList({courierList: courierArray}), setMetadata({metadata: metadata}), setLoading(false)];
};

const courierVehiclesListingSuccessActions: ListSuccessActionsFunction<IVehicleListingOutput> = (
    vehiclesArray: IVehicleListingOutput[] | null,
    metadata: IModelApiResponseViewObject | null
) => {
    return [
        setCourierVehiclesList({courierVehiclesList: vehiclesArray}),
        setCourierVehiclesListMetadata({metadata: metadata}),
        setCourierVehicleListLoading(false),
    ];
};

const courierInviteSuccessActions: DetailsSuccessActionsFunction<IFleetPartnerCourierOutput> = (response: IFleetPartnerCourierOutput) => {
    const isCourierDeleted = response?.status === CourierStatus.DELETED,
        alertMessage = isCourierDeleted
            ? 'couriers.inviteCourier.alerts.unsuccessfulInvitation'
            : 'couriers.inviteCourier.alerts.successInvitation',
        alertType = isCourierDeleted ? AlertType.INFO : AlertType.SUCCESS;

    return [closeModal(), ...basicOperationSuccessActions(alertMessage, alertType)];
};

const courierUnassignSuccessActions: DetailsSuccessActionsFunction<IFleetPartnerCourierOutput> = () => {
    return [closeModal(), ...basicOperationSuccessActions('couriers.inviteCourier.alerts.successUnassignment')];
};

const canBeUnassignedSuccessActions: DetailsSuccessActionsFunction<ICanOutput> = (canBeUnassigned: ICanOutput) => {
    return [setCanBeUnassigned(canBeUnassigned.can)];
};

const assignVehiclesToCourierSuccessActions: DetailsSuccessActionsFunction<ICourierAssignVehiclesProcessOutput> = (
    response: ICourierAssignVehiclesProcessOutput
) => {
    const hasFailedVehicleIds = response?.failedVehicleIds?.length > 0,
        alertMessage = hasFailedVehicleIds ? 'couriers.assignVehicle.vehiclesAssignedError' : 'couriers.assignVehicle.vehiclesAssigned',
        alertType = hasFailedVehicleIds ? AlertType.INFO : AlertType.SUCCESS;

    return [closeModal(), setLoading(false), addAlert({message: alertMessage, type: alertType}), fetchCouriersList()];
};

const canBeUnassignedEpic = createFetchDetailsEpic<ICanOutput>(
    getCanUnassignCourierAPI,
    canBeUnassignedSuccessActions,
    errorActions,
    canUnassignCourier().type
);

const inviteCourierEpic = createOperationEpic<IFleetPartnerCourierOutput>(
    updateInviteCourierAPI,
    courierInviteSuccessActions,
    errorActions,
    inviteCourier().type
);

const unassignCourierEpic = createOperationEpic<IFleetPartnerCourierOutput>(
    createUnassignCourierAPI,
    courierUnassignSuccessActions,
    errorActions,
    unassignCourier().type
);

const fetchCouriersListEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getFleetPartnerCouriersListAPI,
    courierListingSuccessActions,
    errorActions,
    fetchCouriersList().type,
    (state: RootState) => getParameters(state)
);

const changePaginationEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getFleetPartnerCouriersListAPI,
    courierListingSuccessActions,
    errorActions,
    changeCourierListingPagination().type,
    (state: RootState) => getParameters(state)
);

const changeVerifiedFilterEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getFleetPartnerCouriersListAPI,
    courierListingSuccessActions,
    errorActions,
    changeCourierVerifiedFilter().type,
    (state: RootState) => getParameters(state)
);

const changeSearchFilterEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getFleetPartnerCouriersListAPI,
    courierListingSuccessActions,
    errorActions,
    changeCourierSearchFilter().type,
    (state: RootState) => getParameters(state)
);

const changeCityFilterEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getFleetPartnerCouriersListAPI,
    courierListingSuccessActions,
    errorActions,
    changeCourierListingCityFilter().type,
    (state: RootState) => getParameters(state)
);

const fetchCourierVehiclesListEpic = createFetchListEpic<IVehicleListingOutput>(
    getVehicleMineListAPI,
    courierVehiclesListingSuccessActions,
    courierVehicleListErrorActions,
    fetchCourierVehiclesList().type,
    (state: RootState) => getVehicleListParameters(state),
    (action: Action) => {
        action.payload.courierId;
    }
);

const changeVehiclesPaginationEpic = createFetchListEpic<IVehicleListingOutput>(
    getVehicleMineListAPI,
    courierVehiclesListingSuccessActions,
    courierVehicleListErrorActions,
    changeCourierVehiclesListPagination().type,
    (state: RootState) => getVehicleListParameters(state)
);

const assignVehiclesToCourierEpic = createOperationEpic<ICourierAssignVehiclesProcessOutput>(
    updateAssignVehiclesToCourierAPI,
    assignVehiclesToCourierSuccessActions,
    courierVehicleListErrorActions,
    assignVehiclesToCourier().type
);

const fetchCourierAssignedVehicleIdsEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(fetchCourierAssignedVehicleIds().type),
        mergeMap((action) => {
            const courierId = action.payload.courierId,
                courierVehiclesList = courierVehicleListSelector(state$.value),
                authToken = authTokenSelector(state$.value);

            if (!courierVehiclesList) {
                return of(setCourierVehicleListLoading(false));
            }
            const observables = courierVehiclesList.map((vehicle) =>
                getAssignedCouriersListAPI(authToken, {assignedCourierListVehicleId: vehicle.id}).pipe(
                    map((courierInfo: IApiResponseBase<IFleetPartnerCourierListingOutput[]> | null) => {
                        const assignedVehicleIds: string[] = [];
                        courierInfo?.['hydra:member']?.forEach((courier) => {
                            if (courier.id === courierId) {
                                assignedVehicleIds.push(vehicle.id);
                            }
                        });
                        return assignedVehicleIds;
                    }),
                    catchError(() => EMPTY)
                )
            );
            if (observables.length === 0) {
                return of(setCourierAssignedVehicles([]), setCourierVehicleListLoading(false));
            }
            return forkJoin(observables).pipe(
                switchMap((results: string[][]) => {
                    const combinedAssignedVehicleIds = results?.reduce((acc, ids) => [...acc, ...ids], [] as string[]),
                        uniqueAssignedVehicleIds = Array.from(new Set(combinedAssignedVehicleIds));
                    return of(setCourierAssignedVehicles(uniqueAssignedVehicleIds), setCourierVehicleListLoading(false));
                }),
                catchError((error) => of(addAlert(handleApiError(error)), setCourierVehicleListLoading(false)))
            );
        })
    );

const courierListingEpic = combineEpics(
    inviteCourierEpic,
    changePaginationEpic,
    unassignCourierEpic,
    fetchCouriersListEpic,
    canBeUnassignedEpic,
    changeVerifiedFilterEpic,
    changeSearchFilterEpic,
    changeCityFilterEpic,
    fetchCourierVehiclesListEpic,
    changeVehiclesPaginationEpic,
    fetchCourierAssignedVehicleIdsEpic,
    assignVehiclesToCourierEpic
);

export default courierListingEpic;
