import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';

export const selectFleetPartnerActiveCourierListingSlice = (state: RootState) => {
    return state.fleetPartnerActiveCourierListing;
};

export const fleetPartnerActiveCourierListingSelector = createSelector(
    [selectFleetPartnerActiveCourierListingSlice],
    (state: any) => state.activeCourierList
);
export const fleetPartnerActiveCourierListingMetadataSelector = createSelector(
    [selectFleetPartnerActiveCourierListingSlice],
    (state: any) => state.metadata
);
export const fleetPartnerActiveCourierListingTotalItemsSelector = createSelector(
    [selectFleetPartnerActiveCourierListingSlice],
    (state: any) => state.metadata?.totalItems
);
export const fleetPartnerActiveCourierListingPaginationSelector = createSelector(
    [selectFleetPartnerActiveCourierListingSlice],
    (state: any) => state.pagination
);
export const fleetPartnerActiveCourierListVehicleIdSelector = createSelector(
    [selectFleetPartnerActiveCourierListingSlice],
    (state: any) => {
        return state.activeCourierListVehicleId;
    }
);
export const isFleetPartnerActiveCourierListingLoadingSelector = createSelector(
    [selectFleetPartnerActiveCourierListingSlice],
    (state: any) => state.isLoading
);
export const isFleetPartnerActiveCourierListingInitializedSelector = createSelector(
    [selectFleetPartnerActiveCourierListingSlice],
    (state: any) => state.isInitialized
);
export const activeCourierFiltersSelector = createSelector(
    [selectFleetPartnerActiveCourierListingSlice],
    (state: any) => state.activeCourierFilters
);
