import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IVehicleOperationState} from '../reducers/vehicleOperationSlice';

export const selectVehicleOperationSlice = (state: RootState) => {
    return state.vehicleOperation;
};

export const currentStepSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.currentStep);
export const vehicleMakesSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.availableMakes);
export const vehicleModelsSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.availableModels);
export const vehicleYearsSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.availableProductionYears);
export const isVehicleOperationLoadingSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.isLoading);
export const vehicleBasicInformationSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.basicInformation);
export const vehicleInspectionSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.inspection);
export const vehicleInsuranceSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.insurance);
export const canDeleteCurrentVehicleSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.canDeleteCurrentVehicle);
export const canUpdateInspectionSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.canUpdateInspection);
export const canUpdateInsuranceSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.canUpdateInsurance);
export const currentlyDeletedVehicleIdSelector = createSelector(
    [selectVehicleOperationSlice],
    (state: any) => state.currentlyDeletedVehicleId
);
export const assignedCourierListVehicleIdSelector = createSelector(
    [selectVehicleOperationSlice],
    (state: any) => state.assignedCourierListVehicleId
);
export const currentlyEditedVehicleSelector = createSelector([selectVehicleOperationSlice], (state: any) => state.currentlyEditedVehicle);
export const isRedirectToVehicleListSelector = createSelector(
    [selectVehicleOperationSlice],
    (state: IVehicleOperationState) => state.redirectToVehicleList
);
