import {combineEpics} from 'redux-observable';
import {
    accountEpic,
    changePasswordEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    authEpic,
    dictionaryDataEpic,
    systemParametersEpic,
    silentRefreshDictionariesAndParametersEpic,
} from 'palipali-panel-common-web';
import vehiclesEpic from './vehicleListingEpic';
import vehicleOperationEpic from './vehicleOperationEpic';
import accountSettlementEpic from './accountSettlementEpic';
import courierListingEpic from './courierListingEpic';
import vehicleAssignedCouriersEpic from './vehicleAssignedCourierListingEpic';
import fleetPartnerActiveCouriersEpic from './fleetPartnerActiveCourierListingEpic';
import settlementListingEpic from './settlementListingEpic';
import reportsEpic from './reportsEpic';

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    authEpic,
    reauthenticateEpic,
    changePasswordEpic,
    accountEpic,
    dictionaryDataEpic,
    vehiclesEpic,
    vehicleOperationEpic,
    courierListingEpic,
    vehicleAssignedCouriersEpic,
    settlementListingEpic,
    accountSettlementEpic,
    fleetPartnerActiveCouriersEpic,
    systemParametersEpic,
    reportsEpic,
    silentRefreshDictionariesAndParametersEpic
);
