import {Card, CardActions, CardContent, CardHeader, Divider, IconButton, Modal, Theme, ThemeProvider} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {closeModal} from '../../store/reducers/modalSlice';
import {isModalOpenSelector} from '../../store/selectors/modalSelectors';
import CloseButtonIcon from '../../theme/icons/untitled-ui/duocolor/close-button';
import {isNotNullOrUndefined} from '../../utils/runtimeUtils';

export interface CustomModalProps {
    theme: Theme;
    title: React.ReactNode;
    content: any;
    footer?: any;
    customCloseHandle?: () => void;
    onSubmit?: () => void;
    customClasses?: string;
    openOnStart?: boolean;
}

const CustomModalComponent: React.FC<CustomModalProps> = ({
    theme,
    title,
    content,
    customCloseHandle,
    openOnStart,
    customClasses,
    footer,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const isOpen = useSelector(isModalOpenSelector);
    const handleClose = () => {
        if (customCloseHandle) {
            customCloseHandle();
        }
        dispatch(closeModal());
    };

    useEffect(() => {
        if (isNotNullOrUndefined(isOpen)) {
            setIsModalOpen(isOpen);
        }
    }, [isOpen]);

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={openOnStart ? true : isModalOpen}
                onClose={() => handleClose()}
                className={`custom-modal-wrapper ${customClasses ? customClasses : ''}`}>
                <Card className="custom-modal-card">
                    <CardHeader
                        className="custom-modal-header"
                        title={title}
                        action={
                            <IconButton aria-label="close" className="custom-modal-close-button" onClick={() => handleClose()}>
                                <CloseButtonIcon />
                            </IconButton>
                        }
                    />
                    <Divider />
                    <CardContent className="custom-modal-content">{content}</CardContent>
                    {footer && (
                        <>
                            <Divider />
                            <CardActions className="custom-modal-footer">{footer}</CardActions>
                        </>
                    )}
                </Card>
            </Modal>
        </ThemeProvider>
    );
};

export default CustomModalComponent;
