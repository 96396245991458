import type {SVGProps} from 'react';
import React from 'react';

const CloseButtonIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" {...props}>
        <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default CloseButtonIcon;
