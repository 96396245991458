import {Box, Stack, SvgIcon, Typography, alpha} from '@mui/material';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Uploader} from 'rsuite';
import {Subscription, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {
    AuthorizedImage,
    Translation,
    accountInfoSelector,
    addAlert,
    authTokenSelector,
    getFullAccount,
    handleApiError,
    updateAccountSuccess,
    updateAvatarAPI,
} from '../..';
import {API_URL} from '../../config/entrypoint';
import {setIsAccountLoading} from '../../store/reducers/accountSlice';
import Camera01Icon from '../../theme/icons/untitled-ui/duocolor/camera-01';
import {AlertType} from '../../types';

const AvatarUploadComponent: React.FC = () => {
    const [fileInfo, setFileInfo] = React.useState(null);
    const dispatch = useDispatch(),
        authToken = useSelector(authTokenSelector),
        account = useSelector(accountInfoSelector),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        if (account?.avatar) {
            setFileInfo({
                name: 'avatar',
                fileKey: account.avatar.id,
                url: account.avatar.fileUrls.mediumThumb,
            });
        }
    }, [account]);

    return (
        <Uploader
            fileListVisible={false}
            listType="picture"
            className="avatar-upload-component"
            accept="image/*"
            action={`${API_URL}/api/media_objects`}
            headers={{Authorization: `Bearer ${authToken}`}}
            onUpload={(file) => {
                dispatch(setIsAccountLoading(true));
            }}
            onSuccess={(response, file) => {
                if (!account.id) {
                    dispatch(setIsAccountLoading(false));
                    return;
                }
                const mediaObject = {
                    mediaObjectId: response.id,
                };
                const payload = {
                    avatar: mediaObject,
                    accountId: account.id,
                };

                subscriptions.push(
                    updateAvatarAPI(authToken, payload)
                        .pipe(
                            switchMap((resp: any) => {
                                dispatch(setIsAccountLoading(false));
                                const respAvatar = resp.avatar;
                                if (respAvatar) {
                                    setFileInfo({
                                        name: 'avatar',
                                        fileKey: respAvatar.id,
                                        url: respAvatar.fileUrls.mediumThumb,
                                    });
                                }
                                dispatch(addAlert({message: 'account.alert.imageAddSuccess', type: AlertType.SUCCESS}));
                                dispatch(updateAccountSuccess(resp));
                                dispatch(getFullAccount());
                                return of(resp);
                            }),
                            catchError((updateError) => {
                                const errorObj = handleApiError(updateError);
                                errorObj.type = AlertType.WARNING;
                                return of(addAlert(errorObj));
                            })
                        )
                        .subscribe()
                );
            }}
            onError={(error: any) => {
                const errorObj = handleApiError(error);
                errorObj.type = AlertType.WARNING;
                setFileInfo(null);
                dispatch(addAlert(errorObj));
            }}>
            <Box
                className="avatar-upload-component-box-wrapper"
                sx={{
                    borderColor: 'neutral.300',
                }}>
                <Box className="avatar-upload-component-box">
                    <Box
                        className="avatar-upload-component-box-hover"
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.neutral[700], 0.5),
                            color: 'common.white',
                        }}>
                        <Stack className="hover-info-text">
                            <SvgIcon color="inherit">
                                <Camera01Icon />
                            </SvgIcon>
                            <Typography color="inherit" variant="subtitle2" sx={{fontWeight: 700}}>
                                <Translation text={`avatarUpload.${fileInfo ? 'update' : 'select'}`} />
                            </Typography>
                        </Stack>
                    </Box>
                    <Box className="avatar-upload-component-box-image">
                        <AuthorizedImage url={fileInfo?.url} alt="" />
                    </Box>
                </Box>
            </Box>
        </Uploader>
    );
};

export default AvatarUploadComponent;
