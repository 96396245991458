import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';

export function getDictionaryDataAPI(authToken: string, dictionaryName: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return palipaliAPI.get(`api/dictionary/${dictionaryName}`, new RestQueryParams(), headers);
}
