import {
    getReportsListingAPI,
    getCourierJobsStatisticsAPI,
    authTokenSelector,
    handleApiError,
    AlertType,
    addAlert,
    fetchDownloadCourierReportAPI,
} from 'palipali-panel-common-web';
import {
    downloadReport,
    finishReportDownload,
    getReportsListing,
    getReportsListingFailed,
    getReportsListingSuccess,
    getReportStatisticsSuccess,
    IGetReportCSV,
    IGetReportsListing,
} from '../reducers/reportsSlice';
import {combineEpics, Epic, StateObservable, ofType} from 'redux-observable';
import {RootState} from '../reducers';
import {switchMap, catchError} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {PayloadAction} from '@reduxjs/toolkit';
import {saveAs} from 'file-saver';

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.ERROR;
    return [getReportsListingFailed(errorObj.message), addAlert(errorObj)];
};

const getReportsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(getReportsListing.type),
        switchMap((action: PayloadAction<IGetReportsListing>) => {
            const authToken = authTokenSelector(state$.value);
            if (!action.payload.filters['finishedAt[before]'] || !action.payload.filters['startedAt[after]']) {
                return of({type: 'NOOP'});
            }
            return forkJoin([
                getReportsListingAPI(authToken, action.payload.filters, action.payload.page),
                getCourierJobsStatisticsAPI(authToken, action.payload.filters),
            ]).pipe(
                switchMap(([reportsListing, statistics]) => {
                    return [
                        getReportsListingSuccess(
                            reportsListing['hydra:member'],
                            {
                                totalDistance: reportsListing['extra:metadata'].totalDistance,
                                totalTime: reportsListing['extra:metadata'].totalTime,
                                totalIncome: reportsListing['extra:metadata'].totalIncome,
                            },
                            reportsListing['hydra:totalItems'] > 0 ? action.payload.page : 0,
                            reportsListing['hydra:totalItems']
                        ),
                        getReportStatisticsSuccess(statistics['hydra:member']),
                    ];
                })
            );
        }),
        catchError(errorActions)
    );

const getReportCSVEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(downloadReport.type),
        switchMap((action: PayloadAction<IGetReportCSV>) => {
            const authToken = authTokenSelector(state$.value);
            if (!action.payload.filters['finishedAt[before]'] || !action.payload.filters['startedAt[after]']) {
                return of({type: 'NOOP'});
            }
            return fetchDownloadCourierReportAPI(authToken, action.payload.filters, action.payload.page).pipe(
                switchMap((res) => res.blob({type: 'text/csv'})),
                switchMap((response: any) => {
                    saveAs(
                        response,
                        `palipali_report_from_${action.payload.filters['startedAt[after]']}_to_${action.payload.filters['finishedAt[before]']}.csv`
                    );
                    return [finishReportDownload()];
                })
            );
        }),
        catchError(errorActions)
    );

const reportsEpic = combineEpics(getReportsEpic, getReportCSVEpic);

export default reportsEpic;
