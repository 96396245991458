import {
    AlertType,
    DetailsSuccessActionsFunction,
    IFleetPartnerCourierListingOutput,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
    ListSuccessActionsFunction,
    addAlert,
    updateCouriersAssignmentToVehicleAPI,
    createFetchListEpic,
    createOperationEpic,
    flattenObj,
    getAssignedCouriersListAPI,
    getFleetPartnerCouriersListAPI,
    handleApiError,
    IVehicleAssignCouriersProcessOutput,
    closeModal,
    Action,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {RootState} from '../reducers';
import {
    assignCouriersToVehicle,
    changeFleetPartnerCouriersListingPagination,
    getCurrentVehicleAssignedCouriersListing,
    getFleetPartnerCouriersListing,
    resetToInitialState,
    setCurrentVehicleAssignedCourierIdList,
    setError,
    setFleetPartnerCourierList,
    setLoading,
    setMetadata,
} from '../reducers/vehicleAssignedCourierListingSlice';
import {vehicleAssignedCourierListingPaginationSelector} from '../selectors/vehicleAssignedCourierListingSelector';
import {getVehicleListing} from '../reducers/vehicleListingSlice';

const fleetPartnerCourierListingSuccessActions: ListSuccessActionsFunction<IFleetPartnerCourierListingOutput> = (
    courierArray: IFleetPartnerCourierListingOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setFleetPartnerCourierList({fleetPartnerCourierList: courierArray}), setMetadata({metadata: metadata}), setLoading(false)];
};

const changeCouriersAssignmentToVehicleSuccessActions: DetailsSuccessActionsFunction<IVehicleAssignCouriersProcessOutput> = (
    assignedCouriers: IVehicleAssignCouriersProcessOutput
) => {
    const actions = [resetToInitialState(), closeModal(), setLoading(false), getVehicleListing()];
    if (assignedCouriers.failedCourierIds?.length > 0) {
        actions.push(
            addAlert({
                message: 'vehicles.vehicleList.assignedCouriers.modal.alerts.notAssigned',
                type: AlertType.WARNING,
            })
        );
    }
    if (assignedCouriers.assigned?.courierIds?.length > 0) {
        actions.push(
            addAlert({
                message: 'vehicles.vehicleList.assignedCouriers.modal.alerts.success',
                type: AlertType.SUCCESS,
            })
        );
    }
    return actions;
};

const currentVehicleAssignedCouriersListingSuccessActions: ListSuccessActionsFunction<IFleetPartnerCourierListingOutput> = (
    courierArray: IFleetPartnerCourierListingOutput[]
) => {
    const couriersIdList = courierArray.map((courier) => courier.id);
    return [setCurrentVehicleAssignedCourierIdList({currentVehicleAssignedCourierIdList: couriersIdList}), setLoading(false)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const fleetPartnerCourierListingEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getFleetPartnerCouriersListAPI,
    fleetPartnerCourierListingSuccessActions,
    errorActions,
    getFleetPartnerCouriersListing().type,
    (state: RootState) => getParameters(state, true),
    (action: Action) => {
        action.payload?.vehicleId;
    }
);

const vehicleAssignedCouriersListingEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getAssignedCouriersListAPI,
    currentVehicleAssignedCouriersListingSuccessActions,
    errorActions,
    getCurrentVehicleAssignedCouriersListing().type,
    (state: any) => getParameters(state),
    (state: any) => state.payload
);

const getParameters = (state: RootState, onlyActive?: boolean): IRawRestQueryParams => {
    const pagination = vehicleAssignedCourierListingPaginationSelector(state);
    const filterOnlyActive = onlyActive ? {verified: 'active_or_inactive'} : {};
    const params = {
        ...pagination,
        ...filterOnlyActive,
    };

    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const changeFleetPartnerCouriersEpic = createFetchListEpic<IFleetPartnerCourierListingOutput>(
    getFleetPartnerCouriersListAPI,
    fleetPartnerCourierListingSuccessActions,
    errorActions,
    changeFleetPartnerCouriersListingPagination().type,
    (state: RootState) => getParameters(state)
);

const changeCouriersAssignmentToVehicleEpic = createOperationEpic<IVehicleAssignCouriersProcessOutput>(
    updateCouriersAssignmentToVehicleAPI,
    changeCouriersAssignmentToVehicleSuccessActions,
    errorActions,
    assignCouriersToVehicle().type
);

const vehicleAssignedCouriersEpic = combineEpics(
    fleetPartnerCourierListingEpic,
    vehicleAssignedCouriersListingEpic,
    changeFleetPartnerCouriersEpic,
    changeCouriersAssignmentToVehicleEpic
);

export default vehicleAssignedCouriersEpic;
