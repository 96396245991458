import {Box, Divider, Stack, Typography} from '@mui/material';
import {IFleetPartnerCourierListingOutput, NoDataInfo, Loader, LoaderType, Translation} from 'palipali-panel-common-web';
import PropTypes from 'prop-types';
import type {FC} from 'react';
import ActiveCourierListItem from './ActiveCourierListItem';
import {useSelector} from 'react-redux';
import {isFleetPartnerActiveCourierListingLoadingSelector} from '../../../../store/selectors/fleetPartnerActiveCourierListingSelector';

interface IActiveCourierListProps {
    currentCourierId?: string;
    onCourierDeselect?: () => void;
    onCourierSelect?: (courierId: string) => void;
    couriers?: IFleetPartnerCourierListingOutput[];
}

export const ActiveCourierList: FC<IActiveCourierListProps> = (props) => {
    const {onCourierDeselect, onCourierSelect, currentCourierId, couriers = []} = props;
    const isLoading = useSelector(isFleetPartnerActiveCourierListingLoadingSelector);

    const renderCourierList = () => {
        if (!isLoading && couriers.length === 0) {
            return <NoDataInfo text={'activeCouriers.list.noActiveCouriers'} />;
        }

        return couriers.map((courier) => {
            const selected = currentCourierId ? currentCourierId === courier.id : false;
            return (
                <ActiveCourierListItem
                    key={courier.id}
                    onDeselect={onCourierDeselect}
                    onSelect={onCourierSelect}
                    selected={selected}
                    courier={courier}
                />
            );
        });
    };

    return (
        <Box
            sx={{
                borderRightColor: 'divider',
                borderRightStyle: 'solid',
                borderRightWidth: 1,
                flex: '0 0 40rem',
                position: 'relative',
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'white'),
            }}>
            <Typography variant="h2" className="sr-only">
                <Translation text="activeCouriers.list.title" />
            </Typography>

            <Stack
                component="ul"
                divider={<Divider />}
                sx={{
                    borderBottomColor: 'divider',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    listStyle: 'none',
                    m: 0,
                    p: 0,
                }}>
                <Loader showLoader={isLoading} type={LoaderType.Local} />
                {renderCourierList()}
            </Stack>
        </Box>
    );
};

export default ActiveCourierList;

ActiveCourierList.propTypes = {
    currentCourierId: PropTypes.string,
    onCourierDeselect: PropTypes.func,
    onCourierSelect: PropTypes.func,
    couriers: PropTypes.array,
};
