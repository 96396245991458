import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {IAssignVehiclesToCourierInput, ICourierAssignVehiclesProcessOutput} from '../../model/vehicle';

export const updateAssignVehiclesToCourierAPI: ApiOperationResponseFunction<ICourierAssignVehiclesProcessOutput> = (
    authToken: string,
    payload: {
        courierId: string;
        addVehicleIds: string[];
        removeVehicleIds: string[];
    }
): Observable<IApiSingleResponseBase<ICourierAssignVehiclesProcessOutput>> => {
    const assignVehiclePayload: IAssignVehiclesToCourierInput = {
        addVehicleIds: payload.addVehicleIds,
        removeVehicleIds: payload.removeVehicleIds,
    };

    return palipaliAPI.put(`api/couriers/${payload.courierId}/assign_vehicles`, assignVehiclePayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
