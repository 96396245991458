import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './baseSlice';
import {IModelVehicleType} from '../../model/vehicleType';

export interface IVehicleTypeState extends IBaseDictionaryState {
    vehicleTypes: IModelVehicleType[] | null;
}

export interface IChangeVehicleType {
    vehicleTypes: IModelVehicleType[] | null;
}

const initialState: IVehicleTypeState = {
    vehicleTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const vehicleTypeSlice = createSlice({
    name: 'vehicleType',
    initialState: initialState,
    reducers: {
        changeVehicleType: {
            reducer: (state: IVehicleTypeState, action: PayloadAction<IChangeVehicleType>) => {
                return {
                    vehicleTypes: action.payload.vehicleTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(vehicleTypes: IModelVehicleType[] | null) {
                return {
                    payload: {vehicleTypes: vehicleTypes},
                };
            },
        },
        changeIsVehicleTypeLoading: {
            reducer: (state: IVehicleTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    vehicleTypes: state.vehicleTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsVehicleTypeInitialized: {
            reducer: (state: IVehicleTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    vehicleTypes: state.vehicleTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setVehicleTypeError: {
            reducer: (state: IVehicleTypeState, action: PayloadAction<ISetError>) => {
                return {
                    vehicleTypes: state.vehicleTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchVehicleTypes: (state: IVehicleTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeVehicleType, changeIsVehicleTypeLoading, changeIsVehicleTypeInitialized, setVehicleTypeError, fetchVehicleTypes} =
    vehicleTypeSlice.actions;

export default vehicleTypeSlice.reducer;
