import {SvgIcon} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import type {FC} from 'react';
import LogoImg from '../../icons/logo/logo-fleet-partner';
import React from 'react';

const Logo: FC = () => {
    const theme = useTheme();
    const fillColor = theme.palette.primary.main;

    return (
        <SvgIcon
            fill={fillColor}
            viewBox="0 0 30 40"
            sx={{
                display: 'flex',
                flex: '1',
                backgroundColor: '#fff',
                width: '100%',
                height: '100%',
                borderRadius: '0.8rem',
                padding: '0.2rem',
            }}>
            <LogoImg />
        </SvgIcon>
    );
};

export default Logo;
