import {SvgIcon} from '@mui/material';
import type {ReactNode} from 'react';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {BarChartSquare02, Map02, ReceiptCheck, Truck01, Users03} from 'palipali-panel-common-web';

export interface Item {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    items?: Item[];
    label?: ReactNode;
    path?: string;
    class?: string;
    title: string;
}

export interface Section {
    items: Item[];
    subheader?: string;
}

export const useSections = () => {
    const {t} = useTranslation();

    return useMemo(() => {
        return [
            {
                items: [
                    {
                        title: t('menuItems.activeCouriers'),
                        path: '/panel/active-couriers/',
                        icon: (
                            <SvgIcon fontSize="large">
                                <Map02 />
                            </SvgIcon>
                        ),
                        class: 'active-couriers-menu-item',
                    },
                    {
                        title: t('menuItems.reports'),
                        path: '/panel/reports/',
                        icon: (
                            <SvgIcon fontSize="large">
                                <BarChartSquare02 />
                            </SvgIcon>
                        ),
                        class: 'reports-menu-item',
                    },
                    {
                        title: t('menuItems.settlements'),
                        path: '/panel/settlements/',
                        icon: (
                            <SvgIcon fontSize="large">
                                <ReceiptCheck />
                            </SvgIcon>
                        ),
                        class: 'settlements-menu-item',
                    },
                    {
                        title: t('menuItems.couriers'),
                        path: '/panel/couriers/',
                        icon: (
                            <SvgIcon fontSize="large">
                                <Users03 />
                            </SvgIcon>
                        ),
                        class: 'couriers-menu-item',
                    },
                    {
                        title: t('menuItems.vehicles'),
                        path: '/panel/vehicles/',
                        icon: (
                            <SvgIcon fontSize="large">
                                <Truck01 />
                            </SvgIcon>
                        ),
                        class: 'vehicles-menu-item',
                    },
                ],
            },
        ];
    }, [t]);
};
