import {Avatar, ButtonBase, Collapse, Box, IconButton, Stack, SvgIcon, Typography} from '@mui/material';
import {
    IFleetPartnerCourierListingOutput,
    PhoneComponent,
    Translation,
    ChevronRightOutlinedIcon,
    User01Icon,
} from 'palipali-panel-common-web';
import type {FC} from 'react';
import {useCallback} from 'react';
import StatusBadge from 'src/components/Couriers/StatusBadge';
import DeliveryStatusInfo from './DeliveryStatusBadge';

interface IActiveCourierListItemProps {
    onDeselect?: () => void;
    onSelect?: (courierId: string) => void;
    selected?: boolean;
    courier: IFleetPartnerCourierListingOutput;
}

export const ActiveCourierListItem: FC<IActiveCourierListItemProps> = (props) => {
    const {onDeselect, onSelect, selected, courier} = props;

    const handleToggle = useCallback((): void => {
        if (!selected) {
            onSelect?.(courier.id);
        } else {
            onDeselect?.();
        }
    }, [onDeselect, onSelect, selected, courier]);
    const courierName = `${courier.account.firstName} ${courier.account.lastName}`,
        courierPhone = courier.account.phone ? <PhoneComponent phone={courier.account.phone} /> : '-',
        courierEmail = courier.account.username ? courier.account.username : '-',
        courierVehicleRegistrationNumber = courier.activeVehicle?.registrationNumber ? courier.activeVehicle?.registrationNumber : '-',
        courierCurrentJob = courier.currentJob,
        courierStatus = courier.status,
        courierActiveVehicle = courier.activeVehicle,
        courierAvatar = courier.account.avatar?.fileUrls?.smallThumb;

    const getStatus = () => {
        if (!courierCurrentJob && !courierStatus) return;
        if (!courierCurrentJob && courierStatus) return <StatusBadge status={'online'} />;
        if (courierCurrentJob)
            return <DeliveryStatusInfo status={courierCurrentJob.deliveryStatus} packageSize={courierCurrentJob.packageSize} />;
    };

    const vehicleData = () => {
        if (!courierActiveVehicle) return;
        return (
            <Stack>
                <Typography variant="caption" component="p" color="text.primary">
                    {courierActiveVehicle.make} {courierActiveVehicle.model} {courierActiveVehicle.productionYear}
                </Typography>
                <Typography variant="caption" component="p" color="text.secondary">
                    <Translation text={`activeCouriers.list.vehicleOwnership.${courierActiveVehicle.type}`} />
                </Typography>
            </Stack>
        );
    };
    const courierData = () => {
        return (
            <Stack>
                <Typography variant="caption" component="p" color="text.primary">
                    {courierEmail}
                </Typography>
                <Typography variant="caption" component="p" color="text.secondary">
                    {courierPhone}
                </Typography>
            </Stack>
        );
    };

    return (
        <ButtonBase
            component="div"
            onClick={handleToggle}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
                maxWidth: '40rem',
                padding: '1.2rem 1.6rem',
            }}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: '0.8rem', width: '100%'}}>
                <Avatar src={courierAvatar ? courierAvatar : undefined} sx={{marginRight: '1.6rem', width: '4rem', height: '4rem'}}>
                    <SvgIcon fontSize="large">
                        <User01Icon />
                    </SvgIcon>
                </Avatar>
                <Stack sx={{flex: '1', minWidth: '0'}}>
                    <Typography
                        variant="subtitle1"
                        color="text.primary"
                        sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {courierName}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                        {courierVehicleRegistrationNumber}
                    </Typography>
                </Stack>
                <Box className="active-courier-list-item-actions">{getStatus()}</Box>
                <IconButton sx={{borderRadius: '1.2rem'}}>
                    <ChevronRightOutlinedIcon />
                </IconButton>
            </Box>
            <Collapse in={selected} sx={{width: '100%', marginTop: '0.8rem'}}>
                <Stack sx={{display: 'flex', flexDirection: 'column', gap: '0.8rem', marginLeft: '6.4rem'}}>
                    {courierData()}
                    {vehicleData()}
                </Stack>
            </Collapse>
        </ButtonBase>
    );
};

export default ActiveCourierListItem;
