import {FormControl, Grid, IconButton, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getMonth, getYear, setMonth, setYear, Locale} from 'date-fns';
import ChevronLeftIcon from '../../../theme/icons/untitled-ui/duocolor/chevron-left';
import ChevronRightIcon from '../../../theme/icons/untitled-ui/duocolor/chevron-right';

interface HeaderProps {
    date: Date;
    // eslint-disable-next-line no-unused-vars
    setDate: (date: Date) => void;
    nextDisabled: boolean;
    prevDisabled: boolean;
    onClickNext: () => void;
    onClickPrevious: () => void;
    locale?: Locale;
}

const generateYears = (relativeTo: Date, count: number) => {
    const half = Math.floor(count / 2);
    return Array(count)
        .fill(0)
        .map((_y, i) => relativeTo.getFullYear() - half + i);
};

const Header: React.FunctionComponent<HeaderProps> = ({
    date,
    setDate,
    nextDisabled,
    prevDisabled,
    onClickNext,
    onClickPrevious,
    locale,
}: HeaderProps) => {
    const {t} = useTranslation();
    const MONTHS =
        typeof locale !== 'undefined'
            ? Array(12)
                  .fill(null)
                  .map((_, d) => locale.localize?.month(d, {width: 'abbreviated', context: 'standalone'}))
            : [
                  `${t('month.january')}`,
                  `${t('month.february')}`,
                  `${t('month.march')}`,
                  `${t('month.april')}`,
                  `${t('month.may')}`,
                  `${t('month.june')}`,
                  `${t('month.july')}`,
                  `${t('month.august')}`,
                  `${t('month.september')}`,
                  `${t('month.october')}`,
                  `${t('month.november')}`,
                  `${t('month.december')}`,
              ];

    const handleMonthChange = (event: SelectChangeEvent<number>) => {
        setDate(setMonth(date, parseInt(event.target.value as string, 10)));
    };

    const handleYearChange = (event: SelectChangeEvent<number>) => {
        setDate(setYear(date, parseInt(event.target.value as string, 10)));
    };

    return (
        <Grid container justifyContent="space-between" alignItems="center" className="date-range-picker-calendar-header">
            <Grid item className="date-range-picker-calendar-header-month-wrapper">
                <FormControl variant="standard">
                    <Select value={getMonth(date)} onChange={handleMonthChange} MenuProps={{disablePortal: true}}>
                        {MONTHS.map((month, idx) => (
                            <MenuItem key={month} value={idx}>
                                {month}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item className="date-range-picker-calendar-header-year-wrapper">
                <FormControl variant="standard">
                    <Select value={getYear(date)} onChange={handleYearChange} MenuProps={{disablePortal: true}}>
                        {generateYears(date, 30).map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item className="date-range-picker-calendar-header-month-nav-button">
                <IconButton
                    sx={{
                        padding: '10px',
                        '&:hover': {
                            background: 'none',
                        },
                    }}
                    disabled={prevDisabled}
                    onClick={onClickPrevious}>
                    <ChevronLeftIcon color={prevDisabled ? 'disabled' : 'action'} />
                </IconButton>
            </Grid>
            <Grid item className="date-range-picker-calendar-header-month-nav-button">
                <IconButton
                    sx={{
                        padding: '10px',
                        '&:hover': {
                            background: 'none',
                        },
                    }}
                    disabled={nextDisabled}
                    onClick={onClickNext}>
                    <ChevronRightIcon color={nextDisabled ? 'disabled' : 'action'} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default Header;
