import {SVGProps} from 'react';
import React from 'react';

const Clock = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 6.1V11.5L15.6 13.3M21 11.5C21 16.4706 16.9706 20.5 12 20.5C7.02944 20.5 3 16.4706 3 11.5C3 6.52944 7.02944 2.5 12 2.5C16.9706 2.5 21 6.52944 21 11.5Z"
            stroke="#009FE3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Clock;
