import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../reducers';
import {ISagaState} from '../reducers/sagaSlice';

export const selectSaga = (state: CommonRootState): ISagaState => {
    return state.saga;
};

export const activeLanguageSelector = createSelector([selectSaga], (state: ISagaState) => state.activeLanguage);

export const contentWidthSelector = createSelector([selectSaga], (state: ISagaState) => state.contentWidth);

export const routerTransitionSelector = createSelector([selectSaga], (state: ISagaState) => state.routerTransition);

export const menuCollapsedSelector = createSelector([selectSaga], (state: ISagaState) => state.menuCollapsed);

export const menuHiddenSelector = createSelector([selectSaga], (state: ISagaState) => state.menuHidden);

export const showLoaderSelector = createSelector([selectSaga], (state: ISagaState) => state.showLoader);
