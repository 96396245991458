import {Observable} from 'rxjs';
import {ITeamMemberOutput} from '../../model/team';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const deleteTeamMemberAPI: ApiOperationResponseFunction<ITeamMemberOutput> = (
    authToken: string,
    id: string
): Observable<IApiSingleResponseBase<ITeamMemberOutput>> => {
    return palipaliAPI.delete(`api/team_members/${id}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
