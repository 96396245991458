import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelCity} from '../../model/common';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './baseSlice';

export interface ICityState extends IBaseDictionaryState {
    cities: IModelCity[] | null;
}

export interface IChangeCity {
    cities: IModelCity[] | null;
}

const initialState: ICityState = {
    cities: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const citySlice = createSlice({
    name: 'city',
    initialState: initialState,
    reducers: {
        changeCity: {
            reducer: (state: ICityState, action: PayloadAction<IChangeCity>) => {
                return {
                    cities: action.payload.cities,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(cities: IModelCity[] | null) {
                return {
                    payload: {cities: cities},
                };
            },
        },
        changeIsCityLoading: {
            reducer: (state: ICityState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    cities: state.cities,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsCityInitialized: {
            reducer: (state: ICityState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    cities: state.cities,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setCityError: {
            reducer: (state: ICityState, action: PayloadAction<ISetError>) => {
                return {
                    cities: state.cities,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchCities: (state: ICityState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeCity, changeIsCityLoading, changeIsCityInitialized, setCityError, fetchCities} = citySlice.actions;

export default citySlice.reducer;
