import React from 'react';
import {Grid} from '@mui/material';
import GraphCard from './GraphCard';
import {useSelector} from 'react-redux';
import {reportsStatisticsAsGraphPointsSelector} from '../../../store/selectors/reportsSelectors';

const ReportsGraphs: React.FC = () => {
    const chartSeries: {
        jobsCompleted: ApexAxisChartSeries;
        courierNumber: ApexAxisChartSeries;
        distance: ApexAxisChartSeries;
        income: ApexAxisChartSeries;
    } = useSelector(reportsStatisticsAsGraphPointsSelector);
    const customDistanceFormatter = (value: number) => (value > 1000 ? `${(value / 1000).toFixed(0)} km` : `${value.toFixed(0)} m`);
    const customDistanceTooltipFormatter = (value: number) => (value > 1000 ? `${(value / 1000).toFixed(2)} km` : `${value.toFixed(0)} m`);
    const customMoneyFormatter = (value: number) => `${(value / 100).toFixed(0)} zł`;
    const customMoneyTooltipFormatter = (value: number) => `${(value / 100).toFixed(2)} zł`;
    const customNonDivisableValuesFormatter = (value: number) => `${value.toFixed(0)}`;

    return (
        <Grid mb={4} container spacing={4}>
            <GraphCard
                customYFormatter={customMoneyFormatter}
                title={'reports.graphs.income'}
                chartSeries={chartSeries.income}
                customTooltipFormatter={customMoneyTooltipFormatter}
            />
            <GraphCard
                title={'reports.graphs.jobsDone'}
                customYFormatter={customNonDivisableValuesFormatter}
                chartSeries={chartSeries.jobsCompleted}
            />
            <GraphCard
                title={'reports.graphs.numberOfActiveCouriers'}
                customYFormatter={customNonDivisableValuesFormatter}
                chartSeries={chartSeries.courierNumber}
            />
            <GraphCard
                customYFormatter={customDistanceFormatter}
                title={'reports.graphs.totalDistance'}
                chartSeries={chartSeries.distance}
                customTooltipFormatter={customDistanceTooltipFormatter}
            />
        </Grid>
    );
};
export default ReportsGraphs;
