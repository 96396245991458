import {Observable} from 'rxjs';
import {ITeamMemberOutput} from '../../model/team';
import {IInviteTeamMemberInput} from '../../model/teamMember';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const createInviteTeamMemberAPI: ApiOperationResponseFunction<ITeamMemberOutput> = (
    authToken: string,
    payload: {
        id: string;
        member: IInviteTeamMemberInput;
    }
): Observable<IApiSingleResponseBase<ITeamMemberOutput>> => {
    return palipaliAPI.put(`api/teams/${payload.id}/invite`, payload.member, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
