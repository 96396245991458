import {SVGProps} from 'react';
import React from 'react';

const FileDownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.333 10.375V6.1C15.333 4.83988 15.333 4.20982 15.0878 3.72852C14.8721 3.30516 14.5279 2.96095 14.1045 2.74524C13.6232 2.5 12.9931 2.5 11.733 2.5H6.93301C5.67289 2.5 5.04283 2.5 4.56153 2.74524C4.13816 2.96095 3.79396 3.30516 3.57824 3.72852C3.33301 4.20982 3.33301 4.83988 3.33301 6.1V13.9C3.33301 15.1601 3.33301 15.7902 3.57824 16.2715C3.79396 16.6948 4.13816 17.039 4.56153 17.2548C5.04283 17.5 5.67285 17.5 6.9329 17.5H9.70801M10.833 9.25H6.33301M7.83301 12.25H6.33301M12.333 6.25H6.33301M11.583 15.25L13.833 17.5M13.833 17.5L16.083 15.25M13.833 17.5V13"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default FileDownloadIcon;
