import React from 'react';
import {Typography, Stack} from '@mui/material';
import SeverityPill, {SeverityPillColor} from '../../theme/components/SeverityPill';
import Translation from '../Translation';

interface IJobStatusDetailsBadgeProps {
    readonly status: string;
}
export const enum DeliveryStatus {
    PLANNED = 'planned',
    SEARCHING = 'searching',
    PICKUP_DRIVE = 'pickup:drive',
    PICKUP = 'pickup',
    DELIVERY_DRIVE = 'delivery:drive',
    DELIVERY = 'delivery',
    DELIVERED = 'delivered',
    CANCELLED_P = 'cancelled:p',
    CANCELLED_C = 'cancelled:c',
    PROBLEM_C = 'problem:c',
    PROBLEM_P = 'problem:p',
    FAILED = 'failed',
}

const jobStatusColorSettings = {
    info: {color: 'info', backgroundColor: '#e1f5f9'},
    succesDarkest: {color: 'success', customColor: '#134E48', backgroundColor: '#e2e9e9'},
    succesDark: {color: 'success', customColor: '#107569', backgroundColor: '#e2f6f3'},
    warning: {color: 'warning', backgroundColor: '#ffeccc'},
    error: {color: 'error', backgroundColor: '#fde8e7'},
};

const JobStatusDetailsBadge: React.FC<IJobStatusDetailsBadgeProps> = ({status}) => {
    const getDeliveryStatusInfoColor = (): {color: string; customColor?: string; backgroundColor?: string} => {
        switch (status) {
            case DeliveryStatus.SEARCHING:
                return jobStatusColorSettings.succesDarkest;

            case DeliveryStatus.PLANNED:
            case DeliveryStatus.PROBLEM_C:
            case DeliveryStatus.PROBLEM_P:
                return jobStatusColorSettings.warning;

            case DeliveryStatus.PICKUP_DRIVE:
            case DeliveryStatus.PICKUP:
            case DeliveryStatus.DELIVERY:
            case DeliveryStatus.DELIVERY_DRIVE:
                return jobStatusColorSettings.info;

            case DeliveryStatus.DELIVERED:
                return jobStatusColorSettings.succesDark;

            case DeliveryStatus.CANCELLED_P:
            case DeliveryStatus.CANCELLED_C:
            case DeliveryStatus.FAILED:
                return jobStatusColorSettings.error;

            default:
                return jobStatusColorSettings.error;
        }
    };

    const geti18nStatus = (status: DeliveryStatus) => {
        switch (status) {
            case DeliveryStatus.PLANNED:
            case DeliveryStatus.SEARCHING:
            case DeliveryStatus.PICKUP:
            case DeliveryStatus.DELIVERY:
            case DeliveryStatus.DELIVERED:
                return (
                    <Typography variant="caption" component="span">
                        <Translation text={`jobStatusDetails.${status as DeliveryStatus}`} />
                    </Typography>
                );

            case DeliveryStatus.PICKUP_DRIVE:
                return (
                    <Typography variant="caption" component="span">
                        <Translation text="jobStatusDetails.drive" />
                    </Typography>
                );
            case DeliveryStatus.DELIVERY_DRIVE:
                return (
                    <Typography variant="caption" component="span">
                        <Translation text="jobStatusDetails.deliveryDrive" />
                    </Typography>
                );
            case DeliveryStatus.CANCELLED_P:
                return (
                    <Typography variant="caption" component="span">
                        <Translation text="jobStatusDetails.cancelledPurchaser" />
                    </Typography>
                );
            case DeliveryStatus.CANCELLED_C:
                return (
                    <Typography variant="caption" component="span">
                        <Translation text="jobStatusDetails.cancelledCourier" />
                    </Typography>
                );

            case DeliveryStatus.PROBLEM_C:
                return (
                    <Typography variant="caption" component="span">
                        <Translation text="jobStatusDetails.problemCourier" />
                    </Typography>
                );
            case DeliveryStatus.PROBLEM_P:
                return (
                    <Typography variant="caption" component="span">
                        <Translation text="jobStatusDetails.problemPurchaser" />
                    </Typography>
                );
            default:
                return (
                    <Typography variant="caption" component="span">
                        <Translation text="jobStatusDetails.online" />
                    </Typography>
                );
        }
    };
    return (
        <SeverityPill
            color={getDeliveryStatusInfoColor().color as SeverityPillColor}
            customColor={getDeliveryStatusInfoColor().customColor}
            backgroundColor={getDeliveryStatusInfoColor().backgroundColor}
            sx={{textTransform: 'none'}}>
            <Stack>{geti18nStatus(status as DeliveryStatus)}</Stack>
        </SeverityPill>
    );
};

export default JobStatusDetailsBadge;
