import type {StepIconProps} from '@mui/material';
import {Avatar, Card, CardHeader, Divider, Grid, Step, StepLabel, Stepper, SvgIcon, Typography} from '@mui/material';
import CheckIcon from '@untitled-ui/icons-react/build/esm/Check';
import type {FC} from 'react';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {vehicleOperationStepArray} from 'src/store/reducers/vehicleOperationSlice';
import {currentStepSelector} from 'src/store/selectors/vehicleOperationSelector';
import BasicInformation from '../BasicInformation';
import Inspection from '../Inspection';
import Insurance from '../Insurance';

const StepIcon: FC<StepIconProps> = (props) => {
    const {active, completed, icon} = props;
    const highlight = active || completed;

    return (
        <Avatar
            sx={{
                height: 40,
                width: 40,
                ...(highlight && {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                }),
            }}
            variant="rounded">
            {completed ? (
                <SvgIcon>
                    <CheckIcon />
                </SvgIcon>
            ) : (
                icon
            )}
        </Avatar>
    );
};

export const VehicleCreateWizard: FC = () => {
    const {t} = useTranslation();
    const currentStep = useSelector(currentStepSelector);
    const steps = useMemo(() => {
        return [
            {
                label: t('vehicles.vehicleOperation.basicInformation.title'),
                content: <BasicInformation />,
            },
            {
                label: t('vehicles.vehicleOperation.inspection.title'),
                content: <Inspection />,
            },
            {
                label: t('vehicles.vehicleOperation.insurance.title'),
                content: <Insurance />,
            },
        ];
    }, []);
    const currentStepIndex = vehicleOperationStepArray.findIndex((step) => step === currentStep);
    const stepperWrapper = (
        <Stepper activeStep={currentStepIndex} className="stepper-wrapper">
            {steps.map((step) => {
                return (
                    <Step key={step.label} className="stepper-step">
                        <StepLabel StepIconComponent={StepIcon} key={`step-label-${step.label}`} className="stepper-step-label">
                            <Typography key={`step-label-text-${step.label}`} variant="overline" className="stepper-step-label-text">
                                {step.label}
                            </Typography>
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );

    return (
        <Grid container>
            <Grid item xs={12} xl={8}>
                <Card className="custom-card new-vehicle-card">
                    <CardHeader title={stepperWrapper}></CardHeader>
                    <Divider />
                    <Grid item xs={12}>
                        {steps[currentStepIndex].content}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};
