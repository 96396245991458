import {Observable} from 'rxjs';
import {IApiResponseBase, IFleetPartnerCourierListingOutput, IRawRestQueryParams} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {ApiResponseFunction} from '../base/apiConnectionInfrastructure';

export const getFleetPartnerActiveCouriersListAPI: ApiResponseFunction<IFleetPartnerCourierListingOutput> = (
    authToken: string,
    payload?: any,
    params?: IRawRestQueryParams | []
): Observable<IApiResponseBase<IFleetPartnerCourierListingOutput[]>> => {
    return palipaliAPI.get('api/fleet_partner_couriers/list_mine', new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
    });
};
