import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ITranslationProps} from '../../types';

type Props = ITranslationProps;

const Translation = (props: Props) => {
    const {t} = useTranslation();

    return <React.Fragment key={props.translationKey}>{t(props.text, props.config)}</React.Fragment>;
};

export default Translation;
