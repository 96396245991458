import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase, IFleetPartnerCourierOutput, IInviteFleetPartnerCourierInput} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const updateInviteCourierAPI: ApiOperationResponseFunction<IFleetPartnerCourierOutput> = (
    authToken: string,
    payload: {
        id: string;
        courier: IInviteFleetPartnerCourierInput;
    }
): Observable<IApiSingleResponseBase<IFleetPartnerCourierOutput>> => {
    const id = payload.id;
    return palipaliAPI.put(`api/fleet_partners/${id}/invite`, payload.courier, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
