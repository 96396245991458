import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IGroupedByMakeModelTypeYearVehicleDefinitionIds, IVehicleDefinitionOutput} from '../../model/vehicleDefinition';
import {ISetError} from './baseSlice';

export interface IVehicleDefinitionsState {
    vehicleDefinitions: IVehicleDefinitionOutput[] | null;
    groupedData: IGroupedByMakeModelTypeYearVehicleDefinitionIds | null;
    isLoading: boolean;
    error: string | null;
}

export interface IChangeVehicleDefinitions {
    vehicleDefinitions: IVehicleDefinitionOutput[] | null;
    groupedData: IGroupedByMakeModelTypeYearVehicleDefinitionIds | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

const initialState: IVehicleDefinitionsState = {
    vehicleDefinitions: null,
    groupedData: null,
    isLoading: false,
    error: null,
};

const vehicleDefinitionsSlice = createSlice({
    name: 'vehicleDefinitions',
    initialState: initialState,
    reducers: {
        changeVehicleDefinitions: {
            reducer: (state: IVehicleDefinitionsState, action: PayloadAction<IChangeVehicleDefinitions>) => {
                return {
                    vehicleDefinitions: action.payload.vehicleDefinitions,
                    groupedData: action.payload.groupedData,
                    isLoading: false,
                    error: state.error,
                };
            },
            prepare(
                vehicleDefinitions: IVehicleDefinitionOutput[] | null,
                groupedData: IGroupedByMakeModelTypeYearVehicleDefinitionIds | null
            ) {
                return {
                    payload: {vehicleDefinitions: vehicleDefinitions, groupedData},
                };
            },
        },
        changeIsVehicleDefinitionsLoading: {
            reducer: (state: IVehicleDefinitionsState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    vehicleDefinitions: state.vehicleDefinitions,
                    groupedData: state.groupedData,
                    isLoading: action.payload.isLoading,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        setVehicleDefinitionsError: {
            reducer: (state: IVehicleDefinitionsState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    isLoading: false,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchVehicleDefinitions: (state: IVehicleDefinitionsState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeVehicleDefinitions, setVehicleDefinitionsError, changeIsVehicleDefinitionsLoading, fetchVehicleDefinitions} =
    vehicleDefinitionsSlice.actions;

export default vehicleDefinitionsSlice.reducer;
