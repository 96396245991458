import {CustomIconProps} from '../../../types/iconTypes';
import React from 'react';

const ChevronRight: React.FC<CustomIconProps> = ({size, ...props}) => (
    <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" {...props}>
        <path d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z" fill="currentColor" />
    </svg>
);

export default ChevronRight;
