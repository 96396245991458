import {FormikFieldConfig, FormikFieldTypes, ValidationErrorType} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export const createVehicleInspectionFormConfig = (): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'until',
            label: t('vehicles.vehicleOperation.inspection.form.until.label'),
            validation: Yup.date().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,

            disablePast: true,
            className: 'vehicles-create-vehicle-inspection-form-until w-100',
            type: FormikFieldTypes.DATE,
            dateFormat: 'dd.MM.yyyy',
            minDate: new Date().setDate(new Date().getDate() + 1),
        },
        {
            name: 'inspectionPhotoFront',
            label: t('vehicles.vehicleOperation.inspection.form.documentPhoto.labelFront'),
            validation: Yup.object()
                .shape({
                    name: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    fileKey: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    url: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                })
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            acceptedFileTypes: 'image/jpeg, image/png, application/pdf',
            className: 'vehicles-create-vehicle-inspection-form-inspection-photo-front',
            multipleFiles: false,
            placeholder: `${t('vehicles.vehicleOperation.inspection.form.documentPhoto.placeholderFront')}`,
            type: FormikFieldTypes.FILE,
        },
        {
            name: 'inspectionPhotoBack',
            label: t('vehicles.vehicleOperation.inspection.form.documentPhoto.labelBack'),
            validation: Yup.object()
                .shape({
                    name: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    fileKey: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    url: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                })
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            isRequired: true,
            acceptedFileTypes: 'image/jpeg, image/png, application/pdf',
            className: 'vehicles-create-vehicle-inspection-form-inspection-photo-back',
            multipleFiles: false,
            placeholder: `${t('vehicles.vehicleOperation.inspection.form.documentPhoto.placeholderBack')}`,
            type: FormikFieldTypes.FILE,
        },
    ];
};
