import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './baseSlice';
import {TeamsByRole} from '../../utils/tokenDecodingUtils';

export interface ITeamsByRolesState extends IBaseDictionaryState {
    teamsByRoles: TeamsByRole;
}

export interface IChangeTeamsByRoles {
    teamsByRoles: TeamsByRole;
}

const initialState: ITeamsByRolesState = {
    teamsByRoles: {},
    isLoading: false,
    isInitialized: false,
    error: null,
};

const teamsByRolesSlice = createSlice({
    name: 'teamsByRoles',
    initialState: initialState,
    reducers: {
        changeTeamsByRoles: {
            reducer: (state: ITeamsByRolesState, action: PayloadAction<IChangeTeamsByRoles>) => {
                return {
                    teamsByRoles: action.payload.teamsByRoles,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(teamsByRoles: TeamsByRole | null) {
                return {
                    payload: {teamsByRoles: teamsByRoles},
                };
            },
        },
        changeIsTeamsByRolesLoading: {
            reducer: (state: ITeamsByRolesState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    teamsByRoles: state.teamsByRoles,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsTeamsByRolesInitialized: {
            reducer: (state: ITeamsByRolesState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    teamsByRoles: state.teamsByRoles,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setTeamsByRolesError: {
            reducer: (state: ITeamsByRolesState, action: PayloadAction<ISetError>) => {
                return {
                    teamsByRoles: state.teamsByRoles,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
    },
});

export const {changeTeamsByRoles, changeIsTeamsByRolesLoading, changeIsTeamsByRolesInitialized, setTeamsByRolesError} =
    teamsByRolesSlice.actions;

export default teamsByRolesSlice.reducer;
