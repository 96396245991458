import {neutral} from '../theme/themeBase/colors';
import createCache from '@emotion/cache';
import {Locale} from 'date-fns';
import locale from 'date-fns/locale/en-US';

type Document = any;

export function applyPagination<T = Document>(documents: T[], page: number, rowsPerPage: number): T[] {
    return documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

function descendingComparator(a: Record<string, any>, b: Record<string, any>, sortBy: string): number {
    // When compared to something undefined, always returns false.
    // This means that if a field does not exist from either element ('a' or 'b') the return will be 0.

    if (b[sortBy]! < a[sortBy]!) {
        return -1;
    }

    if (b[sortBy]! > a[sortBy]!) {
        return 1;
    }

    return 0;
}

function getComparator(sortDir: string, sortBy: string) {
    return sortDir === 'desc'
        ? (a: Record<string, any>, b: Record<string, any>) => descendingComparator(a, b, sortBy)
        : (a: Record<string, any>, b: Record<string, any>) => -descendingComparator(a, b, sortBy);
}

export function applySort<T = Record<string, any>>(documents: T[], sortBy: string, sortDir: 'asc' | 'desc'): T[] {
    const comparator = getComparator(sortDir, sortBy);
    const stabilizedThis = documents.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        // @ts-ignore
        const newOrder = comparator(a[0], b[0]);

        if (newOrder !== 0) {
            return newOrder;
        }

        // @ts-ignore
        return a[1] - b[1];
    });

    // @ts-ignore
    return stabilizedThis.map((el) => el[0]);
}

/* eslint-disable no-restricted-properties */
export const bytesToSize = (bytes: number, decimals = 2): string => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const codeStyle = {
    'code[class*="language-"]': {
        color: neutral[50],
        background: 'none',
        textShadow: '0 1px rgba(0, 0, 0, 0.3)',
        fontFamily: "'Roboto Mono', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
        fontSize: '14px',
        textAlign: 'left',
        whiteSpace: 'pre',
        wordSpacing: 'normal',
        wordBreak: 'normal',
        wordWrap: 'normal',
        lineHeight: '1.5',
        MozTabSize: '4',
        OTabSize: '4',
        tabSize: '4',
        WebkitHyphens: 'none',
        MozHyphens: 'none',
        msHyphens: 'none',
        hyphens: 'none',
    },
    'pre[class*="language-"]': {
        color: neutral[50],
        background: neutral[800],
        textShadow: '0 1px rgba(0, 0, 0, 0.3)',
        fontFamily: "'Roboto Mono', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
        fontSize: '14px',
        textAlign: 'left',
        whiteSpace: 'pre',
        wordSpacing: 'normal',
        wordBreak: 'normal',
        wordWrap: 'normal',
        lineHeight: '1.5',
        MozTabSize: '4',
        OTabSize: '4',
        tabSize: '4',
        WebkitHyphens: 'none',
        MozHyphens: 'none',
        msHyphens: 'none',
        hyphens: 'none',
        padding: '1em',
        margin: '.5em 0',
        overflow: 'auto',
        borderRadius: '8px',
    },
    ':not(pre) > code[class*="language-"]': {
        background: neutral[800],
        padding: '.1em',
        borderRadius: '.3em',
        whiteSpace: 'normal',
    },
    comment: {
        color: '#6272a4',
    },
    prolog: {
        color: '#6272a4',
    },
    doctype: {
        color: '#6272a4',
    },
    cdata: {
        color: '#6272a4',
    },
    punctuation: {
        color: '#f8f8f2',
    },
    '.namespace': {
        Opacity: '.7',
    },
    property: {
        color: '#ff79c6',
    },
    tag: {
        color: '#ff79c6',
    },
    constant: {
        color: '#ff79c6',
    },
    symbol: {
        color: '#ff79c6',
    },
    deleted: {
        color: '#ff79c6',
    },
    boolean: {
        color: '#bd93f9',
    },
    number: {
        color: '#bd93f9',
    },
    selector: {
        color: '#50fa7b',
    },
    'attr-name': {
        color: '#50fa7b',
    },
    string: {
        color: '#50fa7b',
    },
    char: {
        color: '#50fa7b',
    },
    builtin: {
        color: '#50fa7b',
    },
    inserted: {
        color: '#50fa7b',
    },
    operator: {
        color: '#f8f8f2',
    },
    entity: {
        color: '#f8f8f2',
        cursor: 'help',
    },
    url: {
        color: '#f8f8f2',
    },
    '.language-css .token.string': {
        color: '#f8f8f2',
    },
    '.style .token.string': {
        color: '#f8f8f2',
    },
    variable: {
        color: '#f8f8f2',
    },
    atrule: {
        color: '#f1fa8c',
    },
    'attr-value': {
        color: '#f1fa8c',
    },
    function: {
        color: '#f1fa8c',
    },
    'class-name': {
        color: '#f1fa8c',
    },
    keyword: {
        color: '#8be9fd',
    },
    regex: {
        color: '#ffb86c',
    },
    important: {
        color: '#ffb86c',
        fontWeight: 'bold',
    },
    bold: {
        fontWeight: 'bold',
    },
    italic: {
        fontStyle: 'italic',
    },
};

export const createEmotionCache = () => {
    return createCache({key: 'css'});
};

export const createResourceId = (): string => {
    const arr = new Uint8Array(12);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, (v) => v.toString(16).padStart(2, '0')).join('');
};

const formatDistanceLocale: Record<string, string> = {
    lessThanXSeconds: '{{count}}s',
    xSeconds: '{{count}}s',
    halfAMinute: '30s',
    lessThanXMinutes: '{{count}}m',
    xMinutes: '{{count}}m',
    aboutXHours: '{{count}}h',
    xHours: '{{count}}h',
    xDays: '{{count}}d',
    aboutXWeeks: '{{count}}w',
    xWeeks: '{{count}}w',
    aboutXMonths: '{{count}}m',
    xMonths: '{{count}}m',
    aboutXYears: '{{count}}y',
    xYears: '{{count}}y',
    overXYears: '{{count}}y',
    almostXYears: '{{count}}y',
};

export const customLocale: Locale = {
    ...locale,
    formatDistance: (token, count, options) => {
        options = options || {};

        const result = formatDistanceLocale[token].replace('{{count}}', count);

        if (options.addSuffix) {
            if (options.comparison > 0) {
                return 'in ' + result;
            } else {
                return result + ' ago';
            }
        }

        return result;
    },
};

// eslint-disable-next-line consistent-return
export function deepCopy(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
        return obj.reduce((arr, item, index) => {
            arr[index] = deepCopy(item);
            return arr;
        }, []);
    }

    if (obj instanceof Object) {
        return Object.keys(obj).reduce((newObj: any, key) => {
            newObj[key] = deepCopy(obj[key]);
            return newObj;
        }, {});
    }
}

export const fileToBase64 = (file: Blob): Promise<ArrayBuffer | string | null> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const getInitials = (name = ''): string =>
    name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('');

export const getRandomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const objFromArray = (arr: any[], key = 'id'): {} =>
    arr.reduce((accumulator, current) => {
        accumulator[current[key]] = current;
        return accumulator;
    }, {});

export const wait = (time: number): Promise<void> => new Promise((res) => setTimeout(res, time));
