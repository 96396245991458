import {PayloadAction} from '@reduxjs/toolkit';
import {
    IBaseReducerState,
    IFleetPartnerCourierListingOutput,
    IModelApiResponseViewObject,
    PAGINATION_ITEMS_PER_PAGE,
    createBaseReducerSlice,
    initApiCall,
} from 'palipali-panel-common-web';

export interface IVehicleAssignedCourierListingState extends IBaseReducerState {
    fleetPartnerCourierList: IFleetPartnerCourierListingOutput[] | null;
    currentVehicleAssignedCourierIdList: string[];
    assignedCourierListVehicleId: string | null;
    pagination: IPaginationParams;
    metadata: IModelApiResponseViewObject | null;
    couriersToAdd: string[];
    couriersToRemove: string[];
}

export interface ISetVehicleAssignedCourierListing {
    fleetPartnerCourierList: IFleetPartnerCourierListingOutput[] | null;
}

export interface ISetCurrentVehicleAssignedCourierIdListing {
    currentVehicleAssignedCourierIdList: string[];
}

export interface ISetCurrentVehicleAssignedCourierIdListing {
    currentVehicleAssignedCourierIdList: string[];
}
export interface ISetCurrentVehicleAssignedCourierIdListing {
    currentVehicleAssignedCourierIdList: string[];
}

export interface ISetVehicleAssignedCourierListVehicleId {
    assignedCourierListVehicleId: string | null;
}

const initialState: IVehicleAssignedCourierListingState = {
    fleetPartnerCourierList: [],
    currentVehicleAssignedCourierIdList: [],
    assignedCourierListVehicleId: null,
    pagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    couriersToAdd: [],
    couriersToRemove: [],
    metadata: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

export interface IPaginationParams {
    itemsPerPage: number;
    page: number;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

const vehicleAssignedCourierListingSlice = createBaseReducerSlice({
    name: 'vehicleAssignedCourierListing',
    initialState: initialState,
    reducers: {
        setAssignedCourierListVehicleId: (
            state: IVehicleAssignedCourierListingState,
            action: PayloadAction<ISetVehicleAssignedCourierListVehicleId>
        ) => {
            state.assignedCourierListVehicleId = action.payload.assignedCourierListVehicleId;
            state.couriersToAdd = [];
            state.couriersToRemove = [];
            state.currentVehicleAssignedCourierIdList = [];
        },
        setFleetPartnerCourierList: (
            state: IVehicleAssignedCourierListingState,
            action: PayloadAction<ISetVehicleAssignedCourierListing>
        ) => {
            state.fleetPartnerCourierList = action.payload.fleetPartnerCourierList;
        },
        setCurrentVehicleAssignedCourierIdList: (
            state: IVehicleAssignedCourierListingState,
            action: PayloadAction<ISetCurrentVehicleAssignedCourierIdListing>
        ) => {
            state.currentVehicleAssignedCourierIdList = action.payload.currentVehicleAssignedCourierIdList;
        },
        assignCouriersToVehicle: (state: IVehicleAssignedCourierListingState) => initApiCall(state),
        setMetadata: (state: IVehicleAssignedCourierListingState, action: PayloadAction<ISetMetadata>) => {
            state.metadata = action.payload.metadata;
        },
        setLoading: (state: IVehicleAssignedCourierListingState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state: IVehicleAssignedCourierListingState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setCouriersToAddList: (state: IVehicleAssignedCourierListingState, action: PayloadAction<string[]>) => {
            state.couriersToAdd = action.payload;
        },
        setCouriersToRemoveList: (state: IVehicleAssignedCourierListingState, action: PayloadAction<string[]>) => {
            state.couriersToRemove = action.payload;
        },
        addCourierToAddList: (state: IVehicleAssignedCourierListingState, action: PayloadAction<string>) => {
            state.couriersToAdd.push(action.payload);
        },
        addCourierToRemoveList: (state: IVehicleAssignedCourierListingState, action: PayloadAction<string>) => {
            state.couriersToRemove.push(action.payload);
        },
        removeCourierFromToAddList: (state: IVehicleAssignedCourierListingState, action: PayloadAction<string>) => {
            state.couriersToAdd = state.couriersToAdd.filter((courierId) => courierId !== action.payload);
        },
        removeCourierFromToRemoveList: (state: IVehicleAssignedCourierListingState, action: PayloadAction<string>) => {
            state.couriersToRemove = state.couriersToRemove.filter((courierId) => courierId !== action.payload);
        },
        resetToInitialState: () => {
            return {...initialState};
        },
        changeFleetPartnerCouriersListingPagination: (
            state: IVehicleAssignedCourierListingState,
            action: PayloadAction<IPaginationParams>
        ) => {
            state.pagination = action.payload;
            state.isLoading = true;
        },
        getFleetPartnerCouriersListing: {
            reducer: (state: IVehicleAssignedCourierListingState) => {
                return {
                    ...state,
                    isCourierVehicleListLoading: true,
                };
            },
            prepare: (pagination: any, courierActiveType: string, vehicleId?: string) => {
                return {
                    payload: vehicleId,
                };
            },
        },
        getCurrentVehicleAssignedCouriersListing: (state: IVehicleAssignedCourierListingState) => initApiCall(state),
    },
});

export const {
    setFleetPartnerCourierList,
    setMetadata,
    changeFleetPartnerCouriersListingPagination,
    getFleetPartnerCouriersListing,
    getCurrentVehicleAssignedCouriersListing,
    setAssignedCourierListVehicleId,
    setCurrentVehicleAssignedCourierIdList,
    setLoading,
    setError,
    setCouriersToAddList,
    setCouriersToRemoveList,
    addCourierToAddList,
    addCourierToRemoveList,
    removeCourierFromToAddList,
    removeCourierFromToRemoveList,
    resetToInitialState,
    assignCouriersToVehicle,
} = vehicleAssignedCourierListingSlice.actions;
export default vehicleAssignedCourierListingSlice.reducer;
