import {
    AlertType,
    DetailsSuccessActionsFunction,
    ICourierSettlementListingOutput,
    IRawRestQueryParams,
    ListSuccessActionsFunction,
    addAlert,
    createFetchDetailsEpic,
    createFetchListEpic,
    flattenObj,
    getCourierSettlementAPI,
    getCourierSettlementCurrentAPI,
    getCourierSettlementProcessingAPI,
    handleApiError,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {RootState} from '../reducers';
import {
    IModelApiResponseExtrasObject,
    changeSettlementsListingPagination,
    fetchCurrentSettlement,
    fetchProcessingSettlement,
    getSettlementListing,
    setCurrentSettlement,
    setError,
    setLoading,
    setProcessingSettlement,
    setSettlementListing,
    setSettlementsListingMetadata,
} from '../reducers/settlementListingSlice';
import {settlementListingFiltersSelector, settlementListingPaginationSelector} from '../selectors/settlementListingSelectors';

const getParameters = (state: RootState): IRawRestQueryParams => {
    const filter = settlementListingFiltersSelector(state),
        pagination = settlementListingPaginationSelector(state);

    const params = {
        ...filter,
        ...pagination,
    };

    return params ? flattenObj(params) : [];
};

const errorActions = (error: any) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const settlementListingSuccessActions: ListSuccessActionsFunction<ICourierSettlementListingOutput> = (
    settlementsArray: ICourierSettlementListingOutput[],
    metadata: IModelApiResponseExtrasObject | null,
    extrasMetadata?: any
) => {
    if (extrasMetadata) {
        metadata = {...metadata, ...extrasMetadata};
    }
    return [
        setSettlementListing({settlementList: settlementsArray}),
        setSettlementsListingMetadata({metadata: metadata}),
        setLoading(false),
    ];
};

const getCurrentSettlementEpicSuccessActions: DetailsSuccessActionsFunction<ICourierSettlementListingOutput | null> = (
    currentSettlement: ICourierSettlementListingOutput | null
) => {
    return [setCurrentSettlement(currentSettlement)];
};

const getProcessingSettlementEpicSuccessActions: DetailsSuccessActionsFunction<ICourierSettlementListingOutput | null> = (
    currentSettlement: ICourierSettlementListingOutput | null
) => {
    return [setProcessingSettlement(currentSettlement)];
};

const getCurrentSettlementEpic = createFetchDetailsEpic<ICourierSettlementListingOutput | null>(
    getCourierSettlementCurrentAPI,
    getCurrentSettlementEpicSuccessActions,
    errorActions,
    fetchCurrentSettlement().type
);

const getProcessingSettlementEpic = createFetchDetailsEpic<ICourierSettlementListingOutput | null>(
    getCourierSettlementProcessingAPI,
    getProcessingSettlementEpicSuccessActions,
    errorActions,
    fetchProcessingSettlement().type
);

const getCourierSettlementsEpic = createFetchListEpic<ICourierSettlementListingOutput>(
    getCourierSettlementAPI,
    settlementListingSuccessActions,
    errorActions,
    getSettlementListing().type,
    (state: RootState) => getParameters(state)
);

const changeSettlementsPaginationEpic = createFetchListEpic<ICourierSettlementListingOutput>(
    getCourierSettlementAPI,
    settlementListingSuccessActions,
    errorActions,
    changeSettlementsListingPagination().type,
    (state: RootState) => getParameters(state)
);

const settlementListingEpic = combineEpics(
    getCourierSettlementsEpic,
    getCurrentSettlementEpic,
    getProcessingSettlementEpic,
    changeSettlementsPaginationEpic
);

export default settlementListingEpic;
