import {Button, Stack, useTheme} from '@mui/material';
import {
    FormikForm,
    IFileOutput,
    IFileUploadListElement,
    IInsuranceInput,
    HorizontalCard,
    Translation,
    isNotNullOrUndefined,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateVehicleInsurance} from '../../../../store/reducers/vehicleOperationSlice';
import {vehicleInsuranceFormConfig} from './vehicleInsuranceFormConfig';
import {canUpdateInsuranceSelector, vehicleInsuranceSelector} from 'src/store/selectors/vehicleOperationSelector';
import {useParams} from 'react-router';
import VehicleDocumentStatusBadge from '../../shared/VehicleDocumentStatusBadge';

interface IInsuranceValues {
    number: string;
    until: string;
    files: IFileUploadListElement | undefined;
}

const VehicleInsuranceCard: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const initialValues: IInsuranceValues = {
            number: '',
            until: '',
            files: undefined,
        },
        currentInsurance = useSelector(vehicleInsuranceSelector),
        canUpdateInsurance = useSelector(canUpdateInsuranceSelector),
        [updatedValues, setUpdatedValues] = useState<IInsuranceValues>(initialValues);
    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    const theme = useTheme();
    const params = useParams(),
        vehicleId = params.id;

    const updateInsurance = (values: IInsuranceValues) => {
        if (!values.files || !values.number || !values.until) return;
        const fileString = values.files.fileKey.toString();
        const filesString: string[] = [];
        filesString.push(fileString);
        const insurancePayload: IInsuranceInput = {
            number: values.number,
            until: new Date(values.until).toISOString(),
            files: filesString,
        };

        dispatch(updateVehicleInsurance(insurancePayload, vehicleId));
    };

    const handleChange = (formControl: string, value: string) => {
        setUpdatedValues({
            ...updatedValues,
            [formControl]: value,
        });
    };

    useEffect(() => {
        if (currentInsurance) {
            const files: IFileUploadListElement[] = currentInsurance.files.map((file: IFileOutput) => ({
                name: file.fileName,
                fileKey: file.id,
                url: file.contentUrl ? file.contentUrl : file.fileUrls?.original,
            }));

            setUpdatedValues({
                number: currentInsurance.number,
                until: currentInsurance.until,
                files: files[0],
            });
        }
    }, [currentInsurance]);

    const isFormUpdated = () => {
        const currentInsuranceFiles = currentInsurance?.files[0]?.id ? currentInsurance?.files[0]?.id : '',
            updatedInspectionFiles = updatedValues.files?.fileKey ? updatedValues.files?.fileKey : '';
        return (
            updatedValues.until !== currentInsurance.until ||
            updatedValues.number !== currentInsurance.number ||
            updatedInspectionFiles !== currentInsuranceFiles
        );
    };

    const canUpdate = () => {
        if (!currentInsurance) return isNotNullOrUndefined(updatedValues.files);
        return isFormUpdated();
    };

    return (
        <HorizontalCard
            heading={
                <Stack className="vehicle-document-status-badge">
                    <Translation text="vehicles.vehicleDetails.insuranceSettings.title" />
                    <VehicleDocumentStatusBadge
                        expiring={currentInsurance?.upcomingExpiration !== null && currentInsurance?.upcomingExpiration === true}
                        status={currentInsurance?.status}
                        hasDocument={currentInsurance !== null}
                    />
                </Stack>
            }
            content={
                <FormikForm
                    fields={vehicleInsuranceFormConfig()}
                    formId="insurance-form"
                    initialValues={initialValues}
                    updatedValues={updatedValues}
                    customEventChange={handleChange}
                    submitAllowed={setIsAllowed}
                    theme={theme}
                    onSubmit={updateInsurance}
                />
            }
            actions={
                <Button
                    className="btn-action vehicles-vehicle-details-insurance-update-button"
                    type="submit"
                    size="medium"
                    disabled={!isAllowed || !canUpdate() || !canUpdateInsurance}
                    form="insurance-form">
                    <Translation text="buttons.update" />
                </Button>
            }
        />
    );
};

export default VehicleInsuranceCard;
