import {PayloadAction} from '@reduxjs/toolkit';
import {
    createBaseReducerSlice,
    IBaseReducerState,
    IFleetPartnerCourierListingOutput,
    IModelApiResponseViewObject,
    initApiCall,
    IInviteFleetPartnerCourierInput,
    PAGINATION_ITEMS_PER_PAGE,
    IVehicleListingOutput,
} from 'palipali-panel-common-web';
import {IModelApiResponseExtrasObject} from './settlementListingSlice';

export interface IPaginationParams {
    itemsPerPage: number;
    page: number;
}
export enum CourierFilterStatus {
    ALL = 'all',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    INVITED = 'invited',
}
export interface ICourierListingState extends IBaseReducerState {
    courierList: IFleetPartnerCourierListingOutput[] | null;
    pagination: IPaginationParams;
    courierFilters: ICourierFilters | null;
    redirectToCouriersList: boolean;
    metadata: IModelApiResponseViewObject | null;
    canBeUnassigned: boolean;
    canBeUnassignedCheckInProgress: boolean;
    isCourierVehicleListLoading: boolean;
    courierVehiclesList: IVehicleListingOutput[] | null;
    courierVehiclesListPagination: IPaginationParams;
    courierVehiclesListMetadata: IModelApiResponseViewObject | null;
    courierAssignedVehicleIds: string[] | null;
}

export interface ISetCourierList {
    courierList: IFleetPartnerCourierListingOutput[] | null;
}

export interface ISetCourierVehiclesList {
    courierVehiclesList: IVehicleListingOutput[] | null;
}

export interface ICourierFilters {
    city?: string[];
    online?: boolean;
    search?: string;
    verified?: CourierFilterStatus;
    'createdAt[before]'?: string | Date | null;
    'createdAt[after]'?: string | Date | null;
}

export interface ISetMetadata {
    metadata: IModelApiResponseExtrasObject | null;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

export interface IChangeSearchFilter {
    search: string;
}

export interface IChangeCanBeUnassigned {
    canBeUnassigned: boolean;
}

export interface ISetRedirectToCouriersList {
    redirectToCouriersList: boolean;
}

export interface IChangeCourierAssignedVehicles {
    courierAssignedVehicleIds: string[] | null;
}

const initialState: ICourierListingState = {
    courierList: null,
    pagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    metadata: null,
    courierFilters: {
        verified: CourierFilterStatus.ALL,
    },
    canBeUnassigned: false,
    courierVehiclesList: null,
    courierVehiclesListPagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    courierVehiclesListMetadata: null,
    isInitialized: false,
    isLoading: false,
    isCourierVehicleListLoading: false,
    redirectToCouriersList: false,
    error: null,
    canBeUnassignedCheckInProgress: false,
    courierAssignedVehicleIds: null,
};

const courierListingSlice = createBaseReducerSlice({
    name: 'courierList',
    initialState: initialState,
    reducers: {
        fetchCouriersList: (state: ICourierListingState) => initApiCall(state),
        fetchCourierVehiclesList: {
            reducer: (state: ICourierListingState) => {
                return {
                    ...state,
                    isCourierVehicleListLoading: true,
                };
            },
            prepare: (courierId?: string) => {
                return {
                    payload: courierId,
                };
            },
        },
        setCouriersList: (state: ICourierListingState, action: PayloadAction<ISetCourierList>) => {
            state.courierList = action.payload.courierList;
        },
        setCourierVehiclesList: (state: ICourierListingState, action: PayloadAction<ISetCourierVehiclesList>) => {
            state.courierVehiclesList = action.payload.courierVehiclesList;
        },
        setMetadata: (state: ICourierListingState, action: PayloadAction<ISetMetadata>) => {
            state.metadata = action.payload.metadata;
        },
        setCourierVehiclesListMetadata: (state: ICourierListingState, action: PayloadAction<ISetMetadata>) => {
            state.courierVehiclesListMetadata = action.payload.metadata;
        },
        setCourierVehicleListLoading: (state: ICourierListingState, action: PayloadAction<boolean>) => {
            state.isCourierVehicleListLoading = action.payload;
        },
        inviteCourier: {
            reducer: (state: ICourierListingState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (id: string, courier: IInviteFleetPartnerCourierInput) => {
                return {
                    payload: {
                        id: id,
                        courier: courier,
                    },
                };
            },
        },
        canUnassignCourier: (state: ICourierListingState) => {
            return {
                ...state,
                canBeUnassignedCheckInProgress: true,
            };
        },
        setCanBeUnassigned: {
            reducer: (state: ICourierListingState, action: PayloadAction<IChangeCanBeUnassigned>) => {
                return {
                    ...state,
                    canBeUnassigned: action.payload.canBeUnassigned,
                    canBeUnassignedCheckInProgress: false,
                };
            },
            prepare: (canBeUnassigned: boolean) => {
                return {
                    payload: {
                        canBeUnassigned: canBeUnassigned,
                    },
                };
            },
        },
        unassignCourier: {
            reducer: (state: ICourierListingState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (courierId: string, id: string) => {
                return {
                    payload: {
                        courierId: courierId,
                        id: id,
                    },
                };
            },
        },
        changeCourierListingPagination: (state: ICourierListingState, action: PayloadAction<IPaginationParams>) => {
            state.pagination = action.payload;
            state.isLoading = true;
        },
        changeCourierVehiclesListPagination: (state: ICourierListingState, action: PayloadAction<IPaginationParams>) => {
            state.courierVehiclesListPagination = action.payload;
            state.isLoading = true;
        },
        changeCourierListingCityFilter: (state: ICourierListingState, action: PayloadAction<string[]>) => {
            state.courierFilters = {
                ...state.courierFilters,
                city: action.payload,
            };
            state.isLoading = true;
        },
        changeCourierSearchFilter: (state: ICourierListingState, action: PayloadAction<IChangeSearchFilter>) => {
            state.courierFilters = {
                ...state.courierFilters,
                search: action.payload.search,
            };
            state.isLoading = true;
        },
        changeCourierVerifiedFilter: (state: ICourierListingState, action: PayloadAction<CourierFilterStatus>) => {
            state.courierFilters = {
                ...state.courierFilters,
                verified: action.payload,
            };
            state.isLoading = true;
        },
        assignVehiclesToCourier: {
            reducer: (state: ICourierListingState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (courierId: string, addVehicleIds: string[], removeVehicleIds: string[]) => {
                return {
                    payload: {
                        courierId: courierId,
                        addVehicleIds: addVehicleIds,
                        removeVehicleIds: removeVehicleIds,
                    },
                };
            },
        },
        redirectToCouriersList: {
            reducer: (state: ICourierListingState, action: PayloadAction<ISetRedirectToCouriersList>) => {
                return {
                    ...state,
                    redirectToCouriersList: action.payload.redirectToCouriersList,
                };
            },
            prepare: (redirectToCouriersList: boolean) => {
                return {
                    payload: {
                        redirectToCouriersList: redirectToCouriersList,
                    },
                };
            },
        },
        fetchCourierAssignedVehicleIds: {
            reducer: (state: ICourierListingState) => {
                return {
                    ...state,
                    isCourierVehicleListLoading: true,
                };
            },
            prepare: (courierId: string) => {
                return {
                    payload: {
                        courierId: courierId,
                    },
                };
            },
        },
        setCourierAssignedVehicles: {
            reducer: (state: ICourierListingState, action: PayloadAction<IChangeCourierAssignedVehicles>) => {
                return {
                    ...state,
                    isCourierVehicleListLoading: false,
                    courierAssignedVehicleIds: action.payload.courierAssignedVehicleIds,
                };
            },
            prepare: (courierAssignedVehicleIds: string[] | null) => {
                return {
                    payload: {
                        courierAssignedVehicleIds: courierAssignedVehicleIds,
                    },
                };
            },
        },
    },
});

export const {
    fetchCouriersList,
    setCouriersList,
    setCanBeUnassigned,
    inviteCourier,
    setInitialized,
    setLoading,
    setMetadata,
    canUnassignCourier,
    changeCourierListingPagination,
    changeCourierListingCityFilter,
    changeCourierSearchFilter,
    changeCourierVerifiedFilter,
    setError,
    redirectToCouriersList,
    unassignCourier,
    fetchCourierVehiclesList,
    setCourierVehiclesListMetadata,
    setCourierVehicleListLoading,
    changeCourierVehiclesListPagination,
    setCourierVehiclesList,
    assignVehiclesToCourier,
    setCourierAssignedVehicles,
    fetchCourierAssignedVehicleIds,
} = courierListingSlice.actions;
export default courierListingSlice.reducer;
