import {Box, Card, CardContent, CardHeader, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {ConfirmInvitationStatus, confirmFleetPartnerInvitation} from '../../../store/reducers/confirmInvitationSlice';
import {fleetPartnerInvitationConfirmActionStatusSelector} from '../../../store/selectors/confirmInvitationSelectors';
import {ICourierSuccessPageProps, LoaderType} from '../../../types';
import Loader from '../../Loader';
import Translation from '../../Translation';

type Props = ICourierSuccessPageProps;

const CourierSuccessPage: React.FC<Props> = ({invitationToFleetPartner, isLoading}) => {
    const {id} = useParams(),
        location = useLocation(),
        dispatch = useDispatch(),
        {t} = useTranslation(),
        confirmActionStatus = useSelector(fleetPartnerInvitationConfirmActionStatusSelector);

    useEffect(() => {
        if (invitationToFleetPartner && id && !confirmActionStatus) {
            const actionValue = new URLSearchParams(location.search).get('action') === 'accept' ? true : false;
            dispatch(confirmFleetPartnerInvitation(id, actionValue));
        }
    }, [confirmActionStatus, id, location.search]);

    const getConfirmStatus = () => {
        if (!invitationToFleetPartner) {
            return <Translation text="auth.courierSuccessPage.subtitle" />;
        }
        if (confirmActionStatus === null) {
            return <Translation text="auth.courierSuccessPage.loading" />;
        }
        return (
            <Translation
                text={`auth.courierSuccessPage.${confirmActionStatus === ConfirmInvitationStatus.CONFIRMED ? 'success' : 'failure'}`}
            />
        );
    };

    return (
        <Card elevation={16}>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <CardHeader sx={{pb: 0, textAlign: 'center'}} title={t('auth.courierSuccessPage.title')} />
            <CardContent>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <Typography color="text.primary" variant="body1" key="subheader">
                        {getConfirmStatus()}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default CourierSuccessPage;
