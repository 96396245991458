import {PayloadAction} from '@reduxjs/toolkit';
import {
    createBaseReducerSlice,
    ICreateVehicleInput,
    IBaseReducerState,
    IMultiselectOption,
    initApiCall,
    IInspectionInput,
    IInsuranceInput,
    IFileUploadListElement,
    IVehicleDetailsOutput,
} from 'palipali-panel-common-web';

export const enum VehicleOperationStep {
    BASIC_INFORMATION = 'basicInformation',
    INSPECTION = 'inspection',
    INSURANCE = 'insurance',
}

export const vehicleOperationStepArray = [
    VehicleOperationStep.BASIC_INFORMATION,
    VehicleOperationStep.INSPECTION,
    VehicleOperationStep.INSURANCE,
];

const enum VehicleOperationType {
    EDIT = 'edit',
    CREATE = 'create',
}

export type CreateVehicleBaseInformationInput = Omit<ICreateVehicleInput, 'insurance' | 'inspection'>;

export interface IVehicleBasicInformation extends CreateVehicleBaseInformationInput {
    vehicleType: string | null;
}

export interface IVehicleInspection {
    readonly until: string;
    readonly files: IFileUploadListElement[];
}

export interface IVehicleInsurance {
    readonly number: string;
    readonly until: string;
    readonly files: IFileUploadListElement[];
}

export interface IVehicleOperationState extends IBaseReducerState {
    currentlyEditedVehicle: IVehicleDetailsOutput | null;
    basicInformation: IVehicleBasicInformation | null;
    inspection: IVehicleInspection | null;
    insurance: IVehicleInsurance | null;
    currentStep: VehicleOperationStep;
    currentOperation: VehicleOperationType;
    currentlyDeletedVehicleId: string | null;
    canDeleteCurrentVehicle: boolean;
    canUpdateInspection: boolean;
    canUpdateInsurance: boolean;
    availableMakes: IMultiselectOption[] | null;
    availableModels: IMultiselectOption[] | null;
    availableProductionYears: number[] | null;
    colorList: IMultiselectOption[] | null;
    vehicleTypeList: IMultiselectOption[] | null;
    redirectToVehicleList: boolean;
    isLoading: boolean;
}

export interface ISetCurrentOperation {
    currentOperation: VehicleOperationType;
}

export interface ISetCurrentlyDeletedVehicleId {
    currentlyDeletedVehicleId: string | null;
}

export interface ISetRedirectToVehicleList {
    redirectToVehicleList: boolean;
}

const initialState: IVehicleOperationState = {
    currentlyEditedVehicle: null,
    basicInformation: null,
    inspection: null,
    insurance: null,
    currentStep: VehicleOperationStep.BASIC_INFORMATION,
    currentOperation: VehicleOperationType.CREATE,
    currentlyDeletedVehicleId: null,
    canDeleteCurrentVehicle: false,
    canUpdateInspection: false,
    canUpdateInsurance: false,
    availableMakes: null,
    availableModels: null,
    availableProductionYears: null,
    colorList: null,
    vehicleTypeList: null,
    isLoading: false,
    isInitialized: false,
    error: null,
    redirectToVehicleList: false,
};

export interface ICreateVehicle {
    vehicle: ICreateVehicleInput;
}

export const createSimpleReducer =
    <K extends keyof IVehicleOperationState, T extends IVehicleOperationState[K]>(key: K) =>
    (state: IVehicleOperationState, action: PayloadAction<T>) => {
        state[key] = action.payload;
    };

const vehicleOperationSlice = createBaseReducerSlice({
    name: 'vehicleOperation',
    initialState: initialState,
    reducers: {
        setCurrentOperation: createSimpleReducer('currentOperation'),
        setCurrentStep: createSimpleReducer('currentStep'),
        setCurrentlyEditedVehicle: createSimpleReducer('currentlyEditedVehicle'),
        setAvailableMakes: createSimpleReducer('availableMakes'),
        setAvailableModels: createSimpleReducer('availableModels'),
        setAvailableProductionYears: createSimpleReducer('availableProductionYears'),
        setColorList: createSimpleReducer('colorList'),
        setBasicInformation: createSimpleReducer('basicInformation'),
        setInspection: createSimpleReducer('inspection'),
        setLoading: createSimpleReducer('isLoading'),
        setInsurance: createSimpleReducer('insurance'),
        setVehicleTypeList: createSimpleReducer('vehicleTypeList'),
        getVehicleOperation: (state: IVehicleOperationState) => initApiCall(state),
        setCurrentlyDeletedVehicle: createSimpleReducer('currentlyDeletedVehicleId'),
        setCanDeleteCurrentVehicle: createSimpleReducer('canDeleteCurrentVehicle'),
        changeCanUpdateInspection: createSimpleReducer('canUpdateInspection'),
        changeCanUpdateInsurance: createSimpleReducer('canUpdateInsurance'),
        resetVehicleOperationState: {
            reducer: () => initialState,
            prepare: () => ({payload: null}),
        },
        createVehicle: {
            reducer: (state: IVehicleOperationState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(vehicle: ICreateVehicle) {
                return {
                    payload: vehicle,
                };
            },
        },
        checkDeleteVehiclePossibility: {
            reducer: (state: IVehicleOperationState, action: PayloadAction<ISetCurrentlyDeletedVehicleId>) => {
                return {
                    ...state,
                    currentlyDeletedVehicleId: action.payload.currentlyDeletedVehicleId,
                    isLoading: true,
                };
            },
            prepare(currentlyDeletedVehicleId: string | null) {
                return {
                    payload: {currentlyDeletedVehicleId: currentlyDeletedVehicleId},
                };
            },
        },
        deleteVehicle: {
            reducer: (state: IVehicleOperationState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(vehicleId: string) {
                return {
                    payload: vehicleId,
                };
            },
        },
        checkUpdateVehicleInsurancePossibility: {
            reducer: (state: IVehicleOperationState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(id: string | null) {
                return {
                    payload: {id: id},
                };
            },
        },
        updateVehicleInsurance: {
            reducer: (state: IVehicleOperationState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(insurance: IInsuranceInput, vehicleId: string) {
                return {
                    payload: {vehicleInsurance: insurance, id: vehicleId},
                };
            },
        },
        checkUpdateVehicleInspectionPossibility: {
            reducer: (state: IVehicleOperationState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(id: string | null) {
                return {
                    payload: {id: id},
                };
            },
        },
        updateVehicleInspection: {
            reducer: (state: IVehicleOperationState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(inspection: IInspectionInput, vehicleId: string) {
                return {
                    payload: {vehicleInspection: inspection, id: vehicleId},
                };
            },
        },
        setRedirectToVehicleList: {
            reducer: (state: IVehicleOperationState, action: PayloadAction<ISetRedirectToVehicleList>) => {
                return {
                    ...state,
                    redirectToVehicleList: action.payload.redirectToVehicleList,
                };
            },
            prepare(redirectToVehicleList: boolean) {
                return {
                    payload: {redirectToVehicleList: redirectToVehicleList},
                };
            },
        },
        fetchVehicleDetails: {
            reducer: (state: IVehicleOperationState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(vehicleId: string) {
                return {
                    payload: {vehicleId: vehicleId},
                };
            },
        },
    },
});

export const {
    setVehicleOperation,
    setLoading,
    setError,
    setInitialized,
    setBasicInformation,
    setInspection,
    setInsurance,
    setCurrentOperation,
    setCurrentStep,
    setCurrentlyEditedVehicle,
    setCanDeleteCurrentVehicle,
    setCurrentlyDeletedVehicle,
    setAvailableMakes,
    setAvailableModels,
    setAvailableProductionYears,
    setColorList,
    setVehicleTypeList,
    setRedirectToVehicleList,
    getVehicleOperation,
    createVehicle,
    resetVehicleOperationState,
    checkDeleteVehiclePossibility,
    deleteVehicle,
    fetchVehicleDetails,
    updateVehicleInsurance,
    updateVehicleInspection,
    changeCanUpdateInspection,
    changeCanUpdateInsurance,
    checkUpdateVehicleInsurancePossibility,
    checkUpdateVehicleInspectionPossibility,
} = vehicleOperationSlice.actions;
export default vehicleOperationSlice.reducer;
