import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../reducers';
import {ITeamsByRolesState} from '../reducers/teamsByRolesSlice';
import {UserRole} from '../../types';
import {TeamsByRole} from '../../utils/tokenDecodingUtils';

export const selectTeamsByRoles = (state: CommonRootState): ITeamsByRolesState => {
    return state.teamsByRoles;
};

export const teamsByRolesSelector = createSelector([selectTeamsByRoles], (state: ITeamsByRolesState) => state.teamsByRoles);

export const teamsByRolesLoadingSelector = createSelector([selectTeamsByRoles], (state: ITeamsByRolesState) => state.isLoading);

export const teamsByRolesInitializedSelector = createSelector([selectTeamsByRoles], (state: ITeamsByRolesState) => state.isInitialized);

export const teamsByRolesErrorSelector = createSelector([selectTeamsByRoles], (state: ITeamsByRolesState) => state.error);

export const teamsFromSpecificRoleSelector = (role: UserRole) =>
    createSelector([teamsByRolesSelector], (teamsByRoles: TeamsByRole) => {
        return teamsByRoles[role] || [];
    });

export const teamsFromOwnerOrManagerRoleSelector = createSelector(
    [teamsFromSpecificRoleSelector(UserRole.PURCHASER_TEAM_OWNER), teamsFromSpecificRoleSelector(UserRole.PURCHASER_TEAM_MANAGER)],
    (ownerTeams, managerTeams) => Array.from(new Set([...ownerTeams, ...managerTeams]))
);

export const teamsAnyTeamRoleSelector = createSelector(
    [
        teamsFromSpecificRoleSelector(UserRole.PURCHASER_TEAM_OWNER),
        teamsFromSpecificRoleSelector(UserRole.PURCHASER_TEAM_MANAGER),
        teamsFromSpecificRoleSelector(UserRole.PURCHASER_TEAM_EMPLOYEE),
    ],
    (ownerTeams, managerTeams, employeeTeams) => Array.from(new Set([...ownerTeams, ...managerTeams, ...employeeTeams]))
);

export const firstTeamFromOwnerOrManagerRoleSelector = createSelector([teamsFromOwnerOrManagerRoleSelector], (teams) =>
    teams.length > 0 ? teams[0] : undefined
);

export const isUserTeamOwnerSelector = (teamId: string) =>
    createSelector([teamsFromSpecificRoleSelector(UserRole.PURCHASER_TEAM_OWNER)], (teams: string[]) => {
        return teams.includes(teamId);
    });

export const isUserTeamManagerSelector = (teamId: string) =>
    createSelector([teamsFromSpecificRoleSelector(UserRole.PURCHASER_TEAM_MANAGER)], (teams: string[]) => {
        return teams.includes(teamId);
    });
