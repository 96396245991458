import type {FC} from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import Settings03Icon from '@untitled-ui/icons-react/build/esm/Settings03';
import {Box, ButtonBase, SvgIcon, Theme} from '@mui/material';

interface SettingsButtonProps {
    onClick?: () => void;
}

const SettingsButton: FC<SettingsButtonProps> = ({onClick}) => (
    <Box
        sx={{
            backgroundColor: 'background.paper',
            borderRadius: '50%',
            bottom: 0,
            boxShadow: 16,
            margin: (theme: Theme) => theme.spacing(4),
            position: 'fixed',
            right: 0,
            zIndex: (theme: Theme) => theme.zIndex.speedDial,
        }}>
        <ButtonBase
            onClick={onClick}
            sx={{
                backgroundColor: 'primary.main',
                borderRadius: '50%',
                color: 'primary.contrastText',
                p: '10px',
            }}>
            <SvgIcon>
                <Settings03Icon />
            </SvgIcon>
        </ButtonBase>
    </Box>
);

SettingsButton.propTypes = {
    onClick: PropTypes.func,
};

export default SettingsButton;
