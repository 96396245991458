import {CustomIconProps} from '../../../types/iconTypes';
import React from 'react';

const NoOnboardingIcon = (props: CustomIconProps) => (
    <svg width="135" height="133" viewBox="0 0 135 133" fill="none" {...props}>
        <path
            d="M60.2823 132.69C93.2816 132.69 120.033 105.938 120.033 72.939C120.033 39.9397 93.2816 13.1885 60.2823 13.1885C27.283 13.1885 0.531738 39.9397 0.531738 72.939C0.531738 105.938 27.283 132.69 60.2823 132.69Z"
            fill="url(#paint0_linear_38_128194)"
        />
        <path
            d="M92.5173 27.0918H26.3263C22.2006 27.0918 18.856 30.4364 18.856 34.5622H99.9877C99.9877 30.4364 96.6431 27.0918 92.5173 27.0918Z"
            fill="url(#paint1_linear_38_128194)"
        />
        <path
            d="M18.856 34.3584H99.9877V96.1497C99.9877 98.897 98.8963 101.532 96.9537 103.474C95.0111 105.417 92.3764 106.508 89.6291 106.508H29.2146C26.4673 106.508 23.8325 105.417 21.8899 103.474C19.9473 101.532 18.856 98.897 18.856 96.1497V34.3584Z"
            fill="url(#paint2_linear_38_128194)"
        />
        <path
            d="M86.0413 42.1631H32.8279C31.7197 42.1631 30.8213 43.0605 30.8213 44.1675C30.8213 45.2745 31.7197 46.1719 32.8279 46.1719H86.0413C87.1495 46.1719 88.0478 45.2745 88.0478 44.1675C88.0478 43.0605 87.1495 42.1631 86.0413 42.1631Z"
            fill="url(#paint3_linear_38_128194)"
        />
        <path
            d="M86.0413 59.1943H32.8279C31.7197 59.1943 30.8213 60.0917 30.8213 61.1987C30.8213 62.3057 31.7197 63.2031 32.8279 63.2031H86.0413C87.1495 63.2031 88.0478 62.3057 88.0478 61.1987C88.0478 60.0917 87.1495 59.1943 86.0413 59.1943Z"
            fill="url(#paint4_linear_38_128194)"
        />
        <path
            d="M86.0413 76.2266H32.8279C31.7197 76.2266 30.8213 77.124 30.8213 78.231C30.8213 79.338 31.7197 80.2354 32.8279 80.2354H86.0413C87.1495 80.2354 88.0478 79.338 88.0478 78.231C88.0478 77.124 87.1495 76.2266 86.0413 76.2266Z"
            fill="url(#paint5_linear_38_128194)"
        />
        <path
            d="M86.0417 93.2588H32.8284C31.7202 93.2588 30.8218 94.1562 30.8218 95.2632C30.8218 96.3702 31.7202 97.2676 32.8284 97.2676H86.0417C87.15 97.2676 88.0483 96.3702 88.0483 95.2632C88.0483 94.1562 87.15 93.2588 86.0417 93.2588Z"
            fill="url(#paint6_linear_38_128194)"
        />
        <path
            d="M32.4678 72.5732H4.13248C1.85611 72.5732 0.0107422 74.4186 0.0107422 76.695V117.196C0.0107422 119.472 1.85611 121.317 4.13248 121.317H32.4678C34.7442 121.317 36.5895 119.472 36.5895 117.196V76.695C36.5895 74.4186 34.7442 72.5732 32.4678 72.5732Z"
            fill="url(#paint7_linear_38_128194)"
        />
        <g filter="url(#filter0_d_38_128194)">
            <path
                d="M113.129 9.31055H81.8321C80.3737 9.31055 79.1914 10.4928 79.1914 11.9512V26.6618C79.1914 28.1202 80.3737 29.3025 81.8321 29.3025H113.129C114.588 29.3025 115.77 28.1202 115.77 26.6618V11.9512C115.77 10.4928 114.588 9.31055 113.129 9.31055Z"
                fill="url(#paint8_linear_38_128194)"
            />
        </g>
        <path
            d="M29.3058 92.835H7.29421C6.55301 92.835 5.95215 93.4358 5.95215 94.177V94.1814C5.95215 94.9226 6.55301 95.5234 7.29421 95.5234H29.3058C30.047 95.5234 30.6478 94.9226 30.6478 94.1814V94.177C30.6478 93.4358 30.047 92.835 29.3058 92.835Z"
            fill="url(#paint9_linear_38_128194)"
        />
        <path
            d="M29.3184 111.752H7.49606C6.76122 111.752 6.16553 112.334 6.16553 113.052V113.056C6.16553 113.774 6.76122 114.357 7.49606 114.357H29.3184C30.0533 114.357 30.649 113.774 30.649 113.056V113.052C30.649 112.334 30.0533 111.752 29.3184 111.752Z"
            fill="url(#paint10_linear_38_128194)"
        />
        <path
            d="M29.3184 101.854H7.49606C6.76122 101.854 6.16553 102.437 6.16553 103.155V103.159C6.16553 103.877 6.76122 104.459 7.49606 104.459H29.3184C30.0533 104.459 30.649 103.877 30.649 103.159V103.155C30.649 102.437 30.0533 101.854 29.3184 101.854Z"
            fill="url(#paint11_linear_38_128194)"
        />
        <path
            d="M15.3428 78.8281H8.91045C7.48562 78.8281 6.33057 79.9832 6.33057 81.408V83.1931C6.33057 84.6179 7.48562 85.773 8.91045 85.773H15.3428C16.7676 85.773 17.9227 84.6179 17.9227 83.1931V81.408C17.9227 79.9832 16.7676 78.8281 15.3428 78.8281Z"
            fill="url(#paint12_linear_38_128194)"
        />
        <path
            d="M86.9158 22.3404C88.5925 22.3404 89.9517 20.9812 89.9517 19.3045C89.9517 17.6278 88.5925 16.2686 86.9158 16.2686C85.2391 16.2686 83.8799 17.6278 83.8799 19.3045C83.8799 20.9812 85.2391 22.3404 86.9158 22.3404Z"
            fill="#6C737F"
        />
        <path
            d="M97.7649 22.3404C99.4416 22.3404 100.801 20.9812 100.801 19.3045C100.801 17.6278 99.4416 16.2686 97.7649 16.2686C96.0882 16.2686 94.729 17.6278 94.729 19.3045C94.729 20.9812 96.0882 22.3404 97.7649 22.3404Z"
            fill="#6C737F"
        />
        <path
            d="M108.615 22.3404C110.291 22.3404 111.65 20.9812 111.65 19.3045C111.65 17.6278 110.291 16.2686 108.615 16.2686C106.938 16.2686 105.579 17.6278 105.579 19.3045C105.579 20.9812 106.938 22.3404 108.615 22.3404Z"
            fill="#6C737F"
        />
        <path d="M100.383 94.7508L92.6938 103.228L114.367 122.886L122.056 114.409L100.383 94.7508Z" fill="url(#paint13_linear_38_128194)" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.509 80.7209C108.509 98.4545 94.1328 112.83 76.3991 112.83C58.6655 112.83 44.2896 98.4545 44.2896 80.7209C44.2896 62.9873 58.6655 48.6113 76.3991 48.6113C94.1328 48.6113 108.509 62.9873 108.509 80.7209ZM103.583 80.7209C103.583 95.7344 91.4123 107.905 76.3988 107.905C61.3853 107.905 49.2144 95.7344 49.2144 80.7209C49.2144 65.7074 61.3853 53.5366 76.3988 53.5366C91.4123 53.5366 103.583 65.7074 103.583 80.7209Z"
            fill="url(#paint14_linear_38_128194)"
        />
        <g filter="url(#filter1_b_38_128194)">
            <path
                d="M76.3987 107.905C91.4122 107.905 103.583 95.734 103.583 80.7205C103.583 65.707 91.4122 53.5361 76.3987 53.5361C61.3852 53.5361 49.2144 65.707 49.2144 80.7205C49.2144 95.734 61.3852 107.905 76.3987 107.905Z"
                fill="url(#paint15_linear_38_128194)"
                fillOpacity="0.2"
            />
        </g>
        <path
            d="M66.5 76.4995V88.4995M73.25 76.4995V88.4995M80.75 76.4995V88.4995M87.5 76.4995V88.4995M63.5 90.8995L63.5 92.0995C63.5 92.9396 63.5 93.3597 63.6635 93.6805C63.8073 93.9628 64.0368 94.1922 64.319 94.3361C64.6399 94.4995 65.0599 94.4995 65.9 94.4995H88.1C88.9401 94.4995 89.3601 94.4995 89.681 94.3361C89.9632 94.1922 90.1927 93.9628 90.3365 93.6805C90.5 93.3597 90.5 92.9396 90.5 92.0995V90.8995C90.5 90.0595 90.5 89.6394 90.3365 89.3186C90.1927 89.0363 89.9632 88.8068 89.681 88.663C89.3601 88.4995 88.9401 88.4995 88.1 88.4995H65.9C65.0599 88.4995 64.6399 88.4995 64.319 88.663C64.0368 88.8068 63.8073 89.0363 63.6635 89.3186C63.5 89.6394 63.5 90.0595 63.5 90.8995ZM76.4794 67.6152L65.3794 70.0819C64.7088 70.2309 64.3735 70.3054 64.1232 70.4857C63.9024 70.6448 63.7291 70.8609 63.6217 71.1109C63.5 71.3943 63.5 71.7378 63.5 72.4248L63.5 74.0995C63.5 74.9396 63.5 75.3597 63.6635 75.6805C63.8073 75.9628 64.0368 76.1922 64.319 76.3361C64.6399 76.4995 65.0599 76.4995 65.9 76.4995H88.1C88.9401 76.4995 89.3601 76.4995 89.681 76.3361C89.9632 76.1922 90.1927 75.9628 90.3365 75.6805C90.5 75.3597 90.5 74.9396 90.5 74.0995V72.4248C90.5 71.7378 90.5 71.3943 90.3783 71.1109C90.2709 70.8609 90.0976 70.6448 89.8768 70.4857C89.6265 70.3054 89.2912 70.2309 88.6206 70.0819L77.5206 67.6152C77.3263 67.5721 77.2292 67.5505 77.131 67.5419C77.0438 67.5342 76.9562 67.5342 76.869 67.5419C76.7708 67.5505 76.6737 67.5721 76.4794 67.6152Z"
            stroke="#4D5761"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g filter="url(#filter2_f_38_128194)">
            <ellipse cx="77.4487" cy="96" rx="19" ry="3" fill="#1F2A37" fillOpacity="0.56" />
        </g>
        <path
            d="M54.5847 117.722C55.0571 115.765 57.8404 115.765 58.3128 117.722C58.4816 118.421 59.0275 118.967 59.7268 119.136C61.6836 119.608 61.6836 122.392 59.7268 122.864C59.0275 123.033 58.4816 123.579 58.3128 124.278C57.8404 126.235 55.0571 126.235 54.5847 124.278C54.4159 123.579 53.8699 123.033 53.1706 122.864C51.2138 122.392 51.2138 119.608 53.1706 119.136C53.8699 118.967 54.4159 118.421 54.5847 117.722Z"
            fill="url(#paint16_linear_38_128194)"
        />
        <path
            d="M11.5847 17.7219C12.0571 15.7651 14.8404 15.7651 15.3128 17.7219C15.4816 18.4212 16.0275 18.9672 16.7268 19.136C18.6836 19.6083 18.6836 22.3917 16.7268 22.864C16.0275 23.0328 15.4816 23.5788 15.3128 24.2781C14.8404 26.2349 12.0571 26.2349 11.5847 24.2781C11.4159 23.5788 10.8699 23.0328 10.1706 22.864C8.21384 22.3917 8.21384 19.6083 10.1706 19.136C10.8699 18.9672 11.4159 18.4212 11.5847 17.7219Z"
            fill="url(#paint17_linear_38_128194)"
        />
        <mask id="path-24-inside-1_38_128194" fill="white">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.8998 15.0789C58.9072 15.0789 59.7239 14.2622 59.7239 13.2547C59.7239 12.2473 58.9072 11.4306 57.8998 11.4306C56.8923 11.4306 56.0756 12.2473 56.0756 13.2547C56.0756 14.2622 56.8923 15.0789 57.8998 15.0789ZM57.8998 16.447C59.6628 16.447 61.092 15.0178 61.092 13.2547C61.092 11.4917 59.6628 10.0625 57.8998 10.0625C56.1367 10.0625 54.7075 11.4917 54.7075 13.2547C54.7075 15.0178 56.1367 16.447 57.8998 16.447Z"
            />
        </mask>
        <path
            d="M57.0976 13.2547C57.0976 12.8117 57.4567 12.4526 57.8998 12.4526V17.7052C60.3577 17.7052 62.3502 15.7126 62.3502 13.2547H57.0976ZM57.8998 14.0569C57.4567 14.0569 57.0976 13.6978 57.0976 13.2547H62.3502C62.3502 10.7968 60.3577 8.8043 57.8998 8.8043V14.0569ZM58.7019 13.2547C58.7019 13.6978 58.3428 14.0569 57.8998 14.0569V8.8043C55.4419 8.8043 53.4493 10.7968 53.4493 13.2547H58.7019ZM57.8998 12.4526C58.3428 12.4526 58.7019 12.8117 58.7019 13.2547H53.4493C53.4493 15.7126 55.4419 17.7052 57.8998 17.7052V12.4526ZM58.4657 13.2547C58.4657 13.5673 58.2123 13.8207 57.8998 13.8207V19.0733C61.1132 19.0733 63.7183 16.4682 63.7183 13.2547H58.4657ZM57.8998 12.6888C58.2123 12.6888 58.4657 12.9422 58.4657 13.2547H63.7183C63.7183 10.0412 61.1132 7.4362 57.8998 7.4362V12.6888ZM57.3338 13.2547C57.3338 12.9422 57.5872 12.6888 57.8998 12.6888V7.4362C54.6863 7.4362 52.0812 10.0412 52.0812 13.2547H57.3338ZM57.8998 13.8207C57.5872 13.8207 57.3338 13.5673 57.3338 13.2547H52.0812C52.0812 16.4682 54.6863 19.0733 57.8998 19.0733V13.8207Z"
            fill="#6C737F"
            mask="url(#path-24-inside-1_38_128194)"
        />
        <path
            d="M114.418 66.7955C114.085 65.4895 115.291 64.3127 116.589 64.6773L118.253 65.1451C119.551 65.5097 119.967 67.1423 119.002 68.0837L117.765 69.2914C116.801 70.2328 115.179 69.777 114.846 68.4709L114.418 66.7955Z"
            fill="url(#paint18_linear_38_128194)"
        />
        <path
            d="M6.64811 57.9699C7.95457 57.6386 9.12976 58.8458 8.76344 60.1429L8.29352 61.8069C7.92721 63.104 6.29413 63.5181 5.35398 62.5523L4.1479 61.3134C3.20775 60.3476 3.66564 58.7262 4.9721 58.3949L6.64811 57.9699Z"
            fill="url(#paint19_linear_38_128194)"
        />
        <path
            d="M92.924 120.97C94.2304 120.639 95.4056 121.846 95.0393 123.143L94.5694 124.807C94.2031 126.104 92.57 126.518 91.6299 125.552L90.4238 124.313C89.4836 123.348 89.9415 121.726 91.248 121.395L92.924 120.97Z"
            fill="url(#paint20_linear_38_128194)"
        />
        <mask id="path-29-inside-2_38_128194" fill="white">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M118.848 41.6463C119.856 41.6463 120.673 40.8296 120.673 39.8221C120.673 38.8147 119.856 37.998 118.848 37.998C117.841 37.998 117.024 38.8147 117.024 39.8221C117.024 40.8296 117.841 41.6463 118.848 41.6463ZM118.848 43.0144C120.612 43.0144 122.041 41.5851 122.041 39.8221C122.041 38.0591 120.612 36.6299 118.848 36.6299C117.085 36.6299 115.656 38.0591 115.656 39.8221C115.656 41.5851 117.085 43.0144 118.848 43.0144Z"
            />
        </mask>
        <path
            d="M118.046 39.8221C118.046 39.3791 118.405 39.02 118.848 39.02V44.2726C121.306 44.2726 123.299 42.28 123.299 39.8221H118.046ZM118.848 40.6243C118.405 40.6243 118.046 40.2651 118.046 39.8221H123.299C123.299 37.3642 121.306 35.3717 118.848 35.3717V40.6243ZM119.651 39.8221C119.651 40.2651 119.292 40.6243 118.848 40.6243V35.3717C116.391 35.3717 114.398 37.3642 114.398 39.8221H119.651ZM118.848 39.02C119.292 39.02 119.651 39.3791 119.651 39.8221H114.398C114.398 42.28 116.391 44.2726 118.848 44.2726V39.02ZM119.414 39.8221C119.414 40.1347 119.161 40.3881 118.848 40.3881V45.6407C122.062 45.6407 124.667 43.0356 124.667 39.8221H119.414ZM118.848 39.2562C119.161 39.2562 119.414 39.5096 119.414 39.8221H124.667C124.667 36.6086 122.062 34.0036 118.848 34.0036V39.2562ZM118.283 39.8221C118.283 39.5096 118.536 39.2562 118.848 39.2562V34.0036C115.635 34.0036 113.03 36.6086 113.03 39.8221H118.283ZM118.848 40.3881C118.536 40.3881 118.283 40.1347 118.283 39.8221H113.03C113.03 43.0356 115.635 45.6407 118.848 45.6407V40.3881Z"
            fill="#6C737F"
            mask="url(#path-29-inside-2_38_128194)"
        />
        <path
            d="M114.365 122.887L122.054 114.41L123.212 115.46C124.336 116.48 125.009 117.904 125.083 119.419C125.156 120.934 124.625 122.417 123.606 123.54C122.587 124.664 121.163 125.337 119.648 125.41C118.132 125.484 116.65 124.953 115.526 123.934L114.368 122.883L114.365 122.887Z"
            fill="#6C737F"
        />
        <defs>
            <filter
                id="filter0_d_38_128194"
                x="72.1914"
                y="0.310547"
                width="62.5786"
                height="45.9922"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="6" dy="4" />
                <feGaussianBlur stdDeviation="6.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.104618 0 0 0 0 0.465612 0 0 0 0 0.545833 0 0 0 0.09 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_38_128194" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_38_128194" result="shape" />
            </filter>
            <filter
                id="filter1_b_38_128194"
                x="32.5392"
                y="36.861"
                width="87.7189"
                height="87.7194"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.33757" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_38_128194" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_38_128194" result="shape" />
            </filter>
            <filter
                id="filter2_f_38_128194"
                x="37.6048"
                y="72.1561"
                width="79.6879"
                height="47.6879"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="10.422" result="effect1_foregroundBlur_38_128194" />
            </filter>
            <linearGradient
                id="paint0_linear_38_128194"
                x1="59.8217"
                y1="-6.26776"
                x2="61.0457"
                y2="203.567"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0" />
                <stop offset="1" stopColor="#9DA4AE" />
            </linearGradient>
            <linearGradient id="paint1_linear_38_128194" x1="18.7641" y1="28.8159" x2="100.028" y2="34.546" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9DA4AE" />
                <stop offset="1" stopColor="#6C737F" />
            </linearGradient>
            <linearGradient id="paint2_linear_38_128194" x1="16.4199" y1="88.462" x2="133.628" y2="90.5457" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient id="paint3_linear_38_128194" x1="28.4009" y1="46.0066" x2="91.4328" y2="46.0066" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6C737F" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient id="paint4_linear_38_128194" x1="28.4009" y1="63.0378" x2="91.4328" y2="63.0378" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6C737F" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient id="paint5_linear_38_128194" x1="28.4009" y1="80.0701" x2="91.4328" y2="80.0701" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6C737F" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient id="paint6_linear_38_128194" x1="28.4014" y1="97.1023" x2="91.4333" y2="97.1023" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6C737F" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_38_128194"
                x1="-1.08756"
                y1="109.125"
                x2="51.7657"
                y2="109.752"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient id="paint8_linear_38_128194" x1="78.0931" y1="24.3021" x2="130.91" y2="25.8299" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient id="paint9_linear_38_128194" x1="4.90767" y1="95.4126" x2="32.1086" y2="95.4126" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6C737F" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_38_128194"
                x1="5.13002"
                y1="114.249"
                x2="32.0971"
                y2="114.249"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#6C737F" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_38_128194"
                x1="5.13002"
                y1="104.352"
                x2="32.0971"
                y2="104.352"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#6C737F" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_38_128194"
                x1="5.84029"
                y1="85.4866"
                x2="18.6083"
                y2="85.4866"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#6C737F" />
                <stop offset="1" stopColor="#E5E7EB" />
            </linearGradient>
            <linearGradient id="paint13_linear_38_128194" x1="97.6869" y1="107.774" x2="105.448" y2="99.261" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9DA4AE" />
                <stop offset="1" stopColor="#6C737F" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_38_128194"
                x1="44.2168"
                y1="63.4322"
                x2="108.858"
                y2="63.8519"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#9DA4AE" />
                <stop offset="1" stopColor="#6C737F" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_38_128194"
                x1="76.3987"
                y1="53.5361"
                x2="103.792"
                y2="124.618"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="#D2D6DB" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_38_128194"
                x1="45.4238"
                y1="115.077"
                x2="67.5684"
                y2="115.221"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#9DA4AE" />
                <stop offset="1" stopColor="#6C737F" />
            </linearGradient>
            <linearGradient
                id="paint17_linear_38_128194"
                x1="2.42381"
                y1="15.0773"
                x2="24.5684"
                y2="15.2211"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#9DA4AE" />
                <stop offset="1" stopColor="#6C737F" />
            </linearGradient>
            <linearGradient
                id="paint18_linear_38_128194"
                x1="111.892"
                y1="68.4936"
                x2="118.416"
                y2="62.2082"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#9DA4AE" />
                <stop offset="1" stopColor="#6C737F" />
            </linearGradient>
            <linearGradient id="paint19_linear_38_128194" x1="4.9533" y1="55.4415" x2="11.2302" y2="61.9739" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9DA4AE" />
                <stop offset="1" stopColor="#6C737F" />
            </linearGradient>
            <linearGradient
                id="paint20_linear_38_128194"
                x1="91.2292"
                y1="118.442"
                x2="97.5061"
                y2="124.974"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#9DA4AE" />
                <stop offset="1" stopColor="#6C737F" />
            </linearGradient>
        </defs>
    </svg>
);

export default NoOnboardingIcon;
