import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {IBillingInfoInput, IBillingInfoOutput} from '../../model/billingInfo';

export const updateBillingInfoAPI: ApiOperationResponseFunction<IBillingInfoOutput> = (
    authToken: string,
    payload: {
        id: string;
        billingInfo: IBillingInfoInput;
    }
): Observable<IApiSingleResponseBase<IBillingInfoOutput>> => {
    const id = payload.id;
    return palipaliAPI.put(`api/fleet_partners/${id}/change_billing_info`, payload.billingInfo, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
