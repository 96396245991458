import TextField from '@mui/material/TextField';
import React from 'react';
import {FormikFieldConfig} from '../../../components/FormikForm';
import {PatternFormat, PatternFormatProps} from 'react-number-format';

export enum TextMaskType {
    NIP = 'nip',
    POSTAL_CODE = 'postal_code',
    PHONE = 'phone',
}

export const MASK_FORMATS = {
    [TextMaskType.NIP]: {
        textMask: '### ## ## ###',
    },
    [TextMaskType.POSTAL_CODE]: {
        textMask: '##-###',
    },
    [TextMaskType.PHONE]: {
        textMask: '### ### #### #####',
    },
};

interface CustomProps {
    onChange: (event: {target: {name: string; value: string}}) => void;
    name: string;
    textMaskType: string;
}

const PatternFormatCustom = React.forwardRef<PatternFormatProps, CustomProps>(function PatternFormatCustom(props, ref) {
    const {onChange, textMaskType, ...other} = props;
    return (
        <PatternFormat
            {...other}
            getInputRef={ref}
            format={MASK_FORMATS[textMaskType].textMask}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: textMaskType === TextMaskType.POSTAL_CODE ? values.formattedValue : values.value,
                    },
                });
            }}
            valueIsNumericString
        />
    );
});

const TextMaskFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    type,
    formik,
    endAdornment,
    autofocus,
    customHandleChange,
    customDisabled,
    className,
    customEventChange,
    rows,
    isRequired,
    textMaskType,
}) => {
    const handleChange = (event) => {
        let value = event.target.value;
        if (textMaskType === TextMaskType.PHONE) {
            value = value.replace(/ /g, '');
            event.target.value = value;
        }

        formik.setTouched({[name]: true}, true);
        formik.handleChange(event);

        if (customHandleChange) {
            customHandleChange(value, formik.setFieldValue);
        }

        if (customEventChange) {
            customEventChange(name, value);
        }
    };

    const value = formik.values[name];
    const valueWithoutWhitespaceOrDash = value ? value.replace(/ /g, '').replace(/-/g, '') : '';

    return (
        <TextField
            key={name}
            autoFocus={autofocus || false}
            error={!!(formik.touched[name] && formik.errors[name])}
            fullWidth
            helperText={formik.touched[name] && typeof formik.errors[name] ? (formik.errors[name] as string) : undefined}
            label={label}
            name={name}
            defaultValue={valueWithoutWhitespaceOrDash}
            disabled={customDisabled ? customDisabled(formik.values) : false}
            onBlur={formik.handleBlur}
            onChange={handleChange}
            type={type || 'text'}
            value={valueWithoutWhitespaceOrDash}
            className={className || ''}
            inputProps={{
                className: `${name}-input ${className || ''}}`,
            }}
            InputProps={{
                endAdornment: endAdornment,
                inputComponent: PatternFormatCustom as any,
                inputProps: {
                    name,
                    onChange: handleChange,
                    textMaskType,
                },
            }}
            rows={rows}
            multiline={rows !== undefined}
            required={isRequired}
        />
    );
};

export default TextMaskFormControl;
