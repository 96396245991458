import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, ICanOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';

export function getCanReportJobAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<ICanOutput>> {
    return palipaliAPI.get(`api/jobs/${id}/can_report_problem`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
