import React from 'react';
import {IRadioProps} from '../../../types';
import parse from 'html-react-parser';
import {createFormInputClass} from '../../../utils/formUtils';
import Translation from '../../Translation';

type Props = IRadioProps;

class Radio extends React.Component<Props> {
    render() {
        return (
            <div className={`radio-control ${this.props.wrapperStyles ? this.props.wrapperStyles : ''}`}>
                {this.props.options.map((option: any, index: number) => {
                    return (
                        <label key={index} className="radio-wrapper">
                            <input
                                type={'radio'}
                                name={this.props.name}
                                value={option.value}
                                onChange={this.props.handleChange}
                                className={`radio-input ${this.props.inputStyles} ${createFormInputClass(this.props.name)}`}
                                disabled={option.disabled ? option.disabled : this.props.disabled}
                                checked={option.value === this.props.value}
                            />
                            <span className="radio-label">
                                {this.props.isDisplayValueTranslated ? (
                                    <Translation text={option.displayValue} />
                                ) : (
                                    parse(option.displayValue)
                                )}
                            </span>
                            {option.additionalDescription && (
                                <span className="radio-additional-description">
                                    <Translation text={option.additionalDescription} />
                                </span>
                            )}
                            <span className="radio-checkmark" />
                        </label>
                    );
                })}
            </div>
        );
    }
}

export default Radio;
