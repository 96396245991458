import {Observable} from 'rxjs';
import {IInsuranceInput} from '../../model/insurance';
import {IVehicleDetailsOutput} from '../../model/vehicle';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const updateVehicleInsuranceAPI: ApiOperationResponseFunction<IVehicleDetailsOutput> = (
    authToken: string,
    payload: {vehicleInsurance: IInsuranceInput; id: string}
): Observable<IApiSingleResponseBase<IVehicleDetailsOutput>> => {
    const updatePayload: IInsuranceInput = payload.vehicleInsurance,
        id: string = payload.id;
    return palipaliAPI.put(`api/vehicles/${id}/insurance`, updatePayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
