import {PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {
    IApiError,
    IApiSingleResponseBase,
    IConfirmRegistrationOutput,
    handleApiError,
    getAccountDataAPI,
    setAccountState,
    setAuthState,
    setLoginFailure,
    IAccountMeOutput,
    fetchSystemParameters,
    setAuthTokens,
    changeTeamsByRoles,
    ITeamMemberOutput,
    LanguageLocale,
    changeActiveLanguage,
} from '../..';
import {AlertType, UserRole} from '../../types';
import {addAlert} from '../reducers/alertSlice';
import jwt_decode from 'jwt-decode';
import {
    changeIsAuthPageLoading,
    confirmRegistration,
    IAuthTokens,
    IConfirmRegistration,
    IInitApp,
    initApp,
    IRegisterUser,
    IRequestNewPassword,
    ISetNewPassword,
    registerUser,
    requestNewPassword,
    setNewPassword,
    setRegistrationData,
} from '../reducers/authSlice';
import {setAccountStateFailure} from '../reducers/accountSlice';
import {
    confirmTeamMemberInvitation,
    IConfirmInvitation,
    ConfirmInvitationStatus,
    confirmFleetPartnerInvitation,
    setConfirmFleetPartnerInvitationActionStatus,
    setConfirmPurchaserInvitationActionStatus,
} from '../reducers/confirmInvitationSlice';
import {isRoleMatched} from './loginEpic';
import {loginWithToken, setLoadingState} from '../reducers/loginSlice';
import {handleErrorMessage} from '../../utils/errorHandlingUtils';
import {createRegisterAPI} from '../../api/user/createRegisterAPI';
import {push} from 'react-router-redux';
import {createRequestResetPasswordAPI} from '../../api/user/createRequestResetPasswordAPI';
import {createSetNewPasswordAPI} from '../../api/user/createSetNewPasswordAPI';
import {createConfirmRegistrationAPI} from '../../api/user/createConfirmRegistrationAPI';
import {fetchAllDictionaryData} from '../reducers/countrySlice';
import {createConfirmFleetPartnerInvitationAPI} from '../../api/fleetPartner/createCourierInvitationConfirmAPI';
import {createAcceptTeamMemberInvitationAPI} from '../../api/teamMember/createAcceptTeamMemberInvitationAPI';
import {createRefreshTokenAPI} from '../../api/auth/createRefreshTokenAPI';
import {groupTeamsByRole} from '../../utils/tokenDecodingUtils';

const initAppEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(initApp.type),
        switchMap((action: PayloadAction<IInitApp>) => {
            const odlRefreshToken = action.payload.refreshToken;

            return createRefreshTokenAPI(odlRefreshToken).pipe(
                switchMap((response: IApiSingleResponseBase<IAuthTokens>) => {
                    const newToken = response.authToken,
                        refreshToken = response.refreshToken,
                        decoded = jwt_decode(newToken),
                        userRoles = (decoded as any).roles;
                    if (isRoleMatched(userRoles, action.payload.userRole) !== undefined) {
                        return getAccountDataAPI(newToken).pipe(
                            switchMap((account: IAccountMeOutput) => {
                                const actions: any[] = [];
                                const teamsByRole = groupTeamsByRole(decoded);
                                actions.push(changeTeamsByRoles(teamsByRole));
                                actions.push(setAccountState(account));
                                actions.push(fetchAllDictionaryData());
                                actions.push(fetchSystemParameters());
                                actions.push(setAuthTokens(newToken, refreshToken));

                                const activeLanguage = (state$ as any).value.saga.activeLanguage,
                                    accountLocale = account.account.locale
                                        ? account.account.locale.split('_')[0]
                                        : activeLanguage
                                        ? activeLanguage
                                        : LanguageLocale.PL;
                                if (accountLocale !== activeLanguage) {
                                    actions.push(changeActiveLanguage(accountLocale));
                                }

                                return of(...actions);
                            }),
                            catchError((error: any) => {
                                return of(
                                    setAccountStateFailure(handleErrorMessage(error)),
                                    setLoginFailure('alerts.noAccessError'),
                                    setAuthState(null, null, null, false, null),
                                    addAlert(handleApiError(error))
                                );
                            })
                        );
                    } else {
                        return of(
                            setLoginFailure('alerts.noAccessError'),
                            addAlert({
                                message: 'alerts.noAccessError',
                                type: AlertType.ERROR,
                            }),
                            setAuthState(null, null, null, false, null)
                        );
                    }
                }),
                catchError((error: any) =>
                    of(
                        setLoginFailure('alerts.noAccessError'),
                        addAlert({
                            message: 'alerts.noAccessError',
                            type: AlertType.ERROR,
                        }),
                        setAuthState(null, null, null, false, null),
                        addAlert(handleApiError(error))
                    )
                )
            );
        })
    );

const registerUserEpic: Epic = (action$) =>
    action$.pipe(
        ofType(registerUser.type),
        switchMap((action: PayloadAction<IRegisterUser>) => {
            return createRegisterAPI(action.payload.registrationPayload, action.payload.invitationToken).pipe(
                switchMap(() => of(...successActions('auth.alert.registrationSuccess'), setRegistrationData(null))),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const confirmFleetPartnerInvitationEpic: Epic = (action$) =>
    action$.pipe(
        ofType(confirmFleetPartnerInvitation.type),
        switchMap((action: PayloadAction<IConfirmInvitation>) => {
            return createConfirmFleetPartnerInvitationAPI(action.payload.authenticationToken, action.payload.confirm).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.confirmFleetPartnerInvitationSuccess', true);
                    actions.push(setConfirmFleetPartnerInvitationActionStatus(ConfirmInvitationStatus.CONFIRMED));
                    return of(...actions);
                }),
                catchError((error: any) => {
                    const intitationErrorActions = [
                        ...errorActions(error),
                        setConfirmFleetPartnerInvitationActionStatus(ConfirmInvitationStatus.REJECTED),
                    ];
                    return of(...intitationErrorActions);
                })
            );
        }),
        catchError((error: any) => {
            const intitationErrorActions = [
                ...errorActions(error),
                setConfirmFleetPartnerInvitationActionStatus(ConfirmInvitationStatus.REJECTED),
            ];
            return of(...intitationErrorActions);
        })
    );

const confirmTeamMemberInvitationEpic: Epic = (action$) =>
    action$.pipe(
        ofType(confirmTeamMemberInvitation.type),
        switchMap((action: PayloadAction<IConfirmInvitation>) => {
            return createAcceptTeamMemberInvitationAPI(action.payload.authenticationToken, action.payload.confirm).pipe(
                switchMap((resp: ITeamMemberOutput) => {
                    const isNotRegistered = resp.accountInvitation;
                    const confirmedMessageDependingOnRegistration = isNotRegistered
                        ? 'auth.alert.confirmTeamMemberNotRegisteredInvitationSuccess'
                        : 'auth.alert.confirmTeamMemberInvitationSuccess';
                    const actionTypeSuccessMessage =
                            action.payload.confirm === true
                                ? confirmedMessageDependingOnRegistration
                                : 'auth.alert.rejectTeamMemberInvitationSuccess',
                        confirmInvitationStatus =
                            action.payload.confirm === true ? ConfirmInvitationStatus.CONFIRMED : ConfirmInvitationStatus.REJECTED;
                    return of(
                        ...successActions(actionTypeSuccessMessage, undefined, isNotRegistered),
                        setConfirmPurchaserInvitationActionStatus(confirmInvitationStatus)
                    );
                }),
                catchError((error: any) =>
                    of(...errorActions(error), setConfirmPurchaserInvitationActionStatus(ConfirmInvitationStatus.ERROR))
                )
            );
        }),
        catchError((error: any) => of(...errorActions(error), setConfirmPurchaserInvitationActionStatus(ConfirmInvitationStatus.ERROR)))
    );

const confirmRegistrationEpic: Epic = (action$) =>
    action$.pipe(
        ofType(confirmRegistration.type),
        switchMap((action: PayloadAction<IConfirmRegistration>) => {
            return createConfirmRegistrationAPI(action.payload.registrationToken).pipe(
                switchMap((response: IApiSingleResponseBase<IConfirmRegistrationOutput>) => {
                    const token = response.tokens.token,
                        refresh_token = response.tokens.refresh_token,
                        decoded = jwt_decode(response.tokens.token),
                        userRoles = (decoded as any).roles;
                    const isFleetPartnerUsingPurchaserPanel =
                        action.payload.panelAccessRole === UserRole.PURCHASER &&
                        isRoleMatched(userRoles, UserRole.ROLE_FLEET_PARTNER) !== undefined;

                    if (action.payload.panelAccessRole === UserRole.COURIER) {
                        return of(...successActions('auth.alert.confirmCourierRegistrationSuccess', true));
                    }
                    if (!token || !refresh_token) {
                        return of(...successActions('auth.alert.confirmRegistrationSuccess'));
                    }
                    if (isFleetPartnerUsingPurchaserPanel) {
                        return of(...errorActions({message: 'alerts.noAccessError'}), push('/auth/login'));
                    }
                    return of(
                        ...successActions('auth.alert.confirmRegistrationSuccess'),
                        loginWithToken(token, action.payload.panelAccessRole, refresh_token)
                    );
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => {
            return of(...errorActions(error));
        })
    );

const requestNewPasswordEpic: Epic = (action$) =>
    action$.pipe(
        ofType(requestNewPassword.type),
        switchMap((action: PayloadAction<IRequestNewPassword>) => {
            return createRequestResetPasswordAPI(action.payload.requestNewPasswordPayload).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.sendResetPasswordMailSuccess');
                    if (action.payload.panelAccessRole === UserRole.COURIER) {
                        return of(...successActions('auth.alert.sendCourierResetPasswordMailSuccess', true));
                    }
                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const setNewPasswordEpic: Epic = (action$) =>
    action$.pipe(
        ofType(setNewPassword.type),
        switchMap((action: PayloadAction<ISetNewPassword>) => {
            return createSetNewPasswordAPI(action.payload.authToken, action.payload.password).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.confirmResetPasswordSuccess');
                    if (action.payload.panelAccessRole === UserRole.COURIER) {
                        return of(...successActions('auth.alert.confirmCourierResetPasswordSuccess', true));
                    }
                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const errorActions = (error: IApiError) => {
    return [changeIsAuthPageLoading(false), addAlert(handleApiError(error))];
};

const successActions = (successMessage: string, isCourier?: boolean, isNotRegistered?: boolean) => {
    let redirectPath = '/';
    if (isCourier) {
        redirectPath = '/couriers/success';
    }
    if (isNotRegistered) {
        redirectPath = '/auth/register';
    }
    return [
        changeIsAuthPageLoading(false),
        addAlert({message: successMessage, type: AlertType.SUCCESS, displayFor: 20 * 1000}),
        push(redirectPath),
    ];
};

const authEpic = combineEpics(
    initAppEpic,
    registerUserEpic,
    confirmRegistrationEpic,
    requestNewPasswordEpic,
    setNewPasswordEpic,
    confirmFleetPartnerInvitationEpic,
    confirmTeamMemberInvitationEpic
);

export default authEpic;
