import React from 'react';
import ReportsList from './ReportsList';
import ReportsGraphs from './ReportsGraphs';
import ReportsTotals from './ReportsTotals';
import {useSelector} from 'react-redux';
import {reportsTotalNumberOfResultsSelector} from '../../store/selectors/reportsSelectors';
import ReportsTitle from './ReportsTitle';

const Reports: React.FC = () => {
    const totalResults = useSelector(reportsTotalNumberOfResultsSelector);

    return (
        <div className="content-view">
            <ReportsTitle />
            <ReportsGraphs />
            <ReportsTotals haveResults={totalResults > 0} />
            <ReportsList />
        </div>
    );
};
export default Reports;
