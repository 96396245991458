import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {IAttachPaymentMethodPayload, IPaymentMethodOutput} from '../../model/payment';
import {IApiSingleResponseBase} from '../../types';

export const updateAttachPaymentMethodAPI: ApiOperationResponseFunction<IPaymentMethodOutput> = (
    authToken: string,
    payload: IAttachPaymentMethodPayload
): Observable<IApiSingleResponseBase<IPaymentMethodOutput>> => {
    return palipaliAPI.put(
        `api/payment_accounts/${payload.paymentAccountId}/stripe/attach_payment_method`,
        {vendorPaymentMethodId: payload.vendorPaymentMethodId},
        new RestQueryParams(),
        {Authorization: `Bearer ${authToken}`}
    );
};
