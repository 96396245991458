import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../reducers';
import {IJobCancellationReasonState} from '../reducers/jobCancellationReasonSlice';

export const selectJobCancellationReason = (state: CommonRootState): IJobCancellationReasonState => {
    return state.jobCancellationReason;
};

export const jobCancellationReasonSelector = createSelector(
    [selectJobCancellationReason],
    (state: IJobCancellationReasonState) => state.jobCancellationReasons
);
export const jobCancellationReasonError = createSelector(
    [selectJobCancellationReason],
    (state: IJobCancellationReasonState) => state.jobCancellationReasons
);
export const isJobCancellationReasonLoading = createSelector(
    [selectJobCancellationReason],
    (state: IJobCancellationReasonState) => state.isLoading
);
