import {throttle} from 'lodash';
import nProgress from 'nprogress';
import type {MutableRefObject} from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useSearchParams as _useSearchParams, useLocation, useNavigate} from 'react-router-dom';
import {SettingsContext} from '../contexts/settings-context';
import {gtm} from '../libs/gtm';

interface DialogController<T> {
    data?: T;
    handleClose: () => void;
    handleOpen: (data?: T) => void;
    open: boolean;
}

export function useDialog<T = unknown>(): DialogController<T> {
    const [state, setState] = useState<{open: boolean; data?: T}>({
        open: false,
        data: undefined,
    });

    const handleOpen = useCallback((data?: T): void => {
        setState({
            open: true,
            data,
        });
    }, []);

    const handleClose = useCallback((): void => {
        setState({
            open: false,
        });
    }, []);

    return {
        data: state.data,
        handleClose,
        handleOpen,
        open: state.open,
    };
}

export const useMounted = (): (() => boolean) => {
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback((): boolean => isMounted.current, []);
};

export function useNprogress() {
    const isMounted = useMounted();
    const pathname = usePathname();
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!visible) {
            nProgress.start();
            setVisible(true);
        }

        if (visible) {
            nProgress.done();
            setVisible(false);
        }

        if (!visible && isMounted()) {
            setVisible(false);
            nProgress.done();
        }

        return () => {
            nProgress.done();
        };
    }, [pathname, isMounted]);
}

export const usePageView = (): void => {
    useEffect(() => {
        gtm.push({event: 'page_view'});
    }, []);
};

export const usePathname = () => {
    const location = useLocation();

    return location.pathname;
};

interface PopoverController<T> {
    anchorRef: MutableRefObject<T | null>;
    handleOpen: () => void;
    handleClose: () => void;
    handleToggle: () => void;
    open: boolean;
}

export function usePopover<T = HTMLElement>(): PopoverController<T> {
    const anchorRef = useRef<T | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = useCallback((): void => {
        setOpen(true);
    }, []);

    const handleClose = useCallback((): void => {
        setOpen(false);
    }, []);

    const handleToggle = useCallback((): void => {
        setOpen((prevState) => !prevState);
    }, []);

    return {
        anchorRef,
        handleClose,
        handleOpen,
        handleToggle,
        open,
    };
}

interface NavigateOptions {}

interface Router {
    /**
     * Navigate to the previous history entry.
     */
    back(): void;

    /**
     * Navigate to the next history entry.
     */
    forward(): void;

    /**
     * Refresh the current page.
     */
    refresh(): void;

    /**
     * Navigate to the provided href.
     * Pushes a new history entry.
     */
    push(href: string, options?: NavigateOptions): void;

    /**
     * Navigate to the provided href.
     * Replaces the current history entry.
     */
    replace(href: string, options?: NavigateOptions): void;

    /**
     * Prefetch the provided href.
     */
    prefetch(href: string): void;
}

/**
 * This is a wrapper over `react-router/useNavigate` hook.
 * We use this to help us maintain consistency between CRA and Next.js
 */
export const useRouter = (): Router => {
    const navigate = useNavigate();

    return useMemo(() => {
        return {
            back: () => navigate(-1),
            forward: () => navigate(1),
            refresh: () => navigate(0),
            push: (href: string) => navigate(href),
            replace: (href: string) => navigate(href, {replace: true}),
            prefetch: () => null,
        };
    }, [navigate]);
};

export const useSearchParams = () => {
    const [searchParams] = _useSearchParams();

    return searchParams;
};

export interface Selection<T> {
    handleDeselectAll: () => void;
    handleDeselectOne: (item: T) => void;
    handleSelectAll: () => void;
    handleSelectOne: (item: T) => void;
    selected: T[];
}

export const useSelection = <T>(items: T[] = []): Selection<T> => {
    const [selected, setSelected] = useState<T[]>([]);

    useEffect(() => {
        setSelected([]);
    }, [items]);

    const handleSelectAll = useCallback((): void => {
        setSelected([...items]);
    }, [items]);

    const handleSelectOne = useCallback((item: T): void => {
        setSelected((prevState) => [...prevState, item]);
    }, []);

    const handleDeselectAll = useCallback(() => {
        setSelected([]);
    }, []);

    const handleDeselectOne = useCallback((item: T): void => {
        setSelected((prevState) => {
            return prevState.filter((_item) => _item !== item);
        });
    }, []);

    return {
        handleDeselectAll,
        handleDeselectOne,
        handleSelectAll,
        handleSelectOne,
        selected,
    };
};

export const useSettings = () => useContext(SettingsContext);

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
export const useUpdateEffect = (effect: () => void, dependencies: any[] = []) => {
    const isInitialMount = useRef(true);

    useEffect(
        () => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
            } else {
                return effect();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependencies
    );
};

interface Config {
    handler: () => void;
    delay?: number;
}

export const useWindowScroll = (config: Config): void => {
    useEffect(() => {
        const {handler, delay} = config;

        const withThrottle = throttle(handler, delay);

        window.addEventListener('scroll', withThrottle);

        return () => {
            window.removeEventListener('scroll', withThrottle);
        };
    }, [config]);
};
