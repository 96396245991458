import {createSelector} from '@reduxjs/toolkit';
import {ILocationTypeState} from '../reducers/locationTypeSlice';
import {CommonRootState} from '../reducers';
import {accountLocaleSelector} from './accountSelectors';
import {dictionaryTranslationName} from '../../utils/translationUtils';

export const selectLocationType = (state: CommonRootState): ILocationTypeState => {
    return state.locationType;
};

export const locationTypesSelector = createSelector([selectLocationType], (state: ILocationTypeState) => state.locationTypes);

export const locationTypesLoadingSelector = createSelector([selectLocationType], (state: ILocationTypeState) => state.isLoading);

export const locationTypesInitializedSelector = createSelector([selectLocationType], (state: ILocationTypeState) => state.isInitialized);

export const locationTypesErrorSelector = createSelector([selectLocationType], (state: ILocationTypeState) => state.error);

export const locationTranslationSelector = createSelector(
    [locationTypesSelector, accountLocaleSelector, (state, id) => id],
    (types, locale, id) => {
        const type = types?.find((type) => type.id === id);

        return type ? dictionaryTranslationName(type, locale) : null;
    }
);

export const locationByCodeSelector = createSelector([locationTypesSelector, (state, code) => code], (types, code) => {
    return types?.find((type) => type.code === code);
});

export const createLocationTypesSelector = createSelector([locationTypesSelector], (locations) => {
    return locations?.filter((location) => location.accessibleForClients) || [];
});
