import type {SVGProps} from 'react';
import React from 'react';

const LogOut01 = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path fill="currentColor" d="M3 21V3h6v18H3Z" opacity={0.12} />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m16 17 5-5m0 0-5-5m5 5H9m0-9H7.8c-1.6802 0-2.5202 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.2798 3 6.1198 3 7.8v8.4c0 1.6802 0 2.5202.327 3.162a2.9997 2.9997 0 0 0 1.311 1.311C5.2798 21 6.1198 21 7.8 21H9"
        />
    </svg>
);

export default LogOut01;
