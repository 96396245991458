import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';

export const selectVehicleListingSlice = (state: RootState) => {
    return state.vehicleListing;
};

export const vehicleListingSelector = createSelector([selectVehicleListingSlice], (state: any) => state.vehicleList);
export const vehicleListingMetadataSelector = createSelector([selectVehicleListingSlice], (state: any) => state.metadata);
export const vehicleListingTotalItemsSelector = createSelector([selectVehicleListingSlice], (state: any) => state.metadata?.totalItems);
export const vehicleListingPaginationSelector = createSelector([selectVehicleListingSlice], (state: any) => state.pagination);
export const vehicleListingFilterSelector = createSelector([selectVehicleListingSlice], (state: any) => state.verified);
export const vehicleListingSearchParamsSelector = createSelector([selectVehicleListingSlice], (state: any) => state.search);
export const isVehicleListingLoadingSelector = createSelector([selectVehicleListingSlice], (state: any) => state.isLoading);
