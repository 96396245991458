import React, {ChangeEvent, useEffect} from 'react';
import {Checkbox, FormControlLabel, FormGroup, Stack, Typography, Box} from '@mui/material';
import {Translation, IReportsFilters} from 'palipali-panel-common-web';
import merge from 'lodash/merge';
import {useSelector} from 'react-redux';
import {reportsFiltersValuesSelector} from '../../../../../store/selectors/reportsSelectors';

export interface FilterOption {
    label: string;
    value: string;
}

interface IFiltersGroupProps {
    setFieldValue: (field: keyof IReportsFilters, value: string[], shouldValidate?: boolean | undefined) => void;
    filterOptions: FilterOption[];
    groupTitle?: string;
    fieldName: keyof IReportsFilters;
}
const FiltersGroup = ({groupTitle, filterOptions, fieldName, setFieldValue}: IFiltersGroupProps) => {
    const filterField = useSelector((state) => reportsFiltersValuesSelector(state, fieldName));
    const [options, setOptions] = React.useState<{[id: string]: boolean}>();
    const hasSavedFieldValue = (value: string) => Array.isArray(filterField) && !!filterField?.find((v) => v === value);

    useEffect(() => {
        if (filterOptions && filterOptions.length > 0) {
            const optionsNew = filterOptions.reduce((acc: {[id: string]: boolean}, option) => {
                acc[option.value] = hasSavedFieldValue(option.value);
                return acc;
            }, {});
            setOptions(merge({}, optionsNew, options));
        }
    }, [filterOptions]);
    const handleCheckboxClick = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const optionsNew = merge({}, options, {[event.target.name]: checked});
        setOptions(optionsNew);
        setFieldValue(
            fieldName,
            Object.keys(optionsNew || {}).filter((key) => optionsNew?.[key] === true)
        );
    };

    return (
        <Stack spacing={2}>
            {groupTitle && (
                <Typography variant={'caption'} color={'textSecondary'}>
                    <Translation text={groupTitle} />
                </Typography>
            )}
            <Box
                sx={{overflowY: 'auto', backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.50')}}
                maxHeight={'14.4rem'}
                py={1}>
                <FormGroup sx={{padding: '1rem'}}>
                    {filterOptions.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            className={`reports-list-filter-checkbox-${option.label}`}
                            control={
                                <Checkbox checked={options?.[option.value] || false} name={option.value} onChange={handleCheckboxClick} />
                            }
                            label={option.label}
                        />
                    ))}
                </FormGroup>
            </Box>
        </Stack>
    );
};

export default FiltersGroup;
