const pl = {
    app: {
        title: 'Panel Partnera Flotowego',
        courierTitle: 'Kurier',
        companyTitle: 'PaliPali',
        errorPages: {
            401: {
                title: '401 - Nieautoryzowany',
                description: 'Nie masz uprawnień do przeglądania tej strony.',
                buttons: {
                    backToHome: 'Powrót do strony głównej',
                },
            },
            404: {
                title: '404 - Nie znaleziono strony',
                description: 'Strona, której szukasz nie istnieje.',
                buttons: {
                    backToHome: 'Powrót do strony głównej',
                },
            },
            500: {
                title: '500 - Błąd serwera',
                description: 'Coś poszło nie tak. Spróbuj ponownie później.',
                buttons: {
                    backToHome: 'Powrót do strony głównej',
                },
            },
        },
        validation: {
            phone_taken: 'Podany numer telefonu jest już zarejestrowany w systemie.',
            user_does_not_exists: 'Użytkownik z podaną nazwą nie istnieje.',
            user_already_confirmed: 'Użytkownik z podaną nazwą już jest potwierdzony.',
            object_with_given_id_does_not_exists: 'Obiekt z podanym id nie istnieje.',
            user_is_not_owner_of_object: 'Użytkownik nie jest właścicielem tego obiektu.',
            object_must_implement_ownable_interface: 'Obiekt musi implementować OwnableInterface, aby móc przejść walidację.',
            value_not_part_of_enum: 'Podana wartość nie jest prawidłową wartością',
            location_not_supported:
                'Realizacja zlecenia dla wskazanych punktów nie jest możliwa, ponieważ żaden z punktów nie znajduje się w obszarze działania aplikacji.',
            invalid_domain: 'Adres url zawiera nieprawidłową domenę.',
            invalid_vehicle: 'Brak autoryzacji do użycia tego pojazdu.',
            vehicle_occupied: 'Pojazd jest zajęty przez innego użytkownika.',
            incompatible_package_sizes: 'Wybrane rozmiary przesyłek nie są akceptowalne dla tego pojazdu.',
            not_full_team_member: 'Użytkownik nie jest pełnoprawnym członkiem zespołu.',
            not_team_member: 'Użytkownik nie jest członkiem zespołu.',
            insufficient_team_role: 'Użytkownik nie posiada odpowiednich uprawnień w zespole lub nie jest jego członkiem.',
            missing_phone_in_from_location: 'Numer telefonu punktu nadania jest wymagany.',
            not_fleet_courier: 'Kurier flotowy nie przynależy do partnera flotowego.',
            location_type_not_accessible: 'Ten typ lokacji nie jest dozwolony.',
            invalid_city_limits: 'Podane granice miasta mają nieprawidłowy format.',
            enum_value_not_not_supported: 'Podana wartość enuma %value% nie jest wspierana.',
            greater_than_now: 'Data powinna być w przyszłości.',
            invalid_nip_length: 'NIP musi posiadać dokładnie 10 cyfr.',
            invalid_nip_number: 'Podany NIP jest nieprawidłowy.',
            locale_not_supported: 'Podana lokalizacja nie jest wspierana.',
            no_package_size_selected: 'Musisz wybrać co najmniej jedną kategorię.',
            locations_have_the_same_localisation: 'Wybrane punkty odbioru i dostawy mają taką samą lokalizację.',
        },
        exception: {
            object_with_given_id_does_not_exists: 'Obiekt z podanym id nie istnieje.',
            user_is_not_owner_of_object: 'Użytkownik nie jest właścicielem tego obiektu.',
            object_must_implement_ownable_interface: 'Obiekt musi implementować OwnableInterface, aby móc przejść walidację.',
            value_not_part_of_enum: 'Podana wartość nie jest prawidłową wartością',
            location_not_supported:
                'Realizacja zlecenia dla wskazanych punktów nie jest możliwa, ponieważ żaden z punktów nie znajduje się w obszarze działania aplikacji.',
            invalid_domain: 'Adres url zawiera nieprawidłową domenę.',
            invalid_vehicle: 'Brak autoryzacji do użycia tego pojazdu.',
            vehicle_occupied: 'Pojazd jest zajęty przez innego użytkownika.',
            incompatible_package_sizes: 'Wybrane rozmiary przesyłek nie są akceptowalne dla tego pojazdu.',
            not_full_team_member: 'Użytkownik nie jest pełnoprawnym członkiem zespołu.',
            not_team_member: 'Użytkownik nie jest członkiem zespołu.',
            insufficient_team_role: 'Użytkownik nie posiada odpowiednich uprawnień w zespole lub nie jest jego członkiem.',
            missing_phone_in_from_location: 'Numer telefonu punktu nadania jest wymagany.',
            not_fleet_courier: 'Kurier flotowy nie przynależy do partnera flotowego.',
            location_type_not_accessible: 'Ten typ lokacji nie jest dozwolony.',
            invalid_city_limits: 'Podane granice miasta mają nieprawidłowy format.',
            user_already_exists: 'Wskazany adres e-mail jest już zarejestrowany, podaj inny adres e-mail.',
            file_not_provided: 'Nie załączono pliku.',
            authentication_token_could_not_be_resolved: 'Token autoryzacyjny nie może zostać obsłużony.',
            concurrent_conflict: 'Operacja nie może zostać zrealizowana z powodu konfliktu.',
            cant_change_role_to_owner: 'Nie możesz zmienić roli użytkownika, który jest właścicielem.',
            invalid_role_invited: 'Możesz zaprosić tylko członków z rolą niższą o twojej.',
            invalid_team_member_invitation: 'To zaproszenie jest nieważne.',
            cant_invite_deleted_user: 'Zaproszony użytkownik został usunięty i nie może zostawać więcej zaproszony.',
            invalid_date_format: 'Błędny format daty.',
            invalid_invitation: 'Błędne zaproszenie.',
            invalid_invitation_action: 'Niedozwolona akcja na zaproszeniu.',
            invalid_recipient_data: 'Dane odbiorcy zlecenia muszą zawierać id członka zespołu lub imię, nazwisko oraz numer telefonu.',
            invalid_job_location_data: 'Dane lokacji zlecenia muszą zawierać id lokacji lub dane lokacji w tym adres oraz lokalizację.',
            location_not_allowed: 'Wskazana lokacja nie jest publiczna lub nie należy do wybranego zespołu.',
            courier_job_proposal_rejected: 'Nie można zaakceptować propozycji zlecenia, która została odrzucona.',
            courier_job_proposal_accepted: 'Nie można odrzucić propozycji zlecenia, która została zaakceptowana.',
            courier_job_proposal_expired: 'To zlecenie nie jest już dostępne.',
            invalid_job_problem_type: 'Niewłaściwy typ problemu zlecenia.',
            invalid_uuid_provided: 'Podany id jest nieprawidłowy.',
            invalid_vehicle_provided: 'Wskazane pojazdy nie istnieją lub brak uprawnień do użycia ich w zleceniu.',
            invoicing_service_exception: 'Nieudane pobieranie faktury.',
            invalid_phone_number: 'Podano nieprawidłowy numer telefonu.',
            cant_download_invoice: 'Nieudane pobieranie faktury.',
            account_not_confirmed: 'Konto nie zostało potwierdzone.',
            account_not_active: 'Konto nie jest aktywne.',
            account_deleted: 'Konto zostało usunięte.',
            account_is_invitation: 'Zaproszenie od Partnera Flotowego nie zostało jeszcze zaakceptowane, sprawdź e-mail.',
            user_not_found: 'Wprowadzony użytkownik nie istnieje.',
            payment_method_cannot_be_captured: 'Nie można obecnie użyć wybranej metody płatności. Sprawdź czy masz wystarczające środki.',
            not_allowed_to_login: 'Nie możesz się zalogować do tej aplikacji za pomocą tego konta.',
            invalid_identifier: 'Podany identyfikator jest nieprawidłowy.',
        },
        timeComponent: {
            estimatedTime: ' (przewidywany czas)',
        },
    },
    logoutModal: {
        title: 'Wyloguj się',
        content: 'Czy na pewno chcesz się wylogować?',
        logout: 'Wyloguj się',
    },
    reports: {
        title: 'Raporty',
        downloadReport: 'Pobierz CSV',
        totals: {
            timeOfRealisation: 'Czas realizacji',
            distanceDone: 'Pokonany dystans',
            gain: 'Przychód',
        },
        graphs: {
            income: 'Przychód',
            jobsDone: 'Zlecenia zrealizowane',
            numberOfActiveCouriers: 'Liczba kurierów realizujących zlecenia',
            totalDistance: 'Pokonany dystans',
        },
        reportsList: {
            noDataInfo: 'Brak danych',
            filters: {
                title: 'Filtry',
                courier: 'Kurier',
                city: 'Miasto',
                fleetVehicles: 'Pojazdy Flotowe',
                courierVehicles: 'Pojazdy Kurierów',
            },
            headers: {
                courier: 'Kurier',
                city: 'Miasto',
                jobsDone: 'Zlecenia zrealizowane',
                jobsReported: 'zlecenia zgłoszone',
                jobsCanceled: 'Zlecenia anulowane',
                time: 'Czas realizacji',
                distance: 'Pokonany dystans',
                income: 'Przychód',
                date: 'Okres',
                filters: 'Filtry',
            },
        },
    },
    account: {
        title: 'Konto',
        subtitle: {
            profile: 'Profil',
            settlement: 'Rozliczenia',
            settings: 'Ustawienia',
        },
        profile: {
            title: 'Profil',
            formControls: {
                username: 'E-mail',
                phone: 'Numer telefonu',
                dialingCode: 'Kod kraju',
                firstName: 'Imię',
                lastName: 'Nazwisko',
                cityId: 'Miasto',
            },
            buttons: {
                update: 'Aktualizuj',
            },
        },
        status: {
            inactive: 'Nieaktywny',
            active: 'Aktywny',
            toFill: 'Do uzupełnienia',
        },
        settings: {
            title: 'Ustawienia',
            formControls: {
                language: 'Język',
                highContrastMode: 'Tryb wysokiego kontrastu',
            },
            highContrastMode: 'Tryb wysokiego kontrastu',
            highContrastModeSubtitle: 'Wskazany dla osób słabiej widzących',
            status: {
                inactive: 'Nieaktywny',
                active: 'Aktywny',
            },
        },
        changePassword: {
            title: 'Zmiana hasła',
            formControls: {
                oldPassword: 'Aktualne hasło',
                newPassword: 'Nowe hasło',
                confirmNewPassword: 'Powtórz nowe hasło',
            },
        },
        deleteProfile: {
            title: 'Usuń konto',
            subtitle: 'Czy na pewno chcesz usunąć swoje konto oraz wszystkie dane z nim związane?',
            warning: 'Ta operacja jest nieodwracalna.',
            cannotBeDeleted: 'Nie możesz teraz usunąć konta.',
            buttons: {
                deleteAccount: 'Usuń konto',
            },
            alerts: {
                deleted: 'Konto zostało usunięte.',
            },
        },
        info: {
            name: 'Imię',
            surname: 'Nazwisko',
            email: 'E-mail',
            tel: 'Numer telefonu',
            city: 'Miasto',
            language: 'Język',
            theme: 'Theme',
        },
        settlements: {
            title: 'Rozliczenia',
            status: {
                active: 'Aktywne',
                inActive: 'Do uzupełnienia',
            },
            billingInfoCard: {
                title: 'Dane rozliczeniowe',
                formControls: {
                    companyName: 'Nazwa firmy',
                    taxIdentifier: 'NIP',
                    street: 'Ulica',
                    houseNumber: 'Numer budynku',
                    flatNumber: 'Numer lokalu',
                    city: 'Miejscowość',
                    zip: 'Kod pocztowy',
                },
                alert: {
                    success: 'Pomyślnie zaktualizowano dane rozliczeniowe.',
                },
            },
            stripeAccountCard: {
                title: 'Konto rozliczeniowe',
                inactive: {
                    info: 'Konto rozliczeniowe Stripe jest niezbędne do realizacji rozliczeń za zrealizowane zlecenia.',
                    warning: 'Aby skonfigurować konto rozliczeniowe, wprowadź najpierw dane rozliczeniowe.',
                },
                active: {
                    info: 'Jeśli chcesz zmienić dane firmy przejdż ponownie przez konfigurację konta rozliczeniowego Stripe.',
                },
                button: 'Konfiguruj konto rozliczeniowe Stripe',
            },
        },
        locale: {
            en_US: 'english',
            pl_PL: 'polski',
            uk_UA: 'українська',
        },
        themes: {
            dark: 'Wysoki kontrast',
            normal: 'Zwykły',
        },
        modal: {
            title: 'Wybierz avatar',
        },
        alert: {
            imageAddSuccess: 'Dodano zdjęcie.',
            miscChangeSuccess: 'Ustawienia zostały zmienione',
            changeLocaleSuccess: 'Język został zmieniony',
            changePasswordSuccess: 'Hasło zostało zmienione',
            deleteAccountSuccess: 'Konto zostało usunięte',
            updateProfileDataSuccess: 'Dane zostały zaktualizowane',
            changeEmailSuccess: 'Adres e-mail został zmieniony',
        },
    },
    profileEdit: {
        title: 'Profil',
        formControls: {
            username: 'E-mail',
            phone: 'Numer telefonu',
            dialingCode: 'Kod kraju',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            cityId: 'Miasto',
        },
        links: {
            changePassword: 'Zmiana hasła',
            deleteAccount: 'Usuń konto',
        },
        settings: {
            title: 'Ustawienia',
            formControls: {
                language: 'Język',
                highContrastMode: 'Tryb wysokiego kontrastu',
            },
        },
        resetPassword: {
            title: 'Zmiana hasła',
            formControls: {
                oldPassword: 'Aktualne hasło',
                newPassword: 'Nowe hasło',
                confirmNewPassword: 'Potwierdż nowe hasło',
            },
            buttons: {
                saveChanges: 'Zapisz zmiany',
            },
        },
    },
    settlements: {
        title: 'Rozliczenia',
        noSettlements: 'Brak rozliczeń',
        date: 'Okres',
        summary: {
            current: 'Bieżące',
            inSettlement: 'W rozliczeniu',
            revenueSettlement: 'Przychód rozliczony',
            invoiceCount: 'z {{invoiceCount}} faktur',
        },
        settled: 'Rozliczone ({{settlementsCount}})',
        income: 'Przychód',
        downloadInvoice: 'Pobierz fakturę',
    },
    vehicles: {
        title: 'Pojazdy',
        content: 'Content',
        vehicleOperation: {
            title: {
                create: 'Nowy pojazd',
                backButton: 'Powrót',
            },
            basicInformation: {
                title: 'Informacje ogólne',
                form: {
                    registrationNumber: {
                        label: 'Numer tablicy rejestracyjnej',
                        placeholder: 'Wprowadź numer tablicy rejestracyjnej',
                    },
                    make: {
                        label: 'Marka',
                        placeholder: 'Wybierz markę',
                    },
                    model: {
                        label: 'Model',
                        placeholder: 'Wybierz model',
                    },
                    doorNumber: {
                        label: 'Numer boczny',
                        placeholder: 'Wpisz numer boczny',
                    },
                    vehicleTypeId: {
                        label: 'Typ nadwozia',
                        placeholder: 'Wybierz typ nadwozia',
                    },
                    productionYear: {
                        label: 'Rok produkcji',
                        placeholder: 'Wybierz rok produkcji',
                    },
                    colorId: {
                        label: 'Kolor pojazdu',
                        placeholder: 'Wybierz kolor',
                    },
                },
            },
            inspection: {
                title: 'Dowód rejestracyjny',
                form: {
                    until: {
                        label: 'Termin ważności badania technicznego',
                        placeholder: 'Wybierz datę',
                    },
                    documentPhoto: {
                        label: 'Zdjęcie dokumentu',
                        labelFront: 'Zdjęcie dokumentu - przód',
                        labelBack: 'Zdjęcie dokumentu - tył',
                        placeholderFront: 'Zdjęcie przodu',
                        placeholderBack: 'Zdjęcie tyłu',
                        description: `Tutaj możesz dodać zdjęcie ubezpieczenia, wymagane są dwa zdjęcia zatytułowane - 'przód' i 'tył'.`,
                        button: {
                            add: 'Dodaj',
                            delete: 'Usuń',
                        },
                    },
                },
            },
            insurance: {
                title: 'Ubezpieczenie',
                form: {
                    number: {
                        label: 'Numer polisy OC',
                        placeholder: 'Wpisz numer',
                    },
                    until: {
                        label: 'Termin ważności',
                        placeholder: 'Wybierz datę',
                    },
                    documentPhoto: {
                        label: 'Zdjęcie dokumentu',
                        description: `Tutaj możesz dodać zdjęcie ubezpieczenia, wymagane są dwa zdjęcia zatytułowane - 'przód' i 'tył'.`,
                        button: {
                            add: 'Dodaj',
                            delete: 'Usuń',
                        },
                    },
                },
            },
            buttons: {
                next: 'Dalej',
                back: 'Wstecz',
                create: 'Utwórz pojazd',
                update: 'Aktualizuj',
            },
            alerts: {
                success: {
                    createVehicle: 'Pojazd został utworzony',
                    updateVehicleInsurance: 'Ubezpieczenie zostało zaktualizowane.',
                    updateVehicleInspection: 'Dowód rejestracyjny został zaktualizowany.',
                },
            },
        },
        vehicleList: {
            title: 'Lista Pojazdów',
            table: {
                columnTitles: {
                    number: 'Liczba porządkowa',
                    vehicle: 'Pojazd',
                    registrationNo: 'Numer rejestracyjny',
                    jobCategories: 'Dostępne kategorie zleceń',
                    status: 'Status',
                    actions: 'Akcje',
                },
                buttons: {
                    editVehicle: 'Edytuj pojazd',
                    viewVehicle: 'Szczególy pojazdu',
                    addVehicle: 'Dodaj pojazd',
                    deleteVehicle: 'Usuń pojazd',
                    couriers: 'Kurierzy ({{count}})',
                    details: 'Szczegóły',
                },
                filterButtons: {
                    all: 'Wszyscy',
                    verified: 'Aktywny',
                    unverified: 'Nieaktywny',
                    pending: 'Trwa weryfikacja',
                },
                searchInputLabel: 'Szukaj',
                noResults: 'Brak pojazdów',
            },
            deleteVehicle: {
                modal: {
                    title: 'Usuń pojazd',
                    content: 'Czy na pewno chcesz usunąć pojazd?',
                    subtitle: 'Ta operacja jest nieodwracalna.',
                    buttons: {
                        deleteVehicle: 'Usuń pojazd',
                    },
                },
                alerts: {
                    cannotDeleteVehicle: 'Nie masz uprawnień aby usunąć pojazd.',
                    deleteSuccess: 'Pojazd został usunięty.',
                },
            },
            assignedCouriers: {
                modal: {
                    title: 'Kurierzy przypisani do pojazdu',
                    table: {
                        columnTitles: {
                            assigned: 'Przypisany kurier',
                            available: 'Dostępni kurierzy',
                        },
                        buttons: {
                            update: 'Aktualizuj',
                        },
                        noResults: 'Brak kurierów',
                    },
                    alerts: {
                        success: 'Zmiana przypisania kurierów do pojazdu zakonczona sukcesem.',
                        notAssigned: 'Niektórzy kurierzy nie zostali przypisani do pojazdu.',
                    },
                },
            },
        },
        vehicleDetails: {
            title: 'Pojazd',
            basicInformation: {
                title: 'Informacje ogólne',
            },
            insuranceSettings: {
                title: 'Ubezpieczenie',
            },
            inspectionSettings: {
                title: 'Dowód rejestracyjny',
            },
            buttons: {
                back: 'Powrót',
                update: 'Aktualizuj',
                delete: 'Usuń Pojazd',
            },
            deleteVehicle: {
                title: 'Usuwanie pojazdu',
                subtitle: 'Usuń pojazd. Ta operacja jest nieodwracalna.',
            },
            documentStatus: {
                active: 'Zweryfikowano',
                rejected: 'Odrzucony',
                pending: 'Trwa weryfikacja',
                inactive: 'Nieaktualny',
                verified: 'Zweryfikowano',
                obsolete: 'Nieaktualny',
                to_be_filled: 'Do uzupełnienia',
                expiring: 'Wygasa',
            },
        },
        status: {
            verified: 'Aktywny',
            unverified: 'Nieaktywny',
            pending: 'Trwa weryfikacja',
            verification_pending: 'Trwa weryfikacja',
            active: 'Aktywny',
            inactive: 'Nieaktywny',
            expiring: 'Wygasa',
            to_be_filled: 'Do uzupełnienia',
        },
        deliveryStatus: {
            planned: 'Zaplanowane',
            searching: 'Szukanie',
            drive: 'W drodze',
            pickup: 'Podjęcie przesyłki',
            delivery: 'Wydanie przesyłki',
            delivered: 'Dostarczono',
            cancelledPurchaser: 'Anulowane',
            cancelledCourier: 'Anulowane przez Kuriera',
            problemPurchaser: 'Zgłoszone',
            problemCourier: 'Zgłoszone przez Kuriera',
            deliveryDrive: 'W doręczeniu',
            failed: 'Niepowodzenie',
            online: 'Online',
        },
    },
    activeCouriers: {
        noOnboardingInfo: {
            subtitle: 'Nieaktywne konto',
            title: 'Wymagana konfiguracja konta rozliczeniowego',
            description:
                'Aby rozpocząć współpracę na zasadach Partnera Flotowego należy dokonać konfiguracji konta rozliczeniowego Stripe niezbędnego do realizacji rozliczeń za zrealizowane zlecenia.',
            button: 'Konfiguruj konto rozliczeniowe',
        },
        list: {
            title: 'Aktywni kurierzy',
            noActiveCouriers: 'Brak aktywnych kurierów',
            vehicleOwnership: {
                assigned: 'pojazd Partnera',
                owned: 'pojazd własny',
            },
        },
        noActiveCouriers: 'Brak aktywnych kurierów',
    },
    couriers: {
        noCouriers: 'Brak kurierów',
        title: 'Kurierzy',
        filters: {
            all: 'Wszyscy',
            active: 'Aktywni',
            inactive: 'Nieaktywni',
            invited: 'Zaproszeni',
            city: 'Miasto',
            search: 'Szukaj',
            allCities: 'Wszystkie miasta',
            filterButtons: {
                active: 'Aktywni',
                inactive: 'Nieaktywni',
                invited: 'Zaproszeni',
                all: 'Wszyscy',
            },
        },
        table: {
            courier: 'Kurier',
            city: 'Miasto',
            status: 'Status',
        },
        buttons: {
            vehicles: 'Pojazdy ({{ count }})',
            delete: 'Usuń',
            addCourier: 'Zaproś Kuriera',
            refresh: 'Odśwież',
        },
        courierStatus: {
            active: 'Aktywny',
            inactive: 'Nieaktywny',
            invited: 'Zaproszony',
            online: 'Online',
        },
        inviteCourier: {
            title: 'Zaproś Kuriera',
            description: 'Wprowadź adres e-mail Kuriera, na który wyślemy zaproszenie do Twojego konta Partnera Flotowego.',
            note: 'Jeśli Kurier nie posiada konta w systemie, będzie musiał dokonać rejestracji.',
            helper: 'Twoje zaproszenie Kurier znajdzie w zakładce:',
            helperRoad: `Ustawienia -> Rozliczenia -> Partner Flotowy`,
            button: 'Wyślij zaproszenie',
            email: 'E-mail',
            alerts: {
                successInvitation: 'Kurier został zaproszony.',
                unsuccessfulInvitation: 'Ten kurier usunął swoje konto i nie może być już zaproszony.',
                successUnassignment: 'Kurier został pomyślnie usunięty',
            },
        },
        unassignCourier: {
            title: 'Usuń konto Kuriera',
            checking: 'Sprawdzanie możliwości usunięcia konta Kuriera...',
            description: 'Czy na pewno chcesz usunąć Kuriera ze swojego konta?',
            cannotBeUnassigned: 'Nie możesz teraz odwiązać tego kuriera.',
            warning: 'Ta operacja jest nieodwracalna.',
            button: 'Potwierdzam usunięcie konta',
        },
        assignVehicle: {
            title: 'Pojazdy przypisane do Kuriera',
            description: 'Jeśli nie widzisz swojego pojazdu sprawdź jego status w zakładce',
            descriptionVehicles: ' Pojazdy.',
            availableVehicles: 'Dostępne pojazdy',
            assignButton: 'Aktualizuj',
            vehiclesAssigned: 'Lista przypisanych do kurierów pojazdów została zaktualizowana.',
            vehiclesAssignedError: 'Niektórych przypisanych pojazdów nie udało się zaktualizować.',
            noVehicle: 'Brak pojazdów do przypisania.',
        },
    },
    auth: {
        header: {
            courier: 'Courier',
        },
        authFooter: {
            srTitle: '',
            addressColumn: {
                title: 'PaliPali',
            },
            contactColumn: {
                title: 'Kontakt',
                correspondenceAddressTitle: 'Adres do korespondencji:',
            },
            linksColumn: {
                title: 'PaliPali',
                aboutUs: 'About us',
                career: 'Career',
                cooperation: 'Cooperation',
                contact: 'Contact',
                policies: 'Regulations and privacy policy',
                safePayments: 'Safe payments',
            },
            newsletterColumn: {
                title: 'Stay updated',
                subscribeInfo: 'Subscribe to our newsletter',
                newsletterLabelSR: 'Enter your e-mail address to receive the newsletter',
                newsletterSubmitButtonSR: 'Newsletter subscription form approval button',
            },
            copyright: 'PaliPali {{date}} © All right reserved.',
            socialIcons: {
                facebook: 'Link to Facebook profile',
                instagram: 'Link to Instagram profile',
                linkedin: 'Link to Linkedin profile',
                youtube: 'Link to Youtube channel',
            },
        },
        formControls: {
            email: 'E-mail',
            password: 'Hasło',
            newPassword: 'Nowe hasło',
            confirmPassword: 'Powtórz hasło',
            repeatPassword: 'Powtórz hasło',
            placeholderConfirmPassword: 'Powtórz hasło',
            phone: 'Numer telefonu',
            city: 'Miasto',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            dialingCode: 'Prefiks',
            privacyPolicy: 'Zapoznałem się z <0>Regulaminem</0> oraz <1>Polityką Prywatności</1> aplikacji PaliPali.',
            placeholders: {
                email: 'E-mail',
                password: 'Hasło',
                repeatPassword: 'Powtórz hasło',
                confirmPassword: 'Powtórz hasło',
                city: 'Wprowadź miasto',
                firstName: 'Imię',
                lastName: 'Nazwisko',
                phone: 'Numer telefonu',
                phoneCode: 'Kod',
            },
        },
        login: {
            title: 'Logowanie',
            haveAccount: 'Nowy w PaliPali? ',
            register: 'Zarejestruj się.',
            resetPassword: 'Zapomniałeś hasło?',
            loginButton: 'Zaloguj się',
            availableCities: 'Dostępne miasta',
            availableCitiesDescription: 'Dowiedz się, w jakich miastach możesz realizować przejazdy z wykorzystaniem aplikacji PaliPali.',
            availableCitiesLink: 'Sprawdź <0>palipali.pl/miasta</0> i bądź na bieżąco.',
        },
        resetPassword: {
            title: 'Resetowanie hasła',
            subtitle: 'Wprowadź adres e-mail. Wyślemy na niego link do zmiany hasła.',
            sendEmailButton: 'Wyślij link do zmiany hasła',
            backToLoginButton: 'Logowanie',
        },
        register: {
            title: 'Rejestracja',
            accountCreated: 'Posiadasz już konto? ',
            createAccount: 'Załóż konto Partnera Flotowego',
            login: 'Zaloguj się.',
            registerButton: 'Zarejestruj się',
        },
        confirmRegistration: {
            title: 'Potwierdzenie rejestracji',
            subtitle: 'Aby zakończyć rejestrację na platformie, kliknij poniższy przycisk:',
            confirmButton: 'Potwierdź',
        },
        newPassword: {
            title: 'Resetowanie hasła',
            subtitle: 'Wprowadź nowe hasło.',
            setPasswordButton: 'Ustaw nowe hasło',
        },
        confirmEmailChange: {
            title: 'Potwierdź zmianę adresu e-mail',
            subtitle: 'Żeby dokonać zmiany adresu e-mail kliknij na przycisk niżej',
            confirmButton: 'Potwierdź',
        },
        courierSuccessPage: {
            title: 'Dziękujemy.',
            loading: 'Trwa operacja potwierdzania zaproszenia...',
            subtitle:
                'Operacja została zakończona. Jeżeli konieczne są dalsze kroki, informacje znajdziesz w wiadomości e-mail. W przeciwnym razie kontynuuj działanie w aplikacji mobilnej. Teraz możesz zamknąć tę stronę.',
            success: 'Operacja zakończona powodzeniem - możesz teraz zamknąc to okno i zalogować się w aplikacji mobilnej.',
            failure: 'Operacja zakończona niepowodzeniem - spróbuj ponownie później lub skontaktuj się z administratorem.',
        },
        alert: {
            invalidConfirmRegistrationURL: 'Nieprawidłowy link potwierdzający rejestrację.',
            loginSuccess: 'Logowanie udane.',
            loginFailed: 'Nieprawidłowy login lub hasło. Spróbuj ponownie.',
            logout: 'Zostałeś wylogowany.',
            registrationSuccess: 'Pomyślnie zarejestrowano. Sprawdź swój e-mail w celu potwierdzenia rejestracji.',
            confirmRegistrationSuccess: 'Twój e-mail został potwierdzony.',
            confirmCourierRegistrationSuccess: 'Twój e-mail został potwierdzony. Przejdź do aplikacji mobilnej aby się zalogować.',
            sendResetPasswordMailSuccess: 'Sprawdź swój e-mail w celu uzyskania linku do resetowania hasła.',
            confirmResetPasswordSuccess: 'Twoje hasło zostało zmienione. Możesz się zalogować do panelu.',
            confirmCourierResetPasswordSuccess: 'Twoje hasło zostało zmienione. Przejdź do aplikacji mobilnej aby się zalogować.',
            authenticationError: 'Musisz być zalogowany, aby uzyskać dostęp do danych.',
            courierEmailChangeSuccess: 'Twój e-mail został zmieniony. Przejdź do aplikacji mobilnej aby się zalogować.',
            confirmFleetPartnerInvitationSuccess:
                'Twoje zaproszenie zostało potwierdzone. Przejdź do aplikacji mobilnej aby się zalogować.',
        },
    },
    packageSizes: {
        label: 'Dostępne kategorie zleceń',
        maxWeight: 'do {{weight}} kg',
        max: 'max.',
        s: {
            label: 'S',
        },
        m: {
            label: 'M',
        },
        l: {
            label: 'L',
        },
    },
    buttons: {
        add: 'Dodaj',
        addNew: 'Dodaj nowy',
        upload: 'Wyślij',
        more: 'Więcej',
        edit: 'Edytuj',
        saveChanges: 'Zapisz zmiany',
        discard: 'Odrzuć',
        apply: 'Zastosuj',
        buy: 'Kup',
        readLess: 'Mniej...',
        readMore: 'Więcej...',
        close: 'Zamknij',
        join: 'Dołącz',
        showAll: 'Pokaz wszystkie',
        next: 'Następny',
        previous: 'Poprzedni',
        submit: 'Zatwierdź',
        change: 'Zmień',
        remove: 'Usuń',
        update: 'Aktualizuj',
        resetPassword: 'Resetuj hasło',
        cancel: 'Anuluj',
        create: 'Stwórz',
        confirm: 'Zatwierdź',
        sendInvitation: 'Wyślij zaproszenie',
        view: 'Zobacz',
        detach: 'Odłącz',
        delete: 'Usuń',
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Zeszły miesiąc',
            lastWeek: 'Zeszły tydzień',
            today: 'Dziś',
            currentMonth: 'Ten miesiąc',
        },
    },
    formValidation: {
        errors: {
            minLength: 'Wartość jest zbyt krótka',
            maxLength: 'Wartość jest zbyt długa',
            isEmailValid: 'E-mail jest niepoprawny',
            isPostCodeValid: 'Kod pocztowy jest nieprawidłowy',
            isRequired: 'Pole jest wymagane',
            isNipValid: 'NIP jest nieprawidłowy',
            isPhoneValid: 'Nieprawidłowy numer telefonu',
            isMobileValid: 'Nieprawidłowy numer komórkowy',
            isCheckedTrue: 'Pole powinno być zaznaczone',
            isTrue: 'Pole powinno być zaznaczone',
            isPasswordSame: 'Hasła nie zgadzają się',
            isStartDateValid: 'Proszę wybrać datę z przyszłości',
            isStartDateGreaterOrEvenValid: 'Proszę wybrać datę z przyszłości',
            isEndDateValid: 'Proszę wybrać datę powrotu po dacie wylotu',
            isNumber: 'Wartość powinna być liczbą',
            isPasswordString: 'Hasło jest nieprawidłowe.',
            atLeastOneChecked: 'Proszę zaznaczyć przynajmniej jedną opcję',
            termsAndConditions: 'Należy zaakceptować warunki.',
            isInteger: 'Wartość powinna być liczbą całkowitą',
            isPositiveNumber: 'Wartość powinna być liczbą dodatnią',
            isLengthValid: 'Długość jest nieprawidłowa',
            noRepeatedSpecialCharacters: 'Może być tylko jeden znak łączący',
            containingLetterNotStartingWithWhitespace: 'Pole nie może zaczynać ani kończyć się spacją',
            noEndWithDash: 'Nie może kończyć ani zaczynać się znakiem specjalnym',
            onlyLetters: 'Dozwolone są litery, spacja, apostrof i myślnik',
            isLaterThanNow: 'Data musi być w przyszłości',
            isProcessAtLaterThanNow: 'Data realizacji musi być w przyszłości',
        },
    },
    footer: {
        copyright: `PaliPali {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: '404 - page not found',
    },
    general: {
        clearTextButton: 'Wyczyść',
    },
    menuItems: {
        activeCouriers: 'Aktywni Kurierzy',
        reports: 'Raporty',
        settlements: 'Rozliczenia',
        couriers: 'Kurierzy',
        vehicles: 'Pojazdy',
        account: 'Konto',
        logout: 'Wyloguj',
    },
    alerts: {
        warning: 'Uwaga!',
        error: 'Błąd!',
        success: 'Sukces!',
        info: 'Informacja',
        authError: 'Nazwa użytkownika lub hasło są nieprawidłowe.',
        noAccessError: 'Nie masz uprawnień do przeglądania tej strony.',
        wrongAccountError: 'Nie możesz się zalogować do tej aplikacji za pomocą tego konta.',
        baseError: 'Wystąpił błąd, skontaktuj się z administratorem.',
        loginError: 'Wystąpił błąd przy logowaniu, spróbuj ponownie.',
        tryAgain: 'Spróbuj ponownie',
        unknown: 'Wystąpił nieznany błąd.',
        accessDenied: 'Odmowa dostępu',
        accountNotConfirmed: 'Konto nie zostało potwierdzone.',
        accountNotActive: 'Konto nie jest aktywne.',
        accountDeleted: 'Konto zostało usunięte.',
        accountIsInvitation: 'Zaproszenie od Partnera Flotowego nie zostało jeszcze zaakceptowane, sprawdź e-mail.',
        invalidOrExpiredToken: 'Twój token wygasł lub jest nieprawidłowy.',
        expiredTokenLogout: 'Twoja sesja wygasła. Zostałeś wylogowany.',
    },
    month: {
        january: 'Styczeń',
        february: 'Luty',
        march: 'Marzec',
        april: 'Kwiecień',
        may: 'Maj',
        june: 'Czerwiec',
        july: 'Lipiec',
        august: 'Sierpień',
        september: 'Wrzesień',
        october: 'Październik',
        november: 'Listopad',
        december: 'Grudzień',
    },
    weekday: {
        sunday: 'Nd',
        monday: 'Pon',
        tuesday: 'Wt',
        wednesday: 'Śr',
        thursday: 'Czw',
        friday: 'Pt',
        saturday: 'Sob',
    },
    datepickerWeekday: {
        Su: 'Nd',
        Mo: 'Pon',
        Tu: 'Wt',
        We: 'Śr',
        Th: 'Czw',
        Fr: 'Pt',
        Sa: 'Sob',
    },
    pagination: {
        pageCount: '<0>{{ page }}</0> z <1>{{ totalPages }}</1>',
    },
    jobStatusDetails: {
        planned: 'Zaplanowane',
        searching: 'Szukanie',
        drive: 'W drodze',
        pickup: 'Podjęcie przesyłki',
        delivery: 'Wydanie przesyłki',
        delivered: 'Zrealizowane',
        cancelledPurchaser: 'Anulowane',
        cancelledCourier: 'Anulowane przez Kuriera',
        problemPurchaser: 'Zgłoszone',
        problemCourier: 'Zgłoszone przez Kuriera',
        deliveryDrive: 'W doręczeniu',
        failed: 'Niepowodzenie',
    },
    countryPhoneCodes: {
        AD: 'Andora (376)',
        AE: 'Zjednoczone Emiraty Arabskie (971)',
        AF: 'Afganistan (93)',
        AG: 'Antigua i Barbuda (1-268)',
        AI: 'Anguilla (1-264)',
        AL: 'Albania (355)',
        AM: 'Armenia (374)',
        AN: 'Antyle Holenderskie (599)',
        AO: 'Angola (244)',
        AQ: 'Antarktyka (672)',
        AR: 'Argentyna (54)',
        AS: 'Samoa Amerykańskie (1-684)',
        AT: 'Austria (43)',
        AU: 'Australia (61)',
        AW: 'Aruba (297)',
        AZ: 'Azerbejdżan (994)',
        BA: 'Bośnia i Hercegowina (387)',
        BB: 'Barbados (1-246)',
        BD: 'Bangladesz (880)',
        BE: 'Belgia (32)',
        BF: 'Burkina Faso (226)',
        BG: 'Bułgaria (359)',
        BH: 'Bahrajn (973)',
        BI: 'Burundi (257)',
        BJ: 'Benin (229)',
        BL: 'Saint-Barthélemy (590)',
        BM: 'Bermudy (1-441)',
        BN: 'Brunei (673)',
        BO: 'Boliwia (591)',
        BR: 'Brazylia (55)',
        BS: 'Bahamy (1-242)',
        BT: 'Bhutan (975)',
        BW: 'Botswana (267)',
        BY: 'Białoruś (375)',
        BZ: 'Belize (501)',
        CA: 'Kanada (1)',
        CC: 'Wyspy Kokosowe (61)',
        CD: 'Demokratyczna Republika Konga (243)',
        CF: 'Republika Środkowoafrykańska (236)',
        CG: 'Kongo (242)',
        CH: 'Szwajcaria (41)',
        CI: "Côte d'Ivoire (225)",
        CK: 'Wyspy Cooka (682)',
        CL: 'Chile (56)',
        CM: 'Kamerun (237)',
        CN: 'Chiny (86)',
        CO: 'Kolumbia (57)',
        CR: 'Kostaryka (506)',
        CU: 'Kuba (53)',
        CV: 'Republika Zielonego Przylądka (238)',
        CW: 'Curaçao (599)',
        CX: 'Wyspa Bożego Narodzenia (61)',
        CY: 'Cypr (357)',
        CZ: 'Czechy (420)',
        DE: 'Niemcy (49)',
        DJ: 'Dżibuti (253)',
        DK: 'Dania (45)',
        DM: 'Dominika (1-767)',
        DO: 'Republika Dominikańska (1-809)',
        DZ: 'Algieria (213)',
        EC: 'Ekwador (593)',
        EE: 'Estonia (372)',
        EG: 'Egipt (20)',
        EH: 'Sahara Zachodnia (212)',
        ER: 'Erytrea (291)',
        ES: 'Hiszpania (34)',
        ET: 'Etiopia (251)',
        FI: 'Finlandia (358)',
        FJ: 'Fidżi (679)',
        FK: 'Falklandy (500)',
        FM: 'Mikronezja (691)',
        FO: 'Wyspy Owcze (298)',
        FR: 'Francja (33)',
        GA: 'Gabon (241)',
        GB: 'Wielka Brytania (44)',
        GD: 'Grenada (1-473)',
        GE: 'Gruzja (995)',
        GG: 'Guernsey (44-1481)',
        GH: 'Ghana (233)',
        GI: 'Gibraltar (350)',
        GL: 'Grenlandia (299)',
        GM: 'Gambia (220)',
        GN: 'Gwinea (224)',
        GQ: 'Gwinea Równikowa (240)',
        GR: 'Grecja (30)',
        GT: 'Gwatemala (502)',
        GU: 'Guam (1-671)',
        GW: 'Gwinea Bissau (245)',
        GY: 'Gujana (592)',
        HN: 'Hongkong (852)',
        HR: 'Chorwacja (385)',
        HT: 'Haiti (509)',
        HU: 'Węgry (36)',
        ID: 'Indonezja (62)',
        IE: 'Irlandia (353)',
        IL: 'Izrael (972)',
        IM: 'Wyspa Man (44-1624)',
        IN: 'Indie (91)',
        IO: 'Brytyjskie Terytorium Oceanu Indyjskiego (246)',
        IQ: 'Irak (964)',
        IR: 'Iran (98)',
        IS: 'Islandia (354)',
        IT: 'Włochy (39)',
        JE: 'Jersey (44-1534)',
        JM: 'Jamajka (1-876)',
        JO: 'Jordania (962)',
        JP: 'Japonia (81)',
        KE: 'Kenia (254)',
        KG: 'Kirgistan (996)',
        KH: 'Kambodża (588)',
        KI: 'Kiribati (686)',
        KM: 'Komory (269)',
        KN: 'Saint Kitts i Nevis (1-869)',
        KP: 'Korea Północna (850)',
        KR: 'Korea Południowa (82)',
        KW: 'Kuwejt (965)',
        KY: 'Kajmany (1-345)',
        KZ: 'Kazachstan (7)',
        LA: 'Laos (856)',
        LB: 'Liban (961)',
        LC: 'Saint Lucia (1-758)',
        LI: 'Liechtenstein (423)',
        LK: 'Sri Lanka (94)',
        LR: 'Liberia (231)',
        LS: 'Lesotho (266)',
        LT: 'Litwa (370)',
        LU: 'Luksemburg (352)',
        LV: 'Łotwa (371)',
        LY: 'Libia (218)',
        MA: 'Maroko (212)',
        MC: 'Monako (373)',
        MD: 'Mołdawia (373)',
        ME: 'Czarnogóra (382)',
        MF: 'Saint Martin (590)',
        MG: 'Madagaskar (261)',
        MH: 'Wyspy Marshalla (692)',
        MK: 'Macedonia Północna (389)',
        ML: 'Mali (223)',
        MM: 'Myanmar (95)',
        MN: 'Mongolia (976)',
        MO: 'Makau (853)',
        MP: 'Mariany Północne (1-670)',
        MR: 'Mauretania (222)',
        MS: 'Montserrat (1-664)',
        MT: 'Malta (356)',
        MU: 'Mauritius (230)',
        MV: 'Malediwy (960)',
        MW: 'Malawi (265)',
        MX: 'Meksyk (52)',
        MY: 'Malezja (60)',
        MZ: 'Mozambik (258)',
        NA: 'Namibia (264)',
        NC: 'Nowa Kaledonia (687)',
        NE: 'Niger (227)',
        NG: 'Nigeria (234)',
        NI: 'Nikaragua (505)',
        NL: 'Holandia (31)',
        NO: 'Norwegia (47)',
        NP: 'Nepal (977)',
        NR: 'Nauru (674)',
        NU: 'Niue (683)',
        NZ: 'Nowa Zelandia (64)',
        OM: 'Oman (968)',
        PA: 'Panama (507)',
        PE: 'Peru (51)',
        PF: 'Polinezja Francuska (689)',
        PG: 'Papua-Nowa Gwinea (675)',
        PH: 'Filipiny (63)',
        PK: 'Pakistan (92)',
        PL: 'Polska (48)',
        PM: 'Saint-Pierre i Miquelon (508)',
        PN: 'Pitcairn (64)',
        PR: 'Portoryko (1-787)',
        PS: 'Palestyna (970)',
        PT: 'Portugalia (351)',
        PW: 'Palau (680)',
        PY: 'Paragwaj (595)',
        QA: 'Katar (974)',
        RO: 'Rumunia (40)',
        RS: 'Serbia (381)',
        RU: 'Rosja (7)',
        RW: 'Rwanda (250)',
        SA: 'Arabia Saudyjska (966)',
        SB: 'Wyspy Salomona (677)',
        SC: 'Seszele (248)',
        SD: 'Sudan (249)',
        SE: 'Szwecja (46)',
        SG: 'Singapur (65)',
        SH: 'Święta Helena (290)',
        SI: 'Słowenia (386)',
        SJ: 'Svalbard i Jan Mayen (47)',
        SK: 'Słowacja (421)',
        SL: 'Sierra Leone (232)',
        SM: 'San Marino (378)',
        SN: 'Senegal (221)',
        SO: 'Somalia (252)',
        SR: 'Surinam (597)',
        SS: 'Sudan Południowy (211)',
        ST: 'Wyspy Świętego Tomasza i Książęca (239)',
        SV: 'Salwador (503)',
        SX: 'Sint Maarten (1-721)',
        SY: 'Syria (963)',
        SZ: 'Eswatini (268)',
        TD: 'Czad (235)',
        TG: 'Togo (228)',
        TH: 'Tajlandia (66)',
    },
    // special auth errors from the backend
    'An authentication exception occurred.': 'Wystąpił błąd uwierzytelniania.',
    'Authentication credentials could not be found.': 'Dane uwierzytelniania nie zostały znalezione.',
    'Authentication request could not be processed due to a system problem.':
        'Żądanie uwierzytelniania nie mogło zostać pomyślnie zakończone z powodu problemu z systemem.',
    'Invalid credentials.': 'Nieprawidłowe dane.',
    'Cookie has already been used by someone else.': 'To ciasteczko jest używane przez kogoś innego.',
    'Not privileged to request the resource.': 'Brak uprawnień dla żądania wskazanego zasobu.',
    'Invalid CSRF token.': 'Nieprawidłowy token CSRF.',
    'No authentication provider found to support the authentication token.':
        'Nie znaleziono mechanizmu uwierzytelniania zdolnego do obsługi przesłanego tokenu.',
    'No session available, it either timed out or cookies are not enabled.':
        'Brak danych sesji, sesja wygasła lub ciasteczka nie są włączone.',
    'No token could be found.': 'Nie znaleziono tokenu.',
    'Username could not be found.': 'Użytkownik o podanej nazwie nie istnieje.',
    'Account has expired.': 'Konto wygasło.',
    'Credentials have expired.': 'Dane uwierzytelniania wygasły.',
    'Account is disabled.': 'Konto jest wyłączone.',
    'Account is locked.': 'Konto jest zablokowane.',
    'Too many failed login attempts, please try again later.': 'Zbyt dużo nieudanych prób logowania, proszę spróbować ponownie później.',
    'Invalid or expired login link.': 'Nieprawidłowy lub wygasły link logowania.',
    'Too many failed login attempts, please try again in %minutes% minute.':
        'Zbyt wiele nieudanych prób logowania, spróbuj ponownie po upływie %minutes% minut.',
    'Too many failed login attempts, please try again in %minutes% minutes.':
        'Zbyt wiele nieudanych prób logowania, spróbuj ponownie po upływie %minutes% minut.',
    // end special auth errors from the backend
};

export default pl;
