import {Box, Stack, Typography} from '@mui/material';
import React from 'react';
import LocationFormattedAddress, {LocationFormattedAddressType} from '../LocationFormattedAddress';
import {IJobLocationShortOutput, IPurchaserCurrentJobDetailsOutput, JobStatus} from '../../../types';
import TimeComponent from '../../TimeComponent';
import PackageIcon from '../../../theme/icons/untitled-ui/duocolor/package-icon';
import PinIcon from '../../../theme/icons/untitled-ui/duocolor/pin-icon';

export enum LocationPointBoxType {
    ACTIVE = 'active',
    HISTORICAL = 'historical',
}

export enum LocationPointType {
    SENDER = 'sender',
    RECEIVER = 'receiver',
}

interface ILocationPointBoxProps {
    type: LocationPointBoxType;
    locationType: LocationPointType;
    job: IPurchaserCurrentJobDetailsOutput;
}

const LocationPointBox: React.FC<ILocationPointBoxProps> = (props: ILocationPointBoxProps) => {
    const location: IJobLocationShortOutput =
        props.locationType === LocationPointType.SENDER ? props.job.fromLocation : props.job.toLocation;
    const pickupTime =
        props.job.pickupAt &&
        (props.job.deliveryStatus === JobStatus.DELIVERY_DRIVE ||
            props.job.deliveryStatus === JobStatus.DELIVERY ||
            props.job.deliveryStatus === JobStatus.COMPLETED) &&
        props.locationType === LocationPointType.SENDER
            ? props.job.pickupAt
            : null;
    const deliveryTime =
        props.job.deliveryAt && props.job.deliveryStatus === JobStatus.COMPLETED && props.locationType === LocationPointType.RECEIVER
            ? props.job.deliveryAt
            : null;
    const isSender = props.locationType === LocationPointType.SENDER;
    const getTime = () => {
        if (props.locationType === LocationPointType.SENDER) {
            if (
                props.job.pickupAt &&
                (props.job.deliveryStatus === JobStatus.DELIVERY_DRIVE ||
                    props.job.deliveryStatus === JobStatus.DELIVERY ||
                    props.job.deliveryStatus === JobStatus.COMPLETED)
            ) {
                return props.job.pickupAt;
            }

            return props.job.estimatedPickupAt;
        }
        if (props.locationType === LocationPointType.RECEIVER) {
            if (props.job.deliveryAt && props.job.deliveryStatus === JobStatus.COMPLETED) {
                return props.job.deliveryAt;
            }
            return props.job.estimatedDeliveryAt;
        }
    };

    const renderIcon = () => {
        return props.locationType === LocationPointType.SENDER ? <PackageIcon className="road-icon" /> : <PinIcon className="road-icon" />;
    };

    const renderTime = () => {
        if (props.type === LocationPointBoxType.ACTIVE) {
            return (
                <Typography variant="caption" color="text.secondary">
                    <TimeComponent
                        application="purchaser"
                        date={getTime()}
                        time={props.job.estimatedTime}
                        isSimpleTime
                        isEstimatedTime={isSender ? pickupTime === null : deliveryTime === null}
                    />
                </Typography>
            );
        }
        if (props.type === LocationPointBoxType.HISTORICAL) {
            const deliveryOrPickupTime = isSender ? props.job.pickupAt : props.job.deliveryAt;
            if (!deliveryOrPickupTime) return;
            return (
                <Typography variant="caption" color="text.secondary">
                    <TimeComponent
                        application="purchaser"
                        date={deliveryOrPickupTime ? deliveryOrPickupTime : null}
                        time={props.job.time}
                        isSimpleTime
                    />
                </Typography>
            );
        }
        return (
            <Typography variant="caption" color="text.secondary">
                <TimeComponent application="purchaser" date={props.job.createdAt} time={props.job.time} />
            </Typography>
        );
    };

    return (
        <div className="location-point-box">
            {renderIcon()}
            <Stack className="point-details">
                {location && location.name && (
                    <Typography className="location-name" variant="h5" color="text.primary">
                        {location.name}
                    </Typography>
                )}

                <Box>
                    <Typography variant="subtitle2" component="p" color="text.primary">
                        <LocationFormattedAddress address={location} addressType={LocationFormattedAddressType.TEAM} />
                    </Typography>

                    {renderTime()}
                </Box>
            </Stack>
        </div>
    );
};

export default LocationPointBox;
