import moment, {Moment} from 'moment';
import {getCurrencySymbol} from './currencyHelpers';
import {translatePathToValue} from './TranslationTools';
import {DateFormat} from '../constants/miscellaneous';
import {IMoneyOutput} from '../model/common';
import {isNotNullOrUndefined} from './runtimeUtils';

export const rangeDatesString = (dates?: string[]): string => {
    if (!dates || dates.length === 0) {
        return translatePathToValue('general.noDataInfo');
    }

    if (dates.length === 1 || dates[0] === dates[1]) {
        return `${moment(dates[0]).format(DateFormat.DateDefaultDisplay)}`;
    }

    return `${moment(dates[0]).format(DateFormat.DateDefaultDisplay)} - ${moment(dates[dates.length - 1]).format(
        DateFormat.DateDefaultDisplay
    )}`;
};

export const moneyString = (value?: IMoneyOutput | null, noCurrency = false): string => {
    if (noCurrency) {
        return value?.amount ? `${(+value.amount / 100).toFixed(2)}` : '0';
    }
    return value?.amount ? `${(+value.amount / 100).toFixed(2)} ${getCurrencySymbol(value.currency)}` : '0';
};

export const meterString = (value: string | number | undefined): string => (value ? `${+value / 100}` : '');
export const meterNumber = (value: string | number | undefined): number => (value ? +value / 100 : 0);

export const formatSecondsToDuration = (seconds: number | null): string => {
    if (seconds === null || seconds === 0) {
        return '-';
    }

    const hours = Math.floor(seconds / 3600),
        minutes = Math.floor((seconds % 3600) / 60);

    if (hours === 0 && minutes === 0) {
        return '< 1 min';
    }

    const formattedHours = hours > 0 ? `${hours} h` : '',
        formattedMinutes = minutes > 0 ? `${minutes} min` : '';

    return [formattedHours, formattedMinutes].filter(Boolean).join(' ').trim();
};

export const userFullName = (firstName?: string | null, lastName?: string | null): string => {
    if (!firstName || !firstName) {
        return '-';
    }
    return `${firstName} ${lastName}`;
};

export const timeWithHoursAndMinutes = (timeInSeconds?: number | null) => {
    if (!timeInSeconds) {
        return `${0}h ${0}min`;
    }

    const totalMinutes = Math.floor(timeInSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}h ${minutes}min`;
};

export const formatNumberToMoneyWithThousands = (value?: IMoneyOutput | null, noCurrency?: boolean): string => {
    if (noCurrency) {
        return value?.amount ? `${(+value.amount / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}` : `0.00`;
    }

    return value?.amount
        ? `${(+value.amount / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${getCurrencySymbol(value.currency)}`
        : `0.00`;
};

export const formattedMetersToKilometers = (distance: number | null | undefined) => {
    if (!distance) {
        return '0 km';
    }

    return `${(distance / 1000).toFixed(2)} km`;
};

export const formattedRatingValue = (value: number) => (value / 10).toFixed(1);

export const ifUndefinedSetEmptyString = (value: string | undefined | null): string => {
    return isNotNullOrUndefined(value) ? value : '';
};

export const serverFormattedDateWithCustomTime = (
    date?: Date | Moment | string,
    mode: 'current' | 'endOfDay' | 'startOfDay' = 'current'
): string => {
    if (mode === 'current') {
        return moment(date).utc(false).format();
    }
    if (mode === 'endOfDay') {
        return moment(date).endOf('day').utc(false).format();
    }
    return moment(date).startOf('day').utc(false).format();
};
