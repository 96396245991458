import React from 'react';

const AuthBackground: React.FC = () => {
    return (
        <svg width="1920" height="502" viewBox="0 0 1920 502" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11776_128604)">
                <g opacity="0.8">
                    <g opacity="0.3" filter="url(#filter0_f_11776_128604)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1516.45 318.144L1454.35 416.375L1067.71 -40.9172L1129.81 -139.149L1516.45 318.144Z"
                            fill="url(#paint0_linear_11776_128604)"
                        />
                    </g>
                    <g opacity="0.3" filter="url(#filter1_f_11776_128604)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M761.912 338.732L723.864 416.783L337.712 -40.9179L375.76 -118.969L761.912 338.732Z"
                            fill="url(#paint1_linear_11776_128604)"
                        />
                    </g>
                    <g opacity="0.3" filter="url(#filter2_f_11776_128604)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1215.91 220.732L1177.86 298.783L791.712 -158.918L829.76 -236.969L1215.91 220.732Z"
                            fill="url(#paint2_linear_11776_128604)"
                        />
                    </g>
                    <g opacity="0.3" filter="url(#filter3_f_11776_128604)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1120.32 257.322L933.291 474.668L424.748 -122.482L611.773 -339.828L1120.32 257.322Z"
                            fill="url(#paint3_linear_11776_128604)"
                        />
                    </g>
                    <g opacity="0.3" filter="url(#filter4_f_11776_128604)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M632.605 355.488L597.962 437.596L154.117 -88.8609L188.76 -170.969L632.605 355.488Z"
                            fill="url(#paint4_linear_11776_128604)"
                        />
                    </g>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_f_11776_128604"
                    x="967.712"
                    y="-239.148"
                    width="648.737"
                    height="755.524"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_11776_128604" />
                </filter>
                <filter
                    id="filter1_f_11776_128604"
                    x="237.712"
                    y="-218.969"
                    width="624.199"
                    height="735.752"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_11776_128604" />
                </filter>
                <filter
                    id="filter2_f_11776_128604"
                    x="691.712"
                    y="-336.969"
                    width="624.199"
                    height="735.752"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_11776_128604" />
                </filter>
                <filter
                    id="filter3_f_11776_128604"
                    x="324.748"
                    y="-439.828"
                    width="895.568"
                    height="1014.5"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_11776_128604" />
                </filter>
                <filter
                    id="filter4_f_11776_128604"
                    x="54.1165"
                    y="-270.969"
                    width="678.488"
                    height="808.565"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_11776_128604" />
                </filter>
                <linearGradient
                    id="paint0_linear_11776_128604"
                    x1="1099.27"
                    y1="-113.525"
                    x2="1468.23"
                    y2="326.183"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6366F1" />
                    <stop offset="1" stop-color="#6366F1" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11776_128604"
                    x1="353.882"
                    y1="-100.611"
                    x2="722.84"
                    y2="339.096"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6366F1" />
                    <stop offset="1" stop-color="#6366F1" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11776_128604"
                    x1="807.882"
                    y1="-218.611"
                    x2="1176.84"
                    y2="221.096"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6366F1" />
                    <stop offset="1" stop-color="#6366F1" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_11776_128604" x1="533.72" y1="-274.334" x2="1020" y2="338" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#14B8A6" />
                    <stop offset="1" stop-color="#14B8A6" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_11776_128604"
                    x1="591.141"
                    y1="353.001"
                    x2="166.882"
                    y2="-152.611"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0EA5E9" />
                    <stop offset="1" stop-color="#0EA5E9" stop-opacity="0" />
                </linearGradient>
                <clipPath id="clip0_11776_128604">
                    <rect width="1920" height="502" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default AuthBackground;
