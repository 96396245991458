import React from 'react';
import {ILoaderProps, LoaderType} from '../../types';
import {useTheme, CircularProgress} from '@mui/material';

type Props = ILoaderProps;

const Loader: React.FC<Props> = ({showLoader, type, customClass}) => {
    const showClass = showLoader ? 'visible' : 'hidden',
        loaderType = type === LoaderType.Local ? 'local' : '';

    const theme = useTheme();

    return (
        <div className={`loader-container ${loaderType} ${showClass} ${customClass || ''} ${theme.palette?.mode}`}>
            <CircularProgress color="primary" />
        </div>
    );
};

export default Loader;
