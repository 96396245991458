import {Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Stack, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import DimensionSpacerIcon from '../../icons/untitled-ui/duocolor/dimensions-spacer-icon';
import Translation from '../../../components/Translation';
import CheckedPackageIcon from '../../icons/untitled-ui/duocolor/check-package';
import {PackageTypes, packageParametersSelector} from '../../../store/selectors/systemParametersSelectors';
import {stringToNumber} from '../../../utils/stringUtils';

export type VehiclePackageItem = {
    label: string;
    value: string;
    length: number;
    width: number;
    height: number;
    dimensionUnit: string;
    weight: number;
};

type PackageSize = {
    s: boolean;
    m: boolean;
    l: boolean;
};
interface PackageSizeFormControlProps {
    formControlName: string;
    values: PackageSize;
    formik: any;
    checkboxHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    customDisabled?: (values: any) => boolean;
    label?: string;
    valueToDisable?: string;
}

const PackageSizeFormControl: React.FC<PackageSizeFormControlProps> = ({
    formControlName,
    values,
    checkboxHandleChange,
    customDisabled,
    formik,
    label,
    valueToDisable,
}) => {
    const {t} = useTranslation(),
        packageParameters: PackageTypes = useSelector(packageParametersSelector);

    const packageSizes: VehiclePackageItem[] = [
        {
            label: 'packageSizes.s.label',
            value: 's',
            length: stringToNumber(packageParameters.s.a),
            width: stringToNumber(packageParameters.s.b),
            height: stringToNumber(packageParameters.s.c),
            dimensionUnit: 'cm',
            weight: stringToNumber(packageParameters.s.weight),
        },
        {
            label: 'packageSizes.m.label',
            value: 'm',
            length: stringToNumber(packageParameters.m.a),
            width: stringToNumber(packageParameters.m.b),
            height: stringToNumber(packageParameters.m.c),
            dimensionUnit: 'cm',
            weight: stringToNumber(packageParameters.m.weight),
        },
        {
            label: 'packageSizes.l.label',
            value: 'l',
            length: stringToNumber(packageParameters.l.a),
            width: stringToNumber(packageParameters.l.b),
            height: stringToNumber(packageParameters.l.c),
            dimensionUnit: 'cm',
            weight: stringToNumber(packageParameters.l.weight),
        },
    ];

    useEffect(() => {
        if (formik.touched[formControlName]) {
            if (!formik.values.s && !formik.values.m && !formik.values.l) {
                formik.setFieldValue(formControlName, false);
            } else {
                formik.setFieldValue(formControlName, true);
            }
        }
    }, [formik.values]);

    const getDataPackageValueFromForm = (packageName: string): boolean => {
        switch (packageName) {
            case 's':
                return values.s;
            case 'm':
                return values.m;
            case 'l':
                return values.l;
            default:
                return false;
        }
    };
    const packageDimensionsDetails = (option: VehiclePackageItem) => {
        return (
            <Stack spacing={1} key={`${option.value}-stack-wrapper`}>
                <span key={`${option.value}-additional-description-1`} className="additional-description">
                    {t('packageSizes.max')}
                </span>
                <span key={`${option.value}-additional-description-2`} className="additional-description">
                    <React.Fragment key={`${option.value}-additional-description-2-length`}>{option.length}</React.Fragment>
                    <DimensionSpacerIcon />
                    <React.Fragment key={`${option.value}-additional-description-2-width`}>{option.width}</React.Fragment>
                    <DimensionSpacerIcon />
                    <React.Fragment key={`${option.value}-additional-description-2-height`}>{option.height}</React.Fragment>
                    <React.Fragment key={`${option.value}-additional-description-2-dimensionUnit`}>
                        {t(option.dimensionUnit)}
                    </React.Fragment>
                </span>
                <span key={`${option.value}-additional-description-3`} className="additional-description">
                    <Translation
                        text={`packageSizes.maxWeight`}
                        config={{weight: option.weight}}
                        key={`${option.value}-additional-description-3-text`}
                    />
                </span>
            </Stack>
        );
    };
    const isError = formik.touched[formControlName] && formik.errors[formControlName];

    return (
        <FormControl
            component="fieldset"
            key={`fieldset-form-control-packages-${formControlName}`}
            className={`packages-form-control-wrapper ${isError ? 'error' : ''}`}
            disabled={customDisabled ? customDisabled(values) : false}>
            <FormLabel component="legend" key={`legend-form-label-${formControlName}`}>
                <Typography
                    variant="subtitle1"
                    color={isError ? 'error' : 'text.primary'}
                    key={`legend-form-label-text-${formControlName}`}
                    paddingBottom={'0.4rem'}>
                    {label ? t(`${label}`) : t('packageSizes.label')}
                </Typography>
                <Divider />
            </FormLabel>
            <Box
                className={`package-item-container ${customDisabled ? 'disabled' : ''}`}
                key={`package-sizes-wrapper-box-${formControlName}`}>
                {packageSizes.map((option) => (
                    <FormControlLabel
                        key={`package-size-label-${option.value}-${formControlName}`}
                        className={`package-size-wrapper ${option.value} ${getDataPackageValueFromForm(option.value) ? 'checked' : ''}  ${
                            valueToDisable && option.value === valueToDisable ? 'specific-size-disabled' : ''
                        }`}
                        control={
                            <Box
                                className={`package-size-control ${getDataPackageValueFromForm(option.value) ? 'checked' : ''}`}
                                key={`package-size-box-${option.value}-${formControlName}`}>
                                {packageDimensionsDetails(option)}
                                <React.Fragment key={`${option.value}-checkbox-wrapper`}>
                                    <Checkbox
                                        key={`${option.value}-${formControlName}-form-checkbox`}
                                        id={`${option.value}-${formControlName}-input-id`}
                                        className="package-size-checkbox"
                                        checked={getDataPackageValueFromForm(option.value)}
                                        onChange={checkboxHandleChange}
                                        disabled={
                                            customDisabled
                                                ? customDisabled(values)
                                                : valueToDisable && option.value === valueToDisable
                                                ? true
                                                : false
                                        }
                                        name={option.value}
                                        sx={{display: 'none'}}
                                        icon={<span key={`${option.value}-${formControlName}-icon`} />}
                                    />
                                </React.Fragment>
                            </Box>
                        }
                        label={
                            getDataPackageValueFromForm(option.value) ? (
                                <CheckedPackageIcon />
                            ) : (
                                <Translation text={option.label} translationKey={`${option.value}-${formControlName}-label-size`} />
                            )
                        }
                    />
                ))}
            </Box>
            {isError && <FormHelperText className="package-sizes-error-wrapper"> {formik.errors[formControlName]} </FormHelperText>}
        </FormControl>
    );
};
export default PackageSizeFormControl;
