import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Uploader} from 'rsuite';
import {authTokenSelector} from '../..';
import {API_URL} from '../../config/entrypoint';
import {CommonRootState} from '../../store/reducers';
import Translation from '../Translation';
import {IFileType, IImageUploaderProps} from '../../types';

type Props = IImageUploaderProps;

const ImageUploader: React.FC<Props> = ({authToken, triggerText, selectedImageFile, onImageChange}) => {
    const [value, setValue] = useState([]);
    useEffect(() => {
        if (selectedImageFile) {
            let fileArray = [];
            fileArray.push(selectedImageFile);
            setValue(fileArray);
        }
    }, [selectedImageFile]);
    const handleUploadSuccess = (response: any, file: IFileType) => {
        if (response) {
            const imageId = response.id.replace('/media_object/', '');
            onImageChange(imageId, file);
        }
    };

    const removeFile = (file: IFileType) => {
        const uploadedFiles = value.filter((item: IFileType) => item.fileKey !== file.fileKey);

        setValue(uploadedFiles);
    };

    return (
        <Uploader
            removable={true}
            className={`form-input ${value.length > 0 ? 'uploaded' : 'empty'}`}
            action={`${API_URL as string}/api/media_objects`}
            multiple={false}
            listType="text"
            autoUpload={true}
            headers={{Authorization: 'Bearer ' + authToken}}
            onSuccess={handleUploadSuccess}
            onRemove={(file: IFileType) => removeFile(file)}
            fileList={value}>
            {triggerText && (
                <div>
                    <Translation text={triggerText} />
                </div>
            )}
        </Uploader>
    );
};

export default connect((state: CommonRootState) => ({
    authToken: authTokenSelector(state),
}))(ImageUploader);
