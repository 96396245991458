import {Action as ReduxAction} from 'redux';
import {Epic, StateObservable, ofType} from 'redux-observable';
import {Observable, from} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {
    Action,
    ActionType,
    ApiDeleteResponseFunction,
    ApiDetailsResponseFunction,
    ApiDictionaryResponseFunction,
    ApiOperationResponseFunction,
    ApiResponseFunction,
    DeleteSuccessActionsFunction,
    DetailsSuccessActionsFunction,
    ListSuccessActionsFunction,
    StateSuccessActionsFunction,
} from '../api/base/apiConnectionInfrastructure';
import {IModelApiResponseViewObject} from '../model/base';
import {DictionaryName} from '../model/dictionaryDatum';
import {CommonRootState} from '../store/reducers';
import {authTokenSelector} from '../store/selectors/authSelectors';
import {IApiResponseBase, IRawRestQueryParams} from '../types';

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

export const getMetadataDetails = (hydraViewData: any): IModelApiResponseViewObject | null => {
    const viewData = hydraViewData;
    let metadata: IModelApiResponseViewObject | null = null;

    if (viewData && viewData.hasOwnProperty('hydra:first') && viewData.hasOwnProperty('hydra:last')) {
        metadata = {
            'hydra:first': viewData['hydra:first'],
            'hydra:last': viewData['hydra:last'],
            'hydra:next': viewData['hydra:next'],
        };
    }

    if (viewData && viewData.hasOwnProperty('hydra:next') && metadata) {
        metadata['hydra:next'] = viewData['hydra:next'];
    }

    if (viewData && viewData.hasOwnProperty('hydra:previous') && metadata) {
        metadata['hydra:previous'] = viewData['hydra:previous'];
    }

    return metadata;
};

export const createEpic =
    <T, A extends ReduxAction = ReduxAction>(
        actionType: ActionType,
        apiFunction: (...args: any[]) => Observable<any>,
        successActionsFunction: (resp: any, state: CommonRootState) => any[],
        errorActions: (error: any, setSliceError?: any, setSliceIsLoading?: any) => any[],
        extraParams?: (state$: StateObservable<any>) => IRawRestQueryParams | [],
        payload?: (state$: StateObservable<CommonRootState>, action?: A) => any
    ): Epic =>
    (action$: Observable<A>, state$: StateObservable<any>) => {
        return action$.pipe(
            ofType(actionType),
            switchMap((action: A) => {
                const authToken = authTokenSelector(state$.value);
                const params = extraParams ? extraParams(state$.value) : [];
                const actionPayload = payload ? payload(state$, action) : undefined;
                return apiFunction(authToken, actionPayload, params).pipe(
                    switchMap((resp: any) => {
                        const actions = successActionsFunction(resp, state$.value);
                        return from(actions);
                    }),
                    catchError((error) => from(errorActions(error)))
                );
            }),
            catchError((error) => from(errorActions(error)))
        );
    };

export const createDeleteOperationEpic = <T, A extends ReduxAction = ReduxAction>(
    apiFunction: ApiDeleteResponseFunction,
    successActionsFunction: DeleteSuccessActionsFunction,
    errorActions: (error: any) => any[],
    actionType: ActionType
): Epic => createEpic(actionType, apiFunction, successActionsFunction, errorActions, undefined, (state$, action: any) => action.payload);

export const createOperationEpic = <T, A extends ReduxAction = ReduxAction>(
    apiFunction: ApiOperationResponseFunction<T>,
    successActionsFunction: DetailsSuccessActionsFunction<T>,
    errorActions: (error: any) => any[],
    actionType: ActionType
): Epic => createEpic(actionType, apiFunction, successActionsFunction, errorActions, undefined, (state$, action: any) => action.payload);

export const createActionPayloadEpic = <T, A extends ReduxAction = ReduxAction>(
    apiFunction: ApiDetailsResponseFunction<T>,
    successActionsFunction: StateSuccessActionsFunction<T>,
    errorActions: (error: any) => any[],
    actionType: ActionType
): Epic => createEpic(actionType, apiFunction, successActionsFunction, errorActions, undefined, (state$, action: any) => action.payload);

export const createDictionaryEpic = <T, A extends ReduxAction = ReduxAction>(
    apiFunction: ApiDictionaryResponseFunction<T>,
    listSuccessActionsFunction: ListSuccessActionsFunction<T>,
    errorActions: (error: any, setSliceError: any, setSliceIsLoading: any) => any[],
    actionType: ActionType,
    actionDictionary: DictionaryName
): Epic =>
    createEpic(
        actionType,
        apiFunction,
        (resp: IApiResponseBase<T[]>) => {
            const metadata = getMetadataDetails(resp['hydra:view']);
            const extrasMetadata = resp['extra:metadata'];
            return listSuccessActionsFunction(resp['hydra:member'], metadata, extrasMetadata);
        },
        errorActions,
        undefined,
        () => [actionDictionary.toString()]
    );

export const createFetchDetailsEpic = <T, A extends ReduxAction = ReduxAction>(
    apiFunction: ApiDetailsResponseFunction<T>,
    detailsSuccessActionsFunction: DetailsSuccessActionsFunction<T>,
    errorActions: (error: any) => any[],
    actionType: ActionType
): Epic =>
    createEpic(actionType, apiFunction, detailsSuccessActionsFunction, errorActions, undefined, (state$, action: any) => action.payload);

export const createFetchListEpic = <T, A extends ReduxAction = ReduxAction>(
    apiFunction: ApiResponseFunction<T>,
    listSuccessActionsFunction: ListSuccessActionsFunction<T>,
    errorActions: (error: any) => any[],
    actionType: ActionType,
    queryParameters?: (state: any) => IRawRestQueryParams | [],
    payload?: (state: any) => any
): Epic =>
    createEpic(
        actionType,
        apiFunction,
        (resp: IApiResponseBase<T[]>) => {
            const metadata = getMetadataDetails(resp['hydra:view']);
            if (metadata) {
                metadata['totalItems'] = resp['hydra:totalItems'];
            }
            const extrasMetadata = resp['extra:metadata'];
            return listSuccessActionsFunction(resp['hydra:member'], metadata, extrasMetadata);
        },
        errorActions,
        (state$: StateObservable<any>) => (queryParameters ? queryParameters(state$) : []),
        (state$, action: Action) => {
            if (payload && action.payload) {
                return action.payload;
            }
        }
    );
