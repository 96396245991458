export const resolveVerticalNavMenuItemComponent = (item: any) => {
    if (item.header) return 'VerticalNavMenuSectionHeader';
    if (item.children) return 'VerticalNavMenuGroup';
    return 'VerticalNavMenuLink';
};

export const hasActiveChild = (item: any, currentUrl: any) => {
    const {children} = item;

    if (!children) {
        return false;
    }

    for (const child of children) {
        if (child.children) {
            if (hasActiveChild(child, currentUrl)) {
                return true;
            }
        }

        if (child && child.navLink && currentUrl && (child.navLink === currentUrl || currentUrl.includes(child.navLink))) {
            return true;
        }
    }

    return false;
};

export const removeChildren = (children: any, openGroup: any, currentActiveGroup: any) => {
    children.forEach((child: any) => {
        if (!currentActiveGroup.includes(child.id)) {
            const index = openGroup.indexOf(child.id);
            if (index > -1) openGroup.splice(index, 1);
            if (child.children) removeChildren(child.children, openGroup, currentActiveGroup);
        }
    });
};
