import {Box, Stack, Typography, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {Translation, ChevronRight, NoOnboardingIcon} from 'palipali-panel-common-web';
import {FC} from 'react';

const NoOnboardingInfoView: FC = () => {
    const navigate = useNavigate();

    return (
        <Box className="content-view" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Box className="panel-info-box" sx={{backgroundColor: 'primary.light'}}>
                <div className="info-box-icon">
                    <NoOnboardingIcon />
                </div>
                <Stack className="info-box">
                    <Stack>
                        <Typography variant="overline" color="primary.main">
                            <Translation text="activeCouriers.noOnboardingInfo.subtitle" />
                        </Typography>
                        <div className="info-box-description">
                            <Typography variant="h6" component="h2">
                                <Translation text="activeCouriers.noOnboardingInfo.title" />
                            </Typography>
                            <Typography variant="body1" component="p">
                                <Translation text="activeCouriers.noOnboardingInfo.description" />
                            </Typography>
                        </div>
                    </Stack>

                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        endIcon={<ChevronRight size={18} />}
                        onClick={() => navigate(`/panel/account?tab=settlement`)}>
                        <Translation text="activeCouriers.noOnboardingInfo.button" />
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default NoOnboardingInfoView;
