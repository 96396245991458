import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiResponseBase, IRawRestQueryParams} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ITeamMemberOutput} from '../../model/team';

export function getTeamMembersAPI(
    authToken: string,
    payload?: any,
    params?: IRawRestQueryParams | undefined
): Observable<IApiResponseBase<ITeamMemberOutput[]>> {
    return palipaliAPI.get('api/team_members', new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
    });
}
