import React, {useEffect} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {Badge} from 'reactstrap';
import {hasActiveChild, removeChildren} from '../utils/utils';
import VerticalNavMenuItems from '../VerticalNavMenuItems';
import {IVerticalNavMenuGroupProps} from '../../../types';

type Props = IVerticalNavMenuGroupProps;

const VerticalNavMenuGroup = ({
    item,
    groupOpen,
    activeItem,
    parentItem,
    groupActive,
    currentActiveGroup,
    setGroupOpen,
    setActiveItem,
    setGroupActive,
    setCurrentActiveGroup,
    currentActiveItem,
    ...rest
}: Props) => {
    const {t} = useTranslation();
    const location = useLocation();
    const currentURL = location.pathname;

    // const toggleOpenGroup = (item: any, parent: any) => {
    //     let openGroup = groupOpen;
    //     const activeGroup = groupActive;
    //
    //     if (openGroup.includes(item.id)) {
    //         openGroup.splice(openGroup.indexOf(item.id), 1);
    //         if (item.children) {
    //             removeChildren(item.children, openGroup, groupActive)
    //         }
    //     } else if (activeGroup.includes(item.id) || currentActiveGroup.includes(item.id)) {
    //         if (!activeGroup.includes(item.id) && currentActiveGroup.includes(item.id)) {
    //             activeGroup.push(item.id)
    //         } else {
    //             activeGroup.splice(activeGroup.indexOf(item.id), 1)
    //         }
    //
    //         setGroupActive([...activeGroup])
    //     } else if (parent) {
    //         if (parent.children) {
    //             removeChildren(parent.children, openGroup, groupActive)
    //         }
    //
    //         if (!openGroup.includes(item.id)) {
    //             openGroup.push(item.id)
    //         }
    //     } else {
    //         openGroup = [];
    //
    //         if (!openGroup.includes(item.id)) {
    //             openGroup.push(item.id)
    //         }
    //     }
    //     setGroupOpen([...openGroup])
    // };

    // const onCollapseClick = (e: any, item: any) => {
    //     toggleOpenGroup(item, parentItem);
    //
    //     e.preventDefault()
    // };

    useEffect(() => {
        if (hasActiveChild(item, currentURL)) {
            if (!groupActive.includes(item.id)) groupActive.push(item.id);
        } else {
            const index = groupActive.indexOf(item.id);
            if (index > -1) groupActive.splice(index, 1);
        }
        setGroupActive([...groupActive]);
        setCurrentActiveGroup([...groupActive]);
        setGroupOpen([]);
    }, [location]);

    // const openClassCondition = (id: string) => {
    //     if ((menuCollapsed && menuHover) || !menuCollapsed) {
    //         if (groupActive.includes(id) || groupOpen.includes(id)) {
    //             return true
    //         }
    //     } else if (groupActive.includes(id) && menuCollapsed && menuHover === false) {
    //         return false
    //     } else {
    //         return null
    //     }
    // };

    return (
        <li
            className={classnames('nav-item has-sub', {
                open: true, // openClassCondition(item.id),
                // 'menu-collapsed-open': groupActive.includes(item.id),
                'sidebar-group-active':
                    groupActive.includes(item.id) || groupOpen.includes(item.id) || currentActiveGroup.includes(item.id),
            })}>
            <NavLink className={`${item.navLinkClassname} nav-link`} to={item.navLink ? item.navLink : '/'}>
                {item.icon}
                <span className="menu-title text-truncate">{t(item.title)}</span>

                {item.badge && item.badgeText ? (
                    <Badge className="ms-auto me-1" color={item.badge} pill>
                        {item.badgeText}
                    </Badge>
                ) : null}
            </NavLink>

            <ul className="menu-content">
                {/* <Collapse isOpen={true}> */}
                {/*isOpen={(groupActive && groupActive.includes(item.id)) || (groupOpen && groupOpen.includes(item.id))}>*/}
                <VerticalNavMenuItems
                    {...rest}
                    items={item.children}
                    groupActive={groupActive}
                    setGroupActive={setGroupActive}
                    currentActiveGroup={currentActiveGroup}
                    setCurrentActiveGroup={setCurrentActiveGroup}
                    groupOpen={groupOpen}
                    setGroupOpen={setGroupOpen}
                    parentItem={item}
                    currentActiveItem={currentActiveItem}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                />
                {/* </Collapse> */}
            </ul>
        </li>
    );
};

export default VerticalNavMenuGroup;
