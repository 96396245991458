import {Card, CardContent, CardHeader, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {AlertType, Loader} from '../../..';
import {addAlert} from '../../../store/reducers/alertSlice';
import {setNewPassword} from '../../../store/reducers/authSlice';
import {IBaseAuthPanelComponentWithThemeProps, LoaderType} from '../../../types';
import FormikForm from '../../FormikForm';
import Translation from '../../Translation';
import newPasswordFormConfig from './newPasswordFormConfig';

type Props = IBaseAuthPanelComponentWithThemeProps;

const NewPassword: React.FC<Props> = ({isLoading, theme, userRole}) => {
    const {t} = useTranslation(),
        {id} = useParams(),
        dispatch = useDispatch();

    return (
        <Card elevation={16}>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <CardHeader
                subheader={
                    <Typography color="text.secondary" variant="body2" key="subheader-subtitle">
                        <Translation text="auth.newPassword.subtitle" />
                    </Typography>
                }
                sx={{pb: 0}}
                title={t('auth.newPassword.title')}
            />
            <CardContent>
                <FormikForm
                    fields={newPasswordFormConfig()}
                    formId="new_password_form"
                    theme={theme}
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                    }}
                    onSubmit={(values: any) => {
                        if (!id) {
                            dispatch(addAlert({message: 'auth.alert.invalidResetPasswordURL', type: AlertType.WARNING}));
                            return null;
                        }
                        dispatch(setNewPassword(id, values.password, userRole));
                    }}
                />
            </CardContent>
        </Card>
    );
};

export default NewPassword;
