import {Button} from '@mui/material';
import {Translation} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CourierFilterStatus, changeCourierVerifiedFilter} from 'src/store/reducers/courierListingSlice';
import {courierListingFilterVerifiedSelector} from 'src/store/selectors/courierListingSelector';

const ActiveOrderTabFilters: React.FC = () => {
    const courierListFilter = useSelector(courierListingFilterVerifiedSelector),
        dispatch = useDispatch();
    const enumValuesArray: string[] = Object.values(CourierFilterStatus);

    const filterButton = (filter: string) => {
        const isActive = courierListFilter === filter;
        return (
            <Button
                variant="outlined"
                key={`courier_filter_button_${filter}`}
                color="primary"
                className={`tab-filter ${isActive ? 'active' : ''} couriers-status-button-${filter}}`}
                onClick={() => dispatch(changeCourierVerifiedFilter(filter))}>
                <Translation text={`couriers.filters.filterButtons.${filter}`} />
            </Button>
        );
    };

    return (
        <>
            {enumValuesArray.map((filter) => {
                return filterButton(filter);
            })}
        </>
    );
};

export default ActiveOrderTabFilters;
