import {
    Box,
    Avatar,
    Button,
    Card,
    Chip,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Stack,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {
    CustomTablePagination,
    IFleetPartnerCourierListingOutput,
    IModelCity,
    IMultiselectOption,
    Loader,
    MemberOperationType,
    PAGINATION_ITEMS_PER_PAGE,
    Translation,
    citiesSelector,
    openModal,
    NoDataInfo,
    LoaderType,
    currentlySelectedModalTypeSelector,
    isModalOpenSelector,
    PhoneComponent,
    calculateItemIndex,
    ChevronDownIcon,
    DefaultAvatar,
    TrashIcon,
    Truck02,
    UserPlus,
    isAuthTokenExpiredSelector,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {changeCourierListingCityFilter, changeCourierListingPagination, fetchCouriersList} from 'src/store/reducers/courierListingSlice';
import {
    courierListingCitySelector,
    courierListingMetadataSelector,
    courierListingPaginationSelector,
    courierListingSelector,
    courierLoadingSelector,
    redirectToCouriersListSelector,
} from 'src/store/selectors/courierListingSelector';
import CourierSearchInput from './CourierSearchInput';
import CourierFilterWrapper from './CourierVerifiedFilterWrapper';
import CourierInviteModal from './CourierInviteModal';
import ShowCity from './ShowCity';
import StatusBadge from './StatusBadge';
import UnassignCourierModal from './CourierUnassignModal';
import VehicleAssignModal from './VehicleAssignModal';

const Couriers: React.FC = () => {
    const {t} = useTranslation(),
        dispatch = useDispatch(),
        courierList = useSelector(courierListingSelector),
        pagination = useSelector(courierListingPaginationSelector),
        metadata = useSelector(courierListingMetadataSelector),
        isLoading = useSelector(courierLoadingSelector),
        cities: IModelCity[] = useSelector(citiesSelector),
        selectedCities = useSelector(courierListingCitySelector),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        isAuthTokenExpired: boolean = useSelector(isAuthTokenExpiredSelector),
        [selectedCourier, setSelectedCourier] = useState<IFleetPartnerCourierListingOutput | null>(null),
        redirect = useSelector(redirectToCouriersListSelector);

    useEffect(() => {
        if (redirect && !isAuthTokenExpired) {
            dispatch(fetchCouriersList());
        }
    }, [redirect]);

    const [cityList, setCityList] = useState<IMultiselectOption[]>([]),
        [id, setId] = useState(''),
        [cityMenuOpen, setCityMenuOpen] = useState(false),
        [selectedCitiesId, setSelectedCitiesId] = React.useState<string[]>(selectedCities ? selectedCities : []);

    useEffect(() => {
        if (cities && cities.length > 0) {
            const cityList = cities.map((city: IModelCity): IMultiselectOption => {
                const option: IMultiselectOption = {
                    value: city.id,
                    label: city.name,
                };
                return option;
            });
            setCityList(cityList);
        }
    }, [cities]);

    const getCityNameFromId = (cityId: string): string => {
        const city = cityList.find((city: IMultiselectOption) => city.value === cityId);
        return city ? city.label : '';
    };

    useEffect(() => {
        if (!cityMenuOpen && !isAuthTokenExpired) {
            dispatch(changeCourierListingCityFilter(selectedCitiesId));
        }
    }, [cityMenuOpen, selectedCitiesId, isAuthTokenExpired]);

    const handleDelete = (chipToDelete: string) => {
        setSelectedCitiesId((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: {value},
        } = event;
        if (value[value.length - 1] === 'all') {
            setSelectedCitiesId([]);
            return;
        }
        setSelectedCitiesId(typeof value === 'string' ? [value] : value);
    };
    const renderTableBody = () => {
        if (isLoading) return;

        if (!courierList || courierList.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6} align={'center'}>
                        <NoDataInfo text={'couriers.noCouriers'} />
                    </TableCell>
                </TableRow>
            );
        }

        const renderCourierName = (item: IFleetPartnerCourierListingOutput) => {
            if (!item.account.firstName && !item.account.lastName) return;

            return (
                <Typography variant="subtitle2" component="p" color="text.primary">
                    {`${item.account.firstName} ${item.account.lastName}`}
                </Typography>
            );
        };

        const renderCourierUsername = (item: IFleetPartnerCourierListingOutput) => {
            return (
                <Typography variant="caption" component="p" color="text.secondary">
                    {item.account.username}
                </Typography>
            );
        };

        const renderCourierPhone = (item: IFleetPartnerCourierListingOutput) => {
            return (
                <Typography variant="caption" component="p" color="text.secondary">
                    <PhoneComponent phone={item.account.phone} />
                </Typography>
            );
        };

        return courierList.map((item: IFleetPartnerCourierListingOutput, index: number) => (
            <TableRow key={item.id}>
                <TableCell>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                        <span className="index-number-cell">
                            <Typography component="span" className="index-number">{`#${calculateItemIndex(
                                pagination.page,
                                pagination.itemsPerPage,
                                index
                            )}`}</Typography>
                        </span>
                        <div className="details-box">
                            <div className="details-box-avatar">
                                {item.account.avatar?.fileUrls?.original ? (
                                    <Avatar src={item.account.avatar.fileUrls.original} />
                                ) : (
                                    <DefaultAvatar />
                                )}
                            </div>
                        </div>
                        <Stack>
                            {renderCourierName(item)}
                            {renderCourierUsername(item)}
                            {renderCourierPhone(item)}
                        </Stack>
                    </Box>
                </TableCell>
                <TableCell>
                    <ShowCity cityId={item.account.cityId} />
                </TableCell>
                <TableCell className="courier-status-badge">
                    <StatusBadge status={item.status} />
                </TableCell>
                <TableCell className="action-cell">
                    <div className="action-cell-container">
                        <div className="btn-action-wrapper">
                            <Button
                                className="btn btn-action couriers-courier-assigned-vehicles-button"
                                onClick={() => {
                                    dispatch(openModal(MemberOperationType.ASSIGN_VEHICLE));
                                    setSelectedCourier(item);
                                }}
                                disabled={item.status === 'invited'}
                                startIcon={<Truck02 size={18} />}>
                                <Translation text="couriers.buttons.vehicles" config={{count: item.assignedVehiclesCount}} />
                            </Button>
                        </div>
                        <div className="btn-action-wrapper">
                            <Button
                                className="btn btn-action couriers-delete-courier-button"
                                onClick={() => {
                                    setId(item.id);
                                    dispatch(openModal(MemberOperationType.COURIERS_DELETE_COURIER));
                                }}
                                startIcon={<TrashIcon />}>
                                <Translation text="couriers.buttons.delete" />
                            </Button>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        ));
    };

    const renderCityMultiselect = () => {
        return (
            <FormControl className="multiselect">
                <InputLabel className="sr-only" id="-label">
                    {t('couriers.filters.city')}
                </InputLabel>
                <Select
                    id="city"
                    name="city"
                    type="city"
                    multiple
                    displayEmpty
                    defaultValue={selectedCitiesId}
                    value={selectedCitiesId}
                    renderValue={() => <span>{t('couriers.filters.city')}</span>}
                    onChange={handleSelectChange}
                    className="couriers-filters-city-select"
                    label={`${t('couriers.filters.city')}`}
                    labelId="make-label"
                    onClose={() => setCityMenuOpen(false)}
                    onOpen={() => setCityMenuOpen(true)}
                    IconComponent={ChevronDownIcon}>
                    {cityList.map((city: IMultiselectOption) => (
                        <MenuItem key={city.value} value={city.value}>
                            {city.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const renderSelectedChips = () => {
        if (!selectedCitiesId || !selectedCitiesId.length) return;

        return (
            <Grid component={'ul'} className="selected-chip-list">
                {selectedCitiesId.map((cityId: string) => (
                    <Chip
                        key={`chip-${cityId}`}
                        component="li"
                        variant="outlined"
                        className="selected-chip"
                        label={getCityNameFromId(cityId)}
                        onDelete={() => handleDelete(cityId)}
                    />
                ))}
            </Grid>
        );
    };

    const renderCourierCard = () => {
        return (
            <Card className="custom-card">
                {renderSelectedChips()}
                <Grid className="filters-wrapper">
                    <Grid className="tab-filters">
                        <CourierFilterWrapper />
                    </Grid>
                    <Grid className="input-filters">
                        {renderCityMultiselect()}
                        <CourierSearchInput />
                    </Grid>
                </Grid>
                <Divider />
                <TableContainer>
                    <Table className="custom-list">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Translation text="couriers.table.courier" />
                                </TableCell>
                                <TableCell>
                                    <Translation text="couriers.table.city" />
                                </TableCell>
                                <TableCell>
                                    <Translation text="couriers.table.status" />
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                </TableContainer>
                <CustomTablePagination
                    metadata={metadata}
                    onChangePage={(event, pageNumber) =>
                        dispatch(changeCourierListingPagination({page: pageNumber, itemsPerPage: PAGINATION_ITEMS_PER_PAGE}))
                    }
                    page={pagination.page}
                    rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
                />
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </Card>
        );
    };

    return (
        <div className="content-view">
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="couriers.title" />
                    </Typography>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    className="couriers-add-courier-button"
                    startIcon={<UserPlus size={18} />}
                    onClick={() => {
                        dispatch(openModal(MemberOperationType.COURIERS_COURIER_INVITE));
                    }}>
                    <Translation text="couriers.buttons.addCourier" />
                </Button>
            </div>

            {renderCourierCard()}

            {isModalOpen && modalType === MemberOperationType.COURIERS_COURIER_INVITE && <CourierInviteModal />}
            {isModalOpen && modalType === MemberOperationType.COURIERS_DELETE_COURIER && <UnassignCourierModal id={id} />}
            {isModalOpen && modalType === MemberOperationType.ASSIGN_VEHICLE && (
                <VehicleAssignModal
                    selectedCourierAccountId={selectedCourier?.id || null}
                    selectedCourierId={selectedCourier?.account?.courier?.id || null}
                />
            )}
        </div>
    );
};

export default Couriers;
