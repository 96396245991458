import React, {Component} from 'react';
import Nouislider from 'nouislider-react';
import {isNotNullOrUndefined} from '../../..';
import {IRangePickerProps, IRangePickerState} from '../../../types';

type Props = IRangePickerProps;
type State = IRangePickerState;

class RangePicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: null,
        };
    }

    componentDidMount(): void {}

    render() {
        return (
            <div className="noUi-wrapper">
                <Nouislider
                    onChange={(value: string[]) => this.onRangeChange(value)}
                    step={10}
                    start={isNotNullOrUndefined(this.props.defaultValue) ? this.props.defaultValue : [0]}
                    pips={{
                        mode: 'steps',
                        // filter: (value: any, type: any) => {
                        //     return type === 0 ? -1 : 1;
                        // },
                        format: {
                            to: (value: number) => {
                                if (this.props.labelFormat) {
                                    return this.props.labelFormat(value);
                                } else {
                                    return (this.props.labels as any)[value];
                                }
                            },
                        },
                        density: 10,
                    }}
                    clickablePips
                    range={{
                        min: 0,
                        max: 100,
                    }}
                    tooltips={[{to: (value: number) => `${this.props.labels[value]} ${value}%`, from: (value: number) => value}]}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }

    private onRangeChange = (value: string[]) => {
        this.setState({value: value});
        this.props.onChange(Number(value[0]));
    };
}

export default RangePicker;
