import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, ICanOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';

export function getCanDeleteTeamAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<ICanOutput>> {
    return palipaliAPI.get(`api/teams/${id}/can_delete`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
