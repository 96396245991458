import type {SVGProps} from 'react';
import React from 'react';

const LocationsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M5.7 13.0577C4.03377 13.7928 3 14.8171 3 15.95C3 18.1868 7.02944 20 12 20C16.9706 20 21 18.1868 21 15.95C21 14.8171 19.9662 13.7928 18.3 13.0577M17.4 7.4C17.4 11.0573 13.35 12.8 12 15.5C10.65 12.8 6.6 11.0573 6.6 7.4C6.6 4.41766 9.01766 2 12 2C14.9823 2 17.4 4.41766 17.4 7.4ZM12.9 7.4C12.9 7.89706 12.4971 8.3 12 8.3C11.5029 8.3 11.1 7.89706 11.1 7.4C11.1 6.90294 11.5029 6.5 12 6.5C12.4971 6.5 12.9 6.90294 12.9 7.4Z"
            stroke="CurrentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default LocationsIcon;
