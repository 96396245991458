import {
    AlertType,
    addAlert,
    handleApiError,
    DetailsSuccessActionsFunction,
    createFetchDetailsEpic,
    getBillingInfoAPI,
    updateBillingInfoAPI,
    getCanDoStripeOnboardingAPI,
    updateStripeOnboardingAPI,
    IBillingInfoOutput,
    ICanDoStripeOnboardingOutput,
    IStripeOnboardingOutput,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {
    setBillingInfo,
    fetchBillingInfo,
    setError,
    setLoading,
    updateBillingInfo,
    fetchCanDoStripeOnboarding,
    setCanDoStripeOnboarding,
    updateStripeOnboarding,
    setStripeOnboardingUrl,
    setStripeCardLoading,
} from '../reducers/accountSettlmentSlice';
import store from '../../store';

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), setLoading(false), setError(errorObj.message)];
};

const fetchBillingInfoSuccessActions: DetailsSuccessActionsFunction<IBillingInfoOutput> = (resp: IBillingInfoOutput) => {
    const billingInfo: IBillingInfoOutput = {
        companyName: resp?.companyName || '',
        taxIdentifier: resp?.taxIdentifier || '',
        street: resp?.street || '',
        houseNumber: resp?.houseNumber || '',
        flatNumber: resp?.flatNumber || '',
        city: resp?.city || '',
        zip: resp?.zip || '',
    };

    const fleetPartnerId = store.getState().account?.account?.completedAccounts?.fleetPartner?.id;
    const actions = [setBillingInfo({billingInfo: billingInfo})];
    if (fleetPartnerId) actions.push(fetchCanDoStripeOnboarding(fleetPartnerId));
    return actions;
};

const fetchBillingInfoEpic = createFetchDetailsEpic<IBillingInfoOutput>(
    getBillingInfoAPI,
    fetchBillingInfoSuccessActions,
    errorActions,
    fetchBillingInfo().type
);

const updateBillingInfoSuccessActions: DetailsSuccessActionsFunction<IBillingInfoOutput> = () => {
    const fleetPartnerId = store.getState().account?.account?.completedAccounts?.fleetPartner?.id;
    return [
        setCanDoStripeOnboarding(true),
        fetchBillingInfo(fleetPartnerId),
        setLoading(false),
        addAlert({message: 'account.settlements.billingInfoCard.alert.success', type: AlertType.SUCCESS}),
    ];
};

const updateBillingInfoEpic = createFetchDetailsEpic<IBillingInfoOutput>(
    updateBillingInfoAPI,
    updateBillingInfoSuccessActions,
    errorActions,
    updateBillingInfo().type
);

const canDoStripeOnboardingSuccessActions: DetailsSuccessActionsFunction<ICanDoStripeOnboardingOutput> = (
    resp: ICanDoStripeOnboardingOutput
) => {
    return [setCanDoStripeOnboarding(resp.can)];
};

const fetchCanDoStripeOnboardingEpic = createFetchDetailsEpic<ICanDoStripeOnboardingOutput>(
    getCanDoStripeOnboardingAPI,
    canDoStripeOnboardingSuccessActions,
    errorActions,
    fetchCanDoStripeOnboarding().type
);

const updateStripeOnboardingSuccessActions: DetailsSuccessActionsFunction<IStripeOnboardingOutput> = (resp: IStripeOnboardingOutput) => {
    return [setStripeOnboardingUrl(resp.onboardingUrl), setStripeCardLoading(false)];
};

const updateStripeOnboardingEpic = createFetchDetailsEpic<IStripeOnboardingOutput>(
    updateStripeOnboardingAPI,
    updateStripeOnboardingSuccessActions,
    errorActions,
    updateStripeOnboarding().type
);
const accountSettlementEpic = combineEpics(
    fetchBillingInfoEpic,
    updateBillingInfoEpic,
    fetchCanDoStripeOnboardingEpic,
    updateStripeOnboardingEpic
);

export default accountSettlementEpic;
