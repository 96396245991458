import {Tab, Tabs, Typography} from '@mui/material';
import * as React from 'react';
import {useCallback, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ProfileTab from './ProfileTab';
import SettingsTab from './SettingsTab';
import {Translation, SeverityPill, isAccountActiveSelector, fleetPartnerIdSelector, getFullAccount} from 'palipali-panel-common-web';
import {useDispatch, useSelector} from 'react-redux';
import SettlementTab from './SettlementTab';
import {useSearchParams} from 'react-router-dom';
import {fetchBillingInfo} from 'src/store/reducers/accountSettlmentSlice';

const tabs = [
    {label: 'account.subtitle.profile', value: 'profile'},
    {label: 'account.subtitle.settlement', value: 'settlement'},
    {label: 'account.subtitle.settings', value: 'settings'},
];

const Account: React.FC = () => {
    const [currentTab, setCurrentTab] = useState<string>('profile');
    const handleTabsChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string): void => {
            setCurrentTab(value);
        }, []),
        dispatch = useDispatch(),
        isActiveAccount = useSelector(isAccountActiveSelector),
        fleetPartnerId: string = useSelector(fleetPartnerIdSelector),
        [urlParams] = useSearchParams();

    useEffect(() => {
        const urlTab = urlParams.get('tab');
        if (urlTab === 'settlement') {
            setCurrentTab(urlTab);
            dispatch(fetchBillingInfo(fleetPartnerId));
            // remove tab from url without reloading
            const url = new URL(window.location.href);
            url.searchParams.delete('tab');
            window.history.pushState({}, '', url.href);
        }
    }, []);

    useEffect(() => {
        dispatch(getFullAccount());
    }, []);

    useEffect(() => {
        if (fleetPartnerId) {
            dispatch(fetchBillingInfo(fleetPartnerId));
        }
    }, [fleetPartnerId]);

    const renderTabContent = (tab: string): JSX.Element => {
        switch (tab) {
            case 'profile':
                return <ProfileTab />;
            case 'settlement':
                return <SettlementTab />;
            case 'settings':
                return <SettingsTab />;
            default:
                return <></>;
        }
    };

    const {t} = useTranslation();
    return (
        <div className="content-view">
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="account.title" />
                    </Typography>
                    <div className="badge-wrapper">
                        <SeverityPill className={`account-status-pill ${isActiveAccount ? 'active' : 'inactive'}`}>
                            <Typography variant="caption" sx={{textTransform: 'capitalize'}}>
                                <Translation text={`account.status.${isActiveAccount ? 'active' : 'inactive'}`} />
                            </Typography>
                        </SeverityPill>
                    </div>
                </div>
            </div>

            <div className="tabs-wrapper">
                <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    sx={{px: 3}}
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            label={t(tab.label)}
                            value={tab.value}
                            className={`account-settings-tab-button-${tab.value}`}
                        />
                    ))}
                </Tabs>
            </div>
            {renderTabContent(currentTab)}
        </div>
    );
};

export default Account;
