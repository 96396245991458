import type {SVGProps} from 'react';
import React from 'react';

const AlertTriangle = (props: SVGProps<SVGSVGElement>) => (
    <svg width={72} height={72} fill="none" {...props}>
        <path
            d="M35.9993 27.0007V39.0007M35.9993 51.0007H36.0293M31.8452 11.6759L7.17056 54.2957C5.80195 56.6597 5.11764 57.8417 5.21878 58.8118C5.307 59.6579 5.75031 60.4268 6.43838 60.9271C7.22725 61.5007 8.59304 61.5007 11.3246 61.5007H60.6739C63.4055 61.5007 64.7713 61.5007 65.5601 60.9271C66.2482 60.4268 66.6915 59.6579 66.7797 58.8118C66.8809 57.8417 66.1966 56.6597 64.828 54.2957L40.1533 11.6759C38.7896 9.32036 38.1077 8.14262 37.2182 7.74706C36.4422 7.40202 35.5563 7.40202 34.7804 7.74706C33.8908 8.14262 33.2089 9.32037 31.8452 11.6759Z"
            stroke="#F04438"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default AlertTriangle;
