import type {FC, ReactNode} from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Stack} from '@mui/material';
import {NavItem} from './nav-item';

interface Item {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    items?: Item[];
    label?: ReactNode;
    path?: string;
    class?: string;
    title: string;
    onClick?: () => void;
}

const renderItems = ({depth = 0, items, pathname}: {depth?: number; items: Item[]; pathname?: string | null}): JSX.Element[] =>
    items.reduce(
        (acc: JSX.Element[], item) =>
            reduceChildRoutes({
                acc,
                depth,
                item,
                pathname,
            }),
        []
    );

const reduceChildRoutes = ({
    acc,
    depth,
    item,
    pathname,
}: {
    acc: JSX.Element[];
    depth: number;
    item: Item;
    pathname?: string | null;
}): Array<JSX.Element> => {
    const checkPath = !!(item.path && pathname);
    const partialMatch = checkPath ? pathname.includes(item.path!) : false;

    if (item.items) {
        acc.push(
            <NavItem
                active={partialMatch}
                depth={depth}
                disabled={item.disabled}
                icon={item.icon}
                key={item.title}
                class={item.class}
                label={item.label}
                open={partialMatch}
                title={item.title}>
                <Stack
                    component="ul"
                    spacing={0.5}
                    sx={{
                        listStyle: 'none',
                        m: 0,
                        p: 0,
                    }}>
                    {renderItems({
                        depth: depth + 1,
                        items: item.items,
                        pathname,
                    })}
                </Stack>
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                active={partialMatch}
                depth={depth}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                onClick={item.onClick}
                key={item.title}
                label={item.label}
                class={item.class}
                path={item.path}
                title={item.title}
            />
        );
    }

    return acc;
};

interface INavSectionProps {
    items?: Item[];
    pathname?: string | null;
    subheader?: string;
}

export const NavSection: FC<INavSectionProps> = (props) => {
    const {items = [], pathname, subheader = '', ...other} = props;

    return (
        <Stack
            component="ul"
            spacing={0.5}
            sx={{
                listStyle: 'none',
                m: 0,
                p: 0,
            }}
            {...other}>
            {subheader && (
                <Box
                    component="li"
                    sx={{
                        color: 'var(--nav-section-title-color)',
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: 1.66,
                        mb: 1,
                        ml: 1,
                        textTransform: 'uppercase',
                    }}>
                    {subheader}
                </Box>
            )}
            {renderItems({items, pathname})}
        </Stack>
    );
};

NavSection.propTypes = {
    items: PropTypes.array,
    pathname: PropTypes.string,
    subheader: PropTypes.string,
};
