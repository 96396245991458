import React from 'react';
import {IconButton, Typography, Box, Theme} from '@mui/material';

interface DayProps {
    filled?: boolean;
    outlined?: boolean;
    highlighted?: boolean;
    disabled?: boolean;
    startOfRange?: boolean;
    endOfRange?: boolean;
    onClick?: () => void;
    onHover?: () => void;
    value: number | string;
}

const Day: React.FunctionComponent<DayProps> = ({
    startOfRange,
    endOfRange,
    disabled,
    highlighted,
    filled,
    onClick,
    onHover,
    value,
}: DayProps) => {
    const outlined = false;
    const getColor = (theme: Theme) => {
        if (disabled) {
            return theme.palette.text.secondary;
        }
        if (highlighted && !endOfRange && !startOfRange) {
            return theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.text.primary;
        }
        if (filled) {
            return theme.palette.common.white;
        }
        return theme.palette.text.primary;
    };

    return (
        <Box
            className="date-range-picker-day"
            sx={{
                display: 'flex',
                // eslint-disable-next-line no-nested-ternary
                borderRadius: startOfRange ? '0.8rem 0 0 0.8rem' : endOfRange ? '0 0.8rem 0.8rem 0' : undefined,
                backgroundColor: (theme) => (!disabled && highlighted ? theme.palette.primary.light : undefined),
            }}>
            <IconButton
                className={`date-range-picker-day-button ${startOfRange ? 'range-start' : ''} ${endOfRange ? 'range-end' : ''}`}
                sx={{
                    height: '36px',
                    width: '36px',
                    padding: 0,
                    border: (theme) => (!disabled && outlined ? `1px solid ${theme.palette.primary.dark}` : undefined),
                    ...(!disabled && filled
                        ? {
                              '&:hover': {
                                  backgroundColor: (theme) => theme.palette.primary.dark,
                              },
                              backgroundColor: (theme) => theme.palette.primary.dark,
                          }
                        : {}),
                    color: (theme) => getColor(theme),
                }}
                disabled={disabled}
                onClick={onClick}
                onMouseOver={onHover}>
                <Typography
                    sx={{
                        lineHeight: 1.6,
                    }}
                    variant="body2">
                    {value}
                </Typography>
            </IconButton>
        </Box>
    );
};

export default Day;
