import React from 'react';
import {CustomIconProps} from '../../../types/iconTypes';

const CheckRoundIcon: React.FC<CustomIconProps> = ({...props}) => (
    <svg width={props.size ? props.size : 22} height={props.size ? props.size : 22} fill="none" {...props}>
        <path
            d="M15.2072 6.9485L9.16634 12.9893L5.87551 9.70766L4.58301 11.0002L9.16634 15.5835L16.4997 8.25016L15.2072 6.9485ZM10.9997 1.8335C5.93967 1.8335 1.83301 5.94016 1.83301 11.0002C1.83301 16.0602 5.93967 20.1668 10.9997 20.1668C16.0597 20.1668 20.1663 16.0602 20.1663 11.0002C20.1663 5.94016 16.0597 1.8335 10.9997 1.8335ZM10.9997 18.3335C6.94801 18.3335 3.66634 15.0518 3.66634 11.0002C3.66634 6.9485 6.94801 3.66683 10.9997 3.66683C15.0513 3.66683 18.333 6.9485 18.333 11.0002C18.333 15.0518 15.0513 18.3335 10.9997 18.3335Z"
            fill="currentColor"
        />
    </svg>
);

export default CheckRoundIcon;
