import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiResponseBase, IRawRestQueryParams} from '../../types';
import {ITeamListingOutput} from '../../model/team';
import {ApiResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';

export const getTeamMineListingAPI: ApiResponseFunction<ITeamListingOutput> = (
    authToken: string,
    params: IRawRestQueryParams | []
): Observable<IApiResponseBase<ITeamListingOutput[]>> => {
    return palipaliAPI.get('api/teams/list_mine', new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
    });
};
