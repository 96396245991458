import React from 'react';
import {IMoneyOutput, Price} from 'palipali-panel-common-web';
import {Typography} from '@mui/material';

interface IJobPriceProps {
    readonly price: IMoneyOutput;
}

const JobPrice: React.FC<IJobPriceProps> = ({price}) => {
    return (
        <span className="package-price-color">
            {price.amount === '0' ? (
                <Typography variant="subtitle2" color="primary" className="package-price-color">
                    {'-'}
                </Typography>
            ) : (
                <Price price={price} />
            )}
        </span>
    );
};

export default JobPrice;
