import {Box, Stack, Typography} from '@mui/material';
import type {FC} from 'react';
import React from 'react';
import RouterLink from '../../components/RouterLink';
import {UserRole} from '../../../types';
import LogoPurchaser from '../../icons/logo/logo-purchaser';
import LogoFleetPartner from '../../icons/logo/logo-fleet-partner';
import {accountCredentialsSelector} from '../../../store/selectors/accountSelectors';
import {useSelector} from 'react-redux';

interface INavUserBoxProps {
    userRole: UserRole;
    userName?: string | null;
}

const NavUserBox: FC<INavUserBoxProps> = (props) => {
    const tenantName: string = useSelector(accountCredentialsSelector);

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{
                padding: '0 1.6rem',
                margin: '2.4rem 0',
            }}>
            <Box
                component={RouterLink}
                href={'/'}
                sx={{
                    borderColor: 'var(--nav-logo-border)',
                    borderRadius: '8px',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    padding: '3px',
                }}>
                <Box
                    component="span"
                    sx={{
                        height: 40,
                        width: 40,
                        background: '#fff',
                        borderRadius: '8px',
                        borderColor: 'var(--nav-logo-border)',
                        borderWidth: 1,
                        display: 'flex',
                        alinItems: 'center',
                        justifyContent: 'center',
                        padding: '5px',
                        '.logo': {
                            maxWidth: '100%',
                            maxHeight: '100%',
                        },
                    }}>
                    {props.userRole === UserRole.PURCHASER ? <LogoPurchaser /> : <LogoFleetPartner />}
                </Box>
            </Box>
            <Stack alignItems="center" direction="row" spacing={2}>
                <Box sx={{flexGrow: 1}}>
                    {tenantName && (
                        <Typography
                            color="inherit"
                            variant="h6"
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '18.5rem',
                            }}>
                            {tenantName}
                        </Typography>
                    )}
                    {props.userName && (
                        <Typography
                            color="neutral.400"
                            variant="body2"
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '18.5rem',
                            }}>
                            {props.userName}
                        </Typography>
                    )}
                </Box>
            </Stack>
        </Stack>
    );
};

export default NavUserBox;
