import {Box, Button, Card, CardContent, CardHeader, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {IRequestUserSetNewPasswordInput, Loader, UserRole} from '../../..';
import {requestNewPassword} from '../../../store/reducers/authSlice';
import ArrowLeftIcon from '../../../theme/icons/untitled-ui/duocolor/arrow-left';
import {IResetPasswordProps, LoaderType} from '../../../types';
import FormikForm from '../../FormikForm';
import Translation from '../../Translation';
import resetPasswordFormConfig from './resetPasswordFormConfig';

type Props = IResetPasswordProps;

const ResetPassword: React.FC<Props> = ({userRole, envData, isLoading, theme}) => {
    const {t} = useTranslation(),
        navigate = useNavigate(),
        dispatch = useDispatch(),
        isCourier = userRole === UserRole.COURIER;

    const loginUrl = '/auth/login';

    return (
        <>
            {!isCourier && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginBottom: '3.4rem',
                        mt: '2.8rem',
                    }}>
                    <Button
                        size="small"
                        onClick={() => navigate(loginUrl)}
                        sx={{
                            gap: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '1.4rem',
                            fontWeight: 500,
                            color: 'text.primary',
                        }}>
                        <ArrowLeftIcon />
                        <Translation text="auth.resetPassword.backToLoginButton" />
                    </Button>
                </Box>
            )}
            <Card elevation={16}>
                <Loader showLoader={isLoading} type={LoaderType.Local} />
                <CardHeader
                    subheader={
                        <Typography color="text.secondary" variant="body2" key="subheader-subtitle">
                            <Translation text="auth.resetPassword.subtitle" />
                        </Typography>
                    }
                    sx={{pb: 0}}
                    title={t('auth.resetPassword.title')}
                />
                <CardContent>
                    <FormikForm
                        fields={resetPasswordFormConfig()}
                        formId="reset_password_form"
                        theme={theme}
                        initialValues={{username: ''}}
                        onSubmit={(values: any) => {
                            const baseUrl = userRole === UserRole.USER ? `${envData.REACT_APP_URL}` : `${envData.REACT_APP_URL}`,
                                resetPasswordPayload: IRequestUserSetNewPasswordInput = {
                                    username: values.username,
                                    returnUrl: `${baseUrl}/${isCourier ? 'couriers' : 'auth'}/new-password`,
                                };

                            dispatch(requestNewPassword(resetPasswordPayload, userRole));
                        }}
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default ResetPassword;
