import {Observable} from 'rxjs';
import {IApiSingleResponseBase, ICanOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export function getCanUpdateInsuranceAPI(authToken: string, payload: {id: string}): Observable<IApiSingleResponseBase<ICanOutput>> {
    return palipaliAPI.get(`api/vehicles/${payload.id}/can_insurance`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
