import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material';
import React from 'react';
import {FormikFieldConfig} from '../../../components/FormikForm';
import Translation from '../../../components/Translation';
import {IRadioOption} from '../../../types';

const RadioFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    options,
    formik,
    customHandleChange,
    customEventChange,
    customDisabled,
    customOptions,
    className,
}) => {
    const handleChange = (event) => {
        if (customHandleChange) {
            return customHandleChange(event.target.value, formik.setFieldValue, formik.values);
        }
        if (customEventChange) {
            return customEventChange(name, event.target.value);
        }
        formik.handleChange(event);
    };

    const optionsArray = customOptions ? customOptions(formik.values) : options ? options : [];
    const disabledValue = customDisabled ? customDisabled(formik.values) : false;
    return (
        <FormControl disabled={disabledValue}>
            <FormLabel id={`${name}-radio-control-label`} className={`sr-only ${className}`}>
                <Translation text={label} />
            </FormLabel>
            <RadioGroup aria-labelledby={`${name}-radio-control-label`} name={name} onChange={handleChange}>
                {optionsArray
                    ? optionsArray?.map((item: IRadioOption) => {
                          return (
                              <FormControlLabel
                                  key={item.value}
                                  value={item.value}
                                  control={<Radio color="primary" className={`radio-input-${name}-${item.label}`} />}
                                  label={item.label}
                              />
                          );
                      })
                    : null}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioFormControl;
