import {isFleetPartnerStripeOnboardingCompletedSelector} from 'palipali-panel-common-web';
import React from 'react';
import {useSelector} from 'react-redux';

import ActiveCouriersListMap from './ActiveCouriersListMap';
import NoOnboardingInfoView from './NoOnboardingInfoView';

const ActiveCouriers: React.FC = () => {
    const isAfterOnboarding = useSelector(isFleetPartnerStripeOnboardingCompletedSelector);

    const renderView = () => {
        if (!isAfterOnboarding) {
            return <NoOnboardingInfoView />;
        }

        return <ActiveCouriersListMap />;
    };

    return renderView();
};

export default ActiveCouriers;
