import {ITeamViewOutput} from '../model/team';

export const formatTeamAddress = (team: ITeamViewOutput | null): string => {
    if (!team) {
        return '-';
    }

    const street = team.street,
        flat = team.flatNumber,
        house = team.houseNumber,
        postCode = team.zip,
        city = team.city,
        houseFlatNo = flat ? `${house}/${flat}` : house;

    return `${street} ${houseFlatNo}, ${postCode} ${city}`;
};
