import type {SVGProps} from 'react';
import React from 'react';

const HourglassTimerIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
        <path
            d="M7.16699 14.875H11.667M5.36699 2.5H13.467C13.887 2.5 14.0971 2.5 14.2575 2.58175C14.3986 2.65365 14.5133 2.76839 14.5852 2.90951C14.667 3.06994 14.667 3.27996 14.667 3.7V5.25589C14.667 5.62277 14.667 5.80622 14.6255 5.97885C14.5888 6.1319 14.5282 6.27822 14.446 6.41243C14.3532 6.5638 14.2235 6.69352 13.964 6.95294L11.7655 9.15147C11.4685 9.44848 11.32 9.59699 11.2644 9.76824C11.2154 9.91887 11.2154 10.0811 11.2644 10.2318C11.32 10.403 11.4685 10.5515 11.7655 10.8485L13.964 13.0471C14.2235 13.3065 14.3532 13.4362 14.446 13.5876C14.5282 13.7218 14.5888 13.8681 14.6255 14.0212C14.667 14.1938 14.667 14.3772 14.667 14.7441V16.3C14.667 16.72 14.667 16.9301 14.5852 17.0905C14.5133 17.2316 14.3986 17.3464 14.2575 17.4183C14.0971 17.5 13.887 17.5 13.467 17.5H5.36699C4.94695 17.5 4.73693 17.5 4.5765 17.4183C4.43538 17.3464 4.32064 17.2316 4.24874 17.0905C4.16699 16.9301 4.16699 16.72 4.16699 16.3V14.7441C4.16699 14.3772 4.16699 14.1938 4.20844 14.0212C4.24518 13.8681 4.30579 13.7218 4.38803 13.5876C4.48079 13.4362 4.61051 13.3065 4.86994 13.0471L7.06847 10.8485C7.36548 10.5515 7.51398 10.403 7.56962 10.2318C7.61857 10.0811 7.61857 9.91887 7.56962 9.76824C7.51398 9.59699 7.36547 9.44848 7.06846 9.15147L4.86994 6.95294C4.61051 6.69352 4.48079 6.5638 4.38803 6.41243C4.30579 6.27822 4.24518 6.1319 4.20844 5.97885C4.16699 5.80622 4.16699 5.62277 4.16699 5.25589V3.7C4.16699 3.27996 4.16699 3.06994 4.24874 2.90951C4.32064 2.76839 4.43538 2.65365 4.5765 2.58175C4.73693 2.5 4.94695 2.5 5.36699 2.5Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default HourglassTimerIcon;
