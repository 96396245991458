import {MenuItem, TextField, Typography} from '@mui/material';
import React from 'react';
import {FormikFieldConfig} from '../../../components/FormikForm';
import {IMultiselectOption} from '../../../types';
import ChevronDownIcon from '../../icons/untitled-ui/duocolor/chevron-down';
import {ifUndefinedSetEmptyString} from '../../../utils/dataDisplayHelpers';

const SelectFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    options,
    formik,
    customHandleChange,
    customEventChange,
    customDisabled,
    customOptions,
    className,
    isRequired,
    placeholder,
    formControlClassName,
}) => {
    const handleChange = (event) => {
        formik.handleChange(event);

        if (customHandleChange) {
            customHandleChange(event.target.value, formik.setFieldValue, formik.values);
        }

        if (customEventChange) {
            customEventChange(name, event.target.value);
        }
    };

    const optionsArray = customOptions ? customOptions(formik.values) : options ? options : [];
    const disabledValue = customDisabled ? customDisabled(formik.values) : false;
    const selectedValue = ifUndefinedSetEmptyString(optionsArray?.find((item) => item.value === formik.values[name])?.value);
    return (
        <TextField
            key={`${name}-form-control-select`}
            id={name}
            name={name}
            select
            fullWidth
            disabled={disabledValue}
            label={label}
            required={isRequired}
            value={selectedValue}
            onChange={handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            className={`${name}-form-control-select-wrapper custom-mui-select ${formControlClassName || ''}`}
            SelectProps={{
                IconComponent: ChevronDownIcon,
            }}
            inputProps={{
                className: `optionId-input ${className || ''} ${name}-form-control-select-input`,
            }}>
            {optionsArray && optionsArray.length === 0 && placeholder && (
                <MenuItem value="" disabled className={`${name}-select-item`}>
                    <Typography variant="body2">{placeholder}</Typography>
                </MenuItem>
            )}
            {optionsArray &&
                optionsArray.map((option: IMultiselectOption, index: number) => {
                    return (
                        <MenuItem
                            key={`${option.value}_${option.label}_${index}`}
                            value={option.value}
                            className={`${option.value}-select-item`}>
                            <Typography variant="body2" key={`label-${option.value}-${index}`}>
                                <React.Fragment key={`menu-option-${option.value}-${index}`}>{option.label}</React.Fragment>
                            </Typography>
                        </MenuItem>
                    );
                })}
        </TextField>
    );
};

export default SelectFormControl;
