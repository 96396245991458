import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelColor} from '../../model/common';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './baseSlice';

export interface IColorState extends IBaseDictionaryState {
    colors: IModelColor[] | null;
}

export interface IChangeColor {
    colors: IModelColor[] | null;
}

const initialState: IColorState = {
    colors: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const colorSlice = createSlice({
    name: 'color',
    initialState: initialState,
    reducers: {
        changeColor: {
            reducer: (state: IColorState, action: PayloadAction<IChangeColor>) => {
                return {
                    colors: action.payload.colors,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(colors: IModelColor[] | null) {
                return {
                    payload: {colors: colors},
                };
            },
        },
        changeIsColorLoading: {
            reducer: (state: IColorState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    colors: state.colors,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsColorInitialized: {
            reducer: (state: IColorState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    colors: state.colors,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setColorError: {
            reducer: (state: IColorState, action: PayloadAction<ISetError>) => {
                return {
                    colors: state.colors,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchColors: (state: IColorState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeColor, changeIsColorLoading, changeIsColorInitialized, setColorError, fetchColors} = colorSlice.actions;

export default colorSlice.reducer;
