import React from 'react';
import NavbarUser from './NavbarUser';
import NavbarNavigation from './NavbarNavigation';
import {INavbarProps} from '../../types';

type Props = INavbarProps;

const NavBar: React.FC<Props> = ({navigationMenu, isAuthenticated, account, unauthorizedUserMenu, userMenu}) => {
    return (
        <nav className="header-navbar">
            <div className="navbar-container d-flex justify-content-between">
                <div className="bookmark-wrapper d-flex align-items-center">
                    <NavbarNavigation navigationMenu={navigationMenu} isTooltipVisible={false} />
                </div>
                <NavbarUser
                    isAuthenticated={isAuthenticated}
                    account={account}
                    unauthorizedUserMenu={unauthorizedUserMenu}
                    userMenu={userMenu}
                />
            </div>
        </nav>
    );
};

export default NavBar;
