import React, {Suspense} from 'react';
import {Provider} from 'react-redux';
import store, {history, persistor} from './store';
import {HelmetProvider} from 'react-helmet-async';
import {HistoryRouter as Router} from 'redux-first-history/rr6';
import routes from './routes/routes';
import {SettingsConsumer, SettingsProvider, ThemeRouterHost, createTheme, deepCloneObject} from 'palipali-panel-common-web';
import {PersistGate} from 'redux-persist/lib/integration/react';

const App: React.FC = () => {
    const getUpdatedSettings = (settings: any) => {
        const updatedSettings = deepCloneObject(settings);
        updatedSettings.paletteMode = settings.theme === 'dark' ? 'dark' : 'light';
        updatedSettings.colorPreset = 'fleetPartner';

        return updatedSettings;
    };

    const getTheme = (settings: any) => {
        return createTheme({
            colorPreset: settings.colorPreset,
            contrast: settings.contrast,
            direction: settings.direction,
            paletteMode: settings.paletteMode,
            responsiveFontSizes: settings.responsiveFontSizes,
        });
    };

    const getColorPresent = () => {
        const isCourierRoute = window.location.pathname.includes('/couriers/') && !window.location.pathname.includes('/panel/');

        return isCourierRoute ? 'courier' : 'fleetPartner';
    };

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <HelmetProvider>
                    <Suspense>
                        <Router history={history}>
                            <SettingsProvider>
                                <SettingsConsumer>
                                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                    {(settings: any) => {
                                        const updatedSettings = getUpdatedSettings(settings);
                                        return (
                                            <ThemeRouterHost
                                                theme={getTheme(settings)}
                                                settings={updatedSettings}
                                                routes={routes}
                                                colorPreset={getColorPresent()}
                                            />
                                        );
                                    }}
                                </SettingsConsumer>
                            </SettingsProvider>
                        </Router>
                    </Suspense>
                </HelmetProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
