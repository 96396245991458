import {Button, Typography} from '@mui/material';
import {Translation, ChevronLeftIcon} from 'palipali-panel-common-web';
import React from 'react';
import {useNavigate} from 'react-router';
import {VehicleCreateWizard} from './VehicleCreateWizard';

interface IVehicleCreateProps {}
const VehicleCreate: React.FC<IVehicleCreateProps> = () => {
    const navigate = useNavigate();
    const handleBackButtonClick = () => {
        navigate('/panel/vehicles/');
    };

    return (
        <>
            <div className="btn-back-wrapper">
                <Button onClick={() => handleBackButtonClick()} className="btn-action" startIcon={<ChevronLeftIcon />}>
                    <Translation text={'vehicles.vehicleOperation.title.backButton'} />
                </Button>
            </div>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="vehicles.vehicleOperation.title.create" />
                    </Typography>
                </div>
            </div>
            <VehicleCreateWizard />
        </>
    );
};

export default VehicleCreate;
