export const convertApiErrorToErrorMessage = (error: any): string => {
    let message = '';
    const response = error.response;

    if (response?.['hydra:description']) {
        message = response['hydra:description'];
    } else if (response?.message) {
        message = response?.message;
    } else {
        message = 'alerts.unknown';
    }

    return message;
};
