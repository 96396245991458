import {IconButton, InputAdornment, TextField} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import CalendarIconSmall from '../../icons/untitled-ui/duocolor/calendar-small';
import {customLocaleTranslation} from '../../../utils/localeUtils';

interface DatePickerControlProps {
    formik: any;
    name: string;
    label: string;
    disablePast?: boolean;
    minDate?: Date | string | number;
    maxDate?: Date | string | number;
    dateFormat?: string;
    startFromTomorrow?: boolean;
    className?: string;
    customEventChange?: (controlName: string, value: any) => void;
}

const DatePickerControl: React.FC<DatePickerControlProps> = ({
    formik,
    name,
    label,
    dateFormat,
    disablePast,
    className,
    customEventChange,
    minDate,
    maxDate,
    startFromTomorrow,
}) => {
    const [open, setOpen] = useState(false),
        {t} = useTranslation(),
        today = new Date(),
        tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customLocaleTranslation()}>
            <DatePicker
                label={t(label)}
                key={`datepicker-${name}`}
                className={`form-control-input-wrapper-${className || ''}`}
                value={formik.values[name]}
                onError={(reason) => {
                    if (formik.dirty) {
                        formik.setFieldTouched(name, true);
                    }
                    formik.setFieldError(name, reason);
                }}
                dayOfWeekFormatter={(day) => t(`datepickerWeekday.${day}`)}
                onChange={(date) => {
                    if (customEventChange) {
                        customEventChange(name, date);
                    }

                    formik.setFieldValue(name, date);
                }}
                open={open}
                minDate={minDate ? minDate : startFromTomorrow ? tomorrow : undefined}
                maxDate={maxDate}
                inputFormat={dateFormat || 'dd/MM/yyyy'}
                onOpen={() => setOpen(true)}
                disablePast={disablePast}
                onClose={() => setOpen(false)}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        key={`datepicker-textfield-${name}`}
                        error={formik.touched[name] && Boolean(formik.errors[name])}
                        helperText={formik.touched[name] && formik.errors[name]}
                        onKeyDown={(event) => event.preventDefault()}
                        onClick={() => setOpen(true)}
                        className={className || ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CalendarIconSmall />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default DatePickerControl;
