import {FormikFieldConfig, FormikFieldTypes, ValidationErrorType} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {IBaseInformationOptions} from '.';

const basicInformationFormConfig = (options: IBaseInformationOptions): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'registrationNumber',
            label: t('vehicles.vehicleOperation.basicInformation.form.registrationNumber.label'),
            validation: Yup.string()
                .max(255)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.TEXT,
            customDisabled: () => true,
        },
        {
            name: 'make',
            label: t('vehicles.vehicleOperation.basicInformation.form.make.label'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            customDisabled: () => true,
            options: options.makes,
        },

        {
            name: 'model',
            label: `${t('vehicles.vehicleOperation.basicInformation.form.model.label')}`,
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            customDisabled: () => true,
            options: options.models,
        },
        {
            name: 'vehicleType',
            label: t('vehicles.vehicleOperation.basicInformation.form.vehicleTypeId.label'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            customDisabled: () => true,
            options: options.vehicleTypes,
        },
        {
            name: 'productionYear',
            label: t('vehicles.vehicleOperation.basicInformation.form.productionYear.label'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            customDisabled: () => true,
            options: options.productionYears,
        },
        {
            name: 'colorId',
            label: t('vehicles.vehicleOperation.basicInformation.form.colorId.label'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            customDisabled: () => true,
            options: options.colors,
        },
        {
            name: 'packageSizes',
            label: t('vehicles.vehicleOperation.basicInformation.form.packageSizes.label'),
            validation: Yup.string(),
            type: FormikFieldTypes.PACKAGE_SIZE,
            customDisabled: () => true,
        },
    ];
};
export default basicInformationFormConfig;
