import React from 'react';
import {
    FormControlChangeType,
    IBaseGroupedFormControlProps,
    IBaseGroupedFormControlState,
    IFormControlConfig,
    IFormControls,
    IFormGroupConfig,
    InputDataMapper,
    OutputDataMapper,
} from '../../types';
import {defaultDataAccessor, defaultDataMapper, defaultOutputDataMapper} from '../../utils/formUtils';
import {runValidators} from '../../utils/formValidators';

abstract class BaseGroupedFormControl<
    Props extends IBaseGroupedFormControlProps,
    State extends IBaseGroupedFormControlState
> extends React.Component<Props, State> {
    protected get inputDataMapper(): InputDataMapper {
        if (!this.props.config) {
            return defaultDataMapper;
        }

        return this.props.config.inputDataMapper || defaultDataMapper;
    }

    protected get outputDataMapper(): OutputDataMapper {
        if (!this.props.config) {
            return defaultOutputDataMapper;
        }

        return this.props.config.outputDataMapper || defaultOutputDataMapper;
    }

    protected get dataAccessor(): (data: any, key: string | number) => any {
        if (!this.props.config) {
            return defaultDataAccessor;
        }

        return this.props.config.dataAccessor || defaultDataAccessor;
    }

    protected get hasError(): boolean {
        return this.state.touched && (!this.state.valid || !this.isValid(this.state.childValidationState));
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            value: this.inputDataMapper(this.props.value, this.props.config),
            valid: true,
            errorMessages: [],
            touched: false,
            childValidationState: {},
            mappedOutputValue: {},
        } as State;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.inputDataMapper(this.props.value, this.props.config),
            });
        }
        const emptyArray = [];
        const currentGroups = (this.props.config || {}).controls || emptyArray;
        const prevGroups = (prevProps.config || {}).controls || emptyArray;
        if (currentGroups !== prevGroups) {
            const updatedState = Object.assign({}, this.state.childValidationState);
            const updatedMappedOutputValue = Object.assign({}, this.state.mappedOutputValue);
            const currentGroupKeys = this.mapControls(currentGroups, (_, controlName: string) => controlName);

            this.mapControls(prevGroups, (_, controlName: string) => controlName)
                .filter((controlName) => -1 === currentGroupKeys.indexOf(controlName))
                .forEach((controlName) => {
                    delete updatedState[controlName];
                    delete updatedMappedOutputValue[controlName];
                });

            if (this.props.onValidationStateChange) {
                const isValid = this.isValid(updatedState),
                    previousState = prevState?.childValidationState || {};
                if (
                    this.isValid(previousState) !== isValid ||
                    (isValid &&
                        0 === Object.getOwnPropertyNames(updatedState).length &&
                        0 === Object.getOwnPropertyNames(previousState).length)
                ) {
                    this.props.onValidationStateChange(this.props.controlName, isValid, []);
                }
            }
            this.setState({
                childValidationState: updatedState,
                mappedOutputValue: updatedMappedOutputValue,
            });
        }
    }

    protected abstract renderControls(controls: IFormControls);

    protected mapControls<T = any>(
        controls: IFormControls,
        mapper: (value: IFormGroupConfig | IFormControlConfig, controlName: string) => T
    ): T[] {
        if (Array.isArray(controls)) {
            return controls.map((control: IFormGroupConfig) => mapper(control, control.key));
        }

        return Object.getOwnPropertyNames(controls).map((controlName: string) => mapper(controls[controlName], controlName));
    }

    protected onChildValidationStateChanged = (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>): void => {
        this.setState((state, props) => {
            const updatedState = Object.assign({}, state.childValidationState) as {[key: string]: boolean};
            updatedState[controlName] = isValid;
            if (props.onValidationStateChange) {
                props.onValidationStateChange(props.controlName, state.valid && this.isValid(updatedState), errorMessages);
            }

            return {
                childValidationState: updatedState,
            };
        });
    };

    protected onTouchedStateChanged = (controlName: string, touched: boolean): void => {
        if (!touched) {
            return;
        }
        this.setState({
            touched: true,
        });

        if (this.props.onTouchedStateChange) {
            this.props.onTouchedStateChange(this.props.controlName, true);
        }
    };

    protected onValueStateChanged = (controlName: string, value: any, changeType: FormControlChangeType): void => {
        this.setState((state: State, props: Props) => {
            let updatedMappedOutputValue = Object.assign({}, state.mappedOutputValue) as {[key: string]: any};
            updatedMappedOutputValue = this.outputDataMapper(value, updatedMappedOutputValue, controlName, changeType);

            if (props.onValueStateChange) {
                props.onValueStateChange(props.controlName, updatedMappedOutputValue, changeType);
            }
            const stateUpdate: any = {mappedOutputValue: updatedMappedOutputValue};

            if (props.config.validationRules) {
                const result = runValidators(updatedMappedOutputValue, props.config.validationRules);
                stateUpdate.valid = result.valid;
                stateUpdate.errorMessages = result.errorMessages;

                if (props.onValidationStateChange) {
                    props.onValidationStateChange(
                        props.controlName,
                        stateUpdate.valid && this.isValid(state.childValidationState),
                        result.errorMessages
                    );
                }
            }

            return stateUpdate;
        });
    };

    protected isValid(childValidationState: {[key: string]: boolean}): boolean {
        return Object.getOwnPropertyNames(childValidationState).every((controlName) => true === childValidationState[controlName]);
    }
}

export default BaseGroupedFormControl;
