import {Theme} from '@mui/material';
import {AuthPanel, NotFound, UserRole, activeLanguageSelector} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {PanelHost} from '../components/PanelHost';
import Home from '../components/Home';
import {useSelector} from 'react-redux';
import i18n from '../i18n';

const routes = (theme: Theme) => {
    const activeLanguage: string = useSelector(activeLanguageSelector);
    useEffect(() => {
        if (activeLanguage && i18n.language !== activeLanguage) {
            i18n.changeLanguage(activeLanguage);
        }
    }, [activeLanguage]);

    return [
        {
            path: '/',
            key: 'home',
            element: <Home />,
        },
        {
            path: '/couriers/*',
            key: 'couriers-auth',
            element: <AuthPanel theme={theme} envData={process.env} userRole={UserRole.COURIER} />,
        },
        {
            path: '/auth/*',
            key: 'auth',
            element: <AuthPanel theme={theme} envData={process.env} userRole={UserRole.ROLE_FLEET_PARTNER} />,
        },
        {
            path: '/panel/*',
            key: 'panel-host',
            element: <PanelHost theme={theme} userRole={UserRole.ROLE_FLEET_PARTNER} />,
        },
        {
            path: '*',
            key: 'not-found',
            element: <NotFound />,
        },
    ];
};

export default routes;
