import React from 'react';
import {CustomIconProps} from '../../../types/iconTypes';

const WarningTriangleIcon: React.FC<CustomIconProps> = ({size, ...props}) => (
    <svg width={size ? size : 22} height={size ? size : 22} fill="none" {...props}>
        <path
            d="M11.0003 5.949L17.9028 17.8748H4.09783L11.0003 5.949ZM11.0003 2.2915L0.916992 19.7082H21.0837L11.0003 2.2915Z"
            fill="currentColor"
        />
        <path d="M11.917 15.1248H10.0837V16.9582H11.917V15.1248Z" fill="currentColor" />
        <path d="M11.917 9.62484H10.0837V14.2082H11.917V9.62484Z" fill="currentColor" />
    </svg>
);

export default WarningTriangleIcon;
