import {Drawer} from '@mui/material';
import PropTypes from 'prop-types';
import type {FC} from 'react';
import React from 'react';
import {NavColor, Section} from '../../types/themeTypes';
import {UserRole} from '../../../types';
import {Nav} from '../Nav';
import useCssVars from '../useCssVars';

const SIDE_NAV_WIDTH = 280;

interface ISideNavHostProps {
    color?: NavColor;
    sections?: Section[];
    subMenuSections?: Section[];
    userRole: UserRole;
    userName: string | null;
}
export const SideNavHost: FC<ISideNavHostProps> = (props) => {
    const {color = 'evident'} = props;

    const cssVars = useCssVars(color);

    return (
        <Drawer
            anchor="left"
            open
            PaperProps={{
                sx: {
                    ...cssVars,
                    backgroundColor: 'var(--nav-bg)',
                    borderRightColor: 'var(--nav-border-color)',
                    borderRightStyle: 'solid',
                    borderRightWidth: 1,
                    color: 'var(--nav-color)',
                    width: SIDE_NAV_WIDTH,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flex: '1 1 auto',
                },
            }}
            variant="permanent">
            <Nav sections={props.sections} subMenuSections={props.subMenuSections} userRole={props.userRole} userName={props.userName} />
        </Drawer>
    );
};
SideNavHost.propTypes = {
    color: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident']),
    sections: PropTypes.array,
};
