import {Observable} from 'rxjs';
import {ICreateTeamInput, ITeamViewOutput, IUpdateTeamInput} from '../../model/team';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const createTeamAPI: ApiOperationResponseFunction<ITeamViewOutput> = (
    authToken: string,
    payload: {team: ICreateTeamInput}
): Observable<IApiSingleResponseBase<ITeamViewOutput>> => {
    const createPayload: ICreateTeamInput = payload.team;
    return palipaliAPI.post(`api/teams`, createPayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
