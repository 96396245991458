import {PayloadAction} from '@reduxjs/toolkit';
import createBaseReducerSlice from './baseSlice';

export enum MemberOperationType {
    //common
    LOGOUT = 'logout',

    //fleet partner modals:
    COURIERS_COURIER_INVITE = 'couriers-courier-invite',
    COURIERS_DELETE_COURIER = 'couriers-delete-courier',
    COURIERS_ASSIGNED_VEHICLES = 'couriers-assigned-vehicles',
    VEHICLES_ASSIGNED_COURIERS = 'vehicles-assigned-couriers',
    VEHICLE_DELETE_VEHICLE = 'vehicle-delete-vehicle',
    ACCOUNT_DELETE_ACCOUNT = 'account-delete-account',
    ACCOUNT_CONFIGURE_SETTLEMENT = 'account-configure-settlement',
    ASSIGN_VEHICLE = 'assign-vehicle',

    //PURCHASER MODALS:
    ACTIVE_ORDERS_COURIER_DETAILS = 'active-orders-courier-details',
    ACTIVE_ORDERS_ORDER_EDIT = 'active-orders-order-edit',
    ACTIVE_ORDERS_ORDER_CANCEL = 'active-orders-order-cancel',
    ACTIVE_ORDERS_ORDER_DELETE = 'active-orders-order-delete',
    ACTIVE_ORDERS_ORDER_REPORT = 'active-orders-order-report',
    HISTORICAL_ORDERS_COURIER_DETAILS = 'historical-orders-courier-details',
    HISTORICAL_ORDERS_ORDER_REPORT = 'historical-orders-order-report',
    NEW_ORDER_DIMENSIONS = 'new-order-dimensions',
    NEW_ORDER_ADDRESS_BOOK = 'new-order-address-book',
    TEAMS_REMOVE_PAYMENT_METHOD = 'teams-remove-payment-method',
    TEAMS_REMOVE_TEAM = 'teams-remove-team',
    TEAMS_REMOVE_MEMBER = 'teams-remove-member',
    TEAMS_ADD_MEMBER = 'teams-add-member',
    TEAMS_EDIT_MEMBER = 'teams-edit-member',
    LOCATIONS_OPENING_HOURS = 'locations-opening-hours',
    LOCATIONS_DELETE_LOCATION = 'locations-delete-location',
    SETTINGS_DELETE_ACCOUNT = 'settings-delete-account',
    DELETE_TEAM = 'delete-team',
    DELETE_PAYMENT_METHOD = 'delete-payment-method',
    LOCATION_MAY_BE_CLOSED = 'location-may-be-closed',
}

export interface IModalState {
    isOpen: boolean;
    operationType: MemberOperationType | null;
}

export interface IOpenModal {
    isOpen: boolean;
    operationType: MemberOperationType | null;
}
const initialState: IModalState = {
    isOpen: false,
    operationType: null,
};

const modalSlice = createBaseReducerSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        openModal: {
            reducer: (state: IModalState, action: PayloadAction<IOpenModal>) => {
                return {
                    ...state,
                    isOpen: true,
                    operationType: action.payload.operationType,
                };
            },
            prepare: (operationType: MemberOperationType | null) => {
                return {
                    payload: {
                        operationType: operationType,
                    },
                };
            },
        },
        closeModal: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {openModal, closeModal} = modalSlice.actions;
export default modalSlice.reducer;
