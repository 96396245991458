import {IModelApiObject} from './base';

export enum DictionaryName {
    CITY = 'cities',
    COUNTRY = 'countries',
    COLOR = 'colors',
    LOCATION_TYPE = 'location_types',
    VEHICLE_TYPE = 'vehicle_types',
    VEHICLE_DEFINITION = 'vehicle_definitions',
    SETTLEMENT_PERIOD = 'settlement_periods',
    JOB_PROBLEM_TYPES = 'job_problem_types',
    JOB_CANCELLATION_REASONS = 'job_cancellation_reasons',
}

export interface IModelDictionaryDatum extends IModelApiObject {
    readonly id: string;
    readonly name: string;
    readonly name_i18n?: ITranslationName;
    readonly code?: string;
    readonly allowedFor?: string[] | null;
}

export type ITranslationName = {
    [locale in 'en' | 'pl' | 'uk']: string;
};

export interface IJobCancellationReasonOutput extends IModelApiObject {
    readonly id: string;
    readonly name: string;
    readonly code: string;
    readonly name_i18n: ITranslationName;
}
