import {Button, Stack, useTheme} from '@mui/material';
import {
    FormikForm,
    FormikRegexSettings,
    HorizontalCard,
    IBillingInfoInput,
    SeverityPill,
    Translation,
    doesValueMatchRegex,
    fleetPartnerIdSelector,
    isFleetPartnerStripeOnboardingCompletedSelector,
} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateBillingInfo} from '../../../../store/reducers/accountSettlmentSlice';
import {
    accountSettlementBillingInfoSelector,
    accountSettlementCanDoStripeOnboardingSelector,
    isLoadingAccountSettlementSelector,
} from '../../../../store/selectors/accountSettlementSelector';
import {billingInfoFormConfig} from './billingInfoFormConfig';

const BillingInfoCard: React.FC = () => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        billingInfo: IBillingInfoInput | null = useSelector(accountSettlementBillingInfoSelector),
        fleetPartnerId: string = useSelector(fleetPartnerIdSelector),
        isLoading: boolean = useSelector(isLoadingAccountSettlementSelector),
        isStripeOnboardingCompleted: boolean = useSelector(isFleetPartnerStripeOnboardingCompletedSelector),
        isCanDoStripeOnboarding: boolean = useSelector(accountSettlementCanDoStripeOnboardingSelector),
        isBillingInfoFormValid: boolean = isStripeOnboardingCompleted || isCanDoStripeOnboarding,
        [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>(false);
    const initialValues: IBillingInfoInput = {
        companyName: billingInfo?.companyName || '',
        taxIdentifier:
            billingInfo?.taxIdentifier && doesValueMatchRegex(billingInfo.taxIdentifier, FormikRegexSettings.NIP)
                ? billingInfo.taxIdentifier
                : '',
        street: billingInfo?.street || '',
        houseNumber: billingInfo?.houseNumber || '',
        flatNumber: billingInfo?.flatNumber || '',
        city: billingInfo?.city || '',
        zip: billingInfo?.zip && doesValueMatchRegex(billingInfo.zip, FormikRegexSettings.POSTAL_CODE) ? billingInfo.zip : '',
    };

    const submitHandle = (values: IBillingInfoInput) => {
        if (!values) return;
        const payload: IBillingInfoInput = {
            companyName: values?.companyName,
            taxIdentifier: values?.taxIdentifier,
            street: values?.street,
            houseNumber: values?.houseNumber,
            flatNumber: values?.flatNumber,
            city: values?.city,
            zip: values?.zip,
        };
        dispatch(updateBillingInfo(payload, fleetPartnerId));
    };

    return (
        <HorizontalCard
            heading={
                <Stack sx={{alignItems: 'flex-start'}}>
                    <Translation text="account.settlements.billingInfoCard.title" />

                    {!isBillingInfoFormValid && (
                        <SeverityPill className={`stripe-status-pill warning`}>
                            <Translation text={`account.status.toFill`} />
                        </SeverityPill>
                    )}
                </Stack>
            }
            content={
                <FormikForm
                    theme={theme}
                    formId="billingInfoForm"
                    fields={billingInfoFormConfig()}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    submitAllowed={setIsSubmitAllowed}
                    onSubmit={submitHandle}
                />
            }
            actions={
                <Button
                    type="submit"
                    size="medium"
                    variant="text"
                    form="billingInfoForm"
                    className="btn-action account-settlements-billing-info-submit-button"
                    disabled={!isSubmitAllowed}>
                    <Translation text={`buttons.update`} />
                </Button>
            }
            showLoader={isLoading}
        />
    );
};

export default BillingInfoCard;
