import type {SVGProps} from 'react';
import React from 'react';

const IncomeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" {...props}>
        <rect x="0.333008" y="0.5" width="48" height="48" rx="24" fill="#009FE3" fillOpacity="0.12" />
        <g clipPath="url(#clip0_154_67481)">
            <path
                d="M18.933 22.8V26.4M29.733 21V24.6M28.833 16.5C31.0368 16.5 32.2288 16.8373 32.8219 17.0989C32.9009 17.1337 32.9404 17.1512 33.0544 17.2599C33.1227 17.3251 33.2474 17.5165 33.2795 17.6053C33.333 17.7535 33.333 17.8345 33.333 17.9965V27.67C33.333 28.4879 33.333 28.8969 33.2104 29.1071C33.0856 29.3209 32.9653 29.4203 32.7318 29.5025C32.5022 29.5833 32.0388 29.4942 31.112 29.3161C30.4633 29.1915 29.6939 29.1 28.833 29.1C26.133 29.1 23.433 30.9 19.833 30.9C17.6292 30.9 16.4372 30.5627 15.8441 30.3011C15.7651 30.2663 15.7256 30.2488 15.6116 30.1401C15.5433 30.0749 15.4186 29.8835 15.3865 29.7947C15.333 29.6465 15.333 29.5655 15.333 29.4035L15.333 19.73C15.333 18.9121 15.333 18.5031 15.4557 18.2929C15.5804 18.0791 15.7007 17.9797 15.9343 17.8975C16.1638 17.8167 16.6272 17.9058 17.5541 18.0839C18.2028 18.2085 18.9721 18.3 19.833 18.3C22.533 18.3 25.233 16.5 28.833 16.5ZM26.583 23.7C26.583 24.9426 25.5756 25.95 24.333 25.95C23.0904 25.95 22.083 24.9426 22.083 23.7C22.083 22.4574 23.0904 21.45 24.333 21.45C25.5756 21.45 26.583 22.4574 26.583 23.7Z"
                stroke="#009FE3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_154_67481">
                <rect width="24" height="24" fill="white" transform="translate(12.333 12.5)" />
            </clipPath>
        </defs>
    </svg>
);

export default IncomeIcon;
