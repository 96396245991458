import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IDetachStripePaymentMethodInput, IPaymentAccountOutput, UserRole} from '../../types';
import merge from 'lodash/merge';
import {initApiCall} from '../../utils/reduxHelpers';
import {IAccountMeOutput, IAccountBasicInfoOutput, DEFAULT_LOCALE} from '../../model/account';
import {IChangeUserPasswordInput} from '../../model/user';

export const NULL_ACCOUNT: IAccountMeOutput = {
    account: {
        id: '',
        userId: '',
        username: '',
        firstName: '',
        lastName: '',
        locale: DEFAULT_LOCALE,
        courier: null,
        phone: null,
        avatar: null,
        misc: {theme: null},
        cityId: null,
    },
    completedAccounts: {
        fleetPartner: null,
        courier: null,
    },
};

export interface IAccountState {
    account: IAccountMeOutput | null;
    isLoading: boolean;
    isInitialized: boolean;
    isActionSuccessful: boolean;
    error: string | null;
    canBeDeleted: boolean;
}

interface ISetAccountState {
    account: IAccountMeOutput;
}

export interface IUpdateAccountProfile {
    username: string;
    firstName: string;
    lastName: string;
    country: string;
    phone: string;
    cityId: string;
    returnUrl: string;
}

interface ISetAccountInfoState {
    accountInfo: IAccountBasicInfoOutput;
}

export interface IGetFullAccount {
    accountId: string;
}

export interface IUpdateAvatar {
    avatar: any;
    accountId: string;
}

export interface IUpdateLocale {
    locale: string;
}

interface IUpdateAccountFailure {
    error: string;
}

export interface ISetAccountStateFailure {
    error: string;
}

export interface ISetShowCardRemoveModal {
    showModal: boolean;
}

export interface IRemovePaymentMethod {
    paymentAccountId: string;
    paymentMethodInput: IDetachStripePaymentMethodInput;
}

export interface IUpdateUserPasswordInput {
    passwordForm: IChangeUserPasswordInput;
}

export interface IUpdatePaymentAccountSuccess {
    paymentAccount: IPaymentAccountOutput;
}

export interface ISetCanDeleteAccount {
    canBeDeleted: boolean;
}

export interface IDeleteAccount {
    id: string;
}

export type APP_THEME = 'dark' | 'normal';
export const APP_THEME_DARK = 'dark';
export const APP_THEME_NORMAL = 'normal';
export const APP_THEMES = [APP_THEME_DARK, APP_THEME_NORMAL];

export interface IChangeTheme {
    theme: APP_THEME;
}

export interface IChangeWebSettings {
    misc: {theme: APP_THEME};
    locale: string;
}

export interface IChangeUserPasswordInputSuccess {}

const initialState: IAccountState = {
    account: NULL_ACCOUNT,
    isLoading: false,
    isInitialized: false,
    isActionSuccessful: false,
    error: null,
    canBeDeleted: false,
};

const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        setAccountState: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountState>) => {
                const account: any = action.payload.account ? action.payload.account : NULL_ACCOUNT;
                return {
                    ...state,
                    account: account,
                    isInitialized: true,
                    isLoading: false,
                };
            },
            prepare(account: IAccountMeOutput) {
                return {
                    payload: {
                        account: account,
                    },
                };
            },
        },
        setIsAccountLoading: {
            reducer: (state: IAccountState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isLoading: action.payload,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: isLoading,
                };
            },
        },
        updateAccountSuccess: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountState>) => {
                return {
                    ...state,
                    isLoading: false,
                    showUpdateBasicInfoModal: false,
                    showChangeAvatarModal: false,
                    isActionSuccessful: true,
                    account: action.payload.account,
                };
            },
            prepare(account: IAccountMeOutput) {
                return {
                    payload: {
                        account: account,
                    },
                };
            },
        },
        updateAccountInfoSuccess: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountInfoState>) => {
                const updatedData = merge({}, state.account, {account: action.payload.accountInfo});

                return {
                    ...state,
                    isLoading: false,
                    showUpdateBasicInfoModal: false,
                    showChangeAvatarModal: false,
                    isActionSuccessful: true,
                    account: updatedData,
                };
            },
            prepare(accountInfo: IAccountBasicInfoOutput) {
                return {
                    payload: {
                        accountInfo,
                    },
                };
            },
        },
        setPaymentAccountState: {
            reducer: (state: IAccountState, action: PayloadAction<IUpdatePaymentAccountSuccess>) => {
                const updatedData = state.account ? merge({}, state.account, {paymentAccount: action.payload.paymentAccount}) : null;
                return {
                    ...state,
                    isLoading: false,
                    showCardRemoveModal: false,
                    account: updatedData,
                };
            },
            prepare(paymentAccount: IPaymentAccountOutput) {
                return {
                    payload: {
                        paymentAccount,
                    },
                };
            },
        },
        checkCanDeleteAccount: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        setCanDeleteAccount: {
            reducer: (state: IAccountState, action: PayloadAction<ISetCanDeleteAccount>) => {
                return {
                    ...state,
                    canBeDeleted: action.payload.canBeDeleted,
                };
            },
            prepare: (canBeDeleted: boolean) => {
                return {
                    payload: {
                        canBeDeleted,
                    },
                };
            },
        },
        deleteAccount: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        setAccountStateFailure: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountStateFailure>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        updateAccountFailure: {
            reducer: (state: IAccountState, action: PayloadAction<IUpdateAccountFailure>) => {
                return {
                    ...state,
                    isInitialized: true,
                    error: action.payload.error,
                    isLoading: false,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        changePasswordSuccess: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: false,
                    showChangePasswordModal: false,
                };
            },
            prepare() {
                return {
                    payload: {},
                };
            },
        },
        confirmEmailChange: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(registrationToken: string, panelAccessRole: UserRole) {
                return {
                    payload: {
                        registrationToken: registrationToken,
                        panelAccessRole,
                    },
                };
            },
        },
        updateAccountProfile: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(data: IUpdateAccountProfile) {
                return {
                    payload: data,
                };
            },
        },
        changeTheme: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (theme: APP_THEME) => {
                return {
                    payload: {
                        theme: theme,
                    },
                };
            },
        },
        changeWebSettings: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (webSettings: IChangeWebSettings) => {
                return {
                    payload: {
                        misc: webSettings.misc,
                        locale: webSettings.locale,
                    },
                };
            },
        },
        returnToInitialState: () => {
            return {
                ...initialState,
            };
        },
        silentRefreshDictionariesAndParameters: (state: IAccountState) => {
            return {...state};
        },
        getAccount: (state: IAccountState) => initApiCall(state),
        getFullAccount: (state: IAccountState, action: PayloadAction<IGetFullAccount>) => initApiCall(state, action),
        changeAvatar: (state: IAccountState, action: PayloadAction<IUpdateAvatar>) => initApiCall(state, action),
        changeLocale: (state: IAccountState, action: PayloadAction<IUpdateLocale>) => initApiCall(state, action),
        changePassword: (state: IAccountState, action: PayloadAction<IChangeUserPasswordInput>) => initApiCall(state, action),
        removePaymentMethod: (state: IAccountState, action: PayloadAction<IRemovePaymentMethod>) => initApiCall(state, action),
    },
});

export const {
    setAccountState,
    updateAccountProfile,
    updateAccountSuccess,
    updateAccountInfoSuccess,
    updateAccountFailure,
    setAccountStateFailure,
    getAccount,
    changeAvatar,
    getFullAccount,
    changePassword,
    deleteAccount,
    setCanDeleteAccount,
    checkCanDeleteAccount,
    changePasswordSuccess,
    changeLocale,
    changeTheme,
    changeWebSettings,
    setIsAccountLoading,
    confirmEmailChange,
    removePaymentMethod,
    setPaymentAccountState,
    returnToInitialState,
    silentRefreshDictionariesAndParameters,
} = accountSlice.actions;

export default accountSlice.reducer;
