import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, ICanOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';

export function getCanCreateOpinionJobAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<ICanOutput>> {
    return palipaliAPI.get(`api/jobs/${id}/can_create_opinion`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
