import {PayloadAction} from '@reduxjs/toolkit';
import {IJobCancellationReasonOutput} from '../../types';
import createBaseReducerSlice from './baseSlice';

export interface IJobCancellationReasonState {
    readonly jobCancellationReasons: IJobCancellationReasonOutput[] | null;
    readonly isLoading: boolean;
    readonly error: string | null;
}

export interface IChangeJobCancellationReasons {
    jobCancellationReasons: IJobCancellationReasonOutput[] | null;
}

export interface ISetJobCancellationReasonError {
    error: string | null;
}

export interface ISetJobCancellationReasonLoading {
    isLoading: boolean;
}

const initialState: IJobCancellationReasonState = {
    jobCancellationReasons: null,
    isLoading: false,
    error: null,
};

const jobCancellationReasonSlice = createBaseReducerSlice({
    name: 'jobCancellationReason',
    initialState: initialState,
    reducers: {
        changeCancellationReason: {
            reducer: (state: IJobCancellationReasonState, action: PayloadAction<IChangeJobCancellationReasons>) => {
                return {
                    ...state,
                    jobCancellationReasons: action.payload.jobCancellationReasons,
                };
            },
            prepare: (jobCancellationReasons: IJobCancellationReasonOutput[] | null) => {
                return {
                    payload: {
                        jobCancellationReasons: jobCancellationReasons,
                    },
                };
            },
        },
        fetchCancellationReasons: (state: IJobCancellationReasonState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        setError: {
            reducer: (state: IJobCancellationReasonState, action: PayloadAction<ISetJobCancellationReasonError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare: (error: string | null) => {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        setIsCancellationReasonLoading: {
            reducer: (state: IJobCancellationReasonState, action: PayloadAction<ISetJobCancellationReasonLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare: (isLoading: boolean) => {
                return {
                    payload: {
                        isLoading: isLoading,
                    },
                };
            },
        },
    },
});

export const {changeCancellationReason, fetchCancellationReasons, setError, setIsCancellationReasonLoading} =
    jobCancellationReasonSlice.actions;

export default jobCancellationReasonSlice.reducer;
