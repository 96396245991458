import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {ILocationInput, ILocationOutput} from '../../model/location';

export const updateLocationAPI: ApiOperationResponseFunction<ILocationOutput> = (
    authToken: string,
    payload: {
        id: string;
        location: ILocationInput;
    }
): Observable<IApiSingleResponseBase<ILocationOutput>> => {
    return palipaliAPI.put(`api/locations/${payload.id}`, payload.location, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
