import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    IBaseReducerState,
    ICourierJobDayStatisticsEntry,
    ICourierJobReportListingEntry,
    initApiCall,
    IMoneyOutput,
    IServerReportsFilters,
    formatStartDate,
    formatEndDate,
    finishApiCall,
} from 'palipali-panel-common-web';
import moment from 'moment';

export interface IReportsState extends IBaseReducerState {
    statistics: ICourierJobDayStatisticsEntry[];
    reportsListing: ICourierJobReportListingEntry[];
    reportsFilters: IServerReportsFilters;
    reportsMetadata: {
        totalDistance: number | null;
        totalTime: number | null;
        totalIncome: IMoneyOutput | null;
    };
    page: number;
    totalNumberOfResults: number;
}

export interface IGetReportsListing {
    filters: IServerReportsFilters;
    page: number;
}

export interface IGetReportsListingSuccess {
    reportsListing: ICourierJobReportListingEntry[];
    reportsMetadata: {
        totalDistance: number | null;
        totalTime: number | null;
        totalIncome: IMoneyOutput | null;
    };
    page: number;
    totalNumberOfResults: number;
}

export interface IGetReportStatisticsSuccess {
    statistics: ICourierJobDayStatisticsEntry[];
}
export interface IGetReportsListingFailed {
    error: string;
}

export interface IGetReportCSV {
    filters: IServerReportsFilters;
    page: number;
}

const initialState: IReportsState = {
    isInitialized: false,
    isLoading: false,
    error: null,
    statistics: [],
    reportsListing: [],
    reportsFilters: {
        'fleetPartnerVehicle[]': [],
        'courier.id[]': [],
        'city.id[]': [],
        'finishedAt[before]': formatStartDate(moment().toDate()),
        'startedAt[after]': formatEndDate(moment().startOf('month').toDate()),
        courierVehicle: false,
    },
    reportsMetadata: {
        totalDistance: null,
        totalTime: null,
        totalIncome: null,
    },
    page: 0,
    totalNumberOfResults: 0,
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        getReportsListing: {
            reducer: (state, action: PayloadAction<IGetReportsListing>) => {
                return {
                    ...state,
                    isLoading: true,
                    error: null,
                    reportsFilters: action.payload.filters,
                };
            },
            prepare: (reportsFilters: IServerReportsFilters, page) => ({payload: {filters: reportsFilters, page}}),
        },
        getReportsListingSuccess: {
            reducer: (state, action: PayloadAction<IGetReportsListingSuccess>) => {
                return {
                    ...state,
                    isLoading: false,
                    reportsListing: action.payload.reportsListing,
                    reportsMetadata: {
                        totalDistance: action.payload.reportsMetadata.totalDistance,
                        totalTime: action.payload.reportsMetadata.totalTime,
                        totalIncome: action.payload.reportsMetadata.totalIncome,
                    },
                    page: action.payload.page,
                    totalNumberOfResults: action.payload.totalNumberOfResults,
                };
            },
            prepare: (
                reportsListing: ICourierJobReportListingEntry[],
                reportsMetadata: any,
                page: number,
                totalNumberOfResults: number
            ) => ({
                payload: {reportsListing, reportsMetadata, page, totalNumberOfResults},
            }),
        },
        getReportsListingFailed: {
            reducer: (state, action: PayloadAction<IGetReportsListingFailed>) => {
                return {
                    ...state,
                    isLoading: false,
                    error: action.payload.error,
                };
            },
            prepare: (error: string) => ({payload: {error}}),
        },
        getReportStatisticsSuccess: {
            reducer: (state, action: PayloadAction<IGetReportStatisticsSuccess>) => {
                return {
                    ...state,
                    isLoading: false,
                    statistics: action.payload.statistics,
                };
            },
            prepare: (statistics: ICourierJobDayStatisticsEntry[]) => ({payload: {statistics}}),
        },
        getReportsStatistics: (state, action: PayloadAction<IGetReportsListing>) => initApiCall(state, action),
        downloadReport: (state, action: PayloadAction<IGetReportCSV>) => initApiCall(state, action),
        finishReportDownload: (state) => finishApiCall(state),
    },
});

export const {
    getReportsListing,
    getReportsListingSuccess,
    getReportsListingFailed,
    getReportStatisticsSuccess,
    getReportsStatistics,
    downloadReport,
    finishReportDownload,
} = reportsSlice.actions;
export default reportsSlice.reducer;
