import type {SVGProps} from 'react';
import React from 'react';

const PackageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
        <rect width="36" height="36" rx="8" fill="#F2F4F7" />
        <path
            d="M25.0833 14.0653L18 18.0005M18 18.0005L10.9166 14.0653M18 18.0005L18 25.9171M25.5 21.3826V14.6183C25.5 14.3328 25.5 14.19 25.4579 14.0627C25.4207 13.9501 25.3599 13.8467 25.2795 13.7594C25.1886 13.6608 25.0638 13.5915 24.8142 13.4528L18.6475 10.0269C18.4112 9.89558 18.293 9.82993 18.1679 9.80419C18.0571 9.78141 17.9429 9.78141 17.8321 9.80419C17.707 9.82993 17.5888 9.89558 17.3525 10.0269L11.1858 13.4528C10.9362 13.5915 10.8114 13.6608 10.7205 13.7594C10.6401 13.8467 10.5793 13.9501 10.5421 14.0627C10.5 14.19 10.5 14.3328 10.5 14.6183V21.3826C10.5 21.6681 10.5 21.8109 10.5421 21.9382C10.5793 22.0509 10.6401 22.1543 10.7205 22.2415C10.8114 22.3401 10.9362 22.4095 11.1858 22.5481L17.3525 25.9741C17.5888 26.1054 17.707 26.171 17.8321 26.1968C17.9429 26.2195 18.0571 26.2195 18.1679 26.1968C18.293 26.171 18.4112 26.1054 18.6475 25.9741L24.8142 22.5481C25.0638 22.4095 25.1886 22.3401 25.2795 22.2415C25.3599 22.1543 25.4207 22.0509 25.4579 21.9382C25.5 21.8109 25.5 21.6681 25.5 21.3826Z"
            stroke="#0093E6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M21.75 15.9167L14.25 11.75" stroke="#0093E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default PackageIcon;
