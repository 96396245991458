import {combineEpics, Epic, ofType} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, concatMap} from 'rxjs/operators';
import {getDictionaryDataAPI} from '../../api/dictionary/getDictionaryDataAPI';
import {ListSuccessActionsFunction} from '../../api/base/apiConnectionInfrastructure';
import {IModelCity, IModelColor, IModelCountry} from '../../model/common';
import {DictionaryName, IModelDictionaryDatum} from '../../model/dictionaryDatum';
import {IModelVehicleType} from '../../model/vehicleType';
import {AlertType, ISettlementPeriodOutput, IJobCancellationReasonOutput} from '../../types';
import {createDictionaryEpic, getErrorMessage} from '../../utils/epicUtils';
import {addAlert} from '../reducers/alertSlice';
import {changeCity, changeIsCityInitialized, changeIsCityLoading, fetchCities} from '../reducers/citySlice';
import {changeColor, changeIsColorLoading, fetchColors} from '../reducers/colorSlice';
import {changeCountry, changeIsCountryLoading, fetchAllDictionaryData, fetchCountries} from '../reducers/countrySlice';
import {changeIsLocationTypeLoading, changeLocationType, fetchLocationTypes} from '../reducers/locationTypeSlice';
import {changeIsVehicleTypeLoading, changeVehicleType, fetchVehicleTypes} from '../reducers/vehicleTypeSlice';
import {changeIsSettlementPeriodLoading, fetchSettlementPeriod, setSettlementPeriod} from '../reducers/settlementPeriodSlice';
import {IGroupedByMakeModelTypeYearVehicleDefinitionIds, IVehicleDefinitionOutput} from '../../model/vehicleDefinition';
import {changeIsVehicleDefinitionsLoading, changeVehicleDefinitions, fetchVehicleDefinitions} from '../reducers/vehicleDefinitionsSlice';
import {changeProblemType, getProblemTypes, setIsLoading} from '../reducers/jobProblemTypeSlice';
import {IModelJobProblemType} from '../../model/jobProblemType';
import {changeCancellationReason, fetchCancellationReasons, setIsCancellationReasonLoading} from '../reducers/jobCancellationReasonSlice';
import {ILocationTypeOutput} from '../../model/location';
import {handleApiError} from '../../utils/errorHandlingUtils';

const fetchAllDictionaryDataEpic: Epic = (action$) =>
    action$.pipe(
        ofType(fetchAllDictionaryData.type),
        concatMap(() => {
            return of(
                fetchCities(),
                fetchCountries(),
                fetchColors(),
                fetchLocationTypes(),
                fetchVehicleTypes(),
                fetchSettlementPeriod(),
                fetchVehicleDefinitions(),
                getProblemTypes(),
                fetchCancellationReasons()
            );
        }),
        catchError((error: any) => of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING})))
    );

export const fetchListErrorActions = (error: any, setSliceError: any, setSliceIsLoading: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    let errorActions = [addAlert(errorObj)];
    if (setSliceError) {
        errorActions.push(setSliceError(errorObj?.message));
    }
    if (setSliceIsLoading) {
        errorActions.push(setSliceIsLoading(false));
    }
    return errorActions;
};

const countryDictionarySuccessActions: ListSuccessActionsFunction<IModelDictionaryDatum> = (countriesArray: IModelDictionaryDatum[]) => {
    return [changeCountry(countriesArray), changeIsCountryLoading(false)];
};
const cityDictionarySuccessActions: ListSuccessActionsFunction<IModelCity> = (citiesArray: IModelCity[]) => {
    return [changeCity(citiesArray), changeIsCityLoading(false), changeIsCityInitialized(true)];
};

const colorDictionarySuccessActions: ListSuccessActionsFunction<IModelColor> = (colorsArray: IModelColor[]) => {
    return [changeColor(colorsArray), changeIsColorLoading(false)];
};

const settlementPeriodDictionarySuccessActions: ListSuccessActionsFunction<ISettlementPeriodOutput> = (
    settlementPeriod: ISettlementPeriodOutput[]
) => {
    return [setSettlementPeriod(settlementPeriod), changeIsSettlementPeriodLoading(false)];
};

const locationTypeDictionarySuccessActions: ListSuccessActionsFunction<ILocationTypeOutput> = (
    locationTypesArray: ILocationTypeOutput[]
) => {
    return [changeLocationType(locationTypesArray), changeIsLocationTypeLoading(false)];
};

const vehicleDefinitionDictionarySuccessActions: ListSuccessActionsFunction<IVehicleDefinitionOutput> = (
    vehicleDefinitionsArray: IVehicleDefinitionOutput[]
) => {
    let groupedDefinitions: IGroupedByMakeModelTypeYearVehicleDefinitionIds = {};
    vehicleDefinitionsArray.forEach((item) => {
        groupedDefinitions[item.make] = {
            ...groupedDefinitions[item.make],
            [item.model]: {
                ...groupedDefinitions[item.make]?.[item.model],
                [item.vehicleType.name]: {
                    ...groupedDefinitions[item.make]?.[item.model]?.[item.vehicleType.name],
                    [item.productionYear]: item,
                },
            },
        };
    });

    return [changeVehicleDefinitions(vehicleDefinitionsArray, groupedDefinitions), changeIsVehicleDefinitionsLoading(false)];
};

const vehicleTypeDictionarySuccessActions: ListSuccessActionsFunction<IModelVehicleType> = (vehicleTypesArray: IModelVehicleType[]) => {
    return [changeVehicleType(vehicleTypesArray), changeIsVehicleTypeLoading(false)];
};

const jobProblemTypeDictionarySuccessActions: ListSuccessActionsFunction<IModelJobProblemType> = (
    jobProblemsArray: IModelJobProblemType[]
) => {
    return [changeProblemType(jobProblemsArray), setIsLoading(false)];
};

const jobCancellationReasonDictionarySuccessActions: ListSuccessActionsFunction<IJobCancellationReasonOutput> = (
    jobCancellationReasonsArray: IJobCancellationReasonOutput[]
) => {
    return [changeCancellationReason(jobCancellationReasonsArray), setIsCancellationReasonLoading(false)];
};

const fetchCountriesEpic = createDictionaryEpic<IModelCountry>(
    getDictionaryDataAPI,
    countryDictionarySuccessActions,
    fetchListErrorActions,
    fetchCountries().type,
    DictionaryName.COUNTRY
);
const fetchCitiesEpic = createDictionaryEpic<IModelCity>(
    getDictionaryDataAPI,
    cityDictionarySuccessActions,
    fetchListErrorActions,
    fetchCities().type,
    DictionaryName.CITY
);
const fetchColorsEpic = createDictionaryEpic<IModelColor>(
    getDictionaryDataAPI,
    colorDictionarySuccessActions,
    fetchListErrorActions,
    fetchColors().type,
    DictionaryName.COLOR
);
const fetchSettlementPeriodEpic = createDictionaryEpic<ISettlementPeriodOutput>(
    getDictionaryDataAPI,
    settlementPeriodDictionarySuccessActions,
    fetchListErrorActions,
    fetchSettlementPeriod().type,
    DictionaryName.SETTLEMENT_PERIOD
);

const fetchLocationTypesEpic = createDictionaryEpic<ILocationTypeOutput>(
    getDictionaryDataAPI,
    locationTypeDictionarySuccessActions,
    fetchListErrorActions,
    fetchLocationTypes().type,
    DictionaryName.LOCATION_TYPE
);

const fetchVehicleDefinitionsEpic = createDictionaryEpic<IVehicleDefinitionOutput>(
    getDictionaryDataAPI,
    vehicleDefinitionDictionarySuccessActions,
    fetchListErrorActions,
    fetchVehicleTypes().type,
    DictionaryName.VEHICLE_DEFINITION
);
const fetchVehicleTypesEpic = createDictionaryEpic<IModelVehicleType>(
    getDictionaryDataAPI,
    vehicleTypeDictionarySuccessActions,
    fetchListErrorActions,
    fetchVehicleTypes().type,
    DictionaryName.VEHICLE_TYPE
);
const fetchJobProblemTypesEpic = createDictionaryEpic<IModelJobProblemType>(
    getDictionaryDataAPI,
    jobProblemTypeDictionarySuccessActions,
    fetchListErrorActions,
    getProblemTypes().type,
    DictionaryName.JOB_PROBLEM_TYPES
);
const fetchJobCancellationReasonsEpic = createDictionaryEpic<IJobCancellationReasonOutput>(
    getDictionaryDataAPI,
    jobCancellationReasonDictionarySuccessActions,
    fetchListErrorActions,
    fetchCancellationReasons().type,
    DictionaryName.JOB_CANCELLATION_REASONS
);
const dictionaryDataEpic = combineEpics(
    fetchAllDictionaryDataEpic,
    fetchCitiesEpic,
    fetchCountriesEpic,
    fetchColorsEpic,
    fetchSettlementPeriodEpic,
    fetchJobProblemTypesEpic,
    fetchJobCancellationReasonsEpic,
    fetchVehicleDefinitionsEpic,
    fetchLocationTypesEpic,
    fetchVehicleTypesEpic
);

export default dictionaryDataEpic;
