import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, IPurchaserCurrentJobDetailsOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';

export function getJobAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<IPurchaserCurrentJobDetailsOutput>> {
    return palipaliAPI.get(`api/jobs/${id}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
