import type {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import React from 'react';

interface SeoProps {
    title?: string;
}

const Seo: FC<SeoProps> = (props) => {
    const {title} = props,
        {t} = useTranslation();
    const stringTitle = t(title ? title : '');
    return (
        <Helmet>
            <title>{stringTitle}</title>
        </Helmet>
    );
};

Seo.propTypes = {
    title: PropTypes.string,
};

export default Seo;
