import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    CustomTablePagination,
    Eye,
    IVehicleListingOutput,
    Loader,
    LoaderType,
    MemberOperationType,
    NoDataInfo,
    PAGINATION_ITEMS_PER_PAGE,
    Translation,
    Truck02,
    Users,
    calculateItemIndex,
    currentlySelectedModalTypeSelector,
    isAuthTokenExpiredSelector,
    isModalOpenSelector,
    isNotNullOrUndefined,
    openModal,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
    getCurrentVehicleAssignedCouriersListing,
    getFleetPartnerCouriersListing,
    setAssignedCourierListVehicleId,
} from 'src/store/reducers/vehicleAssignedCourierListingSlice';
import {resetVehicleOperationState, setRedirectToVehicleList} from 'src/store/reducers/vehicleOperationSlice';
import {vehicleAssignedCourierListVehicleIdSelector} from 'src/store/selectors/vehicleAssignedCourierListingSelector';
import {
    isVehicleListingLoadingSelector,
    vehicleListingMetadataSelector,
    vehicleListingPaginationSelector,
    vehicleListingSelector,
} from 'src/store/selectors/vehicleListingSelector';
import {isRedirectToVehicleListSelector} from 'src/store/selectors/vehicleOperationSelector';
import {changeVehicleListingPagination, getVehicleListing} from '../../../store/reducers/vehicleListingSlice';
import VehicleStatusBadge from '../shared/VehicleStatusBadge';
import CourierAssignModal from './CourierAssignModal';
import VehicleDetails from './VehicleDetails';
import VehicleFilterWrapper from './VehicleFilterWrapper';
import VehicleJobCategoriesComponent from './VehicleJobCategoriesComponent';
import VehicleSearchInput from './VehicleSearchInput';

const VehicleList: React.FC = () => {
    const vehicles = useSelector(vehicleListingSelector),
        metadata = useSelector(vehicleListingMetadataSelector),
        pagination = useSelector(vehicleListingPaginationSelector),
        isRedirectToVehicleList = useSelector(isRedirectToVehicleListSelector),
        assignedCourierListVehicleId = useSelector(vehicleAssignedCourierListVehicleIdSelector),
        isLoading = useSelector(isVehicleListingLoadingSelector),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        isAuthTokenExpired = useSelector(isAuthTokenExpiredSelector),
        [selectedAssignedCouriersListVehicleId, setSelectedAssignedCouriersListVehicleId] = useState<string | null>(null),
        dispatch = useDispatch(),
        navigate = useNavigate();

    useEffect(() => {
        if (!isAuthTokenExpired) {
            dispatch(getVehicleListing());
        }
    }, [dispatch, isAuthTokenExpired]);

    const viewAssignedCourierList = (vehicleId: string) => {
        dispatch(setAssignedCourierListVehicleId({assignedCourierListVehicleId: vehicleId}));
        dispatch(getFleetPartnerCouriersListing(pagination, 'active', vehicleId));
        dispatch(openModal(MemberOperationType.VEHICLES_ASSIGNED_COURIERS));
    };

    useEffect(() => {
        setSelectedAssignedCouriersListVehicleId(assignedCourierListVehicleId);
        if (assignedCourierListVehicleId && !isAuthTokenExpired) {
            dispatch(getCurrentVehicleAssignedCouriersListing({assignedCourierListVehicleId: assignedCourierListVehicleId}));
        }
    }, [assignedCourierListVehicleId, isAuthTokenExpired]);
    useEffect(() => {
        if (isRedirectToVehicleList && !isAuthTokenExpired) {
            dispatch(setRedirectToVehicleList(false));
            dispatch(resetVehicleOperationState());
        }
    }, [isRedirectToVehicleList, navigate, isAuthTokenExpired]);
    const renderTableBody = () => {
        if (isLoading) return;

        if (!vehicles || vehicles.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6} align={'center'}>
                        <NoDataInfo text={'vehicles.vehicleList.table.noResults'} />
                    </TableCell>
                </TableRow>
            );
        }

        return vehicles.map((vehicle: IVehicleListingOutput, index: number) => {
            const isInsuranceExpiring =
                vehicle.latestInspection &&
                isNotNullOrUndefined(vehicle.latestInspection.upcomingExpiration) &&
                vehicle.latestInspection.upcomingExpiration === true;

            const isInspectionExpiring =
                vehicle.latestInsurance &&
                isNotNullOrUndefined(vehicle.latestInsurance.upcomingExpiration) &&
                vehicle.latestInsurance.upcomingExpiration === true;
            return (
                <TableRow hover key={vehicle.id}>
                    <TableCell>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                            <span className="index-number-cell">
                                <Typography component="span" className="index-number">{`#${calculateItemIndex(
                                    pagination.page,
                                    pagination.itemsPerPage,
                                    index
                                )}`}</Typography>
                            </span>
                            <VehicleDetails vehicle={vehicle} />
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary" variant="body2" component="div">
                            {vehicle.registrationNumber}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <VehicleJobCategoriesComponent packageSizes={vehicle.packageSizes} />
                    </TableCell>
                    <TableCell>
                        {' '}
                        <VehicleStatusBadge status={vehicle.status} expiring={isInsuranceExpiring || isInspectionExpiring} />{' '}
                    </TableCell>
                    <TableCell className="action-cell">
                        <div className="action-cell-container">
                            <div className="btn-action-wrapper">
                                <Button
                                    className="btn btn-action vehicles-vehicle-assigned-couriers-button"
                                    key={`vehicle_button_${vehicle.id}_couriers`}
                                    onClick={() => viewAssignedCourierList(vehicle.id)}
                                    startIcon={<Users size={18} />}>
                                    <Translation
                                        text="vehicles.vehicleList.table.buttons.couriers"
                                        config={{count: vehicle.assignedCourierCount || 0}}
                                    />
                                </Button>
                            </div>
                            <div className="btn-action-wrapper">
                                <Button
                                    key={`vehicle_button_${vehicle.id}_details`}
                                    onClick={() => navigate(`${vehicle.id}`)}
                                    className="btn btn-action vehicles-vehicle-details-button"
                                    startIcon={<Eye size={18} />}>
                                    <Translation text="vehicles.vehicleList.table.buttons.details" />
                                </Button>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
            );
        });
    };
    const renderVehicleTable = () => {
        return (
            <TableContainer>
                <Table className="custom-list">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Box sx={{paddingLeft: '4.3rem'}}>
                                    <Translation text="vehicles.vehicleList.table.columnTitles.vehicle" />
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Translation text="vehicles.vehicleList.table.columnTitles.registrationNo" />
                            </TableCell>
                            <TableCell>
                                <Translation text="vehicles.vehicleList.table.columnTitles.jobCategories" />
                            </TableCell>
                            <TableCell>
                                <Translation text="vehicles.vehicleList.table.columnTitles.status" />
                            </TableCell>
                            <TableCell>
                                <div className="sr-only">
                                    <Translation text="vehicles.vehicleList.table.columnTitles.actions" />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderTableBody()}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="vehicles.title" />
                    </Typography>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    className="vehicles-vehicle-add-button"
                    startIcon={<Truck02 size={18} />}
                    onClick={() => navigate('create')}>
                    <Translation text="vehicles.vehicleList.table.buttons.addVehicle" />
                </Button>
            </div>
            <Card className="custom-card">
                <Grid container className="filters-wrapper">
                    <Grid className="tab-filters">
                        <VehicleFilterWrapper />
                    </Grid>
                    <Grid className="input-filters">
                        <VehicleSearchInput />
                    </Grid>
                </Grid>
                <Divider />
                {renderVehicleTable()}
                <CustomTablePagination
                    metadata={metadata}
                    onChangePage={(event, pageNumber) =>
                        dispatch(changeVehicleListingPagination({page: pageNumber, itemsPerPage: PAGINATION_ITEMS_PER_PAGE}))
                    }
                    page={pagination.page}
                    rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
                />
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </Card>
            {isModalOpen && modalType === MemberOperationType.VEHICLES_ASSIGNED_COURIERS && (
                <CourierAssignModal assignedCourierListVehicleId={selectedAssignedCouriersListVehicleId} />
            )}
        </>
    );
};

export default VehicleList;
