import {Button, Stack, Typography} from '@mui/material';
import {
    Translation,
    HorizontalCard,
    SeverityPill,
    OnboardingMode,
    IStripeOnboardingInput,
    fleetPartnerIdSelector,
    isFleetPartnerStripeOnboardingCompletedSelector,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    accountSettlementCanDoStripeOnboardingSelector,
    isLoadingAccountSettlementStripeSelector,
    stripeOnboardingUrlSelector,
} from '../../../../store/selectors/accountSettlementSelector';
import {fetchCanDoStripeOnboarding, updateStripeOnboarding} from '../../../../store/reducers/accountSettlmentSlice';

const StripeAccountCard: React.FC = () => {
    const dispatch = useDispatch(),
        stripeOnboardingUrl: string | null = useSelector(stripeOnboardingUrlSelector),
        fleetPartnerId: string = useSelector(fleetPartnerIdSelector),
        isStripeOnboardingCompleted: boolean = useSelector(isFleetPartnerStripeOnboardingCompletedSelector),
        isCanDoStripeOnboarding: boolean = useSelector(accountSettlementCanDoStripeOnboardingSelector),
        isLoading: boolean = useSelector(isLoadingAccountSettlementStripeSelector);

    const getStripeOnboardingPayload = (onboardingMode: OnboardingMode): IStripeOnboardingInput => {
        return {
            refreshUrl: `${window.location.origin}/panel/account?tab=settlement`,
            returnUrl: `${window.location.origin}/panel/account?tab=settlement`,
            onboardingMode: onboardingMode,
        };
    };

    useEffect(() => {
        if (isCanDoStripeOnboarding) {
            if (!isStripeOnboardingCompleted) {
                dispatch(fetchCanDoStripeOnboarding(fleetPartnerId));
            }
            dispatch(updateStripeOnboarding(getStripeOnboardingPayload(OnboardingMode.ONBOARDING), fleetPartnerId));
        }
    }, [isStripeOnboardingCompleted, isCanDoStripeOnboarding]);

    const renderBadge = () => {
        if (isStripeOnboardingCompleted || !isCanDoStripeOnboarding) return;

        return (
            <SeverityPill className={`stripe-status-pill warning`}>
                <Translation text={`account.status.toFill`} />
            </SeverityPill>
        );
    };
    const renderHeading = () => {
        return (
            <Stack spacing={1} sx={{alignItems: 'flex-start'}}>
                <Translation text="account.settlements.stripeAccountCard.title" />
                {renderBadge()}
            </Stack>
        );
    };
    const renderContent = () => {
        return (
            <Stack spacing={3} sx={{alignItems: 'flex-start'}}>
                <Typography variant="body1">
                    <Translation
                        text={`account.settlements.stripeAccountCard.${isStripeOnboardingCompleted ? 'active' : 'inactive'}.info`}
                    />
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    className="account-settlements-stripe-account-card-submit-button"
                    disabled={!isCanDoStripeOnboarding || !stripeOnboardingUrl}
                    target={isStripeOnboardingCompleted ? '_blank' : '_blank'}
                    href={stripeOnboardingUrl || '#'}>
                    <Translation text="account.settlements.stripeAccountCard.button" />
                </Button>
                {!isCanDoStripeOnboarding && (
                    <Typography variant="body2" color="error">
                        <Translation text={`account.settlements.stripeAccountCard.inactive.warning`} />
                    </Typography>
                )}
            </Stack>
        );
    };

    return <HorizontalCard heading={renderHeading()} content={renderContent()} showLoader={isLoading} />;
};

export default StripeAccountCard;
