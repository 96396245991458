import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {noTokenCheckPaliPaliAPI} from '../provider/noTokenCheckPaliPaliAPI';
import {IRegisterUserInput} from '../../model/user';
import {isNotNullOrUndefined} from '../../utils/runtimeUtils';

export function createRegisterAPI(payload: IRegisterUserInput, invitationToken?: string | null): Observable<any> {
    const headers: Object = isNotNullOrUndefined(invitationToken) ? {'X-Authentication-Token': invitationToken} : {};

    return noTokenCheckPaliPaliAPI.post(`api/users/register`, payload, new RestQueryParams(), headers);
}
