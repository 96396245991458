import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, IPurchaserCurrentJobDetailsOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ICreateJobInput} from '../../model/jobListing';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const createJobAPI: ApiOperationResponseFunction<IPurchaserCurrentJobDetailsOutput> = (
    authToken: string,
    payload: {createJobInput: ICreateJobInput}
): Observable<IApiSingleResponseBase<IPurchaserCurrentJobDetailsOutput>> => {
    return palipaliAPI.post(`api/jobs`, payload.createJobInput, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
