import React from 'react';

const BookClosedIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="book-closed">
            <line id="Line 19" x1="7" y1="6" x2="12" y2="6" stroke="#111927" strokeWidth="2" strokeLinecap="round" />
            <path
                id="Icon"
                d="M15.333 15.25V13H5.58301C4.34037 13 3.33301 14.0074 3.33301 15.25M6.93301 17.5H12.933C13.7731 17.5 14.1931 17.5 14.514 17.3365C14.7962 17.1927 15.0257 16.9632 15.1695 16.681C15.333 16.3601 15.333 15.9401 15.333 15.1V4.9C15.333 4.05992 15.333 3.63988 15.1695 3.31901C15.0257 3.03677 14.7962 2.8073 14.514 2.66349C14.1931 2.5 13.7731 2.5 12.933 2.5H6.93301C5.67289 2.5 5.04283 2.5 4.56153 2.74524C4.13816 2.96095 3.79396 3.30516 3.57824 3.72852C3.33301 4.20982 3.33301 4.83988 3.33301 6.1V13.9C3.33301 15.1601 3.33301 15.7902 3.57824 16.2715C3.79396 16.6948 4.13816 17.039 4.56153 17.2548C5.04283 17.5 5.67289 17.5 6.93301 17.5Z"
                stroke="#111927"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default BookClosedIcon;
