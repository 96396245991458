import type {SVGProps} from 'react';
import React from 'react';

const ReceiptCheck02 = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path
            d="M6.75 7.875L8.25 9.375L11.625 6M15 15.75V5.85C15 4.58988 15 3.95982 14.7548 3.47852C14.539 3.05516 14.1948 2.71095 13.7715 2.49524C13.2902 2.25 12.6601 2.25 11.4 2.25H6.6C5.33988 2.25 4.70982 2.25 4.22852 2.49524C3.80516 2.71095 3.46095 3.05516 3.24524 3.47852C3 3.95982 3 4.58988 3 5.85V15.75L5.0625 14.25L6.9375 15.75L9 14.25L11.0625 15.75L12.9375 14.25L15 15.75Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ReceiptCheck02;
