import {
    AlertType,
    DeleteSuccessActionsFunction,
    DetailsSuccessActionsFunction,
    ICanOutput,
    IVehicleDetailsOutput,
    addAlert,
    closeModal,
    createActionPayloadEpic,
    createDeleteOperationEpic,
    createFetchDetailsEpic,
    createOperationEpic,
    createVehicleAPI,
    deleteVehicleAPI,
    getCanDeleteVehicleAPI,
    getCanUpdateInspectionAPI,
    getCanUpdateInsuranceAPI,
    getVehicleDetailsAPI,
    handleApiError,
    updateVehicleInspectionAPI,
    updateVehicleInsuranceAPI,
} from 'palipali-panel-common-web';
import {push} from 'react-router-redux';
import {combineEpics} from 'redux-observable';
import {getVehicleListing} from '../reducers/vehicleListingSlice';
import {
    CreateVehicleBaseInformationInput,
    changeCanUpdateInspection,
    changeCanUpdateInsurance,
    checkDeleteVehiclePossibility,
    checkUpdateVehicleInspectionPossibility,
    checkUpdateVehicleInsurancePossibility,
    createVehicle,
    deleteVehicle,
    fetchVehicleDetails,
    resetVehicleOperationState,
    setBasicInformation,
    setCanDeleteCurrentVehicle,
    setCurrentlyDeletedVehicle,
    setCurrentlyEditedVehicle,
    setError,
    setInspection,
    setInsurance,
    setLoading,
    setRedirectToVehicleList,
    updateVehicleInspection,
    updateVehicleInsurance,
} from '../reducers/vehicleOperationSlice';

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), setLoading(false), setError(errorObj.message)];
};

const basicSuccessActions = (successMessage: string) => [setLoading(false), addAlert({message: successMessage, type: AlertType.SUCCESS})];

const vehicleCreateSuccessActions: DetailsSuccessActionsFunction<IVehicleDetailsOutput> = () => {
    return [
        push('/panel/vehicles'),
        ...basicSuccessActions('vehicles.vehicleOperation.alerts.success.createVehicle'),
        setRedirectToVehicleList(true),
    ];
};

const vehicleDeleteSuccessActions: DeleteSuccessActionsFunction = () => {
    return [
        ...basicSuccessActions('vehicles.vehicleList.deleteVehicle.alerts.deleteSuccess'),
        setCurrentlyDeletedVehicle(null),
        resetVehicleOperationState(),
        getVehicleListing(),
        push('/panel/vehicles'),
        closeModal(),
    ];
};
const getBasicInformationPayload = (fetchedVehicle: IVehicleDetailsOutput) => {
    const basicInformationPayload: CreateVehicleBaseInformationInput = {
        registrationNumber: fetchedVehicle.registrationNumber ? fetchedVehicle.registrationNumber : '',
        vehicleDefinitionId: fetchedVehicle.vehicleDefinition?.id ? fetchedVehicle.vehicleDefinition.id : '',
        make: fetchedVehicle.make,
        model: fetchedVehicle.model,
        vehicleTypeId: fetchedVehicle.vehicleType?.name ? fetchedVehicle.vehicleType.name : '',
        productionYear: fetchedVehicle.productionYear ? fetchedVehicle.productionYear.toString() : null,
        colorId: fetchedVehicle.color.name ? fetchedVehicle.color.name : '',
        packageSizes: fetchedVehicle.packageSizes,
    };
    return basicInformationPayload;
};

const vehicleFetchSuccessActions: DetailsSuccessActionsFunction<IVehicleDetailsOutput> = (fetchedVehicle: IVehicleDetailsOutput) => {
    return [
        setLoading(false),
        checkUpdateVehicleInspectionPossibility(fetchedVehicle.id),
        checkUpdateVehicleInsurancePossibility(fetchedVehicle.id),
        setCurrentlyEditedVehicle(fetchedVehicle),
        setBasicInformation(getBasicInformationPayload(fetchedVehicle)),
        setInspection(fetchedVehicle.latestInspection),
        setInsurance(fetchedVehicle.latestInsurance),
    ];
};

const vehicleInspectionUpdateSuccessActions: DetailsSuccessActionsFunction<IVehicleDetailsOutput> = (
    fetchedVehicle: IVehicleDetailsOutput
) => {
    return [
        ...vehicleFetchSuccessActions(fetchedVehicle),
        addAlert({message: 'vehicles.vehicleOperation.alerts.success.updateVehicleInspection', type: AlertType.SUCCESS}),
    ];
};

const vehicleCanUpdateInspectionActions: DetailsSuccessActionsFunction<ICanOutput> = (canBeChanged: ICanOutput) => {
    return [changeCanUpdateInspection(canBeChanged.can), setLoading(false)];
};
const vehicleCanUpdateInsuranceActions: DetailsSuccessActionsFunction<ICanOutput> = (canBeChanged: ICanOutput) => {
    return [changeCanUpdateInsurance(canBeChanged.can), setLoading(false)];
};

const vehicleInsuranceUpdateSuccessActions: DetailsSuccessActionsFunction<IVehicleDetailsOutput> = (
    fetchedVehicle: IVehicleDetailsOutput
) => {
    return [
        ...vehicleFetchSuccessActions(fetchedVehicle),
        addAlert({
            message: 'vehicles.vehicleOperation.alerts.success.updateVehicleInsurance',
            type: AlertType.SUCCESS,
        }),
    ];
};

const vehicleDeletePossibilitySuccessActions: any = (data: ICanOutput) => {
    const actions = [setCanDeleteCurrentVehicle(data.can), setLoading(false)];
    if (data.can === false) {
        actions.push(setCurrentlyDeletedVehicle(null));
    }
    return actions;
};
const createVehicleEpic = createOperationEpic<IVehicleDetailsOutput>(
    createVehicleAPI,
    vehicleCreateSuccessActions,
    errorActions,
    createVehicle().type
);

const updateVehicleInsuranceEpic = createOperationEpic<IVehicleDetailsOutput>(
    updateVehicleInsuranceAPI,
    vehicleInsuranceUpdateSuccessActions,
    errorActions,
    updateVehicleInsurance().type
);
const updateVehicleInspectionEpic = createOperationEpic<IVehicleDetailsOutput>(
    updateVehicleInspectionAPI,
    vehicleInspectionUpdateSuccessActions,
    errorActions,
    updateVehicleInspection().type
);

const fetchVehicleDetailsEpic = createFetchDetailsEpic<IVehicleDetailsOutput>(
    getVehicleDetailsAPI,
    vehicleFetchSuccessActions,
    errorActions,
    fetchVehicleDetails().type
);

const vehicleDeleteEpic = createDeleteOperationEpic<ICanOutput>(
    deleteVehicleAPI,
    vehicleDeleteSuccessActions,
    errorActions,
    deleteVehicle().type
);

const checkVehicleDeletePossibilityEpic = createActionPayloadEpic<ICanOutput>(
    getCanDeleteVehicleAPI,
    vehicleDeletePossibilitySuccessActions,
    errorActions,
    checkDeleteVehiclePossibility().type
);
const checkCanUpdateInspectionEpic = createActionPayloadEpic<ICanOutput>(
    getCanUpdateInspectionAPI,
    vehicleCanUpdateInspectionActions,
    errorActions,
    checkUpdateVehicleInspectionPossibility().type
);
const checkCanUpdateInsuranceEpic = createActionPayloadEpic<ICanOutput>(
    getCanUpdateInsuranceAPI,
    vehicleCanUpdateInsuranceActions,
    errorActions,
    checkUpdateVehicleInsurancePossibility().type
);

const vehicleOperationEpic = combineEpics(
    createVehicleEpic,
    checkVehicleDeletePossibilityEpic,
    vehicleDeleteEpic,
    fetchVehicleDetailsEpic,
    updateVehicleInsuranceEpic,
    updateVehicleInspectionEpic,
    checkCanUpdateInspectionEpic,
    checkCanUpdateInsuranceEpic
);

export default vehicleOperationEpic;
