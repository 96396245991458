import {createSelector} from '@reduxjs/toolkit';
import {ICityState} from '../reducers/citySlice';
import {CommonRootState} from '../reducers';

export const selectCity = (state: CommonRootState): ICityState => {
    return state.city;
};

export const citiesSelector = createSelector([selectCity], (state: ICityState) => state.cities);

export const citiesLoadingSelector = createSelector([selectCity], (state: ICityState) => state.isLoading);

export const citiesInitializedSelector = createSelector([selectCity], (state: ICityState) => state.isInitialized);

export const citiesErrorSelector = createSelector([selectCity], (state: ICityState) => state.error);
