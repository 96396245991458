import {ICurrencyOutput} from '../model/common';
import {Currency} from '../types';

export function getCurrencySymbol(currencyCode: ICurrencyOutput | undefined) {
    switch (currencyCode?.name) {
        case Currency.EURO:
            return '€';
        case Currency.PLN:
            return 'zł';
        default:
            return currencyCode?.name ? currencyCode?.name : '';
    }
}
