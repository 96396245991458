import type {SVGProps} from 'react';
import React from 'react';

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={16} height={16} fill="none" {...props}>
        <path
            d="M12.25 7H1.75M1.75 7L7 12.25M1.75 7L7 1.75"
            stroke="#111927"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ArrowLeftIcon;
