import React, {useState} from 'react';
import parse from 'html-react-parser';
import {IItemDescriptionProps} from '../../types';
import Translation from '../Translation';

type Props = IItemDescriptionProps;

const ItemDescription: React.FC<Props> = ({description}) => {
    const [isDescriptionShortened, setIsDescriptionShortened] = useState(true);

    const renderDescription = () => {
        if (!description) {
            return null;
        }

        if (description.length < 50) {
            return <span>{parse(description)}</span>;
        }

        const shortenedDescription = description.substr(0, 50) + '...';
        if (isDescriptionShortened) {
            return (
                <div>
                    <span>{parse(shortenedDescription)}</span>
                    <button
                        className="btn toggle-details-button"
                        type="button"
                        onClick={() => setIsDescriptionShortened(!isDescriptionShortened)}>
                        <Translation text="buttons.readMore" />
                    </button>
                </div>
            );
        }

        return (
            <>
                <span>{parse(description)}</span>
                <button
                    className="btn toggle-details-button"
                    type="button"
                    onClick={() => setIsDescriptionShortened(!isDescriptionShortened)}>
                    <Translation text="buttons.readLess" />
                </button>
            </>
        );
    };

    return <div>{renderDescription()}</div>;
};

export default ItemDescription;
