import {ApexOptions} from 'apexcharts';
import {useTheme} from '@mui/material/styles';
import moment from 'moment';

export const useChartOptions = (customYFormatter: ((value: number) => string | string[]) | null = null): ApexOptions => {
    const theme = useTheme();

    return {
        chart: {
            stacked: true,
            background: 'transparent',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        colors: [theme.palette.primary.main],
        dataLabels: {
            enabled: false,
        },
        fill: {
            gradient: {
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 100],
            },
            type: 'gradient',
        },
        grid: {
            show: false,
            padding: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
            },
        },
        stroke: {
            curve: 'smooth',
            width: 3,
        },
        theme: {
            mode: theme.palette.mode,
        },
        tooltip: {
            enabled: false,
        },
        xaxis: {
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                rotate: 0,
                show: true,
                formatter: (value: string) => moment(value).format('DD.MM'),
            },
            type: 'datetime',
        },
        yaxis: {
            show: true,
            tickAmount: 2,
            decimalsInFloat: 0,
            labels: {
                offsetX: -16,
                formatter: customYFormatter || undefined,
            },
        },
    };
};
