import React from 'react';
import {CustomIconProps} from '../../types/iconTypes';
const Edit2 = (props: CustomIconProps) => (
    <svg width={props.size || 18} height={props.size || 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="edit-02">
            <path
                id="Icon"
                d="M12.6231 7.86025L10.1398 5.37687M3 15L5.10117 14.7665C5.35789 14.738 5.48624 14.7237 5.60622 14.6849C5.71266 14.6504 5.81395 14.6018 5.90735 14.5402C6.01263 14.4707 6.10395 14.3794 6.28659 14.1968L14.4857 5.99771C15.1714 5.31195 15.1714 4.20009 14.4857 3.51433C13.7999 2.82856 12.6881 2.82856 12.0023 3.51432L3.80321 11.7134C3.62056 11.896 3.52924 11.9874 3.45982 12.0926C3.39823 12.186 3.34955 12.2873 3.31509 12.3938C3.27625 12.5137 3.26199 12.6421 3.23346 12.8988L3 15Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default Edit2;
