import {IPhoneOutput} from '../model/common';
import {parsePhoneNumberFromString} from 'libphonenumber-js/max';

export const formatPhoneNumber = (phone: IPhoneOutput | null): string => {
    if (!phone) return '-';

    let formattedPhoneNumber: string = '';
    if (phone.phone.length) {
        const phoneWithoutBlankSpaces = formatPhoneToPayload(phone.phone);
        for (let i = 0; i < phoneWithoutBlankSpaces.length; i++) {
            formattedPhoneNumber += phoneWithoutBlankSpaces[i];
            if ((i + 1) % 3 === 0 && i < phoneWithoutBlankSpaces.length - 1) formattedPhoneNumber += ' ';
        }
    }

    if (phone.country) return `${phone.country} ${formattedPhoneNumber}`;

    return formattedPhoneNumber;
};

export const formatPhoneToPayload = (phone: string): string => {
    return phone.replace(/\s/g, '');
};

export const isPhoneNumberTypeMobile = (dialingCode: string, phone: string) => {
    const fullPhoneNumber = `${dialingCode}${phone}`;
    const phoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
    return phoneNumber && phoneNumber.isValid() && phoneNumber.getType() === 'MOBILE';
};

export const isPhoneNumberValid = (dialingCode: string, phone: string) => {
    const fullPhoneNumber = `${dialingCode}${phone}`;
    const phoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
    return phoneNumber && phoneNumber.isValid();
};
