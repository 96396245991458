import type {FC} from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import {Box, IconButton, Stack, SvgIcon} from '@mui/material';
import {UserRole} from '../../../types';
import LogoPurchaser from '../../icons/logo/logo-purchaser';
import LogoFleetPartner from '../../icons/logo/logo-fleet-partner';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

interface ITopNavProps {
    onMobileNavOpen?: () => void;
    userRole: UserRole;
}

export const TopNav: FC<ITopNavProps> = (props) => {
    const {onMobileNavOpen, userRole, ...other} = props;

    return (
        <Box
            component="header"
            sx={{
                backdropFilter: 'blur(6px)',
                backgroundColor: (theme) => theme.palette.background.default,
                position: 'sticky',
                left: {
                    lg: `${SIDE_NAV_WIDTH}px`,
                },
                top: 0,
                width: {
                    lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
                },
                zIndex: (theme) => theme.zIndex.appBar,
            }}
            {...other}>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    minHeight: TOP_NAV_HEIGHT,
                    px: 2,
                }}>
                <Box
                    component="span"
                    sx={{
                        height: 48,
                        width: 48,
                        background: '#fff',
                        borderRadius: '8px',
                        borderColor: 'var(--nav-logo-border)',
                        borderWidth: 1,
                        display: 'flex',
                        alinItems: 'center',
                        justifyContent: 'center',
                        padding: '5px',
                        '.logo': {
                            maxWidth: '100%',
                            maxHeight: '100%',
                        },
                    }}>
                    {props.userRole === UserRole.PURCHASER ? <LogoPurchaser /> : <LogoFleetPartner />}
                </Box>

                <Stack alignItems="center" direction="row" spacing={2}>
                    <IconButton onClick={onMobileNavOpen}>
                        <SvgIcon>
                            <Menu01Icon />
                        </SvgIcon>
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    );
};

TopNav.propTypes = {
    onMobileNavOpen: PropTypes.func,
};
