import React, {PropsWithChildren} from 'react';
import {Avatar, Box, Card, Grid, Typography} from '@mui/material';
import {Loader, LoaderType, Translation} from 'palipali-panel-common-web';
import {useSelector} from 'react-redux';
import {reportsLoadingSelector} from '../../../../store/selectors/reportsSelectors';

interface IReportTotalsCardProps {
    title: string;
    icon: React.ReactNode;
}

const ReportTotalsCard: React.FC<PropsWithChildren<IReportTotalsCardProps>> = ({title, children, icon}) => {
    const isLoading = useSelector(reportsLoadingSelector);
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <Box position={'relative'} display={'flex'} py={4} px={3}>
                    <Avatar sx={{width: 48, height: 48, backgroundColor: 'primary.light', mr: 2, color: 'primary.main'}}>{icon}</Avatar>
                    <Box>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            <Translation text={title} />
                        </Typography>
                        {children}
                    </Box>
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Box>
            </Card>
        </Grid>
    );
};

export default ReportTotalsCard;
