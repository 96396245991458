import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './baseSlice';
import {ISystemParameterOutput} from '../../model/systemPatameters';

export interface ISystemParametersState extends IBaseDictionaryState {
    parameters: ISystemParameterOutput[] | null;
}

export interface IChangeSystemParameters {
    parameters: ISystemParameterOutput[] | null;
}

const initialState: ISystemParametersState = {
    parameters: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const systemParametersSlice = createSlice({
    name: 'systemParameters',
    initialState: initialState,
    reducers: {
        changeSystemParameters: {
            reducer: (state: ISystemParametersState, action: PayloadAction<IChangeSystemParameters>) => {
                return {
                    ...state,
                    parameters: action.payload.parameters,
                };
            },
            prepare(parameters: ISystemParameterOutput[] | null) {
                return {
                    payload: {parameters: parameters},
                };
            },
        },
        changeIsSystemParametersLoading: {
            reducer: (state: ISystemParametersState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsSystemParametersInitialized: {
            reducer: (state: ISystemParametersState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setSystemParametersError: {
            reducer: (state: ISystemParametersState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchSystemParameters: (state: ISystemParametersState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {
    changeSystemParameters,
    changeIsSystemParametersLoading,
    changeIsSystemParametersInitialized,
    setSystemParametersError,
    fetchSystemParameters,
} = systemParametersSlice.actions;

export default systemParametersSlice.reducer;
