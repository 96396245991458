import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {ITeamMemberOutput} from '../../model/team';

export function createAcceptTeamMemberInvitationAPI(
    authenticationToken: string,
    confirm: boolean
): Observable<IApiSingleResponseBase<ITeamMemberOutput>> {
    return palipaliAPI.post(`api/team_members/confirm_invitation`, {confirm: confirm}, new RestQueryParams(), {
        'X-Authentication-Token': authenticationToken,
    });
}
