import {Button, Stack, Theme, Typography} from '@mui/material';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {initLogout} from '../../../store/reducers/loginSlice';
import {closeModal} from '../../../store/reducers/modalSlice';
import LogOut02 from '../../../theme/icons/untitled-ui/duocolor/log-out-02';
import CustomModalComponent from '../../CustomModal';
import Translation from '../../Translation';

export interface LogoutProps {
    theme: Theme;
}

const LogoutModal: React.FC<LogoutProps> = ({theme}) => {
    const dispatch = useDispatch(),
        navigate = useNavigate(),
        handleConfirm = () => {
            dispatch(initLogout());
            dispatch(closeModal());
            navigate('/');
        };

    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text="logoutModal.title" />
                </Typography>
            }
            content={
                <>
                    <div className="confirm-modal-icon-container">
                        <LogOut02 color={'error'} />
                    </div>
                    <Stack className="confirm-modal-description-wrapper">
                        <Typography variant="body1" component="p">
                            <Translation text="logoutModal.content" />
                        </Typography>
                    </Stack>
                </>
            }
            footer={
                <Button
                    fullWidth
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                    autoFocus
                    className="logout-modal-button-submit">
                    <Translation text="logoutModal.logout" />
                </Button>
            }
        />
    );
};

export default LogoutModal;
