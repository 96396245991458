import {CustomIconProps} from '../../../types/iconTypes';
import React from 'react';

const Truck02 = (props: CustomIconProps) => (
    <svg width={props.size ? props.size : 16} height={props.size ? props.size : 15} viewBox="0 0 16 15" fill="none" {...props}>
        <path
            d="M10.7 10.025V3.41C10.7 2.65393 10.7 2.27589 10.5529 1.98711C10.4234 1.73309 10.2169 1.52657 9.96289 1.39714C9.67411 1.25 9.29607 1.25 8.54 1.25H3.41C2.65393 1.25 2.27589 1.25 1.98711 1.39714C1.73309 1.52657 1.52657 1.73309 1.39714 1.98711C1.25 2.27589 1.25 2.65393 1.25 3.41V7.865C1.25 8.62107 1.25 8.99911 1.39714 9.28789C1.52657 9.54191 1.73309 9.74843 1.98711 9.87786C2.27589 10.025 2.65393 10.025 3.41 10.025H10.7ZM10.7 10.025H13.67C14.048 10.025 14.2371 10.025 14.3814 9.95143C14.5085 9.88672 14.6117 9.78345 14.6764 9.65644C14.75 9.51205 14.75 9.32304 14.75 8.945V7.09735C14.75 6.93225 14.75 6.8497 14.7314 6.77202C14.7148 6.70314 14.6875 6.6373 14.6505 6.57691C14.6088 6.50879 14.5504 6.45042 14.4337 6.33368L13.0413 4.94132C12.9246 4.82458 12.8662 4.76621 12.7981 4.72447C12.7377 4.68746 12.6719 4.66019 12.603 4.64365C12.5253 4.625 12.4427 4.625 12.2776 4.625H10.7M5.975 11.7125C5.975 12.6445 5.21948 13.4 4.2875 13.4C3.35552 13.4 2.6 12.6445 2.6 11.7125C2.6 10.7805 3.35552 10.025 4.2875 10.025C5.21948 10.025 5.975 10.7805 5.975 11.7125ZM13.4 11.7125C13.4 12.6445 12.6445 13.4 11.7125 13.4C10.7805 13.4 10.025 12.6445 10.025 11.7125C10.025 10.7805 10.7805 10.025 11.7125 10.025C12.6445 10.025 13.4 10.7805 13.4 11.7125Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Truck02;
