import {of} from 'rxjs';
import {Epic, ofType} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {addAlert} from '../reducers/alertSlice';
import {getErrorMessage} from '../../utils/epicUtils';
import {AlertType} from '../../types';
import {getDictionaryDataAPI} from '../../api/dictionary/getDictionaryDataAPI';
import {DictionaryName} from '../../model/dictionaryDatum';
import {authTokenSelector} from '../selectors/authSelectors';
import {translatePathToValue} from '../../utils/TranslationTools';
import {getSystemParametersAPI} from '../../api/systemParameters';
import {changeCountry} from '../reducers/countrySlice';
import {changeCity} from '../reducers/citySlice';
import {changeColor} from '../reducers/colorSlice';
import {changeLocationType} from '../reducers/locationTypeSlice';
import {changeVehicleType} from '../reducers/vehicleTypeSlice';
import {setSettlementPeriod} from '../reducers/settlementPeriodSlice';
import {changeVehicleDefinitions} from '../reducers/vehicleDefinitionsSlice';
import {changeProblemType} from '../reducers/jobProblemTypeSlice';
import {changeCancellationReason} from '../reducers/jobCancellationReasonSlice';
import {changeSystemParameters} from '../reducers/systemParametersSlice';
import {IGroupedByMakeModelTypeYearVehicleDefinitionIds} from '../../model/vehicleDefinition';
import {silentRefreshDictionariesAndParameters} from '../reducers/accountSlice';
const silentRefreshDictionariesAndParametersEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(silentRefreshDictionariesAndParameters.type),
        switchMap(() => {
            const token = authTokenSelector(state$.value);
            if (!token) {
                return of(addAlert({message: translatePathToValue('No token could be found.'), type: AlertType.ERROR}));
            }
            return forkJoin({
                cities: getDictionaryDataAPI(token, DictionaryName.CITY),
                countries: getDictionaryDataAPI(token, DictionaryName.COUNTRY),
                colors: getDictionaryDataAPI(token, DictionaryName.COLOR),
                locationTypes: getDictionaryDataAPI(token, DictionaryName.LOCATION_TYPE),
                vehicleTypes: getDictionaryDataAPI(token, DictionaryName.VEHICLE_TYPE),
                settlementPeriod: getDictionaryDataAPI(token, DictionaryName.SETTLEMENT_PERIOD),
                vehicleDefinitions: getDictionaryDataAPI(token, DictionaryName.VEHICLE_DEFINITION),
                problemTypes: getDictionaryDataAPI(token, DictionaryName.JOB_PROBLEM_TYPES),
                cancelReasons: getDictionaryDataAPI(token, DictionaryName.JOB_CANCELLATION_REASONS),
                parameters: getSystemParametersAPI(token),
            }).pipe(
                switchMap((response) => {
                    let groupedDefinitions: IGroupedByMakeModelTypeYearVehicleDefinitionIds = {};
                    response.vehicleDefinitions['hydra:member'].forEach((item) => {
                        groupedDefinitions[item.make] = {
                            ...groupedDefinitions[item.make],
                            [item.model]: {
                                ...groupedDefinitions[item.make]?.[item.model],
                                [item.vehicleType.name]: {
                                    ...groupedDefinitions[item.make]?.[item.model]?.[item.vehicleType.name],
                                    [item.productionYear]: item,
                                },
                            },
                        };
                    });

                    return of(
                        changeCountry(response.countries['hydra:member']),
                        changeCity(response.cities['hydra:member']),
                        changeColor(response.colors['hydra:member']),
                        changeLocationType(response.locationTypes['hydra:member']),
                        changeVehicleType(response.vehicleTypes['hydra:member']),
                        setSettlementPeriod(response.settlementPeriod['hydra:member']),
                        changeVehicleDefinitions(response.vehicleDefinitions['hydra:member'], groupedDefinitions),
                        changeProblemType(response.problemTypes['hydra:member']),
                        changeCancellationReason(response.cancelReasons['hydra:member']),
                        changeSystemParameters(response.parameters['hydra:member'])
                    );
                })
            );
        }),
        catchError((error: any) => of(addAlert({message: getErrorMessage(error), type: AlertType.ERROR})))
    );

export default silentRefreshDictionariesAndParametersEpic;
