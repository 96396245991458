import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {AlertObject, removeAlert} from '../../store/reducers/alertSlice';
import {alertsSelector, disabledAlertsSelector} from '../../store/selectors/alertSelectors';
import {AlertType, IIconItem, IToastProps} from '../../types';
import {Translation} from '../../index';
import {Toast, ToastBody} from 'reactstrap';
import {IconSize} from '../../constants/miscellaneous';
import WarningTriangleIcon from '../../theme/icons/untitled-ui/duocolor/warning-triangle';
import CheckRoundIcon from '../../theme/icons/untitled-ui/duocolor/check-round';
import Info from '../../theme/icons/feather/info';
import AlertCircle from '../../theme/icons/feather/alert-circle';

type Props = IToastProps;

const ToastComponent: React.FC<Props> = ({alerts, alertsDisabled, removeAlert, alertType, ...props}) => {
    const getAlertIcon = (type: AlertType): IIconItem | null => {
        const {t} = props;
        let alertDetails: IIconItem | null = {
            label: t(`alerts.${type}`),
            icon: null,
        };
        switch (type) {
            case AlertType.SUCCESS:
                alertDetails.icon = <CheckRoundIcon size={IconSize.Toast} />;
                break;
            case AlertType.INFO:
                alertDetails.icon = <Info size={IconSize.Toast} />;
                break;
            case AlertType.WARNING:
                alertDetails.icon = <WarningTriangleIcon size={IconSize.Toast} />;
                break;
            case AlertType.ERROR:
                alertDetails.icon = <AlertCircle size={IconSize.Toast} />;
                break;
            default:
                alertDetails.icon = null;
                break;
        }
        return alertDetails;
    };

    const closeToast = (id: number) => {
        removeAlert(id);
    };

    const removeAlerts = () => {
        alerts.map((alert: AlertObject) => {
            if (alert.displayFor !== 0) {
                setTimeout(() => {
                    removeAlert(alert.id);
                }, alert.displayFor);
            }

            return alert;
        });
    };

    useEffect(() => {
        removeAlerts();
    }, [alerts]);

    if (alertsDisabled || !alerts.length) {
        return null;
    }

    return (
        <React.Fragment>
            {alerts.map((alert: AlertObject) => {
                const message: any = !alert.isTranslated ? alert.message : <Translation text={alert.message} />,
                    isTypeWarning = alertType === AlertType.WARNING || alert.type === AlertType.WARNING,
                    alertDetails = getAlertIcon(alert.type);
                if (alert.message === 'Expired JWT Token') {
                    return null;
                }
                return (
                    <Toast key={`toast_${alert.id}`} className={`${isTypeWarning ? 'warning' : ''} ${alert.type ? alert.type : ''}`}>
                        <ToastBody>
                            {alertDetails.icon && <div className="toast-icon">{alertDetails.icon}</div>}
                            <div className="toast-message-wrapper">
                                {alertDetails.label && <p className="toast-heading">{alertDetails.label}</p>}
                                <span className="toast-message">{message}</span>
                            </div>
                        </ToastBody>
                        <button type="button" className="ms-1 btn-close" onClick={() => closeToast(alert.id)} />
                    </Toast>
                );
            })}
        </React.Fragment>
    );
};

export default withTranslation()(
    connect(
        (state: any) => ({
            alerts: alertsSelector(state),
            alertsDisabled: disabledAlertsSelector(state),
        }),
        {
            removeAlert,
        }
    )(ToastComponent)
);
