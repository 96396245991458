import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiResponseBase, IRawRestQueryParams} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ICourierSettlementListingOutput} from '../../model/settlements';
import {ApiResponseFunction} from '../base/apiConnectionInfrastructure';

export const getCourierSettlementAPI: ApiResponseFunction<ICourierSettlementListingOutput> = (
    authToken: string,
    payload?: any,
    params?: IRawRestQueryParams | []
): Observable<IApiResponseBase<ICourierSettlementListingOutput[]>> => {
    return palipaliAPI.get(`api/courier_settlements`, new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
    });
};
