import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, IPurchaserCurrentJobDetailsOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ICreateJobInput} from '../../model/jobListing';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const replaceJobAPI: ApiOperationResponseFunction<IPurchaserCurrentJobDetailsOutput> = (
    authToken: string,
    payload: {
        replacePayload: {
            jobId: string;
            createJobInput: ICreateJobInput;
        };
    }
): Observable<IApiSingleResponseBase<IPurchaserCurrentJobDetailsOutput>> => {
    return palipaliAPI.put(
        `api/jobs/${payload.replacePayload.jobId}/replace`,
        payload.replacePayload.createJobInput,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
};
