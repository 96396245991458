import PropTypes from 'prop-types';
import type {FC, MouseEvent, ReactNode} from 'react';
import React, {useCallback, useRef, useState} from 'react';
import DropdownContext from './DropdownContext';

interface DropdownProps {
    children: ReactNode[];
    delay?: number;
}

const Dropdown: FC<DropdownProps> = (props) => {
    const {children, delay = 50} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cleanupRef = useRef<any>(null);

    const handleTriggerEnter = useCallback((event: MouseEvent<HTMLElement>) => {
        clearTimeout(cleanupRef.current);
        setAnchorEl(event.currentTarget);
    }, []);

    const handleTriggerLeave = useCallback(() => {
        cleanupRef.current = setTimeout(() => {
            setAnchorEl(null);
        }, delay);
    }, [delay]);

    const handleMenuEnter = useCallback(() => {
        clearTimeout(cleanupRef.current);
    }, []);

    const handleMenuLeave = useCallback(() => {
        cleanupRef.current = setTimeout(() => {
            setAnchorEl(null);
        }, delay);
    }, [delay]);

    const open = !!anchorEl;

    return (
        <DropdownContext.Provider
            value={{
                anchorEl,
                onMenuEnter: handleMenuEnter,
                onMenuLeave: handleMenuLeave,
                onTriggerEnter: handleTriggerEnter,
                onTriggerLeave: handleTriggerLeave,
                open,
            }}>
            {children}
        </DropdownContext.Provider>
    );
};

Dropdown.propTypes = {
    children: PropTypes.arrayOf(PropTypes.any).isRequired,
    delay: PropTypes.number,
};

export default Dropdown;
