import {Loader} from 'palipali-panel-common-web';
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {isVehicleOperationLoadingSelector} from 'src/store/selectors/vehicleOperationSelector';
import VehicleList from './VehicleList';
import VehicleCreate from './VehicleCreate';
import VehicleView from './VehicleView';

interface IVehiclesProps {}

const Vehicles: React.FC<IVehiclesProps> = () => {
    const isLoading = useSelector(isVehicleOperationLoadingSelector);

    return (
        <div className="content-view">
            <Routes>
                <Route path="create" element={<VehicleCreate />} key="vehicle-create" />
                <Route path=":id" element={<VehicleView />} key="vehicle-view" />
                <Route path="/" element={<VehicleList />} key="vehicle-list" />
            </Routes>
            <Loader showLoader={isLoading} />
        </div>
    );
};

export default Vehicles;
