import {createSelector} from '@reduxjs/toolkit';
import {IColorState} from '../reducers/colorSlice';
import {CommonRootState} from '../reducers';

export const selectColor = (state: CommonRootState): IColorState => {
    return state.color;
};

export const colorsSelector = createSelector([selectColor], (state: IColorState) => state.colors);

export const colorsLoadingSelector = createSelector([selectColor], (state: IColorState) => state.isLoading);

export const colorsInitializedSelector = createSelector([selectColor], (state: IColorState) => state.isInitialized);

export const colorsErrorSelector = createSelector([selectColor], (state: IColorState) => state.error);
