import {
    Box,
    Stack,
    Button,
    Checkbox,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import {
    CustomModalComponent,
    CustomTablePagination,
    IAssignCouriersToVehiclesInput,
    IFleetPartnerCourierListingOutput,
    Loader,
    NoDataInfo,
    PAGINATION_ITEMS_PER_PAGE,
    Translation,
    LoaderType,
} from 'palipali-panel-common-web';
import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    addCourierToAddList,
    addCourierToRemoveList,
    assignCouriersToVehicle,
    changeFleetPartnerCouriersListingPagination,
    removeCourierFromToAddList,
    removeCourierFromToRemoveList,
} from 'src/store/reducers/vehicleAssignedCourierListingSlice';
import {
    couriersToAddListSelector,
    couriersToRemoveListSelector,
    currentVehicleAssignedCourierIdListSelector,
    isVehicleAssignedCourierListingLoadingSelector,
    vehicleAssignedCourierListingMetadataSelector,
    vehicleAssignedCourierListingPaginationSelector,
    vehicleAssignedCourierListingSelector,
} from 'src/store/selectors/vehicleAssignedCourierListingSelector';
import CourierAssignDetails from './CourierAssignDetails';

interface IAssignedCourierAssignModalProps {
    assignedCourierListVehicleId: string | null;
}
const CourierAssignModal: FC<IAssignedCourierAssignModalProps> = (props: IAssignedCourierAssignModalProps) => {
    const assignedCouriers = useSelector(vehicleAssignedCourierListingSelector),
        pagination = useSelector(vehicleAssignedCourierListingPaginationSelector),
        metadata = useSelector(vehicleAssignedCourierListingMetadataSelector),
        isLoading = useSelector(isVehicleAssignedCourierListingLoadingSelector),
        initialAssignedCouriersIdList = useSelector(currentVehicleAssignedCourierIdListSelector),
        couriersToAddList = useSelector(couriersToAddListSelector),
        couriersToRemoveList = useSelector(couriersToRemoveListSelector),
        dispatch = useDispatch(),
        theme = useTheme(),
        [assignedCouriersIdArray, setAssignedCouriersIdArray] = useState<string[] | null>(null);

    useEffect(() => {
        let updatedList = [...initialAssignedCouriersIdList];
        couriersToAddList.forEach((courierId: string) => {
            if (!updatedList.includes(courierId)) {
                updatedList.push(courierId);
            }
        });
        updatedList = updatedList.filter((courierId) => !couriersToRemoveList.includes(courierId));
        setAssignedCouriersIdArray(updatedList);
    }, [initialAssignedCouriersIdList, couriersToAddList, couriersToRemoveList]);

    const assignedCourierTableRow = (courier: IFleetPartnerCourierListingOutput) => {
        const isCourierAssigned: boolean = assignedCouriersIdArray ? assignedCouriersIdArray.includes(courier.id) : false,
            isOnToRemoveList = couriersToRemoveList.includes(courier.id),
            isOnToAddList = couriersToAddList.includes(courier.id);

        const handleCheckboxClick = () => {
            if (isCourierAssigned) {
                isOnToAddList ? dispatch(removeCourierFromToAddList(courier.id)) : dispatch(addCourierToRemoveList(courier.id));
            } else {
                isOnToRemoveList ? dispatch(removeCourierFromToRemoveList(courier.id)) : dispatch(addCourierToAddList(courier.id));
            }
        };

        return (
            <TableRow hover key={courier.id}>
                <TableCell>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                        <Checkbox
                            checked={isCourierAssigned}
                            disabled={isCourierAssigned && courier.canUnassign === false}
                            onClick={handleCheckboxClick}
                            className="vehicles-assigned-couriers-modal-assign-checkbox"
                        />
                        <CourierAssignDetails account={courier.account} />
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    const handleConfirm = () => {
        const payload: IAssignCouriersToVehiclesInput = {
            addCourierIds: couriersToAddList,
            removeCourierIds: couriersToRemoveList,
        };
        dispatch(assignCouriersToVehicle({courierArrays: payload, id: props.assignedCourierListVehicleId}));
    };

    const isButtonDisabled = (): boolean => {
        return couriersToAddList && couriersToAddList.length === 0 && couriersToRemoveList && couriersToRemoveList.length === 0;
    };

    const renderCourierList = () => {
        if (!assignedCouriers) return;

        if (!assignedCouriers.length) {
            return (
                <TableRow>
                    <TableCell>
                        <NoDataInfo text={'vehicles.vehicleList.assignedCouriers.modal.table.noResults'} />
                    </TableCell>
                </TableRow>
            );
        }

        return assignedCouriers.map((courier: IFleetPartnerCourierListingOutput) => assignedCourierTableRow(courier));
    };

    return (
        <CustomModalComponent
            theme={theme}
            customClasses="courier-assign-modal"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text="vehicles.vehicleList.assignedCouriers.modal.title" />
                </Typography>
            }
            content={
                <Stack spacing={3} sx={{width: '100%'}} className="assign-modal-list">
                    <TableContainer className={'checkbox-list'}>
                        <Table className="custom-list">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography className="checkbox-list-header">
                                            <Translation text="vehicles.vehicleList.assignedCouriers.modal.table.columnTitles.available" />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderCourierList()}</TableBody>
                        </Table>
                    </TableContainer>

                    <CustomTablePagination
                        metadata={metadata}
                        onChangePage={(event, pageNumber) =>
                            dispatch(
                                changeFleetPartnerCouriersListingPagination({page: pageNumber, itemsPerPage: PAGINATION_ITEMS_PER_PAGE})
                            )
                        }
                        page={pagination.page}
                        rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
                    />
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Stack>
            }
            footer={
                <Button fullWidth onClick={handleConfirm} color="primary" variant="contained" disabled={isButtonDisabled()}>
                    <Translation text="vehicles.vehicleList.assignedCouriers.modal.table.buttons.update" />
                </Button>
            }
        />
    );
};

export default CourierAssignModal;
