import {useTheme} from '@mui/material';
import {FormikForm, IMultiselectOption, HorizontalCard, Translation, fetchVehicleDefinitions} from 'palipali-panel-common-web';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {vehicleBasicInformationSelector} from 'src/store/selectors/vehicleOperationSelector';
import basicInformationFormConfig from './basicInformationFormConfig';

export interface IBaseInformationValues {
    registrationNumber: string | null;
    vehicleDefinitionId: string | null;
    make: string | null;
    model: string | null;
    vehicleType: string | null;
    productionYear: string | null;
    colorId: string | null;
    s: boolean;
    m: boolean;
    l: boolean;
}
export interface IBaseInformationOptions {
    makes: IMultiselectOption[];
    models: IMultiselectOption[];
    vehicleTypes: IMultiselectOption[];
    productionYears: IMultiselectOption[];
    colors: IMultiselectOption[];
}

const VehicleBasicInformationCard: React.FunctionComponent = () => {
    const dispatch = useDispatch(),
        basicInformation = useSelector(vehicleBasicInformationSelector),
        initialValues: IBaseInformationValues = {
            registrationNumber: '',
            vehicleDefinitionId: '',
            make: '',
            model: '',
            vehicleType: '',
            productionYear: '',
            colorId: '',
            s: false,
            m: false,
            l: false,
        },
        [updatedOptions, setUpdatedOptions] = useState<IBaseInformationOptions>({
            makes: [],
            models: [],
            vehicleTypes: [],
            productionYears: [],
            colors: [],
        }),
        [updatedValues, setUpdatedValues] = useState<IBaseInformationValues>(initialValues);

    const theme = useTheme();

    useEffect(() => {
        dispatch(fetchVehicleDefinitions());
    }, [dispatch]);

    useEffect(() => {
        if (basicInformation !== null) {
            const makes: IMultiselectOption[] = basicInformation?.make
                    ? [{label: basicInformation.make, value: basicInformation.make}]
                    : [],
                vehicleTypes: IMultiselectOption[] = basicInformation?.vehicleTypeId
                    ? [{label: basicInformation.vehicleTypeId, value: basicInformation.vehicleTypeId}]
                    : [],
                productionYears: IMultiselectOption[] = basicInformation?.productionYear
                    ? [{label: basicInformation.productionYear, value: basicInformation.productionYear}]
                    : [],
                colors: IMultiselectOption[] = basicInformation?.colorId
                    ? [{label: basicInformation.colorId, value: basicInformation.colorId}]
                    : [],
                models: IMultiselectOption[] = basicInformation?.model
                    ? [{label: basicInformation.model, value: basicInformation.model}]
                    : [];

            setUpdatedOptions({
                makes: makes,
                models: models,
                vehicleTypes: vehicleTypes,
                productionYears: productionYears,
                colors: colors,
            });
            setUpdatedValues({
                registrationNumber: basicInformation?.registrationNumber ? basicInformation.registrationNumber : '',
                vehicleDefinitionId: basicInformation?.vehicleDefinitionId ? basicInformation.vehicleDefinitionId : '',
                make: basicInformation?.make ? basicInformation.make : '',
                model: basicInformation?.model ? basicInformation.model : '',
                vehicleType: basicInformation?.vehicleTypeId ? basicInformation.vehicleTypeId : '',
                productionYear: basicInformation?.productionYear ? basicInformation.productionYear : '',
                colorId: basicInformation?.colorId ? basicInformation.colorId : '',

                s: basicInformation?.packageSizes.s ? basicInformation.packageSizes.s : false,
                m: basicInformation?.packageSizes.m ? basicInformation.packageSizes.m : false,
                l: basicInformation?.packageSizes.l ? basicInformation.packageSizes.l : false,
            });
        }
    }, [basicInformation]);

    return (
        <HorizontalCard
            heading={<Translation text="vehicles.vehicleDetails.basicInformation.title" />}
            content={
                <FormikForm
                    fields={basicInformationFormConfig(updatedOptions)}
                    formId="basic-information-form"
                    initialValues={initialValues}
                    updatedValues={updatedValues}
                    theme={theme}
                    onSubmit={() => null}
                />
            }
        />
    );
};

export default VehicleBasicInformationCard;
