import React from 'react';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {isSameValue} from '../../..';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#334048',
            fontFamily: '"Montserrat", sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
                color: '#b9b9c3',
                fontFamily: '"Montserrat", sans-serif',
                fontWeight: '400',
                lineHeight: '2.4rem',
                fontSize: '14px',
            },
        },
        invalid: {
            color: '#bc5a45',
            iconColor: '#bc5a45',
        },
    },
};

export interface ICardSectionProps {
    name: string;
    onChange: (e: any) => void;
    stripePublicKey?: string;
    stripeAccount?: string | null;
}

class CardSection extends React.Component<ICardSectionProps, any> {
    private stripePromise: any;

    componentDidMount() {
        if (this.props.stripePublicKey) {
            this.stripePromise = loadStripe(
                this.props.stripePublicKey,
                this.props.stripeAccount ? {stripeAccount: this.props.stripeAccount} : null
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (!isSameValue(this.props.stripeAccount, prevProps.stripeAccount)) {
            let config = {stripeAccount: this.props.stripeAccount};
            this.stripePromise = loadStripe(this.props.stripePublicKey, config);
        }

        if (!isSameValue(this.props.stripePublicKey, prevProps.stripePublicKey)) {
            this.stripePromise = loadStripe(
                this.props.stripePublicKey,
                this.props.stripeAccount ? {stripeAccount: this.props.stripeAccount} : null
            );
        }
    }

    render() {
        if (null === this.stripePromise || undefined === this.stripePromise) {
            return null;
        }

        return (
            <Elements stripe={this.stripePromise} options={{locale: 'en'}}>
                <ElementsConsumer>
                    {({stripe, elements}) => (
                        <CardElement
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={(e) => {
                                this.onChange(e, stripe, elements);
                            }}
                        />
                    )}
                </ElementsConsumer>
            </Elements>
        );
    }

    private onChange = (e: any, stripe: any, elements: any) => {
        if (e) {
            const eventData = {
                target: {
                    name: this.props.name,
                    value: Object.assign(e, {stripe, elements}),
                    valid: e.complete,
                },
            };
            this.props.onChange(eventData);
        }
    };
}

export default CardSection;
