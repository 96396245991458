import {PayloadAction} from '@reduxjs/toolkit';
import {IModelJobProblemType} from '../../model/jobProblemType';
import createBaseReducerSlice from './baseSlice';

export interface IJobProblemTypeState {
    readonly jobProblemTypes: IModelJobProblemType[] | null;
    readonly isLoading: boolean;
    readonly error: string | null;
}

export interface IChangeJobProblemTypes {
    jobProblemTypes: IModelJobProblemType[] | null;
}

export interface ISetJobProblemTypeError {
    error: string | null;
}

export interface ISetJobProblemLoading {
    isLoading: boolean;
}

const initialState: IJobProblemTypeState = {
    jobProblemTypes: null,
    isLoading: false,
    error: null,
};

const jobProblemTypeSlice = createBaseReducerSlice({
    name: 'jobProblemType',
    initialState: initialState,
    reducers: {
        changeProblemType: {
            reducer: (state: IJobProblemTypeState, action: PayloadAction<IChangeJobProblemTypes>) => {
                return {
                    ...state,
                    jobProblemTypes: action.payload.jobProblemTypes,
                };
            },
            prepare: (jobProblemTypes: IModelJobProblemType[] | null) => {
                return {
                    payload: {
                        jobProblemTypes: jobProblemTypes,
                    },
                };
            },
        },
        getProblemTypes: (state: IJobProblemTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        setError: {
            reducer: (state: IJobProblemTypeState, action: PayloadAction<ISetJobProblemTypeError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare: (error: string | null) => {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        setIsLoading: {
            reducer: (state: IJobProblemTypeState, action: PayloadAction<ISetJobProblemLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare: (isLoading: boolean) => {
                return {
                    payload: {
                        isLoading: isLoading,
                    },
                };
            },
        },
    },
});

export const {changeProblemType, getProblemTypes, setError, setIsLoading} = jobProblemTypeSlice.actions;

export default jobProblemTypeSlice.reducer;
