import {combineEpics, Epic, ofType} from 'redux-observable';
import {getTeamListing, setError, setLoading, setTeamListing} from '../reducers/teamListingSlice';
import {ITeamListingOutput} from '../../model/team';
import {AlertType} from '../../types';
import {handleApiError} from '../../utils/errorHandlingUtils';
import {addAlert} from '../reducers/alertSlice';
import {catchError, expand, mergeMap, scan, switchMap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {getTeamMineListingAPI} from '../../api/team/getTeamMineListingAPI';
import {authTokenSelector} from '../selectors/authSelectors';
import {PAGINATION_ITEMS_PER_PAGE} from '../../config/paginationDefaults';
import {flattenObj} from '../../utils/objectUtils';

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const getTeamsEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(getTeamListing().type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            let params = {
                page: 1,
                itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
            };

            return getTeamMineListingAPI(authToken, flattenObj(params)).pipe(
                expand((apiRes) => {
                    const totalItems = apiRes['hydra:totalItems'];
                    const nextPage = params.page + 1;
                    if (totalItems > PAGINATION_ITEMS_PER_PAGE * params.page) {
                        params = {
                            ...params,
                            page: nextPage,
                        };
                        return getTeamMineListingAPI(authToken, flattenObj(params));
                    } else if (totalItems > PAGINATION_ITEMS_PER_PAGE * nextPage) {
                        params = {
                            ...params,
                            page: nextPage,
                        };
                        return getTeamMineListingAPI(authToken, flattenObj(params));
                    } else {
                        return EMPTY;
                    }
                }),
                scan((acc: ITeamListingOutput[], teams) => {
                    return [...acc, ...teams['hydra:member']];
                }, []),
                mergeMap((teamsList) => {
                    return of(setTeamListing({teamList: teamsList}));
                }),
                catchError(errorActions)
            );
        })
    );

const teamListingEpic = combineEpics(getTeamsEpic);

export default teamListingEpic;
