import {createBrowserHistory} from 'history';
import {
    CommonRootState,
    IChangePasswordState,
    ISettlementPeriodSliceState,
    accountSlice,
    alertSlice,
    authSlice,
    changePasswordSlice,
    citySlice,
    colorSlice,
    countrySlice,
    initLogout,
    loaderSlice,
    locationTypeSlice,
    loginSlice,
    modalSlice,
    sagaSlice,
    settlementPeriodSlice,
    vehicleDefinitionSlice,
    vehicleTypeSlice,
    systemParametersSlice,
    ISystemParametersState,
    confirmInvitationSlice,
    IConfirmInvitationState,
} from 'palipali-panel-common-web';
import {CombinedState, combineReducers} from 'redux';
import {RouterState, createReduxHistoryContext} from 'redux-first-history';
import vehicleListingSlice, {IVehicleListingState} from './vehicleListingSlice';
import vehicleOperationSlice, {IVehicleOperationState} from './vehicleOperationSlice';
import vehicleAssignedCourierListingSlice, {IVehicleAssignedCourierListingState} from './vehicleAssignedCourierListingSlice';
import courierListingSlice, {ICourierListingState} from './courierListingSlice';
import fleetPartnerActiveCourierListingSlice, {IFleetPartnerActiveCourierListingState} from './fleetPartnerActiveCourierListingSlice';
import settlementListingSlice, {ISettlementListingSliceState} from './settlementListingSlice';
import accountSettlementSlice, {IAccountSettlementState} from './accountSettlmentSlice';
import reportsSlice, {IReportsState} from './reportsSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    saga: sagaSlice,
    country: countrySlice,
    city: citySlice,
    color: colorSlice,
    locationType: locationTypeSlice,
    vehicleType: vehicleTypeSlice,
    router: routerReducer,
    loader: loaderSlice,
    vehicleListing: vehicleListingSlice,
    vehicleOperation: vehicleOperationSlice,
    vehicleAssignedCourierListing: vehicleAssignedCourierListingSlice,
    fleetPartnerActiveCourierListing: fleetPartnerActiveCourierListingSlice,
    vehicleDefinitions: vehicleDefinitionSlice,
    modal: modalSlice,
    courierList: courierListingSlice,
    accountSettlement: accountSettlementSlice,
    settlementPeriod: settlementPeriodSlice,
    settlementListing: settlementListingSlice,
    reports: reportsSlice,
    systemParameters: systemParametersSlice,
    confirmInvitation: confirmInvitationSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = CombinedState<{
    changePassword: IChangePasswordState;
    router: RouterState;
    vehicleListing: IVehicleListingState;
    vehicleOperation: IVehicleOperationState;
    courierList: ICourierListingState;
    vehicleAssignedCourierListing: IVehicleAssignedCourierListingState;
    fleetPartnerActiveCourierListing: IFleetPartnerActiveCourierListingState;
    accountSettlement: IAccountSettlementState;
    settlementPeriod: ISettlementPeriodSliceState;
    settlementListing: ISettlementListingSliceState;
    reports: IReportsState;
    systemParametersSlice: ISystemParametersState;
    confirmInvitation: IConfirmInvitationState;
}> &
    CommonRootState;

export default rootReducer;
