import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase} from '../../types';
import {IDetachPaymentMethodPayload} from '../../model/payment';

export const updateDetachPaymentMethodAPI: ApiOperationResponseFunction<null> = (
    authToken: string,
    payload: IDetachPaymentMethodPayload
): Observable<IApiSingleResponseBase<null>> => {
    return palipaliAPI.put(
        `api/payment_accounts/${payload.paymentAccountId}/stripe/detach_payment_method`,
        {systemPaymentMethodId: payload.systemPaymentMethodId},
        new RestQueryParams(),
        {Authorization: `Bearer ${authToken}`}
    );
};
