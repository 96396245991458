import {Button, CardActions, CardContent, Divider, Grid, useTheme} from '@mui/material';
import {FormikForm, IFileUploadListElement, Translation, ChevronRight, ChevronLeftIcon} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IVehicleInspection, VehicleOperationStep, setCurrentStep, setInspection} from '../../../../store/reducers/vehicleOperationSlice';
import {vehicleInspectionSelector} from '../../../../store/selectors/vehicleOperationSelector';
import {createVehicleInspectionFormConfig} from './createVehicleInspectionFormConfig';

interface IInspectionValues {
    until: string | null;
    inspectionPhotoFront: IFileUploadListElement | undefined;
    inspectionPhotoBack: IFileUploadListElement | undefined;
}

const Inspection: React.FunctionComponent = () => {
    const dispatch = useDispatch(),
        vehicleInspection = useSelector(vehicleInspectionSelector),
        [submitAllowed, setSubmitAllowed] = React.useState<boolean>(false),
        initialValues: IInspectionValues = {
            until: vehicleInspection?.until || '',
            inspectionPhotoFront: vehicleInspection?.files[0] || undefined,
            inspectionPhotoBack: vehicleInspection?.files[1] || undefined,
        },
        theme = useTheme();

    const onSubmitAction = (values: IInspectionValues) => {
        const inspectionPhotoFront = values.inspectionPhotoFront,
            inspectionPhotoBack = values.inspectionPhotoBack;
        if (inspectionPhotoFront && inspectionPhotoBack) {
            const inspectionPayload: IVehicleInspection = {
                until: values.until ? new Date(values.until).toISOString() : new Date().toISOString(),
                files: [inspectionPhotoFront, inspectionPhotoBack],
            };
            dispatch(setInspection(inspectionPayload));
            dispatch(setCurrentStep(VehicleOperationStep.INSURANCE));
        }
        return;
    };

    return (
        <React.Fragment key={'inspection-wrapper-react-fragment'}>
            <CardContent>
                <Grid item xs={12} md={7}>
                    <FormikForm
                        fields={createVehicleInspectionFormConfig()}
                        formId="inspection-form"
                        initialValues={initialValues}
                        theme={theme}
                        submitAllowed={setSubmitAllowed}
                        shouldApplyCustomValidation={true}
                        onSubmit={onSubmitAction}
                    />
                </Grid>
            </CardContent>
            <Divider />
            <CardActions className="card-actions-button-wrapper">
                <Button
                    size="medium"
                    className="btn btn-action vehicle-nav-prev-button"
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => dispatch(setCurrentStep(VehicleOperationStep.BASIC_INFORMATION))}>
                    <Translation text="vehicles.vehicleOperation.buttons.back" />
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    size="medium"
                    type="submit"
                    disabled={!submitAllowed}
                    form="inspection-form"
                    className="vehicle-nav-next-button"
                    endIcon={<ChevronRight />}>
                    <Translation text="vehicles.vehicleOperation.buttons.next" />
                </Button>
            </CardActions>
        </React.Fragment>
    );
};

export default Inspection;
