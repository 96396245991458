import React from 'react';
import {Translation} from 'palipali-panel-common-web';
import {Badge} from '@mui/material';

interface IStatusBadgeProps {
    readonly status: string;
}

const StatusBadge: React.FC<IStatusBadgeProps> = ({status}) => {
    const badge = (status: string) => {
        switch (status) {
            case 'online':
                return (
                    <Badge className="completed">
                        <Translation text={`couriers.courierStatus.online`} />
                    </Badge>
                );
            case 'active':
                return (
                    <Badge className="completed">
                        <Translation text={`couriers.courierStatus.active`} />
                    </Badge>
                );
            case 'invited':
                return (
                    <Badge className="reported">
                        <Translation text={`couriers.courierStatus.invited`} />
                    </Badge>
                );
            case 'inactive':
                return (
                    <Badge className="canceled">
                        <Translation text={`couriers.courierStatus.inactive`} />
                    </Badge>
                );
            default:
                return null;
        }
    };
    return <div className="package-status-badge">{badge(status)}</div>;
};

export default StatusBadge;
