import React from 'react';
import {IFormControlConfig, IFormControlsGroupProps, IFormControlsGroupState, IFormGroupConfig} from '../../types';
import BaseGroupedFormControl from '../BaseGroupedFormControl';
import FormControl from '../FormControl';
import styles from './styles.module.scss';
import FormErrorMessages from '../FormErrorMessages';
import {appendFormPath} from '../../utils/formUtils';
import Translation from '../Translation';

class FormControlsGroup extends BaseGroupedFormControl<IFormControlsGroupProps, IFormControlsGroupState> {
    render() {
        if (!this.props.config) {
            return null;
        }

        return (
            <div className={`${styles.formGroupWrapper} ${this.props.config.class} ${this.hasError ? 'group-with-error' : ''}`}>
                {this.hasError && (
                    <div className={`${styles.errorBlock} error-block`}>
                        <FormErrorMessages errorMessages={this.state.errorMessages} />
                    </div>
                )}
                {this.props.config.groupLabel ? (
                    <p className={`${styles.groupLabel} group-label`}>
                        <Translation text={this.props.config.groupLabel} />
                    </p>
                ) : null}
                {this.renderControls()}
            </div>
        );
    }

    // DO NOT move this method to BaseGroupedFormControl, it will cause the app to break
    // probably because there will be a circular reference between FormControlsGroup and BaseGroupedFormControl
    protected renderControls() {
        const path = appendFormPath(this.props.controlPath, String(this.props.controlName));

        return this.mapControls(this.props.config.controls, (control: IFormGroupConfig | IFormControlConfig, controlName: string) => {
            if (control.controlType === 'group') {
                return (
                    <FormControlsGroup
                        config={control}
                        controlName={control.key}
                        formControlName={this.props.formControlName}
                        value={this.dataAccessor(this.state.value, control.key)}
                        handleChange={this.props.handleChange}
                        handleMultiselectChange={this.props.handleMultiselectChange}
                        onValidationStateChange={this.onChildValidationStateChanged}
                        onTouchedStateChange={this.onTouchedStateChanged}
                        onValueStateChange={this.onValueStateChanged}
                        onButtonClicked={this.props.onButtonClicked}
                        submitTouched={this.props.submitTouched}
                        formId={this.props.formId}
                        key={control.key}
                        controlPath={path}
                    />
                );
            }

            return (
                <FormControl
                    config={control as IFormControlConfig}
                    controlName={controlName}
                    formControlName={this.props.formControlName}
                    handleChange={this.props.handleChange}
                    handleMultiselectChange={this.props.handleMultiselectChange}
                    value={this.dataAccessor(this.props.value, controlName)}
                    onValidationStateChange={this.onChildValidationStateChanged}
                    onTouchedStateChange={this.onTouchedStateChanged}
                    onValueStateChange={this.onValueStateChanged}
                    onButtonClicked={this.props.onButtonClicked}
                    submitTouched={this.props.submitTouched}
                    formId={this.props.formId}
                    key={controlName}
                    controlPath={path}
                />
            );
        });
    }
}

export default FormControlsGroup;
