export enum SystemParameterCode {
    WAIT_UNTIL_CLOSING = 'wait_until_closing',
    OPINION_AVAILABLE_FOR = 'opinion_available_for',
    JOB_PROCESSING_MAX_LIFETIME = 'job_processing_max_lifetime',
    RESET_PASSWORD_MAX_LIFETIME = 'reset_password_max_lifetime',
    COURIER_JOB_CANCEL_THRESHOLD = 'courier_job_cancel_threshold',
    PURCHASER_JOB_CANCEL_THRESHOLD = 'purchaser_job_cancel_threshold',
    GLOBAL_SUBURBAN_ZONE_LIMIT = 'global_suburban_zone_limit',
    MEDIA_OBJECT_ORPHAN_LIFETIME = 'media_object_orphan_lifetime',
    SETTLEMENT_PERIOD_PROCESSING_ADVANCE = 'settlement_period_processing_advance',
    SYSTEM_INVOICE_DATA = 'system_invoice_data',
    DOCUMENT_UPCOMING_EXPIRATION_THRESHOLD = 'document_upcoming_expiration_threshold',
    PICKUP_ALLOWED_RADIUS = 'pickup_allowed_radius',
    LOCATION_GATHER_NO_JOB = 'location_gather_no_job',
    LOCATION_GATHER_WITH_JOB = 'location_gather_with_job',
    TOC_LINK = 'toc_link',
    COURIER_JOB_DELIVERY_TIME_LIMIT = 'courier_job_delivery_time_limit',
    JOB_FUNDS_STANDARD_LOCK_TIME = 'job_funds_standard_lock_time',
    JOB_FUNDS_EXTENDED_LOCK_TIME = 'job_funds_extended_lock_time',
    JOB_PRICING_INSURANCE_MAXIMUM_VALUE = 'job_pricing_insurance_maximum_value',
    JOB_PRICING_INSURANCE_MINIMUM_VALUE = 'job_pricing_insurance_minimum_value',
    PACKAGE_S_MAX_SIZE = 'package_s_max_size',
    PACKAGE_S_MAX_SIZE_SUM = 'package_s_max_size_sum',
    PACKAGE_S_MAX_WEIGHT = 'package_s_max_weight',
    PACKAGE_M_MAX_SIZE = 'package_m_max_size',
    PACKAGE_M_MAX_SIZE_SUM = 'package_m_max_size_sum',
    PACKAGE_M_MAX_WEIGHT = 'package_m_max_weight',
    PACKAGE_L_MAX_SIZE = 'package_l_max_size',
    PACKAGE_L_MAX_SIZE_SUM = 'package_l_max_size_sum',
    PACKAGE_L_MAX_WEIGHT = 'package_l_max_weight',
    PRIVACY_LINK = 'privacy_link',
    PURCHASER_TOC_LINK = 'purchaser_toc_link',
    COURIER_TOC_LINK = 'courier_toc_link',
    CITIES_LINK = 'cities_link',
    STRIPE_PUBLISHABLE_KEY = 'stripe_publishable_key',
    REFRESH_DICTIONARY_INTERVAL = 'refresh_dictionary_interval',
    JOB_ESTIMATION_VALID_FOR = 'job_estimation_valid_for',
}

export interface ISystemParameterOutput {
    readonly code: SystemParameterCode;
    readonly id: string;
    readonly value: string;
}
