import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';

export function deleteAccountAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<undefined>> {
    return palipaliAPI.delete(`api/accounts/${id}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
