import type {SVGProps} from 'react';
import React from 'react';

const CalendarIconSmall = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" fill="none" {...props}>
        <g id="calendar">
            <path
                id="Icon"
                d="M14.4 7.65H2.25M11.025 2.25V4.95M5.625 2.25V4.95M5.49 15.75H11.16C12.2941 15.75 12.8612 15.75 13.2943 15.5293C13.6754 15.3351 13.9851 15.0254 14.1793 14.6443C14.4 14.2112 14.4 13.6441 14.4 12.51V6.84C14.4 5.70589 14.4 5.13884 14.1793 4.70567C13.9851 4.32464 13.6754 4.01486 13.2943 3.82071C12.8612 3.6 12.2941 3.6 11.16 3.6H5.49C4.35589 3.6 3.78884 3.6 3.35567 3.82071C2.97464 4.01486 2.66486 4.32464 2.47071 4.70567C2.25 5.13884 2.25 5.70589 2.25 6.84V12.51C2.25 13.6441 2.25 14.2112 2.47071 14.6443C2.66486 15.0254 2.97464 15.3351 3.35567 15.5293C3.78884 15.75 4.35589 15.75 5.49 15.75Z"
                stroke="#6C737F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default CalendarIconSmall;
