import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserRole} from '../../types';

export interface ILoginState {
    username: string | null;
    error: string | null;
    isLoading: boolean;
}

export interface IGetLoginCredentials {
    username: string;
    password: string;
    role: UserRole;
}

export interface ISetLoginError {
    readonly error: string;
}
export interface ISetLoginWithToken {
    readonly authToken: string;
    readonly userRole: UserRole;
    readonly refreshToken?: string;
}

export interface ISetLoadingState {
    readonly loading: boolean;
}

const initialState: ILoginState = {
    username: null,
    error: null,
    isLoading: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        getLoginCredentials: {
            reducer: (state: ILoginState) => {
                return {
                    username: '',
                    error: state.error,
                    isLoading: true,
                };
            },
            prepare(username: string, password: string, role: UserRole) {
                return {
                    payload: {
                        username,
                        password,
                        role,
                    },
                };
            },
        },
        loginWithToken: {
            reducer: (state: ILoginState) => {
                return {
                    ...state,
                };
            },
            prepare(authToken: string, userRole: UserRole, refreshToken?: string) {
                return {
                    payload: {
                        authToken: authToken,
                        userRole: userRole,
                        refreshToken: refreshToken,
                    },
                };
            },
        },
        setLoginFailure: {
            reducer: (state: ILoginState, action: PayloadAction<ISetLoginError>) => {
                return {
                    username: state.username,
                    error: action.payload.error,
                    isLoading: false,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        setLoadingState: {
            reducer: (state: ILoginState, action: PayloadAction<ISetLoadingState>) => {
                return {
                    ...state,
                    isLoading: action.payload.loading,
                };
            },
            prepare(loading: boolean) {
                return {
                    payload: {
                        loading: loading,
                    },
                };
            },
        },
        initLogout: {
            reducer: () => {
                return {
                    username: null,
                    error: null,
                    isLoading: false,
                };
            },
            prepare() {
                return {
                    payload: {},
                };
            },
        },
    },
});

export const {getLoginCredentials, loginWithToken, setLoginFailure, setLoadingState, initLogout} = loginSlice.actions;

export default loginSlice.reducer;
