import {RootState} from '../reducers';
import {createSelector} from '@reduxjs/toolkit';
import {IReportsState} from '../reducers/reportsSlice';
import {IReportsFilters, citiesLoadingSelector} from 'palipali-panel-common-web';
import {isVehicleListingLoadingSelector} from './vehicleListingSelector';
import {isVehicleAssignedCourierListingLoadingSelector} from './vehicleAssignedCourierListingSelector';

const generateDateRange = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateRange: string[] = [];
    const startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate()));
    const endUTC = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()));

    for (let date = startUTC; date <= endUTC; date.setUTCDate(date.getUTCDate() + 1)) {
        dateRange.push(date.toISOString().split('T')[0]);
    }

    return dateRange;
};

export const selectReportsSlice = (state: RootState) => {
    return state.reports;
};
export const reportsFiltersLoadingSelector = createSelector(
    [selectReportsSlice, isVehicleListingLoadingSelector, isVehicleAssignedCourierListingLoadingSelector, citiesLoadingSelector],
    (state: IReportsState, areVehiclesLoading, areCouriersLoading, areCitiesLoading) => {
        return state.isLoading || areVehiclesLoading || areCouriersLoading || areCitiesLoading;
    }
);
export const reportsListingSelector = createSelector([selectReportsSlice], (state: IReportsState) => state.reportsListing);
export const reportsLoadingSelector = createSelector([selectReportsSlice], (state: IReportsState) => state.isLoading);
export const reportsErrorSelector = createSelector([selectReportsSlice], (state: IReportsState) => state.error);
export const reportsInitializedSelector = createSelector([selectReportsSlice], (state: IReportsState) => state.isInitialized);
export const reportsFiltersSelector = createSelector([selectReportsSlice], (state: IReportsState) => {
    const serverReportFilters = state.reportsFilters;
    return {
        fleetPartnerVehicle: serverReportFilters['fleetPartnerVehicle[]'],
        courierId: serverReportFilters['courier.id[]'],
        cityId: serverReportFilters['city.id[]'],
        finishedAt: serverReportFilters['finishedAt[before]'],
        startedAt: serverReportFilters['startedAt[after]'],
        courierVehicle: serverReportFilters.courierVehicle,
    };
});

export const reportsFiltersValuesSelector = createSelector(
    [reportsFiltersSelector, (state, fieldName: keyof IReportsFilters) => fieldName],
    (filters, fieldName) => {
        return filters[fieldName];
    }
);

export const serverReportsFiltersSelector = createSelector([selectReportsSlice], (state: IReportsState) => state.reportsFilters);
export const reportsStatisticsSelector = createSelector([selectReportsSlice], (state: IReportsState) => state.statistics);
export const reportsStatisticsAsGraphPointsSelector = createSelector([selectReportsSlice], (state: IReportsState) => {
    const dateRange = generateDateRange(state.reportsFilters['startedAt[after]'], state.reportsFilters['finishedAt[before]']);
    const jobsCompletedDefaultData = [{data: dateRange.map((date) => ({x: date, y: 0}))}],
        courierNumberDefaultData = [{data: dateRange.map((date) => ({x: date, y: 0}))}],
        distanceDefaultData = [{data: dateRange.map((date) => ({x: date, y: 0}))}],
        incomeDefaultData = [{data: dateRange.map((date) => ({x: date, y: 0}))}];

    const series: {
        jobsCompleted: ApexAxisChartSeries;
        courierNumber: ApexAxisChartSeries;
        distance: ApexAxisChartSeries;
        income: ApexAxisChartSeries;
    } = {
        jobsCompleted: jobsCompletedDefaultData,
        courierNumber: courierNumberDefaultData,
        distance: distanceDefaultData,
        income: incomeDefaultData,
    };
    const updateData = (seriesData: any, statisticValue: number, date: string) => {
        if (!Array.isArray(seriesData) || (seriesData.length > 0 && typeof seriesData[0] !== 'object')) {
            return;
        }

        const dataIndex = seriesData.findIndex((data: {x: string; y: number}) => data.x === date);
        if (dataIndex !== -1) {
            seriesData[dataIndex].y = statisticValue;
        } else {
            seriesData.push({x: date, y: statisticValue});
        }
    };

    state.statistics.forEach((statistic) => {
        updateData(series.jobsCompleted[0].data, statistic.jobsCompleted, statistic.date);
        updateData(series.courierNumber[0].data, statistic.courierNumber, statistic.date);
        updateData(series.distance[0].data, statistic.distance, statistic.date);
        updateData(series.income[0].data, Number(statistic.value.amount), statistic.date);
    });

    return series;
});
export const reportsMetadataSelector = createSelector([selectReportsSlice], (state: IReportsState) => state.reportsMetadata);
export const reportsPageSelector = createSelector([selectReportsSlice], (state: IReportsState) => state.page);
export const reportsTotalNumberOfResultsSelector = createSelector(
    [selectReportsSlice],
    (state: IReportsState) => state.totalNumberOfResults
);
