import {IGoogleAddressParts, IGooglePlaceDetail} from '../model/location';

export const getAddressParts = (address: IGooglePlaceDetail | null): IGoogleAddressParts => {
    if (address) {
        const zip = address.address_components.find((component) => component.types.includes('postal_code'))?.long_name;
        const street = address.address_components.find((component) => component.types.includes('route'))?.long_name;
        const houseNumber =
            address.address_components.find((component) => component.types.includes('street_number'))?.short_name ||
            address.address_components.find((component) => component.types.includes('premise'))?.short_name;
        const city = address.address_components.find((component) => component.types.includes('locality'))?.long_name;
        const country = address.address_components.find((component) => component.types.includes('country'))?.long_name;

        return {
            zip: zip || '',
            street: street || null,
            houseNumber: houseNumber || '',
            city: city || '',
            country: country || '',
        };
    }
    return {
        zip: '',
        street: '',
        houseNumber: '',
        city: '',
        country: '',
    };
};
export default getAddressParts;
