import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase, IPlacedJobOutput, IPurchaserCurrentJobDetailsOutput} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const placeJobAPI: ApiOperationResponseFunction<IPlacedJobOutput> = (
    authToken: string,
    payload: {jobId: string}
): Observable<IApiSingleResponseBase<IPlacedJobOutput>> => {
    return palipaliAPI.put(`api/jobs/${payload.jobId}/place`, {}, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
