import {
    Box,
    Button,
    Card,
    CardHeader,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import {useFormik} from 'formik';
import {
    CurrentIcon,
    CustomTablePagination,
    DateRange,
    FormikForm,
    ICourierSettlementListingOutput,
    IncomeIcon,
    Loader,
    LoaderType,
    NoDataInfo,
    PAGINATION_ITEMS_PER_PAGE,
    ProcessingIcon,
    ReceiptCheck02,
    TimeComponent,
    Translation,
    authTokenSelector,
    calculateItemIndex,
    downloadInvoice,
    isAuthTokenExpiredSelector,
    serverFormattedDateWithCustomTime,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    changeSettlementFilters,
    changeSettlementsListingPagination,
    fetchCurrentSettlement,
    fetchProcessingSettlement,
    getSettlementListing,
} from '../../store/reducers/settlementListingSlice';
import {
    currentSettlementSelector,
    isSettlementListingLoadingSelector,
    processingSettlementSelector,
    settlementListSelector,
    settlementListingMetadataSelector,
    settlementListingPaginationSelector,
    settlementListingFiltersSelector,
} from '../../store/selectors/settlementListingSelectors';
import JobPrice from './JobPrice';
import settlementsFilterFormConfig from './settlementsFilterFormConfig';

interface ISettlementsProps {}
interface TotalIncome {
    amount: string;
    currency: {
        name: string;
    };
}
const Settlements: React.FC<ISettlementsProps> = () => {
    const [totalIncomeValue, setIncome] = useState<TotalIncome>({
        amount: '',
        currency: {
            name: '',
        },
    });
    const dispatch = useDispatch(),
        theme = useTheme(),
        authToken: string = useSelector(authTokenSelector),
        settlements: ICourierSettlementListingOutput[] | null = useSelector(settlementListSelector),
        current: ICourierSettlementListingOutput | null = useSelector(currentSettlementSelector),
        processing: ICourierSettlementListingOutput | null = useSelector(processingSettlementSelector),
        pagination = useSelector(settlementListingPaginationSelector),
        metadata = useSelector(settlementListingMetadataSelector),
        settlementsFiltersConfig = useSelector(settlementListingFiltersSelector),
        isAuthTokenExpired = useSelector(isAuthTokenExpiredSelector),
        isLoading = useSelector(isSettlementListingLoadingSelector);

    useEffect(() => {
        if (metadata && metadata.totalIncome) {
            setIncome({
                amount: metadata.totalIncome.amount.toString(),
                currency: {name: metadata.totalIncome.currency.name},
            });
        } else {
            setIncome({amount: '0', currency: {name: ''}});
        }
    }, [metadata]);

    const formik = useFormik({
        initialValues: settlementsFiltersConfig,
        onSubmit: (values) => {
            const payload = {
                'date[after]': values?.['date[after]'] ? values['date[after]'] : settlementsFiltersConfig?.['date[after]'],
                'date[before]': values?.['date[before]'] ? values['date[before]'] : settlementsFiltersConfig?.['date[before]'],
            };
            dispatch(changeSettlementFilters(payload));
        },
    });

    useEffect(() => {
        if (!isAuthTokenExpired) {
            dispatch(changeSettlementFilters(settlementsFiltersConfig));
            dispatch(fetchCurrentSettlement());
            dispatch(fetchProcessingSettlement());
        }
    }, [isAuthTokenExpired]);

    useEffect(() => {
        if (!isAuthTokenExpired) {
            dispatch(getSettlementListing());
        }
    }, [settlementsFiltersConfig, isAuthTokenExpired]);

    const renderTableBody = () => {
        if (isLoading) return;

        if (!settlements || settlements.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6} align={'center'}>
                        <NoDataInfo text={'settlements.noSettlements'} />
                    </TableCell>
                </TableRow>
            );
        }

        return settlements.map((item: ICourierSettlementListingOutput, index: number) => (
            <TableRow key={item.id}>
                <TableCell>
                    <Box sx={{display: 'flex', gap: '1.6rem'}}>
                        <span className="index-number-cell">
                            <Typography component="span" className="index-number">{`#${calculateItemIndex(
                                pagination.page,
                                pagination.itemsPerPage,
                                index
                            )}`}</Typography>
                        </span>
                        <Box>
                            <TimeComponent application="fleetPartner" date={item.from} /> {'-'}{' '}
                            <TimeComponent application="fleetPartner" date={item.to} />
                        </Box>
                    </Box>
                </TableCell>
                <TableCell className="action-cell">
                    {item.invoiceNumber && (
                        <div className="cell">
                            <Typography className="invoice">
                                <Translation text="settlements.downloadInvoice" />
                            </Typography>
                            <Button
                                className="btn btn-action"
                                disabled={!item.invoiceNumber || item.invoiceNumber === null}
                                onClick={() => {
                                    downloadInvoice(
                                        item?.invoiceId,
                                        process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '',
                                        authToken,
                                        dispatch,
                                        item?.invoiceNumber
                                    );
                                }}
                                startIcon={<ReceiptCheck02 />}>
                                {item.invoiceNumber}
                            </Button>
                        </div>
                    )}
                </TableCell>
                <TableCell>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <Typography className="income">
                            <Translation text="settlements.income" />
                        </Typography>
                        <JobPrice price={item.value} />
                    </Box>
                </TableCell>
            </TableRow>
        ));
    };

    const renderTablePagination = () => {
        if (settlements && settlements.length === 0) return;

        return (
            <CustomTablePagination
                metadata={metadata}
                onChangePage={(event, pageNumber) => {
                    dispatch(changeSettlementsListingPagination({page: pageNumber, itemsPerPage: PAGINATION_ITEMS_PER_PAGE}));
                }}
                page={pagination.page}
                rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
            />
        );
    };

    const renderSettlementBoxes = () => {
        return (
            <Grid container spacing={3.2} className="settlements-boxes">
                <Grid item xs={12} md={4}>
                    <Card className="box">
                        <CurrentIcon />
                        <div className="description">
                            <Typography className="description-title">
                                <Translation text="settlements.summary.current" />
                            </Typography>
                            <Typography className="basic-income" color="text.primary">
                                {current ? <JobPrice price={current.value} /> : <span>{'-'}</span>}
                            </Typography>
                            {current ? (
                                <>
                                    <TimeComponent className="description-title" application="fleetPartner" date={current?.from} /> -{' '}
                                    <TimeComponent className="description-title" application="fleetPartner" date={current?.to} />
                                </>
                            ) : null}
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className="box">
                        <ProcessingIcon />
                        <div className="description">
                            <Typography className="description-title">
                                <Translation text="settlements.summary.inSettlement" />
                            </Typography>
                            <Typography className="basic-income" color="text.primary" variant="body1">
                                {processing ? <JobPrice price={processing.value} /> : <span>{'-'}</span>}
                            </Typography>
                            {processing ? (
                                <>
                                    <TimeComponent className="description-title" application="fleetPartner" date={processing?.from} /> -{' '}
                                    <TimeComponent className="description-title" application="fleetPartner" date={processing?.to} />
                                </>
                            ) : null}
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className="box">
                        <IncomeIcon />
                        <div className="description">
                            <Typography className="description-title">
                                <Translation text="settlements.summary.revenueSettlement" />
                            </Typography>
                            <Typography className="total-income" color="text.primary">
                                <JobPrice price={totalIncomeValue} />
                            </Typography>
                            <Typography className="description-title">
                                {settlements && settlements?.length !== 0 ? (
                                    <Translation
                                        text="settlements.summary.invoiceCount"
                                        config={{invoiceCount: metadata?.totalItems ? metadata.totalItems : settlements.length}}
                                    />
                                ) : null}
                            </Typography>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        );
    };

    const renderCardHeader = () => {
        return (
            <CardHeader
                className="settlements-card-header"
                title={
                    <Typography variant="h6" component="h3" color="text.secondary">
                        <Translation text="settlements.settled" config={{settlementsCount: settlements?.length || 0}} />
                    </Typography>
                }
                action={
                    <FormikForm
                        fields={settlementsFilterFormConfig({
                            startDate: settlementsFiltersConfig['date[after]']
                                ? new Date(settlementsFiltersConfig['date[after]'])
                                : undefined,
                            endDate: settlementsFiltersConfig['date[before]']
                                ? new Date(settlementsFiltersConfig['date[before]'])
                                : undefined,
                        })}
                        formId="settlements-list-filters"
                        initialValues={settlementsFiltersConfig}
                        updatedValues={formik.values}
                        customEventChange={(formControlName: string, value: DateRange) => {
                            if (formControlName === 'until' && value?.startDate && value?.endDate) {
                                const formattedStartDate = serverFormattedDateWithCustomTime(value.startDate, 'startOfDay');
                                const formattedEndDate = serverFormattedDateWithCustomTime(value.endDate, 'endOfDay');
                                dispatch(changeSettlementFilters({'date[after]': formattedStartDate, 'date[before]': formattedEndDate}));
                            }
                        }}
                        theme={theme}
                        onSubmit={() => null}
                    />
                }
            />
        );
    };

    return (
        <section className="content-view settlements">
            <div className="header">
                <Typography variant="h4" component="h2" color="text.primary">
                    <Translation text="settlements.title" />
                </Typography>
            </div>

            {renderSettlementBoxes()}

            <Stack spacing={2.4}>
                {renderCardHeader()}

                <Card className="custom-card settlements-card">
                    <TableContainer>
                        <Table className="custom-list">
                            <TableBody>{renderTableBody()}</TableBody>
                        </Table>
                    </TableContainer>

                    {renderTablePagination()}

                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Card>
            </Stack>
        </section>
    );
};

export default Settlements;
