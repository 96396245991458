import {Button, Stack, useTheme} from '@mui/material';
import {
    FormikForm,
    HorizontalCard,
    IFileOutput,
    IFileUploadListElement,
    IInspectionInput,
    Translation,
    isNotNullOrUndefined,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {canUpdateInspectionSelector, vehicleInspectionSelector} from 'src/store/selectors/vehicleOperationSelector';
import {updateVehicleInspection} from '../../../../store/reducers/vehicleOperationSlice';
import VehicleDocumentStatusBadge from '../../shared/VehicleDocumentStatusBadge';
import {vehicleInspectionFormConfig} from './vehicleInspectionFormConfig';

interface IInspectionValues {
    until: string | null;
    inspectionPhotoFront: IFileUploadListElement | undefined;
    inspectionPhotoBack: IFileUploadListElement | undefined;
}

const VehicleRegistrationCard: React.FunctionComponent = () => {
    const dispatch = useDispatch(),
        initialValues: IInspectionValues = {
            until: '',
            inspectionPhotoFront: undefined,
            inspectionPhotoBack: undefined,
        },
        currentInspection = useSelector(vehicleInspectionSelector),
        canUpdateInspection = useSelector(canUpdateInspectionSelector),
        [updatedValues, setUpdatedValues] = useState<IInspectionValues>(initialValues),
        [isAllowed, setIsAllowed] = useState<boolean>(false),
        theme = useTheme();
    const params = useParams(),
        vehicleId = params.id;
    const updateInspection = (values: IInspectionValues) => {
        const inspectionPhotoFrontString = values.inspectionPhotoFront?.fileKey;
        const inspectionPhotoBackString = values.inspectionPhotoBack?.fileKey;
        if (inspectionPhotoFrontString && inspectionPhotoBackString) {
            const inspectionPayload: IInspectionInput = {
                until: values.until ? new Date(values.until).toISOString() : new Date().toISOString(),
                files: [inspectionPhotoFrontString, inspectionPhotoBackString],
            };
            dispatch(updateVehicleInspection(inspectionPayload, vehicleId));
        }
        return;
    };

    const handleChange = (formControl: string, value: string) => {
        setUpdatedValues({
            ...updatedValues,
            [formControl]: value,
        });
    };

    const areFilesUploaded = () => {
        return isNotNullOrUndefined(updatedValues.inspectionPhotoFront) && isNotNullOrUndefined(updatedValues.inspectionPhotoBack);
    };

    const isFormUpdated = () => {
        const currentInspectionPhotoBack = currentInspection?.files[1]?.id ? currentInspection?.files[1]?.id : '',
            currentInspectionPhotoFront = currentInspection?.files[0]?.id ? currentInspection?.files[0]?.id : '',
            updatedInspectionPhotoBack = updatedValues.inspectionPhotoBack?.fileKey ? updatedValues.inspectionPhotoBack?.fileKey : '',
            updatedInspectionPhotoFront = updatedValues.inspectionPhotoFront?.fileKey ? updatedValues.inspectionPhotoFront?.fileKey : '';
        return (
            updatedValues.until !== currentInspection.until ||
            updatedInspectionPhotoFront !== currentInspectionPhotoFront ||
            updatedInspectionPhotoBack !== currentInspectionPhotoBack
        );
    };
    const canUpdate = () => {
        if (!currentInspection) return areFilesUploaded();
        return isFormUpdated();
    };

    useEffect(() => {
        if (currentInspection) {
            const files: IFileUploadListElement[] = currentInspection.files.map((file: IFileOutput) => ({
                name: file.fileName,
                fileKey: file.id,
                url: file.contentUrl ? file.contentUrl : file.fileUrls?.original,
            }));

            setUpdatedValues({
                until: currentInspection.until,
                inspectionPhotoFront: files[0],
                inspectionPhotoBack: files[1],
            });
        }
    }, [currentInspection]);

    return (
        <HorizontalCard
            heading={
                <Stack className="vehicle-document-status-badge">
                    <Translation text="vehicles.vehicleDetails.inspectionSettings.title" />
                    <VehicleDocumentStatusBadge
                        expiring={currentInspection?.upcomingExpiration !== null && currentInspection?.upcomingExpiration === true}
                        status={currentInspection?.status}
                        hasDocument={currentInspection !== null}
                    />
                </Stack>
            }
            content={
                <FormikForm
                    fields={vehicleInspectionFormConfig()}
                    formId="inspection-form"
                    initialValues={initialValues}
                    updatedValues={updatedValues}
                    submitAllowed={setIsAllowed}
                    theme={theme}
                    customEventChange={handleChange}
                    onSubmit={updateInspection}
                />
            }
            actions={
                <Button
                    className="btn-action vehicles-vehicle-details-inspection-update-button"
                    disabled={!isAllowed || !canUpdate() || !canUpdateInspection}
                    type="submit"
                    form="inspection-form">
                    <Translation text="buttons.update" />
                </Button>
            }
        />
    );
};

export default VehicleRegistrationCard;
