import {Button, Grid, Stack, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, Translation, AlertTriangle} from 'palipali-panel-common-web';
import {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setCurrentlyDeletedVehicle} from 'src/store/reducers/vehicleOperationSlice';

interface IVehicleDeleteModalProps {
    handleConfirm: () => void;
}

const VehicleDeleteModal: FC<IVehicleDeleteModalProps> = ({handleConfirm}) => {
    const theme = useTheme(),
        dispatch = useDispatch();

    useEffect(() => {
        () => {
            dispatch(setCurrentlyDeletedVehicle(null));
        };
    }, []);

    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text="vehicles.vehicleList.deleteVehicle.modal.title" />
                </Typography>
            }
            content={
                <Grid item sx={{width: '100%'}}>
                    <Stack className="confirm-modal-content delete-vehicle">
                        <div className="confirm-modal-icon-container">
                            <AlertTriangle />
                        </div>
                        <Stack className="confirm-modal-description-wrapper">
                            <Typography variant="body2" component="p">
                                <Translation text="vehicles.vehicleList.deleteVehicle.modal.content" />
                            </Typography>
                            <Typography variant="body2" component="p" sx={{color: 'error.dark'}}>
                                <Translation text="vehicles.vehicleList.deleteVehicle.modal.subtitle" />
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            }
            footer={
                <Button
                    fullWidth
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                    autoFocus
                    className="vehicles-vehicle-view-delete-vehicle-modal-submit-button">
                    <Translation text="vehicles.vehicleList.deleteVehicle.modal.buttons.deleteVehicle" />
                </Button>
            }
        />
    );
};

export default VehicleDeleteModal;
