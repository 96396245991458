import {Stack, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ICombinedOutput} from '../../../types';
export enum LocationFormattedAddressType {
    DETAILED = 'detailed',
    GENERAL = 'general',
    TEAM = 'team',
    FULL = 'full',
}
interface ILocationFormattedAddressProps {
    readonly addressType: LocationFormattedAddressType;
    readonly address: ICombinedOutput;
}
const LocationFormattedAddress: React.FC<ILocationFormattedAddressProps> = ({address, addressType}) => {
    const {t} = useTranslation();
    const showAddress = (addressType: LocationFormattedAddressType) => {
        switch (addressType) {
            case LocationFormattedAddressType.GENERAL:
                const nameOrAddress = address.name ? address.name : address.addressLine ? address.addressLine : '';
                return <span>{`${nameOrAddress}`}</span>;
            case LocationFormattedAddressType.DETAILED:
                return (
                    <Stack component="span">
                        {address.companyName && (
                            <Typography variant="body2" color="text.primary" component="span">
                                {address.companyName ? address.companyName + ',' : ''}
                            </Typography>
                        )}
                        <Typography variant="body2" color="text.primary" component="span">
                            {address.addressLine}
                            {address.houseNumber ? address.houseNumber : ''}
                            {address.flatNumber ? `/${address.flatNumber},` : ''}
                            {address.zip ? address.zip : ''}
                            {address.city ? address.city : ''}
                        </Typography>
                    </Stack>
                );
            case LocationFormattedAddressType.TEAM:
                return (
                    <Stack component="span">
                        <Typography variant="body2" color="text.primary" component="span">
                            {address.companyName ? address.companyName + ', ' : ''}
                            {address.street ? `${t('teams.teamView.teamDetails.street') + ' ' + address.street + ' '}` : ''}
                            <span></span>
                            {address.houseNumber ? address.houseNumber : ''}
                            {address.flatNumber ? `/${address.flatNumber},` : ''}
                        </Typography>
                        <Typography variant="body2" color="text.primary" component="span">
                            {`${address.zip ? address.zip + ' ' : ''}${address.city ? address.city : ''}`}
                        </Typography>
                    </Stack>
                );
            default:
                null;
        }
    };

    return <>{showAddress(addressType)}</>;
};

export default LocationFormattedAddress;
