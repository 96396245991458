import {PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, concatMap, mergeMap, switchMap} from 'rxjs/operators';
import {getCanDeleteAccountAPI} from '../../api/account/getCanDeleteAccountAPI';
import {updateAvatarAPI} from '../../api/account/updateAvatarAPI';
import {updateLocaleAPI} from '../../api/account/updateLocaleAPI';
import {updateProfileAPI} from '../../api/account/updateProfileAPI';
import {deleteAccountAPI} from '../../api/account/deleteAccountAPI';
import {getAccountDataAPI} from '../../api/account/getAccountDataAPI';
import {updateAccountMiscAPI} from '../../api/account/updateAccountMiscAPI';
import {DetailsSuccessActionsFunction} from '../../api/base/apiConnectionInfrastructure';
import {createChangePasswordAPI} from '../../api/user/createChangePasswordAPI';
import {createConfirmEmailChangeAPI} from '../../api/user/createConfirmEmailChangeAPI';
import {IAccountBasicInfoOutput, IAccountMeOutput, IAccountProfileInput} from '../../model/account';
import {IChangeUserPasswordInput} from '../../model/user';
import {AlertType, IApiError, IApiSingleResponseBase, ICanOutput, UserRole} from '../../types';
import {createOperationEpic} from '../../utils/epicUtils';
import {handleApiError} from '../../utils/errorHandlingUtils';
import {CommonRootState} from '../reducers';
import {
    APP_THEME,
    changeAvatar,
    changeLocale,
    changePassword,
    changePasswordSuccess,
    changeTheme,
    changeWebSettings,
    checkCanDeleteAccount,
    confirmEmailChange,
    deleteAccount,
    getFullAccount,
    IChangeWebSettings,
    IDeleteAccount,
    IUpdateAccountProfile,
    IUpdateLocale,
    setAccountState,
    setCanDeleteAccount,
    updateAccountFailure,
    updateAccountInfoSuccess,
    updateAccountProfile,
} from '../reducers/accountSlice';
import {addAlert} from '../reducers/alertSlice';
import {IConfirmRegistration, logout} from '../reducers/authSlice';
import {setIsLoading} from '../reducers/loaderSlice';
import {initLogout} from '../reducers/loginSlice';
import {closeModal} from '../reducers/modalSlice';
import {accountIdSelector} from '../selectors/accountSelectors';
import {authTokenSelector} from '../selectors/authSelectors';
import {push} from 'react-router-redux';
import {LanguageLocale, LanguageLocaleType} from '../../constants/locales';
import {changeActiveLanguage} from '../reducers/sagaSlice';
import {fetchAllDictionaryData} from '../reducers/countrySlice';

const errorActions = (error: IApiError) => {
    if ((error?.response?.['hydra:description'] as string)?.includes("oldPassword: This value should be the user's current password.")) {
        return [logout(true), setIsLoading(false), updateAccountFailure(error.message), addAlert(handleApiError('alerts.badPassword'))];
    }
    return [addAlert(handleApiError(error)), setIsLoading(false), updateAccountFailure(error.message)];
};

const getAccountBasicInfoEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(getFullAccount.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value) || '';
            return getAccountDataAPI(authToken).pipe(
                mergeMap((res: IApiSingleResponseBase<IAccountMeOutput>) => {
                    return of(setAccountState(res), setIsLoading(false));
                }),
                catchError(errorActions)
            );
        })
    );

const updateLocaleEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(changeLocale.type),
        switchMap((action: PayloadAction<IUpdateLocale>) => {
            const accountId = accountIdSelector(state$.value);
            const authToken = authTokenSelector(state$.value);
            return updateLocaleAPI(authToken, accountId, action.payload.locale).pipe(
                mergeMap((res: IApiSingleResponseBase<IAccountBasicInfoOutput>) => {
                    return of(
                        addAlert({message: 'account.alert.changeLocaleSuccess', type: AlertType.SUCCESS}),
                        updateAccountInfoSuccess(res),
                        fetchAllDictionaryData(),
                        setIsLoading(false)
                    );
                }),
                catchError(errorActions)
            );
        })
    );

const updateThemeEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(changeTheme.type),
        switchMap((action: PayloadAction<APP_THEME>) => {
            const accountId = accountIdSelector(state$.value) || '';
            const authToken = authTokenSelector(state$.value);

            return updateAccountMiscAPI(authToken, accountId, action.payload).pipe(
                mergeMap((res: IApiSingleResponseBase<IAccountBasicInfoOutput>) => {
                    return of(
                        addAlert({message: 'account.alert.miscChangeSuccess', type: AlertType.SUCCESS}),
                        updateAccountInfoSuccess(res),
                        setIsLoading(false)
                    );
                }),
                catchError(errorActions)
            );
        })
    );

const updateWebSettingsEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(changeWebSettings.type),
        concatMap((action: PayloadAction<IChangeWebSettings>) => {
            const accountId = accountIdSelector(state$.value) || '';
            const authToken = authTokenSelector(state$.value);

            return updateAccountMiscAPI(authToken, accountId, action.payload.misc).pipe(
                switchMap(() => updateLocaleAPI(authToken, accountId, action.payload.locale)),
                mergeMap((res: IApiSingleResponseBase<IAccountBasicInfoOutput>) => {
                    const activeLanguage = (state$ as any).value.saga.activeLanguage;
                    const accountLanguage = res.locale ? res.locale.split('_')[0] : activeLanguage ? activeLanguage : LanguageLocale.PL;
                    return of(
                        addAlert({message: 'account.alert.miscChangeSuccess', type: AlertType.SUCCESS}),
                        changeActiveLanguage(accountLanguage),
                        fetchAllDictionaryData(),
                        updateAccountInfoSuccess(res),
                        setIsLoading(false)
                    );
                })
            );
        })
    );

const updatePasswordEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(changePassword.type),
        switchMap((action: PayloadAction<IChangeUserPasswordInput>) => {
            const authToken = authTokenSelector(state$.value) || '';
            return createChangePasswordAPI(action.payload.oldPassword, action.payload.newPassword, authToken).pipe(
                mergeMap(() => {
                    return of(
                        addAlert({message: 'account.alert.changePasswordSuccess', type: AlertType.SUCCESS}),
                        changePasswordSuccess(),
                        setIsLoading(false)
                    );
                }),
                catchError(errorActions)
            );
        })
    );

const updateAvatarSuccessActions: DetailsSuccessActionsFunction<IAccountBasicInfoOutput> = (account: IAccountBasicInfoOutput) => {
    return [updateAccountInfoSuccess(account), ...basicOperationSuccessActions('account.alert.imageAddSuccess')];
};

const updateAccountEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(updateAccountProfile.type),
        concatMap((action: PayloadAction<IUpdateAccountProfile>) => {
            const accountId = accountIdSelector(state$.value) || '',
                authToken = authTokenSelector(state$.value);

            const input: IAccountProfileInput = {
                username: action.payload.username,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                phone: {
                    country: action.payload.country,
                    phone: action.payload.phone,
                },
                cityId: action.payload.cityId,
                returnUrl: action.payload.returnUrl,
            };

            return updateProfileAPI(accountId, authToken, input).pipe(
                switchMap(() => getAccountDataAPI(authToken)),

                mergeMap((resp: IApiSingleResponseBase<IAccountMeOutput>) => {
                    return of(...basicOperationSuccessActions('account.alert.updateProfileDataSuccess'));
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError(errorActions)
    );

const deleteAccountEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(deleteAccount.type),
        switchMap(() => {
            const accountId = accountIdSelector(state$.value) || '',
                authToken = authTokenSelector(state$.value);
            return deleteAccountAPI(authToken, accountId).pipe(
                switchMap(() => {
                    return of(
                        setIsLoading(false),
                        closeModal(),
                        initLogout(),
                        addAlert({message: 'account.deleteProfile.alerts.deleted', type: AlertType.SUCCESS})
                    );
                })
            );
        }),
        catchError(errorActions)
    );

const canDeleteAccountEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(checkCanDeleteAccount.type),
        switchMap(() => {
            const accountId = accountIdSelector(state$.value) || '',
                authToken = authTokenSelector(state$.value);
            return getCanDeleteAccountAPI(authToken, accountId).pipe(
                switchMap((resp) => {
                    return of(setCanDeleteAccount(resp.can), setIsLoading(false), closeModal());
                })
            );
        }),
        catchError(errorActions)
    );

const confirmEmailChangeEpic: Epic = (action$) =>
    action$.pipe(
        ofType(confirmEmailChange.type),
        switchMap((action: PayloadAction<IConfirmRegistration>) => {
            return createConfirmEmailChangeAPI(action.payload.registrationToken).pipe(
                switchMap(() => {
                    const actions = [setIsLoading(false)];
                    if (action.payload.panelAccessRole === UserRole.COURIER) {
                        actions.push(push('/couriers/success'));
                        return of(...actions);
                    }
                    return of(...actions, addAlert({message: 'account.alert.changeEmailSuccess', type: AlertType.SUCCESS}));
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
const basicOperationSuccessActions = (successMessage: string) => [
    setIsLoading(false),
    addAlert({message: successMessage, type: AlertType.SUCCESS}),
    closeModal(),
    getFullAccount(),
];

const updateAvatarEpic = createOperationEpic<IAccountBasicInfoOutput>(
    updateAvatarAPI,
    updateAvatarSuccessActions,
    errorActions,
    changeAvatar().type
);

const accountEpic = combineEpics(
    getAccountBasicInfoEpic,
    updateAvatarEpic,
    confirmEmailChangeEpic,
    canDeleteAccountEpic,
    updatePasswordEpic,
    updateLocaleEpic,
    updateThemeEpic,
    updateWebSettingsEpic,
    deleteAccountEpic,
    updateAccountEpic
);

export default accountEpic;
