import * as React from 'react';
import {Box, Divider, Stack, Typography, CircularProgressProps, CircularProgress} from '@mui/material';
import HourglassTimerIcon from '../../../theme/icons/untitled-ui/duocolor/hourglass-timer-icon';
import Translation from '../../Translation';

const CircularProgressWithLabel: React.FC<CircularProgressProps & {value?: number; stringValue?: string; isHourglass?: boolean}> = ({
    isHourglass,
    stringValue,
    ...otherProps
}) => {
    const hasHours = (stringValue: string) => stringValue.split(':').length === 3;
    const renderContent = () => {
        if (isHourglass) {
            return (
                <Typography variant="caption" component="div" color="primary">
                    <HourglassTimerIcon color={'primary'} />
                </Typography>
            );
        }
        if (stringValue) {
            return (
                <Stack className="progress-string-value-wrapper">
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.primary"
                        className={`progress-string-value ${hasHours(stringValue) ? 'hour-input' : ''}`}>
                        {hasHours(stringValue) ? stringValue.split(':').slice(0, -1).join(':') : stringValue}
                    </Typography>
                    <Typography variant="caption" component="div" color="text.primary" className="progress-string-measurement-unit">
                        <Translation text={`jobs.orderDetails.timer.${hasHours(stringValue) ? 'hours' : 'minutes'}`} />
                    </Typography>
                </Stack>
            );
        }
    };
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}} className="job-view-circular-progress-wrapper">
            <Divider className="job-view-circular-progress-divider" />
            <CircularProgress
                sx={{
                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'white'),
                    color: 'primary',
                    borderColor: 'divider',
                    borderStyle: 'solid',
                    borderWidth: 1,
                }}
                variant={`${isHourglass ? 'indeterminate' : 'determinate'}`}
                {...otherProps}
                className="job-view-circular-progress-progress-item"
            />
            <Box
                className="job-view-circular-progress-progress-content-wrapper"
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                {renderContent()}
            </Box>
        </Box>
    );
};
type ProgressTimerProps = {
    isHourglass: boolean;
    withProgress: boolean;
    value?: number;
    stringValue?: string;
};

const ProgressTimer: React.FC<ProgressTimerProps> = (props) => {
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 250);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            <CircularProgressWithLabel
                value={props.withProgress ? progress : props.value}
                stringValue={props.stringValue}
                color="primary"
                isHourglass={props.isHourglass}
                size={48}
                thickness={2}
            />
            {props.value === 0 && (
                <Typography variant="subtitle2" color="text.secondary">
                    <Translation text="jobs.orderDetails.courierArriving" />
                </Typography>
            )}
        </>
    );
};

export default ProgressTimer;
