import {IApiSingleResponseBase} from '../../types';
import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IAccountBasicInfoOutput} from '../../model/account';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {IUpdateAvatar} from '../../store/reducers/accountSlice';

export const updateAvatarAPI: ApiOperationResponseFunction<IAccountBasicInfoOutput> = (
    authToken: string,
    payload: IUpdateAvatar
): Observable<IApiSingleResponseBase<IAccountBasicInfoOutput>> => {
    return palipaliAPI.put(`api/accounts/${payload.accountId}/change_avatar`, payload.avatar, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
