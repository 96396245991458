import {Typography} from '@mui/material';
import {SeverityPill, Translation, IVehicleDocumentStatus} from 'palipali-panel-common-web';
import React from 'react';
import {vehicleStatusColorSettings} from '../VehicleStatusBadge';
import {SeverityPillColor} from 'palipali-panel-common-web/dist/theme/components/SeverityPill';

interface VehicleDocumentStatusBadgeProps {
    expiring: boolean;
    status: IVehicleDocumentStatus;
    hasDocument: boolean;
}

const VehicleDocumentStatusBadge: React.FC<VehicleDocumentStatusBadgeProps> = ({expiring, status, hasDocument}) => {
    if (!status && hasDocument) return null;

    type VehicleStatusWithExpiringState = IVehicleDocumentStatus | 'expiring' | 'to_be_filled';

    let documentStatus: VehicleStatusWithExpiringState = expiring ? 'expiring' : status;

    if (!hasDocument) {
        documentStatus = 'to_be_filled';
    }

    const getDocumentBadgeColor = (status: VehicleStatusWithExpiringState) => {
        switch (status) {
            case 'active':
                return vehicleStatusColorSettings.successDark;
            case 'expiring':
            case 'pending':
                return vehicleStatusColorSettings.warningDark;
            case 'rejected':
            case 'inactive':
                return vehicleStatusColorSettings.error;
            default:
                return vehicleStatusColorSettings.error;
        }
    };

    return (
        <SeverityPill
            color={getDocumentBadgeColor(documentStatus).color as SeverityPillColor}
            customColor={getDocumentBadgeColor(documentStatus).customColor ? getDocumentBadgeColor(documentStatus).customColor : undefined}
            backgroundColor={getDocumentBadgeColor(documentStatus).backgroundColor}>
            <Typography variant="caption" component="span" className="vehicle-document-status-badge-text">
                <Translation text={`vehicles.vehicleDetails.documentStatus.${documentStatus}`} />
            </Typography>
        </SeverityPill>
    );
};

export default VehicleDocumentStatusBadge;
