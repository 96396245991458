import {Observable} from 'rxjs';
import {IAccountMeOutput, IAccountProfileInput} from '../../model/account';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export function updateProfileAPI(
    accountId: string,
    authToken: string,
    profile: IAccountProfileInput
): Observable<IApiSingleResponseBase<IAccountMeOutput>> {
    return palipaliAPI.put(`api/accounts/${accountId}/change_profile`, profile, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
