import type {SVGProps} from 'react';
import React from 'react';

const SadFaceIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M46.8 43.8C46.8 43.8 42.75 38.4 36 38.4C29.25 38.4 25.2 43.8 25.2 43.8M49.5 25.548C48.4335 26.8575 46.9755 27.6 45.45 27.6C43.9245 27.6 42.507 26.8575 41.4 25.548M30.6 25.548C29.5335 26.8575 28.0755 27.6 26.55 27.6C25.0245 27.6 23.607 26.8575 22.5 25.548M63 33C63 47.9117 50.9117 60 36 60C21.0883 60 9 47.9117 9 33C9 18.0883 21.0883 6 36 6C50.9117 6 63 18.0883 63 33Z"
            stroke="#F04438"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SadFaceIcon;
