import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IAccountSettlementState} from '../reducers/accountSettlmentSlice';

export const selectAccountSettlementSlice = (state: RootState) => {
    return state.accountSettlement;
};
export const accountSettlementBillingInfoSelector = createSelector(
    [selectAccountSettlementSlice],
    (state: IAccountSettlementState) => state.billingInfo
);
export const accountSettlementCanDoStripeOnboardingSelector = createSelector(
    [selectAccountSettlementSlice],
    (state: IAccountSettlementState) => state.canDoStripeOnboarding
);
export const isLoadingAccountSettlementSelector = createSelector(
    [selectAccountSettlementSlice],
    (state: IAccountSettlementState) => state.isLoading
);

export const isLoadingAccountSettlementStripeSelector = createSelector(
    [selectAccountSettlementSlice],
    (state: IAccountSettlementState) => state.isStripeCardLoading
);

export const stripeOnboardingUrlSelector = createSelector(
    [selectAccountSettlementSlice],
    (state: IAccountSettlementState) => state.stripeOnboardingUrl
);
