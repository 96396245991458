import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';

export const selectVehicleAssignedCourierListingSlice = (state: RootState) => {
    return state.vehicleAssignedCourierListing;
};

export const vehicleAssignedCourierListingSelector = createSelector(
    [selectVehicleAssignedCourierListingSlice],
    (state: any) => state.fleetPartnerCourierList
);
export const currentVehicleAssignedCourierIdListSelector = createSelector(
    [selectVehicleAssignedCourierListingSlice],
    (state: any) => state.currentVehicleAssignedCourierIdList
);

export const couriersToAddListSelector = createSelector([selectVehicleAssignedCourierListingSlice], (state: any) => state.couriersToAdd);

export const couriersToRemoveListSelector = createSelector(
    [selectVehicleAssignedCourierListingSlice],
    (state: any) => state.couriersToRemove
);

export const vehicleAssignedCourierListingMetadataSelector = createSelector(
    [selectVehicleAssignedCourierListingSlice],
    (state: any) => state.metadata
);
export const vehicleAssignedCourierListingTotalItemsSelector = createSelector(
    [selectVehicleAssignedCourierListingSlice],
    (state: any) => state.metadata?.totalItems
);
export const vehicleAssignedCourierListingPaginationSelector = createSelector(
    [selectVehicleAssignedCourierListingSlice],
    (state: any) => state.pagination
);
export const vehicleAssignedCourierListVehicleIdSelector = createSelector([selectVehicleAssignedCourierListingSlice], (state: any) => {
    return state.assignedCourierListVehicleId;
});
export const isVehicleAssignedCourierListingLoadingSelector = createSelector(
    [selectVehicleAssignedCourierListingSlice],
    (state: any) => state.isLoading
);
