import {InputDataMapper, OutputDataMapper, IMultiselectOption, FormControlChangeType} from '../types';
import {isNullOrUndefined, isNotNullOrUndefined} from './runtimeUtils';
import {convertToKebabCase} from './stringUtils';
import {useEffect} from 'react';
import {Subscription, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import React from 'react';
import {phoneCodesList} from '../assets/metadata/phoneCodesList';
export const defaultDataMapper: InputDataMapper = (data: any) => data;

export const defaultOutputDataMapper: OutputDataMapper = (data: any, previousStateSnapshot: any, controlName: string) => {
    previousStateSnapshot[controlName] = data;

    return previousStateSnapshot;
};

export const defaultDataAccessor = (data: any, key: string | number) => {
    if (null === data || undefined === data) {
        return null;
    }

    return data[key];
};

export const removeExtraValueKeys = (value: object, prefix: string, index: number): void => {
    delete value[`${prefix}${index}`];
    Object.keys(value).forEach((key) => {
        if (!key.startsWith(prefix)) {
            return;
        }
        const regex = new RegExp(`(${prefix})(\\d+)`);
        const matches = regex.exec(key);
        if (undefined === matches || null === matches || matches.length < 3) {
            return;
        }
        const targetIndex = Number(matches[2]);
        if (targetIndex <= index) {
            return;
        }
        value[`${prefix}${targetIndex - 1}`] = value[key];
        delete value[key];
    });
};

export const collectionInputDataMapper = (data: any, config: any) => {
    if (isNullOrUndefined(data)) {
        return [];
    }
    if (!config || !config.multiselectOptions || !Array.isArray(config.multiselectOptions)) {
        return [];
    }

    const option = config.multiselectOptions.find((candidate: IMultiselectOption) => {
        return candidate.value === data;
    });

    return option ? option : [];
};

export const collectionOutputDataMapper = (data: any) => {
    if (Array.isArray(data)) {
        return data.length > 0 ? data : null;
    }
    if (data && typeof data === 'object') {
        return data.value;
    }

    return data;
};

export const multiCollectionInputDataMapper = (data: any, config: any) => {
    if (isNullOrUndefined(data) || data === '') {
        return [];
    }
    if (!config || !config.multiselectOptions || !Array.isArray(config.multiselectOptions)) {
        return [];
    }
    if (!Array.isArray(data)) {
        data = [data];
    }

    const isMultiOptionGrouped = config.multiselectOptions.some((option: {[key: string]: any}) => {
        return option.hasOwnProperty('options');
    });

    if (isMultiOptionGrouped) {
        const selectedOptions: any[] = [];
        data.forEach((entry: string) => {
            config.multiselectOptions.forEach((option: {[key: string]: any}) => {
                if (option.hasOwnProperty('options')) {
                    return option.options.find((candidate: IMultiselectOption) => {
                        if (candidate.value === entry) {
                            return selectedOptions.push(candidate);
                        }
                    });
                }

                if (option.value === entry) {
                    return selectedOptions.push(option);
                }
            });
        });
        return selectedOptions;
    }

    const options = data.map((entry: string) => {
        return config.multiselectOptions.find((candidate: IMultiselectOption) => {
            return candidate.value === entry;
        });
    });

    return options ? options : [];
};

export const multiCollectionOutputDataMapper = (data: any) => {
    if (Array.isArray(data)) {
        return data.length > 0
            ? data.filter((entry) => isNotNullOrUndefined(entry)).map((entry) => (isNotNullOrUndefined(entry.value) ? entry.value : entry))
            : null;
    }
    if (data && typeof data === 'object') {
        return data.value;
    }

    return data;
};

export const createFormPath = (path: string | null | undefined): string => {
    path ||= '';

    return `${filterFormPath(path)}`;
};

export const appendFormPath = (path: string | null | undefined, part: string): string => {
    path ||= '';

    return `${path}.${filterFormPath(part)}`;
};

const filterFormPath = (path: string): string => {
    return path.replace(/[\-.]/g, '_');
};

export const convertToMultiselectLabels = (data: {[key: string]: any} | null, t?: any, isFormatted?: boolean): IMultiselectOption[] => {
    if (data === null) {
        return [];
    }

    return data.map((item: {[key: string]: any}) => {
        const itemName = isFormatted ? item.name.toLowerCase() : item.name;
        return {label: t ? t(itemName) : item.name, value: item.id};
    });
};

export const convertStringArrayToMultiselectLabels = (data: string[] | null, t?: any): IMultiselectOption[] => {
    if (data === null || data.length === 0) {
        return [];
    }
    return data.map((item: string) => {
        return {label: t ? t(item) : item, value: item};
    });
};

export const sortMultiselectLabels = (multiselectOptions: IMultiselectOption[]) => {
    return multiselectOptions.sort((a: IMultiselectOption, b: IMultiselectOption) => a.label.localeCompare(b.label));
};

export const createFormButtonClass = (path: string | null | undefined): string => {
    path = path ? path : 'form';

    const formattedPath = convertToKebabCase(path);
    return `${formattedPath}-submit`;
};

export const createFormInputClass = (path: string | null | undefined): string => {
    path = path ? path : 'form';

    const formattedPath = convertToKebabCase(path);
    return `${formattedPath}-input`;
};

export const createFormClass = (path: string | null | undefined): string => {
    path = path ? path : 'form';
    return convertToKebabCase(path);
};

export const updateFormControlValue = <T extends Record<string, any>>(prevState: T, fieldName: keyof T, data: {value?: T}) =>
    data.value && isNotNullOrUndefined(data.value[fieldName]) ? data.value[fieldName] : prevState[fieldName];

export const useFormUpdateValues = <T extends Record<string, any>>(
    onValueStateChange$$: Observable<any>,
    setValue: React.Dispatch<React.SetStateAction<T>>
) => {
    useEffect(() => {
        const subscriptions: Subscription[] = [];

        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data.changeType === FormControlChangeType.User && isNotNullOrUndefined(data.controlName);
                    })
                )
                .subscribe((data: any) => {
                    setValue((prevState: T) => {
                        const formValues = {...prevState};

                        Object.keys(prevState).forEach((key) => {
                            formValues[key as keyof T] = updateFormControlValue(prevState, key as keyof T, data);
                        });

                        return formValues;
                    });
                })
        );

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [onValueStateChange$$]);
};

export const formatStartDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatEndDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day} 23:59:59`;
    };

export const selectPhoneCodeList: IMultiselectOption[] =
    phoneCodesList.map((item) => {
        const replacedPhoneCodeItem: IMultiselectOption = {
            value: item.value,
            label: item.value,
        };
        return replacedPhoneCodeItem;
    }) || [];
