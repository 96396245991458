import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase} from '../../types';
import {ITeamViewOutput} from '../../model/team';

export function getTeamViewAPI(authToken: string, payload: any): Observable<IApiSingleResponseBase<ITeamViewOutput>> {
    return palipaliAPI.get(`api/teams/${payload.teamId}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
