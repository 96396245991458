import type {SVGProps} from 'react';
import React from 'react';

const DimensionSpacerIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={5} height={5} fill="none" {...props}>
        <path
            d="M3.54134 1.45898L1.45801 3.54232M1.45801 1.45898L3.54134 3.54232"
            stroke="#AD8A56"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default DimensionSpacerIcon;
