import React from 'react';
import {NavLink as Link} from 'react-router-dom';
import {DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown, UncontrolledTooltip} from 'reactstrap';
import ChevronDownIcon from '../../../theme/icons/untitled-ui/duocolor/chevron-down';
import {INavbarNavigationProps} from '../../../types';
import Translation from '../../Translation';

type Props = INavbarNavigationProps;

const NavbarNavigation: React.FC<Props> = ({navigationMenu, isTooltipVisible}) => {
    const renderNavigation = () => {
        if (navigationMenu.length) {
            return navigationMenu
                .map((item: any) => {
                    return (
                        <NavItem key={item.target}>
                            <NavLink tag={Link} to={item.link} id={item.target}>
                                <p className="d-none d-lg-block">
                                    <Translation text={item.title} />
                                </p>
                                {isTooltipVisible ? <UncontrolledTooltip target={item.target}>{item.title}</UncontrolledTooltip> : null}
                            </NavLink>
                        </NavItem>
                    );
                })
                .slice(0, 10);
        } else {
            return null;
        }
    };

    const renderExtraNavigationDropdown = () => {
        if (navigationMenu.length && navigationMenu.length >= 11) {
            return (
                <NavItem className="d-none d-lg-block">
                    <NavLink tag="span">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="span">
                                <ChevronDownIcon />
                            </DropdownToggle>
                            <DropdownMenu end>
                                {navigationMenu
                                    .map((item: any) => {
                                        return (
                                            <DropdownItem tag={Link} to={item.link} key={item.id}>
                                                <span className="align-middle">{item.title}</span>
                                            </DropdownItem>
                                        );
                                    })
                                    .slice(10)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </NavLink>
                </NavItem>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <ul className="navbar-nav">
                <NavItem>
                    <NavLink href={'/'}>{/*<img src={logoImage} alt="PaliPali Logo" />*/}</NavLink>
                </NavItem>
            </ul>
            <ul className="nav navbar-nav bookmark-icons">
                {renderNavigation()}
                {renderExtraNavigationDropdown()}
            </ul>
        </>
    );
};

export default NavbarNavigation;
