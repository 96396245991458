import {AlertType, UserRole} from '../types';

export type TeamsByRole = {
    [role in UserRole]?: string[];
};

export const groupTeamsByRole = (decodedToken: any): TeamsByRole => {
    const teamsFromTokenRaw = decodedToken.teams;
    return Object.keys(teamsFromTokenRaw).reduce((accumulator: TeamsByRole, teamId: string) => {
        const role: UserRole = teamsFromTokenRaw[teamId];
        if (!accumulator[role]) {
            accumulator[role] = [];
        }
        accumulator[role].push(teamId);
        return accumulator;
    }, {} as TeamsByRole);
};
