import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './baseSlice';
import {ILocationTypeOutput} from '../../model/location';

export interface ILocationTypeState extends IBaseDictionaryState {
    locationTypes: ILocationTypeOutput[] | null;
}

export interface IChangeLocationType {
    locationTypes: ILocationTypeOutput[] | null;
}

const initialState: ILocationTypeState = {
    locationTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const locationTypeSlice = createSlice({
    name: 'locationType',
    initialState: initialState,
    reducers: {
        changeLocationType: {
            reducer: (state: ILocationTypeState, action: PayloadAction<IChangeLocationType>) => {
                return {
                    locationTypes: action.payload.locationTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(locationTypes: ILocationTypeOutput[] | null) {
                return {
                    payload: {locationTypes: locationTypes},
                };
            },
        },
        changeIsLocationTypeLoading: {
            reducer: (state: ILocationTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    locationTypes: state.locationTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsLocationTypeInitialized: {
            reducer: (state: ILocationTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    locationTypes: state.locationTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setLocationTypeError: {
            reducer: (state: ILocationTypeState, action: PayloadAction<ISetError>) => {
                return {
                    locationTypes: state.locationTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchLocationTypes: (state: ILocationTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    },
});

export const {changeLocationType, changeIsLocationTypeLoading, changeIsLocationTypeInitialized, setLocationTypeError, fetchLocationTypes} =
    locationTypeSlice.actions;

export default locationTypeSlice.reducer;
