import {Button, Stack, Typography} from '@mui/material';
import {
    HorizontalCard,
    MemberOperationType,
    Translation,
    currentlySelectedModalTypeSelector,
    isModalOpenSelector,
    openModal,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {
    checkDeleteVehiclePossibility,
    deleteVehicle,
    setCurrentlyDeletedVehicle,
    setRedirectToVehicleList,
} from 'src/store/reducers/vehicleOperationSlice';
import {
    canDeleteCurrentVehicleSelector,
    currentlyDeletedVehicleIdSelector,
    isRedirectToVehicleListSelector,
} from 'src/store/selectors/vehicleOperationSelector';
import VehicleDeleteModal from './VehicleDeleteModal';

const VehicleDeleteCard: React.FC = () => {
    const dispatch = useDispatch(),
        params = useParams(),
        navigate = useNavigate(),
        currentlyDeletedVehicleId = useSelector(currentlyDeletedVehicleIdSelector),
        canDeleteCurrentVehicle = useSelector(canDeleteCurrentVehicleSelector),
        isRedirectToVehicleList = useSelector(isRedirectToVehicleListSelector),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        vehicleId = params.id,
        [deleteButtonDisabled, setDeleteButtonDisabled] = React.useState<boolean>(true);

    const handleClickOpen = (vehicleId: string | undefined) => {
        if (!vehicleId) return null;
        dispatch(setCurrentlyDeletedVehicle(vehicleId));
        dispatch(openModal(MemberOperationType.VEHICLE_DELETE_VEHICLE));
    };

    const handleConfirm = () => {
        if (!currentlyDeletedVehicleId || !canDeleteCurrentVehicle) return null;
        dispatch(deleteVehicle(currentlyDeletedVehicleId));
    };

    useEffect(() => {
        if (vehicleId) {
            dispatch(checkDeleteVehiclePossibility(vehicleId));
        }
    }, [dispatch, vehicleId]);

    useEffect(() => {
        if (canDeleteCurrentVehicle !== null) {
            setDeleteButtonDisabled(!canDeleteCurrentVehicle);
        }
    }, [canDeleteCurrentVehicle]);

    useEffect(() => {
        if (isRedirectToVehicleList) {
            dispatch(setRedirectToVehicleList(false));
            navigate('/panel/vehicles/');
        }
    }, [isRedirectToVehicleList, navigate]);

    return (
        <HorizontalCard
            heading={<Translation text="vehicles.vehicleDetails.deleteVehicle.title" />}
            content={
                <Stack className="vehicle-delete-content-wrapper">
                    <Typography variant="subtitle1" component="h4">
                        <Translation text="vehicles.vehicleDetails.deleteVehicle.subtitle" />
                    </Typography>
                    <Button
                        variant="outlined"
                        color="error"
                        disabled={deleteButtonDisabled}
                        onClick={() => handleClickOpen(vehicleId)}
                        size="medium"
                        className="vehicles-vehicle-details-vehicle-delete-button">
                        <Translation text="vehicles.vehicleDetails.buttons.delete" />
                    </Button>

                    {isModalOpen && modalType === MemberOperationType.VEHICLE_DELETE_VEHICLE && (
                        <VehicleDeleteModal handleConfirm={handleConfirm} />
                    )}
                </Stack>
            }
        />
    );
};

export default VehicleDeleteCard;
