import React, {useEffect} from 'react';
import {selectSystemParameter} from '../../store/selectors/systemParametersSelectors';
import {SystemParameterCode} from '../../model/systemPatameters';
import {useSelector} from 'react-redux';

interface IAppDataRefreshComponentProps {
    silentDictionaryRefreshFunction: () => void;
}
const AppDataRefreshComponent: React.FC<IAppDataRefreshComponentProps> = ({silentDictionaryRefreshFunction}) => {
    const refreshDictionaryIntervalParameter = useSelector((state) =>
        selectSystemParameter(state, SystemParameterCode.REFRESH_DICTIONARY_INTERVAL)
    )?.value;

    useEffect(() => {
        let interval: ReturnType<typeof setTimeout>;
        if (refreshDictionaryIntervalParameter) {
            interval = setInterval(() => {
                silentDictionaryRefreshFunction();
            }, +refreshDictionaryIntervalParameter * 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [refreshDictionaryIntervalParameter]);

    return <React.Fragment />;
};

export default AppDataRefreshComponent;
