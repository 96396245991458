import {injectable} from 'inversify';
import {v4 as uuidv4} from 'uuid';

export type IFormStateListener = (mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void;

@injectable()
export class FormStateEmitter {
    private forms = new Map<string, Map<string, IFormStateListener>>();

    constructor() {}

    register(formId: string, listener): () => void {
        let listeners: Map<string, IFormStateListener>;
        if (this.forms.has(formId)) {
            listeners = this.forms.get(formId);
        } else {
            listeners = new Map();
            this.forms.set(formId, listeners);
        }
        const key = uuidv4();
        listeners.set(key, listener);

        return () => {
            listeners.delete(key);
            if (0 === listeners.size) {
                this.forms.delete(formId);
            }
        };
    }

    emit(formId: string, mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean): void {
        if (this.forms.has(formId)) {
            this.forms.get(formId).forEach((listener) => listener(mappedOutputValue, isFormValid, isFormTouched));
        }
    }
}

export default FormStateEmitter;
