import {DateRange, FormikFieldConfig, FormikFieldTypes} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const settlementsFilterFormConfig = (initialDate: DateRange): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'until',
            label: t('settlements.date'),
            validation: Yup.date(),
            startFromTomorrow: true,
            initialDates: initialDate,
            dateFormat: 'DD.MM.YYYY',
            className: 'vehicle-inspection-form-until-form-control',
            type: FormikFieldTypes.DATERANGE,
        },
    ];
};

export default settlementsFilterFormConfig;
