import React from 'react';

const PlusSquareIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="plus-square">
            <path
                id="Icon"
                d="M8 6V12M5 9H11M4.85 15.75H11.15C12.4101 15.75 13.0402 15.75 13.5215 15.5048C13.9448 15.289 14.289 14.9448 14.5048 14.5215C14.75 14.0402 14.75 13.4101 14.75 12.15V5.85C14.75 4.58988 14.75 3.95982 14.5048 3.47852C14.289 3.05516 13.9448 2.71095 13.5215 2.49524C13.0402 2.25 12.4101 2.25 11.15 2.25H4.85C3.58988 2.25 2.95982 2.25 2.47852 2.49524C2.05516 2.71095 1.71095 3.05516 1.49524 3.47852C1.25 3.95982 1.25 4.58988 1.25 5.85V12.15C1.25 13.4101 1.25 14.0402 1.49524 14.5215C1.71095 14.9448 2.05516 15.289 2.47852 15.5048C2.95982 15.75 3.58988 15.75 4.85 15.75Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default PlusSquareIcon;
