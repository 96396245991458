import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../reducers';
import {IJobProblemTypeState} from '../reducers/jobProblemTypeSlice';

export const selectJobProblemType = (state: CommonRootState): IJobProblemTypeState => {
    return state.jobProblemType;
};

export const jobProblemTypeSelector = createSelector([selectJobProblemType], (state: IJobProblemTypeState) => state.jobProblemTypes);
export const purchaserJobProblemTypesSelector = createSelector([jobProblemTypeSelector], (problemTypes) => {
    if (!problemTypes) {
        return null;
    }

    return problemTypes.filter((problemType) => {
        const allowedFor = problemType.allowedFor;
        return (
            (Array.isArray(allowedFor) && allowedFor.includes('purchaser')) ||
            allowedFor === null ||
            (Array.isArray(allowedFor) && allowedFor.length === 0)
        );
    });
});
export const jobProblemTypeError = createSelector([selectJobProblemType], (state: IJobProblemTypeState) => state.jobProblemTypes);
export const isJobProblemTypeLoading = createSelector([selectJobProblemType], (state: IJobProblemTypeState) => state.isLoading);
