import {createSelector} from '@reduxjs/toolkit';
import {ISettlementListingSliceState} from '../reducers/settlementListingSlice';
import {RootState} from '../reducers';

export const selectSettlement = (state: RootState): ISettlementListingSliceState => {
    return state.settlementListing;
};
export const settlementListSelector = createSelector([selectSettlement], (state: ISettlementListingSliceState) => state.settlementList);
export const currentSettlementSelector = createSelector(
    [selectSettlement],
    (state: ISettlementListingSliceState) => state.currentSettlement
);
export const processingSettlementSelector = createSelector(
    [selectSettlement],
    (state: ISettlementListingSliceState) => state.processingSettlement
);
export const settlementListingFiltersSelector = createSelector(
    [selectSettlement],
    (state: ISettlementListingSliceState) => state.settlementFilters
);
export const isSettlementListingLoadingSelector = createSelector(
    [selectSettlement],
    (state: ISettlementListingSliceState) => state.isLoading
);
export const isSettlementListingInitializedSelector = createSelector(
    [selectSettlement],
    (state: ISettlementListingSliceState) => state.isInitialized
);
export const settlementListingErrorSelector = createSelector([selectSettlement], (state: ISettlementListingSliceState) => state.error);

export const settlementListingPaginationSelector = createSelector(
    [selectSettlement],
    (state: ISettlementListingSliceState) => state.pagination
);

export const settlementListingMetadataSelector = createSelector(
    [selectSettlement],
    (state: ISettlementListingSliceState) => state.metadata
);
