import {Stack, Typography} from '@mui/material';
import React from 'react';
import SizeComponent from '../../SizeComponent';
import {IPurchaserCurrentJobDetailsOutput, JobStatus} from '../../../types';
import JobStatusDetailsBadge, {DeliveryStatus} from '../../JobsStatusDetailsBadge';
import TimeComponent from '../../TimeComponent';

interface IJobViewHeaderProps {
    job: IPurchaserCurrentJobDetailsOutput;
}

const JobViewHeader: React.FC<IJobViewHeaderProps> = (props: IJobViewHeaderProps) => {
    return (
        <Stack className={`job-information ${props.job.deliveryStatus === JobStatus.PLANNED ? 'planned' : ''}`}>
            <div className="job-data">
                <Typography className="job-number" color="text.primary" variant="h4">{`#${props.job.number}`}</Typography>
                <JobStatusDetailsBadge status={props.job.deliveryStatus as JobStatus} />
            </div>
            <div className="job-status">
                <Typography className="package-date">
                    <TimeComponent application="purchaser" date={props.job.createdAt} time={props.job.time} isSimpleTime />
                </Typography>
                <SizeComponent size={props.job.packageSize} />
            </div>
        </Stack>
    );
};

export default JobViewHeader;
