import React from 'react';
import {IModelCity, citiesSelector} from 'palipali-panel-common-web';
import {useSelector} from 'react-redux';
interface IShowCity {
    readonly cityId: string | null;
}

const ShowCity: React.FC<IShowCity> = ({cityId}) => {
    const cities: IModelCity[] = useSelector(citiesSelector);
    const city: IModelCity | undefined = cities.find((city) => city.id === cityId);
    return <span>{city?.name}</span>;
};

export default ShowCity;
