import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../reducers';
import {ISystemParametersState} from '../reducers/systemParametersSlice';

interface PackageAttributes {
    [key: string]: string;
}
export interface PackageDetails {
    weight: string;
    b: string;
    c: string;
    a: string;
}

export interface PackageTypes {
    s: PackageDetails;
    m: PackageDetails;
    l: PackageDetails;
}

export const selectSystemParameters = (state: CommonRootState): ISystemParametersState => {
    return state.systemParameters;
};

export const systemParametersSelector = createSelector([selectSystemParameters], (state: ISystemParametersState) => state.parameters);

export const systemParametersLoadingSelector = createSelector([selectSystemParameters], (state: ISystemParametersState) => state.isLoading);

export const systemParametersInitializedSelector = createSelector(
    [selectSystemParameters],
    (state: ISystemParametersState) => state.isInitialized
);

export const systemParametersErrorSelector = createSelector([selectSystemParameters], (state: ISystemParametersState) => state.error);

export const selectSystemParameter = createSelector(
    [systemParametersSelector, (state, paramName) => paramName],
    (parameters, paramName) => {
        return parameters?.find((parameter) => parameter.code === paramName);
    }
);
export const packageParametersSelector = createSelector([systemParametersSelector], (parameters) => {
    const packageParameters = parameters?.filter((parameter) => parameter.code.startsWith('package_'));
    const packageS: PackageAttributes = {},
        packageM: PackageAttributes = {},
        packageL: PackageAttributes = {};

    if (!packageParameters) {
        return {
            s: {
                weight: '15',
                b: '50',
                c: '50',
                a: '50',
            },
            m: {
                weight: '50',
                b: '200',
                c: '100',
                a: '100',
            },
            l: {
                weight: '70',
                b: '250',
                c: '200',
                a: '600',
            },
        };
    }
    packageParameters.forEach((item) => {
        const codeParts = item.code.split('_');
        const packageType = codeParts[0];
        const attribute = codeParts[codeParts.length - 1];
        if (packageType === 'package') {
            switch (codeParts[1]) {
                case 's':
                    packageS[`${attribute}`] = item.value;
                    break;
                case 'm':
                    packageM[attribute] = item.value;
                    break;
                case 'l':
                    packageL[attribute] = item.value;
                    break;
                default:
                    break;
            }
        }
    });

    const payload: PackageTypes = {
        s: {
            weight: packageS.weight || '15',
            b: packageS.b || '50',
            c: packageS.c || '50',
            a: packageS.a || '50',
        },
        m: {
            weight: packageM.weight || '50',
            b: packageM.b || '200',
            c: packageM.c || '100',
            a: packageM.a || '100',
        },
        l: {
            weight: packageL.weight || '70',
            b: packageL.b || '250',
            c: packageL.c || '200',
            a: packageL.a || '600',
        },
    };
    return payload;
});
