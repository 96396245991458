import React from 'react';
import {Grid, Typography, Card} from '@mui/material';
import {LoaderType} from '../../../types';
import {Loader} from '../../../index';

interface IHorizontalCardProps {
    heading: React.ReactNode;
    content: React.ReactNode;
    actions?: React.ReactNode;
    showLoader?: boolean;
}

const HorizontalCard: React.FC<IHorizontalCardProps> = ({heading, content, actions, showLoader}) => {
    const show: boolean = !!showLoader;

    return (
        <Card className="custom-card">
            <Grid container spacing={1} sx={{px: 4.6, py: 4.6}}>
                <Grid item md={3} sm={3} xs={12} sx={{py: 1}}>
                    <Typography variant="h6" component="h3" color="text.primary" gutterBottom>
                        {heading}
                    </Typography>
                </Grid>
                <Grid item md={7} sm={9} xs={12} sx={{py: 1}}>
                    {content}
                </Grid>
                <Grid item className="action-container" md={2} sm={12} sx={{py: 1}}>
                    {actions}
                </Grid>
            </Grid>
            <Loader showLoader={show} type={LoaderType.Local} />
        </Card>
    );
};

export default HorizontalCard;
