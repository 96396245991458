export enum OnboardingMode {
    ONBOARDING = 'account_onboarding',
    UPDATE = 'account_update',
}

export interface ICanDoStripeOnboardingOutput {
    can: boolean;
}
export interface IStripeOnboardingInput {
    refreshUrl: string;
    returnUrl: string;
    onboardingMode: OnboardingMode;
}

export interface IStripeOnboardingOutput {
    onboardingUrl: string;
}
