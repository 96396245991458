import {Box, Stack} from '@mui/material';
import PropTypes from 'prop-types';
import type {FC} from 'react';
import React from 'react';
import NavUserBox from '../NavUserBox';
import {Section} from '../../types/themeTypes';
import {usePathname} from '../../hooks/themeHooks';
import Scrollbar from '../../components/Scrollbar';
import {UserRole} from '../../../types';
import {NavSection} from './nav-section';
import FlagiEU from '../../../assets/images/flagi-eu';

interface INavProps {
    sections?: Section[];
    subMenuSections?: Section[];
    userRole?: UserRole;
    userName?: string | null;
}

export const Nav: FC<INavProps> = (props) => {
    const pathname = usePathname();

    return (
        <Stack sx={{flex: 1}}>
            <NavUserBox userRole={props.userRole} userName={props.userName} />
            <Scrollbar
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    alignItems: 'stretch',
                }}>
                <Box sx={{display: 'flex', flex: '1', flexDirection: 'column'}}>
                    <Stack component="nav" spacing={2} sx={{height: '100%', flexGrow: 1, px: 2}}>
                        {props.sections.map((section, index) => (
                            <NavSection items={section.items} key={index} pathname={pathname} subheader={section.subheader} />
                        ))}
                    </Stack>
                </Box>
            </Scrollbar>
            <Box sx={{display: 'flex', flexDirection: 'column', paddingBottom: '2rem'}}>
                <Stack component="nav" spacing={2} sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, px: 2}}>
                    {props.subMenuSections.map((section, index) => (
                        <NavSection items={section.items} key={index} pathname={pathname} subheader={section.subheader} />
                    ))}
                </Stack>
            </Box>
            <Box className="dotacje">
                <a href="https://www.palipali.pl/dotacje" target="_blank">
                    <FlagiEU />
                </a>
            </Box>
        </Stack>
    );
};

Nav.propTypes = {
    sections: PropTypes.array,
    subMenuSections: PropTypes.array,
};
