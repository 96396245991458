import type {Theme} from '@mui/material';
import {Box, useMediaQuery} from '@mui/material';
import {IFleetPartnerCourierListingOutput, isAuthTokenExpiredSelector} from 'palipali-panel-common-web';
import type {FC} from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    getFleetPartnerActiveCourierListing,
    setActiveCouriersListInitialized,
} from 'src/store/reducers/fleetPartnerActiveCourierListingSlice';
import {
    fleetPartnerActiveCourierListingSelector,
    isFleetPartnerActiveCourierListingInitializedSelector,
    isFleetPartnerActiveCourierListingLoadingSelector,
} from 'src/store/selectors/fleetPartnerActiveCourierListingSelector';
import ActiveCourierDrawer from './ActiveCourierDrawer';
import ActiveCourierList from './ActiveCourierList';
import ActiveCourierMap from './ActiveCourierMap';
import ActiveCourierToolbar from './ActiveCourierToolbar';

export type Page<T> = T & FC;

const ActiveCouriersListMap: FC = () => {
    const rootRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
    const couriers: IFleetPartnerCourierListingOutput[] = useSelector(fleetPartnerActiveCourierListingSelector);
    const isAuthTokenExpired: boolean = useSelector(isAuthTokenExpiredSelector);
    const isViewInitialized: boolean = useSelector(isFleetPartnerActiveCourierListingInitializedSelector);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [currentCourierId, setCurrentCourierId] = useState<string | undefined>(couriers[0]?.id);
    const isLoading = useSelector(isFleetPartnerActiveCourierListingLoadingSelector);

    const handleCourierSelect = useCallback((courierId: string): void => {
        setCurrentCourierId(courierId);
    }, []);

    useEffect(() => {
        if (!isAuthTokenExpired && !isLoading && !isViewInitialized) {
            dispatch(getFleetPartnerActiveCourierListing());
            dispatch(setActiveCouriersListInitialized(true));
        }
        return () => {
            dispatch(setActiveCouriersListInitialized(false));
        };
    }, [isAuthTokenExpired]);

    const handleCourierDeselect = useCallback((): void => {
        setCurrentCourierId(undefined);
    }, []);

    const handleDrawerOpen = useCallback((): void => {
        setOpenDrawer(true);
    }, []);

    const handleDrawerClose = useCallback((): void => {
        setOpenDrawer(false);
    }, []);

    return (
        <>
            <Box
                component="main"
                ref={rootRef}
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    overflow: 'hidden',
                    position: 'relative',
                }}>
                {mdUp && (
                    <ActiveCourierList
                        currentCourierId={currentCourierId}
                        onCourierDeselect={handleCourierDeselect}
                        onCourierSelect={handleCourierSelect}
                        couriers={couriers}
                    />
                )}
                <Box
                    className="map-container-main"
                    sx={{
                        flex: '1 1 auto',
                        overflow: 'hidden',
                        position: 'relative',
                    }}>
                    {!mdUp && <ActiveCourierToolbar onDrawerOpen={handleDrawerOpen} />}
                    <ActiveCourierMap currentCourierId={currentCourierId} onCourierSelect={handleCourierSelect} couriers={couriers} />
                </Box>
            </Box>
            {!mdUp && (
                <ActiveCourierDrawer container={rootRef.current} onClose={handleDrawerClose} open={openDrawer}>
                    <ActiveCourierList
                        currentCourierId={currentCourierId}
                        onCourierDeselect={handleCourierDeselect}
                        onCourierSelect={handleCourierSelect}
                        couriers={couriers}
                    />
                </ActiveCourierDrawer>
            )}
        </>
    );
};

export default ActiveCouriersListMap;
