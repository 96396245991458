import {SVGProps} from 'react';
import React from 'react';

const BankNote01 = (props: SVGProps<SVGSVGElement>) => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.93301 10.8V14.4M17.733 9V12.6M16.833 4.5C19.0368 4.5 20.2288 4.83728 20.8219 5.0989C20.9009 5.13374 20.9404 5.15116 21.0544 5.25993C21.1227 5.32514 21.2474 5.51645 21.2795 5.60528C21.333 5.75346 21.333 5.83447 21.333 5.99647V15.67C21.333 16.4879 21.333 16.8969 21.2104 17.1071C21.0856 17.3209 20.9653 17.4203 20.7318 17.5025C20.5022 17.5833 20.0388 17.4942 19.112 17.3161C18.4633 17.1915 17.6939 17.1 16.833 17.1C14.133 17.1 11.433 18.9 7.83301 18.9C5.62917 18.9 4.43719 18.5627 3.8441 18.3011C3.76512 18.2663 3.72563 18.2488 3.61165 18.1401C3.54333 18.0749 3.41861 17.8835 3.38653 17.7947C3.33301 17.6465 3.33301 17.5655 3.33301 17.4035L3.33301 7.72997C3.33301 6.91208 3.33301 6.50313 3.45566 6.29293C3.58042 6.07911 3.70074 5.9797 3.93426 5.89753C4.16383 5.81675 4.62724 5.90579 5.55405 6.08386C6.20276 6.20851 6.97214 6.3 7.83301 6.3C10.533 6.3 13.233 4.5 16.833 4.5ZM14.583 11.7C14.583 12.9426 13.5756 13.95 12.333 13.95C11.0904 13.95 10.083 12.9426 10.083 11.7C10.083 10.4574 11.0904 9.45 12.333 9.45C13.5756 9.45 14.583 10.4574 14.583 11.7Z"
            stroke="#009FE3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default BankNote01;
