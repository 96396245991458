import React from 'react';
import {IAuthHeaderProps, UserRole} from '../../../types';
import Translation from '../../Translation';
import {Box} from '@mui/material';
import LogoPurchaser from '../../../theme/icons/logo/logo-purchaser';
import LogoFleetPartner from '../../../theme/icons/logo/logo-fleet-partner';
import LogoCourier from '../../../theme/icons/logo/logo-courier';

type Props = IAuthHeaderProps;

const AuthHeader: React.FC<Props> = ({userRole}) => {
    const getLogo = () => {
        switch (userRole) {
            case UserRole.PURCHASER:
                return <LogoPurchaser />;
            case UserRole.COURIER:
                return <LogoCourier />;
            case UserRole.ROLE_FLEET_PARTNER:
                return <LogoFleetPartner />;
            default:
                return null;
        }
    };
    return (
        <Box
            sx={{
                color: 'text.primary',
                fontFamily: 'Inter, sans-serif',
                fontSize: '1.2rem',
                fontWeight: 600,
                letterSpacing: '0.05rem',
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: 3,
                '& span': {
                    color: 'primary.main',
                },
                marginBottom: '2.4rem',
            }}>
            <Box
                key={'logo-box'}
                sx={{
                    display: 'inline-flex',
                    height: '8.5rem',
                    width: '6rem',
                    marginBottom: '1.2rem',
                    '& img': {
                        maxHeight: '100%',
                        maxWidth: '100%',
                    },
                }}>
                {getLogo()}
            </Box>

            <span key="auth-header-title">
                <Translation text={`app.${userRole === UserRole.COURIER ? 'courierTitle' : 'title'}`} />
            </span>
        </Box>
    );
};

export default AuthHeader;
