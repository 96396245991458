import {Button, useTheme} from '@mui/material';
import L, {latLngBounds, LatLngExpression} from 'leaflet';
import {IFleetPartnerCourierListingOutput, Translation, RefreshIcon} from 'palipali-panel-common-web';
import PropTypes from 'prop-types';
import type {FC} from 'react';
import {useEffect} from 'react';
import {MapContainer, Marker, useMap} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import {useDispatch, useSelector} from 'react-redux';
import {getFleetPartnerActiveCourierListing} from 'src/store/reducers/fleetPartnerActiveCourierListingSlice';
import {isFleetPartnerActiveCourierListingLoadingSelector} from 'src/store/selectors/fleetPartnerActiveCourierListingSelector';
import CustomTileLayer from './CustomTileLayer';

interface IActiveCourierMapProps {
    currentCourierId?: string;
    onCourierSelect?: (courierId: string) => void;
    couriers?: IFleetPartnerCourierListingOutput[];
}

export const ActiveCourierMap: FC<IActiveCourierMapProps> = (props) => {
    const {onCourierSelect, currentCourierId, couriers = []} = props;
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isLoading = useSelector(isFleetPartnerActiveCourierListingLoadingSelector);

    const refreshPosition = () => {
        dispatch(getFleetPartnerActiveCourierListing());
    };

    const SetViewOnClick = ({coords}: {coords: LatLngExpression}) => {
        const map = useMap();

        useEffect(() => {
            if (map) {
                const isPositionAvailable = couriers && couriers.length > 0;
                map.setView(coords, isPositionAvailable ? undefined : 7);

                if (isPositionAvailable) {
                    const markerBounds = latLngBounds([]);

                    couriers.forEach((courier) => {
                        if (!courier.lastPosition) return;
                        markerBounds.extend([courier.lastPosition.latitude, courier.lastPosition.longitude]);
                    });

                    map.fitBounds(markerBounds, {maxZoom: 12});
                }
            }
        }, [map, coords, couriers]);

        return null;
    };

    const getMapCenter = (activeCouriers: IFleetPartnerCourierListingOutput[]): LatLngExpression | undefined => {
        const latitudes: number[] = [];
        const longitudes: number[] = [];

        activeCouriers.forEach((courier) => {
            if (courier.lastPosition) {
                latitudes.push(courier.lastPosition.latitude);
                longitudes.push(courier.lastPosition.longitude);
            }
        });

        if (!latitudes.length || !longitudes.length) {
            return undefined;
        }

        const centerLatitude = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
        const centerLongitude = longitudes.reduce((sum, lon) => sum + lon, 0) / longitudes.length;

        return {
            lat: centerLatitude,
            lng: centerLongitude,
        };
    };
    const mapCenter = getMapCenter(couriers) ? getMapCenter(couriers) : {lat: 52.237049, lng: 19.017782},
        theme = useTheme();

    return (
        <MapContainer center={mapCenter} zoom={7} scrollWheelZoom={true} maxZoom={18} minZoom={2}>
            <SetViewOnClick coords={mapCenter ? mapCenter : [52.237049, 19.017782]} />
            <CustomTileLayer />
            <>
                <Button
                    className="refresh-position-button"
                    variant="contained"
                    color="primary"
                    onClick={() => refreshPosition()}
                    disabled={isLoading}
                    startIcon={<RefreshIcon />}>
                    <Translation text="couriers.buttons.refresh" />
                </Button>
                <MarkerClusterGroup chunkedLoading>
                    {couriers.map((courier) => {
                        if (!courier.lastPosition) return;

                        const isActive = courier.status === 'active',
                            isSelected = currentCourierId === courier.id,
                            shadowColor = isActive ? theme.palette.primary.main : theme.palette.secondary.main,
                            styleDropColor = `filter: drop-shadow(0px 0px 8px ${shadowColor})`,
                            customMarker = L.divIcon({
                                className: `custom-courier-marker ${isActive ? 'active' : ''} ${isSelected ? 'selected' : ''}`,
                                html: `<img class="marker-icon-image" style="${
                                    isSelected ? styleDropColor : ''
                                }" src="${require('../../../../assets/images/car-marker-icon-default.png')}" alt="Car Marker" />`,
                                iconSize: [50, 50],
                                iconAnchor: [25, 10],
                            });
                        return (
                            <Marker
                                key={courier.id}
                                position={courier.lastPosition ? [courier.lastPosition?.latitude, courier.lastPosition?.longitude] : [0, 0]}
                                icon={customMarker}
                                eventHandlers={{
                                    click: () => onCourierSelect?.(courier.id),
                                }}
                            />
                        );
                    })}
                </MarkerClusterGroup>
            </>
        </MapContainer>
    );
};

export default ActiveCourierMap;

ActiveCourierMap.propTypes = {
    currentCourierId: PropTypes.string,
    onCourierSelect: PropTypes.func,
    couriers: PropTypes.array,
};
