import {Observable} from 'rxjs';
import {IApiResponseBase, IFleetPartnerCourierListingOutput, IRawRestQueryParams} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export function getAssignedCouriersListAPI(
    authToken: string,
    payload?: {assignedCourierListVehicleId: string},
    params?: IRawRestQueryParams | []
): Observable<IApiResponseBase<IFleetPartnerCourierListingOutput[]>> {
    return palipaliAPI.get(
        `api/vehicles/${payload.assignedCourierListVehicleId}/assigned_fleet_partner_couriers`,
        new RestQueryParams(params),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );
}
