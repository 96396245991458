import React, {useState, PropsWithChildren} from 'react';
import {Tooltip} from 'reactstrap';
import {ITooltipProps, TooltipPlacement} from '../../types';

type Props = PropsWithChildren<ITooltipProps>;

const TooltipComponent: React.FC<Props> = ({target, tooltipText, placement, innerClassName}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Tooltip
            placement={placement ? placement : TooltipPlacement.TOP}
            innerClassName={innerClassName}
            isOpen={isOpen}
            target={target}
            toggle={() => setIsOpen(!isOpen)}>
            {tooltipText}
        </Tooltip>
    );
};

export default TooltipComponent;
