import React from 'react';
import Radio from '../../Radio';
import Checkbox from '../../Checkbox';
import {ICalendarTimeSlotsProps, ICalendarTimeSlotsState, TimeSlotsType} from '../../../../types';
import {isNotNullOrUndefined} from '../../../../utils/runtimeUtils';
import Translation from '../../../Translation';

type Props = ICalendarTimeSlotsProps;
type State = ICalendarTimeSlotsState;

class CalendarTimeSlots extends React.Component<Props, State> {
    render() {
        return (
            <div
                className={
                    this.props.timeSlotsType && this.props.timeSlotsType === TimeSlotsType.WEEK_CALENDAR
                        ? 'time-slots-container'
                        : 'slots-container'
                }>
                {this.props.isLabelHidden ? null : (
                    <h2 className="slots-label">
                        <Translation text={'videoChat.settings.select'} />
                    </h2>
                )}
                <div className="slots">{this.renderTimeControls()}</div>
            </div>
        );
    }

    private renderTimeControls = () => {
        let timeSlots = [];
        if (this.props.expandedSlotsShown !== undefined && this.props.expandedSlotsShown === false) {
            timeSlots = this.props.availableTimeSlots.slice(
                0,
                isNotNullOrUndefined(this.props.slotsNumberDisplayed) ? this.props.slotsNumberDisplayed : 4
            );
        } else {
            timeSlots = this.props.availableTimeSlots;
        }

        if (this.props.checkboxSlots) {
            return timeSlots.map((timeSlot) => (
                <Checkbox
                    name={'timeSlot'}
                    key={timeSlot.value}
                    label={timeSlot.displayValue}
                    value={timeSlot.value}
                    isLabelTranslated={false}
                    additionalStyles={timeSlot.isFree ? 'checked' : 'unavailable'}
                    handleChange={(e: any) => this.onCheckboxChange(e)}
                    checked={!timeSlot.is_free}
                />
            ));
        }
        return (
            <Radio
                name="timeSlot"
                placeholder={this.props.placeholder}
                value={this.props.selectedDate}
                handleChange={(e: any) => this.onRadioChange(e)}
                options={timeSlots}
            />
        );
    };

    private onRadioChange = (e: any) => {
        if (e) {
            const eventData = {
                target: {
                    value: {
                        name: 'selectedDate',
                        value: e.target.value,
                    },
                },
            };
            this.setState({selectedDate: e.target.value});
            this.props.onChange(eventData);
        }
    };

    private onCheckboxChange = (e: any) => {
        if (e) {
            const timeFromState = this.props.availableTimeSlots.find((timeSlot) => timeSlot.value === e.target.value);
            const eventData = {
                target: {
                    value: {
                        name: 'checkboxSelectedDate',
                        value: new Date(e.target.value),
                        endsAt: timeFromState.endsAt,
                    },
                },
            };

            this.setState({selectedDate: e.target.value});
            this.props.onChange(eventData);
        }
    };
}

export default CalendarTimeSlots;
