import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {IAssignPackageSizeToDimensionsInput, IPackageSizesOutput} from '../../model/common';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';

export const updateAssignPackageSizeToDimensionsAPI: ApiOperationResponseFunction<IPackageSizesOutput> = (
    authToken: string,
    payload: {packageDimensions: IAssignPackageSizeToDimensionsInput}
): Observable<IApiSingleResponseBase<IPackageSizesOutput>> => {
    return palipaliAPI.put('api/package_sizes/assign_package_size_to_dimensions', payload.packageDimensions, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
