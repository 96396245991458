import {Observable} from 'rxjs';
import {IInspectionInput} from '../../model/inspection';
import {IVehicleDetailsOutput} from '../../model/vehicle';
import {IApiSingleResponseBase} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export const updateVehicleInspectionAPI: ApiOperationResponseFunction<IVehicleDetailsOutput> = (
    authToken: string,
    payload: {vehicleInspection: IInspectionInput; id: string}
): Observable<IApiSingleResponseBase<IVehicleDetailsOutput>> => {
    const updatePayload: IInspectionInput = payload.vehicleInspection,
        id: string = payload.id;
    return palipaliAPI.put(`api/vehicles/${id}/inspection`, updatePayload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
