import {Box, Button, Grid, Stack, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, Translation, AlertTriangle} from 'palipali-panel-common-web';
import {FC} from 'react';

interface DeleteAccountDialogProps {
    handleConfirm: () => void;
}

const DeleteProfileModal: FC<DeleteAccountDialogProps> = ({handleConfirm}) => {
    const theme = useTheme();

    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`account.deleteProfile.title`} />
                </Typography>
            }
            content={
                <Grid
                    sx={{
                        width: '100%',
                        maxWidth: '48rem',
                    }}>
                    <Stack className="description delete-account">
                        <AlertTriangle className="triangle" />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <Typography variant="body2" component="p" sx={{textAlign: 'center'}}>
                                <Translation text="account.deleteProfile.subtitle" />
                            </Typography>
                            <Typography variant="body2" component="p" sx={{color: 'error.dark'}}>
                                <Translation text="account.deleteProfile.warning" />
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
            }
            footer={
                <Button
                    className="account-delete-account-delete-modal-submit-button"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => handleConfirm()}>
                    <Translation text={`account.deleteProfile.buttons.deleteAccount`} />
                </Button>
            }
        />
    );
};

export default DeleteProfileModal;
