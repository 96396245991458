import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {UserRole} from '../../types';
import ConfirmRegistration from './ConfirmRegistration';
import ConfirmCourierEmailChange from './CourierEmailChange';
import CourierSuccessPage from './CourierSuccessPage';
import NewPassword from './NewPassword';
import ResetPassword from './ResetPassword';

export const courierRoutes = (envData: any, isLoading: boolean, theme: any) => [
    <Routes key="courier-routes">
        <Route
            key="courier-reset-password-route"
            path="/reset-password"
            element={
                <ResetPassword
                    userRole={UserRole.COURIER}
                    envData={envData}
                    isLoading={isLoading}
                    theme={theme}
                    key="courier-reset-password"
                />
            }
        />

        <Route
            key="courier-new-password-route"
            path="/new-password/:id"
            element={<NewPassword isLoading={isLoading} theme={theme} userRole={UserRole.COURIER} key="courier-new-password" />}
        />

        <Route
            key="confirm-courier-registration-route"
            path="/confirm-registration/:id"
            element={<ConfirmRegistration isLoading={isLoading} userRole={UserRole.COURIER} key="confirm-courier-registration" />}
        />

        <Route
            key="confirm-courier-email-route"
            path="/confirm-email/:id"
            element={<ConfirmCourierEmailChange isLoading={isLoading} key="confirm-courier-email" />}
        />

        <Route
            key="invite-fleet-partner-success-route"
            path="/confirm-invitation/:id"
            element={<CourierSuccessPage key="invite-fleet-partner-success" invitationToFleetPartner={true} isLoading={isLoading} />}
        />

        <Route
            key="courier-success-page-route"
            path="/success"
            element={<CourierSuccessPage key="courier-success-page" isLoading={isLoading} />}
        />

        <Route key="courier-not-found-route" path="*" element={<Navigate to="/auth/login" replace />} />
    </Routes>,
];
