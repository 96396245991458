import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {ICourierSettlementListingOutput} from '../../model/settlements';
import {isNullOrUndefined} from '../../utils/runtimeUtils';

export function getCourierSettlementCurrentAPI(
    authToken: string
): Observable<IApiSingleResponseBase<ICourierSettlementListingOutput | null>> {
    return palipaliAPI
        .get(`api/courier_settlements/current`, new RestQueryParams(), {
            Authorization: `Bearer ${authToken}`,
        })
        .pipe(
            map((response) => {
                return isNullOrUndefined(response) ||
                    isNullOrUndefined(response['hydra:member']) ||
                    isNullOrUndefined(response['hydra:member'][0])
                    ? null
                    : response['hydra:member'][0];
            })
        );
}
