import type {PaperProps, PopoverOrigin} from '@mui/material';
import {Popover} from '@mui/material';
import PropTypes from 'prop-types';
import type {FC, ReactNode} from 'react';
import React, {useContext} from 'react';
import DropdownContext from '../DropdownContext';

interface DropdownMenuProps {
    anchorEl?: HTMLElement | null;
    anchorOrigin?: PopoverOrigin;
    children?: ReactNode;
    disableScrollLock?: boolean;
    PaperProps?: PaperProps;
    transformOrigin?: PopoverOrigin;
}

const DropdownMenu: FC<DropdownMenuProps> = (props) => {
    const {anchorEl, children, PaperProps, ...other} = props;
    const ctx = useContext(DropdownContext);

    return (
        <Popover
            anchorEl={anchorEl || ctx.anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            open={ctx.open}
            PaperProps={{
                ...PaperProps,
                onMouseEnter: ctx.onMenuEnter,
                onMouseLeave: ctx.onMenuLeave,
                sx: {
                    ...PaperProps?.sx,
                    pointerEvents: 'auto',
                },
            }}
            sx={{pointerEvents: 'none'}}
            transformOrigin={{
                horizontal: 'left',
                vertical: 'top',
            }}
            {...other}>
            {children}
        </Popover>
    );
};

DropdownMenu.propTypes = {
    anchorEl: PropTypes.any,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    anchorOrigin: PropTypes.object,
    children: PropTypes.any,
    disableScrollLock: PropTypes.bool,
    PaperProps: PropTypes.object,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transformOrigin: PropTypes.object,
};

export default DropdownMenu;
