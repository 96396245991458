import {Divider, Stack, Typography} from '@mui/material';
import React from 'react';
import {IPurchaserCurrentJobDetailsOutput} from '../../../types';
import Translation from '../../Translation';

interface IJobViewAdditionalInfoProps {
    job: IPurchaserCurrentJobDetailsOutput;
    titleKey: string;
}

const JobViewAdditionalInfo: React.FC<IJobViewAdditionalInfoProps> = (props: IJobViewAdditionalInfoProps) => {
    const description: string = props.job.description ? props.job?.description : '-';

    return (
        <Stack>
            <Typography className="additional-info" variant="subtitle1" color="text.primary">
                <Translation text={props.titleKey} />
            </Typography>
            <Divider />
            <Typography className="description-text">{description}</Typography>
        </Stack>
    );
};

export default JobViewAdditionalInfo;
