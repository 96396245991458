import {Grid, Stack} from '@mui/material';
import React from 'react';
import ChangePasswordCard from './ChangePasswordCard';
import ProfileDeleteCard from './ProfileDeleteCard';
import ProfileCard from './ProfileCard';

const ProfileTab: React.FC = () => {
    return (
        <Grid sx={{display: 'flex', flexDirection: 'column'}}>
            <Stack spacing={5}>
                <ProfileCard />
                <ChangePasswordCard />
                <ProfileDeleteCard />
            </Stack>
        </Grid>
    );
};

export default ProfileTab;
