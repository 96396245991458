import {Observable} from 'rxjs';
import {IApiSingleResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';
import {IAccountBasicInfoOutput} from '../../model/account';

export function updateAccountMiscAPI(
    authToken: string | null,
    accountId: string,
    misc: any
): Observable<IApiSingleResponseBase<IAccountBasicInfoOutput>> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return palipaliAPI.put(`api/accounts/${accountId}/merge_misc`, {misc: misc}, new RestQueryParams(), headers);
}
