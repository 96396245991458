import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './baseSlice';
import {ISettlementPeriodOutput} from '../../types';

export interface ISettlementPeriodSliceState extends IBaseDictionaryState {
    readonly settlementPeriods: ISettlementPeriodOutput[] | null;
}

export interface ISetSettlementPeriod {
    readonly settlementPeriods: ISettlementPeriodOutput[] | null;
}

const initialState: ISettlementPeriodSliceState = {
    settlementPeriods: null,
    isInitialized: false,
    isLoading: false,
    error: null,
};

const settlementPeriodSlice = createSlice({
    name: 'settlementPeriod',
    initialState: initialState,
    reducers: {
        fetchSettlementPeriod: (state: ISettlementPeriodSliceState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        setSettlementPeriod: {
            reducer: (state: ISettlementPeriodSliceState, action: PayloadAction<ISetSettlementPeriod>) => {
                return {
                    ...state,
                    settlementPeriods: action.payload.settlementPeriods,
                };
            },
            prepare: (settlementPeriods: ISettlementPeriodOutput[] | null) => {
                return {
                    payload: {
                        settlementPeriods: settlementPeriods,
                    },
                };
            },
        },
        changeIsSettlementPeriodLoading: {
            reducer: (state: ISettlementPeriodSliceState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare: (isLoading: boolean) => {
                return {
                    payload: {
                        isLoading: isLoading,
                    },
                };
            },
        },
        changeIsSettlementPeriodInitialized: {
            reducer: (state: ISettlementPeriodSliceState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare: (isInitialized: boolean) => {
                return {
                    payload: {
                        isInitialized: isInitialized,
                    },
                };
            },
        },
        setSettlementPeriodError: {
            reducer: (state: ISettlementPeriodSliceState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare: (error: string | null) => {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
    },
});

export const {
    fetchSettlementPeriod,
    changeIsSettlementPeriodInitialized,
    changeIsSettlementPeriodLoading,
    setSettlementPeriod,
    setSettlementPeriodError,
} = settlementPeriodSlice.actions;
export default settlementPeriodSlice.reducer;
