import {Button, Unstable_Grid2 as Grid, Stack, Typography, useTheme} from '@mui/material';
import {
    CustomModalComponent,
    FormikForm,
    IAccountMeOutput,
    Loader,
    LoaderType,
    Translation,
    accountSelector,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {inviteCourier} from 'src/store/reducers/courierListingSlice';
import {courierLoadingSelector} from 'src/store/selectors/courierListingSelector';
import {courierInviteFormConfig} from './courierInviteFormConfig';

const CourierInviteModal: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch(),
        isLoading = useSelector(courierLoadingSelector),
        [submitAllowed, setSubmitAllowed] = React.useState<boolean>(false),
        account: IAccountMeOutput = useSelector(accountSelector);

    const startValues = {
        email: '',
    };

    return (
        <CustomModalComponent
            theme={theme}
            customClasses="invite-courier-modal-wrapper"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`couriers.inviteCourier.title`} />
                </Typography>
            }
            content={
                <Grid sx={{width: '100%'}}>
                    <Stack sx={{gap: '1.6rem'}}>
                        <Stack className="description invite-courier-modal">
                            <Typography color="text.secondary" variant="body1" className="invite-description">
                                <Translation text="couriers.inviteCourier.description" />
                            </Typography>
                            <Typography color="text.secondary" variant="body1" className="invite-description">
                                <Translation text="couriers.inviteCourier.note" />
                            </Typography>
                            <Typography color="text.secondary" variant="body1">
                                <Translation text="couriers.inviteCourier.helper" />
                            </Typography>
                            <Typography color="text.primary" variant="body1">
                                <Translation text="couriers.inviteCourier.helperRoad" />
                            </Typography>
                        </Stack>
                        <FormikForm
                            fields={courierInviteFormConfig()}
                            formId="courier-invite"
                            initialValues={startValues}
                            submitAllowed={setSubmitAllowed}
                            onSubmit={(values) => {
                                const payload = {
                                    email: values.email,
                                    returnUrl: `${process.env.REACT_APP_URL}/couriers/confirm-invitation`,
                                };
                                dispatch(inviteCourier(account?.completedAccounts?.fleetPartner?.id, payload));
                            }}
                            theme={theme}
                        />
                    </Stack>
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Grid>
            }
            footer={
                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    form="courier-invite"
                    disabled={!submitAllowed}
                    className="courier-invite-courier-modal-submit-button">
                    <Translation text="couriers.inviteCourier.button" />
                </Button>
            }
        />
    );
};
export default CourierInviteModal;
