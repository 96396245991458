import React from 'react';
import {CustomIconProps} from '../../../types/iconTypes';

const DefaultAvatar = (props: CustomIconProps) => (
    <svg width={props.size ? props.size : 48} height={props.size ? props.size : 48} viewBox="0 0 48 48" fill="none" {...props}>
        <rect width={props.size ? props.size : 48} height={props.size ? props.size : 48} rx="8" fill="currentColor" fillOpacity="0.08" />
        <path
            d="M16.8 32.8357C17.3423 33 18.0748 33 19.32 33H28.68C29.9252 33 30.6577 33 31.2 32.8357M16.8 32.8357C16.6837 32.8005 16.5762 32.7577 16.4742 32.7057C15.9662 32.4469 15.5531 32.0338 15.2943 31.5258C15 30.9482 15 30.1921 15 28.68V19.32C15 17.8079 15 17.0518 15.2943 16.4742C15.5531 15.9662 15.9662 15.5531 16.4742 15.2943C17.0518 15 17.8079 15 19.32 15H28.68C30.1921 15 30.9482 15 31.5258 15.2943C32.0338 15.5531 32.4469 15.9662 32.7057 16.4742C33 17.0518 33 17.8079 33 19.32V28.68C33 30.1921 33 30.9482 32.7057 31.5258C32.4469 32.0338 32.0338 32.4469 31.5258 32.7057C31.4238 32.7577 31.3163 32.8005 31.2 32.8357M16.8 32.8357C16.8003 32.1073 16.8047 31.7219 16.8692 31.3977C17.1532 29.9696 18.2696 28.8532 19.6977 28.5692C20.0454 28.5 20.4636 28.5 21.3 28.5H26.7C27.5364 28.5 27.9546 28.5 28.3023 28.5692C29.7304 28.8532 30.8468 29.9696 31.1308 31.3977C31.1953 31.7219 31.1997 32.1073 31.2 32.8357M27.6 21.75C27.6 23.7382 25.9882 25.35 24 25.35C22.0118 25.35 20.4 23.7382 20.4 21.75C20.4 19.7618 22.0118 18.15 24 18.15C25.9882 18.15 27.6 19.7618 27.6 21.75Z"
            stroke="currentColor"
            strokeOpacity="0.16"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default DefaultAvatar;
