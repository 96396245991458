import type {SVGProps} from 'react';
import React from 'react';

const ChevronRightOutlinedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
        <path d="M9.70492 6.5L8.29492 7.91L12.8749 12.5L8.29492 17.09L9.70492 18.5L15.7049 12.5L9.70492 6.5Z" fill="#6C737F" />
    </svg>
);

export default ChevronRightOutlinedIcon;
