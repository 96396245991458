import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export function createSetNewPasswordAPI(authToken: string, plainPassword: string): Observable<any> {
    return palipaliAPI.post(
        `api/users/set_new_password`,
        {
            password: plainPassword,
        },
        new RestQueryParams(),
        {
            'X-Authentication-Token': authToken,
        }
    );
}
