import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase, IRawRestQueryParams} from '../../types';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {IBillingInfoOutput} from '../../model/billingInfo';

export const getBillingInfoAPI: ApiOperationResponseFunction<IBillingInfoOutput> = (
    authToken: string,
    payload: {id: string}
): Observable<IApiSingleResponseBase<IBillingInfoOutput>> => {
    const id = payload.id;
    return palipaliAPI.get(`api/fleet_partners/${id}/billing_info`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
