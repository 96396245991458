import type {SVGProps} from 'react';
import React from 'react';

const ChevronLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" {...props}>
        <g id="MuiChevronLeftFilled">
            <path id="Vector" d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z" fill="#6C737F" />
        </g>
    </svg>
);

export default ChevronLeftIcon;
