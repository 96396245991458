import {PayloadAction} from '@reduxjs/toolkit';
import {
    IBaseReducerState,
    IFleetPartnerCourierListingOutput,
    IModelApiResponseViewObject,
    createBaseReducerSlice,
} from 'palipali-panel-common-web';

export interface IFleetPartnerActiveCourierListingState extends IBaseReducerState {
    activeCourierList: IFleetPartnerCourierListingOutput[] | null;
    metadata: IModelApiResponseViewObject | null;
    activeCourierFilters: IActiveCourierFilters | null;
}

export interface ISetFleetPartnerActiveCourierListing {
    activeCourierList: IFleetPartnerCourierListingOutput[] | null;
}

export interface ISetFleetPartnerActiveCourierListVehicleId {
    activeCourierListVehicleId: string | null;
}

const initialState: IFleetPartnerActiveCourierListingState = {
    activeCourierList: [],
    metadata: null,
    activeCourierFilters: {
        'courier.online': true,
    },
    isLoading: false,
    isInitialized: false,
    error: null,
};

export interface IActiveCourierFilters {
    'courier.online': boolean;
}

export interface IPaginationParams {
    itemsPerPage: number;
    page: number;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

const fleetPartnerActiveCourierListingSlice = createBaseReducerSlice({
    name: 'fleetPartnerActiveCourierListing',
    initialState,
    reducers: {
        setFleetPartnerActiveCourierListing: (
            state: IFleetPartnerActiveCourierListingState,
            action: PayloadAction<ISetFleetPartnerActiveCourierListing>
        ) => {
            state.activeCourierList = action.payload.activeCourierList;
        },
        setMetadata: (state: IFleetPartnerActiveCourierListingState, action: PayloadAction<ISetMetadata>) => {
            state.metadata = action.payload.metadata;
        },
        setActiveCouriersListInitialized: (state: IFleetPartnerActiveCourierListingState, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload;
        },
        setLoading: (state: IFleetPartnerActiveCourierListingState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state: IFleetPartnerActiveCourierListingState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        resetToInitialState: () => {
            return {...initialState};
        },
        getFleetPartnerActiveCourierListing: (state: IFleetPartnerActiveCourierListingState) => {
            state.isLoading = true;
        },
    },
});

export const {
    setFleetPartnerActiveCourierListing,
    setMetadata,
    getFleetPartnerActiveCourierListing,
    setLoading,
    setError,
    setActiveCouriersListInitialized,
    resetToInitialState,
} = fleetPartnerActiveCourierListingSlice.actions;
export default fleetPartnerActiveCourierListingSlice.reducer;
