import React, {ReactElement} from 'react';
import {ButtonBase, Box} from '@mui/material';
import Translation from '../../Translation';
import PhoneComponent from '../../PhoneComponent';
import PhoneHandle from '../../../theme/icons/untitled-ui/duocolor/phone-handle';
import ChevronRight from '../../../theme/icons/untitled-ui/duocolor/chevron-right';

interface IJobViewDetailsListButtonProps {
    itemClass?: string;
    label: string;
    labelClass?: string;
    value?: ReactElement<typeof PhoneComponent> | string | null;
    valueClass?: string;
    onClick: () => void;
    isPhoneBtn?: boolean;
}

const JobViewDetailsListButton: React.FC<IJobViewDetailsListButtonProps> = (props: IJobViewDetailsListButtonProps) => {
    const itemClasses: string = props.itemClass ? ` ${props.itemClass}` : ``,
        labelClasses: string = props.labelClass ? ` ${props.labelClass}` : ``;

    const renderValue = () => {
        if (!props.value) return;

        return <span>{props.value}</span>;
    };

    return (
        <ButtonBase className={`job-details-list-item item-button${itemClasses}`} onClick={() => props.onClick()}>
            <dt className={`item-label${labelClasses}`}>
                <Translation text={props.label} />
            </dt>
            <dd className="item-value">
                {renderValue()}
                {props.isPhoneBtn ? (
                    <Box sx={{paddingLeft: '0.5rem'}}>
                        <PhoneHandle />
                    </Box>
                ) : (
                    <ChevronRight size={24} />
                )}
            </dd>
        </ButtonBase>
    );
};

export default JobViewDetailsListButton;
