import {Observable} from 'rxjs';
import {palipaliAPI} from '../provider/palipaliAPI';
import {RestQueryParams} from '../base/queryParams';
import {IVehicleListingOutput} from '../../model/vehicle';
import {IApiResponseBase, IRawRestQueryParams} from '../../types';
import {ApiResponseFunction} from '../../api/base/apiConnectionInfrastructure';

export const getVehicleMineListAPI: ApiResponseFunction<IVehicleListingOutput> = (
    authToken: string,
    payload?: {courierId?: string},
    params?: IRawRestQueryParams | []
): Observable<IApiResponseBase<IVehicleListingOutput[]>> => {
    return palipaliAPI.get('api/vehicles/list_mine', new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
        'X-Unassign-Target-Fleet-Partner-Courier-Id': payload?.courierId || null,
    });
};
