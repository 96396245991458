import {JobStatusDetailsBadge} from 'palipali-panel-common-web';
import React from 'react';

interface IDeliveryStatusInfoProps {
    status: string;
    packageSize: 's' | 'l' | 'm';
}

const DeliveryStatusInfo: React.FC<IDeliveryStatusInfoProps> = ({status, packageSize}) => {
    const getPackageSizeIcon = (size: string) => {
        return (
            <span
                key={`item_${size}`}
                className={`package-size-icon active-couriers ${size === 's' ? 'small' : size === 'm' ? 'medium' : 'large'}`}>
                {size}
            </span>
        );
    };
    return (
        <>
            <JobStatusDetailsBadge status={status} />
            {getPackageSizeIcon(packageSize)}
        </>
    );
};

export default DeliveryStatusInfo;
