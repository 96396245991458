import {Box, Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {useFormik} from 'formik';
import merge from 'lodash/merge';
import moment from 'moment';
import {
    CustomTablePagination,
    DateRangePickerControl,
    FormikFieldTypes,
    ICourierJobReportListingEntry,
    IReportsFilters,
    IServerReportsFilters,
    Loader,
    LoaderType,
    NoDataInfo,
    PAGINATION_ITEMS_PER_PAGE,
    Translation,
    calculateItemIndex,
    formatEndDate,
    formatStartDate,
    FilterIcon,
    isAuthTokenExpiredSelector,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getReportsListing} from '../../../store/reducers/reportsSlice';
import {
    reportsFiltersSelector,
    reportsListingSelector,
    reportsLoadingSelector,
    reportsPageSelector,
    reportsTotalNumberOfResultsSelector,
} from '../../../store/selectors/reportsSelectors';
import Filters from './Filters';
import ReportListRow from './ReportListRow';

const ReportsList: React.FC = () => {
    const page = useSelector(reportsPageSelector);
    const totalResults = useSelector(reportsTotalNumberOfResultsSelector);
    const reportsList = useSelector(reportsListingSelector);
    const filters = useSelector(reportsFiltersSelector);
    const dispatch = useDispatch();
    const [showFiltersTab, setShowFiltersTab] = React.useState(false);
    const isLoading = useSelector(reportsLoadingSelector);
    const isAuthTokenExpired: boolean = useSelector(isAuthTokenExpiredSelector);

    const initialValues: IReportsFilters = merge(
        {},
        {
            vehicleId: [],
            courierId: [],
            cityId: [],
            finishedAt: '',
            startedAt: '',
        },
        filters
    );

    const formik = useFormik({
        initialValues,
        onSubmit: () => {
            console.log('submit');
        },
    });

    const changeReportFiltersToServerReportFilters = (values: IReportsFilters): IServerReportsFilters => {
        return {
            'fleetPartnerVehicle[]': values.fleetPartnerVehicle,
            'courier.id[]': values.courierId,
            'city.id[]': values.cityId,
            'finishedAt[before]': values.finishedAt,
            'startedAt[after]': values.startedAt,
            courierVehicle: values.courierVehicle,
        };
    };
    const toggleFiltersTab = () => setShowFiltersTab((show) => !show);

    const handleFieldChange = (field: keyof IReportsFilters, value: unknown) => {
        const reportFiltersValues = {
            ...filters,
            [field]: value,
        };
        if (
            (Array.isArray(value) && value.length === 0 && Array.isArray(filters[field]) && (filters[field] as [])?.length === 0) ||
            value === filters[field]
        ) {
            return;
        }
        dispatch(getReportsListing(changeReportFiltersToServerReportFilters(reportFiltersValues), 1));
    };

    const handleDateChange = (dateRange: {startDate: Date; endDate: Date}) => {
        const formattedStartDate = formatStartDate(dateRange.startDate);
        const formattedEndDate = formatEndDate(dateRange.endDate);
        const reportFiltersValues = {
            ...filters,
            finishedAt: formattedEndDate,
            startedAt: formattedStartDate,
        };
        formik.setValues(reportFiltersValues);
        dispatch(getReportsListing(changeReportFiltersToServerReportFilters(reportFiltersValues), 1));
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPageIndex: number) =>
        dispatch(getReportsListing(changeReportFiltersToServerReportFilters(filters), newPageIndex + 1));

    useEffect(() => {
        if (!isAuthTokenExpired) {
            dispatch(getReportsListing(changeReportFiltersToServerReportFilters(filters), 1));
        }
    }, [isAuthTokenExpired]);

    const renderTableBody = () => {
        if (isLoading) return;

        if (!reportsList || reportsList.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={9} align={'center'}>
                        <NoDataInfo text={'reports.reportsList.noDataInfo'} />
                    </TableCell>
                </TableRow>
            );
        }

        return reportsList.map((item: ICourierJobReportListingEntry, index: number) => (
            <ReportListRow key={item.id} index={calculateItemIndex(page, PAGINATION_ITEMS_PER_PAGE, index)} item={item} />
        ));
    };

    return (
        <Box>
            <Grid container spacing={4}>
                {showFiltersTab ? <Filters setFieldValue={handleFieldChange} /> : null}
                <Grid item xs={12} md={showFiltersTab ? 9 : 12}>
                    <Card className="custom-card">
                        <Box className="filters-wrapper">
                            <Box className="tab-filters"></Box>
                            <Box className="input-filters" sx={{alignItems: 'center'}}>
                                <Button
                                    sx={{mr: 2}}
                                    size={'large'}
                                    color={showFiltersTab ? 'primary' : 'inherit'}
                                    onClick={toggleFiltersTab}
                                    className="reports-report-list-filters-button"
                                    startIcon={<FilterIcon size={20} />}>
                                    <Translation text="reports.reportsList.headers.filters" />
                                </Button>
                                <DateRangePickerControl
                                    initialDates={{
                                        startDate: initialValues.startedAt ? moment(initialValues.startedAt).toDate() : undefined,
                                        endDate: initialValues.finishedAt ? moment(initialValues.finishedAt).toDate() : undefined,
                                    }}
                                    dateFormat={'DD.MM.YYYY'}
                                    label="settlements.date"
                                    name="until"
                                    className="reports-report-list-date-range-picker"
                                    formik={formik}
                                    type={FormikFieldTypes.DATERANGE}
                                    validation={''}
                                    customHandleChange={(dateRange) => handleDateChange(dateRange)}
                                />
                            </Box>
                        </Box>
                        <TableContainer>
                            <Table className="custom-list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Box sx={{paddingLeft: '4.3rem'}}>
                                                <Translation text="reports.reportsList.headers.courier" />
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Translation text="reports.reportsList.headers.city" />
                                        </TableCell>
                                        <TableCell>
                                            <Translation text="reports.reportsList.headers.jobsDone" />
                                        </TableCell>
                                        <TableCell>
                                            <Translation text="reports.reportsList.headers.jobsReported" />
                                        </TableCell>
                                        <TableCell>
                                            <Translation text="reports.reportsList.headers.jobsCanceled" />
                                        </TableCell>
                                        <TableCell>
                                            <Translation text="reports.reportsList.headers.time" />
                                        </TableCell>
                                        <TableCell>
                                            <Translation text="reports.reportsList.headers.distance" />
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Translation text="reports.reportsList.headers.income" />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderTableBody()}</TableBody>
                            </Table>
                        </TableContainer>
                        {reportsList.length > 0 && (
                            <CustomTablePagination
                                metadata={{totalItems: totalResults}}
                                onChangePage={(event, pageNumber) => {
                                    handlePageChange(event, pageNumber);
                                }}
                                page={page}
                                rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
                            />
                        )}
                        <Loader showLoader={isLoading} type={LoaderType.Local} />
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};
export default ReportsList;
