import {Observable} from 'rxjs';
import {IVehicleDefinitionOutput} from '../../model/vehicleDefinition';
import {IApiResponseBase} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {palipaliAPI} from '../provider/palipaliAPI';

export function getVehicleDefinitionsAPI(authToken: string): Observable<IApiResponseBase<IVehicleDefinitionOutput[]>> {
    return palipaliAPI.get('api/dictionary/vehicle_definitions', new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
