import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../reducers';
import jwtDecode from 'jwt-decode';
import {ITeamListingOutput} from '../../model/team';
import {authTokenSelector} from './authSelectors';
import {IAuthToken, UserRole} from '../../types';

export const selectTeamListing = (state: CommonRootState) => {
    return state.teamListing;
};

export const teamListingSelector = createSelector([selectTeamListing], (state: any) => state.teamList);
export const isTeamListingLoadingSelector = createSelector([selectTeamListing], (state: any) => state.isLoading);
export const teamByIdSelector = createSelector([teamListingSelector, (state, id) => id], (teams, id) => {
    return teams?.find((team: ITeamListingOutput) => team.id === id);
});
export const createJobTeamListingSelector = createSelector([teamListingSelector, authTokenSelector], (teamListing, authToken) => {
    if (authToken && teamListing) {
        const decodedToken = jwtDecode<IAuthToken>(authToken),
            accountTeams = decodedToken.teams;
        return teamListing.filter((team: ITeamListingOutput) => {
            const roleId = team?.id,
                role = accountTeams?.[roleId];
            return (role === UserRole.PURCHASER_TEAM_OWNER || role === UserRole.PURCHASER_TEAM_MANAGER) && team.verificationStatus.active;
        });
    }

    return [];
});

export const activeValidTeamListingSelector = createSelector([teamListingSelector, authTokenSelector], (teamListing, authToken) => {
    if (authToken && teamListing) {
        const decodedToken = jwtDecode<IAuthToken>(authToken),
            accountTeams = decodedToken.teams;
        return teamListing.filter((team: ITeamListingOutput) => {
            const roleId = team?.id,
                role = accountTeams?.[roleId];
            return (
                (role === UserRole.PURCHASER_TEAM_OWNER ||
                    role === UserRole.PURCHASER_TEAM_MANAGER ||
                    role === UserRole.PURCHASER_TEAM_EMPLOYEE) &&
                team.verificationStatus.active &&
                team.verificationStatus.billingInfo &&
                team.verificationStatus.paymentMethod
            );
        });
    }

    return [];
});

export const locationsTeamListingSelector = createSelector([teamListingSelector, authTokenSelector], (teamListing, authToken) => {
    if (authToken && teamListing) {
        const decodedToken = jwtDecode<IAuthToken>(authToken),
            accountTeams = decodedToken.teams;

        return teamListing.filter((team: ITeamListingOutput) => {
            const roleId = team?.id,
                role = accountTeams?.[roleId];
            return role === UserRole.PURCHASER_TEAM_OWNER || role === UserRole.PURCHASER_TEAM_MANAGER;
        });
    }

    return [];
});
