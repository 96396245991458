import type {SVGProps} from 'react';
import React from 'react';

const PinIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
        <rect width="36" height="36" rx="8" fill="#F2F4F7" />
        <path
            d="M21 18.2808C23.6489 18.8016 25.5 19.991 25.5 21.375C25.5 23.239 22.1421 24.75 18 24.75C13.8579 24.75 10.5 23.239 10.5 21.375C10.5 19.991 12.3511 18.8016 15 18.2808M18 21V10.5L21.9883 12.9543C22.2792 13.1334 22.4247 13.2229 22.4711 13.3356C22.5115 13.434 22.5083 13.5449 22.4623 13.6408C22.4094 13.7507 22.259 13.8317 21.9583 13.9936L18 16.125"
            stroke="#DDBB22"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default PinIcon;
