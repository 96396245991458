import {PayloadAction} from '@reduxjs/toolkit';
import createBaseReducerSlice, {IBaseReducerState} from './baseSlice';
import {ITeamListingOutput} from '../../model/team';
import {initApiCall} from '../../utils/reduxHelpers';

export interface ITeamListingState extends IBaseReducerState {
    teamList: ITeamListingOutput[] | null;
}

export interface ISetTeamListing {
    teamList: ITeamListingOutput[] | null;
}

const initialState: ITeamListingState = {
    teamList: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const teamListingSlice = createBaseReducerSlice({
    name: 'teamListing',
    initialState: initialState,
    reducers: {
        setTeamListing: (state: ITeamListingState, action: PayloadAction<ISetTeamListing>) => {
            return {
                ...state,
                teamList: action.payload.teamList,
                isLoading: false,
            };
        },
        getTeamListing: (state: ITeamListingState) => initApiCall(state),
    },
});

export const {setTeamListing, setLoading, setError, setInitialized, getTeamListing} = teamListingSlice.actions;
export default teamListingSlice.reducer;
