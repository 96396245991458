import {Box, Stack, TableCell, TableRow, Typography} from '@mui/material';
import React from 'react';
import {
    ICourierJobReportListingEntry,
    userFullName,
    formattedMetersToKilometers,
    timeWithHoursAndMinutes,
    PhoneComponent,
    JobPrice,
} from 'palipali-panel-common-web';
import ShowCity from '../../../Couriers/ShowCity';

interface IReportListRowProps {
    index: number;
    item: ICourierJobReportListingEntry;
}
const ReportListRow: React.FC<IReportListRowProps> = (props: IReportListRowProps) => {
    return (
        <TableRow>
            <TableCell>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                    <span className="index-number-cell">
                        <Typography component="span" className="index-number">{`#${props.index}`}</Typography>
                    </span>
                    <Stack spacing={0}>
                        <Typography variant="subtitle2" color="textPrimary">
                            {userFullName(props.item?.account?.firstName, props.item?.account?.lastName)}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {props.item?.account?.username}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            <PhoneComponent phone={props.item.account?.phone} />
                        </Typography>
                    </Stack>
                </Box>
            </TableCell>
            <TableCell>
                <ShowCity cityId={props.item.account.cityId} />
            </TableCell>
            <TableCell>
                <Typography variant="body2">{props.item.jobsCompleted}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{props.item.jobsReported}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{props.item.jobsCancelled}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{timeWithHoursAndMinutes(props.item.time)}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{formattedMetersToKilometers(props.item.distance)}</Typography>
            </TableCell>
            <TableCell>
                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                    <JobPrice price={props.item.value} />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default ReportListRow;
