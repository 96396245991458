import React, {Component} from 'react';
import {useTranslation} from 'react-i18next';
import {IVerticalNavMenuSectionHeaderProps} from '../../../types';

type Props = IVerticalNavMenuSectionHeaderProps;

const VerticalNavMenuSectionHeader = (props: Props) => {
    const {t} = useTranslation();

    return (
        <li className="navigation-header">
            <span>{t(props.item.header)}</span>
        </li>
    );
};

export default VerticalNavMenuSectionHeader;
