import {createSelector} from '@reduxjs/toolkit';
import {IVehicleTypeState} from '../reducers/vehicleTypeSlice';
import {CommonRootState} from '../reducers';

export const selectVehicleType = (state: CommonRootState): IVehicleTypeState => {
    return state.vehicleType;
};

export const vehicleTypesSelector = createSelector([selectVehicleType], (state: IVehicleTypeState) => state.vehicleTypes);

export const vehicleTypesLoadingSelector = createSelector([selectVehicleType], (state: IVehicleTypeState) => state.isLoading);

export const vehicleTypesInitializedSelector = createSelector([selectVehicleType], (state: IVehicleTypeState) => state.isInitialized);

export const vehicleTypesErrorSelector = createSelector([selectVehicleType], (state: IVehicleTypeState) => state.error);
